import React from "react";
import { connect } from "../i18n/connect";
import { actions as translationsActions } from "../redux/modules/translations";

export default function withTranslations(WrappedComponent) {
  const mapDispatchToProps = {
    setLocale: translationsActions.setLocale
  };
  @connect(null, mapDispatchToProps)
  class WithTranslations extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithTranslations;
}
