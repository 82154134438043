import { combineReducers } from "redux";

import deleteAccount from "./delete";
import login from "./login";
import register from "./register";

export default combineReducers({
  deleteAccount,
  login,
  register
});
