import DialogContent from "@material-ui/core/DialogContent";
import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import ModalBase from "../../ModalBase";

@withTranslations
class PremiumBenefitsModal extends ModalBase {
  getContent() {
    const { translate } = this.props;

    return (
      <div className={"landing-modal"}>
        <DialogContent>
          <div
            dangerouslySetInnerHTML={{
              __html: translate("premium_benefits_modal_content")
            }}
          />
        </DialogContent>
      </div>
    );
  }
}

export default PremiumBenefitsModal;
