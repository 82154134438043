import React from "react";
import connect from "../../../i18n/connect";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import { withFormsy } from "formsy-react";
import FormsyBase from "./FormsyBase";
import Checkbox from "@material-ui/core/Checkbox";
import SquaberButton from "../../../web/components/common/SquaberButton";

const styles = {
  errorMessage: {
    marginTop: 0,
    marginLeft: -6
  }
};

@connect()
@withFormsy
@withStyles(styles)
class FormsyCheckbox extends FormsyBase {
  static defaultProps = {
    defaultStyles: false
  };

  state = {
    expanded: false
  };

  expand() {
    this.setState({
      expanded: true
    });
  }

  render() {
    let {
      classes,
      style,
      label,
      defaultStyles,
      errorMessage,
      value,
      translate,
      translateData,
      expands,
      expandedLabel,
      required
    } = this.props;

    const { expanded } = this.state;

    const showError = this.showError();

    label = translate(
      expands && expanded ? expandedLabel : label,
      translateData
    );

    return (
      <FormControl
        className={`${classes.formControl} jtc-formsy-checkbox`}
        error={showError}
      >
        <FormControlLabel
          className={"checkbox-wrapper"}
          classes={{
            label: "checkbox-label-wrapper"
          }}
          control={
            <Checkbox
              checked={value}
              onChange={(event, value) => this.handleChange(value)}
              style={defaultStyles ? {} : style}
            />
          }
          label={
            <span className={"checkbox-label"}>
              {required ? <span className="required-mark">*</span> : null}
              <span dangerouslySetInnerHTML={{ __html: label }} />{" "}
              {expands && !expanded ? (
                <SquaberButton
                  buttonStyle={false}
                  onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    this.expand();
                  }}
                >
                  {translate("more")}
                </SquaberButton>
              ) : null}
            </span>
          }
        />
        {showError ? (
          <FormHelperText className={classes.errorMessage}>
            {translate(errorMessage)}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}

export default FormsyCheckbox;
