import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";

export const instrumentsListRoutine = createRoutine(
  "app/instrumentsList/GET_INSTRUMENTS_LIST"
);

const initialState = {
  data: null,
  loading: false,
  error: null
};

export default handleActions(
  {
    [instrumentsListRoutine.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [instrumentsListRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
      error: null
    }),
    [instrumentsListRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    })
  },
  initialState
);
