import type { ContactEventData } from "../types/SalesManagoEventData.type";
import type { ToggleStockToListMetaData } from "./AddStockToListEvent";
import { BaseEvent } from "./BaseEvent";

class DeleteStockFromListEvent extends BaseEvent {
  constructor(metaData: ToggleStockToListMetaData) {
    super();
    this.metaData = metaData;
  }

  getEventName() {
    return "delete_stock_from_list";
  }
  getEventTriggerName() {
    return "Deletaing stock from user stock list";
  }

  getEventDetailsMatrix() {
    return `detail5=stock list id
detail6=stock id
detail7=stock ticker`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: this.metaData.stockListId,
      detail6: this.metaData.stockId,
      detail7: this.metaData.stockTicker
    };
  }
}
export default DeleteStockFromListEvent;
