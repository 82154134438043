import React, { useEffect, useMemo } from "react";
import StockCards from "../../../common/StockCards";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty, isFunction } from "lodash";
import classNames from "classnames";
import { fetchInterestingStocks } from "../../../../../redux/modules/browserView";
import {
  getInterestingStocks,
  getInterestingStocksLoading
} from "../../../../../redux/modules/browserView/selector";
import Loader from "../../../common/Loader";
import AnalyticsEventTrackerWeb from "../../../../../lib/AnalyticsEventTracker/AnalyticsEventTrackerWeb";
import SearchResults from "../../../common/SearchResults";

import "../styles/default.scss";

function SearchModal({
  lastSearched = [],
  onClose,
  onStockClick = () => {},
  results,
  loadingResults,
  loadingMoreResults,
  searchPhrase,
  isOpen
}) {
  const dispatch = useDispatch();
  const interestingStocks = useSelector(getInterestingStocks);
  const isLoadingInterestingStocks = useSelector(getInterestingStocksLoading);

  const hasSearchPhrase = !isEmpty(searchPhrase);
  const hasFoundResults = !isEmpty(searchPhrase) && !isEmpty(results);
  const hasInterestingStocks = !isEmpty(interestingStocks);

  useEffect(() => {
    if (!isOpen) return;
    dispatch(fetchInterestingStocks());
    AnalyticsEventTrackerWeb.trackSearchPageShownEvent();
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    const hasMainContent =
      isLoadingInterestingStocks ||
      loadingResults ||
      hasInterestingStocks ||
      hasFoundResults;

    if (hasMainContent) return;

    onClose();
  }, [
    isLoadingInterestingStocks,
    loadingResults,
    hasInterestingStocks,
    hasFoundResults
  ]);

  const handleSearchResultClick = ({ market, ticker }) => {
    isFunction(onStockClick) && onStockClick({ market, ticker });
    AnalyticsEventTrackerWeb.trackSearchResultOnClickEvent({ market, ticker });
  };

  const handleInterestingStockClick = ({
    market,
    ticker,
    index,
    stockCardsTitle
  }) => {
    isFunction(onStockClick) && onStockClick({ market, ticker });
    AnalyticsEventTrackerWeb.trackSearchListStockOnClickEvent({
      market,
      ticker,
      searchListPosition: index + 1,
      searchListName: stockCardsTitle
    });
  };

  const interestingStockSections = useMemo(() => {
    const stocks = [];

    for (const [key, value] of Object.entries(interestingStocks)) {
      if (isEmpty(value)) continue;

      const sections = (
        <StockCards
          key={key}
          title={key}
          stocks={value}
          className="search-modal__section"
          onStockClick={handleInterestingStockClick}
        />
      );
      stocks.push(sections);
    }

    return stocks;
  }, [interestingStocks]);

  const parseLastSearched = useMemo(() => {
    return lastSearched.map(stock => {
      return {
        ...stock,
        market: undefined,
        onClick: () =>
          onStockClick({ market: stock.market, ticker: stock.ticker })
      };
    });
  }, [lastSearched]);

  const resultsWrapper = (
    <SearchResults
      loadingMoreResults={loadingMoreResults}
      results={results}
      onResultClick={handleSearchResultClick}
      searchPhrase={searchPhrase}
      loadingResults={loadingResults}
    />
  );

  const loadingMainContent =
    loadingResults || (isLoadingInterestingStocks && !hasSearchPhrase);

  const mainContent = hasSearchPhrase ? (
    resultsWrapper
  ) : (
    <div className="search-modal__grid">{interestingStockSections}</div>
  );

  return (
    <div
      className={classNames({
        "search-modal": true,
        "search-modal_results": hasFoundResults
      })}
    >
      <main className="search-modal__contentWrapper">
        <div className="search-modal__content">
          {!isEmpty(parseLastSearched) ? (
            <StockCards
              title="last_searched"
              stocks={parseLastSearched}
              type="simple"
            />
          ) : null}
          {loadingMainContent ? (
            <div className="search-modal__loading">
              <Loader />
            </div>
          ) : (
            mainContent
          )}
        </div>
        <span onClick={() => onClose()} className="search-modal__close">
          <i className="fal fa-times" />
        </span>
      </main>
    </div>
  );
}

export default SearchModal;
