import "../styles/default.scss";

import { throttle } from "lodash";
import moment from "moment-mini";
import React, { useMemo, useRef, useState } from "react";

import ApiClientAsync from "../../../../../api/ApiClientAsync";
import withTranslations from "../../../../../hocs/withTranslations";
import decisionModal from "../../../../../lib/decisionModal";
import { toFrontendBackupData } from "../../../../../redux/modules/tvchartData/dto/toFrontendBackupData";
import SquaberTooltip from "../../SquaberTooltip";

function TVChartBackupList({
  close,
  backupsList,
  translate,
  minDisplayedCount = 10,
  uploadChartBackupToChart
}) {
  const [displayedCount, setDisplayedCount] = useState(minDisplayedCount);
  const [error, setError] = useState(null);
  const wrapperRef = useRef(null);

  const increaseDisplayCount = () => {
    if (displayedCount >= backupsList.length) return;
    if (minDisplayedCount <= 0) return;

    setDisplayedCount(prevState => prevState + minDisplayedCount);
  };

  const handleScroll = () => {
    if (typeof wrapperRef?.current === undefined) return;

    const { scrollTop, scrollHeight, clientHeight } = wrapperRef.current;

    const scrollMaxHeight = scrollHeight - clientHeight;
    const scrollPosition = scrollTop;
    const scrollBreakPoint = scrollMaxHeight / 2;

    if (scrollMaxHeight - scrollPosition >= scrollBreakPoint) return;

    increaseDisplayCount();
  };

  const apiClient = useMemo(() => new ApiClientAsync(), []);

  const handleClick = async url => {
    try {
      setError(null);

      const data = await apiClient.get({
        url: url
      });

      const backupData = toFrontendBackupData(data);

      if (typeof backupData === "undefined") {
        throw Error("Failed backup data transformation");
      }

      uploadChartBackupToChart({
        chart: JSON.parse(backupData.chart),
        settings: backupData.settings
      });

      close();
    } catch {
      setError(url);
    }
  };

  return (
    <div
      className="tv-chart-options-window wide chart-backup"
      ref={wrapperRef}
      onScroll={throttle(handleScroll, 250)}
    >
      <h4>
        <span className="title">{translate("Backups")}</span>
        <span className="close-button" onClick={close}>
          <i className="fal fa-times" />
        </span>
      </h4>
      <ul className="chart-backup-list">
        {backupsList.slice(0, displayedCount).map(({ created, url }) => {
          return (
            <li
              key={created}
              onClick={async () => {
                const decision = await decisionModal(
                  "ConfirmModal",
                  {
                    title: "Are you sure?",
                    message:
                      "Are you sure you want restore this chart backup? This will replace all your settings and drawn objects."
                  },
                  ["confirm", "cancel"]
                );

                if (decision === "confirm") {
                  await handleClick(url);
                }
              }}
            >
              <span className="label">
                {moment(created).format("DD.MM.YYYY, HH:mm:ss")}
              </span>
              {error === url ? (
                <span className="label error">
                  {translate("Applying backup failed")}
                </span>
              ) : null}
              <SquaberTooltip title="tooltip-restore-backup">
                <span>
                  <i className="fal fa-cloud-download" />
                </span>
              </SquaberTooltip>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default withTranslations(TVChartBackupList);
