import React from "react";

import theme from "../../../../theme";

function Shimmer({ style, className }) {
  return (
    <div
      style={{ backgroundColor: theme.colors.baseGreyLight8, ...style }}
      className={className}
    />
  );
}

export default Shimmer;
