import type { ContactEventData } from "../types/SalesManagoEventData.type";
import smEventTypes from "../utils/smEventTypes";
import { BaseEvent } from "./BaseEvent";

interface OfferModalEventMetaData {
  name: string;
}

class OfferModalEvent extends BaseEvent {
  constructor(offerModalEvent: OfferModalEventMetaData) {
    super();
    this.offerModalEvent = offerModalEvent;
  }

  getEventName() {
    return "offer_modal";
  }

  getEventTriggerName() {
    "Offer modal is displayed.";
  }

  getEventDetailsMatrix() {
    return `detail5=what user click to show offer modal`;
  }

  getContactEventData(): ContactEventData {
    return {
      contactExtEventType: smEventTypes.OFFER,
      detail5: this.offerModalEvent.name
    };
  }
}

export default OfferModalEvent;
