import React from "react";
import autoBind from "auto-bind";

export default class PaymentMethodBoxAbstract extends React.Component {
  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  state = {
    showCardMockup: false,
    cardModel: {
      card_code: "",
      card_number: "",
      expiration_month: "01",
      expiration_year: "18",
      name_on_card: ""
    },
    // TODO: Allow credit card update when proper back-end implementation will be available
    canUpdateCreditCard: false
  };

  setShowCardMockup(value) {
    this.setState({
      showCardMockup: value
    });
  }

  setCardModelValue(key, value) {
    const { cardModel } = this.state;

    cardModel[key] = value;

    this.setState({ cardModel });
  }
}
