import "../styles/default.scss";

import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";

import ApiClientAsync from "../../../../../api/ApiClientAsync";
import withTranslations from "../../../../../hocs/withTranslations";
import responseStatus from "../../../../../lib/api/responseStatus";
import ContentSection from "../../../common/ContentSection";
import Loader from "../../../common/Loader";
import unsubscribeNotificationType from "../lib/unsubscribeNotificationType";

function UnsubscribeNotificationTypePage({ match, translate }) {
  const params: {
    locale?: string,
    notificationChannel?: string,
    notificationType?: string,
    userId?: string,
    hash?: string
  } = match.params ?? {};

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      const apiClient = new ApiClientAsync(params?.locale);

      const unsubscribedNotificationType = await unsubscribeNotificationType(
        apiClient,
        {
          hash: params.hash,
          notificationChannel: params.notificationChannel,
          notificationType: params.notificationType,
          userId: params.userId
        }
      );

      const success =
        unsubscribedNotificationType?.status === responseStatus.OK;

      if (success) {
        setError(false);
      } else {
        setError(true);
      }

      setLoading(false);
    };

    fetch().then();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Col
      md={{ size: 8, offset: 2 }}
      className={"unsubscribe-notification-type-page"}
    >
      <ContentSection>
        {error ? (
          <>
            <h1>{translate("unsubscribe_notification_type_error_title")}</h1>
            <p>
              {translate("unsubscribe_notification_type_error_description")}
            </p>
          </>
        ) : (
          <>
            <h1>{translate(`unsubscribe_${params.notificationType}_title`)}</h1>
            <p>
              {translate(`unsubscribe_${params.notificationType}_description`)}
            </p>
          </>
        )}
      </ContentSection>
    </Col>
  );
}

export default withTranslations(UnsubscribeNotificationTypePage);
