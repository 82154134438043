import autoBind from "auto-bind";
import moment from "moment-mini";
import React from "react";

import { connect } from "../i18n/connect";
import detectDateFormat from "../lib/detectDateFormat";
import { getIsWeb } from "../lib/platformHelper";
import {
  addPortfolioTransactionRoutine,
  clearAddTransactionValidationError,
  clearAllAddTransactionValidationErrors
} from "../redux/modules/portfolios";
import {
  getAddPortfolioStockTransactionState,
  getCurrentPortfolioState
} from "../redux/modules/portfolios/selector";
import withFormBuilder from "./withFormBuilder";
import withPortfolioTransactionHistoryTable, {
  DIVIDEND_TRANSACTION_TYPE
} from "./withPortfolioTransactionHistoryTable";

export default function withPortfolioAddStockTransaction(WrappedComponent) {
  const mapStateToProps = state => ({
    currentPortfolio: getCurrentPortfolioState(state),
    addPortfolioStockTransactionState: getAddPortfolioStockTransactionState(
      state
    )
  });

  const mapDispatchToProps = {
    addPortfolioTransaction: addPortfolioTransactionRoutine,
    clearValidationError: clearAddTransactionValidationError,
    clearAllValidationError: clearAllAddTransactionValidationErrors
  };

  @connect(mapStateToProps, mapDispatchToProps)
  @withPortfolioTransactionHistoryTable
  @withFormBuilder(props => [
    {
      name: "type",
      label: "Transaction type",
      type: getIsWeb() ? "select" : "radio",
      value: "",
      options: Object.keys(props.transactionTypes.stock)
        .filter(key => Number(key) !== DIVIDEND_TRANSACTION_TYPE)
        .map(key => ({
          label: props.transactionTypes.stock[key],
          value: String(key)
        })),
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "date",
      label: "Date",
      type: "date",
      value: moment().format("DD.MM.YYYY"),
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "price",
      label: "Price",
      type: "text",
      value: props.quoteData
        ? String(Number(props.quoteData.close).toFixed(4))
        : "",
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      inputMode: "numeric"
    },
    {
      name: "shares",
      label: "Stocks count",
      type: "text",
      value: "",
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      inputMode: "numeric"
    },
    {
      name: "commission",
      label: "Commission",
      type: "text",
      value: "",
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      inputMode: "numeric"
    }
  ])
  class WithPortfolioAddStockTransaction extends React.Component {
    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    addTransaction(data, hideForm) {
      const { setShowAddStockTransaction } = this.props;

      let requestData = {};

      if (!hideForm && setShowAddStockTransaction) {
        hideForm = function() {
          setShowAddStockTransaction(false);
        };
      }

      data.formBuilder.fields.forEach(field => {
        if (field.name === "date") {
          requestData[field.name] = moment(
            field.value,
            detectDateFormat(field.value)
          ).format("YYYY-MM-DD");
        } else {
          requestData[field.name] = field.value;
        }
      });

      requestData.ticker = data.stockData.ticker;
      requestData.market = data.stockData.market;
      requestData.stock = data.stockData.stock.id;

      requestData.stockDetails = {
        ticker: data.stockData.ticker,
        market: data.stockData.market,
        id: data.stockData.stock.id
      };

      this.props.addPortfolioTransaction({
        requestData,
        hideForm
      });
    }

    calculateCommission() {
      const {
        formBuilder,
        currentPortfolio: {
          data: { commission, commission_min: minCommission }
        }
      } = this.props;

      const stocksCount = formBuilder.fields.find(
        field => field.name === "shares"
      ).value;
      let stockPrice = formBuilder.fields.find(field => field.name === "price")
        .value;

      if (!stockPrice || !stocksCount) {
        return;
      }

      stockPrice = Number(stockPrice.replace(",", "."));

      let calculatedCommission = stockPrice * stocksCount * commission;

      if (calculatedCommission < minCommission) {
        calculatedCommission = minCommission;
      }

      formBuilder.handleChange(
        {
          name: "commission"
        },
        String(Number(calculatedCommission).toFixed(3))
      );
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          addTransaction={this.addTransaction}
          calculateCommission={this.calculateCommission}
        />
      );
    }
  }

  return WithPortfolioAddStockTransaction;
}
