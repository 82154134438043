import { LOCATION_CHANGE } from "react-router-redux/reducer";
import { getIsWeb } from "../../../lib/platformHelper";

const initialState = {
  lastPathName: ""
};

export default function reducer(state = initialState, action = {}) {
  if (getIsWeb() && action.type === LOCATION_CHANGE) {
    if (state.lastPathName !== action.payload.pathname) {
      if (window.pageYOffset !== 0) {
        setTimeout(() => {
          window.scrollTo(0, 0);
          let event = new Event("scroll");
          window.dispatchEvent(event);
        }, 50);
      }

      return Object.assign({}, state, {
        lastPathName: action.payload.pathname
      });
    }
  }

  return state;
}
