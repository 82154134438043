import React from "react";
import { getMarketTranslations } from "../redux/modules/translations/selector";
import { connect } from "react-redux";

export default function withMarketTransltaions(WrappedComponent) {
  const mapStateToProps = state => ({
    marketTranslations: getMarketTranslations(state)
  });

  @connect(mapStateToProps)
  class WithMarketTransltaions extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }
  return WithMarketTransltaions;
}
