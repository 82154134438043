import React from "react";
import { Helmet } from "react-helmet";
import withNotifications from "../../../../../hocs/withNotifications";
import config from "../../../../../config";

function getTitle(title, unreadCount) {
  let newTitle = "";
  if (unreadCount) {
    newTitle += `(${unreadCount}) `;
  }

  newTitle += `${title} - ${config.title}`;

  return newTitle;
}

const PageTitle = ({ title, unreadCount }) => (
  <Helmet title={getTitle(title, unreadCount)} />
);

PageTitle.propTypes = {};

export default withNotifications(PageTitle);
