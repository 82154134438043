import { spawn, takeEvery, select } from "redux-saga/effects";
import { TRACK_SM_EVENT } from "./index";
import type { ISalesManagoEvent } from "../../../lib/SalesManago/interfaces/ISalesManagoEvent";
import type { SalesManagoEventData } from "../../../lib/SalesManago/types/SalesManagoEventData.type";
import ApiClient from "../../../api/ApiClient";
import config from "../../../config";
import { getUserData } from "../auth/login/selector";

interface IOnTrackSMEventParams {
  payload: {
    event: ISalesManagoEvent
  };
}

function* onTrackSMEvent({ payload: { event } }: IOnTrackSMEventParams) {
  if (!config.trackSMEvents) {
    return;
  }

  const userData = yield select(getUserData);

  if (userData?.contact_id) {
    event.setContactId(userData.contact_id);

    const eventData: SalesManagoEventData = event.getSMEventData();
    const fingerprint: string = event.getFingerprint();

    try {
      yield ApiClient.post({
        urlPath: "app.sm.event",
        data: eventData,
        headers: {
          "X-Fgp": fingerprint
        }
      });
    } catch (e) {
      console.error("SM event not sent");
    }
  }
}

function* watchTrackEvent() {
  yield takeEvery(TRACK_SM_EVENT, onTrackSMEvent);
}

export default [spawn(watchTrackEvent)];
