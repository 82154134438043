export const getLoading = state => state.notifications.loading;
export const getMessages = state => state.notifications.data.messages || [];
export const getMessage = (state, id) =>
  state.notifications.data.messages.find(message => message.id === id);
export const getUnreadCount = state =>
  state.notifications.data.unread ? state.notifications.data.unread : 0;
export const getEndReached = state => state.notifications.endReached;
export const getLastId = state => {
  let messages = state.notifications.data.messages
    ? [...state.notifications.data.messages]
    : [];

  return messages.length ? messages[messages.length - 1].id || null : null;
};
