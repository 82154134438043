import classNames from "classnames";
import React from "react";

import connect from "../../../../../../i18n/connect";
import useIsDesktop from "../../../hooks/useIsDesktop";
import AppMockup from "../assets/iphone-mockup-2_1.png";

export const AppMock = ({ translate }) => {
  const isDesktop = useIsDesktop();

  return (
    <div
      className={classNames("flex items-center justify-center gap-5", {
        "mb-16": !isDesktop
      })}
    >
      <img
        className={"w-6/12"}
        src={AppMockup}
        alt={translate("lp_section-6_squaber-app-image_alt")}
      />
      <ul
        className={classNames("ul_check min-w-32", {
          "text-base": isDesktop,
          "text-xs": !isDesktop
        })}
      >
        <li>{translate("lp_section-6_squaber-app-image_item-1")}</li>
        <li>{translate("lp_section-6_squaber-app-image_item-2")}</li>
        <li>{translate("lp_section-6_squaber-app-image_item-3")}</li>
      </ul>
    </div>
  );
};

export default connect()(AppMock);
