import React from "react";
import qs from "qs";
import Loader from "../../../common/Loader";
import withPaymentRedirectHandler from "../../../../../hocs/withPaymentRedirectHandler";

@withPaymentRedirectHandler
class PaymentRedirectActionHandler extends React.Component {
  componentDidMount(): void {
    const { handlePaymentAfterRedirect } = this.props;

    const urlParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    handlePaymentAfterRedirect(urlParams);
  }

  render() {
    return <Loader />;
  }
}

export default PaymentRedirectActionHandler;
