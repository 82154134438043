import { isObject } from "lodash";
import React, { useCallback, useEffect, useState } from "react";

import SubmenuLinks from "./SubmenuLinks";

function Submenu({ label, children, setMarketingMessage }) {
  const [nestedSubmenu, setNestedSubmenu] = useState(null);

  const handleCloseNestedSubmenu = useCallback(() => {
    setNestedSubmenu(null);
  }, []);

  const handleSetMarketingMessage = useCallback(
    props => {
      const marketingMessage = props?.additional?.marketingMessage;

      if (!isObject(marketingMessage)) {
        return;
      }

      setMarketingMessage(marketingMessage);
    },
    [setMarketingMessage]
  );

  const handleOpenNestedSubmenu = useCallback(props => {
    handleSetMarketingMessage(props);

    if (typeof props?.children === "undefined") {
      handleCloseNestedSubmenu();
      return;
    }
    setNestedSubmenu(props);
  }, []);

  useEffect(() => {
    handleCloseNestedSubmenu();
  }, [label]);

  return (
    <div className={"submenu"}>
      <div className={"submenu__content"}>
        <SubmenuLinks
          links={children}
          nestedSubmenu={nestedSubmenu}
          onOpenNestedSubmenu={handleOpenNestedSubmenu}
          onCloseNestedSubmenu={handleCloseNestedSubmenu}
        />
      </div>
      {nestedSubmenu ? (
        <Submenu {...nestedSubmenu} setMarketingMessage={setMarketingMessage} />
      ) : null}
    </div>
  );
}

export default Submenu;
