import "../styles/default.scss";

import React from "react";

import AdminCommentAbstract from "../../../../../abstract/AdminCommentAbstract";
import withAttention from "../../../../../hocs/withAttention";
import withTranslations from "../../../../../hocs/withTranslations";
import { shouldShowAdminComment } from "../../../../../lib/shouldShowAdminComment";
import { updateAttentionStateLocal } from "../../../../../lib/updateAttentionStateLocal";
import getStockMessages from "../../../../../lib/getStockMessages";

@withAttention
class AdminComment extends React.Component {
  render() {
    const {
      attentionState,
      translate,
      market,
      ticker,
      updateAttentionState,
      attention_updated,
      stock
    } = this.props;

    const messages = getStockMessages(stock, translate);

    if (!messages.length) {
      return null;
    }

    const showAdminComment = shouldShowAdminComment(
      attentionState,
      attention_updated
    );

    return showAdminComment ? (
      <div className="admin-comment">
        <div className={"admin-comment-content"}>
          {messages.map((message, index) => (
            <React.Fragment key={index}>
              <p>
                {message.header} <i className="far fa-comment-exclamation" />
              </p>
              <div dangerouslySetInnerHTML={{ __html: message.content }} />
            </React.Fragment>
          ))}
        </div>

        <span
          className={"admin-comment-collapse-button"}
          onClick={() =>
            updateAttentionStateLocal(
              market,
              ticker,
              updateAttentionState,
              attentionState
            )
          }
        >
          {translate("Collapse")}
          <i className={"fal fa-angle-down"} />
        </span>
      </div>
    ) : null;
  }
}

export default withTranslations(AdminComment);
