import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import "../styles/default.scss";

const PremiumIcon = ({ userHasAccess }) => (
  <i
    className={classNames({
      "premium-indicator": true,
      "user-has-premium-access": userHasAccess
    })}
  />
);

PremiumIcon.propTypes = {
  userHasAccess: PropTypes.bool
};

export default PremiumIcon;
