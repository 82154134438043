import React from "react";
import classNames from "classnames";
import { createChart } from "lightweight-charts";
import PortfolioChartAbstract from "../../../../../abstract/PortfolioChartAbstract";
import ContentSection from "../../ContentSection";
import withPortfolioChart from "../../../../../hocs/withPortfolioChart";
import Loader from "../../Loader";
import SquaberButton from "../../SquaberButton";

import "../styles/default.scss";

@withPortfolioChart
class PortfolioChart extends PortfolioChartAbstract {
  chartHeight = 400;

  componentDidMount(): void {
    this.initChart();

    window.addEventListener("resize", this.resizeChart);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.resizeChart);
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ): void {
    const { portfolioChart } = this.props;
    const portfolioHadError = prevProps.hasError && !this.props.hasError;

    if (portfolioHadError) {
      this.initChart();
    }

    this.lineSeries.setData(portfolioChart.data);

    this.chart.timeScale().fitContent();

    this.resizeChart();
  }

  initChart() {
    this.getChartData();

    if (this.chart) {
      this.chart.remove();
    }

    this.chart = createChart(this.chartContainer, {
      width: this.chartContainer.clientWidth,
      height: this.chartHeight
    });

    this.lineSeries = this.chart.addLineSeries({
      lineWidth: 2
    });
  }

  resizeChart() {
    this.chart.resize(this.chartContainer.clientWidth, this.chartHeight);
  }

  render() {
    const { translate, portfolioChart, availableCharts, hasError } = this.props;
    const { currentChart } = this.state;

    return (
      <ContentSection>
        <div className="chart-section-heading">
          <h2 className="big-heading">
            {translate("Cumulative rate of return")}
          </h2>
          <div className="chart-type-switcher">
            {availableCharts.map(type => (
              <SquaberButton
                key={type.name}
                onClick={() => {
                  this.setChartType(type.name);
                }}
                color={currentChart === type.name ? "primary" : "secondary"}
              >
                {translate(type.label)}
              </SquaberButton>
            ))}
          </div>
        </div>
        {portfolioChart.loading ? <Loader /> : null}
        <div
          className={classNames({
            "chart-container": true,
            visible:
              !portfolioChart.loading && portfolioChart.data.length && !hasError
          })}
          ref={element => {
            this.chartContainer = element;
          }}
        />
        {!hasError && !portfolioChart.loading && !portfolioChart.data.length ? (
          <p className="portfolio-is-empty">
            {translate(
              "There are no components in this portfolio. Add a transaction and start analyzing the selected stocks"
            )}
          </p>
        ) : null}
        {hasError ? (
          <p className="portfolio-errors">
            {translate(
              "Unfortunately your portfolio is invalid and we are not able to display a chart. Correct your data and start analyzing your portfolios with squaber.com"
            )}
          </p>
        ) : null}
      </ContentSection>
    );
  }
}

export default PortfolioChart;
