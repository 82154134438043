import { takeLatest, fork, put } from "redux-saga/effects";
import { fetchRecommendedStocksRoutine } from "./index";
import ApiClient from "../../../api/ApiClient";
import { getIsWeb } from "../../../lib/platformHelper";

function* fetchRecommendedStocks() {
  try {
    const { data } = yield ApiClient.get("app.stocks.recommended");

    yield put(fetchRecommendedStocksRoutine.success(data));

    if (getIsWeb()) {
      let event = new Event("resize");
      window.dispatchEvent(event);
    }
  } catch (e) {
    yield put(fetchRecommendedStocksRoutine.failure(e));
  }
}

function* watchFetchRecommendedStocks() {
  yield takeLatest(
    fetchRecommendedStocksRoutine.TRIGGER,
    fetchRecommendedStocks
  );
}

export default [fork(watchFetchRecommendedStocks)];
