import moment from "moment-mini";
import HelperWithFormattingBase from "./HelperWithFormattingBase";

export class DateHelper extends HelperWithFormattingBase {
  renderMethods = {
    date_default: this.renderDefault,
    date_days_until_date: this.renderDaysUntilDate
  };

  renderDefault(value) {
    if (moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid()) {
      const date = moment(value);

      return date.format("YYYY-MM-DD HH:mm");
    }

    return value;
  }

  renderDaysUntilDate(value, translate) {
    const date = moment(value);
    const today = moment();

    const daysDiff = date.diff(today, "days");
    const translateVars = {
      days: Math.abs(daysDiff)
    };

    let translatePhrase;

    if (daysDiff > 0) {
      translatePhrase = "in %{days} days";
    } else if (daysDiff === 0) {
      translatePhrase = "today";
    } else {
      translatePhrase = "%{days} days ago";
    }

    return translate(translatePhrase, translateVars);
  }
}

export default new DateHelper();
