import React from "react";
import YellowSwitch from "../../../../../lib/SquaberThemeComponents/YellowSwitch";
import withBulkFollowUnfollow from "../../../../../hocs/withBulkFollowUnfollow";
import TagsHelper from "../../../../../lib/TagsHelper/TagsHelper";
import withUserData from "../../../../../hocs/withUserData";
import getAccessLevel from "../../../../../lib/access/getAccessLevel";
import handleAccess from "../../../../../lib/access/handleAccess";
import AnalyticsEventTrackerWeb from "../../../../../lib/AnalyticsEventTracker/AnalyticsEventTrackerWeb";
import DesktopSorting from "./DesktopSorting";
import DesktopViewTypeSelect from "./DesktopViewTypeSelect";
import SquaberButton from "../../SquaberButton/modules/default";
import "../styles/DesktopSettings.scss";

@withBulkFollowUnfollow
@withUserData
class DesktopSettings extends React.Component {
  render() {
    const {
      settings,
      options,
      setSortBy,
      setViewType,
      setAdvancedView,
      translate,
      tagData,
      bulkFollow,
      bulkUnFollow,
      userData,
      userHasAccess
    } = this.props;

    const { name } = tagData;

    const currentTagIsAlertedTag = TagsHelper.isAlertedTag(tagData);
    const userAccessLevel = getAccessLevel(userData);
    const stockIds = tagData.stocks.map(stock => stock.id);

    return (
      <div className="desktop-settings">
        <div className="desktop-settings__text-block">
          {translate("View type")}:
        </div>
        <DesktopViewTypeSelect
          options={options.viewTypes}
          value={settings.viewType}
          onChange={value => {
            setViewType(value);
          }}
        />
        {settings.viewType === "grid" ? (
          <React.Fragment>
            <div className="desktop-settings__text-block">
              {translate("Sort by")}:
            </div>
            <DesktopSorting
              options={options.sortOptions}
              value={settings.sortBy}
              onChange={value => {
                setSortBy(value);
              }}
            />
            <div className="desktop-settings__text-block">
              {translate("Advanced view")}:
            </div>
            <YellowSwitch
              checked={settings.advancedView}
              onChange={() => {
                setAdvancedView(!settings.advancedView);
              }}
              value="advanced_view"
              color="primary"
            />
          </React.Fragment>
        ) : null}
        {!currentTagIsAlertedTag ? (
          <React.Fragment>
            <div className="desktop-settings__text-block pull-right">
              {translate("Alert stocks")}:
            </div>
            <SquaberButton
              color={"dark"}
              onClick={() => {
                if (userHasAccess) {
                  bulkFollow({ stocks: stockIds });
                  AnalyticsEventTrackerWeb.trackStockAlertOnEvent({
                    type: "list",
                    market: "list",
                    name
                  })
                } else {
                  handleAccess(userAccessLevel);
                }
              }}
            >
              <i className="fas fa-bell" /> <span>{translate("All")}</span>
            </SquaberButton>
            <SquaberButton
              color={"dark"}
              onClick={() => {
                if (userHasAccess) {
                  bulkUnFollow({ stocks: stockIds });
                } else {
                  handleAccess(userAccessLevel);
                }
              }}
            >
              <i className="fal fa-bell-slash" />{" "}
              <span>{translate("None")}</span>
            </SquaberButton>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default DesktopSettings;
