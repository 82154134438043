import React from "react";
import { bindPromiseCreators } from "redux-saga-routines";
import autoBind from "auto-bind";
import connect from "../i18n/connect";
import {
  connectTagToStockRoutinePromiseCreator,
  disconnectTagFromStockRoutinePromiseCreator
} from "../redux/modules/tags";

export default function withTagStocksControl(WrappedComponent) {
  const mapDispatchToProps = dispatch => ({
    ...bindPromiseCreators(
      {
        connectTagToStockRoutinePromiseCreator,
        disconnectTagFromStockRoutinePromiseCreator
      },
      dispatch
    )
  });

  @connect(null, mapDispatchToProps)
  class WithTagStocksControl extends React.Component {
    state = {
      activeStocks: [],
      currentTab: "stocks-list"
    };

    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    componentDidMount() {
      const {
        tagDetails: {
          data: { stocks }
        }
      } = this.props;

      const activeStocks = stocks.map(stock => ({ ...stock, active: true }));

      this.setState({
        activeStocks
      });
    }

    addActiveStock(stockToAdd) {
      const { id: tagId, connectTagToStockRoutinePromiseCreator } = this.props;

      const { activeStocks } = this.state;

      const stockReference = activeStocks.find(
        stock => stock.id === stockToAdd.id
      );

      if (stockReference) {
        stockReference.active = true;
      } else {
        activeStocks.push({ ...stockToAdd, active: true });
      }

      this.setState({ activeStocks });

      connectTagToStockRoutinePromiseCreator({ tagId, stockId: stockToAdd.id });
    }

    removeStockFromActiveStocks(stockToRemove) {
      const {
        id: tagId,
        disconnectTagFromStockRoutinePromiseCreator
      } = this.props;

      let { activeStocks } = this.state;

      activeStocks.find(stock => stock.id === stockToRemove.id).active = false;

      this.setState({ activeStocks });

      disconnectTagFromStockRoutinePromiseCreator({
        tagId,
        stockId: stockToRemove.id
      });
    }

    setCurrentTab(tab) {
      this.setState({ currentTab: tab });
    }

    render() {
      const { activeStocks, currentTab } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          activeStocks={activeStocks}
          currentTab={currentTab}
          addActiveStock={this.addActiveStock}
          removeStockFromActiveStocks={this.removeStockFromActiveStocks}
          setCurrentTab={this.setCurrentTab}
        />
      );
    }
  }

  return WithTagStocksControl;
}
