import React from "react";

import ApiClientAsync from "../api/ApiClientAsync";

export default function withCurrrentAnalyses(WrappedComponent) {
  class WithCurrentAnalyses extends React.Component {
    state = {
      analysesData: []
    };
    async componentDidMount() {
      this.setState({ analysesData: await this.getAnalysesList() });
    }

    async getAnalysesList() {
      const { locale, authToken, market, ticker, data } = this.props;

      if (!data) {
        return;
      }

      const apiClient = new ApiClientAsync(locale, authToken);
      const { id } = data;
      try {
        return await apiClient.get({
          urlPath: "app.stocks.stockPageSingleAnalyses",
          variables: {
            ticker,
            market,
            category_id: id
          }
        });
      } catch (e) {
        console.error(e);
        return [];
      }
    }

    async componentDidUpdate(prevProps) {
      const { stock } = this.props;
      const { ticker, market } = stock?.stock ?? {};
      const prevTicker = prevProps?.stock?.stock?.ticker;
      const prevMarket = prevProps?.stock?.stock?.market;
      const stockChanged = ticker !== prevTicker || market !== prevMarket;

      if (!ticker || !market || !stockChanged) return;

      this.setState({
        analysesData: await this.getAnalysesList()
      });
    }

    render() {
      const { analysesData } = this.state;

      return <WrappedComponent {...this.props} analysesData={analysesData} />;
    }
  }

  return WithCurrentAnalyses;
}
