import { isObject } from "lodash";
import React, { useEffect, useState } from "react";
import { Container } from "reactstrap";

import MarketingMessage from "./MarketingMessage";
import Submenu from "./Submenu";

function DropdownNavigation({ label, href, children, additional }) {
  const [marketingMessage, setMarketingMessage] = useState(
    additional?.marketingMessage ?? null
  );

  useEffect(() => {
    const marketingMessage = additional?.marketingMessage;

    if (isObject(marketingMessage)) {
      setMarketingMessage(additional.marketingMessage);
      return;
    }

    setMarketingMessage(null);
  }, [additional]);

  return (
    <div className={"dropdown-navigation"}>
      <Container>
        <Submenu
          label={label}
          children={children}
          href={href}
          additional={additional}
          setMarketingMessage={setMarketingMessage}
        />
        {marketingMessage ? <MarketingMessage {...marketingMessage} /> : null}
      </Container>
    </div>
  );
}

export default DropdownNavigation;
