export const getCurrentPortfolioState = state => ({
  loading: state.portfolios.currentPortfolioLoading,
  data: state.portfolios.currentPortfolio
});

export const getPortfolioChartState = state => state.portfolios.chart;

export const getPortfolioTransactionHistory = state =>
  state.portfolios.transactionHistory;

export const getPortfoliosListState = state => state.portfolios.list;
export const getPortfoliosStockState = state =>
  state.portfolios.portfoliosStock;

export const getPortfolioStockTransactionsState = state =>
  state.portfolios.stockTransactions;

export const getPortfolioCashTransactionsState = state =>
  state.portfolios.cashTransactions;

export const getAddPortfolioCashTransactionState = state =>
  state.portfolios.addPortfolioCashTransaction;

export const getAddPortfolioStockTransactionState = state =>
  state.portfolios.addPortfolioStockTransaction;

export const getPortfolioHasErrors = state => {
  const singlePortfolioHasErrors =
    state.portfolios.currentPortfolio.errors.length;

  const portfolioOnListHasErrors =
    state.portfolios.list.data.portfolios
      .map(portfolio => portfolio.errors)
      .filter(value => value.length > 0).length > 0;

  return singlePortfolioHasErrors || portfolioOnListHasErrors;
};

export const getChartLastUsedType = state =>
  state.portfolios.chart.lastUsedType;

export const getAddEditPortfolioState = state =>
  state.portfolios.addEditPortfolioState;
