import React from "react";

export default class SingleValueFilterAbstract extends React.Component {
  handleChange(value) {
    const { onChange } = this.props;

    onChange(value.map(option => option.value));
  }

  mapOptionsList(options, settings: { labelPrefix: string }) {
    const { translate } = this.props;

    return options
      ? options
          .filter(option => {
            if (typeof option === "boolean" || option === 0) {
              return true;
            }

            return !!option;
          })
          .map(option => ({
            label: translate(
              settings?.labelPrefix
                ? `${settings?.labelPrefix}${option}`
                : String(option)
            ),
            value: option
          }))
      : [];
  }
}
