import React from "react";
import connect from "../../../i18n/connect";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import { withFormsy } from "formsy-react";
import FormsyBase, { styles } from "./FormsyBase";

@connect()
@withFormsy
@withStyles(styles)
class FormsySelect extends FormsyBase {
  static defaultProps = {
    showInitialValue: false
  };

  render() {
    let {
      classes,

      name,
      label,
      options,
      fullWidth,

      errorMessage,
      value,
      translate,
      showInitialValue,
      disabled
    } = this.props;

    const showError = this.showError();
    label = translate(label);

    return (
      <FormControl
        className={classes.formControl}
        error={showError}
        fullWidth={fullWidth}
      >
        <InputLabel htmlFor={name}>{label}</InputLabel>
        <Select
          value={value || ""}
          inputProps={{
            name: name
          }}
          onChange={event => this.handleChange(event.target.value)}
          disabled={disabled}
        >
          {showInitialValue ? <MenuItem value="">{label}</MenuItem> : null}
          {options
            ? options.map(option => (
                <MenuItem value={`${option.value}`} key={option.value}>
                  {option.label}
                </MenuItem>
              ))
            : null}
        </Select>
        {showError ? (
          <FormHelperText>{translate(errorMessage)}</FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}

export default FormsySelect;
