import { takeLatest, fork, put } from "redux-saga/effects";
import { resendConfirmationEmail } from "./index";
import ApiClient from "../../../api/ApiClient";
import { actions as alertsActions } from "../alerts";

function* onResendVerificationEmail() {
  try {
    const { data } = yield ApiClient.post({
      urlPath: "app.auth.sendValidationEmail"
    });
    yield put(
      alertsActions.addSuccessAlert({
        title: data.message
      })
    );
  } catch (e) {
    console.log(e);
  }
}

function* watchResendVerificationEmail() {
  yield takeLatest(resendConfirmationEmail.TRIGGER, onResendVerificationEmail);
}

export default [fork(watchResendVerificationEmail)];
