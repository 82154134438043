import "../styles/default.scss";

import PropTypes from "prop-types";
import React from "react";

import ApiClientAsync from "../../../../../api/ApiClientAsync";
import withTranslations from "../../../../../hocs/withTranslations";
import withUserNote from "../../../../../hocs/withUserNote";
import Loader from "../../Loader";

@withUserNote
@withTranslations
class UserNotes extends React.Component {
  static propTypes = {
    authToken: PropTypes.string,
    stockId: PropTypes.number
  };

  constructor(props) {
    super(props);
    this.textAreaRef = React.createRef();
    this.state = {
      notice: null
    };
  }

  async fetchNotice() {
    const apiClient = new ApiClientAsync(
      this.props.locale,
      this.props.authToken
    );

    return await apiClient.get({
      urlPath: "app.notices.get",
      variables: {
        stockId: this.props.stockId
      }
    });
  }

  async componentDidMount() {
    const notice = await this.fetchNotice();

    if (this?.textAreaRef?.current) {
      this.textAreaRef.current.innerText = notice.text;
    }

    this.setState({ notice });
  }

  async componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    if (this.props.stockId === prevProps.stockId) {
      return;
    }

    const notice = await this.fetchNotice();

    if (this?.textAreaRef?.current) {
      this.textAreaRef.current.innerText = notice.text;
    }

    this.setState({ notice });
  }

  render() {
    const { translate, saveUserNote } = this.props;

    const { notice } = this.state;

    const loading = !notice;

    return (
      <>
        {loading ? <Loader small /> : null}
        <div
          className="user-notes"
          style={
            loading
              ? {
                  visibility: "hidden",
                  height: 0,
                  width: 0,
                  position: "absolute"
                }
              : {}
          }
        >
          <span>{translate("user-notes-description")}</span>
          <div
            ref={this.textAreaRef}
            className="textarea"
            contentEditable
            onInput={({ target: { innerText: text } }) => {
              saveUserNote({
                id: notice?.id,
                user: notice?.user,
                stock: notice?.stock,
                text
              });
            }}
          />
        </div>
      </>
    );
  }
}

export default UserNotes;
