export default function getStockData(props) {
  const {
    stock,
    stock_id,
    ticker,
    market,
    company_name,
    recommended,
    average_volume,
    there_is_a_manual_signal,
    currency = "CUR"
  } = props;

  if (stock) {
    return {
      stock_id: stock.id,
      ticker: stock.ticker,
      market: stock.market,
      name: stock.short_name,
      recommended: stock.recommended,
      currency: stock.currency,
      average_volume: stock.average_volume
    };
  } else {
    return {
      stock_id,
      ticker,
      market,
      name: company_name,
      recommended: there_is_a_manual_signal,
      currency,
      average_volume
    };
  }
}
