import React from "react";
import moment from "moment-mini";
import withUserData from "../../../../../hocs/withUserData";
import getAccessLevel from "../../../../../lib/access/getAccessLevel";
import accessLevelsMap from "../../../../../lib/access/accessLevelsMap";
import FinancialAnalysisDetail from "../../FinancialAnalysisDetail";
import { isEmpty } from "lodash";
import Loader from "../../Loader";
import { compose } from "redux";
import withFinancialSituationData from "../../../../../hocs/withFinancialSituationData";

const FinancialIndicators = ({ data, loading, userData, translate }) => {
  if (loading || isEmpty(data)) {
    return <Loader />;
  }

  const { parameters_groups_classification, balance_date } = data;
  const userAccess = getAccessLevel(userData);
  const hasPremiumAccess = userAccess === accessLevelsMap.HAS_PREMIUM_ACCESS;

  if (!hasPremiumAccess) {
    return null;
  }

  return (
    <div className="details">
      <p
        dangerouslySetInnerHTML={{
          __html: translate(
            "The analysis is based on the company's 4 quarterly reports, the latest report taken into account presents the company's <strong>financial condition as at %{report_date}</strong>",
            {
              report_date: moment(balance_date).format("DD.MM.YYYY")
            }
          )
        }}
      />
      {parameters_groups_classification.map((classification, index) => (
        <FinancialAnalysisDetail key={index} classification={classification} />
      ))}
    </div>
  );
};

FinancialIndicators.propTypes = {};

export default compose(
  withFinancialSituationData,
  withUserData
)(FinancialIndicators);
