import { createAction, handleActions } from "redux-actions";

export const SET_IS_LANDSCAPE = "app/screenOrientation/SET_IS_LANDSCAPE";

export const setIsLandscape = createAction(
  SET_IS_LANDSCAPE,
  (value: boolean) => ({ value })
);

const initialState = {
  isLandscape: false
};

export default handleActions(
  {
    [SET_IS_LANDSCAPE]: (state, { payload }) => ({
      isLandscape: payload.value
    })
  },
  initialState
);
