import React from "react";
import UniversalTableLiteWeb from "../../../common/UniversalTableLiteWeb";
import ContentSection from "../../../common/ContentSection";
import withTranslations from "../../../../../hocs/withTranslations";
import PageTitle from "../../../common/PageTitle";
import ResourceResolver from "../../../../../lib/UniversalTable/ResourceResolver";

const StocksTable = ({ translate }) => (
  <ContentSection>
    <PageTitle title={translate("stocks_table_page_title")} />
    <h1>{translate("stocks_table_page_header")}</h1>
    <p
      dangerouslySetInnerHTML={{
        __html: translate("stocks_table_page_description")
      }}
    />
    <UniversalTableLiteWeb
      resourceId={ResourceResolver["stocks_list"]}
      useUrlSettings
    />
  </ContentSection>
);

export default withTranslations(StocksTable);
