import { put, takeLatest, fork, select } from "redux-saga/effects";
import { constants, actions } from "./index";
import { actions as authActions } from "../auth/login/index";
import { getLocale } from "./selector";
import * as translations from "../../../translations/";
import ApiClient from "../../../api/ApiClient";
import { actions as menuActions } from "../menu";
import { fetchMarketingMessagesRoutine } from "../marketingMessages";
import { fetchMarketingBarsRoutine } from "../marketingBars";

function* fetchTranslations() {
  const locale = yield select(getLocale);

  try {
    let response = yield ApiClient.get({
      urlPath: "app.translations",
      variables: {
        locale
      }
    });

    const data = {
      ...translations[locale],
      ...response.data
    };

    yield put(actions.fetchTranslationsSuccess(locale, data));
  } catch (error) {
    yield put(actions.fetchTranslationsError(error));
  }
}

function* fetchMarketTranslations() {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.stockSeoData.getMarketList"
    });

    yield put(actions.fetchMarketTranslationsSuccess(data));
  } catch (error) {
    yield put(actions.fetchMarketTranslationsError(error));
  }
}

function* onSetLocale({ payload: { locale } }) {
  yield put(actions.resetTranslations());
  yield put(menuActions.fetchMenu());
  yield put(fetchMarketingMessagesRoutine());
  yield put(fetchMarketingBarsRoutine());
  yield fetchTranslations();

  try {
    yield ApiClient.put({
      urlPath: "app.auth.setLocale",
      variables: {
        locale
      }
    });

    yield put(authActions.refreshUserData({ skipAffiliation: true }));
  } catch (e) {
    console.error(e);
  }
}

function* watchGetTranslations() {
  yield takeLatest(constants.FETCH_TRANSLATIONS, fetchTranslations);
  yield takeLatest(constants.SET_LOCALE, onSetLocale);

  yield takeLatest(
    constants.FETCH_MARKET_TRANSLATIONS,
    fetchMarketTranslations
  );
}

export default [fork(watchGetTranslations)];
