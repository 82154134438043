import React from "react";
import PropTypes from "prop-types";
import { Pie } from "react-chartjs-2";
import colorArray from "../../../../../lib/colorArray";
import withTranslations from "../../../../../hocs/withTranslations";
import StockHoldersLegendItem from "../../StockHoldersLegendItem";

import "../styles/default.scss";

const StockHolders = ({ stockholders, shareAmount, translate }) => {
  if (!stockholders || !stockholders.length) {
    stockholders = [
      {
        stock_count: shareAmount,
        vote_count: shareAmount,
        name: translate("Remaining")
      }
    ];
  }
  const stocksSum = stockholders
    .map(stockholder => stockholder.stock_count)
    .reduce((previous, current) => previous + current);

  return (
    <div className="stockholders">
      <div>
        <Pie
          width={250}
          height={250}
          options={{
            maintainAspectRatio: false,
            legend: {
              display: false
            },
            tooltips: {
              callbacks: {
                label: function(tooltipItem) {
                  const { index } = tooltipItem;

                  return `${stockholders[index].name}: ${(
                    (stockholders[index].stock_count / stocksSum) *
                    100
                  ).toFixed(2)}%`;
                }
              }
            }
          }}
          data={{
            datasets: [
              {
                data: stockholders.map(stockholder => stockholder.stock_count),
                backgroundColor: colorArray,
                hoverBackgroundColor: colorArray
              }
            ],
            labels: stockholders.map(stockholder => stockholder.name)
          }}
        />
      </div>
      <ul className="legend">
        {stockholders.map((stockholder, index) => {
          return (
            <StockHoldersLegendItem
              key={stockholder.id ? stockholder.id : `stockholder - ${index}`}
              index={index}
              stockholder={stockholder}
              stocksSum={stocksSum}
            />
          );
        })}
      </ul>
    </div>
  );
};

StockHolders.propTypes = {
  stockHolders: PropTypes.array
};

export default withTranslations(StockHolders);
