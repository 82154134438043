export default {
  name: "Lista transakcji w portfelu",
  tabs: [
    {
      id: "transaction",
      name: "Kupno/sprzedaż akcji",
      is_default: true,
      is_premium: false,
      column_pairs: [
        {
          csv_data_column_first: {
            label: "Spółka",
            csv_name: "ticker",
            attribute_name: "ticker_as_link",
            filtering_attribute_name: "stock__ticker",
            sorting_attribute_name: "stock__ticker",
            values_type: "generated_in_frontend",
            filter_type: "search",
            filter_data: null,
            is_premium: false
          },
          csv_data_column_second: {
            label: "Typ transakcji",
            csv_name: "transaction_type",
            attribute_name: "transaction_type",
            renderer_name: "transaction",
            filtering_attribute_name: "type",
            sorting_attribute_name: "type",
            values_type: "generated_in_frontend",
            filter_type: false,
            filter_data: null,
            is_premium: false
          }
        },
        {
          csv_data_column_first: {
            label: "Data",
            csv_name: "date",
            attribute_name: "date",
            filtering_attribute_name: null,
            sorting_attribute_name: null,
            values_type: "date",
            filter_type: null,
            filter_data: null,
            is_premium: false
          },
          csv_data_column_second: {
            label: "Kwota",
            csv_name: "price",
            attribute_name: "price",
            filtering_attribute_name: null,
            sorting_attribute_name: null,
            values_type: "number",
            values_format: "number_default",
            filter_type: null,
            filter_data: null,
            is_premium: false
          }
        },
        {
          csv_data_column_first: {
            label: "Liczba akcji",
            csv_name: "shares",
            attribute_name: "shares",
            filtering_attribute_name: null,
            sorting_attribute_name: null,
            values_type: "whole_number",
            filter_type: null,
            filter_data: null,
            is_premium: false
          },
          csv_data_column_second: {
            label: "Prowizja",
            csv_name: "commission",
            attribute_name: "commission",
            filtering_attribute_name: null,
            sorting_attribute_name: null,
            values_type: "number",
            values_format: "number_default",
            filter_type: null,
            filter_data: null,
            is_premium: false
          }
        },
        {
          csv_data_column_first: {
            label: "",
            csv_name: "action",
            attribute_name: "action",
            values_type: "action",
            filter_type: null,
            filter_data: null,
            is_premium: false
          },
          csv_data_column_second: null
        }
      ]
    },
    {
      id: "cash_balance_change",
      name: "Transakcje gotówkowe",
      is_default: false,
      is_premium: false,
      column_pairs: [
        {
          csv_data_column_first: {
            label: "Typ operacji",
            csv_name: "transaction_type",
            attribute_name: "transaction_type",
            renderer_name: "cash_balance_change",
            filtering_attribute_name: "type",
            sorting_attribute_name: "type",
            values_type: "generated_in_frontend",
            filter_type: false,
            filter_data: null,
            is_premium: false
          },
          csv_data_column_second: null
        },
        {
          csv_data_column_first: {
            label: "Data",
            csv_name: "date",
            attribute_name: "date",
            filtering_attribute_name: null,
            sorting_attribute_name: null,
            values_type: "date",
            filter_type: false,
            filter_data: null,
            is_premium: false
          },
          csv_data_column_second: null
        },
        {
          csv_data_column_first: {
            label: "Kwota",
            csv_name: "amount",
            attribute_name: "amount",
            filtering_attribute_name: null,
            sorting_attribute_name: null,
            values_type: "number",
            values_format: "number_default",
            filter_type: null,
            filter_data: null,
            is_premium: false
          },
          csv_data_column_second: null
        },
        {
          csv_data_column_first: {
            label: "",
            csv_name: "action",
            attribute_name: "action",
            values_type: "action",
            filter_type: null,
            filter_data: null,
            is_premium: false
          },
          csv_data_column_second: null
        }
      ]
    },
    {
      id: "dividend_payout",
      name: "Dywidendy",
      is_default: false,
      is_premium: false,
      column_pairs: [
        {
          csv_data_column_first: {
            label: "Spółka",
            csv_name: "ticker",
            attribute_name: "ticker_as_link",
            filtering_attribute_name: "stock__ticker",
            sorting_attribute_name: "stock__ticker",
            values_type: "generated_in_frontend",
            filter_type: "search",
            filter_data: null,
            is_premium: false
          },
          csv_data_column_second: null
        },
        {
          csv_data_column_first: {
            label: "Data",
            csv_name: "date",
            attribute_name: "date",
            filtering_attribute_name: null,
            sorting_attribute_name: null,
            values_type: "date",
            filter_type: false,
            filter_data: null,
            is_premium: false
          },
          csv_data_column_second: null
        },
        {
          csv_data_column_first: {
            label: "Kwota",
            csv_name: "amount",
            attribute_name: "amount",
            filtering_attribute_name: null,
            sorting_attribute_name: null,
            values_type: "number",
            values_format: "number_default",
            filter_type: null,
            filter_data: null,
            is_premium: false
          },
          csv_data_column_second: null
        }
      ]
    }
  ]
};
