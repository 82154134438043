import { call, fork, put, select, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import { getDefaultMarketId } from "../../../lib/getDefaultMarketId";
import { getIsWeb } from "../../../lib/platformHelper";
import { getStockData } from "../stockData/selector";
import { getMarketTranslations } from "../translations/selector";
import {
  getMediaMonitorEntries,
  getMoreMediaMonitorEntries,
  refreshMediaMonitorEntries
} from "./index";

function* onRefreshMediaMonitorEntries() {
  try {
    const stockData = yield select(getStockData);
    const { ticker: stockId, market: marketId } = stockData.stock;

    let defaultMarketId = marketId;

    if (getIsWeb()) {
      const marketTranslations = yield select(getMarketTranslations);
      defaultMarketId = getDefaultMarketId(marketTranslations, marketId);
    }

    if (!stockId || !marketId) throw "RefreshMediaMonitorEntries error";

    yield onGetMediaMonitorEntries({ payload: { defaultMarketId, stockId } });
  } catch (error) {
    console.error(error);
  }
}

function* onGetMediaMonitorEntries({ payload }) {
  try {
    const count = typeof payload.count === "number" ? payload.count : 5;
    const start = typeof payload.start === "number" ? payload.start : 0;

    let { marketId, stockId } = payload;

    if (getIsWeb()) {
      const marketTranslations = yield select(getMarketTranslations);
      marketId = getDefaultMarketId(marketTranslations, marketId);
    }

    const { data } = yield ApiClient.get({
      urlPath: "app.stocks.mediaMonitor",
      variables: {
        market: marketId,
        ticker: stockId,
        count: count,
        start: start
      }
    });

    yield put(getMediaMonitorEntries.success(data));
  } catch (error) {
    yield put(getMediaMonitorEntries.failure(error));
  }
}

function* onGetMoreMediaMonitorEntries({ payload }) {
  try {
    const { start, count, ticker, market } = payload;

    let defaultMarketId = market;

    if (getIsWeb()) {
      const marketTranslations = yield select(getMarketTranslations);
      defaultMarketId = getDefaultMarketId(marketTranslations, market);
    }

    const { data } = yield ApiClient.get({
      urlPath: "app.stocks.mediaMonitor",
      variables: {
        start,
        count,
        ticker,
        market: defaultMarketId
      }
    });

    yield put(getMoreMediaMonitorEntries.success(data));
  } catch (error) {
    yield put(getMoreMediaMonitorEntries.failure(error));
  }
}

function* watchMediaMonitor() {
  yield takeLatest(getMediaMonitorEntries.TRIGGER, onGetMediaMonitorEntries);
  yield takeLatest(
    getMoreMediaMonitorEntries.TRIGGER,
    onGetMoreMediaMonitorEntries
  );
  yield takeLatest(
    refreshMediaMonitorEntries.TRIGGER,
    onRefreshMediaMonitorEntries
  );
}

export default [fork(watchMediaMonitor)];
