import React from "react";
import classNames from "classnames";
import { globalConfigFields } from "../../../../../redux/modules/globalConfig";
import UniversalTableCellTypes from "../../../../../lib/UniversalTable/UniversalTableCellTypes";
import withGlobalConfigFields from "../../../../../hocs/withGlobalConfigFields";
import config from "../../../../../config";

const Renderer = props => {
  const {
    blurred,
    type,
    value,
    globalConfig: { offerUrl }
  } = props;

  const cellType =
    UniversalTableCellTypes[type] || UniversalTableCellTypes["string"];

  const Renderer = require(`./${cellType}`).default;

  let shouldRenderDash = false;

  if (typeof value === "undefined" || value === null) {
    shouldRenderDash = true;
  }

  if (value && typeof value === "string" && value.toLowerCase() === "none") {
    shouldRenderDash = true;
  }

  if (blurred) {
    shouldRenderDash = true;
  }

  return (
    <div
      className={classNames({
        "universal-table-lite-renderer": true,
        blurred
      })}
      onClick={() => {
        if (blurred) {
          window.open(offerUrl || config.defaultPremiumOfferUrl, "_blank");
        }
      }}
    >
      {shouldRenderDash ? "-" : <Renderer {...props} />}
    </div>
  );
};

Renderer.defaultProps = {
  blurred: false,
  type: "String"
};

export default withGlobalConfigFields([globalConfigFields.OFFER_URL])(Renderer);
