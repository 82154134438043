import React from "react";
import connect from "../i18n/connect";
import {
  getPortfolioCashTransactionsRoutine,
  getPortfolioRoutine,
  refreshPortfolioRoutine
} from "../redux/modules/portfolios";
import { getCurrentPortfolioState } from "../redux/modules/portfolios/selector";
import withUserData from "./withUserData";
import config from "../config";
import { debounce } from "lodash";

export default function withPortfolio(WrappedComponent) {
  const mapStateToProps = state => ({
    portfolio: getCurrentPortfolioState(state)
  });

  const mapDispatchToProps = {
    getPortfolio: getPortfolioRoutine,
    getPortfolioCashTransactions: getPortfolioCashTransactionsRoutine,
    refreshPortfolio: refreshPortfolioRoutine
  };

  @withUserData
  @connect(mapStateToProps, mapDispatchToProps)
  class WithPortfolio extends React.Component {
    state = {
      dataFromWebSocket: {}
    };

    mounted = false;

    constructor(props) {
      super(props);
      const { id } = props;

      this.SocketClient = require("../lib/SocketIO/SocketClient").default;
      this.roomName = this.getRoomName(id);
      this.debouncedFetchPortfolioCashTransactions = debounce(
        props.getPortfolioCashTransactions,
        2000
      );
    }

    getRoomName(param) {
      return `${param}_view_update`;
    }

    componentDidMount(): void {
      const { getPortfolio, id } = this.props;
      const refetchIntervalSeconds = 120;

      this.mounted = true;

      getPortfolio(id);

      this.connectToWebSocket(this.roomName);

      this.refetchPortfolioInterval = setInterval(() => {
        this.refetchPortfolio();
      }, refetchIntervalSeconds * 1000);
    }

    connectToWebSocket(roomName) {
      const { id } = this.props;

      this.SocketClient.connect(
        config.socketIoKeys.portfolios,
        portfolioData => {
          if (this.mounted && portfolioData.id === Number(id)) {
            this.setState(({ dataFromWebSocket }) => ({
              dataFromWebSocket: {
                ...dataFromWebSocket,
                ...portfolioData
              }
            }));

            this.debouncedFetchPortfolioCashTransactions();
          }
        },
        roomName
      );
    }

    disconnectWebSocket(roomName) {
      this.SocketClient.disconnect(roomName);
    }

    componentDidUpdate(
      prevProps: Readonly<P>,
      prevState: Readonly<S>,
      snapshot: SS
    ): void {
      const { id, getPortfolio } = this.props;

      if (prevProps.id !== id) {
        getPortfolio(id);

        this.setState({ dataFromWebSocket: {} });

        const oldRoomName = this.roomName;
        const newRoomName = this.getRoomName(id);
        this.disconnectWebSocket(oldRoomName);
        this.connectToWebSocket(newRoomName);

        this.roomName = newRoomName;
      }
    }

    componentWillUnmount() {
      this.SocketClient.disconnect(this.roomName);

      clearInterval(this.refetchPortfolioInterval);

      this.mounted = false;
    }

    refetchPortfolio() {
      const { refreshPortfolio } = this.props;

      refreshPortfolio();
    }

    render() {
      const { dataFromWebSocket } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          dataFromWebSocket={dataFromWebSocket}
        />
      );
    }
  }

  return WithPortfolio;
}
