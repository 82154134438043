const filterOutExcludedColumns = (
  configRef: {
    tabs: {
      column_pairs: {
        csv_data_column_first: { attribute_name: string },
        csv_data_column_second: { attribute_name: string }
      }[]
    }[]
  },
  excludedColumnsByAttributeName: string[] = []
) => {
  for (let i = 0; i < configRef?.tabs?.length; i++) {
    const tab = configRef.tabs[i];

    for (let j = 0; j < tab?.column_pairs?.length; j++) {
      const columnPairsRef = tab.column_pairs[j];

      let firstColumn = columnPairsRef?.csv_data_column_first;
      let secondColumn = columnPairsRef?.csv_data_column_second;

      if (
        excludedColumnsByAttributeName.includes(firstColumn?.attribute_name)
      ) {
        columnPairsRef.csv_data_column_first = null;
      }

      if (
        excludedColumnsByAttributeName.includes(secondColumn?.attribute_name)
      ) {
        columnPairsRef.csv_data_column_second = null;
      }
    }
  }
};

export default filterOutExcludedColumns;
