import React from "react";
import { withRouter } from "react-router";
import withRegisterForm from "../../../../../hocs/withRegisterForm";
import BaseRegisterModal from "./default";
import SquaberButton from "../../../common/SquaberButton";
import config from "../../../../../config";

@withRouter
@withRegisterForm
class ExpalertsRegisterModal extends BaseRegisterModal {
  getTitle() {
    return "In order to complete this action you have to sign up.";
  }

  getRegisterForm() {
    return null;
  }

  getRegisterButton(): null {
    const { translate } = this.props;

    return (
      <SquaberButton
        style={{
          marginLeft: "auto"
        }}
        onClick={() => {
          window.open(config.registerUrl, "_blank");
        }}
        color="primary"
      >
        {translate("Register")}
      </SquaberButton>
    );
  }
}

export default ExpalertsRegisterModal;
