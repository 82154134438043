import "../styles/default.scss";

import autoBind from "auto-bind";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withRouter } from "react-router";
import {
  Dropdown,
  DropdownMenu,
  DropdownToggle,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withDeviceType from "../../../../../hocs/withDeviceType";
import withSearchBar from "../../../../../hocs/withSearchBar";
import SearchModal from "../../../modal/SearchModal";
import SearchResults from "../../SearchResults";

@withSearchBar
@withBottomNavigation("navState")
@withDeviceType
@withRouter
class SearchBar extends Component {
  constructor(props: P, context: any) {
    super(props, context);
    autoBind.react(this);

    this.searchInputRef = React.createRef();

    this.state = {
      cursor: 0,
      result: []
    };
  }

  static defaultProps = {
    inContent: false,
    shouldShowSearchBar: true,
    resultUrlPattern: "fe.stockPage"
  };

  componentDidMount() {
    const { navState } = this.props ?? {};

    this.handleNavStateAction(navState);
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    const { navState } = this.props ?? {};
    const { navState: prevNavState } = prevProps ?? {};

    this.handleNavStateAction(navState, prevNavState);
  }

  handleNavStateAction(navState, prevNavState) {
    const hasClosedMobileSearch = prevNavState === "search" && !navState;
    const hasOpenedMobileSearch = !prevNavState && navState === "search";

    if (hasClosedMobileSearch) {
      this.closeDropdown();
    }

    if (hasOpenedMobileSearch) {
      this.openDropdown();
    }
  }

  handleClick(stock) {
    const {
      history,
      locale,
      clear,
      resultUrlPattern,
      additionalUrlFields
    } = this.props;

    history.push(
      UrlProvider.getUrl(resultUrlPattern, {
        locale,
        marketId: stock.market,
        stockId: stock.ticker,
        ...additionalUrlFields
      })
    );

    clear();
    this.closeDropdown();
  }

  handleKeyDown(e) {
    const { stocks, setValue, searchBarOpen } = this.props;
    const { cursor } = this.state;
    if (!searchBarOpen) this.onToggle();
    // arrow up/down button should select next/previous list element
    if (e.keyCode === 38 && cursor > 0) {
      this.setState(prevState => ({
        cursor: prevState.cursor - 1
      }));
    } else if (e.keyCode === 40 && cursor < stocks.length - 1) {
      this.setState(prevState => ({
        cursor: prevState.cursor + 1
      }));
    } else if (e.key === "Enter") {
      const hasStock = stocks[cursor] && stocks[cursor].stock;

      if (!hasStock) return;

      this.handleClick(stocks[cursor].stock);
      setValue("");
      this.closeDropdown();
    }
  }

  onToggle() {
    const { searchBarOpen, setSearchBarOpen } = this.props;
    setSearchBarOpen(!searchBarOpen);
  }

  focusSearchInput() {
    const searchInputFocus = this.searchInputRef.current.focus();
    if (typeof searchInputFocus !== "function") return;

    searchInputFocus();
  }

  openDropdown() {
    const { setSearchBarOpen } = this.props;
    setSearchBarOpen(true, this.focusSearchInput);
  }

  closeDropdown() {
    const { setSearchBarOpen } = this.props;
    setSearchBarOpen(false);
  }

  render() {
    const {
      shouldShowSearchBar,
      inContent,
      searchAction,
      phrase,
      stocks,
      loading: loadingStocks,
      setValue,
      navState,
      translate,
      next,
      fetchMoreSearchResults,
      loadingMore,
      searchAlwaysOpen,
      searchBarOpen
    } = this.props;

    let classes = classNames({
      "search-bar": true,
      "on-search-page": !shouldShowSearchBar,
      "in-content": inContent,
      "always-open": searchAlwaysOpen
    });

    return (
      <div className={classes}>
        <Dropdown isOpen={searchBarOpen} toggle={this.onToggle} inNavbar>
          <DropdownToggle tag="div">
            <InputGroup>
              <Input
                innerRef={this.searchInputRef}
                onKeyDown={this.handleKeyDown}
                onChange={event => searchAction(event.target.value)}
                value={phrase || ""}
                autoComplete="off"
                placeholder={translate(
                  "Enter the company you are interested in - eg KGHM"
                )}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <i className="fal fa-search" />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </DropdownToggle>
          <DropdownMenu
            onScroll={event => {
              if (!next) {
                return;
              }

              let element = event.target;

              if (
                !loadingMore &&
                element.scrollHeight / element.scrollTop > 0.5
              ) {
                fetchMoreSearchResults();
              }
            }}
          >
            {inContent ? (
              <SearchResults
                className={"in-content-results"}
                searchPhrase={phrase}
                loadingResults={loadingStocks}
                loadingMoreResults={loadingMore}
                onResultClick={this.handleClick}
                results={stocks}
              />
            ) : (
              <SearchModal
                isOpen={searchBarOpen}
                onClose={this.closeDropdown}
                results={stocks}
                loadingResults={loadingStocks}
                loadingMoreResults={loadingMore}
                searchPhrase={phrase}
                onStockClick={this.handleClick}
              />
            )}
          </DropdownMenu>
        </Dropdown>
        <div className="button-wrapper" onClick={() => setValue("search")}>
          {navState === "search" ? (
            <i className="fal fa-times" />
          ) : (
            <i className="fal fa-search" />
          )}
        </div>
      </div>
    );
  }
}

SearchBar.propTypes = {
  shouldShowSearchBar: PropTypes.bool,
  searchBarOpen: PropTypes.bool,
  setSearchBarOpen: PropTypes.func,
  inContent: PropTypes.bool,
  searchAction: PropTypes.func,
  phrase: PropTypes.string,
  stocks: PropTypes.arrayOf(PropTypes.object),
  setValue: PropTypes.func,
  history: PropTypes.object,
  locale: PropTypes.string
};

export default SearchBar;
