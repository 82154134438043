import mainLogo from "../assets/expalerts-logo.png";
import lightLogo from "../assets/expalerts-logo-light.png";
import textLogo from "../assets/expalerts-logo-text.png";

export default {
  main: lightLogo,
  dark: mainLogo,
  text: textLogo,
  loginAspectRatio: 2.5
};
