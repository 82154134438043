import React from "react";
import FormWrapper from "../lib/FormBuilder/Core/FormWrapper";

export default function withFormBuilder(fields) {
  return function(WrappedComponent) {
    class WithFormBuilder extends FormWrapper {
      constructor(props): void {
        super(props);

        const fieldsCopy = [
          ...(typeof fields === "function" ? fields(props) : fields)
        ];

        this.state = {
          fields: fieldsCopy,
          canSubmit: FormWrapper.getCanSubmit(fieldsCopy)
        };
      }

      render() {
        const { fields, canSubmit } = this.state;

        return (
          <WrappedComponent
            formBuilder={{
              fields,
              canSubmit,
              handleChange: this.handleChange,
              handleCheckboxChange: this.handleCheckboxChange,
              clear: this.clear
            }}
            {...this.props}
          />
        );
      }
    }

    return WithFormBuilder;
  };
}
