import React from "react";
import ResourceResolver from "../../../../../lib/UniversalTable/ResourceResolver";
import UniversalTableLiteWeb from "../../UniversalTableLiteWeb";
import withUserData from "../../../../../hocs/withUserData";

const IndexComponents = ({ userHasAccess, stocks, translate }) => {
  if (!userHasAccess) {
    return null;
  }

  if (!stocks || !stocks.length) {
    return <div>{translate("This index has no stocks")}</div>;
  }

  return (
    <UniversalTableLiteWeb
      resourceId={ResourceResolver["stocks_list"]}
      defaultSettings={{
        filters: {
          stock_id: stocks
        }
      }}
    />
  );
};

IndexComponents.propTypes = {};

export default withUserData(IndexComponents);
