import React from "react";
import connect from "../i18n/connect";
import {
  bulkFollowRoutine,
  bulkUnFollowRoutine
} from "../redux/modules/followed";

export default function withBulkFollowUnfollow(WrappedComponent) {
  const mapDispatchToProps = {
    bulkFollow: bulkFollowRoutine,
    bulkUnFollow: bulkUnFollowRoutine
  };

  @connect(null, mapDispatchToProps)
  class WithBulkUnfollow extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithBulkUnfollow;
}
