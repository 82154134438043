import React from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind";
import { clearResults, getResultsRoutine } from "../redux/modules/autocomplete";
import { getAutocompleteState } from "../redux/modules/autocomplete/selector";
import { connect } from "../i18n/connect";

export default function withAutocomplete(WrappedComponent) {
  const mapStateToProps = state => ({
    autocompleteState: getAutocompleteState(state)
  });

  const mapDispatchToProps = {
    getResults: getResultsRoutine,
    clearResults
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithAutocomplete extends React.Component {
    static propTypes = {
      source: PropTypes.string
    };

    static defaultProps = {
      source: "app.stocks.searchAutocompleteWithIndex"
    };

    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    getResults(query) {
      const { getResults, source, params } = this.props;

      getResults({ source, query, params });
    }

    render() {
      return <WrappedComponent {...this.props} getResults={this.getResults} />;
    }
  }

  return WithAutocomplete;
}
