import React from "react";
import { fetchFaqQuestionsRoutine } from "../redux/modules/faq";
import { getFaqQuestionsState } from "../redux/modules/faq/selector";
import connect from "../i18n/connect";

export default function withFaq(WrappedComponent) {
  const mapStateToProps = state => ({
    faqQuestionsState: getFaqQuestionsState(state)
  });

  const mapDispatchToProps = {
    fetchFaqQuestions: fetchFaqQuestionsRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithFaq extends React.Component {
    componentDidMount(): void {
      this.props.fetchFaqQuestions();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithFaq;
}
