import { useEffect } from "react";

import withTriggerUserActions from "../../../../../hocs/withUserActions/withTriggerUserActions";

const UserActionsProvider = ({ triggerDefaultUserAction }) => {
  useEffect(() => {
    const handleClick = () => {
      triggerDefaultUserAction();
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  return null;
};

export default withTriggerUserActions(UserActionsProvider);
