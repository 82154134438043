import React from "react";
import PropTypes from "prop-types";
import { Radar } from "react-chartjs-2";
import { compose } from "redux";
import moment from "moment-mini";
import { isEmpty } from "lodash";
import getValueColor from "../../../../../lib/getValueColor";
import withUserData from "../../../../../hocs/withUserData";
import withDeviceType from "../../../../../hocs/withDeviceType";
import withFinancialSituationData from "../../../../../hocs/withFinancialSituationData";
import GaugeChart from "../../GaugeChart";
import Loader from "../../Loader";

import "../styles/default.scss";

const FinancialAnalysis = ({ data, loading, translate, isMobile }) => {
  if (loading || isEmpty(data)) {
    return <Loader />;
  }

  const {
    stock_classification,
    parameters_groups_classification,
    warnings,
    balance_date
  } = data;

  const situationUnknown = stock_classification.classification === "unknown";

  return (
    <div className="financial-analysis">
      <p
        dangerouslySetInnerHTML={{
          __html: translate(
            "The analysis is based on the company's 4 quarterly reports, the latest report taken into account presents the company's <strong>financial condition as at %{report_date}</strong>",
            {
              report_date: moment(balance_date).format("DD.MM.YYYY")
            }
          )
        }}
      ></p>
      <div className="row">
        <div className="col-md-7">
          <div>
            {warnings.map((warning, key) => (
              <p key={key}>{warning}</p>
            ))}
          </div>
        </div>
        <div className="col-md-5">
          <GaugeChart value={stock_classification.value} />
        </div>
      </div>
      {!situationUnknown ? (
        <div className="row flex-md-row-reverse">
          <div className="col-md-6">
            <ul className="groups-classification">
              {parameters_groups_classification.map((parameter, index) => (
                <li key={index}>
                  <span
                    className="result"
                    style={{
                      color: getValueColor(
                        parameter.group_classification.value,
                        null,
                        true
                      )
                    }}
                  >
                    {parameter.group_classification.value}
                  </span>{" "}
                  - {parameter.group_classification.parameter}
                </li>
              ))}
            </ul>
          </div>
          <div className="col-md-6">
            <div>
              <Radar
                height={isMobile ? 320 : null}
                data={{
                  labels: parameters_groups_classification.map(
                    ({ group_classification }) => group_classification.parameter
                  ),
                  datasets: [
                    {
                      backgroundColor: `${getValueColor(
                        stock_classification.value
                      )}66`,
                      data: parameters_groups_classification.map(
                        ({ group_classification }) => group_classification.value
                      )
                    }
                  ]
                }}
                options={{
                  scale: {
                    ticks: {
                      min: 0,
                      max: 5
                    },
                    pointLabels: {
                      fontSize: 12,
                      fontColor: parameters_groups_classification.map(
                        ({ group_classification }) =>
                          getValueColor(group_classification.value, null, true)
                      ),
                      callback: (label, index) => {
                        return parameters_groups_classification[index]
                          .group_classification.value;
                      }
                    }
                  },
                  legend: {
                    display: false
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

FinancialAnalysis.propTypes = {
  data: PropTypes.shape({
    stock_classification: PropTypes.object,
    all_stocks_count: PropTypes.number,
    stock_rank: PropTypes.number,
    parameters_groups_classification: PropTypes.array,
    warnings: PropTypes.array,
    balance_date: PropTypes.string
  })
};

export default compose(
  withFinancialSituationData,
  withUserData,
  withDeviceType
)(FinancialAnalysis);
