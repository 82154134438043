import { handleActions } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

export const START_ACCOUNT_DELETION_PROCESS =
  "app/auth/delete/START_ACCOUNT_DELETION_PROCESS";

export const startAccountDeletionProcess = createRoutine(
  START_ACCOUNT_DELETION_PROCESS
);

const initialState = {
  loading: false,
  error: null
};

export default handleActions(
  {
    [startAccountDeletionProcess.TRIGGER]: state => ({
      ...state,
      loading: true,
      error: null
    }),
    [startAccountDeletionProcess.SUCCESS]: state => ({
      ...state,
      loading: false,
      error: null
    }),
    [startAccountDeletionProcess.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    })
  },
  initialState
);
