import PropTypes from "prop-types";
import React from "react";

import withTranslations from "../../../../../../hocs/withTranslations";

const TransactionType = ({
  resultMetaData: { transaction_type },
  translate
}) => {
  return (
    <div className="transaction-type">
      {translate(`insider_transaction_type_${transaction_type}`)}
    </div>
  );
};

TransactionType.propTypes = {
  resultMetaData: PropTypes.shape({ transaction_type: PropTypes.number })
};

export default withTranslations(TransactionType);
