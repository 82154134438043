import SquaberIntervalTypes from "./SquaberIntervalTypes";

const SquaberIntervalMap = {
  "1": SquaberIntervalTypes.one_minute,
  "5": SquaberIntervalTypes.five_minutes,
  "15": SquaberIntervalTypes.fifteen_minutes,
  "30": SquaberIntervalTypes.thirty_minutes,
  "60": SquaberIntervalTypes.one_hour,
  "240": SquaberIntervalTypes.four_hours,
  D: SquaberIntervalTypes.daily,
  "1D": SquaberIntervalTypes.daily,
  W: SquaberIntervalTypes.weekly,
  "1W": SquaberIntervalTypes.weekly,
  M: SquaberIntervalTypes.monthly,
  "1M": SquaberIntervalTypes.monthly
};

export default SquaberIntervalMap;
