import React from "react";
import prepareEmbeddedLandingPagUrl from '../../../../../lib/prepareEmbeddedLandingPagUrl';
import "../styles/default.scss";

const LandingPage = ({ match: { params } }) => {
  const landingPageUrl = `https://l.squaber.com/${params.slug || ""}/`;

  return (
    <div className="landing-page-iframe-wrapper">
      <iframe
        scrolling="yes"
        seamless="seamless"
        allowFullScreen
        width="100%"
        height="100%"
        src={prepareEmbeddedLandingPagUrl(landingPageUrl)}
      />
    </div>
  );
};

export default LandingPage;
