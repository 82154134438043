import "../styles/default.scss";

import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import classNames from "classnames";
import React from "react";

import withStockTagsModal from "../../../../../hocs/withStockTagsModal";
import withTranslations from "../../../../../hocs/withTranslations";
import SquaberButton from "../../../common/SquaberButton";
import ModalBase from "../../ModalBase";
import CreateNewTag from "../components/CreateNewTag";
import TagsList from "../components/TagsList";

@withStockTagsModal(TagsList, CreateNewTag)
@withTranslations
class StockTagsModal extends ModalBase {
  getClassName() {
    return "stock-tags-modal is-mobile-page-sheet";
  }

  getContent() {
    const {
      translate,
      hideModal,
      tabs,
      getCurrentTabComponent,
      setCurrentTab,
      showCloseButton,
      getTabIsActive,
      stockId
    } = this.props;

    return (
      <React.Fragment>
        <DialogTitle id="interesting-stock-dialog-title">
          {translate("Stock tags")} <i className="far fa-tag" />
          <span className="close-dialog" onClick={() => hideModal()}>
            <i className="fal fa-times" />
          </span>
        </DialogTitle>
        <DialogContent>
          <div className="stock-tags-modal-tabs">
            {tabs.map(tab => (
              <div
                key={tab.key}
                className={classNames({
                  tab: true,
                  active: getTabIsActive(tab.key)
                })}
                onClick={() => {
                  setCurrentTab(tab.key);
                }}
              >
                {translate(tab.label)}
              </div>
            ))}
          </div>
          <div className="stock-tags-modal-current-tab">
            {getCurrentTabComponent({
              stockId,
              afterTagCreated: () => {
                setCurrentTab("tags-list");
              }
            })}
          </div>
          {showCloseButton ? (
            <div className="button-wrapper">
              <SquaberButton fullWidth onClick={() => hideModal()}>
                {translate("Close")}
              </SquaberButton>
            </div>
          ) : null}
        </DialogContent>
      </React.Fragment>
    );
  }
}

StockTagsModal.propTypes = {};

export default StockTagsModal;
