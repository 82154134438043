export const toBackendChartUserData = ({
  chart,
  additionalData
}: {
  chart: string,
  additionalData: {
    settings: { key: string, label: string, value: boolean }[]
  }
}) => {
  return {
    chart: JSON.parse(chart),
    drawn_objects_options: additionalData.settings
  };
};
