import type { ContactEventData } from "../types/SalesManagoEventData.type";
import smEventTypes from "../utils/smEventTypes";
import { BaseEvent } from "./BaseEvent";

interface PaymentMetaData {
  offerId: number;
  offerAmount: number;
  offerPeriod: number;
  button: "SIDE_BUTTON" | "MAIN_BUTTON";
  paymentMethod: string;
}

class GoToPaymentEvent extends BaseEvent {
  constructor(paymentMetaData: PaymentMetaData) {
    super();
    this.paymentMetaData = paymentMetaData;
  }

  getEventName() {
    return "go_to_payment";
  }

  getEventTriggerName() {
    return "button at the /pl/payment-details/ page";
  }

  getEventDetailsMatrix() {
    return `detail5=offer id
detail6=offer amount
detail7=offer period
detail8=button (side button/main button)
detail9=payment method`;
  }

  getContactEventData(): ContactEventData {
    return {
      products: String(this.paymentMetaData.offerId),
      value: Number(this.paymentMetaData.offerAmount),
      contactExtEventType: smEventTypes.CART,
      detail5: String(this.paymentMetaData.offerId),
      detail6: String(this.paymentMetaData.offerAmount),
      detail7: String(this.paymentMetaData.offerPeriod),
      detail8: String(this.paymentMetaData.button),
      detail9: String(this.paymentMetaData.paymentMethod)
    };
  }
}

export default GoToPaymentEvent;
