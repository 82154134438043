import React from "react";
import withTranslations from "../../../../../../hocs/withTranslations";
import TransactionHelper from "../../../../../../lib/UniversalTable/RendererHelpers/TransactionHelper";

const Transaction = ({ resultMetaData, translate }) => {
  const transactionTypeName = TransactionHelper.getTransactionTypeName(
    "stock",
    resultMetaData.type || resultMetaData.transaction_type
  );

  return (
    <div className="transaction-type">
      {transactionTypeName ? translate(transactionTypeName) : ""}
    </div>
  );
};

export default withTranslations(Transaction);
