import React from "react";
import connect from "../i18n/connect";
import {
  getStocksListSource
} from "../redux/modules/tags/selector";
import { updateStocksListSource } from "../redux/modules/tags";

export default function withStocksListFromSource(WrappedComponent) {
  const mapStateToProps = state => ({
    stocksListSource: getStocksListSource(state)
  });

  const mapDispatchToProps = {
    updateStocksListSource
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithStocksListFromSource extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithStocksListFromSource;
}
