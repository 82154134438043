import React from "react";
import { UrlProvider } from "../../../../../../api/UrlProvider";
import withTranslations from "../../../../../../hocs/withTranslations";
import SquaberLink from "../../../SquaberLink";
import SquaberTooltip from "../../../SquaberTooltip/";
import { getTranslatedMarket } from "../../../../../../lib/getTranslatedMarket";

const TickerAsLink = ({
  resultMetaData: { ticker, market, company_name },
  locale,
  marketTranslations
}) => {
  const translatedMarket = getTranslatedMarket(
    locale,
    market,
    marketTranslations
  );
  let content = (
    <SquaberLink
      to={UrlProvider.getUrl("fe.stockPage", {
        locale,
        marketId: translatedMarket,
        stockId: ticker
      })}
    >
      {ticker}
    </SquaberLink>
  );

  if (company_name) {
    content = <SquaberTooltip title={company_name}>{content}</SquaberTooltip>;
  }

  return <div className="ticker-as-link">{content}</div>;
};

export default withTranslations(TickerAsLink);
