export default class CardModelValidator {
  constructor(cardModel) {
    this.cardModel = cardModel;
  }

  fields = [
    "card_code",
    "card_number",
    "expiration_month",
    "expiration_year",
    "name_on_card"
  ];

  validations = {
    card_code: this.validateCardCode,
    card_number: this.validateCardNumber,
    expiration_month: this.validateExpirationMonth,
    expiration_year: this.validateExpirationYear,
    name_on_card: this.validateNameOnCard
  };

  validateCardNumber(value) {
    return value.length === 16 && /\D/g.test(value) === false;
  }

  validateCardCode(value) {
    return !!value && value.length <= 3 && /\D/g.test(value) === false;
  }

  validateExpirationMonth(value) {
    return value.length === 2 && /\D/g.test(value) === false;
  }

  validateExpirationYear(value) {
    return value.length === 4 && /\D/g.test(value) === false;
  }

  validateNameOnCard(value) {
    return value.length > 0;
  }

  validate() {
    let isValid = true;

    for (let i = 0; i < this.fields.length; i++) {
      const field = this.fields[i];

      isValid = this.validations[field](this.cardModel[field]);

      if (!isValid) {
        break;
      }
    }

    return isValid;
  }
}
