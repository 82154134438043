import "../styles/default.scss";

import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import withCurrrentAnalyses from "../../../../../hocs/withCurrrentAnalyses";
import withUserData from "../../../../../hocs/withUserData";
import Loader from "../../Loader";
import Analysis from "../components/Analysis";

@withUserData
@withCurrrentAnalyses
class StockAnalyses extends React.Component {
  static propTypes = {
    analysesData: PropTypes.arrayOf(
      PropTypes.shape({
        active: PropTypes.bool.isRequired,
        author: PropTypes.shape({
          first_name: PropTypes.string,
          job_title: PropTypes.string,
          last_name: PropTypes.string
        }).isRequired,
        category: PropTypes.number.isRequired,
        content: PropTypes.string,
        created: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        stock: PropTypes.shape({
          currency: PropTypes.string.isRequired,
          id: PropTypes.number.isRequired,
          is_index: PropTypes.bool,
          market: PropTypes.string.isRequired,
          name: PropTypes.string.isRequired,
          pipsize: PropTypes.string.isRequired,
          recommended: PropTypes.bool.isRequired,
          short_name: PropTypes.string.isRequired,
          ticker: PropTypes.string.isRequired
        }).isRequired,
        updated: PropTypes.string.isRequired
      }).isRequired
    )
  };

  render() {
    const { analysesData, data } = this.props;
    const categoryDescription = data.description;

    return (
      <div
        className={classNames({
          "stock-analyses": true
        })}
      >
        {analysesData.length > 0 ? (
          analysesData.map((analysis, index) => (
            <Analysis
              analysis={analysis}
              key={analysis.id}
              index={index}
              categoryDescription={categoryDescription}
            />
          ))
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

export default StockAnalyses;
