import classNames from "classnames";
import { isEqual } from "lodash";
import React, { useMemo, useState } from "react";

import ShowMoreSubmenuLink from "./ShowMoreSubmenuLink";
import SubmenuLink from "./SubmenuLink";

function SubmenuLinks({
  nestedSubmenu,
  onOpenNestedSubmenu,
  onCloseNestedSubmenu,
  links = [],
  defaultShowedLinks = 10
}) {
  const [showMore, setShowMore] = useState(false);

  const linksToRender = useMemo(() => {
    let toRender = [];
    let limit = showMore
      ? links.length
      : Math.min(links.length, defaultShowedLinks);

    for (let i = 0; i < limit; i++) {
      const props = links[i];
      const { label: childLabel } = props;
      const isActive = isEqual(props, nestedSubmenu);

      const link = (
        <li key={childLabel}>
          <SubmenuLink
            {...props}
            onEnter={async () => await onOpenNestedSubmenu(props)}
            onLeave={onCloseNestedSubmenu}
            active={isActive}
          />
        </li>
      );

      toRender.push(link);
    }

    if (links.length > defaultShowedLinks) {
      toRender.push(
        <ShowMoreSubmenuLink showMore={showMore} setShowMore={setShowMore} />
      );
    }

    return toRender;
  }, [
    nestedSubmenu,
    defaultShowedLinks,
    links,
    showMore,
    onOpenNestedSubmenu,
    onCloseNestedSubmenu
  ]);

  return (
    <ul
      className={classNames("submenu__list", { submenu__list_more: showMore })}
    >
      {linksToRender}
    </ul>
  );
}

export default SubmenuLinks;
