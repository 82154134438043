import React from "react";
import { Link } from "react-router-dom";

import connect from "../../../../../../i18n/connect";
import PlayStore from "../assets/store_download_button.svg";
import Apple from "../assets/store_download_button_1.svg";

export const StoreButton = ({ type, translate }) => {
  if (type === "playstore") {
    return (
      <Link
        className={"flex-1"}
        to={translate("lp_store-button_play-store_href")}
      >
        <img
          className={"w-full"}
          src={PlayStore}
          alt={translate("lp_store-button_play-store_alt")}
        />
      </Link>
    );
  }

  return (
    <Link className={"flex-1"} to={translate("lp_store-button_ios_href")}>
      <img
        className={"w-full"}
        src={Apple}
        alt={translate("lp_store-button_ios_alt")}
      />
    </Link>
  );
};

export default connect()(StoreButton);
