import "../styles/default.scss";

import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import Price from "../../../../../common/Price";
import withPortfoliosStock from "../../../../../hocs/withPortfoliosStock";
import withTranslations from "../../../../../hocs/withTranslations";
import HistoryWithStockAddPortfolio from "../../HistoryWithStockAddPortfolio";
import HistoryWithStockAddToPortfolio from "../../HistoryWithStockAddToPortfolio";
import Loader from "../../Loader";
import SquaberLink from "../../SquaberLink";

const HistoryWithStock = ({
  currency,
  translate,
  locale,
  stock,
  quote,
  portfoliosStockState
}) => {
  const portfolio = portfoliosStockState.data;
  const loading = !portfoliosStockState?.data || portfoliosStockState.loading;

  if (loading) {
    return <Loader small />;
  }

  return portfolio.has_portfolios ? (
    <div className="history-with-stock">
      {portfolio.results.length ? (
        <ul>
          {portfolio.results.map((result, index) => {
            if (!result.is_closed) {
              return (
                <li key={index}>
                  {translate("%{shares} shares are in", {
                    shares: result.shares
                  })}{" "}
                  <SquaberLink
                    to={UrlProvider.getUrl("fe.portfolio", {
                      locale,
                      id: result.portfolio_id
                    })}
                  >
                    {result.name}
                  </SquaberLink>
                  .{" "}
                  {translate(
                    "Your current result of that investment is %{rate_of_return} (%{result})",
                    {
                      rate_of_return: new Price({
                        value: result.rate_of_return * 100,
                        currency: "%",
                        roundToSecondNumber: true
                      }).render(),
                      result: new Price({
                        value: result.result,
                        currency
                      }).render()
                    }
                  )}
                </li>
              );
            } else {
              return (
                <li key={index}>
                  {translate(
                    "You had shares of this stock for %{days} days in",
                    {
                      days: result.days
                    }
                  )}{" "}
                  <SquaberLink
                    to={UrlProvider.getUrl("fe.portfolio", {
                      locale,
                      id: result.portfolio_id
                    })}
                  >
                    {result.name}
                  </SquaberLink>
                  .{" "}
                  {translate(
                    "Your result of that position was %{rate_of_return}. Since the date of sale exchange rate changed to %{change}",
                    {
                      portfolio_name: result.name,
                      rate_of_return: new Price({
                        value: result.rate_of_return * 100,
                        currency: "%",
                        roundToSecondNumber: true
                      }).render(),
                      change: new Price({
                        value: result.change * 100,
                        currency: "%",
                        roundToSecondNumber: true
                      }).render()
                    }
                  )}
                </li>
              );
            }
          })}
        </ul>
      ) : null}
      <HistoryWithStockAddToPortfolio
        currency={currency}
        stock={stock}
        quote={quote}
        showDescription={portfolio.results.length === 0}
      />
    </div>
  ) : (
    <HistoryWithStockAddPortfolio />
  );
};

HistoryWithStock.propTypes = {
  portfolio: PropTypes.shape({
    results: PropTypes.arrayOf(
      PropTypes.shape({
        change: PropTypes.any,
        rate_of_return: PropTypes.number,
        shares: PropTypes.number,
        name: PropTypes.string,
        days: PropTypes.number,
        is_closed: PropTypes.bool,
        result: PropTypes.number
      })
    ),
    has_portfolios: PropTypes.bool
  }),
  currency: PropTypes.string
};

export default compose(withTranslations, withPortfoliosStock)(HistoryWithStock);
