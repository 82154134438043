import React from "react";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import withUserData from "../../../../../hocs/withUserData";
import ContentSection from "../../ContentSection";
import SquaberButton from "../../SquaberButton";

import "../styles/default.scss";

const NoPortfolios = ({ translate, userData }) => (
  <ContentSection className="no-portfolios-information">
    <h1>{translate("Portfolio")}</h1>
    <p>
      {translate(
        "You don't have any portfolios yet. Add your first portfolio and start observing the behavior of selected groups of companies. Thanks to this functionality you will check exactly which companies are profitable and which have lost and you will learn detailed portfolio statistics prepared by Squaber.com"
      )}
    </p>
    <div className="buttons-wrapper">
      <SquaberButton
        onClick={() => {
          if (userData) {
            showModal("PortfolioDetailsModal", {
              title: translate("Add portfolio"),
              portfolioData: {
                name: "Portfolio 1",
                description: ""
              },
              action: "add"
            });
          } else {
            showModal("RegisterModal");
          }
        }}
      >
        {translate("Add portfolio")}
      </SquaberButton>
    </div>
  </ContentSection>
);

export default withUserData(NoPortfolios);
