const apiHostName = "app.expalerts.com";

module.exports = {
  apiHostName: `https://${apiHostName}/`,
  apiHostNameWithoutTrailingSlash: `https://${apiHostName}`,
  apiUrl: `https://${apiHostName}/:locale/api/`,
  firebase: {
    authDomain: "investio-backup.firebaseapp.com",
    databaseURL: "https://expalerts-com.firebaseio.com/",
    storageBucket: "investio-backup.appspot.com"
  },
  websocketUrl: "https://app.expalerts.com/",
  defaultPremiumPopupUrl: "https://l.squaber.com/pop-up-premium/",
  tradingViewOptions: {
    hasIntraday: true,
    intervals: ["1", "5", "15", "30", "60", "240", "D", "W", "M"]
  },
  quoteConvertersInUse: false,
  registerUrl: "https://expregister.com/register"
};
