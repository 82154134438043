import React from "react";
import { UrlProvider } from "../../../../../api/UrlProvider";
import withTranslations from "../../../../../hocs/withTranslations";
import SquaberLink from "../../SquaberLink";
import TagsHelper from "../../../../../lib/TagsHelper/TagsHelper";

const DesktopHeading = ({ tag, translate, locale }) => {
  const isUserTag = TagsHelper.isUserTag(tag);
  const isPortfolioTag = TagsHelper.isPortfolioTag(tag);

  return (
    <div className="desktop-details-head">
      <div className="heading">
        <h1>{tag.name}</h1>
        {isPortfolioTag ? (
          <SquaberLink
            to={UrlProvider.getUrl("fe.portfolio", {
              id: tag.automated_tag_object_id,
              locale
            })}
          >
            {translate("Go to portfolio")}
          </SquaberLink>
        ) : null}
      </div>
      <p className="description">{tag.description}</p>
      {isUserTag ? (
        <SquaberLink
          className="settings-trigger"
          to={UrlProvider.getUrl("fe.tagSettings", {
            locale,
            id: tag.id
          })}
        >
          <span>{translate("Manage tag")}</span>
          <i className="fas fa-cog" />
        </SquaberLink>
      ) : null}
    </div>
  );
};

export default withTranslations(DesktopHeading);
