import React from "react";
import { connect } from "../i18n/connect";
import { actions as notificationsActions } from "../redux/modules/alerts";
import { getCurrentAlert, getAlerts } from "../redux/modules/alerts/selector";

export default function withAlerts(WrappedComponent) {
  const mapStateToProps = state => ({
    alerts: getAlerts(state),
    currentAlert: getCurrentAlert(state)
  });

  const mapDispatchToProps = {
    attemptPopAlert: notificationsActions.attemptPopAlert
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithAlerts extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithAlerts;
}
