import React from "react";
import { connect } from "../i18n/connect";
import { getMarketingBarsSeen } from "../redux/modules/marketingBarsSeen/selector";
import {
  setMarketingBarSeen,
  unsetMarketingBarSeen
} from "../redux/modules/marketingBarsSeen";

export default function withMarketingBarsSeen(WrappedComponent) {
  const mapStateToProps = state => ({
    marketingBarsSeen: getMarketingBarsSeen(state)
  });

  const mapDispatchToProps = {
    setMarketingBarSeen,
    unsetMarketingBarSeen
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithMarketingBarsSeen extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithMarketingBarsSeen;
}
