import React from "react";
import classNames from "classnames";
import withTranslations from "../../../../../hocs/withTranslations";

import "../rendererStyles/Boolean.scss";

const Boolean = ({ value, format, translate }) => (
  <div
    className={classNames({
      "boolean-renderer": true,
      [String(value)]: format !== "boolean_text"
    })}
  >
    {format === "boolean_text" ? (
      translate(String(value))
    ) : (
      <i
        className={classNames({
          fas: true,
          "fa-times-circle": !value,
          "fa-check-circle": value
        })}
      />
    )}
  </div>
);

export default withTranslations(Boolean);
