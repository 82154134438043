import React from "react";
import Formsy from "formsy-react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import withResetPasswordHandler from "../../../../../hocs/withResetPasswordHandler";
import SquaberButton from "../../../common/SquaberButton";
import PageTitle from "../../../common/PageTitle";

const ResetPasswordPage = ({
  formBuilder,
  executeResetPassword,
  translate
}) => (
  <div className="login-form register-wrapper">
    <PageTitle title={translate("Reset password")} />
    <Row>
      <Col lg={{ size: 6, offset: 3 }}>
        <Card className={"login-wrapper"}>
          <CardHeader>{translate("Reset password")}</CardHeader>
          <CardBody>
            <Formsy
              onValidSubmit={() => {
                executeResetPassword(formBuilder.fields);
              }}
            >
              <FormBuilderWeb {...formBuilder} />
              <SquaberButton
                color="primary"
                type="submit"
                disabled={!formBuilder.canSubmit}
              >
                <span>{translate("Submit")}</span>
              </SquaberButton>
            </Formsy>
          </CardBody>
        </Card>
      </Col>
    </Row>
  </div>
);

export default withResetPasswordHandler(ResetPasswordPage);
