import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";

export const fetchStockRecommendations = createRoutine(
  "app/stockRecommendations/FETCH_RECOMMENDATIONS"
);
export const fetchMoreStockRecommendations = createRoutine(
  "app/stockRecommendations/FETCH_MORE_RECOMMENDATIONS"
);

const initialState = {
  data: [],
  more_recommendations: false,
  loading: false,
  moreLoading: false,
  error: null
};

export default handleActions(
  {
    [fetchStockRecommendations.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [fetchStockRecommendations.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload.recommendations,
      more_recommendations: payload.more_recommendations
    }),
    [fetchMoreStockRecommendations.TRIGGER]: state => ({
      ...state,
      moreLoading: true
    }),
    [fetchMoreStockRecommendations.SUCCESS]: (state, { payload }) => ({
      ...state,
      data: [...state.data, ...payload.recommendations],
      more_recommendations: payload.more_recommendations,
      moreLoading: false
    })
  },
  initialState
);
