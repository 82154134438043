import React from "react";
import SquaberButton from "../../SquaberButton";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import withTranslations from "../../../../../hocs/withTranslations";

const HistoryWithStockAddPortfolio = ({
  translate,
  forSpecificCurrency = false
}) => {
  return (
    <div>
      <p>
        {translate(
          forSpecificCurrency
            ? "You do not have any portfolios for this currency. Create it now and start analysing group of stocks."
            : "You do not have any portfolios. Create your first portfolio and start analysing group of stocks."
        )}
      </p>
      <SquaberButton
        onClick={() => {
          showModal("PortfolioDetailsModal", {
            title: translate("Add portfolio"),
            portfolioData: {
              name: "Portfolio 1",
              description: ""
            },
            action: "add"
          });
        }}
      >
        {translate("Add portfolio")}
      </SquaberButton>
    </div>
  );
};

HistoryWithStockAddPortfolio.propTypes = {};

export default withTranslations(HistoryWithStockAddPortfolio);
