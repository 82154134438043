const apiHostName = "expalerts.squaber.com";

module.exports = {
  apiHostName: `http://${apiHostName}/`,
  apiUrl: `https://${apiHostName}/:locale/api/`,
  firebase: {
    authDomain: "investio-backup.firebaseapp.com",
    databaseURL: "https://expalerts-staging.firebaseio.com/",
    storageBucket: "investio-backup.appspot.com"
  },
  websocketUrl: "http://localhost:3000",
  tradingViewOptions: {
    hasIntraday: false,
    intervals: ["D", "W", "M"]
  },
  quoteConvertersInUse: false,
  defaultPremiumPopupUrl: "https://l.squaber.com/pop-up-premium/",
  native_tv_enabled: false,
  webAppUrl: "http://192.168.1.11:3000"
};
