export const getFollowedStocks = state => {
  if (state.followed.data) {
    return state.followed.data.stocks.filter(
      stock => stock.user_stock.alerted === true
    );
  }

  return [];
};

export const getFollowedStocksLoading = state => {
  return state.followed.loading;
};

export const getUserStocks = state => state.followed.data;

export const getShouldRenderFollowedStocks = state =>
  state.followed.shouldRenderFollowedStocks;

export const getFollowedStocksMap = state => state.followed.followedStocksMap;

export const getAlertedTagDetails = state => state.followed.alertedTagDetails;

export const getStockIsFollowed = (state, stockId) => {
  const followedStocksMap = getFollowedStocksMap(state);

  return (
    followedStocksMap[stockId] &&
    followedStocksMap[stockId].active &&
    followedStocksMap[stockId].alerted
  );
};
