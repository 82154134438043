import { isArray, isObject } from "lodash";

const mainSeriesTypeName = "MainSeries";

const getMainSeries = chartData => {
  const chartPanes = chartData?.chart?.charts?.[0]?.panes;

  if (!isArray(chartPanes)) return;

  const sourcesWithMainSeries =
    chartPanes.find(({ sources }) => {
      if (!isArray(sources)) return false;

      const mainSeries = sources.find(
        ({ type }) => type === mainSeriesTypeName
      );
      return isObject(mainSeries);
    }).sources ?? [];

  if (!isArray(sourcesWithMainSeries)) return;

  return sourcesWithMainSeries.find(({ type }) => type === mainSeriesTypeName);
};

export default getMainSeries;
