import React from "react";
import withFormBuilder from "./withFormBuilder";

export default function withAffiliateProgramForm(WrappedComponent) {
  const fields = [
    {
      name: "affiliate-terms",
      selected: [],
      type: "checkbox",
      required: true,
      options: [
        {
          label: "I agree to terms and conditions of affiliate program.",
          value: "terms"
        }
      ]
    }
  ];

  @withFormBuilder(fields)
  class WithAffiliateProgramForm extends React.Component {
    state = {};

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithAffiliateProgramForm;
}
