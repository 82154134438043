import autoBind from "auto-bind";
import React from "react";

import connect from "../i18n/connect";
import { deleteTransactionRoutine } from "../redux/modules/portfolios";

export const DIVIDEND_TRANSACTION_TYPE = 3;
export const DIVIDEND_TRANSACTION_TYPE_NAME = "DividendPayout";

export default function withPortfolioTransactionHistoryTable(WrappedComponent) {
  const mapDispatchToProps = {
    deleteTransaction: deleteTransactionRoutine
  };

  @connect(null, mapDispatchToProps)
  class WithPortfolioTransactionHistoryTable extends React.Component {
    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    deleteTransaction(transaction) {
      const { deleteTransaction, portfolioId } = this.props;

      deleteTransaction({ portfolioId, transaction });
    }

    render() {
      const { translate } = this.props;

      return (
        <WrappedComponent
          {...this.props}
          deleteTransaction={this.deleteTransaction}
          transactionTypes={{
            cash: {
              1: translate("Deposit"),
              2: translate("Withdraw")
            },
            stock: {
              1: translate("Buy"),
              2: translate("Sell"),
              [DIVIDEND_TRANSACTION_TYPE]: translate("Dividend")
            }
          }}
        />
      );
    }
  }

  return WithPortfolioTransactionHistoryTable;
}
