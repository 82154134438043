import React from "react";
import PropTypes from "prop-types";
import { checkAccessTime } from "../lib/access/time";
import config from "../config";
import { UrlProvider } from "../api/UrlProvider";

class PaymentAlertAbstract extends React.Component {
  static propTypes = {
    paymentDetails: PropTypes.shape({
      end_date: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
    })
  };

  state = {
    accessTime: null
  };

  paymentDetails = null;
  refreshInterval = null;
  dataRefreshTimeout = null;

  componentDidUpdate() {
    const { paymentDetails, userData } = this.props;

    this.paymentDetails = paymentDetails;

    if (
      paymentDetails.payment_menthod_in_verification &&
      !this.dataRefreshTimeout
    ) {
      this.dataRefreshTimeout = setTimeout(() => {
        this.refreshPaymentDetails();
        this.dataRefreshTimeout = null;
      }, 2000);
    }

    if (
      !this.refreshInterval &&
      paymentDetails &&
      !paymentDetails.payment_menthod_in_verification
    ) {
      this.refreshInterval = setInterval(() => {
        this.paymentDetails.end_date_in_seconds -= 1;
        this.setState({
          accessTime: checkAccessTime(this.paymentDetails, userData)
        });
      }, 1000);
    }
  }

  componentWillUnmount() {
    clearInterval(this.refreshInterval);
  }

  refreshPaymentDetails() {
    this.props.fetchPaymentDetails();
  }

  getLinkUrl() {
    const {
      globalConfig: { offerUrl }
    } = this.props;
    const { accessTime } = this.state;

    if (accessTime.paymentMethodInVerification) {
      return "#!";
    }

    return offerUrl || config.defaultPremiumOfferUrl;
  }

  getMessage() {
    const { translate } = this.props;
    const { accessTime } = this.state;

    if (accessTime.paymentMethodInVerification) {
      return translate("Your payment method is being verified.");
    } else if (accessTime.noPremiumAccess) {
      return translate(
        "You are using a free version of Squaber. See all benefits from the Premium version"
      );
    } else if (accessTime.expired) {
      return translate(
        "You are using a free version of Squaber. Return to the Premium version"
      );
    } else if (accessTime.days > 0) {
      return translate(
        Number(accessTime.days) !== 1
          ? "Your premium access will expire in %{days} days."
          : "Your premium access will expire in 1 day",
        accessTime
      );
    } else {
      return translate("Renew your premium access. %{time} left", accessTime);
    }
  }
}

export default PaymentAlertAbstract;
