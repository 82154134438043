import { isString } from "lodash";
import { createAction, handleActions } from "redux-actions";
import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const fetchChartTemplatesRoutine = createRoutine(
  "app/chartTemplates/FETCH_CHART_TEMPLATES"
);
export const fetchChartTemplateDetailsRoutine = createRoutine(
  "app/chartTemplates/FETCH_CHART_TEMPLATE_DETAILS"
);
export const addChartTemplateRoutine = createRoutine(
  "app/chartTemplates/ADD_CHART_TEMPLATE"
);
export const updateChartTemplateRoutine = createRoutine(
  "app/chartTemplates/UPDATE_CHART_TEMPLATE"
);

export const removeChartTemplateRoutine = createRoutine(
  "app/chartTemplates/REMOVE_CHART_TEMPLATE"
);

export const fetchChartTemplateDetailsRoutinePromiseCreator = promisifyRoutine(
  fetchChartTemplateDetailsRoutine
);

export const addChartTemplateRoutinePromiseCreator = promisifyRoutine(
  addChartTemplateRoutine
);

export const CLEAR_ERROR_MESSAGES = "app/chartTemplates/CLEAR_ERROR_MESSAGES";

export const clearErrorMessages = createAction(
  CLEAR_ERROR_MESSAGES,
  () => ({})
);

export const initialState = {
  list: null,
  loading: false,
  error: false,
  defaultTemplate: null,
  addChartTemplateErrors: null
};

export default handleActions(
  {
    [fetchChartTemplatesRoutine.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [fetchChartTemplatesRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      list: payload.templatesList,
      defaultTemplate: payload.defaultTemplate
    }),
    [fetchChartTemplatesRoutine.FAILURE]: state => ({
      ...state,
      loading: false,
      list: []
    }),
    [addChartTemplateRoutine.FAILURE]: (state, { payload }) => {
      let error = null;

      if (isString(payload)) {
        error = { validation: [{ value: payload }] };
      }

      return {
        ...state,
        addChartTemplateErrors: error
      };
    },
    [CLEAR_ERROR_MESSAGES]: state => ({
      ...state,
      addChartTemplateErrors: null
    })
  },
  initialState
);
