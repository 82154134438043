import { clone } from "lodash";
import React from "react";

class TagsListWithSwitchersAbstract extends React.Component {
  state = {
    latestTagsStatus: {}
  };

  setLatestTagStatus(tagId, value) {
    const { latestTagsStatus } = this.state;

    latestTagsStatus[tagId] = value;

    this.setState({ latestTagsStatus });
  }

  getTagIsActive(tag) {
    const { stockId } = this.props;
    const { latestTagsStatus } = this.state;

    let tagIsActive =
      tag.stocks && tag.stocks.length
        ? tag.stocks.indexOf(stockId) !== -1
        : false;

    if (typeof latestTagsStatus[tag.id] !== "undefined") {
      tagIsActive = latestTagsStatus[tag.id];
    }

    return tagIsActive;
  }

  onTagChange(tag, ticker) {
    const {
      connectTagToStockRoutinePromiseCreator,
      disconnectTagFromStockRoutinePromiseCreator,
      stockId,
      updateTag
    } = this.props;

    const tagIsActive = this.getTagIsActive(tag);

    if (!tagIsActive) {
      connectTagToStockRoutinePromiseCreator({
        tagId: tag.id,
        stockId,
        shouldRefreshStockPageTags: true,
        ticker
      }).then(() => {
        const tagStocks = clone(tag?.stocks ?? []);

        tagStocks.push(stockId);

        updateTag({
          id: tag.id,
          stocks: tagStocks
        });
      });
    } else {
      disconnectTagFromStockRoutinePromiseCreator({
        tagId: tag.id,
        stockId,
        shouldRefreshStockPageTags: true,
        ticker
      }).then(() => {
        const tagStocks = clone(tag.stocks);

        const stockIdIndex = tagStocks.findIndex(stock => stock === stockId);

        if (stockIdIndex !== -1) {
          tagStocks.splice(stockIdIndex, 1);
        }

        updateTag({
          id: tag.id,
          stocks: tagStocks
        });
      });
    }

    this.setLatestTagStatus(tag.id, !tagIsActive);
  }
}
export default TagsListWithSwitchersAbstract;
