import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { compose } from "redux";
import { Redirect } from "react-router";
import qs from "qs";
import withLoginForm from "../../../../../hocs/withLoginForm";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import SquaberButton from "../../../common/SquaberButton";
import PageTitle from "../../../common/PageTitle";
import SquaberLink from "../../../common/SquaberLink";
import { UrlProvider } from "../../../../../api/UrlProvider";
import config from "../../../../../config";
import withUserData from "../../../../../hocs/withUserData";

const SocialLogin = lazy(() => import("../../../auth/SocialLogin"));

import "../styles/default.scss";

class LoginForm extends React.Component {
  state = {
    loginWasExecuted: false
  };

  componentDidUpdate() {
    if (this.props.loginLoading && !this.state.loginWasExecuted) {
      this.setState({
        loginWasExecuted: true
      });
    }
  }

  render() {
    const {
      loginLoading,
      locale,
      formBuilder,
      login: executeLogin,
      translate,
      userData
    } = this.props;

    const { loginWasExecuted } = this.state;

    if (!loginWasExecuted && userData) {
      return <Redirect to={"/"} />;
    }

    const redirectTo = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    }).redirectTo;

    return (
      <div className="login-form">
        <PageTitle title={translate("Log in")} />
        <Row>
          <Col lg={{ size: config.vendor === "squaber" ? 6 : 10, offset: 1 }}>
            <Card className={"login-wrapper"}>
              <CardHeader>
                {translate("Log in")}
                <SquaberLink
                  target={config.vendor === "squaber" ? "_self" : "_blank"}
                  to={
                    redirectTo
                      ? UrlProvider.getUrl("fe.registerWithRedirect", {
                          locale,
                          redirectUrl: redirectTo
                        })
                      : UrlProvider.getUrl("fe.register", { locale })
                  }
                >
                  <div className="register-link">
                    <span className="first-part">
                      {translate("You dont have an account yet?")}
                    </span>
                    <span className="second-part">
                      {translate("Register for free")}
                      <i className="fas fa-angle-double-right" />
                    </span>
                  </div>
                </SquaberLink>
              </CardHeader>
              <CardBody>
                <Formsy
                  onValidSubmit={() => {
                    executeLogin(formBuilder.fields);
                  }}
                >
                  <FormBuilderWeb
                    {...formBuilder}
                    fields={formBuilder.fields.filter(
                      field => field.name !== "rememberme"
                    )}
                  />
                  <SquaberButton
                    className="reset-password-link"
                    onClick={() => {
                      showModal("ResetPasswordModal");
                    }}
                    buttonStyle={false}
                  >
                    {translate("Forgot password?")}
                  </SquaberButton>
                  <div className="actions">
                    <div className="action-box">
                      <FormBuilderWeb
                        {...formBuilder}
                        fields={formBuilder.fields.filter(
                          field => field.name === "rememberme"
                        )}
                      />
                    </div>
                    <div className="action-box">
                      <SquaberButton
                        type="submit"
                        disabled={!formBuilder.canSubmit || loginLoading}
                      >
                        <span>{translate("Log in")}</span>
                        {loginLoading ? (
                          <i
                            style={{ marginLeft: 10 }}
                            className="fal fa-spinner fa-spin"
                          />
                        ) : null}
                      </SquaberButton>
                    </div>
                  </div>
                </Formsy>
              </CardBody>
            </Card>
          </Col>
          {config.vendor === "squaber" ? (
            <Col lg={{ size: 4, offset: 0 }}>
              <Card className={"login-wrapper register-wrapper"}>
                <CardHeader>{translate("Or use social account")}</CardHeader>
                <CardBody>
                  <Suspense fallback={<div />}>
                    <SocialLogin />
                  </Suspense>
                </CardBody>
              </Card>
            </Col>
          ) : null}
        </Row>
      </div>
    );
  }
}

LoginForm.propTypes = {
  formBuilder: PropTypes.object,
  login: PropTypes.func,
  loginLoading: PropTypes.bool
};

export default compose(withLoginForm, withUserData)(LoginForm);
