import React from "react";
import PropTypes from "prop-types";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import SquaberButton from "../../SquaberButton";
import withTranslations from "../../../../../hocs/withTranslations";

const PortfolioCashAddTransaction = ({
  translate,
  formBuilder,
  loading,
  validationErrors,
  clearValidationError
}) => {
  return (
    <tr className="portfolio-add-transaction">
      <td>
        <FormBuilderWeb
          {...formBuilder}
          fields={formBuilder.fields.filter(field => field.name === "type")}
          validationErrors={validationErrors}
          clearValidationError={clearValidationError}
          className="select-wrapper"
        />
      </td>
      <td>
        <FormBuilderWeb
          {...formBuilder}
          fields={formBuilder.fields.filter(field => field.name === "date")}
          validationErrors={validationErrors}
          clearValidationError={clearValidationError}
        />
      </td>
      <td>
        <FormBuilderWeb
          {...formBuilder}
          fields={formBuilder.fields.filter(field => field.name === "amount")}
          validationErrors={validationErrors}
          clearValidationError={clearValidationError}
        />
      </td>
      <td>
        <SquaberButton type="submit" disabled={loading}>
          <span>{translate("Add")}</span>
          {loading ? (
            <>
              {" "}
              <i className="fal fa-spinner fa-spin" />
            </>
          ) : null}
        </SquaberButton>
      </td>
    </tr>
  );
};

export default withTranslations(PortfolioCashAddTransaction);
