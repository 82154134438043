import { takeLatest, fork, put } from "redux-saga/effects";
import { contactFormRoutine } from "./index";
import { actions as alertsActions } from "../alerts";
import ApiClient from "../../../api/ApiClient";

function* sendContactForm({ payload: { data, form } }) {
  try {
    const { data: responseData } = yield ApiClient.post({
      urlPath: "app.contact.send",
      data
    });

    yield put(
      alertsActions.addSuccessAlert({
        title: responseData.message
      })
    );
    form.reset();
  } catch (error) {
    if (error.request) {
      const response = JSON.parse(error.request.response);
      yield put(contactFormRoutine.failure());
      yield put(
        alertsActions.addErrorAlert({
          title: response.message
        })
      );
    }
  }
}

function* watchSendContactForm() {
  yield takeLatest(contactFormRoutine.TRIGGER, sendContactForm);
}

export default [fork(watchSendContactForm)];
