import classNames from "classnames";
import React from "react";
import MultiSelect from "react-multi-select-component";

import SingleValueFilterAbstract from "../../../../../../../abstract/SingleValueFilterAbstract";
import withTranslations from "../../../../../../../hocs/withTranslations";

@withTranslations
class SingleValueFilter extends SingleValueFilterAbstract {
  state = {
    isOpen: false
  };

  getOptionPrefix(column: { values_type: string, attribute_name: string }) {
    if (column.values_type !== "generated_in_frontend") {
      return;
    }

    switch (column.attribute_name) {
      case "transaction_type": {
        return `insider_transaction_type_`;
      }
    }
  }

  render() {
    const { column, value, translate } = this.props;
    const { isOpen } = this.state;

    const optionPrefix = this.getOptionPrefix(column);

    const options = this.mapOptionsList(column.filter_data, {
      labelPrefix: optionPrefix
    });
    const calculatedValue = this.mapOptionsList(value, {
      labelPrefix: optionPrefix
    });

    return (
      <div
        className={classNames({
          "squaber-multi-select": true,
          "filters-item-wrapper": true,
          "half-width": true,
          "show-label": isOpen || (value && value.length)
        })}
      >
        <div className="label">{column.label}</div>
        <MultiSelect
          options={options}
          value={calculatedValue}
          onChange={value => this.handleChange(value)}
          labelledBy={column.label}
          hasSelectAll={false}
          disableSearch
          onMenuToggle={isOpen => {
            this.setState({ isOpen });
          }}
          overrideStrings={{
            selectSomeItems: column.label,
            allItemsAreSelected: translate("All items are selected"),
            selectAll: translate("Select All"),
            search: translate("Search")
          }}
        />
      </div>
    );
  }
}

export default SingleValueFilter;
