import Formsy from "formsy-react";
import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import FormsyComposedTextField from "../../../../../lib/FormBuilder/FormsyMaterialUI/FormsyComposedTextField";
import SquaberButton from "../../SquaberButton";

@withTranslations
class AddTemplate extends React.Component {
  state = {
    inputValue: ""
  };

  componentWillUnmount() {
    this.props.clearErrorMessages();
  }

  setInputValue(value) {
    this.setState({
      inputValue: value
    });
  }

  render() {
    const {
      toggleMode,
      addChartTemplateAsync,
      addChartTemplateErrors,
      extractTemplateFromCurrentChartSettings,
      translate
    } = this.props;

    const { inputValue } = this.state;

    return (
      <div className="add-template">
        <Formsy
          onValidSubmit={() => {
            extractTemplateFromCurrentChartSettings(chartData => {
              addChartTemplateAsync({
                name: inputValue,
                settings: chartData
              })
                .then(() => {
                  toggleMode();
                })
                .catch(() => {});
            });
          }}
        >
          <FormsyComposedTextField
            name="template-name"
            label={translate("Template name")}
            value={inputValue}
            fullWidth
            required
            maxLength={50}
            validationErrors={{
              isDefaultRequiredValue: "This field is required"
            }}
            onChange={value => {
              this.setInputValue(value);
            }}
          />
          {addChartTemplateErrors ? (
            <p className="form-error-message">
              {addChartTemplateErrors.validation[0].value}
            </p>
          ) : null}
          <div className="actions-wrapper">
            <SquaberButton
              color="secondary"
              type="button"
              onClick={() => {
                toggleMode();
              }}
            >
              {translate("Cancel")}
            </SquaberButton>
            <SquaberButton type="submit">
              {translate("Add template")}
            </SquaberButton>
          </div>
        </Formsy>
      </div>
    );
  }
}

export default AddTemplate;
