import { isEmpty } from "lodash";
import { fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import AnalyticsEventTracker from "../../../lib/AnalyticsEventTracker";
import decisionModal from "../../../lib/decisionModal";
import { getDefaultMarketId } from "../../../lib/getDefaultMarketId";
import { getIsWeb } from "../../../lib/platformHelper";
import StockAlertOffEvent from "../../../lib/SalesManago/events/StockAlertOffEvent";
import StockAlertOnEvent from "../../../lib/SalesManago/events/StockAlertOnEvent";
import { actions as alertActions } from "../alerts";
import { getUserData } from "../auth/login/selector";
import { followedStocksRoutine } from "../followed";
import { getStockIsFollowed } from "../followed/selector";
import { trackSMEvent } from "../salesManagoEventTracker";
import { fetchStockTagsRoutine } from "../tags";
import { getMarketTranslations } from "../translations/selector";
import {
  getMoreRecommendations,
  getMoreTransactionSignals,
  stockDataRoutine,
  toggleAlert
} from "./index";

function* getStockData({ payload }) {
  try {
    let defaultMarketId = payload.marketId;

    if (getIsWeb()) {
      const marketTranslations = yield select(getMarketTranslations);
      if (!isEmpty(marketTranslations)) {
        defaultMarketId = getDefaultMarketId(
          marketTranslations,
          payload.marketId
        );
      }
    }

    const { data } = yield ApiClient.get({
      urlPath: "app.stocks.singleStock",
      variables: {
        stockId: payload.stockId,
        marketId: defaultMarketId
      },
      skipAccessCheck: true
    });

    yield put(stockDataRoutine.success(data));
  } catch (error) {
    yield put(stockDataRoutine.failure(error));
  }
}

function* onToggleAlert({ payload: { stockId }, market, ticker }) {
  const userData = yield select(getUserData);
  const removingAlert = yield select(getStockIsFollowed, stockId);
  let decision = "confirm";
  let SMEventClass = StockAlertOnEvent;

  if (removingAlert) {
    decision = yield decisionModal(
      "ConfirmModal",
      {
        title: "Are you sure?",
        message: "Do you really want to cancel alerts for this stock?"
      },
      ["confirm", "cancel"]
    );
    SMEventClass = StockAlertOffEvent;
  }

  if (decision === "cancel") {
    return;
  }

  const smEvent = new SMEventClass({
    stockId,
    ticker,
    market
  });

  yield put(trackSMEvent(smEvent));

  try {
    yield ApiClient.put({
      urlPath: "app.stocks.toggleAlert",
      variables: {
        stockId
      }
    });

    yield put(
      alertActions.addSuccessAlert({
        title: "Stock settings changed successfully"
      })
    );
    yield put(toggleAlert.success());
    yield put(followedStocksRoutine());
    yield put(fetchStockTagsRoutine({ stockId }));

    if (!removingAlert) {
      AnalyticsEventTracker.trackStockAlertOnEvent({
        type: "single_stock",
        market,
        name: ticker
      });
    }
  } catch (error) {
    console.log(error);
  }
}

function* onGetMoreRecommendations({ payload }) {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.stocks.recommendations",
      variables: payload
    });

    yield put(getMoreRecommendations.success(data));
  } catch (error) {
    yield put(getMoreRecommendations.failure(error));
  }
}

function* onGetMoreTransactionSignals({ payload }) {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.stocks.transactionSignal",
      variables: payload
    });

    yield put(getMoreTransactionSignals.success({ data, ...payload }));
  } catch (error) {
    yield put(getMoreTransactionSignals.failure({ error, ...payload }));
  }
}

function* watchGetStockData() {
  yield takeLatest(stockDataRoutine.TRIGGER, getStockData);
  yield takeLatest(getMoreRecommendations.TRIGGER, onGetMoreRecommendations);
  yield takeLatest(toggleAlert.TRIGGER, onToggleAlert);
  yield takeEvery(
    getMoreTransactionSignals.TRIGGER,
    onGetMoreTransactionSignals
  );
}

export default [fork(watchGetStockData)];
