import { takeLatest, fork, put, select, take } from "redux-saga/effects";
import { fetchInterestingStocks } from "./index";
import ApiClient from "../../../api/ApiClient";
import { getUserMarketsState } from "../userMarkets/selector";
import { isArray } from "lodash";
import { getUserMarkets } from "../userMarkets";
import { getUserData } from "../auth/login/selector";

function* onFetchInterestingStocks() {
  try {
    let { markets } = yield select(getUserMarketsState) || {};
    const userData = yield select(getUserData) || {};

    if (typeof userData?.id !== "undefined") {
      yield put(getUserMarkets.trigger());
      yield take([getUserMarkets.SUCCESS, getUserMarkets.FAILURE]);
    }

    if (!isArray(markets)) {
      const { markets: newMarkets } = yield select(getUserMarketsState) || {};
      markets = newMarkets;
    }

    const getMarkets = () => {
      if (!isArray(markets)) return "";
      const sortedMarkets = markets.sort();
      return `${sortedMarkets.join(`&markets[]=`)}`;
    };

    const noMarkets = !markets || !markets.length;

    const { data } = yield ApiClient.get({
      urlPath: noMarkets
        ? "app.browserView.interestingStocksNoMarkets"
        : "app.browserView.interestingStocks",
      variables: { markets: getMarkets() }
    });

    yield put(fetchInterestingStocks.success(data));
  } catch (e) {
    console.error(e);
    yield put(fetchInterestingStocks.failure(e));
  }
}

function* watchBrowserView() {
  yield takeLatest(fetchInterestingStocks, onFetchInterestingStocks);
}

export default [fork(watchBrowserView)];
