import reactNativeFirebaseAnalytics from "@react-native-firebase/analytics";
import * as Amplitude from "@amplitude/analytics-react-native";
import uuidv4 from "uuid";
import { UrlProvider } from "../../api/UrlProvider";
import { getIsAndroid } from '../platformHelper';
import config from "../../config";

class AnalyticsEventTrackerNative {
  canTrackUserData = false;

  async trackRegisterConversion(registerSource, userId, registrationMethod) {
    if (!this.canTrackUserData) {
      return false;
    }

    if (!registrationMethod) {
      registrationMethod = "form";
    }

    if (config.trackEvents) {
      // New analytics
      try {
        await reactNativeFirebaseAnalytics().logEvent("Registration", {
          source: registerSource,
          user_id: userId
        });
      } catch (e) {}

      // Facebook analytics
      await this.trackFacebookEvent(
        "fb_mobile_complete_registration",
        registerSource
      );
    }

    await Amplitude.setUserId(String(userId));

    if (config.amplitude.trackRegistration) {
      await Amplitude.track("register", {
        registerSource,
        registration_method: registrationMethod
      });
    }
  }

  async trackFacebookEvent(event, source) {
    if (!this.canTrackUserData) {
      return false;
    }

    if (config.facebookCredentials) {
      const customEvents = [
        {
          _eventName: event,
          fb_registration_method: source,
          fb_currency: "PLN",
          fb_system: getIsAndroid() ? "Android" : "iOS",
          _valueToSum: 0
        }
      ];

      const url = UrlProvider.getUrl(
        "fbTracker.trackEvent",
        {
          appId: config.facebookCredentials.appId,
          clientToken: config.facebookCredentials.clientToken,
          event: "CUSTOM_APP_EVENTS",
          customEvents: JSON.stringify(customEvents),
          applicationTrackingEnabled: 0,
          advertiserTrackingEnabled: 0,
          advertiserId: uuidv4()
        },
        ""
      ).replace(/\s/gm, "");

      return await fetch(url, {
        method: "POST"
      });
    }
  }

  async trackUserLogInEvent() {
    if (!this.canTrackUserData) {
      return false;
    }

    await Amplitude.track("log_in");
  }

  async trackOfferModalEvent(id = null) {
    if (!this.canTrackUserData) {
      return false;
    }

    const type = id === null ? "premium_modal" : "marketing_message";

    await Amplitude.track("offer_modal", {
      marketing_message_id: id,
      type
    });
  }

  async trackStockPageSectionShownEvent(sectionName, market, ticker) {
    if (!this.canTrackUserData) {
      return false;
    }

    await Amplitude.track("stock_section_show", {
      section_name: sectionName,
      market,
      ticker
    });
  }

  async trackSearchListStockOnClickEvent({
    searchListName,
    market,
    ticker,
    searchListPosition
  }) {
    if (!this.canTrackUserData) {
      return false;
    }

    await Amplitude.track("search_list_stock_click", {
      search_list_name: searchListName,
      market,
      ticker,
      position: searchListPosition
    });
  }

  async trackSearchResultOnClickEvent({ market, ticker }) {
    if (!this.canTrackUserData) {
      return false;
    }

    await Amplitude.track("user_search_click", {
      market,
      ticker
    });
  }

  async trackSearchPageShownEvent() {
    if (!this.canTrackUserData) {
      return false;
    }

    await Amplitude.track("search_click");
  }

  async trackRegistrationModalEvent() {
    if (!this.canTrackUserData) {
      return false;
    }

    await Amplitude.track("registration_modal");
  }

  async trackPortfolioCreatedEvent() {
    if (!this.canTrackUserData) {
      return false;
    }

    await Amplitude.track("portfolio_created");
  }

  async trackPortfolioTransactionEvent() {
    if (!this.canTrackUserData) {
      return false;
    }

    await Amplitude.track("portfolio_transaction");
  }

  async trackStockAlertOnEvent(type, market, name) {
    if (!this.canTrackUserData) {
      return false;
    }

    await Amplitude.track("stock_alert_on", {
      type,
      market,
      name
    });
  }

  async trackTagCreatedEvent() {
    if (!this.canTrackUserData) {
      return false;
    }

    await Amplitude.track("tag_created");
  }
}

export default new AnalyticsEventTrackerNative();
