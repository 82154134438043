import React from "react";
import { Helmet } from "react-helmet";

export const SEOHelmet = ({ data }) => {
  const { keywords, meta_description, meta_title } = data;
  return (
    <Helmet>
      <meta name="keywords" content={keywords} />
      <meta name="description" content={meta_description} />
      <meta property="og:description" content={meta_description} />
      <meta property="og:title" content={meta_title} />
    </Helmet>
  );
};
