import React from "react";
import PropTypes from "prop-types";

import config from "../config";

class SettingsPageAbstract extends React.Component {
  static propTypes = {
    settingsCategories: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
        icon: PropTypes.string,
        nativeIcon: PropTypes.string
      })
    )
  };

  static defaultProps = {
    groups: config.settingsGroups,
    settingsCategories: config.settingsCategories
  };
}

export default SettingsPageAbstract;
