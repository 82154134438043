import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";

export const fetchConfigPageRoutine = createRoutine(
  "app/faq/FETCH_CONFIG_PAGE"
);

const initialState = {
  loading: true,
  error: null,
  data: null
};

export default handleActions(
  {
    [fetchConfigPageRoutine.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [fetchConfigPageRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false
    }),
    [fetchConfigPageRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      error: null,
      data: payload,
      loading: false
    })
  },
  initialState
);
