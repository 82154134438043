import { createAction, handleActions } from "redux-actions";

export const SAVE_UNIVERSAL_TABLE_SETTINGS =
  "app/universalTable/SAVE_UNIVERSAL_TABLE_SETTINGS";
export const saveUniversalTableSettings = createAction(
  SAVE_UNIVERSAL_TABLE_SETTINGS
);

export const UPDATE_UNIVERSAL_TABLE_SETTINGS =
  "app/universalTable/SAVE_UNIVERSAL_TABLE_SETTINGS";
export const updateUniversalTableSettings = createAction(
  UPDATE_UNIVERSAL_TABLE_SETTINGS
);

const initialState = {
  savedUniversalTableSettings: {
    cacheKey: null,
    settings: {
      currentTabId: null,
      search: null,
      filters: null,
      sortBy: null,
      pagination: null
    }
  }
};

export default handleActions(
  {
    [SAVE_UNIVERSAL_TABLE_SETTINGS]: (state, { payload }) => ({
      ...state,
      savedUniversalTableSettings: {
        ...state.savedUniversalTableSettings,
        cacheKey: payload.cacheKey,
        settings: {
          ...state.savedUniversalTableSettings.settings,
          currentTabId: payload.settings.currentTabId,
          search: payload.settings.search,
          filters: payload.settings.filters,
          sortBy: payload.settings.sortBy,
          pagination: payload.settings.pagination
        }
      }
    }),
    [UPDATE_UNIVERSAL_TABLE_SETTINGS]: (state, { payload }) => ({
      ...state,
      savedUniversalTableSettings: {
        ...state.savedUniversalTableSettings,
        cacheKey: payload.cacheKey,
        settings: {
          ...state.savedUniversalTableSettings.settings,
          ...payload.settings
        }
      }
    })
  },
  initialState
);
