import { takeLatest, fork, put } from "redux-saga/effects";
import { instrumentsListRoutine } from "./index";
import ApiClient from "../../../api/ApiClient";

function* onFetchInstrumentsList() {
  try {
    const { data } = yield ApiClient.get("app.stock.list");

    yield put(instrumentsListRoutine.success(data));
  } catch (error) {
    yield put(instrumentsListRoutine.failure(error));
  }
}

function* watchFetchInstrumentsList() {
  yield takeLatest(instrumentsListRoutine.TRIGGER, onFetchInstrumentsList);
}

export default [fork(watchFetchInstrumentsList)];
