import React from "react";
import withTranslations from "../../../../../hocs/withTranslations";
import InterestingStocksList from "../../../common/InterestingStocksList";
import PageTitle from "../../../common/PageTitle/modules/default";

const InterestingStocksPage = ({ translate }) => (
  <div className="history-list-page">
    <PageTitle title={translate("Interesting stocks")} />
    <h1>{translate("Interesting stocks")}</h1>
    <p>{translate("Signals generated during last 365 days")}</p>
    <InterestingStocksList />
  </div>
);

export default withTranslations(InterestingStocksPage);
