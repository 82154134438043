import React from "react";
import moment from "moment-mini";
import { compose } from "redux";
import classNames from "classnames";
import { UrlProvider } from "../../../../../api/UrlProvider";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import withQuoteData from "../../../../../hocs/withQuoteData";
import withHistoryListItemAccessHandler from "../../../../../hocs/withHistoryListItemAccessHandler";
import Price from "../../../../../common/Price";
import ContentSection from "../../ContentSection";
import ChangePercent from "../../ChangePercent";
import SquaberButton from "../../SquaberButton";
import SquaberLink from "../../SquaberLink";
import SimpleAlertButton from "../../SimpleAlertButton/modules/default";
import withMarketTransltaions from "../../../../../hocs/withMarketTranslations";
import { getTranslatedMarket } from "../../../../../lib/getTranslatedMarket";

const InterestingStocksListItem = ({
  translate,
  stock: { ticker, market, name: stock_name, currency, id: stockId },
  type,
  actual,
  created,
  updated,
  price,
  deactivation_price,
  tp,
  sl,
  quoteData,
  description,
  handleDescriptionButtonClick,
  locale,
  author,
  max_profit,
  userHasAccess,
  marketTranslations
}) => {
  const updateDateDiff = moment(updated).diff(moment(created));
  const closePrice = deactivation_price || (quoteData ? quoteData.close : null);
  const shouldBlurData = actual && !userHasAccess;

  let maxPrice = max_profit;

  if (actual && quoteData) {
    if (type === "buy") {
      maxPrice = Math.max(Number(max_profit), Number(quoteData.high));
    } else {
      maxPrice = Math.min(Number(max_profit), Number(quoteData.low));
    }
  }

  let maxProfit = null;

  if (max_profit) {
    if (type === "buy") {
      maxProfit = ((maxPrice - price) / price / 100) * 100;
    } else {
      maxProfit = ((price - maxPrice) / price / 100) * 100;
    }
  }

  const profit =
    type === "buy"
      ? ((closePrice - price) / price / 100) * 100
      : ((price - closePrice) / price / 100) * 100;

  const translatedMarket = getTranslatedMarket(
    locale,
    market,
    marketTranslations
  );

  return (
    <ContentSection
      className={classNames({
        "history-list-item": true,
        "with-blurred-data": shouldBlurData
      })}
    >
      <div className="history-list-item__row">
        <div className="basic-info">
          <SquaberLink
            to={
              !shouldBlurData
                ? UrlProvider.getUrl("fe.stockPageWithScrollToSection", {
                    locale,
                    marketId: translatedMarket,
                    stockId: ticker,
                    section: "section-current-signal"
                  })
                : "#!"
            }
          >
            <h2 className="stock-ticker">{!shouldBlurData ? ticker : "XXX"}</h2>
          </SquaberLink>
          <p className="stock-name">{!shouldBlurData ? stock_name : "Stock"}</p>
          <p className="secondary-text">
            {translate("Direction")}:{" "}
            <span className="direction">
              {translate(!shouldBlurData ? `signal_type_${type}` : "Direction")}
            </span>
          </p>
        </div>
        <div className="alert-status">
          <SimpleAlertButton
            stockId={stockId}
            ticker={ticker}
            market={market}
          />
          <p className="secondary-text">
            {translate("Status")}:{" "}
            <span
              className={classNames({
                "signal-status-description": true,
                [`signal-${actual ? "active" : "inactive"}`]: true
              })}
            >
              {translate(`signal_status_${actual ? "active" : "closed"}`)}
            </span>
          </p>
        </div>
      </div>
      <div className="history-list-item__row">
        <p className="secondary-text description">
          {translate("Created on")}:{" "}
          {moment(created).format("DD.MM.YYYY HH:mm")}
        </p>
        <div className="cells-wrapper">
          <div className="cell">
            <div className="cell__label">{translate("Starting price")}</div>
            <div className="cell__content">
              <Price value={!shouldBlurData ? price : 0} currency={currency} />
            </div>
          </div>
          <div className="cell">
            <div className="cell__label">
              {translate(
                deactivation_price ? "Closing price" : "Current price"
              )}
            </div>
            <div className="cell__content">
              {closePrice ? (
                <Price
                  value={!shouldBlurData ? closePrice : 0}
                  currency={currency}
                />
              ) : (
                <i className="fal fa-spinner fa-spin" />
              )}
            </div>
          </div>
          <div className="cell">
            <div className="cell__label">{translate("Change percent")}</div>
            <div className="cell__content">
              {closePrice ? (
                <ChangePercent change={!shouldBlurData ? profit : 0} />
              ) : (
                <i className="fal fa-spinner fa-spin" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="history-list-item__row">
        <div className="cells-wrapper">
          <div className="cell half-width">
            <div className="cell__label">{translate("Take profit")}</div>
            <div className="cell__content">
              <Price value={!shouldBlurData ? tp : 0} currency={currency} />
            </div>
          </div>
          <div className="cell half-width">
            <div className="cell__label">{translate("Stop loss")}</div>
            <div className="cell__content">
              <Price value={!shouldBlurData ? sl : 0} currency={currency} />
            </div>
          </div>
          {max_profit !== null ? (
            <div className="cell half-width">
              <div className="cell__label">{translate("Max profit")}</div>
              <div className="cell__content">
                <ChangePercent change={!shouldBlurData ? maxProfit : 0} />
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="history-list-item__footer">
        <div className="author-data">
          <p className="secondary-text multiline description">
            {translate("Author")}: {author.first_name} {author.last_name},
            {author.job_title}
          </p>
          <p className="secondary-text multiline">
            {translate("Published on")}:{" "}
            {moment(created).format("DD.MM.YYYY HH:mm")}
          </p>
          {updateDateDiff > 0 ? (
            <p className="secondary-text multiline">
              {translate("Updated on")}:{" "}
              {moment(updated).format("DD.MM.YYYY HH:mm")}
            </p>
          ) : null}
        </div>
        <div className="button-wrapper">
          <SquaberButton
            color="primary_small"
            onClick={() => {
              handleDescriptionButtonClick(hasAccess => {
                if (hasAccess) {
                  showModal("InterestingStockDescription", {
                    title: `${ticker}, ${moment(created).format(
                      "DD.MM.YYYY HH:mm"
                    )}`,
                    content: description
                  });
                }
              }, actual);
            }}
          >
            {translate("Description")}
          </SquaberButton>
        </div>
      </div>
    </ContentSection>
  );
};

InterestingStocksListItem.propTypes = {};

export default compose(
  withQuoteData(),
  withHistoryListItemAccessHandler,
  withMarketTransltaions
)(InterestingStocksListItem);
