import { isArray, isEmpty } from "lodash";
import { fork, put, select, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import { actions as alertsActions } from "../alerts";
import { getStockId } from "../stockPageTvChart/selector";
import { getLocale, getTranslations } from "../translations/selector";
import {
  addChartAnalysisRoutine,
  deleteChartAnalysis,
  fetchChartAnalysesDetailsRoutine,
  fetchChartAnalysesRoutine,
  updateChartAnalysis
} from "./index";
import { getChartAnalysesList } from "./selector";

function* showSuccessfullyAddedAlert() {
  yield put(
    alertsActions.addSuccessAlert({
      title: "Successfully added chart analysis"
    })
  );
}

function* showErrorWhileAddingAlert() {
  yield put(
    alertsActions.addErrorAlert({
      title: "There was an error when saving your chart analysis"
    })
  );
}

function* showErrorWhileDeletingAlert() {
  yield put(
    alertsActions.addErrorAlert({
      title: "There was an error when removing your chart analysis"
    })
  );
}

function* showSuccessfullyRemovedAlert() {
  yield put(
    alertsActions.addSuccessAlert({
      title: "Successfully removed chart analysis"
    })
  );
}

function* showSuccessfullyUpdatedAlert() {
  yield put(
    alertsActions.addSuccessAlert({
      title: "Successfully updated chart analysis"
    })
  );
}

function* showErrorWhileUpdatingAlert() {
  yield put(
    alertsActions.addErrorAlert({
      title: "There was an error when updating your chart analysis"
    })
  );
}

function* showSuccessfullyAppliedAlert() {
  yield put(
    alertsActions.addSuccessAlert({
      title: "Successfully applied chart analysis"
    })
  );
}

function* showErrorWhileApplyingAlert() {
  yield put(
    alertsActions.addErrorAlert({
      title: "There was an error while applying your chart analysis"
    })
  );
}

function* onFetchChartAnalysis() {
  const stockId = yield select(getStockId);

  if (!stockId) return;

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.chartAnalyses.list",
      skipAccessCheck: true,
      variables: {
        stockId
      }
    });

    yield put(fetchChartAnalysesRoutine.success(data));
  } catch (error) {
    yield put(fetchChartAnalysesRoutine.failure());
  }
}

function isAnalysisNameExists(name, analyses = []) {
  return (
    typeof analyses.find(({ name: aName }) => aName === name) !== "undefined"
  );
}

function* getDefaultAnalysisName() {
  const analyses = yield select(getChartAnalysesList);
  const translations = yield select(getTranslations);
  const locale = yield select(getLocale);

  const name = "default_analysis_name";
  const translatedName = translations?.[locale]?.[name] ?? name;

  let number = analyses.length + 1;
  let defaultName = `${translatedName} ${number}`;

  while (isAnalysisNameExists(defaultName, analyses)) {
    number++;
    defaultName = `${translatedName} ${number}`;
  }

  return defaultName;
}

function* onAddChartAnalysis({ payload }) {
  const {
    name = yield getDefaultAnalysisName(),
    description,
    settings: chartSettings
  } = payload ?? {};
  const stockId = yield select(getStockId);

  try {
    const settings = yield JSON.stringify(chartSettings);

    yield ApiClient.post({
      urlPath: "app.chartAnalyses.list",
      data: {
        name,
        description,
        settings
      },
      variables: { stockId }
    });

    yield showSuccessfullyAddedAlert();
    yield put(fetchChartAnalysesRoutine());
    yield put(addChartAnalysisRoutine.success());
  } catch (error) {
    yield showErrorWhileAddingAlert();

    const errors = error?.response?.data?.non_field_errors;

    if (isArray(errors) && !isEmpty(errors)) {
      yield put(addChartAnalysisRoutine.failure(errors[0]));
    } else {
      yield put(addChartAnalysisRoutine.failure());
    }
  }
}

function* onUpdateChartAnalysis({ payload }) {
  const { id, name, description } = payload;
  const stockId = yield select(getStockId);

  try {
    yield ApiClient.patch({
      urlPath: "app.chartAnalyses.update",
      skipAccessCheck: true,
      variables: {
        stockId,
        analysisId: id
      },
      data: {
        name,
        description
      }
    });

    yield showSuccessfullyUpdatedAlert();
    yield put(fetchChartAnalysesRoutine());
  } catch (error) {
    yield showErrorWhileUpdatingAlert();

    const errors = error?.response?.data?.non_field_errors;

    yield put(addChartAnalysisRoutine.failure(errors[0]));
  }
}

function* onDeleteChartAnalysis({ payload }) {
  const { id: analysisId } = payload;
  const stockId = yield select(getStockId);

  try {
    yield ApiClient.delete({
      urlPath: "app.chartAnalyses.remove",
      skipAccessCheck: true,
      variables: {
        stockId,
        analysisId
      }
    });

    yield put(fetchChartAnalysesRoutine());
    yield showSuccessfullyRemovedAlert();
  } catch (error) {
    yield showErrorWhileDeletingAlert();
  }
}

function* onFetchChartAnalysisDetails({ payload: url }) {
  try {
    const { data } = yield ApiClient.get({
      url,
      skipAuthorizationHeader: true
    });

    yield put(fetchChartAnalysesDetailsRoutine.success(data));
    yield showSuccessfullyAppliedAlert();
  } catch (e) {
    yield put(fetchChartAnalysesDetailsRoutine.failure(e));
    yield showErrorWhileApplyingAlert();
  }
}

function* watchChartAnalysis() {
  yield takeLatest(fetchChartAnalysesRoutine, onFetchChartAnalysis);
  yield takeLatest(
    fetchChartAnalysesDetailsRoutine,
    onFetchChartAnalysisDetails
  );
  yield takeLatest(updateChartAnalysis, onUpdateChartAnalysis);
  yield takeLatest(deleteChartAnalysis, onDeleteChartAnalysis);
  yield takeLatest(addChartAnalysisRoutine, onAddChartAnalysis);
}

export default [fork(watchChartAnalysis)];
