import React from "react";

import useWebNetworkStatus from "../hooks/useWebNetworkStatus";

const withWebNetworkStatus = WrappedComponent => {
  return function WithWebNetworkStatus(props) {
    const { networkStatus } = useWebNetworkStatus();

    return <WrappedComponent {...props} networkStatus={networkStatus} />;
  };
};

export default withWebNetworkStatus;
