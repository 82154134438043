import config from "../../../../../config";
import * as modules from "./modules/";

const { vendor } = config;

const LandingTypography = modules[vendor]
  ? modules[vendor]
  : modules["default"];

export default LandingTypography;
