import React from "react";

import connect from "../../i18n/connect";
import { getDefaultUserAction } from "../../redux/modules/userActions/selector";

export default function withUserActions(WrappedComponent) {
  const mapStateToProps = state => ({
    defaultUserAction: getDefaultUserAction(state)
  });

  @connect(mapStateToProps)
  class WithUserActions extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithUserActions;
}
