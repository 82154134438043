import React from "react";
import qs from "qs";
import autoBind from "auto-bind";
import { connect } from "../i18n/connect";
import withFormBuilder from "./withFormBuilder";
import { resetPasswordExecuteRoutine } from "../redux/modules/resetPassword";
import transformFormData from "../lib/transformFormData";

export default function withResetPasswordHandler(WrappedComponent) {
  const mapDispatchToProps = {
    executeResetPassword: resetPasswordExecuteRoutine
  };

  const formFields = [
    {
      name: "password",
      value: "",
      type: "password",
      required: true,
      fullWidth: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      label: "Password"
    },
    {
      name: "password_confirm",
      value: "",
      type: "password",
      required: true,
      fullWidth: true,
      validations: {
        equalsField: "password"
      },
      validationErrors: {
        equalsField: "Your password doesn't match",
        isDefaultRequiredValue: "This field is required"
      },
      label: "Repeat password"
    }
  ];

  @connect(null, mapDispatchToProps)
  @withFormBuilder(formFields)
  class WithResetPasswordHandler extends React.Component {
    state = { resetPasswordData: null };

    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    componentDidMount() {
      const {
        match: {
          params: { user_id, hash }
        }
      } = this.props;

      this.setState({ resetPasswordData: { user_id, hash } });
    }

    executeResetPassword(formData) {
      const fields = transformFormData(formData);
      const { resetPasswordData } = this.state;

      this.props.executeResetPassword({
        ...fields,
        ...resetPasswordData
      });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          executeResetPassword={this.executeResetPassword}
        />
      );
    }
  }

  return WithResetPasswordHandler;
}
