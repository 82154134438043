import React from "react";
import UniversalTableLiteWeb from "../../../common/UniversalTableLiteWeb";
import ContentSection from "../../../common/ContentSection";
import withTranslations from "../../../../../hocs/withTranslations";
import PageTitle from "../../../common/PageTitle";
import ResourceResolver from "../../../../../lib/UniversalTable/ResourceResolver";

const TurtleStrategyTablePage = ({ translate }) => (
  <ContentSection>
    <PageTitle title={translate("turtle_strategy_summary_page_title")} />
    <h1>{translate("turtle_strategy_summary_header")}</h1>
    <p
      dangerouslySetInnerHTML={{
        __html: translate("turtle_strategy_summary_description")
      }}
    />
    <UniversalTableLiteWeb
      resourceId={ResourceResolver["turtle_strategy_summary"]}
      useUrlSettings
    />
  </ContentSection>
);

export default withTranslations(TurtleStrategyTablePage);
