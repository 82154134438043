import React from "react";
import classNames from "classnames";
import Interval from "react-interval-rerender";
import withTranslations from "../../../../../hocs/withTranslations";
import DateHelper from "../../../../../lib/UniversalTable/RendererHelpers/DateHelper";
import "../rendererStyles/Date.scss";

const Date = ({ value, format, translate }) => {
  return (
    <div
      className={classNames({
        "date-renderer": true
      })}
    >
      <Interval delay={60 * 1000}>
        {() => DateHelper.render(value, format, translate)}
      </Interval>
    </div>
  );
};

export default withTranslations(Date);
