import * as WebBrowser from "expo-web-browser";
import { WebBrowserOpenOptions } from "expo-web-browser";

const openBrowserAsync = (
  url: string,
  browserParams?: WebBrowserOpenOptions
) => {
  return WebBrowser.openBrowserAsync(url, {
    createTask: false,
    ...(browserParams ?? {})
  });
};

export default openBrowserAsync;
