import toLocalUpdateTime from "./toLocalUpdateTime";

export const updateAttentionStateLocal = (
  market,
  ticker,
  updateAttentionState,
  attentionState
) => {
  if (market && ticker) {
    const normalizedMarket = market.toLowerCase();
    const normalizedTicker = ticker.toLowerCase();
    const currentDate = toLocalUpdateTime(new Date());
    updateAttentionState(
      normalizedMarket,
      normalizedTicker,
      typeof attentionState?.currentAttentionState === "undefined"
        ? false
        : !attentionState?.currentAttentionState,
      currentDate
    );
  }
  return null;
};
