export default function getSquaberTrendyIndicator(PineJS) {
  return {
    name: "Squaber Trendy",
    metainfo: {
      _metainfoVersion: 42,
      id: "trendy@tv-basicstudies-1",
      name: "[Squaber] Trendy",
      description: "[Squaber] Trendy",
      shortDescription: "[Squaber] Trendy",
      scriptIdPart: "",
      is_price_study: true,
      is_hidden_study: false,
      isCustomIndicator: true,
      plots: [
        {
          id: "plot_0",
          type: "bg_colorer",
          palette: "palette_1"
        }
      ],
      defaults: {
        inputs: {},
        precision: 4,
        palettes: {
          palette_1: {
            colors: [
              { color: "rgba(0,128,0,0.15)" },
              { color: "rgba(0,128,0,0.1)" },
              { color: "rgba(0,128,0,0.05)" },
              { color: "rgba(255,0,0,0.15)" },
              { color: "rgba(255,0,0,0.1)" },
              { color: "rgba(255,0,0,0.05)" }
            ]
          }
        },
        styles: {
          plot_0: {
            linestyle: 0,
            visible: true,
            linewidth: 1,
            plottype: 2,
            trackPrice: true,
            transparency: 50,
            color: "#8264b8"
          }
        }
      },
      inputs: [],
      palettes: {
        palette_1: {}
      }
    },
    constructor: function() {
      this.init = function(context, inputCallback) {
        this._context = context;
        this._input = inputCallback;
      };

      this.main = function(context, inputCallback) {
        this._context = context;
        this._input = inputCallback;

        var getTrend = function() {
          var close = this._context.new_var(PineJS.Std.close(this._context));
          var quick_sma = PineJS.Std.sma(close, 50, this._context);
          var medium_sma = PineJS.Std.sma(close, 100, this._context);
          var slow_sma = PineJS.Std.sma(close, 200, this._context);
          var current_price = PineJS.Std.close(this._context);

          var strong_uptrend_color = 0;
          var semi_uptrend_color = 1;
          var weak_uptrend_color = 2;
          var stong_downtrend_color = 3;
          var semi_downtrend_color = 4;
          var weak_downtrend_color = 5;

          if (
            current_price > quick_sma &&
            current_price > medium_sma &&
            current_price > slow_sma
          ) {
            return strong_uptrend_color;
          }

          if (
            (current_price < quick_sma &&
              current_price > medium_sma &&
              current_price > slow_sma) ||
            (current_price > quick_sma &&
              current_price > medium_sma &&
              PineJS.Std.na(slow_sma))
          ) {
            return semi_uptrend_color;
          }

          if (
            (current_price < quick_sma &&
              current_price < medium_sma &&
              current_price > slow_sma) ||
            (current_price > quick_sma &&
              PineJS.Std.na(medium_sma) &&
              PineJS.Std.na(slow_sma))
          ) {
            return weak_uptrend_color;
          }

          if (
            current_price < quick_sma &&
            current_price < medium_sma &&
            current_price < slow_sma
          ) {
            return stong_downtrend_color;
          }

          if (
            (current_price > quick_sma &&
              current_price < medium_sma &&
              current_price < slow_sma) ||
            (current_price < quick_sma &&
              current_price < medium_sma &&
              PineJS.Std.na(slow_sma))
          ) {
            return semi_downtrend_color;
          }

          if (
            (current_price > quick_sma &&
              current_price > medium_sma &&
              current_price < slow_sma) ||
            (current_price < quick_sma &&
              PineJS.Std.na(medium_sma) &&
              PineJS.Std.na(slow_sma))
          ) {
            return weak_downtrend_color;
          }

          return NaN;
        }.bind(this);

        return [getTrend()];
      };
    }
  };
}
