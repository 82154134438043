import React from "react";

import { connect } from "../i18n/connect";
import { actions as priceAlertActions } from "../redux/modules/priceAlerts";
import {
  getPriceAlerts,
  getPriceAlertsLoading
} from "../redux/modules/priceAlerts/selector";
import withFormBuilder from "./withFormBuilder";

export default function withPriceAlerts(WrappedComponent) {
  const mapStateToProps = state => ({
    priceAlerts: getPriceAlerts(state),
    priceAlertsLoading: getPriceAlertsLoading(state)
  });

  const mapDispatchToProps = {
    addPriceAlert: priceAlertActions.addPriceAlert,
    deletePriceAlert: priceAlertActions.deletePriceAlert,
    fetchPriceAlerts: priceAlertActions.fetchPriceAlerts
  };

  @connect(mapStateToProps, mapDispatchToProps)
  @withFormBuilder(() => [
    {
      name: "value",
      type: "text",
      value: "",
      label: "Price",
      required: false,
      fullWidth: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      inputMode: "numeric"
    },
    {
      name: "comment",
      type: "text",
      multiline: true,
      rows: 3,
      fullWidth: true,
      value: "",
      label: "Comment to price alert",
      required: false,
      maxLength: 100
    }
  ])
  class WithPriceAlerts extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithPriceAlerts;
}
