import React from "react";

import snakeToPascal from "../../../../../lib/snakeToPascal";
import * as Renderers from "./FrontEndRenderers/";

export default props => {
  const { attributeName, rendererName } = props;

  if (typeof attributeName !== "string") {
    return null;
  }

  const finalRendererName = snakeToPascal(rendererName || attributeName);
  const Renderer = Renderers[finalRendererName];

  if (!Renderer) {
    return null;
  }

  return (
    <div className="generated-in-front-end-renderer">
      <Renderer {...props} />
    </div>
  );
};
