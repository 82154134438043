import "../styles/defatult.scss";

import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import classNames from "classnames";
import Formsy from "formsy-react";
import React from "react";
import { withRouter } from "react-router";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withAutocomplete from "../../../../../hocs/withAutocomplete";
import withEditOrDeleteSingleTag from "../../../../../hocs/withEditOrDeleteSingleTag";
import withSingleTagDetails from "../../../../../hocs/withSingleTagDetails";
import withTagDetailsForm from "../../../../../hocs/withTagDetailsForm";
import withTagStocksControl from "../../../../../hocs/withTagStocksControl";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import TagsHelper from "../../../../../lib/TagsHelper/TagsHelper";
import Loader from "../../../common/Loader";
import SquaberButton from "../../../common/SquaberButton";
import TagsSettingsSearchBox from "../../TagsSettingsSearchBox";

@withAutocomplete
@withSingleTagDetails(true, Loader)
@withTagDetailsForm
@withEditOrDeleteSingleTag
@withTagStocksControl
@withRouter
class TagsSettingsEditTag extends React.Component {
  componentDidMount() {
    this.props.clearResults();
  }

  renderStockCheckbox(stock, isActive) {
    const { removeStockFromActiveStocks, addActiveStock } = this.props;

    return (
      <FormControlLabel
        key={stock.id}
        className={"checkbox-wrapper"}
        classes={{
          label: "checkbox-label-wrapper"
        }}
        control={
          <Checkbox
            checked={isActive}
            onChange={() => {
              if (isActive) {
                removeStockFromActiveStocks(stock);
              } else {
                addActiveStock(stock);
              }
            }}
          />
        }
        label={
          <span className={"checkbox-label"}>
            <span className="ticker">{stock.ticker}</span>
            <span className="market">{stock.market}</span>
            <span className="stock-name">{stock.name}</span>
          </span>
        }
      />
    );
  }

  render() {
    const {
      id,
      translate,
      formBuilder,
      getResults,
      autocompleteState: { result },
      editSingleTag,
      deleteSingleTag,
      history,
      locale,
      activeStocks,
      currentTab,
      setCurrentTab,
      tagDetails
    } = this.props;

    if (
      tagDetails?.data?.tag_type &&
      tagDetails.data.tag_type !== TagsHelper.tagTypes.USER
    ) {
      return (
        <>
          <h1>{translate("Access denied")}</h1>
          <p>{translate("You can only edit tags created by you")}</p>
        </>
      );
    }

    return (
      <div className="tags-settings-edit-tag">
        <div
          className="remove-tag"
          onClick={() => {
            deleteSingleTag({ tagId: id, tagName: tagDetails.data.name })
              .then(() => {
                history.push(
                  UrlProvider.getUrl("fe.tagsSettings", {
                    locale
                  })
                );
              })
              .catch(() => {});
          }}
        >
          <span>{translate("Remove tag")}</span>
          <i className="far fa-trash-alt" />
        </div>
        <Formsy
          onValidSubmit={() => {
            editSingleTag({
              tagId: id,
              fields: formBuilder.fields
            });
          }}
        >
          <FormBuilderWeb {...formBuilder} />
          <div className="text-right">
            <SquaberButton color="primary" type="submit">
              {translate("Apply")}
            </SquaberButton>
          </div>
        </Formsy>

        <h5>{translate("Select stocks you want to apply this tag for")}</h5>

        <div className="stock-selector-tabs">
          <div
            className={classNames({
              "stock-selector-tab": true,
              active: currentTab === "stocks-list"
            })}
            onClick={() => {
              setCurrentTab("stocks-list");
            }}
          >
            {translate("Stocks list")}
          </div>
          <div
            className={classNames({
              "stock-selector-tab": true,
              active: currentTab === "selected-stocks"
            })}
            onClick={() => {
              setCurrentTab("selected-stocks");
            }}
          >
            <span>{translate("Selected stocks")}</span>
            <span className="selected-stocks-count-badge">
              {activeStocks.filter(stock => stock.active).length}
            </span>
          </div>
        </div>

        <div className="stocks-selector">
          <div
            className={classNames({
              "stocks-list": true,
              active: currentTab === "stocks-list"
            })}
          >
            <div className="search-box">
              <TagsSettingsSearchBox
                label={translate(
                  "Enter the company you are interested in - eg KGHM"
                )}
                onChange={event => {
                  getResults(event.target.value);
                }}
              />
            </div>
            <div className="stocks-list-body">
              <div className="stocks-list-inside">
                {result.stocks
                  ? result.stocks.map(({ stock }) => {
                      const correspondingActiveStock = activeStocks.find(
                        stockToFind => stockToFind.id === stock.id
                      );

                      return this.renderStockCheckbox(
                        stock,
                        correspondingActiveStock &&
                          correspondingActiveStock.active
                      );
                    })
                  : null}
              </div>
            </div>
          </div>
          <div
            className={classNames({
              "stocks-list": true,
              active: currentTab === "selected-stocks"
            })}
          >
            <div className="stocks-list-body">
              <div className="stocks-list-inside big">
                {activeStocks.map(stock =>
                  this.renderStockCheckbox(stock, stock.active)
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default TagsSettingsEditTag;
