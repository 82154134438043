import { createAction, handleActions } from "redux-actions";

const SET_SEEN_NOTIFICATION_MODAL =
  "app/seenNotificationModal/SET_SEEN_NOTIFICATION_MODAL";

export const setSeenNotificationModal = createAction(
  SET_SEEN_NOTIFICATION_MODAL,
  () => {}
);

const initialState = {
  seenNotificationModal: false
};

export default handleActions(
  {
    [SET_SEEN_NOTIFICATION_MODAL]: state => ({
      ...state,
      seenNotificationModal: true
    })
  },
  initialState
);
