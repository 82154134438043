import ApiClientAsync from "../../api/ApiClientAsync";
import { getDefaultMarketId } from "../getDefaultMarketId";

export default class SEOHelper {
  static async getStockSlugList(
    locale,
    authToken,
    marketId,
    marketTranslations
  ) {
    const preparedMarketId = getDefaultMarketId(marketTranslations, marketId);
    const apiClient = new ApiClientAsync(locale, authToken);
    if (marketId) {
      return await apiClient.get({
        urlPath: "app.stockSeoData.getSectionList",
        variables: {
          marketId: preparedMarketId
        }
      });
    }
  }

  static async getStockSeoMetadata(
    locale,
    authToken,
    marketId,
    stockId,
    section = "main",
    marketTranslations
  ) {
    const preparedMarketId = getDefaultMarketId(marketTranslations, marketId);

    if (marketId) {
      const apiClient = new ApiClientAsync(locale, authToken);
      return await apiClient.get({
        urlPath: "app.stockSeoData.getSectionSeoData",
        variables: {
          marketId: preparedMarketId,
          stockId,
          section: section ? section : "main"
        }
      });
    }

    return null;
  }
}
