import classNames from "classnames";
import React, { useState } from "react";
import ScrollContainer from "react-indiana-drag-scroll";
import { Link } from "react-router-dom";

import connect from "../../../../../../i18n/connect";
import LandingTypography from "../../../components/LandingTypography";
import useIsDesktop from "../../../hooks/useIsDesktop";
import useIsTablet from "../../../hooks/useIsTablet";
import useScrollToAnchor from "../../../hooks/useScrollToAnchor";
import TeSpolkiWartoMiecWPortfelu from "../assets/4a6803ce99e0a5d0696922cf5afbcd56.png";
import KupujSpolkiChinskie from "../assets/ad267ebbd0c879563110a938dd9e0f85.png";
import Battery from "../assets/battery-charging-twotone-100_svgrepo.com.svg";
import Coins from "../assets/coin-stack_svgrepo.com.svg";
import Materials from "../assets/frame_106.svg";
import Crypto from "../assets/frame_118.svg";
import BellIcon from "../assets/frame_136.svg";
import WalletIcon from "../assets/frame_136_1.svg";
import PercentageIcon from "../assets/frame_136_2.svg";
import PcIcon from "../assets/frame_137.svg";
import NewsIcon from "../assets/frame_137_1.svg";
import ChartIcon from "../assets/frame_137_2.svg";
import EducationIcon from "../assets/frame_137_3.svg";
import Nasdaq from "../assets/frame_212.svg";
import Nyse from "../assets/frame_214.svg";
import Chart from "../assets/frame_281_1.svg";
import GieldaPapierowWartosciowych from "../assets/gielda-papierow-wartosciowych-w-warszawie-group-gpw-vector-logo_1.svg";
import SquaberLogo from "../assets/image.png";
import XTB from "../assets/image_28.png";
import AliorBank from "../assets/image_30.png";
import MarcinTuszkiewicz from "../assets/image_36.png";
import CIIICMarker from "../assets/image_40.png";
import Phone from "../assets/iphone-mockup.png";
import HeaderImageMobile from "../assets/iphone-mockup-3_1.png";
import HeaderImageDesktop from "../assets/macbook_pro_1.png";
import SAXO from "../assets/output-onlinepngtools_1.png";
import PLNIcon from "../assets/subtract.svg";
import USDIcon from "../assets/subtract_1.svg";
import EUROIcon from "../assets/subtract_2.svg";
import Trace from "../assets/vector_34.svg";
import AppMock from "../components/appMock";
import { Box } from "../components/box";
import { BoxContent } from "../components/boxContent";
import { BoxIconTitle } from "../components/boxIconTitle";
import Footer from "../components/footer";
import { HorizontalSeparatorWithText } from "../components/horizontalSeparatorWithText";
import NavDesktop from "../components/navDesktop";
import NavMobile from "../components/navMobile";
import PlanTable from "../components/planTable";
import Reviews from "../components/reviews";
import StoreButton from "../components/storeButton";
import StoreQRCode from "../components/storeQRCode";
import VideoBox from "../components/videoBox";

const Homepage = ({ translate }) => {
  useScrollToAnchor();

  const isDesktop = useIsDesktop();
  const isTablet = useIsTablet();

  const [plan, setPlan] = useState("yearly");
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  return (
    <LandingTypography>
      {isDesktop && !isTablet ? (
        <NavDesktop />
      ) : (
        <NavMobile isOpen={isMobileMenuOpen} setIsOpen={setIsMobileMenuOpen} />
      )}
      <main>
        <header
          style={{ paddingTop: "96px" }}
          className={classNames("overflow-hidden bg-header text-white", {
            "px-5": !isDesktop,
            "px-10": isDesktop
          })}
        >
          <div
            className={classNames("max-w-11xl mx-auto", {
              "mt-8": !isDesktop,
              "flex items-center mt-24": isDesktop
            })}
          >
            <div>
              <strong
                className={classNames("highlight-1", {
                  "mb-4": !isDesktop,
                  "mb-6": isDesktop
                })}
              >
                {translate("lp_header_subtitle")}
              </strong>
              <h1
                className={classNames({
                  "mb-6": !isDesktop,
                  "mb-10 max-w-2xl.2": isDesktop
                })}
              >
                {translate("lp_header_title")}
              </h1>
              <p
                className={classNames("opacity-85", {
                  "mb-10": !isDesktop,
                  "mb-12 max-w-2xl.1": isDesktop
                })}
                dangerouslySetInnerHTML={{
                  __html: translate("lp_header_paragraph")
                }}
              />
              <Link
                to={translate("lp_create-account-button_href")}
                className={classNames("button button_full-width", {
                  "mb-10": !isDesktop,
                  "max-w-72": isDesktop
                })}
              >
                {translate("lp_create-account-button_text")}
              </Link>
            </div>
            <img
              src={isDesktop ? HeaderImageDesktop : HeaderImageMobile}
              alt={"homepage_header-image"}
              height={isDesktop ? 689 : undefined}
              width={isDesktop ? undefined : 330}
              className={classNames("header-image block", {
                "mx-auto": !isDesktop
              })}
            />
          </div>
          {isDesktop ? (
            <aside className={"header-aside max-w-11xl mx-auto"}>
              <div
                className={"header-aside__text"}
                dangerouslySetInnerHTML={{
                  __html: translate("lp_header_aside-1")
                }}
              />
              <svg
                className={"header-aside__reverse-border"}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 16.0002H0C8.58826 15.4276 15.4274 8.58827 16 0V16.0002Z"
                  fill="#180245"
                />
              </svg>
              <div className={"header-aside__separator"} />
              <svg
                className={"header-aside__reverse-border"}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 16.0002H16C7.41174 15.4276 0.572551 8.58827 0 0V16.0002Z"
                  fill="#1D0F47"
                />
              </svg>
              <div
                className={"header-aside__text text-right"}
                dangerouslySetInnerHTML={{
                  __html: translate("lp_header_aside-2")
                }}
              />
            </aside>
          ) : null}
        </header>
        <div
          className={classNames("bg-blue text-white bg-blue-circle", {
            "pb-120": isDesktop,
            "pb-19": !isDesktop
          })}
        >
          <section
            className={classNames("max-w-11xl flow-root mx-auto", {
              "px-5": !isDesktop,
              "px-10": isDesktop
            })}
            id={translate("lp_section-1_id")}
          >
            <h2
              className={classNames("text-center", {
                "-mt-6 mb-24": isDesktop,
                "mt-12 mb-10": !isDesktop
              })}
              dangerouslySetInnerHTML={{
                __html: translate("lp_section-1_title")
              }}
            />
            {isDesktop ? (
              <table
                className={"w-full plus-table plus-table_4-col mx-auto mb-37"}
              >
                <tbody>
                  <tr>
                    <td>
                      <span
                        className={"text-3xl font-medium block"}
                        dangerouslySetInnerHTML={{
                          __html: translate("lp_section-1_table-desktop_cell-1")
                        }}
                      />
                    </td>
                    <td>
                      <div
                        className={
                          "flex flex-wrap items-center gap-x-8 gap-y-4 text-center justify-center"
                        }
                      >
                        <img
                          src={GieldaPapierowWartosciowych}
                          height="64"
                          alt={""}
                        />
                        <span
                          className={"text-lg"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-desktop_cell-2"
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          "flex flex-wrap items-center gap-x-8 gap-y-4 text-center justify-center"
                        }
                      >
                        <img src={Nasdaq} height="64" alt={""} />
                        <span
                          className={"text-lg"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-desktop_cell-3"
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          "flex flex-wrap items-center gap-x-8 gap-y-4 text-center justify-center"
                        }
                      >
                        <img src={Crypto} height="64" alt={""} />
                        <span
                          className={"text-lg"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-desktop_cell-4"
                            )
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className={
                          "flex flex-wrap items-center gap-x-8 gap-y-4 text-center justify-center"
                        }
                      >
                        <img src={Nyse} height="64" alt={""} />
                        <span
                          className={"text-lg"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-desktop_cell-5"
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          "flex flex-wrap items-center gap-x-8 gap-y-4 text-center justify-center"
                        }
                      >
                        <img src={Materials} height="64" alt={""} />
                        <span
                          className={"text-lg"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-desktop_cell-6"
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          "flex flex-wrap items-center gap-x-8 gap-y-4 text-center justify-center"
                        }
                      >
                        <img src={Battery} height="64" alt={""} />
                        <span
                          className={"text-lg"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-desktop_cell-7"
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          "flex flex-wrap items-center gap-x-8 gap-y-4 text-center justify-center"
                        }
                      >
                        <img src={Coins} height="64" alt={""} />
                        <span
                          className={"text-lg"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-desktop_cell-8"
                            )
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <table className={"plus-table plus-table_2-col mx-auto mb-17"}>
                <tbody>
                  <tr>
                    <td>
                      <div
                        className={
                          "flex flex-col items-center gap-1 text-center"
                        }
                      >
                        <img
                          src={GieldaPapierowWartosciowych}
                          height="40"
                          alt={""}
                        />
                        <span
                          className={"text-sm"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-mobile_cell-1"
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          "flex flex-col items-center gap-1 text-center"
                        }
                      >
                        <img src={Nyse} height="40" alt={""} />
                        <span
                          className={"text-sm"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-mobile_cell-2"
                            )
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className={
                          "flex flex-col items-center gap-1 text-center"
                        }
                      >
                        <img src={Nasdaq} height="40" alt={""} />
                        <span
                          className={"text-sm"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-mobile_cell-3"
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          "flex flex-col items-center gap-1 text-center"
                        }
                      >
                        <img src={Crypto} height="40" alt={""} />
                        <span
                          className={"text-sm"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-mobile_cell-4"
                            )
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className={
                          "flex flex-col items-center gap-1 text-center"
                        }
                      >
                        <img src={Materials} height="40" alt={""} />
                        <span
                          className={"text-sm"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-mobile_cell-5"
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <div
                        className={
                          "flex flex-col items-center gap-1 text-center"
                        }
                      >
                        <img src={Battery} height="40" alt={""} />
                        <span
                          className={"text-sm"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-mobile_cell-6"
                            )
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div
                        className={
                          "flex flex-col items-center gap-1 text-center"
                        }
                      >
                        <img src={Coins} height="40" alt={""} />
                        <span
                          className={"text-sm"}
                          dangerouslySetInnerHTML={{
                            __html: translate(
                              "lp_section-1_table-mobile_cell-7"
                            )
                          }}
                        />
                      </div>
                    </td>
                    <td>
                      <span
                        className={"text-lg font-medium text-right block"}
                        dangerouslySetInnerHTML={{
                          __html: translate("lp_section-1_table-mobile_cell-8")
                        }}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            )}
          </section>
          <section
            className={classNames("max-w-11xl mx-auto", {
              "px-5": !isDesktop,
              "px-10": isDesktop
            })}
            id={translate("lp_section-2_id")}
          >
            <h2
              className={classNames("text-center", {
                "mb-24": isDesktop,
                "mb-10": !isDesktop
              })}
              dangerouslySetInnerHTML={{
                __html: translate("lp_section-2_title")
              }}
            />
            <div
              className={classNames("custom-grid", {
                "gap-10": isDesktop,
                "gap-4": !isDesktop
              })}
            >
              <Box className={"custom-grid__a"}>
                <BoxContent>
                  <BoxIconTitle
                    title={translate("lp_section-2_grid_cell-1_title")}
                    icon={BellIcon}
                  />
                </BoxContent>
                <img src={Chart} alt={""} className={"w-full"} />
              </Box>
              <Box className={"custom-grid__b"}>
                <BoxContent>
                  <BoxIconTitle
                    title={translate("lp_section-2_grid_cell-2_title")}
                    icon={NewsIcon}
                  />
                  <p
                    className={classNames("opacity-65", {
                      "mt-10 mb-5": isDesktop,
                      "mt-6 mb-2": !isDesktop
                    })}
                  >
                    {translate("lp_section-2_grid_cell-2_paragraph")}
                  </p>
                </BoxContent>
              </Box>
              <Box className={"custom-grid__c overflow-hidden"}>
                <BoxContent>
                  <BoxIconTitle
                    title={translate("lp_section-2_grid_cell-3_title")}
                    icon={WalletIcon}
                    classNameChildren={"overflow-hidden"}
                  >
                    <div className={"flex gap-4 items-center m-1.5"}>
                      <strong
                        className={classNames("text-yellow font-semibold", {
                          "text-2xl": isDesktop,
                          "text-base": !isDesktop
                        })}
                      >
                        {translate("lp_section-2_grid_cell-3_subtitle-1")}
                      </strong>
                      <div className={"hr-vertical hr-vertical_24"} />
                      <strong
                        className={classNames("text-yellow font-semibold", {
                          "text-2xl": isDesktop,
                          "text-base": !isDesktop
                        })}
                      >
                        {translate("lp_section-2_grid_cell-3_subtitle-2")}
                      </strong>
                      <div className={"hr-vertical hr-vertical_24"} />
                      <strong
                        className={classNames(
                          "text-yellow font-semibold text-ellipsis overflow-hidden",
                          {
                            "text-2xl": isDesktop,
                            "text-base": !isDesktop
                          }
                        )}
                      >
                        {translate("lp_section-2_grid_cell-3_subtitle-3")}
                      </strong>
                    </div>
                  </BoxIconTitle>
                  <div
                    className={
                      "flex gap-2 justify-center items-center mt-6 mb-5"
                    }
                  >
                    <strong
                      className={classNames("font-medium opacity-65", {
                        "text-base": isDesktop,
                        "text-xs": !isDesktop
                      })}
                    >
                      {translate("lp_section-2_grid_cell-3_text-1")}
                    </strong>
                    <div className={"hr-vertical hr-vertical_24"} />
                    <strong
                      className={classNames("font-medium opacity-65", {
                        "text-base": isDesktop,
                        "text-xs": !isDesktop
                      })}
                    >
                      {translate("lp_section-2_grid_cell-3_text-2")}
                    </strong>
                    <div className={"hr-vertical hr-vertical_24"} />
                    <strong
                      className={classNames("font-medium opacity-65", {
                        "text-base": isDesktop,
                        "text-xs": !isDesktop
                      })}
                    >
                      {translate("lp_section-2_grid_cell-3_text-3")}
                    </strong>
                  </div>
                  <div className={"flex justify-around mb-2"}>
                    <div className={"flex gap-2"}>
                      <img
                        src={PLNIcon}
                        alt={""}
                        width={isDesktop ? "24" : "20"}
                        height={isDesktop ? "24" : "20"}
                      />
                      <span
                        className={classNames("text-yellow font-medium", {
                          "text-base": isDesktop,
                          "text-xs": !isDesktop
                        })}
                      >
                        {translate("lp_section-2_grid_cell-3_text-4")}
                      </span>
                    </div>
                    <div className={"flex gap-2"}>
                      <img
                        src={USDIcon}
                        alt={""}
                        width={isDesktop ? "24" : "20"}
                        height={isDesktop ? "24" : "20"}
                      />
                      <span
                        className={classNames("text-yellow font-medium", {
                          "text-base": isDesktop,
                          "text-xs": !isDesktop
                        })}
                      >
                        {translate("lp_section-2_grid_cell-3_text-5")}
                      </span>
                    </div>
                    <div className={"flex gap-2"}>
                      <img
                        src={EUROIcon}
                        alt={"EURO"}
                        width={isDesktop ? "24" : "20"}
                        height={isDesktop ? "24" : "20"}
                      />
                      <span
                        className={classNames("text-yellow font-medium", {
                          "text-base": isDesktop,
                          "text-xs": !isDesktop
                        })}
                      >
                        {translate("lp_section-2_grid_cell-3_text-6")}
                      </span>
                    </div>
                  </div>
                </BoxContent>
              </Box>
              <Box className={"custom-grid__d"}>
                <BoxContent>
                  <BoxIconTitle
                    title={translate("lp_section-2_grid_cell-4_title")}
                    icon={PcIcon}
                  />
                  <ul className={"mb-0 pl-0 list-none"}>
                    <li
                      className={classNames("mt-6 font-medium", {
                        "text-sm": !isDesktop,
                        "text-lg": isDesktop
                      })}
                      dangerouslySetInnerHTML={{
                        __html: translate("lp_section-2_grid_cell-4_text-1")
                      }}
                    />
                    <li
                      className={classNames("mt-6 mb-2 font-medium", {
                        "text-sm": !isDesktop,
                        "text-lg": isDesktop
                      })}
                      dangerouslySetInnerHTML={{
                        __html: translate("lp_section-2_grid_cell-4_text-2")
                      }}
                    />
                  </ul>
                </BoxContent>
              </Box>
              <Box className={"custom-grid__e flex flex-col justify-between"}>
                <BoxContent>
                  <BoxIconTitle
                    title={translate("lp_section-2_grid_cell-5_title")}
                    icon={ChartIcon}
                  />
                </BoxContent>
                <ul
                  className={classNames(
                    "mb-0 pl-0 list-none bg-circle grid justify-items-center grid-cols-2 gap-4 pb-8",
                    { "pb-6 px-4": !isDesktop, "pb-8": isDesktop }
                  )}
                >
                  <li className={"li-as-box col-span-2"}>
                    {translate("lp_section-2_grid_cell-5_text-1")}
                  </li>
                  <li className={"li-as-box"}>
                    {translate("lp_section-2_grid_cell-5_text-2")}
                  </li>
                  <li className={"li-as-box"}>
                    {translate("lp_section-2_grid_cell-5_text-3")}
                  </li>
                </ul>
              </Box>
              <Box className={"custom-grid__f overflow-hidden"}>
                <BoxContent>
                  <BoxIconTitle
                    title={translate("lp_section-2_grid_cell-6_title")}
                    icon={EducationIcon}
                  />
                  <div
                    className={classNames("flex gap-4 items-center mt-6 mb-2", {
                      "text-lg": isDesktop,
                      "text-sm": !isDesktop
                    })}
                  >
                    <span>{translate("lp_section-2_grid_cell-6_text-1")}</span>
                    <img src={Trace} alt={""} />
                    <ul
                      className={
                        "mb-0 pl-0 list-none flex flex-col gap-6 overflow-hidden"
                      }
                    >
                      <li
                        className={"text-yellow text-ellipsis overflow-hidden"}
                      >
                        {translate("lp_section-2_grid_cell-6_text-2")}
                      </li>
                      <li
                        className={"text-yellow text-ellipsis overflow-hidden"}
                      >
                        {translate("lp_section-2_grid_cell-6_text-3")}
                      </li>
                    </ul>
                  </div>
                </BoxContent>
              </Box>
              {isDesktop && !isTablet ? (
                <img
                  src={Phone}
                  alt={translate("lp_section-2_image-alt")}
                  className={
                    "custom-grid__g max-w-full col-start-2 col-end-2 row-start-1 row-end-4 self-center justify-self-center"
                  }
                />
              ) : (
                <div className={"custom-grid__g"}>
                  <div className={"img-highlight px-5 text-center"}>
                    <img
                      src={Phone}
                      alt={translate("lp_section-2_image_alt")}
                      className={"max-w-full"}
                      style={{
                        maxHeight: "750px",
                        clipPath: "inset(0 0 218px 0)"
                      }}
                    />
                  </div>
                  <div
                    className={
                      "py-8 px-6 bg-blue-1 border-t-3 rounded-b-xl mb-4"
                    }
                  >
                    <span
                      className={"block mb-6 text-2xl font-medium text-center"}
                      dangerouslySetInnerHTML={{
                        __html: translate("lp_section-2_image_text")
                      }}
                    />
                    <Link
                      to={translate("lp_create-account-button_href")}
                      className={"button button_full-width"}
                    >
                      {translate("lp_create-account-button_long-text")}
                    </Link>
                  </div>
                </div>
              )}
              {isDesktop && !isTablet ? (
                <Box className={"custom-grid__h col-span-3 mb-20"}>
                  <BoxContent className={"flex justify-between gap-4"}>
                    <BoxIconTitle
                      classNameTitle={"text-2xl.1"}
                      icon={PercentageIcon}
                      title={translate("lp_section-2_grid_cell-7_title")}
                    />
                    <ul
                      className={
                        "mb-0 pl-0 flex list-none font-semibold max-w-120 "
                      }
                    >
                      <li className={"flex items-center"}>
                        <div
                          className={"flex justify-between items-center gap-4"}
                        >
                          <img
                            src={SAXO}
                            alt={translate(
                              "lp_section-2_grid_cell-7_image-1_alt"
                            )}
                            height={"40"}
                          />
                          <span
                            className={"text-sm"}
                            dangerouslySetInnerHTML={{
                              __html: translate(
                                "lp_section-2_grid_cell-7_image-1_text"
                              )
                            }}
                          />
                        </div>
                        <div className={"hr-vertical hr-vertical_56 mx-12"} />
                      </li>
                      <li className={"flex items-center"}>
                        <div
                          className={"flex justify-between items-center gap-4"}
                        >
                          <img
                            src={XTB}
                            alt={translate(
                              "lp_section-2_grid_cell-7_image-2_alt"
                            )}
                            height={"40"}
                          />
                          <span
                            className={"text-sm"}
                            dangerouslySetInnerHTML={{
                              __html: translate(
                                "lp_section-2_grid_cell-7_image-2_text"
                              )
                            }}
                          />
                        </div>
                        <div className={"hr-vertical hr-vertical_56 mx-12"} />
                      </li>
                      <li className={"flex items-center"}>
                        <div
                          className={"flex justify-between items-center gap-4"}
                        >
                          <img
                            src={AliorBank}
                            alt={translate(
                              "lp_section-2_grid_cell-7_image-3_alt"
                            )}
                            height={"40"}
                          />
                          <span
                            className={"text-sm"}
                            dangerouslySetInnerHTML={{
                              __html: translate(
                                "lp_section-2_grid_cell-7_image-3_text"
                              )
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                  </BoxContent>
                </Box>
              ) : (
                <Box className={"custom-grid__h"}>
                  <BoxContent>
                    <h3 className={"mb-4"}>
                      {translate("lp_section-2_grid_cell-7_title")}
                    </h3>
                    <ul className={"mb-0 pl-0 list-none font-semibold"}>
                      <li>
                        <div
                          className={"flex justify-between items-center gap-4"}
                        >
                          <img
                            src={SAXO}
                            alt={translate(
                              "lp_section-2_grid_cell-7_image-1_alt"
                            )}
                            height={"40"}
                          />
                          <span
                            className={"text-right text-sm"}
                            dangerouslySetInnerHTML={{
                              __html: translate(
                                "lp_section-2_grid_cell-7_image-1_text"
                              )
                            }}
                          />
                        </div>
                        <div className={"hr-horizontal my-4"} />
                      </li>
                      <li>
                        <div
                          className={"flex justify-between items-center gap-4"}
                        >
                          <img
                            src={XTB}
                            alt={translate(
                              "lp_section-2_grid_cell-7_image-2_alt"
                            )}
                            height={"40"}
                          />
                          <span
                            className={"text-right text-sm"}
                            dangerouslySetInnerHTML={{
                              __html: translate(
                                "lp_section-2_grid_cell-7_image-2_text"
                              )
                            }}
                          />
                        </div>

                        <div className={"hr-horizontal my-4"} />
                      </li>
                      <li className={"mb-2"}>
                        <div
                          className={"flex justify-between items-center gap-4"}
                        >
                          <img
                            src={AliorBank}
                            alt={translate(
                              "lp_section-2_grid_cell-7_image-3_alt"
                            )}
                            height={"40"}
                          />
                          <span
                            className={"text-right text-sm"}
                            dangerouslySetInnerHTML={{
                              __html: translate(
                                "lp_section-2_grid_cell-7_image-3_text"
                              )
                            }}
                          />
                        </div>
                      </li>
                    </ul>
                  </BoxContent>
                </Box>
              )}
            </div>
            {isDesktop && !isTablet ? (
              <Link
                to={translate("lp_create-account-button_href")}
                className={"button button_full-width max-w-min mx-auto"}
              >
                {translate("lp_create-account-button_long-text")}
              </Link>
            ) : null}
          </section>
        </div>
        <div className={"bg-black-1 text-white flow-root"}>
          <section
            className={classNames(
              "bg-gray text-black max-w-13xl py-12 mx-auto",
              {
                "rounded-6xl mx-6 px-4 py-23 mb-36 -mt-115": isDesktop,
                "rounded-2xl px-5 py-12 mb-19": !isDesktop
              }
            )}
            id={translate("lp_section-3_id")}
          >
            <div className={"max-w-10xl mx-auto"}>
              <h2
                className={classNames("text-center", {
                  "mb-6": !isDesktop,
                  "mb-8": isDesktop
                })}
              >
                {translate("lp_section-3_title")}
              </h2>
              <p
                className={classNames(
                  "text-center max-w-89 opacity-65 mx-auto",
                  {
                    "mb-20": !isDesktop,
                    "mb-16": isDesktop
                  }
                )}
              >
                {translate("lp_section-3_paragraph")}
              </p>
              <PlanTable plan={plan} setPlan={setPlan} />
            </div>
          </section>
        </div>
        <div className={"bg-black text-white"}>
          <section
            id={translate("lp_section-4_id")}
            className={"max-w-11xl mx-auto"}
          >
            <div
              className={classNames("flex gap-10 justify-between", {
                "mb-32 px-10": isDesktop,
                "flex-wrap mb-12 px-5": !isDesktop
              })}
            >
              <div className={"max-w-90"}>
                <strong
                  className={classNames("text-yellow block font-medium", {
                    "text-xl mb-6": isDesktop,
                    "text-base mb-4": !isDesktop
                  })}
                >
                  {translate("lp_section-4_subtitle")}
                </strong>
                <h2
                  className={classNames({
                    "mb-10 text-5xl": isDesktop,
                    "mb-6": !isDesktop
                  })}
                  dangerouslySetInnerHTML={{
                    __html: translate("lp_section-4_title")
                  }}
                />
                <p className={"opacity-65"}>
                  {translate("lp_section-4_paragraph")}
                </p>
              </div>
              <ul
                className={classNames("grid grid-cols-2 mb-0 pl-0 list-none", {
                  "gap-10": isDesktop,
                  "gap-y-6 gap-x-5": !isDesktop
                })}
              >
                <li className={"flex flex-col"}>
                  <strong
                    className={classNames("text-yellow block font-bold", {
                      "text-6xl mb-6": isDesktop,
                      "text-4xl.1 mb-3": !isDesktop
                    })}
                  >
                    {translate("lp_section-4_item-1_title")}
                  </strong>
                  <span
                    className={classNames("opacity-65 block", {
                      "text-base mb-10": isDesktop,
                      "text-xs mb-6": !isDesktop
                    })}
                  >
                    {translate("lp_section-4_item-1_paragraph")}
                  </span>
                  <div className={"hr-horizontal mt-auto"} />
                </li>
                <li className={"flex flex-col"}>
                  <strong
                    className={classNames("text-yellow block font-bold", {
                      "text-6xl mb-6": isDesktop,
                      "text-4xl.1 mb-3": !isDesktop
                    })}
                  >
                    {translate("lp_section-4_item-2_title")}
                  </strong>
                  <span
                    className={classNames("opacity-65 block", {
                      "text-base mb-10": isDesktop,
                      "text-xs mb-6": !isDesktop
                    })}
                  >
                    {translate("lp_section-4_item-2_paragraph")}
                  </span>
                  <div className={"hr-horizontal mt-auto"} />
                </li>
                <li>
                  <strong
                    className={classNames("text-yellow block font-bold", {
                      "text-6xl mb-6": isDesktop,
                      "text-4xl.1 mb-3": !isDesktop
                    })}
                  >
                    {translate("lp_section-4_item-3_title")}
                  </strong>
                  <span
                    className={classNames("opacity-65 block", {
                      "text-base mb-10": isDesktop,
                      "text-xs mb-6": !isDesktop
                    })}
                  >
                    {translate("lp_section-4_item-3_paragraph")}
                  </span>
                </li>
                <li>
                  <strong
                    className={classNames("text-yellow block font-bold", {
                      "text-6xl mb-6": isDesktop,
                      "text-4xl.1 mb-3": !isDesktop
                    })}
                  >
                    {translate("lp_section-4_item-4_title")}
                  </strong>
                  <span
                    className={classNames("opacity-65 block", {
                      "text-base mb-10": isDesktop,
                      "text-xs mb-6": !isDesktop
                    })}
                  >
                    {translate("lp_section-4_item-4_paragraph")}
                  </span>
                </li>
              </ul>
            </div>
            {isDesktop ? (
              <div
                className={classNames("reviews", {
                  "px-10": isDesktop,
                  "px-5": !isDesktop
                })}
              >
                <Reviews />
              </div>
            ) : (
              <ScrollContainer
                className={classNames("reviews", {
                  "px-10": isDesktop,
                  "px-5": !isDesktop
                })}
              >
                <Reviews />
              </ScrollContainer>
            )}
          </section>
          <section
            className={classNames("max-w-11xl mx-auto bg-yellow-light", {
              "pt-36 px-10": isDesktop,
              "pt-19 px-5": !isDesktop
            })}
            id={translate("lp_section-5_id")}
          >
            <h2 className={"text-center mb-6"}>
              {translate("lp_section-5_title")}
            </h2>
            <p
              className={classNames("text-center opacity-65", {
                "mb-38": isDesktop,
                "mb-31": !isDesktop
              })}
            >
              {translate("lp_section-5_paragraph")}
            </p>
            <div
              className={classNames("flex", {
                "gap-12": isDesktop,
                "flex-col gap-6": !isDesktop
              })}
            >
              <VideoBox
                chanelHref={translate("lp_video-1_channel-href")}
                channelImageAlt={translate("lp_video-1_channel-image-alt")}
                videoTitle={translate("lp_video-1_video-title")}
                subscriptionCount={translate("lp_video-1_subscription-count")}
                chanelImage={MarcinTuszkiewicz}
                videoHref={translate("lp_video-1_video-href")}
                videoImage={TeSpolkiWartoMiecWPortfelu}
                videoImageAlt={translate("lp_video-1_video-image_alt")}
              />
              <VideoBox
                chanelHref={translate("lp_video-2_channel-href")}
                channelImageAlt={translate("lp_video-2_channel-image-alt")}
                videoTitle={translate("lp_video-2_video-title")}
                subscriptionCount={translate("lp_video-2_subscription-count")}
                chanelImage={SquaberLogo}
                videoHref={translate("lp_video-2_video-href")}
                videoImage={KupujSpolkiChinskie}
                videoImageAlt={translate("lp_video-2_video-image_alt")}
              />
            </div>
          </section>
          <div className={"bg-yellow-circle flow-root"}>
            <section
              id={translate("lp_section-6_id")}
              className={classNames("max-w-11xl mx-auto", {
                "px-10 mb-36 mt-36": isDesktop,
                "px-5 mb-19 mt-19": !isDesktop
              })}
            >
              <div
                className={classNames({
                  "grid gap-12 grid-cols-2": isDesktop
                })}
              >
                {isDesktop ? <AppMock /> : null}
                <div>
                  <h2
                    className={classNames({
                      "mb-8 text-5xl": isDesktop,
                      "mb-6": !isDesktop
                    })}
                  >
                    {translate("lp_section-6_title")}
                  </h2>
                  <p
                    className={classNames("opacity-65", {
                      "mb-14": isDesktop,
                      "mb-8": !isDesktop
                    })}
                  >
                    {translate("lp_section-6_paragraph")}
                  </p>
                  {isDesktop ? (
                    <div className={"flex flex-wrap gap-6 mb-14"}>
                      <StoreQRCode />
                      <StoreQRCode type={"playstore"} />
                    </div>
                  ) : null}
                  {!isDesktop ? <AppMock /> : null}
                  {!isDesktop ? (
                    <div className={"flex gap-5 mx-auto mb-16"}>
                      <StoreButton />
                      <StoreButton type={"playstore"} />
                    </div>
                  ) : null}
                  <HorizontalSeparatorWithText
                    className={"mb-6"}
                    text={translate("lp_section-6_partners_title")}
                  />
                  <div className={"flex justify-between items-center"}>
                    <img
                      src={SAXO}
                      width={isDesktop ? "82" : "57"}
                      alt={"SAXO"}
                    />
                    <img
                      src={XTB}
                      width={isDesktop ? "94" : "66"}
                      alt={"XTB"}
                    />
                    <img
                      src={AliorBank}
                      width={isDesktop ? "78" : "54"}
                      alt={"AliorBank"}
                    />
                    <img
                      src={CIIICMarker}
                      width={isDesktop ? "77" : "54"}
                      alt={"CIICMarket"}
                    />
                  </div>
                </div>
              </div>
            </section>
            <aside
              className={classNames("max-w-11xl mx-auto", {
                "px-10 mb-36": isDesktop,
                "px-5 mb-19": !isDesktop
              })}
            >
              <div
                className={classNames(
                  "rounded-2xl bg-banner flex justify-between",
                  {
                    "p-16 items-center gap-10": isDesktop,
                    "flex-col px-6 py-10 gap-8": !isDesktop
                  }
                )}
              >
                <strong
                  className={classNames("max-w-2xl", {
                    "text-5xl": isDesktop,
                    "text-3xl": !isDesktop
                  })}
                >
                  {translate("lp_banner_text")}
                </strong>
                <Link
                  to={translate("lp_create-account-button_href")}
                  className={classNames("button", {
                    "min-w-64": isDesktop,
                    "button_full-width": !isDesktop
                  })}
                >
                  {translate("lp_create-account-button_text")}
                </Link>
              </div>
            </aside>
          </div>
        </div>
      </main>
      <div className={"bg-black text-white bg-yellow-circle-1"}>
        <Footer />
      </div>
    </LandingTypography>
  );
};

export default connect()(Homepage);
