const pageTypes = {
  "/stock/": "stock",
  "/pulpit": "pulpit",
  "/register": "registration",
  "/login": "login",
  "/portfolio": "portfolio",
  "/settings": "settings",
  "/payment-details": "payment_details"
};

function getPageType(pathname) {
  let pageType = "other";

  for (let key in pageTypes) {
    if (pageTypes.hasOwnProperty(key)) {
      if (pathname.indexOf(key) !== -1) {
        pageType = pageTypes[key];
      }
    }
  }

  return pageType;
}

export default function getPageVisitEventData(url) {
  if (url === "/") {
    // Skip tracking this event as it will be tracked in l.squaber.com
    return;
  }

  const splitLocation = url.split("/").filter(element => !!element);

  const page_type = getPageType(url);
  let ticker = null;
  let market = null;
  let offer_id = null;

  if (page_type === "stock") {
    market = splitLocation[splitLocation.length - 2];
    ticker = splitLocation[splitLocation.length - 1];
  }

  if (page_type === "payment_details") {
    offer_id = splitLocation[splitLocation.length - 1];
  }

  const eventData = {
    page_type,
    ticker,
    market,
    offer_id
  };

  for (let key in eventData) {
    if (eventData.hasOwnProperty(key)) {
      if (eventData[key] === null) {
        delete eventData[key];
      }
    }
  }

  return eventData;
}
