import React from "react";
import withQuoteData from "../../../../../../hocs/withQuoteData";
import ChangePercent from "../../../ChangePercent";

const DailyChangeFromFirebase = ({ emptyQuoteData, quoteData }) => {
  if (emptyQuoteData) {
    return null;
  }

  return (
    <div className="daily-change-from-firebase">
      <ChangePercent change={quoteData.change} />
    </div>
  );
};

export default withQuoteData()(DailyChangeFromFirebase);
