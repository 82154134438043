import "../styles/default.scss";

import React from "react";

import withPortfolioSummary from "../../../../../hocs/withPortfolioSummary";
import PortfolioSummaryItem from "../../PortfolioSummaryItem";

const PortfolioSummary = ({
  items,
  errors
}: {
  errors?: { [key: string]: string }
}) => {
  return (
    <div className="portfolio-summary">
      {items.map((item, key) => {
        const errorMessage = errors?.[item.key];

        return (
          <PortfolioSummaryItem
            key={key}
            errorMessage={errorMessage}
            {...item}
          />
        );
      })}
    </div>
  );
};

PortfolioSummary.propTypes = {};

export default withPortfolioSummary(PortfolioSummary);
