import React from "react";
import classNames from "classnames";
import withTranslations from "../../../../../../hocs/withTranslations";
import "./RowsPerPageSelect.scss";

@withTranslations
class RowsPerPageSelect extends React.Component {
  options = [20, 40, 60, 80, 100];

  state = {
    open: false
  };

  setOpen(value) {
    this.setState({
      open: value
    });
  }

  render() {
    const { translate, value } = this.props;
    const { open } = this.state;

    return (
      <div
        className={classNames({
          "rows-per-page-select": true,
          open
        })}
      >
        <div
          className="description-wrapper"
          onClick={() => {
            this.setOpen(!open);
          }}
        >
          <span className="description">{translate("Rows per page")}:</span>
          <span className="value">{value}</span>
          <span className="arrow">
            <i
              className={classNames({
                fal: true,
                "fa-angle-down": !open,
                "fa-angle-up": open
              })}
            />
          </span>
        </div>
        <div
          className="rows-per-page-select-options-underlay"
          onClick={() => {
            this.setOpen(false);
          }}
        />
        <ul className="rows-per-page-select-options">
          {this.options.map(option => (
            <li
              className={classNames({
                "rows-per-page-select-option": true,
                active: value === option
              })}
              key={option}
              onClick={() => {
                this.props.onChange(option);
                this.setOpen(false);
              }}
            >
              {option}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default RowsPerPageSelect;
