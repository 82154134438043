import classNames from "classnames";
import React from "react";

export const HorizontalSeparatorWithText = ({ className, text }) => {
  return (
    <div className={classNames(className, "horizontal-separator-with-text")}>
      <div className={"hr-horizontal"} />
      <span className={"font-base opacity-65"}>{text}</span>
      <div className={"hr-horizontal"} />
    </div>
  );
};
