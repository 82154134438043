import classNames from "classnames";
import React, { useCallback } from "react";

import BaseLink from "../BaseLink";

function DesktopNavigationLink({
  href,
  label,
  children,
  onEnter,
  className,
  target,
  active
}: {
  onEnter?: ({
    children: any,
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLLinkElement>
  }) => void,
  onLeave?: ({
    children: any,
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLLinkElement>
  }) => void
}) {
  const handleEnter = useCallback(
    async ({ event }) => {
      if (typeof onEnter !== "function") return;
      event.stopPropagation();

      await onEnter({ event });
    },
    [onEnter]
  );

  return (
    <BaseLink
      href={href}
      label={label}
      children={children}
      onEnter={handleEnter}
      className={classNames("desktop-navigation__link", className, {
        "desktop-navigation__link_active": active
      })}
      target={target}
    />
  );
}

export default DesktopNavigationLink;
