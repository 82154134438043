import Cookies from "js-cookie";
import TagManager from "react-gtm-module";
import config from "../../config";
import ConsentModeHelperAbstract from "./ConsentModeHelperAbstract";

export default class ConsentModeHelperWeb extends ConsentModeHelperAbstract {
  static excludedPathPatterns = [
    /\/fullscreenchart\/\w+/ // will match paths containing "/fullscreenchart/" part
  ];

  static shouldShowModal() {
    const { pathname } = window.location;

    for (let excludedPathPattern of ConsentModeHelperWeb.excludedPathPatterns) {
      if (excludedPathPattern.test(pathname)) {
        return false;
      }
    }

    return true;
  }

  static applyDefaultConsent() {
    window.dataLayer = window.dataLayer || [];
    window.gtag =
      window.gtag ||
      function() {
        window.dataLayer.push(arguments);
      };

    const defaultConsents = {};

    for (let consentOption of ConsentModeHelperWeb.consentOptions) {
      const isEnabledByDefault = ConsentModeHelperWeb.consentEnabledByDefault.includes(
        consentOption
      );
      defaultConsents[consentOption] = isEnabledByDefault
        ? "granted"
        : "denied";
    }

    window.gtag("consent", "default", {
      ...defaultConsents,
      wait_for_update: 500
    });
    dataLayer.push({
      "gtm.start": new Date().getTime(),
      event: "gtm.js"
    });
  }

  static attemptLoadConsentSettings() {
    const settings = Cookies.get(ConsentModeHelperWeb.STORAGE_ITEM_NAME);

    if (settings) {
      try {
        return JSON.parse(settings);
      } catch (e) {
        console.error("Failed to parse consent settings");
        console.error(e);
      }
    }

    return false;
  }

  static applyConsentSettings(settings) {
    window.gtag("consent", "update", {
      ...settings
    });

    if (config.gtm.trackingId) {
      TagManager.initialize({ gtmId: config.gtm.trackingId });
    }

    Cookies.set(
      ConsentModeHelperWeb.STORAGE_ITEM_NAME,
      JSON.stringify(settings),
      {
        expires: 360
      }
    );
  }
}
