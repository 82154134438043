import React from "react";
import { withRouter } from "react-router";
import withLocation from "../../../../../hocs/withLocation";
import withUserData from "../../../../../hocs/withUserData";
import withPaymentDetails from "../../../../../hocs/withPaymentDetails";
import withAppStatus from "../../../../../hocs/withAppStatus";
import withDeviceType from "../../../../../hocs/withDeviceType";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withNavbarHeight from "../../../../../hocs/withNavbarHeight";
import { UrlProvider } from "../../../../../api/UrlProvider";

import HeaderAbstractWeb from "./default";

import logo from "../../../../../logo";
import config from "../../../../../config";

import "../styles/squaber.scss";

@withLocation
@withRouter
@withUserData
@withPaymentDetails
@withAppStatus
@withDeviceType
@withBottomNavigation("bottomNavigationValue")
@withNavbarHeight
class Header extends HeaderAbstractWeb {
  getTextLogo() {
    return (
      <img src={logo.text} className="img-fluid logo-text" alt={config.title} />
    );
  }

  getRegisterUrl() {
    const { locale } = this.props;

    return UrlProvider.getUrl("fe.registerWithRedirect", {
      locale,
      redirectUrl: window.location.pathname
    });
  }

  getRegisterTarget() {
    return "_self";
  }
}

export default Header;
