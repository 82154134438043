import React from "react";
import withUserData from "../../../../../hocs/withUserData";
import getAccessLevel from "../../../../../lib/access/getAccessLevel";
import accessLevelsMap from "../../../../../lib/access/accessLevelsMap";
import PageTitle from "../../../common/PageTitle/modules/default";
import NoAccessDisclaimer from "../../../common/NoAccessDisclaimer";
import LongTermSignalsList from "../../../common/LongTermSignalsList";

const LongTermSignalPage = ({ userData, translate }) => {
  const accessLevel = getAccessLevel(userData);
  const userHasAccess = accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS;

  return (
    <div className="history-list-page">
      <PageTitle title={translate("long_term_signals_header")} />
      <div className="heading-wrapper">
        <div className="heading">
          <h1>{translate("long_term_signals_header")}</h1>
          <p
            dangerouslySetInnerHTML={{
              __html: translate("long_term_signals_description")
            }}
          />
        </div>
      </div>
      {!userHasAccess ? <NoAccessDisclaimer /> : <LongTermSignalsList />}
    </div>
  );
};

export default withUserData(LongTermSignalPage);
