import classNames from "classnames";
import moment from "moment-mini";
import React from "react";

import config from "../../../../../config";
import withSmEventTracker from "../../../../../hocs/withSmEventTracker";
import CardMockup from "../../../common/CardMockup";
import SquaberButton from "../../../common/SquaberButton";
import { PAYMENT_METHODS } from "../modules/default";
import getDiscountOffer from "../utils/getDiscountOffer";
import GoToPaymentEvent from "../../../../../lib/SalesManago/events/GoToPaymentEvent";

@withSmEventTracker
class Confirmation extends React.Component {
  paylaneForm = null;

  render() {
    const {
      selectedOffer,
      paymentMethods,
      paymentMethod,
      paymentMethodAlias,
      currentPaymentMethod,
      paymentDetails,
      setPaymentMethod,
      isRecurringPaymentMethod,
      currency,
      paymentFormLoading,
      cardModel,
      offerId,
      finalizePayment,
      translate,
      locale,
      small,
      trackSMEvent,
      isSideButton,
      offerPaymentMethods,
      subscriptionId
    } = this.props;

    const discountedOffer = getDiscountOffer({
      ...selectedOffer,
      canDiscount: currentPaymentMethod.canDiscount
    });

    return (
      <React.Fragment>
        <div className={small ? "confirmation-small" : null}>
          <h2 className="payment-method-title">
            {translate("Choose payment method")}
          </h2>
          <div className="payment-method-selector">
            {paymentMethods
              .filter(method => offerPaymentMethods.indexOf(method.name) !== -1)
              .filter(method => {
                if (method.requiresSubscriptionId) {
                  return !!subscriptionId;
                }

                return true;
              })
              .map(method => (
                <div
                  className={classNames({
                    "payment-method": true,
                    active: paymentMethodAlias === method.alias
                  })}
                  key={`${method.name}-${method.alias}`}
                  onClick={() => {
                    setPaymentMethod(method);
                  }}
                >
                  <img src={method.image} alt={method.name} />
                </div>
              ))}
          </div>
          {paymentMethod === PAYMENT_METHODS.VISA ? (
            <div className="card-mockup-outside">
              <CardMockup
                cardModel={cardModel}
                onValueChange={this.setCardModelValue}
              />
            </div>
          ) : null}
          {paymentMethod ? (
            <React.Fragment>
              <div className="payment-finalization-box">
                <span className="text">
                  {isRecurringPaymentMethod
                    ? translate(
                        "An amount of %{amount} %{currency} will be charged %{resaleDate} and will be renewed after every %{days} days by charging the selected payment method until you cancel it in your account settings",
                        {
                          amount: selectedOffer.amount,
                          resaleDate: paymentDetails.resale_date
                            ? translate("on %{date}", {
                                date: moment(paymentDetails.resale_date).format(
                                  "DD.MM.YYYY"
                                )
                              })
                            : translate("today"),
                          days: selectedOffer.periods * 30,
                          currency
                        }
                      )
                    : translate(
                        "An amount of %{amount} %{currency} will be charged today. After the payment has been booked, your access to SQUABER.COM will be extended by %{days} days.",
                        {
                          amount: discountedOffer.amountAfterDiscount,
                          days: selectedOffer.periods * 30,
                          currency
                        }
                      )}
                </span>
                <SquaberButton
                  onClick={() => {
                    const smEvent = new GoToPaymentEvent({
                      button: isSideButton ? "SIDE_BUTTON" : "MAIN_BUTTON",
                      offerAmount: selectedOffer.amount,
                      offerPeriod: selectedOffer.periods,
                      offerId: selectedOffer.id,
                      paymentMethod
                    });

                    trackSMEvent(smEvent);

                    if (paymentMethod === PAYMENT_METHODS.PAYLANE) {
                      this.paylaneForm.submit();
                    } else {
                      finalizePayment({
                        paymentMethod,
                        paymentMethodAlias,
                        cardModel,
                        offer: offerId,
                        subscriptionId
                      });
                    }

                    const dataToSave = {
                      amount: selectedOffer.amount,
                      id: selectedOffer.id
                    };

                    localStorage.setItem(
                      config.paymentDetailsStorageKey,
                      JSON.stringify(dataToSave)
                    );
                  }}
                  disabled={paymentFormLoading}
                >
                  {translate("Proceed to payment")}
                  {paymentFormLoading ? (
                    <i className="fal fa-spinner fa-spin" />
                  ) : null}
                </SquaberButton>
              </div>
            </React.Fragment>
          ) : null}
        </div>
        {selectedOffer ? (
          <div>
            <form
              action="https://secure.paylane.com/order/cart.html"
              method="post"
              id={`PayLaneForm${selectedOffer.id}`}
              ref={element => {
                this.paylaneForm = element;
              }}
            >
              <input
                type="hidden"
                name="amount"
                value={selectedOffer.paylane_form_amount}
              />
              <input
                type="hidden"
                name="currency"
                value={paymentDetails.currency}
              />
              <input
                type="hidden"
                name="merchant_id"
                value={paymentDetails.merchant_id}
              />
              <input
                type="hidden"
                name="description"
                value={selectedOffer.description}
              />
              <input
                type="hidden"
                name="transaction_description"
                value={paymentDetails.transaction_description}
              />
              <input
                type="hidden"
                name="transaction_type"
                value={selectedOffer.transaction_type}
              />
              <input
                type="hidden"
                name="back_url"
                value={selectedOffer.back_url}
              />
              <input type="hidden" name="language" value={locale} />
              <input type="hidden" name="hash" value={selectedOffer.hash} />
            </form>
          </div>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Confirmation;
