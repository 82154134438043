export const getChartData = state => state.tvChart.data;
export const getDividendsData = state => state.tvChart.dividends.data;
export const getDividendsLoading = state => state.tvChart.dividends.loading;
export const getPriceAlertsData = state => state.tvChart.priceAlerts.data;
export const getPriceAlertsLoading = state => state.tvChart.priceAlerts.loading;

export const getChartReportDates = state => state.tvChart.reportDates.data;
export const getInsiderTransactions = state =>
  state.tvChart.insiderTransactions.data;
export const getChartReportDatesLoading = state =>
  state.tvChart.reportDates.loading;

export const getChartMediaMonitorEntries = state =>
  state.tvChart.mediaMonitorEntries;

export const getLastMinMaxPoints = state => state.tvChart.lastMinMaxPoints;
export const getCurrentLastMinMaxPoints = state =>
  state.tvChart.lastMinMaxPoints.current;
