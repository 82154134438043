import React from "react";
import { Container } from "reactstrap";
import connect from "../../../../../i18n/connect";
import agenoLogo from "../../../../../assets/images/logo-ageno.svg";
import config from "../../../../../config";

import "../styles/default.scss";

function getLinks(locale) {
  return config.vendor === "squaber"
    ? [
        {
          label: "Oferta premium",
          href: "https://squaber.com/l/oferta-dostepu-squabera-premium",
          target: "_blank"
        },
        {
          label: "Faq",
          href: `https://squaber.com/${locale}/faq`,
          target: "_blank"
        },
        {
          label: "Blog",
          href: "https://blog.squaber.com/",
          target: "_blank"
        },
        {
          label: "Regulamin",
          href: `https://squaber.com/${locale}/regulations`,
          target: "_blank"
        },
        {
          label: "Kontakt",
          href: `https://squaber.com/${locale}/contact`,
          target: "_blank"
        },
        {
          label: "Oferta reklamowa",
          href: "http://reklamy.investio.pl/",
          target: "_blank",
          rel: "nofollow"
        }
      ]
    : [
        {
          label: "Terms and conditions",
          href:
            "https://s3.eu-central-1.amazonaws.com/panel.expasset.com/1553525484_ddaa5f685f03_ExpRegisterTermsandConditions.pdf",
          target: "_blank",
          rel: "noffollow"
        },
        {
          label: "Contact",
          href: `https://app.expalerts.com/${locale}/contact`,
          target: "_blank",
          rel: "nofollow"
        }
      ];
}

const Footer = ({ translate, locale }) => {
  return (
    <footer className="footer">
      <ul className="footer-links">
        {getLinks(locale).map(({ label, href, target, rel }, index) => (
          <li key={index}>
            <a href={href} target={target} rel={rel}>
              {label}
            </a>
          </li>
        ))}
      </ul>
      <div className="footer-content">
        <Container>
          <div className="disclaimer">
            <p
              dangerouslySetInnerHTML={{
                __html: translate("disclaimer_1", {
                  disclaimerUrl: translate("disclaimerUrl")
                })
              }}
            />
            <p
              dangerouslySetInnerHTML={{ __html: translate("disclaimer_2") }}
            />
          </div>
          <div className="partners">
            <div className="partner-box">
              <span>
                &copy; {new Date().getFullYear()}{" "}
                {config.vendor === "squaber"
                  ? "SQUABER Investio sp. z o.o."
                  : "Expalerts"}
              </span>
            </div>
            {config.vendor === "squaber" ? (
              <React.Fragment>
                <div className="partner-box">
                  <span>{translate("Created by")}</span>
                  <a href="https://ageno.pl" target="_blank" rel={"nofollow"}>
                    <img src={agenoLogo} alt={"Ageno"} />
                  </a>
                </div>
              </React.Fragment>
            ) : null}
          </div>
        </Container>
      </div>
    </footer>
  );
};
export default connect()(Footer);
