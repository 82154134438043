import SquaberIntervalMap from "../TradingView/SquaberIntervalMap";
import getMainSeries from "./getMainSeries";

const getInterval = chartData => getMainSeries(chartData).state?.interval;
export const getIntervalFromIntervalTypeId = intervalTypeId => {
  return Object.keys(SquaberIntervalMap).find(
    key => SquaberIntervalMap[key] === intervalTypeId
  );
};

export default getInterval;
