import "../styles/default.scss";

import PropTypes from "prop-types";
import React, { useEffect, useMemo } from "react";

import Price from "../../../../../common/Price";
import withDividends from "../../../../../hocs/withDividends";
import consolidateDividends from "../../../../../lib/consolidateDividends";
import Loader from "../../Loader";
import SquaberTable from "../../SquaberTable";

const StockDividends = ({
  dividends,
  dividendsLoading,
  fetchDividends,
  currency,
  translate,
  stockId
}) => {
  useEffect(() => {
    fetchDividends(stockId);
  }, [fetchDividends, stockId]);

  const preparedDividends = useMemo(() => consolidateDividends(dividends), [
    dividends
  ]);

  if (dividendsLoading) {
    return <Loader small />;
  }

  return (
    <div className="scrollable-table-wrapper">
      {preparedDividends.length ? (
        <SquaberTable
          withDistinctRows
          className="dividends-table"
          heading={[
            translate("Year"),
            translate("Dividend per share"),
            translate("Dividend yield"),
            translate("Last day with right to dividend"),
            translate("Dividend pay"),
            translate("Dividend payout day")
          ]}
          rows={preparedDividends
            .filter(
              dividend =>
                dividend.payouts.length > 0 ||
                !!dividend.dividend_day ||
                !!dividend.payout_day
            )
            .map(dividend => [
              dividend.year,
              dividend.payouts.map((p, index) => (
                <div key={index}>
                  <Price
                    value={p.payout}
                    currency={dividend.currency || currency}
                  />
                </div>
              )),
              `${Number(dividend.dividend_yield).toFixed(2)}%`,
              dividend.last_day_with_right_to_dividend,
              dividend.dividend_day,
              dividend.payouts.map((p, index) => (
                <div key={index}>{p.payout_day}</div>
              ))
            ])}
        />
      ) : (
        <p>{translate("This stock has no dividends.")}</p>
      )}
    </div>
  );
};

StockDividends.propTypes = {
  dividends: PropTypes.array.isRequired,
  currency: PropTypes.string
};

export default withDividends(StockDividends);
