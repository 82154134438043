export const prepareSectionSlugList = list => {
  let preparedList = {};
  if (list) {
    list.forEach(item => {
      preparedList[item.section_id] = item.slug;
    });
  }
  return preparedList;
};
export const prepareSectionIdList = list => {
  let preparedList = {};
  if (list) {
    list.forEach(item => {
      preparedList[item.slug] = item.section_id;
    });
  }
  return preparedList;
};
