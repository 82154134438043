import React from "react";
import PropTypes from "prop-types";

class RegisterFormAbstract extends React.Component {
  static propTypes = {
    formBuilder: PropTypes.object,
    register: PropTypes.func,
    registerLoading: PropTypes.bool,
    validationErrors: PropTypes.object
  };

  state = {
    isValid: false
  };

  setIsValid(value) {
    this.setState({ isValid: value });
  }

  render() {
    return null;
  }
}

export default RegisterFormAbstract;
