export const getManualStrategies = state => state.manualStrategies;

export const getTransactionStrategies = state => {
  if (state.manualStrategies?.data?.length) {
    return state.manualStrategies.data.filter(
      strategy => strategy.isTransactionStrategy
    );
  }

  return [];
};
