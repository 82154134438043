import React from "react";
import { useSelector } from "react-redux";
import { useRouteMatch } from "react-router";

import withPreloadedStockPageData from "../../../../../../hocs/withPreloadedStockPageData";
import {
  getStockData,
  getStockLoading
} from "../../../../../../redux/modules/stockPageTvChart/selector";
import StockPageHeader from "../../../../common/StockPageHeader";
import StockPageHeaderShimmer from "../../../../common/StockPageHeaderShimmer";

function StockPagePreloadedHeader({ preloadedStockPageData }) {
  const match = useRouteMatch();

  const stock = useSelector(getStockData);
  const stockLoading = useSelector(getStockLoading);

  const currentTicker = match?.params?.stockId;
  const stockData = stock?.stock;
  const preloadedStockData = preloadedStockPageData?.stock?.data;

  const hasCorrectStockData = stockData?.ticker === currentTicker;
  const hasCorrectPreloadedStockData =
    preloadedStockData?.ticker === currentTicker;

  const loading = !hasCorrectPreloadedStockData && stockLoading;

  if (loading) {
    return <StockPageHeaderShimmer />;
  }

  if (hasCorrectPreloadedStockData) {
    return <StockPageHeader stock={preloadedStockData} />;
  }

  if (hasCorrectStockData) {
    return <StockPageHeader stock={stockData} />;
  }

  return null;
}

export default withPreloadedStockPageData(StockPagePreloadedHeader);
