import { union, without } from "lodash";
import { useEffect, useState } from "react";

import { DisplayStyle } from "./AnalysisPersistenceLayer";
import ANALYSIS_SORT_OPTIONS from "./analysisSortOptions";

const DEFAULT_DISPLAY_STYLE = "grid";

const useAnalysisPersistenceLayer = ({ analysisPersistenceLayer }) => {
  const [filters, setFilters] = useState({});
  const [displayStyle, setDisplayStyle] = useState(DEFAULT_DISPLAY_STYLE);

  useEffect(() => {
    analysisPersistenceLayer.getFilters().then(data => {
      setFilters(data);
    });

    analysisPersistenceLayer.getDisplayStyle().then(data => {
      setDisplayStyle(data ?? DEFAULT_DISPLAY_STYLE);
    });
  }, []);

  const handleGetSearchParams = async () => {
    return await analysisPersistenceLayer.toString();
  };

  const handleRestoreFromString = async (value: string) => {
    const data = await analysisPersistenceLayer.restoreFromString(value);

    if (typeof data !== "undefined") {
      setFilters(data);
      return data;
    }
  };

  const handleSetDisplayStyle = async (style: DisplayStyle) => {
    const data = await analysisPersistenceLayer.setDisplayStyle(style);

    if (typeof data !== "undefined") {
      setDisplayStyle(data);
    }
  };

  const handleSetSort = async value => {
    const data = await analysisPersistenceLayer.setSortFilter(value);

    if (typeof data !== "undefined") {
      setFilters(data);
    }
  };

  const handleSetFilteredCategories = async filteredCategoryIds => {
    const data = await analysisPersistenceLayer.setFilteredCategories(
      filteredCategoryIds
    );

    if (typeof data !== "undefined") {
      setFilters(data);
    }
  };

  const handleAppendFilteredCategory = async id => {
    const newFilteredCategoryIds = union(filters?.filteredCategoryIds ?? [], [
      id
    ]);

    await handleSetFilteredCategories(newFilteredCategoryIds);
  };

  const handleRemoveFilteredCategory = async id => {
    const newFilteredCategoryIds = without(
      filters?.filteredCategoryIds ?? [],
      id
    );

    await handleSetFilteredCategories(newFilteredCategoryIds);
  };

  return {
    analysisFilters: filters,
    analysisDisplayStyle: displayStyle,
    getSearchParams: handleGetSearchParams,
    restoreAnalysisFromString: handleRestoreFromString,
    setAnalysisSort: handleSetSort,
    setAnalysisFilteredCategories: handleSetFilteredCategories,
    setAnalysisDisplayStyle: handleSetDisplayStyle,
    appendFilteredCategory: handleAppendFilteredCategory,
    removeFilteredCategory: handleRemoveFilteredCategory,
    analysisSortOptions: ANALYSIS_SORT_OPTIONS
  };
};

export default useAnalysisPersistenceLayer;
