import "../../styles/mobile.scss";

import { Drawer } from "@material-ui/core";
import PropTypes from "prop-types";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from "react";
import { useHistory } from "react-router";

import MobileNavigationLinks from "./MobileNavigationLinks";
import MobileSubmenu from "./MobileSubmenu";

const MobileNavigation = forwardRef(({ items = [], onClose }, ref) => {
  const [open, setOpen] = useState(false);
  const [submenu, setSubmenu] = useState(null);
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen(() => {
      handleClose();
    });

    return () => {
      unlisten();
    };
  }, []);

  useImperativeHandle(ref, () => {
    return {
      isOpen: !!open,
      open: handleOpen,
      close: handleClose
    };
  });

  const handleClose = useCallback(() => {
    if (typeof onClose === "function") {
      onClose();
    }

    setOpen(false);
    handleCloseSubmenu();
  }, []);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleCloseSubmenu = useCallback(() => {
    setSubmenu(null);
  }, []);

  const handleOpenSubmenu = useCallback(props => {
    if (typeof props?.children === "undefined") {
      handleCloseSubmenu();
      return;
    }
    setSubmenu(props);
  }, []);

  return (
    <Drawer
      style={{ zIndex: 300000000 }}
      anchor={"left"}
      open={open}
      onClose={handleClose}
      className={"mobile-navigation"}
    >
      <nav className={"mobile-navigation__content"}>
        <div className={"mobile-navigation__breadcrumb"}>
          <strong className={"mobile-navigation__title"}>Menu</strong>
          <button className={"mobile-navigation__close"} onClick={handleClose}>
            <i className="fal fa-times" />
          </button>
        </div>
        <MobileNavigationLinks
          links={items}
          onOpenSubmenu={handleOpenSubmenu}
          submenu={submenu}
        />
        <MobileSubmenu
          {...submenu}
          onClose={handleCloseSubmenu}
          open={!!submenu}
        />
      </nav>
    </Drawer>
  );
});

MobileNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.arrayOf(
        PropTypes.shape({
          order: PropTypes.number,
          label: PropTypes.string,
          href: PropTypes.string
        })
      ),
      order: PropTypes.number,
      label: PropTypes.string,
      href: PropTypes.string
    })
  )
};

MobileNavigation.displayName = "MobileNavigation";
export default MobileNavigation;
