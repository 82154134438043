const UniversalTableCellTypes = {
  string: "String",
  number: "Number",
  whole_number: "WholeNumber",
  heading: "Heading",
  generated_in_frontend: "GeneratedInFrontEnd",
  boolean: "Boolean",
  number_not_abbreviated: "NumberNotAbbreviated",
  action: "Action",
  date: "Date"
};

export default UniversalTableCellTypes;
