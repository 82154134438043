import "../styles/default.scss";

import qs from "qs";
import React from "react";
import { Redirect } from "react-router";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withMobileMediaQuery from "../../../../../hocs/withMobileMediaQuery";
import withPortfoliosList from "../../../../../hocs/withPortfoliosList";
import withUserData from "../../../../../hocs/withUserData";
import NoPortfolios from "../../../common/NoPortfolios";
import Portfolio from "../../../common/Portfolio";
import PortfoliosError from "../../../common/PortfoliosError";
import PortfoliosList from "../../../common/PortfoliosList";

class PortfoliosPage extends React.Component {
  render() {
    const {
      locale,
      match: {
        params: { portfolioId }
      },
      location: { search },
      portfoliosListState,
      userData,
      isMobileMediaQuery
    } = this.props;

    let shouldAttemptRedirect = true;

    const searchParams = qs.parse(search.replace("?", ""));

    if (!userData || searchParams.no_redirect) {
      shouldAttemptRedirect = false;
    }

    const shouldShowNoPortfolios =
      !userData ||
      (!portfoliosListState.loading &&
        portfoliosListState.data &&
        portfoliosListState.data.portfolios.length === 0);

    if (
      shouldAttemptRedirect &&
      !portfolioId &&
      portfoliosListState.data &&
      portfoliosListState.data.portfolios.length
    ) {
      return (
        <Redirect
          to={UrlProvider.getUrl("fe.portfolio", {
            locale,
            id: portfoliosListState.data.portfolios[0].id
          })}
        />
      );
    }

    if (portfoliosListState.error) {
      return (
        <div className="portfolios-page">
          <PortfoliosError />
        </div>
      );
    }

    return (
      <div className="portfolios-page">
        {!isMobileMediaQuery ? (
          <div className="portfolios-page-sidebar">
            <PortfoliosList
              current={portfolioId}
              portfoliosListState={portfoliosListState}
            />
          </div>
        ) : null}
        <div className="portfolios-page-content">
          {shouldShowNoPortfolios ? (
            <NoPortfolios />
          ) : portfolioId ? (
            <Portfolio id={portfolioId} key={portfolioId} />
          ) : null}
        </div>
      </div>
    );
  }
}

export default compose(
  withUserData,
  withPortfoliosList,
  withMobileMediaQuery
)(PortfoliosPage);
