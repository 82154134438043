import React from "react";
import connect from "../i18n/connect";
import { getMoreTransactionSignals } from "../redux/modules/stockData";
import { getTransactionSignalsLoading } from "../redux/modules/stockData/selector";

export default function withLoadMoreTransactionSignals(WrappedComponent) {
  const mapStateToProps = state => ({
    mediaMonitorEntriesLoading: getTransactionSignalsLoading(state)
  });

  const mapDispatchToProps = {
    getMoreTransactionSignals
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithLoadMoreTransactionSignals extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithLoadMoreTransactionSignals;
}
