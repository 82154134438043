import React from "react";
import "../styles/default.scss";

class IntercomPage extends React.Component {
  componentDidMount() {
    window.Intercom("show");
    document.body.classList.add("is-intercom-page");

    window.Intercom("onHide", function() {
      window.Intercom("show");
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage("INTERCOM_CLOSE");
      }
    });
  }

  componentWillUnmount(): void {
    document.body.classList.remove("is-intercom-page");
  }

  render() {
    return <div>IntercomPage - default</div>;
  }
}

export default IntercomPage;
