import React from "react";
import { connect } from "../i18n/connect";
import { removePortfolioRoutine } from "../redux/modules/portfolios";
import config from "../config";
import withUserData from "./withUserData";

export default function withPortfoliosListItem(WrappedComponent) {
  const mapDispatchToProps = {
    removePortfolio: removePortfolioRoutine
  };

  @connect(null, mapDispatchToProps)
  @withUserData
  class WithPortfoliosListItem extends React.Component {
    state = {
      dataFromWebSocket: {}
    };

    mounted = false;

    constructor(props) {
      super(props);

      const {
        data: { id: portfolioId }
      } = props;

      this.SocketClient = require("../lib/SocketIO/SocketClient").default;
      this.roomName = this.getRoomName(portfolioId);
    }

    getRoomName(param) {
      return `${param}_list_update`;
    }

    componentDidMount() {
      const {
        data: { id: portfolioId }
      } = this.props;

      this.mounted = true;

      this.SocketClient.connect(
        config.socketIoKeys.portfolios,
        portfolioData => {
          if (this.mounted && portfolioData.id === portfolioId) {
            this.setState(({ dataFromWebSocket }) => ({
              dataFromWebSocket: {
                ...dataFromWebSocket,
                ...portfolioData
              }
            }));
          }
        },
        this.roomName
      );
    }

    componentWillUnmount() {
      this.mounted = false;

      this.SocketClient.disconnect(this.roomName);
    }

    render() {
      const { dataFromWebSocket } = this.state;
      return (
        <WrappedComponent
          {...this.props}
          dataFromWebSocket={dataFromWebSocket}
        />
      );
    }
  }

  return WithPortfoliosListItem;
}
