import classNames from "classnames";
import React, { useCallback } from "react";

import withTranslations from "../../../../../../hocs/withTranslations";
import { isNotFollowableLink } from "../../../../../../lib/isInternalLink";
import BaseLink from "../BaseLink";

function SubmenuLink({
  label,
  href,
  children,
  onLeave,
  onEnter,
  active,
  translate,
  target
}) {
  const handleLeave = useCallback(
    async ({ event }) => {
      if (typeof onLeave !== "function") return;

      await onLeave({ event });
    },
    [onLeave]
  );

  const handleEnter = useCallback(
    async ({ event }) => {
      if (typeof onEnter !== "function") return;

      await onEnter({ event });
    },
    [onEnter]
  );

  const Label = useCallback(() => {
    const hasChildren = !!children;

    if (!hasChildren) return translate(label);

    return (
      <>
        {translate(label)}
        <i
          onClick={async event => {
            event.preventDefault();
            event.stopPropagation();

            active
              ? await handleLeave({ event })
              : await handleEnter({ event });
          }}
          className="far fa-chevron-right"
        />
      </>
    );
  }, [children, label, active, handleLeave, handleEnter]);

  return (
    <BaseLink
      target={target}
      href={href}
      label={<Label />}
      children={children}
      onEnter={handleEnter}
      rel={isNotFollowableLink(href) ? "nofollow" : null}
      className={classNames({
        submenu__link: true,
        submenu__link_active: active
      })}
    />
  );
}

export default withTranslations(SubmenuLink);
