import RN from "react-native";

let Platform;

if (RN) {
  Platform = RN.Platform;
}

export const getIsWeb = () => !RN;

export const getIsMobile = () =>
  window && !window.matchMedia(`(min-width: 992px)`).matches;

export const getSystem = () => (getIsWeb() ? "web" : Platform.OS);

export const getIsIos = () => !getIsWeb() && getSystem() === "ios";
export const getIsAndroid = () => !getIsWeb() && getSystem() === "android";

export const getPlatformSpecificField = (obj) => obj[getSystem()];

export const getIsIosBrowser = () => {
  if (!getIsWeb()) {
    return false;
  }

  const ua = window.navigator.userAgent;
  return !!ua.match(/iPad/i) || !!ua.match(/iPhone/i);
};

export const getIsAndroidBrowser = () => {
  if (!getIsWeb()) {
    return false;
  }

  return !!window.navigator.userAgent.match(/Android/i);
}
