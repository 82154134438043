import { createAction, handleActions } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

export const registerRoutine = createRoutine("app/auth/register/REGISTER");
export const CLEAR_VALIDATION_ERROR =
  "app/auth/register/CLEAR_VALIDATION_ERROR";

export const clearValidationError = createAction(
  CLEAR_VALIDATION_ERROR,
  (field: string) => ({ field })
);

export const initialState = {
  loading: false,
  error: null
};

export default handleActions(
  {
    [registerRoutine.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [registerRoutine.SUCCESS]: state => ({
      ...state,
      loading: false
    }),
    [registerRoutine.FAILURE]: (state, action) => {
      const validationErrors = {};

      for (let field of action.payload) {
        validationErrors[field.field] = field.value;
      }

      return {
        ...state,
        loading: false,
        error: validationErrors
      };
    },
    [clearValidationError]: (state, action) => {
      let validationErrors = { ...state.error };

      delete validationErrors[action.payload.field];

      return {
        ...state,
        error: validationErrors
      };
    }
  },
  initialState
);
