import React from "react";
import SweetScroll from "sweet-scroll";
import withConfigPage from "../../../../../hocs/withConfigPage";
import withDeviceType from "../../../../../hocs/withDeviceType";
import Loader from "../../../common/Loader";
import ContentSection from "../../../common/ContentSection";
import PageTitle from "../../../common/PageTitle/modules/default";

@withConfigPage("termsAndConditions")
@withDeviceType
class Regulations extends React.Component {
  componentDidMount(): void {
    this.scroller = SweetScroll.create({
      duration: 500,
      easing: "easeOutExpo"
    });

    setTimeout(() => {
      this.scrollToSection();
    }, 1000);
  }

  scrollToSection() {
    const { isMobile } = this.props;
    const section = this.props.match.params.scrollToSection;

    if (section) {
      this.scroller.to(`#${section}`, {
        offset: isMobile ? -100 : -135
      });
    }
  }

  render() {
    const { translate, configPageState } = this.props;

    if (configPageState.loading || !configPageState.data) {
      return <Loader />;
    }

    return (
      <div>
        <PageTitle title={translate("Terms and conditions")} />
        <ContentSection>
          <div
            className="config-page-content"
            dangerouslySetInnerHTML={{
              __html: configPageState.data.termsAndConditions
            }}
          />
        </ContentSection>
      </div>
    );
  }
}

export default Regulations;
