import React from "react";
import autoBind from "auto-bind";
import { createChart } from "lightweight-charts";
import moment from "moment-mini";
import classNames from "classnames";
import withTranslations from "../../../../../hocs/withTranslations";
import { UrlProvider } from "../../../../../api/UrlProvider";
import SquaberLink from "../../SquaberLink/modules/default";
import SquaberButton from "../../SquaberButton/modules/default";
import PropTypes from "prop-types";
import Loader from "../../Loader";

import "../styles/default.scss";

const chartTypes = {
  LINE: "LINE",
  CANDLES: "CANDLES"
};

@withTranslations
class SimpleChart extends React.Component {
  static propTypes = {
    showRedirectToFullChart: PropTypes.bool,
    showOptions: PropTypes.bool,
    onFullscreenChartActivated: PropTypes.func,
    defaultChartType: PropTypes.oneOf(Object.values(chartTypes)),
    data: PropTypes.array,
    showVolumeStudy: PropTypes.bool,
    loading: PropTypes.bool,
    chartDataFetchError: PropTypes.bool
  };

  chart = null;

  constructor(props) {
    super(props);

    autoBind.react(this);

    this.state = {
      chartType: props.defaultChartType || chartTypes.LINE
    };
  }

  componentDidMount(): void {
    this.initChart();

    window.addEventListener("resize", this.resizeChart);
  }

  componentWillUnmount(): void {
    window.removeEventListener("resize", this.resizeChart);
    clearTimeout(this.initChartTimeout);
  }

  componentDidUpdate(): void {
    if (this.chart) {
      this.updateChartData();
    }
  }

  updateChartData() {
    const { quoteData, showVolumeStudy } = this.props;
    const volumeColorPositive = "rgba(38, 166, 154, 0.5)";
    const volumeColorNegative = "rgba(240, 83, 80, 0.5)";

    const valuesArray = [
      ...this.props.data.map(element => element[1]),
      ...this.props.data.map(element => element[2].high),
      ...this.props.data.map(element => element[2].low)
    ];

    let lineData = this.props.data.map(element => ({
      time: moment(`${element[0]}Z`).format("YYYY-MM-DD"),
      value: Number(element[1])
    }));

    if (lineData.length && lineData.length > 10 && quoteData) {
      lineData[lineData.length - 1].time = moment
        .utc(quoteData.datetime_utc)
        .add(2, "hours")
        .format("YYYY-MM-DD");
      lineData[lineData.length - 1].value = quoteData.close;
    }

    let barData = this.props.data.map(element => ({
      ...element[2],
      close: Number(element[2].close),
      time: moment(`${element[0]}Z`).format("YYYY-MM-DD")
    }));

    let histogramData = [];

    if (showVolumeStudy) {
      histogramData = this.props.data.map(element => {
        return {
          value: Math.abs(element[2].volume),
          color:
            Number(element[2].change) > 0
              ? volumeColorPositive
              : volumeColorNegative,
          time: moment(`${element[0]}Z`).format("YYYY-MM-DD")
        };
      });
    }

    if (barData.length && quoteData) {
      lineData[lineData.length - 1].value = Number(quoteData.close);
      barData[barData.length - 1].close = Number(quoteData.close);
      if (showVolumeStudy) {
        histogramData[barData.length - 1].value = Math.abs(quoteData.volume);
        histogramData[barData.length - 1].color =
          Number(quoteData.change) > 0
            ? volumeColorPositive
            : volumeColorNegative;
      }
      valuesArray.push(quoteData.close, quoteData.high, quoteData.low);
    }

    const min = Math.min(...valuesArray);
    const max = Math.max(...valuesArray);

    const priceRange = {
      minValue: min,
      maxValue: max
    };

    this.lineSeries.setData(lineData);
    this.candlestickSeries.setData(barData);

    if (showVolumeStudy) {
      this.histogramSeries.setData(histogramData);
    }

    this.lineSeries.applyOptions({
      autoscaleInfoProvider: () => ({ priceRange })
    });
    this.candlestickSeries.applyOptions({
      autoscaleInfoProvider: () => ({ priceRange })
    });

    this.chart.timeScale().fitContent();
  }

  initChart() {
    const { showVolumeStudy } = this.props;
    const { chartType } = this.state;

    if (!this.props.data.length) {
      this.initChartTimeout = setTimeout(() => {
        this.initChart();
      }, 500);
    }

    if (this.chart) {
      this.chart.remove();
    }

    const options = {
      width: this.chartContainer.clientWidth,
      height: this.chartContainer.clientHeight,
      handleScroll: {
        mouseWheel: false,
        pressedMouseMove: false,
        horzTouchDrag: false,
        vertTouchDrag: false,
        axisPressedMouseMove: false,
        axisDoubleClickReset: true,
        pinch: false
      },
      handleScale: {
        mouseWheel: false,
        pressedMouseMove: false,
        horzTouchDrag: false,
        vertTouchDrag: false,
        axisPressedMouseMove: false,
        axisDoubleClickReset: true,
        pinch: false
      }
    };

    this.chart = createChart(this.chartContainer, options);

    this.lineSeries = this.chart.addAreaSeries({
      lineWidth: 2,
      lineColor: "rgb(40, 149, 240)",
      topColor: "rgba(40, 149, 240, 0.5)",
      bottomColor: "white"
    });

    this.candlestickSeries = this.chart.addCandlestickSeries();

    if (showVolumeStudy) {
      this.histogramSeries = this.chart.addHistogramSeries({
        base: 0,
        priceFormat: {
          type: "volume"
        },
        priceScaleId: "",
        scaleMargins: {
          top: 0.8,
          bottom: 0
        }
      });
    }

    this.applyChartType(chartType);
    this.updateChartData();
  }

  applyChartType(chartType) {
    this.candlestickSeries.applyOptions({
      visible: chartType === chartTypes.CANDLES
    });
    this.lineSeries.applyOptions({
      visible: chartType === chartTypes.LINE
    });
  }

  toggleChartType() {
    const { chartType } = this.state;

    let newChartType =
      chartType === chartTypes.LINE ? chartTypes.CANDLES : chartTypes.LINE;

    this.setState(
      {
        chartType: newChartType
      },
      () => {
        this.applyChartType(newChartType);
      }
    );
  }

  resizeChart() {
    const resize = () => {
      this.chart.resize(
        this.chartContainer.clientWidth,
        this.chartContainer.clientHeight
      );
      this.chart.timeScale().fitContent();
    };

    resize();

    setTimeout(() => {
      resize();
    }, 500);
  }

  render() {
    const {
      showRedirectToFullChart,
      showOptions,
      translate,
      locale,
      ticker,
      market,
      onFullscreenChartActivated,
      chartDataFetchError,
      loading
    } = this.props;

    const { chartType } = this.state;

    return (
      <div className="simple-chart">
        {chartDataFetchError ? (
          <div className="chart-data-fetch-error">
            {translate(
              "An error occurred while fetching chart data. Try again later or contact support for more information."
            )}
          </div>
        ) : null}
        {loading ? (
          <div className="loader">
            <Loader />
          </div>
        ) : null}
        {showRedirectToFullChart ? (
          <SquaberLink
            className="show-advanced-chart"
            to={UrlProvider.getUrl("fe.fullScreenChartWithReturnTo", {
              locale,
              marketId: market,
              stockId: ticker,
              returnTo: window.location.pathname
            })}
          >
            {translate("Advanced chart")}
          </SquaberLink>
        ) : null}
        <div
          className="chart-container"
          ref={element => {
            this.chartContainer = element;
          }}
        />
        {showOptions ? (
          <div className="options">
            <SquaberButton
              className="chart-type-switcher-button"
              color="secondary_small"
              onClick={() => {
                this.toggleChartType();
              }}
            >
              <i
                className={classNames({
                  far: true,
                  "fa-chart-bar": chartType === chartTypes.LINE,
                  "fa-chart-line": chartType === chartTypes.CANDLES
                })}
              />
            </SquaberButton>
            <SquaberButton
              color="secondary_small"
              onClick={() => {
                if (onFullscreenChartActivated) {
                  onFullscreenChartActivated();
                }
              }}
            >
              {translate("Show advanced chart")}
              <i className="fas fa-expand-arrows-alt toggler-icon" />
            </SquaberButton>
          </div>
        ) : null}
      </div>
    );
  }
}

export default SimpleChart;
