import React from "react";

import config from "../config";

export default class MarketsSettingsAbstract extends React.Component {
  availableMarkets = config.availableMarkets;

  onMarketChange(market, status) {
    this.props.toggleMarket(market, status);
  }
}
