import React from "react";
import { deleteTransactionRoutine } from "../redux/modules/portfolios";
import { connect } from "../i18n/connect";

export default function withDeleteTransaction(WrappedComponent) {
  const mapDispatchToProps = {
    deleteTransaction: deleteTransactionRoutine
  };

  @connect(null, mapDispatchToProps)
  class WithDeleteTransaction extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithDeleteTransaction;
}
