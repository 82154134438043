import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";

export const resetPasswordRoutine = createRoutine(
  "app/resetPassword/REMIND_PASSWORD"
);

export const resetPasswordExecuteRoutine = createRoutine(
  "app/resetPassword/REMIND_PASSWORD_EXECUTE"
);

export const initialState = {
  loading: false,
  error: null,
  data: null
};

export default handleActions(
  {
    [resetPasswordRoutine.REQUEST]: state => ({
      ...state,
      loading: true
    }),
    [resetPasswordRoutine.SUCCESS]: state => ({
      ...state,
      loading: false
    }),
    [resetPasswordRoutine.FAILURE]: (state, payload) => ({
      ...state,
      loading: false,
      error: payload
    })
  },
  initialState
);
