import type { AccessLevel } from "./accessLevelsMap";
import accessLevelsMap from "./accessLevelsMap";
import { showModal } from "../react-redux-modal-provider";

export default function handleAccess(
  accessLevel: AccessLevel,
  skipAccess: Array<AccessLevel> = []
): void {
  if (
    accessLevel === accessLevelsMap.NOT_LOGGED_IN &&
    skipAccess.indexOf(accessLevelsMap.NO_PREMIUM_ACCESS) === -1
  ) {
    showModal("RegisterModal");
  } else if (
    accessLevel === accessLevelsMap.NO_PREMIUM_ACCESS &&
    skipAccess.indexOf(accessLevelsMap.NO_PREMIUM_ACCESS) === -1
  ) {
    showModal("PremiumModal");
  } else if (
    accessLevel === accessLevelsMap.PREMIUM_ACCESS_EXPIRED &&
    skipAccess.indexOf(accessLevelsMap.PREMIUM_ACCESS_EXPIRED) === -1
  ) {
    showModal("PremiumModal");
  }
}
