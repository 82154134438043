import { debounce, isEqual } from "lodash";
import PropTypes from "prop-types";
import qs from "qs";
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState
} from "react";
import { useHistory } from "react-router";
import { withRouter } from "react-router";
import { Container } from "reactstrap";
import { compose } from "redux";
import SweetScroll from "sweet-scroll";

import { UrlProvider } from "../../../../../../api/UrlProvider";
import withCollapsibleSectionControl from "../../../../../../hocs/withCollapsibleSectionControl";
import withStockDataLoading from "../../../../../../hocs/withStockDataLoading";
import withTranslations from "../../../../../../hocs/withTranslations";
import DesktopNavigationLink from "./DesktopNavigationLink";
import DropdownNavigation from "./DropdownNavigation";

const executeScrollToStockPageSection = (
  sectionId,
  scroller,
  expandSection
) => {
  const elementSelector = "#" + sectionId;

  scroller.to(elementSelector, {
    offset: -150
  });

  expandSection(sectionId);
};

const DesktopNavigation = ({
  items = [],
  locale,
  translate,
  stockDataLoading,
  location,
  expandSection,
  forwardedRef
}) => {
  const [dropdownNavigation, setDropdownNavigation] = useState(null);
  const history = useHistory();

  const scroller = useMemo(() => {
    return SweetScroll.create({
      duration: 500,
      easing: "easeOutExpo"
    });
  }, []);

  useEffect(() => {
    const unlisten = history.listen(() => {
      handleLeave();
    });

    return () => {
      unlisten();
    };
  }, []);

  useEffect(() => {
    if (!stockDataLoading) {
      scrollToStockPageSectionAfterDataLoaded();
    }
  }, [stockDataLoading]);

  const isOpenDropdown = useMemo(() => {
    return dropdownNavigation !== null;
  }, [dropdownNavigation]);

  const handleLeave = useCallback(() => {
    openDropdownNavigationDebounce.cancel();
    setDropdownNavigation(null);
  }, []);

  const openDropdownNavigation = useCallback(
    props => {
      if (typeof props?.children === "undefined") {
        handleLeave();
        return;
      }

      setDropdownNavigation(props);
    },
    [handleLeave]
  );

  const openDropdownNavigationDebounce = useCallback(
    debounce(props => {
      openDropdownNavigation(props);
    }, 300),
    [openDropdownNavigation]
  );

  const handleOpenDropdownNavigation = useCallback(
    props => {
      if (isOpenDropdown) {
        openDropdownNavigation(props);
        return;
      }

      openDropdownNavigationDebounce(props);
    },
    [
      handleLeave,
      isOpenDropdown,
      openDropdownNavigation,
      openDropdownNavigationDebounce
    ]
  );

  const scrollToStockPageSectionAndClearSearchParams = useCallback(
    section => {
      setTimeout(() => {
        executeScrollToStockPageSection(section, scroller, expandSection);
        history.push(location.pathname);
      }, 250);
    },
    [location]
  );

  const scrollToStockPageSectionAfterDataLoaded = useCallback(() => {
    const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

    if (searchParams.scroll_to_stock_page_section) {
      scrollToStockPageSectionAndClearSearchParams(
        searchParams.scroll_to_stock_page_section
      );
    }
  }, [location]);

  useImperativeHandle(forwardedRef, () => {
    return {
      isOpen: isOpenDropdown,
      open: openDropdownNavigation,
      close: handleLeave
    };
  });

  return (
    <>
      <nav className={"desktop-navigation"} onMouseLeave={handleLeave}>
        <div className={"desktop-navigation__list-wrapper"}>
          <Container tag={"ul"} className={"desktop-navigation__list"}>
            {items.map(props => {
              const { label: itemLabel } = props;
              const isActive = isEqual(props, dropdownNavigation);

              return (
                <li key={itemLabel}>
                  <DesktopNavigationLink
                    {...props}
                    onEnter={() => handleOpenDropdownNavigation(props)}
                    onLeave={handleLeave}
                    active={isActive}
                  />
                </li>
              );
            })}
            <li className={"desktop-navigation__item_right"}>
              <DesktopNavigationLink
                className={"desktop-navigation__link_hero"}
                label={
                  <>
                    <i className="fas fa-star" />
                    <span>{translate("Hot stocks")}</span>
                  </>
                }
                href={UrlProvider.getUrl("fe.interestingStocks", { locale })}
                onEnter={() => handleOpenDropdownNavigation()}
              />
            </li>
          </Container>
        </div>
        {!!dropdownNavigation ? (
          <>
            <DropdownNavigation {...dropdownNavigation} />
            <div
              className={"desktop-navigation__backdrop"}
              onClick={handleLeave}
              onMouseMove={handleLeave}
            />
          </>
        ) : null}
      </nav>
    </>
  );
};

DesktopNavigation.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.arrayOf(
        PropTypes.shape({
          order: PropTypes.number,
          label: PropTypes.string,
          href: PropTypes.string
        })
      ),
      order: PropTypes.number,
      label: PropTypes.string,
      href: PropTypes.string
    })
  )
};

DesktopNavigation.displayName = "DesktopNavigation";

const ComposedDesktopNavigation = compose(
  withTranslations,
  withStockDataLoading,
  withRouter,
  withCollapsibleSectionControl
)(DesktopNavigation);

export default forwardRef((props, ref) => (
  <ComposedDesktopNavigation {...props} forwardedRef={ref} />
));
