const availableDelimiters = [".", "/", "-"];

export default function detectDateFormat(value: String) {
  let currentDelimiter = ".";

  for (let delimiter of availableDelimiters) {
    if (value.indexOf(delimiter) !== -1) {
      currentDelimiter = delimiter;
      break;
    }
  }

  const splitValue = value.split(currentDelimiter);
  let yearFirst = false;

  if (splitValue[0].length === 4) {
    yearFirst = true;
  }

  return yearFirst
    ? `YYYY${currentDelimiter}MM${currentDelimiter}DD`
    : `DD${currentDelimiter}MM${currentDelimiter}YYYY`;
}
