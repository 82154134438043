import React from "react";
import classNames from "classnames";
import "../styles/default.scss";

const ContentSection = ({ children, className, dangerouslySetInnerHTML }) => (
  <div
    className={classNames({
      "content-section": true,
      [className]: !!className
    })}
    dangerouslySetInnerHTML={dangerouslySetInnerHTML}
  >
    {children}
  </div>
);

export default ContentSection;
