import isNumberWithExponentialNotation from "./isNumberWithExponentialNotation";

const appendDecimalZerosIfNeeded = ({
  value,
  decimalZerosCount = 2
}: {
  value: string,
  decimalZerosCount?: number
}) => {
  let transformedNumber = value;

  const trailingZerosCount = transformedNumber.split(".")[1]
    ? transformedNumber.split(".")[1].length
    : 0;

  if (
    !isNumberWithExponentialNotation(transformedNumber) &&
    trailingZerosCount < decimalZerosCount
  ) {
    if (trailingZerosCount === 0) {
      transformedNumber += ".";
    }
    for (let i = 0; i < decimalZerosCount - trailingZerosCount; i++) {
      transformedNumber += "0";
    }
  }

  return transformedNumber;
};

export default appendDecimalZerosIfNeeded;
