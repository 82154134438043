const smEventTypes = {
  OTHER: "OTHER",
  PURCHASE: "PURCHASE",
  CART: "CART",
  TRANSACTION: "TRANSACTION",
  CANCELLATION: "CANCELLATION",
  RETURN: "RETURN",
  VISIT: "VISIT",
  PHONE_CALL: "PHONE_CALL",
  RESERVATION: "RESERVATION",
  CANCELLED: "CANCELLED",
  ACTIVATION: "ACTIVATION",
  MEETING: "MEETING",
  OFFER: "OFFER",
  DOWNLOAD: "DOWNLOAD",
  LOGIN: "LOGIN",
  SURVEY: "SURVEY"
};

export default smEventTypes;
