import React from "react";

import Price from "../../../../../../common/Price";
import withQuoteData from "../../../../../../hocs/withQuoteData";

const CurrentPriceFromFirebase = ({
  emptyQuoteData,
  quoteData,
  resultMetaData: { currency }
}) => {
  if (emptyQuoteData) {
    return null;
  }

  return (
    <div className="current-price-from-firebase">
      <Price
        value={quoteData.close}
        currency={currency || ""}
        useCommaAsDecimalPointer
        maxDecimalPlace={6}
      />
    </div>
  );
};

export default withQuoteData()(CurrentPriceFromFirebase);
