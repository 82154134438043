import React from "react";

import connect from "../../../../../../i18n/connect";
import AndroidIcon from "../assets/android_svgrepo.com.svg";
import AppleIcon from "../assets/apple_svgrepo.com.svg";
import AppStoreQRCode from "../assets/vector_1.svg";
import PlayStoreQRCode from "../assets/vector_2.svg";
import { Box } from "./box";
import { BoxContent } from "./boxContent";

const StoreQRCode = ({ type, translate }) => {
  if (type === "playstore") {
    return (
      <Box className={"flex-1"}>
        <BoxContent className={"flex gap-12"}>
          <img
            src={PlayStoreQRCode}
            alt={translate("lp_store-qr-code_play-store_alt")}
          />
          <div className={"text-center flex-1"}>
            <img src={AndroidIcon} alt={""} className={"mb-5"} />
            <span className={"block text-yellow text-base font-medium"}>
              {translate("lp_store-qr-code_text")}
            </span>
          </div>
        </BoxContent>
      </Box>
    );
  }

  return (
    <Box className={"flex-1"}>
      <BoxContent className={"flex gap-12"}>
        <img src={AppStoreQRCode} alt={translate("lp_store-qr-code_ios_alt")} />
        <div className={"text-center flex-1"}>
          <img src={AppleIcon} alt={"Apple Icon"} className={"mb-5"} />
          <span className={"block text-yellow text-base font-medium"}>
            {translate("lp_store-qr-code_text")}
          </span>
        </div>
      </BoxContent>
    </Box>
  );
};

export default connect()(StoreQRCode);
