import "../styles/default.scss";

import autoBind from "auto-bind";
import { debounce } from "lodash";
import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import Headroom from "react-headroom";
import { Container, Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import { UrlProvider } from "../../../../../api/UrlProvider";
import config from "../../../../../config";
import accessLevelsMap from "../../../../../lib/access/accessLevelsMap";
import getAccessLevel from "../../../../../lib/access/getAccessLevel";
import logo from "../../../../../logo";
import PaymentAlert from "../../../common/PaymentAlert";
import SearchBar from "../../../common/SearchBar";
import SquaberLink from "../../../common/SquaberLink";
import NotificationIndicator from "../../../navigation/NotificationIndicator";
import MainMenu from "../../MainMenu/modules/default";

class Header extends Component {
  constructor(props) {
    super(props);
    this.headerRef = createRef();
    this.mainMenuRef = createRef();

    autoBind.react(this);
  }

  static propTypes = {
    userData: PropTypes.shape({
      firstName: PropTypes.string,
      email: PropTypes.string
    }),
    logout: PropTypes.func.isRequired,
    history: PropTypes.shape({
      push: PropTypes.func.isRequired
    }).isRequired
  };

  static defaultProps = {
    userData: {}
  };

  state = {
    userDropdownOpen: false,
    headroomHeight: null,
    searchBarOpen: false,
    notificationsOpen: false,
    headroomPinned: false
  };

  activeSignalsElementId = "#active-signals-list";

  toggleUser() {
    this.setState(prevState => ({
      userDropdownOpen: !prevState.userDropdownOpen
    }));
  }

  setSearchBarOpen(newValue, callback) {
    this.setState({ searchBarOpen: newValue }, callback);
  }

  setNotificationsOpen(newValue, callback) {
    this.setState({ notificationsOpen: newValue }, callback);
  }

  closeUserMenuDropdown() {
    const { isMobile } = this.props;

    if (!isMobile) {
      this.setState({
        userDropdownOpen: false
      });
    }
  }

  onLogout = () => {
    const { logout } = this.props;
    logout();
  };

  getName = () => {
    const { first_name, last_name, username } = this.props.userData;
    return first_name ? `${first_name} ${last_name}` : username;
  };

  getTextLogo() {
    throw new Error("You have to implement this method in child class");
  }

  getRegisterUrl() {
    throw new Error("You have to implement this method in child class");
  }

  getRegisterTarget() {
    throw new Error("You have to implement this method in child class");
  }

  componentDidUpdate() {
    const headerHeight = this.headerRef.current?.clientHeight;
    const { setNavbarHeight, navbarHeight } = this.props;

    if (headerHeight !== navbarHeight) {
      setNavbarHeight(headerHeight);
    }
  }

  calcPinHeadroomHeight = debounce(() => {
    if (this.props.isMobile) {
      return;
    }

    const currentHeadroomHeight = this.headerRef.current?.clientHeight;

    if (this.state.headerHeight === currentHeadroomHeight) return;

    this.setState({
      headroomHeight: currentHeadroomHeight
    });
  }, 0);

  calcUnpinHeadroomHeight = debounce(() => {
    if (this.props.isMobile) {
      return;
    }

    const currentHeadroomHeight = this.headerRef.current?.clientHeight;
    const desktopMenuHeight = 53;

    const newHeadroomHeight = currentHeadroomHeight - desktopMenuHeight;

    if (this.state.headerHeight === newHeadroomHeight) {
      return;
    }

    this.setState({
      headroomHeight: newHeadroomHeight
    });
  }, 200);

  render() {
    const {
      userData,
      translate,
      locale,
      location,
      appStatus: { error: appError },
      bottomNavigationValue,
      isMobile
    } = this.props;

    if (
      isMobile &&
      bottomNavigationValue &&
      bottomNavigationValue === "watched"
    ) {
      return null;
    }

    const loggedIn = !!(userData && userData.username);

    const accessLevel = getAccessLevel(userData);
    const hasPremiumAccess = accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS;

    const shouldShowSearchBar =
      !appError &&
      location.pathname !== UrlProvider.getUrl("fe.search", { locale });

    return (
      <Headroom
        calcHeightOnResize={true}
        style={{
          height: this.state.headroomHeight,
          overflow:
            this.state.searchBarOpen ||
            this.state.notificationsOpen ||
            this.state.userDropdownOpen ||
            this.state.headroomPinned
              ? undefined
              : "hidden"
        }}
        disableInlineStyles
        onUnfix={() => {
          this.calcPinHeadroomHeight();

          this.setState({ headroomPinned: true });
        }}
        onPin={() => {
          // Executes when page is scrolled up

          this.calcUnpinHeadroomHeight.cancel();
          this.calcPinHeadroomHeight();

          this.setState({ headroomPinned: true });

          document.body.classList.remove("headroom-unpinned");
          document.body.classList.add("headroom-pinned");
        }}
        upTolerance={80}
        onUnpin={() => {
          // Executes when page is scrolled down

          this.calcPinHeadroomHeight.cancel();
          this.calcUnpinHeadroomHeight();

          this.setState({ headroomPinned: false });

          document.body.classList.remove("headroom-pinned");
          document.body.classList.add("headroom-unpinned");
        }}
      >
        <header ref={this.headerRef}>
          <div className="navbar navbar-expand-lg">
            <div className="first-row navbar-dark">
              <Container>
                <SquaberLink to="/" className="navbar-brand">
                  <div className="logo-holder ">
                    <img
                      src={
                        hasPremiumAccess
                          ? logo.iconWithTextWebPremium
                          : logo.iconWithTextWeb
                      }
                      className="img-fluid"
                      alt={config.title}
                    />
                  </div>
                </SquaberLink>

                <SearchBar
                  shouldShowSearchBar={shouldShowSearchBar}
                  searchBarOpen={this.state.searchBarOpen}
                  setSearchBarOpen={this.setSearchBarOpen.bind(this)}
                />

                {loggedIn ? (
                  <div className="auth">
                    <NotificationIndicator
                      setNotificationsOpen={this.setNotificationsOpen}
                      notificationsOpen={this.state.notificationsOpen}
                    />
                    <div className="user-menu">
                      <Dropdown
                        isOpen={this.state.userDropdownOpen}
                        toggle={this.toggleUser}
                      >
                        <DropdownToggle caret nav>
                          {this.getName()}
                        </DropdownToggle>
                        <DropdownMenu right>
                          <SquaberLink
                            onClick={() => {
                              this.closeUserMenuDropdown();
                            }}
                            to={UrlProvider.getUrl("fe.settingsPageCategory", {
                              locale,
                              settingsCategory: "personal"
                            })}
                          >
                            {translate("Account")}
                          </SquaberLink>
                          <SquaberLink
                            onClick={() => {
                              this.closeUserMenuDropdown();
                            }}
                            to={UrlProvider.getUrl("fe.settingsPageCategory", {
                              locale,
                              settingsCategory: "notifications"
                            })}
                          >
                            {translate("Settings")}
                          </SquaberLink>
                          <SquaberLink
                            onClick={event => {
                              this.closeUserMenuDropdown();
                              event.preventDefault();
                              this.onLogout();
                            }}
                            to={""}
                          >
                            {translate("Logout")}
                          </SquaberLink>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                ) : (
                  <ul className="navbar-nav auth">
                    <li>
                      <SquaberLink
                        to={UrlProvider.getUrl("fe.loginWithRedirect", {
                          locale,
                          redirectUrl: window.location.pathname
                        })}
                      >
                        {translate("Log in")}
                      </SquaberLink>
                    </li>
                    <li className="registration">
                      <SquaberLink
                        to={this.getRegisterUrl()}
                        target={this.getRegisterTarget()}
                      >
                        {translate("Register")}
                      </SquaberLink>
                    </li>
                  </ul>
                )}
              </Container>
            </div>
            <MainMenu ref={this.mainMenuRef} />
            <PaymentAlert />
          </div>
        </header>
      </Headroom>
    );
  }
}

export default Header;
