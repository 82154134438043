import config from "../../config";

const { defaultTagId } = config;

class TagsHelper {
  tagTypes = {
    AUTOMATED: "00_automated",
    USER: "01_user",
    ADMIN: "02_admin"
  };

  automatedTagTypes = {
    ALERTED: "00_alerted",
    PORTFOLIO: "01_portfolio"
  };

  editableTagTypesByAutomatedTagType = [this.automatedTagTypes.ALERTED];
  editableTagTypesByTagType = [this.tagTypes.USER];

  isAlertedTag(tag) {
    return (
      this.isAutomatedTag(tag) &&
      tag.automated_tag_type === this.automatedTagTypes.ALERTED
    );
  }

  isUserTag(tag) {
    return tag.tag_type === this.tagTypes.USER;
  }

  isEditableTag(tag) {
    return (
      this.editableTagTypesByAutomatedTagType.includes(
        tag.automated_tag_type
      ) || this.editableTagTypesByTagType.includes(tag.tag_type)
    );
  }

  isAdminTag(tag) {
    return tag.tag_type === this.tagTypes.ADMIN;
  }

  isAutomatedTag(tag) {
    return tag.tag_type === this.tagTypes.AUTOMATED;
  }

  isPortfolioTag(tag) {
    return (
      this.isAutomatedTag(tag) &&
      tag.automated_tag_type === this.automatedTagTypes.PORTFOLIO
    );
  }

  isPremiumTag(tag) {
    return tag.is_premium;
  }

  isDefaultTag(tag) {
    return tag.id === defaultTagId;
  }

  getAlertedTagMockupData(translate) {
    return {
      name: translate("ALERTED"),
      tag_type: this.tagTypes.AUTOMATED,
      automated_tag_type: this.automatedTagTypes.ALERTED
    };
  }

  userHasAccessToTag(tag, userHasAccess) {
    return userHasAccess || !this.isPremiumTag(tag);
  }

  flatStocks(tag) {
    const { stocks = [] } = tag;

    const flattenedStocks = stocks.map(stock => {
      if (typeof stock.id !== "undefined") {
        return stock.id;
      }
      return stock;
    });

    return { ...tag, stocks: flattenedStocks };
  }
}

export default new TagsHelper();
