import { createAction } from "redux-actions";
import { createRoutine, promisifyRoutine } from "redux-saga-routines";

const namespace = "app/auth";

const LOGIN = `${namespace}/LOGIN`;
const LOGIN_ERROR = `${namespace}/LOGIN_ERROR`;
const LOGIN_SUCCESS = `${namespace}/LOGIN_SUCCESS`;
const SET_AUTH_TOKEN = `${namespace}/SET_AUTH_TOKEN`;

const COOKIE_LOGIN = `${namespace}/COOKIE_LOGIN`;
const AUTO_LOGIN = `${namespace}/AUTO_LOGIN`;

const LOGOUT = `${namespace}/LOGOUT`;
const LOGOUT_AFTER = `${namespace}/LOGOUT_AFTER`;

const SOCIAL_LOGIN = `${namespace}/SOCIAL_LOGIN`;
const EXECUTE_SOCIAL_LOGIN = `${namespace}/EXECUTE_SOCIAL_LOGIN`;
const EXECUTE_SOCIAL_LOGIN_V2 = `${namespace}/EXECUTE_SOCIAL_LOGIN_V2`;

const REFRESH_USER_DATA = `${namespace}/REFRESH_USER_DATA`;

const REGISTER_FOR_PUSH_NOTIFICATIONS = `${namespace}/REGISTER_FOR_PUSH_NOTIFICATIONS`;
const REGISTER_FOR_PUSH_NOTIFICATIONS_SUCCESS = `${namespace}/REGISTER_FOR_PUSH_NOTIFICATIONS_SUCCESS`;

const UNREGISTER_FROM_PUSH_NOTIFICATIONS = `${namespace}/UNREGISTER_FROM_PUSH_NOTIFICATIONS`;

const EDIT_USER_DATA = `${namespace}/EDIT_USER_DATA`;
const EDIT_USER_DATA_ERROR = `${namespace}/EDIT_USER_DATA_ERROR`;
const EDIT_USER_DATA_SUCCESS = `${namespace}/EDIT_USER_DATA_SUCCESS`;
const CLEAR_USER_EDIT_VALIDATION_ERROR = `${namespace}/CLEAR_USER_EDIT_VALIDATION_ERROR`;

const EDIT_USER_PASSWORD = `${namespace}/EDIT_USER_PASSWORD`;
const EDIT_USER_PASSWORD_ERROR = `${namespace}/EDIT_USER_PASSWORD_ERROR`;
const CLEAR_VALIDATION_ERROR = `${namespace}/CLEAR_VALIDATION_ERROR`;

const TOGGLE_MARKETING_EMAILS = `${namespace}/TOGGLE_MARKETING_EMAILS`;

const EXECUTE_AUTH_REDIRECT = `${namespace}/EXECUTE_AUTH_REDIRECT`;

const SET_USER_WAS_LOGGED_IN = `${namespace}/SET_USER_WAS_LOGGED_IN`;

export const SOCIAL_LOGIN_SITES = {
  FACEBOOK: "facebook",
  LINKEDIN: "linkedin",
  GOOGLE: "google"
};

export const constants = {
  LOGIN,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  SET_AUTH_TOKEN,
  COOKIE_LOGIN,
  AUTO_LOGIN,
  LOGOUT,
  LOGOUT_AFTER,
  SOCIAL_LOGIN,
  EXECUTE_SOCIAL_LOGIN,
  EXECUTE_SOCIAL_LOGIN_V2,
  REFRESH_USER_DATA,
  REGISTER_FOR_PUSH_NOTIFICATIONS,
  REGISTER_FOR_PUSH_NOTIFICATIONS_SUCCESS,
  UNREGISTER_FROM_PUSH_NOTIFICATIONS,
  EDIT_USER_DATA,
  EDIT_USER_DATA_ERROR,
  EDIT_USER_DATA_SUCCESS,
  CLEAR_USER_EDIT_VALIDATION_ERROR,
  EDIT_USER_PASSWORD,
  EDIT_USER_PASSWORD_ERROR,
  CLEAR_VALIDATION_ERROR,
  TOGGLE_MARKETING_EMAILS,
  EXECUTE_AUTH_REDIRECT,
  SET_USER_WAS_LOGGED_IN
};

type LoginData = {
  user: string,
  password: string,
  rememberme: boolean
};

type SocialLoginData = {
  access_token: string,
  expires_in: string,
  id_token: string,
  social_type_id: string
};

type SocialLoginV2Data = {
  code: string,
  email: string
};

const login = createAction(LOGIN, (data: LoginData, urlParams: Object) => ({
  data,
  urlParams
}));
const loginError = createAction(LOGIN_ERROR, () => ({}));
const loginSuccess = createAction(LOGIN_SUCCESS, (userData: Object) => ({
  userData
}));
const setAuthToken = createAction(SET_AUTH_TOKEN, (token: string) => ({
  token
}));

const cookieLogin = createAction(
  COOKIE_LOGIN,
  (token: string, indicateChangingAccount: boolean) => ({
    token,
    indicateChangingAccount
  })
);
const autoLogin = createAction(AUTO_LOGIN, (token: string) => ({ token }));

const logout = createAction(LOGOUT, () => ({}));
const logoutAfter = createAction(LOGOUT_AFTER, () => ({}));

const socialLogin = createAction(SOCIAL_LOGIN, (site: string) => ({ site }));
const executeSocialLogin = createAction(
  EXECUTE_SOCIAL_LOGIN,
  (data: SocialLoginData) => ({ data })
);

const executeSocialLoginV2 = createAction(
  EXECUTE_SOCIAL_LOGIN_V2,
  (data: SocialLoginV2Data) => ({ data })
);

const refreshUserData = createRoutine(REFRESH_USER_DATA);
const refreshUserDataPromiseCreator = promisifyRoutine(refreshUserData);

const registerForPushNotifications = createAction(
  REGISTER_FOR_PUSH_NOTIFICATIONS,
  (userId: number, registrationId: string) => ({ userId, registrationId })
);

const registerForPushNotificationsSuccess = createAction(
  REGISTER_FOR_PUSH_NOTIFICATIONS_SUCCESS,
  (token: string) => ({ token })
);

const unregisterFromPushNotifications = createAction(
  UNREGISTER_FROM_PUSH_NOTIFICATIONS,
  (userId: number, registrationId: string) => ({ userId, registrationId })
);

const editUserData = createAction(
  EDIT_USER_DATA,
  (userData: Array, callback: func) => ({
    userData,
    callback
  })
);

const editUserDataError = createAction(
  EDIT_USER_DATA_ERROR,
  (error: Object) => ({
    error
  })
);

const clearUserEditValidationError = createAction(
  CLEAR_USER_EDIT_VALIDATION_ERROR,
  (field: string) => ({ field })
);

const editUserPassword = createAction(
  EDIT_USER_PASSWORD,
  (formData: Array, form) => ({ formData, form })
);

const editUserPasswordError = createAction(EDIT_USER_PASSWORD_ERROR, error => ({
  error
}));

const clearValidationError = createAction(
  CLEAR_VALIDATION_ERROR,
  (field: string) => ({ field })
);

const toggleMarketingEmails = createAction(TOGGLE_MARKETING_EMAILS, () => ({}));

const executeAuthRedirect = createAction(
  EXECUTE_AUTH_REDIRECT,
  (authRedirect: string) => ({ authRedirect })
);

const setUserWasLoggedIn = createAction(
  SET_USER_WAS_LOGGED_IN,
  (value: boolean) => ({ value })
);

export const actions = {
  login,
  loginError,
  loginSuccess,
  setAuthToken,
  cookieLogin,
  autoLogin,
  logout,
  logoutAfter,
  socialLogin,
  executeSocialLogin,
  executeSocialLoginV2,
  refreshUserData,
  refreshUserDataPromiseCreator,
  registerForPushNotifications,
  registerForPushNotificationsSuccess,
  unregisterFromPushNotifications,
  editUserData,
  editUserDataError,
  clearUserEditValidationError,
  editUserPassword,
  editUserPasswordError,
  clearValidationError,
  toggleMarketingEmails,
  executeAuthRedirect,
  setUserWasLoggedIn
};

const initialState = {
  token: null,
  loading: false,
  user: null,
  registeredForPushNotifications: false,
  pushNotificationsToken: null,
  validationErrors: null,
  userDataEditError: null,
  userWasLoggedIn: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        loading: true
      };

    case SET_AUTH_TOKEN:
      return {
        ...state,
        token: action.payload.token
      };

    case LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload.userData
      };

    case LOGOUT_AFTER:
    case LOGIN_ERROR:
      return {
        ...initialState,
        userWasLoggedIn: state.userWasLoggedIn
      };

    case REGISTER_FOR_PUSH_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        registeredForPushNotifications: true,
        pushNotificationsToken: action.payload.token
      };

    case TOGGLE_MARKETING_EMAILS:
      return {
        ...state,
        user: {
          ...state.user,
          marketing_emails: !state.user.marketing_emails
        }
      };

    case EDIT_USER_DATA_ERROR: {
      const validationErrors = {};

      for (let field of action.payload.error) {
        validationErrors[field.field] = field.value;
      }

      return {
        ...state,
        userDataEditError: validationErrors
      };
    }

    case CLEAR_USER_EDIT_VALIDATION_ERROR: {
      let validationErrors = { ...state.error };

      delete validationErrors[action.payload.field];

      return {
        ...state,
        userDataEditError: validationErrors
      };
    }

    case EDIT_USER_PASSWORD_ERROR: {
      const validationErrors = {};

      for (let field of action.payload.error) {
        validationErrors[field.field] = field.value;
      }

      return {
        ...state,
        loading: false,
        validationErrors: validationErrors
      };
    }

    case CLEAR_VALIDATION_ERROR: {
      let validationErrors = { ...state.validationErrors };

      delete validationErrors[action.payload.field];

      return {
        ...state,
        validationErrors: validationErrors
      };
    }

    case SET_USER_WAS_LOGGED_IN: {
      return {
        ...state,
        userWasLoggedIn: action.payload.value
      };
    }

    default:
      return state;
  }
}
