import React from "react";
import connect from "../i18n/connect";
import {
  createNewTagPromiseCreator,
  fetchAllTagsRoutine
} from "../redux/modules/tags";
import { bindPromiseCreators } from "redux-saga-routines";

export default function withCreateNewTag(WrappedComponent) {
  const mapDispatchToProps = dispatch => ({
    ...bindPromiseCreators(
      { createNewTag: createNewTagPromiseCreator },
      dispatch
    )
  });

  const regularMapDispatchToProps = {
    fetchAllTags: fetchAllTagsRoutine
  };

  @connect(null, mapDispatchToProps)
  @connect(null, regularMapDispatchToProps)
  class WithCreateNewTag extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithCreateNewTag;
}
