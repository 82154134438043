import autoBind from "auto-bind";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import Url from "url-parse";

import config from "../../../../../config";
import { isInternalLink } from "../../../../../lib/isInternalLink";

class SquaberLink extends React.Component {
  static propTypes = {
    to: PropTypes.string.isRequired,
    target: PropTypes.string,
    shouldAddSchema: PropTypes.bool,
    forceExternalLink: PropTypes.bool
  };

  static defaultProps = {
    target: "_self",
    shouldAddSchema: false
  };

  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  onClick(event) {
    const { to, onClick } = this.props;

    if (to === "#" || to === "#!") {
      event.preventDefault();
    }

    if (onClick) {
      onClick(event);
    }
  }

  getUrl() {
    let { to, shouldAddSchema } = this.props;

    if (
      shouldAddSchema &&
      to.indexOf("http://") === -1 &&
      to.indexOf("https://") === -1
    ) {
      to = `http://${to}`;
    }

    return to;
  }

  isLocalLink() {
    const { target, forceExternalLink } = this.props;

    if (forceExternalLink || (target && target !== "_self")) {
      return false;
    }

    const url = this.getUrl();

    const parsedUrl = new Url(url);

    return parsedUrl.host === config.baseHostName;
  }

  getFormattedUrl() {
    const isInternal = isInternalLink(this.getUrl());
    const url = this.getUrl();

    if (!isInternal || !url) {
      return url;
    }

    const urlParts = url.split("?");
    const lowerCaseUrl = urlParts[0].toLowerCase();
    if (urlParts.length > 1) {
      return lowerCaseUrl + "?" + urlParts[1];
    }
    return lowerCaseUrl;
  }

  render() {
    let {
      to,
      target,
      className,
      children,
      shouldAddSchema,
      ...rest
    } = this.props;

    const url = this.getFormattedUrl();

    const isLocalLink = this.isLocalLink();

    return isLocalLink ? (
      <Link to={url} className={className} {...rest} onClick={this.onClick}>
        {children}
      </Link>
    ) : (
      <a
        href={url}
        target={target}
        className={className}
        {...rest}
        onClick={this.onClick}
      >
        {children}
      </a>
    );
  }
}

export default SquaberLink;
