import React from "react";
import connect from "../i18n/connect";
import { getStockTags } from "../redux/modules/tags/selector";
import { fetchStockTagsRoutine } from "../redux/modules/tags";

export default function withStockTags(WrappedComponent) {
  const mapStateToProps = state => ({
    stockTags: getStockTags(state)
  });

  const mapDispatchToProps = {
    fetchStockTags: fetchStockTagsRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithStockTags extends React.Component {
    dataFetchedForStock = null;

    componentDidUpdate() {
      const { fetchStockTags, stock } = this.props;

      if (
        stock?.stock?.id &&
        (!this.dataFetchedForStock ||
          this.dataFetchedForStock !== stock.stock.id)
      ) {
        fetchStockTags({ stockId: stock.stock.id });
        this.dataFetchedForStock = stock.stock.id;
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithStockTags;
}
