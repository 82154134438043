import SquaberButton from "../../../SquaberButton";
import React from "react";
import withTranslations from "../../../../../../hocs/withTranslations";

import "./LoadMore.scss";

const LoadMore = ({ isFetchingNextPage, fetchNextPage, translate }) => {
  return (
    <div className="text-center load-more-button-wrapper">
      <SquaberButton
        color="tertiary"
        disabled={isFetchingNextPage}
        onClick={async () => {
          await fetchNextPage();
        }}
      >
        <span>{translate("Load more stocks")}</span>
        {isFetchingNextPage ? <i className="fal fa-spinner fa-spin" /> : null}
      </SquaberButton>
    </div>
  );
};

export default withTranslations(LoadMore);
