import React from "react";
import connect from "../i18n/connect";
import { handlePaymentAfterRedirectRoutine } from "../redux/modules/payment";

export default function withPaymentRedirectHandler(WrappedComponent) {
  const mapDispatchToProps = {
    handlePaymentAfterRedirect: handlePaymentAfterRedirectRoutine
  };

  @connect(null, mapDispatchToProps)
  class WithPaymentRedirectHandler extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithPaymentRedirectHandler;
}
