import React from "react";
import autoBind from "auto-bind";
import connect from "../i18n/connect";
import { getPortfolioTransactionHistory as getPortfolioTransactionHistorySelector } from "../redux/modules/portfolios/selector";
import {
  deleteTransactionRoutine,
  getPortfolioTransactionHistoryRoutine
} from "../redux/modules/portfolios";

export default function withPortfolioTransactionHistory(WrappedComponent) {
  const mapStateToProps = state => ({
    transactionHistory: getPortfolioTransactionHistorySelector(state)
  });
  const mapDispatchToProps = {
    getTransactionHistory: getPortfolioTransactionHistoryRoutine,
    deleteTransaction: deleteTransactionRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithPortfolioTransactionHistory extends React.Component {
    constructor(props) {
      super(props);

      autoBind.react(this);
    }
    componentDidMount() {
      this.getData();
    }

    getNextPage() {
      this.getData("next");
    }

    getPreviousPage() {
      this.getData("previous");
    }

    getData(page = null) {
      const { getTransactionHistory, portfolioId } = this.props;
      getTransactionHistory({ portfolioId, page });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          getNextPage={this.getNextPage}
          getPreviousPage={this.getPreviousPage}
        />
      );
    }
  }

  return WithPortfolioTransactionHistory;
}
