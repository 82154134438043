export default class ApiHelper {
  static getColumnAttributeForFiltering(column) {
    return column.filtering_attribute_name || column.attribute_name;
  }

  static getColumnAttributeForSorting(column) {
    return column.sorting_attribute_name || column.attribute_name;
  }

  static compareColumnAttributeName(column, attributeName) {
    return (
      column.data_management_attribute_name === attributeName ||
      column.attribute_name === attributeName
    );
  }

  static compareColumnAttributeNameForFiltering(column, attributeName) {
    return (
      column.filtering_attribute_name === attributeName ||
      column.attribute_name === attributeName
    );
  }

  static compareColumnAttributeNameForSorting(column, attributeName) {
    return (
      column.sorting_attribute_name === attributeName ||
      column.attribute_name === attributeName
    );
  }

  static getIsSortable(column) {
    return (
      column.values_type !== "generated_in_frontend" ||
      !!column.sorting_attribute_name
    );
  }

  static getIsSearchable(column) {
    return (
      (column.values_type === "string" || !!column.filtering_attribute_name) &&
      column.filter_type === "search"
    );
  }

  static getIsFilterableByRange(column) {
    return (
      column.filter_type === "range" &&
      column.filter_data.min !== null &&
      column.filter_data.max !== null
    );
  }

  static getIsFilterableByValueSet(column) {
    return (
      column.filter_type === "values" &&
      Array.isArray(column.filter_data) &&
      column.filter_data.length > 0
    );
  }

  static getUserHasAccessToColumn(column, userHasAccess) {
    if (column.is_premium) {
      return userHasAccess;
    }

    return true;
  }

  static getIsDateRange(column) {
    return column.values_type === "date" && this.getIsFilterableByRange(column);
  }
}
