import PropTypes from "prop-types";
import React from "react";

import ResourceResolver from "../../../../../lib/UniversalTable/ResourceResolver";
import UniversalTableLiteWeb from "../../UniversalTableLiteWeb";

const StockInsiderTransactions = ({ ticker, market }) => {
  return (
    <UniversalTableLiteWeb
      resourceId={ResourceResolver["insider_transactions_summary"]}
      fetchConfigParams={{
        urlPath: "app.csvData.financialReport.insiderTransactions.config",
        urlPathWithSettings:
          "app.csvData.financialReport.insiderTransactions.configWithSettings",
        excludedColumnsByAttributeName: ["stock__ticker", "stock__name"]
      }}
      fetchDataParams={{
        urlPath:
          "app.csvData.financialReport.insiderTransactions.tabWithOptions"
      }}
      defaultSettings={{
        filters: {
          stock__ticker: [ticker],
          stock__market: [market]
        },
        sortBy: {
          field: "message_date",
          direction: "desc"
        }
      }}
    />
  );
};

StockInsiderTransactions.propTypes = {
  ticker: PropTypes.string
};

export default StockInsiderTransactions;
