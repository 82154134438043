import moment from "moment-mini";
import React from "react";

import Price from "../../../../../common/Price";
import withPortfolioTransactionHistoryTable from "../../../../../hocs/withPortfolioTransactionHistoryTable";
import Loader from "../../Loader";
import SquaberTable from "../../SquaberTable";

const PortfolioCashOperationsList = ({
  portfolioCashTransactionsState,
  translate,
  transactionTypes,
  currency,
  deleteTransaction,
  startingCapital
}) => {
  if (portfolioCashTransactionsState.loading) {
    return <Loader small />;
  }

  return (
    <SquaberTable
      className="portfolio-transactions-history"
      heading={[
        translate("Transaction type"),
        translate("Date"),
        translate("Amount"),
        ""
      ]}
      rows={[
        [
          transactionTypes.cash[1],
          translate("Starting capital"),
          <Price value={startingCapital} currency={currency} />,
          ""
        ],
        ...portfolioCashTransactionsState.data.results.map(result => [
          transactionTypes.cash[result.type],
          moment(result.date).format("DD.MM.YYYY"),
          <Price
            value={result.amount}
            currency={currency}
            roundToSecondNumber
          />,
          <i
            className="far fa-trash-alt delete-transaction"
            onClick={() => {
              deleteTransaction(result);
            }}
          />
        ])
      ]}
    />
  );
};

PortfolioCashOperationsList.propTypes = {};

export default withPortfolioTransactionHistoryTable(
  PortfolioCashOperationsList
);
