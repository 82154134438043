import { isEmpty } from "lodash";

import toLocalUpdateTime from "./toLocalUpdateTime";

const checkNewestAttentionDate = (attentionState, attention_updated) => {
  const lastAttentionDate = attentionState.attentionStateUpdateDate;
  const lastAttentionUpdateDate = toLocalUpdateTime(lastAttentionDate);
  if (attentionState.attentionStateUpdateDate) {
    if (attention_updated > lastAttentionUpdateDate) {
      return true;
    }
  } else {
    return false;
  }
};

export const shouldShowAdminComment = (attentionState, attention_updated) => {
  const isAttentionUpdated = checkNewestAttentionDate(
    attentionState,
    attention_updated
  );

  if (isEmpty(attentionState)) {
    return true;
  }
  return isAttentionUpdated
    ? isAttentionUpdated
    : attentionState.currentAttentionState;
};
