import React, { lazy, Suspense } from "react";
import { withRouter } from "react-router";
import DialogContent from "@material-ui/core/DialogContent";
import Formsy from "formsy-react";
import withRegisterForm from "../../../../../hocs/withRegisterForm";
import withRegisterModal from "../../../../../hocs/withRegisterModal";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import BaseRegisterModal from "./default";
import config from "../../../../../config";
import SquaberButton from "../../../common/SquaberButton";

const SocialLogin = lazy(() => import("../../../auth/SocialLogin"));

@withRouter
@withRegisterForm
@withRegisterModal
class SquaberRegisterModal extends BaseRegisterModal {
  getTitle() {
    return "Register";
  }

  getRegisterButton() {
    const { translate, formBuilder, register } = this.props;

    return (
      <SquaberButton
        style={{
          marginLeft: "auto"
        }}
        onClick={() => {
          register({ fields: formBuilder.fields });
        }}
        color="primary"
      >
        {translate("Register")}
      </SquaberButton>
    );
  }

  getRegisterForm() {
    const {
      translate,
      formBuilder,
      validationErrors,
      clearValidationError
    } = this.props;

    return (
      <DialogContent>
        <div className="register-form">
          <div className="row">
            <div className="col-lg-7">
              <p className="register-form-description">
                {translate("register-form-modal-description")}
              </p>
              <Formsy validationErrors={validationErrors}>
                <FormBuilderWeb
                  {...formBuilder}
                  validationErrors={validationErrors}
                  clearValidationError={clearValidationError}
                />
              </Formsy>
            </div>
            <div className="col-lg-4 offset-lg-1">
              <Suspense fallback={<div />}>
                <SocialLogin />
              </Suspense>
            </div>
          </div>
          <div
            className="gdpr-disclaimer"
            dangerouslySetInnerHTML={{
              __html: translate("gdpr_disclaimer", {
                gdprInfoUrl: config.gdprInfoUrl
              })
            }}
          />
        </div>
      </DialogContent>
    );
  }
}

export default SquaberRegisterModal;
