import React from "react";
import { trackSMEvent } from "../redux/modules/salesManagoEventTracker";
import connect from "../i18n/connect";

export default function withSmEventTracker(WrappedComponent) {
  const mapDispatchToProps = {
    trackSMEvent
  };
  @connect(null, mapDispatchToProps)
  class WithSmEventTracker extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithSmEventTracker;
}
