import React from "react";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withTranslations from "../../../../../hocs/withTranslations";
import useGetRequest from "../../../../../hooks/useGetRequest";
import GenericPage from "../../../common/GenericPage";
import SignalDetails from "../../../common/SignalDetails";

function ManualStrategyPage({ match, locale, translate }) {
  const { id } = match.params;

  const { response, loading, error } = useGetRequest({
    urlPath: "app.signal.get",
    variables: { signalId: id },
    locale
  });

  const title = `${response?.stock?.ticker}, ${response?.stock?.name}`;

  const errorDetail = error?.response?.data?.detail;
  const noAccess = errorDetail?.endsWith?.("premium")
    ? "premium"
    : errorDetail?.endsWith?.("freemium")
    ? "freemium"
    : undefined;

  return (
    <GenericPage
      notFound={!response}
      loading={loading}
      title={title}
      noAccess={noAccess}
      buttons={
        response?.stock
          ? [
              {
                url: UrlProvider.getUrl("fe.stockPage", {
                  locale,
                  stockId: response?.stock?.ticker,
                  marketId: response?.stock?.market
                }),
                label: translate("Go to %{name}", {
                  name: title
                })
              }
            ]
          : []
      }
    >
      <SignalDetails signal={response} currency={response?.stock?.currency} />
    </GenericPage>
  );
}

export default compose(withTranslations)(ManualStrategyPage);
