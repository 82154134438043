import DataInterceptorBase from "../DataInterceptorBase";
import TransactionHistory from "../DefaultConfiguration/TransactionHistory";

export default class TransactionHistoryDataInterceptor extends DataInterceptorBase {
  async fetchConfig(id) {
    return TransactionHistory;
  }

  async fetchTabWithOptions(id, currentTabId, tableState) {
    this.queryBuilder.setTableState(tableState);

    return await this.makeRequest({
      urlPath: "app.portfolio.transactionHistoryUniversalTable",
      variables: {
        id,
        model: currentTabId,
        options: this.queryBuilder.getQueryParams()
      }
    });
  }

  async fetchNextPage(url) {
    return await this.makeRequest({ url });
  }

  transformResults(results) {
    const { portfolioId } = this.options;

    return results.map(result => {
      result.ticker_as_link = "ticker_as_link";
      result.price = result.price ? Number(result.price) : null;
      result.amount = result.amount ? Number(result.amount) : null;
      result.commission = result.commission ? Number(result.commission) : null;
      result.action = "DeletePortfolioTransaction";

      result.action_delete_portfolio_transaction = {
        portfolioId,
        transactionId: result.id
      };

      return result;
    });
  }
}
