import { handleActions } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

export const GET_STOCK = "app/stockPageTvChart/GET_STOCK";
export const getStockRoutine = createRoutine(GET_STOCK);

export const initialState = {
  stock: { data: null, loading: null, error: null }
};

export default handleActions(
  {
    [getStockRoutine.TRIGGER]: state => ({
      ...state,
      stock: {
        ...state.stock,
        loading: true,
        error: null
      }
    }),
    [getStockRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      stock: {
        ...state.stock,
        data: payload,
        loading: false,
        error: null
      }
    }),
    [getStockRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      stock: {
        loading: false,
        error: payload
      }
    })
  },
  initialState
);
