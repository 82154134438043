import React from "react";
import { createPortal } from "react-dom";
import DatePicker, { registerLocale } from "react-datepicker";
import withStyles from "@material-ui/core/styles/withStyles";
import { withFormsy } from "formsy-react";
import classNames from "classnames";
import moment from "moment-mini";
import connect from "../../../i18n/connect";
import FormsyBase, { styles } from "./FormsyBase";
import detectDateFormat from "../../detectDateFormat";
import ComposedTextField from "./ComposedTextField";
import transformDateTimeIntoDate from "../../transformDateTimeIntoDate";
import pl from "date-fns/locale/pl";
import en from "date-fns/locale/en-US";

import "./FormsyDatePicker.scss";

registerLocale("pl", pl);
registerLocale("en", en);

@connect()
@withFormsy
@withStyles(styles)
class FormsyDatePicker extends FormsyBase {
  state = {
    blurred: false,
    isOpen: false
  };

  setIsOpen(value) {
    this.setState({
      isOpen: value
    });
  }

  showError() {
    const { isValid, isPristine, skipPristine, isFormSubmitted } = this.props;
    const { blurred } = this.state;
    return (
      !isValid &&
      (!isPristine || skipPristine) &&
      (blurred || skipPristine || isFormSubmitted)
    );
  }

  onBlur(event) {
    const { blurred } = this.state;

    if (!blurred) {
      this.setState({ blurred: true });
    }

    if (this.props.onBlur && typeof this.props.onBlur === "function") {
      this.props.onBlur(event);
    }
  }

  getSelectedDate() {
    const { value } = this.props;

    let selectedDate = moment(value, detectDateFormat(value)).toDate();

    if (!this.isValidDate(selectedDate)) {
      selectedDate = new Date();
    }

    return selectedDate;
  }

  isValidDate(date) {
    return date instanceof Date && !isNaN(date);
  }

  render() {
    let {
      name,
      label,
      value,
      translate,
      locale,
      targetFormat,
      className,
      allowFuture,
      formatDatetimeLikeValue
    } = this.props;

    const { isOpen } = this.state;

    label = translate(label);

    let formattedValue = formatDatetimeLikeValue
      ? transformDateTimeIntoDate(value, targetFormat)
      : value;

    return (
      <div
        className={classNames({
          "datepicker-with-calendar-button-wrapper": true,
          [className]: !!className
        })}
      >
        <div
          className="calendar-button"
          onClick={() => {
            this.setIsOpen(true);
          }}
        >
          <i className="fal fa-calendar-alt" />
        </div>
        <div className="formsy-datepicker-wrapper">
          <ComposedTextField
            name={name}
            label={label}
            value={formattedValue}
            onChange={value => this.handleChange(value)}
          />
          {createPortal(
            <DatePicker
              locale={locale}
              name={name}
              selected={this.getSelectedDate()}
              allowSameDay={true}
              maxDate={allowFuture ? undefined : new Date()}
              dateFormat={"dd.MM.yyyy"}
              inline={isOpen}
              withPortal
              showMonthDropdown
              showYearDropdown
              scrollableYearDropdown
              onChange={date => {
                this.handleChange(
                  moment(date).format(targetFormat || "DD.MM.YYYY")
                );
                this.setIsOpen(false);
              }}
              onClickOutside={() => {
                this.setIsOpen(false);
              }}
              customInput={<div />}
            />,
            document.querySelector(".app-content")
          )}
        </div>
      </div>
    );
  }
}

export default FormsyDatePicker;
