import { isString } from "lodash";
import { createAction, handleActions } from "redux-actions";
import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const fetchChartAnalysesRoutine = createRoutine(
  "app/chartAnalyses/FETCH_CHART_ANALYSES"
);
export const fetchChartAnalysesDetailsRoutine = createRoutine(
  "app/chartAnalyses/FETCH_CHART_ANALYSES_DETAILS"
);

export const fetchChartAnalysesDetailsRoutinePromiseCreator = promisifyRoutine(
  fetchChartAnalysesDetailsRoutine
);
export const addChartAnalysisRoutine = createRoutine(
  "app/chartAnalyses/ADD_CHART_ANALYSIS"
);

export const deleteChartAnalysis = createAction(
  "app/chartAnalyses/DELETE_CHART_ANALYSIS"
);

export const updateChartAnalysis = createAction(
  "app/chartAnalyses/UPDATE_CHART_ANALYSIS"
);

export const addChartAnalysisRoutinePromiseCreator = promisifyRoutine(
  addChartAnalysisRoutine
);

export const CLEAR_ERROR_MESSAGES = "app/chartAnalyses/CLEAR_ERROR_MESSAGES";
export const CLEAR_CHART_ANALYSIS = "app/chartAnalyses/CLEAR_CHART_ANALYSIS";

export const clearErrorMessages = createAction(CLEAR_ERROR_MESSAGES);
export const clearChartAnalysis = createAction(CLEAR_CHART_ANALYSIS);

export const initialState = {
  list: [],
  loading: false,
  error: false,
  addChartAnalysisErrors: null
};

export default handleActions(
  {
    [fetchChartAnalysesRoutine.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [fetchChartAnalysesRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      list: payload
    }),
    [fetchChartAnalysesRoutine.FAILURE]: state => ({
      ...state,
      loading: false,
      list: []
    }),
    [addChartAnalysisRoutine.FAILURE]: (state, { payload }) => {
      let error = null;

      if (isString(payload)) {
        error = { validation: [{ value: payload }] };
      }

      return {
        ...state,
        addChartAnalysisErrors: error
      };
    },
    [CLEAR_ERROR_MESSAGES]: state => ({
      ...state,
      addChartAnalysisErrors: null
    }),
    [CLEAR_CHART_ANALYSIS]: state => ({
      ...state,
      list: initialState.list
    })
  },
  initialState
);
