import React from "react";
import withUserMessagesHandler from "../../../../../hocs/withUserMessagesHandler";

@withUserMessagesHandler("UserMessageModal")
class UserMessagesHandler extends React.Component {
  render() {
    return null;
  }
}

export default UserMessagesHandler;
