const NOTIFICATION_TYPE__ALERT__INTERESTING_SITUATION = "interesting_situation";
const NOTIFICATION_TYPE__ALERT__MEDIA_MONITOR = "media_monitor";
const NOTIFICATION_TYPE__ALERT__PRICE_ALERTS = "price_alerts";
const NOTIFICATION_TYPE__ALERT__DEMAND_ZONE = "demand_zone";
const NOTIFICATION_TYPE__ALERT__SQUABER_TEAM_COMMENT = "squaber_team_comment";
const NOTIFICATION_TYPE__ALERT__RECOMMENDATIONS = "stock_messages";

const NOTIFICATION_TYPE__ALERT__DONCHAIN_H1 = "donchain_h1";
const NOTIFICATION_TYPE__ALERT__DONCHAIN_H4 = "donchain_h4";
const NOTIFICATION_TYPE__ALERT__ICHIMOKU_KINKO_HYO_H1 = "ichimoku_h1";
const NOTIFICATION_TYPE__ALERT__ICHIMOKU_KINKO_HYO_H4 = "ichimoku_h4";

const sectionsMap = {
  [NOTIFICATION_TYPE__ALERT__INTERESTING_SITUATION]: "section-current-signal",
  [NOTIFICATION_TYPE__ALERT__MEDIA_MONITOR]: "section-media-monitor",
  [NOTIFICATION_TYPE__ALERT__PRICE_ALERTS]: "section-price-alerts",
  [NOTIFICATION_TYPE__ALERT__DEMAND_ZONE]: "section-chart",
  [NOTIFICATION_TYPE__ALERT__SQUABER_TEAM_COMMENT]: "section-stock-analyses",
  [NOTIFICATION_TYPE__ALERT__RECOMMENDATIONS]: "section-recommendations",
  [NOTIFICATION_TYPE__ALERT__DONCHAIN_H1]:
    "section-automatic-strategy-donchain_h1",
  [NOTIFICATION_TYPE__ALERT__DONCHAIN_H4]:
    "section-automatic-strategy-donchain_h4",
  [NOTIFICATION_TYPE__ALERT__ICHIMOKU_KINKO_HYO_H1]:
    "section-automatic-strategy-ichimoku_h1",
  [NOTIFICATION_TYPE__ALERT__ICHIMOKU_KINKO_HYO_H4]:
    "section-automatic-strategy-ichimoku_h4"
};

export const NOTIFICATION_TYPES = {
  INTERESTING_SITUATION: "interesting_situation",
  MEDIA_MONITOR: "media_monitor",
  PRICE_ALERTS: "price_alerts",
  DEMAND_ZONE: "demand_zone",
  SQUABER_TEAM_COMMENT: "squaber_team_comment",
  RECOMMENDATIONS: "stock_messages",
  DONCHAIN_H1: "donchain_h1",
  DONCHAIN_H4: "donchain_h4",
  ICHIMOKU_KINKO_HYO_H1: "ichimoku_h1",
  ICHIMOKU_KINKO_HYO_H4: "ichimoku_h4",
  TRANSACTION_STRATEGY: "transaction_strategy"
};

export default function getStockPageSectionFromNotificationType(
  notificationType
) {
  return sectionsMap[notificationType];
}

export const getNotificationTypeFromStockPageSection = (
  stockPageSection = ""
) => {
  const notificationType = Object.keys(sectionsMap).find(
    key => sectionsMap[key] === stockPageSection
  );

  if (typeof notificationType === "undefined" || notificationType === null) {
    return "";
  }

  return notificationType;
};
