import React from "react";
import { connect } from "../i18n/connect";
import { getPortfoliosListState } from "../redux/modules/portfolios/selector";
import { getPortfoliosListRoutine } from "../redux/modules/portfolios";

export default function withPortfoliosList(WrappedComponent) {
  const mapStateToProps = state => ({
    portfoliosListState: getPortfoliosListState(state)
  });

  const mapDispatchToProps = {
    getPortfoliosList: getPortfoliosListRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithPortfoliosList extends React.Component {
    componentDidMount() {
      const { getPortfoliosList } = this.props;

      getPortfoliosList();
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithPortfoliosList;
}
