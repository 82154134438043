import React from "react";
import { setModalShowingLocked } from "../redux/modules/browsedPagesCount";
import connect from "../i18n/connect";

export default function withRegisterModal(WrappedComponent) {
  const mapDispatchToProps = {
    setModalShowingLocked
  };

  @connect(null, mapDispatchToProps)
  class WithRegisterModal extends React.Component {
    componentDidMount() {
      this.props.setModalShowingLocked(true);
    }

    componentWillUnmount() {
      this.props.setModalShowingLocked(false);
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithRegisterModal;
}
