import React from "react";

import OtherNotificationsSettingsAbstract from "../../../../../abstract/OtherNotificationsSettingsAbstract";
import withAlertManagement from "../../../../../hocs/withAlertManagement";
import toAdditionalSettingsCategories from "../../../../../lib/dto/toAdditionalSettingsCategories";
import AlertManagementSwitchers from "../../../common/AlertManagementSwitchers";

class OtherNotificationsSettings extends OtherNotificationsSettingsAbstract {
  render() {
    const {
      translate,
      alertManagementData,
      loading,
      analysisCategories,
      transactionStrategies
    } = this.props;

    return (
      <div>
        <h2>{translate("Notification management")}</h2>
        <p>
          {translate("Set how you want to receive alerts.")}
          <br />
          {translate(
            "These settings affect all of your alerted financial instruments! For example, if you disable Price Alerts then you will not receive any price alerts, even if they are put on the website! If you decide to disable email notifications for Interesting Companies in here, you will receive the information only on the mobile application and in the web application. So use these settings wisely."
          )}
        </p>
        <AlertManagementSwitchers
          data={alertManagementData ? alertManagementData : null}
          disabled={loading}
          onElementChange={this.handleChange}
          isGeneralSettings={true}
          additionalSettingsCategories={toAdditionalSettingsCategories({
            settingsCategories: [
              ...analysisCategories,
              ...transactionStrategies
            ]
          })}
        />
      </div>
    );
  }
}

export default withAlertManagement(OtherNotificationsSettings);
