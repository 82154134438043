import { createAction } from "redux-actions";

const namespace = "app/menu";

const FETCH_MENU = `${namespace}/FETCH_MENU`;
const FETCH_MENU_ERROR = `${namespace}/FETCH_MENU_ERROR`;
const FETCH_MENU_SUCCESS = `${namespace}/FETCH_MENU_SUCCESS`;

export const constants = {
  FETCH_MENU,
  FETCH_MENU_ERROR,
  FETCH_MENU_SUCCESS
};

const fetchMenu = createAction(FETCH_MENU, () => ({}));
const fetchMenuError = createAction(FETCH_MENU_ERROR, () => ({}));
const fetchMenuSuccess = createAction(FETCH_MENU_SUCCESS, (data: Object) => ({
  data
}));

export const actions = {
  fetchMenu,
  fetchMenuError,
  fetchMenuSuccess
};

const initialState = {
  loading: false,
  data: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_MENU:
      return {
        ...state,
        loading: true
      };

    case FETCH_MENU_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data
      };

    case FETCH_MENU_ERROR:
      return initialState;

    default:
      return state;
  }
}
