import React from "react";
import NumberHelper from "../../../../../lib/UniversalTable/RendererHelpers/NumberHelper";
import ChangePercent from "../../ChangePercent";

export default ({ value, format }) => {
  if (typeof value === "undefined" || value === null) {
    return null;
  }

  let renderedData;

  if (format === "number_percent") {
    renderedData = <ChangePercent change={value / 100} />;
  } else {
    renderedData = NumberHelper.render(value, format);
  }

  return <div className="number-renderer">{renderedData}</div>;
};
