export default class TransactionHelper {
  static transactionTypes = {
    cash: {
      1: "Deposit",
      2: "Withdraw"
    },
    stock: {
      1: "Buy",
      2: "Sell",
      DividendPayout: "DividendPayout"
    }
  };

  static getTransactionTypeName(group, id) {
    return this.transactionTypes[group][id];
  }
}
