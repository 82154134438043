import React from "react";

import withWebHistoryList from "../../../../../hocs/withHistoryList/withWebHisotryList";
import Loader from "../../Loader";
import LongTermSignalsListItem from "../../LongTermSignalsListItem";
import SquaberButton from "../../SquaberButton";

const LongTermSignalsList = ({
  historyListState,
  fetchNextPage,
  translate
}) => (
  <div className="history-list">
    {historyListState.loading ? (
      <Loader small />
    ) : (
      <React.Fragment>
        {historyListState.data.results.map((signal, index) => (
          <LongTermSignalsListItem key={index} {...signal} />
        ))}
        {historyListState.data.next ? (
          <div className="text-center load-more-button-wrapper">
            <SquaberButton
              color="tertiary"
              onClick={async () => {
                await fetchNextPage();
              }}
              disabled={historyListState.loadingNextPage}
            >
              {translate("Load more stocks")}
              {historyListState.loadingNextPage ? (
                <i className="fal fa-spinner fa-spin" />
              ) : null}
            </SquaberButton>
          </div>
        ) : null}
      </React.Fragment>
    )}
  </div>
);

LongTermSignalsList.propTypes = {};

export default withWebHistoryList("long-term-signals")(LongTermSignalsList);
