import PropTypes from "prop-types";
import React from "react";

import isNumberWithExponentialNotation from "../lib/isNumberWithExponentialNotation";

class Price extends React.Component {
  static propTypes = {
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    currency: PropTypes.string,
    roundToSecondNumber: PropTypes.bool,
    useCommaAsDecimalPointer: PropTypes.bool,
    maxDecimalPlace: PropTypes.number
  };

  static defaultProps = {
    roundToSecondNumber: false,
    useCommaAsDecimalPointer: false,
    maxDecimalPlace: 4
  };

  static stripTrailingZeros(number) {
    return Number(number).toString();
  }

  getPrice() {
    const {
      value,
      roundToSecondNumber,
      useCommaAsDecimalPointer,
      maxDecimalPlace
    } = this.props;

    let price = Price.stripTrailingZeros(value);

    let trailingZerosCount = price.split(".")[1]
      ? price.split(".")[1].length
      : 0;

    if (!isNumberWithExponentialNotation(price) && trailingZerosCount < 2) {
      if (trailingZerosCount === 0) {
        price += ".";
      }
      for (let i = 0; i < 2 - trailingZerosCount; i++) {
        price += "0";
      }
    }

    let finalPrice = roundToSecondNumber ? Number(price).toFixed(2) : price;

    if (trailingZerosCount > 4 && !roundToSecondNumber) {
      finalPrice = Number(finalPrice).toFixed(maxDecimalPlace);
    }

    if (useCommaAsDecimalPointer) {
      finalPrice = finalPrice.replace(".", ",");
    }

    return finalPrice;
  }

  render() {
    const { currency } = this.props;

    return `${this.getPrice()}${currency === "%" ? "" : " "}${
      currency ? currency : ""
    }`.trim();
  }
}

export default Price;
