import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";

export const fetchInterestingStocks = createRoutine(
  "app/browserView/FETCH_INTERESTING_STOCKS"
);

const initialState = {
  interestingStocks: {},
  loading: false,
  error: false
};

export default handleActions(
  {
    [fetchInterestingStocks.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [fetchInterestingStocks.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      interestingStocks: payload
    }),
    [fetchInterestingStocks.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    })
  },
  initialState
);
