import React from "react";
import PropTypes from "prop-types";
import SquaberTable from "../../SquaberTable/modules/default";
import withStockRecommendations from "../../../../../hocs/withStockRecommendations";
import withUserData from "../../../../../hocs/withUserData";
import StockRecommendationsAbstract from "../../../../../abstract/StockRecommendationsAbstract";
import Price from "../../../../../common/Price";
import SquaberButton from "../../SquaberButton";

import "../styles/default.scss";
import Loader from "../../Loader";

@withStockRecommendations
class StockRecommendations extends StockRecommendationsAbstract {
  static propTypes = {
    currency: PropTypes.string,
    stockId: PropTypes.number
  };

  componentDidMount() {
    const { stockId, fetchStockRecommendations } = this.props;

    fetchStockRecommendations({ stockId });
  }

  render() {
    const {
      recommendations,
      hasMoreStockRecommendations,
      translate,
      currency,
      recommendationsLoading,
      moreRecommendationsLoading
    } = this.props;

    if (recommendationsLoading) {
      return <Loader small />;
    }

    return (
      <div className="stock-recommendations">
        <SquaberTable
          className="stock-recommendations-table"
          withDistinctRows
          heading={[
            translate("Target Price"),
            translate("Issue Price"),
            translate("Issue Date"),
            translate("Type"),
            translate("Issuer")
          ]}
          rows={recommendations.map(recommendation => [
            <Price value={recommendation.target_price} currency={currency} />,
            <Price value={recommendation.issue_price} currency={currency} />,
            recommendation.issue_date,
            recommendation.kind,
            recommendation.institution
          ])}
        />
        <div className="button-wrapper text-center">
          {hasMoreStockRecommendations ? (
            <SquaberButton
              disabled={moreRecommendationsLoading}
              onClick={this.onButtonClick}
            >
              <span>
                {translate("More")}
                {moreRecommendationsLoading ? (
                  <i className="loading-indicator fal fa-spinner fa-spin" />
                ) : null}
              </span>
            </SquaberButton>
          ) : null}
        </div>
      </div>
    );
  }
}

export default StockRecommendations;
