import { io } from "socket.io-client";
import { includes, without, uniq } from "lodash";
import config from "../../config";

class SocketClient {
  constructor() {
    this.roomNames = [];
  }

  init() {
    let socketOptions = {
      transports: ["websocket", "polling"]
    };

    this.socket = io(config.websocketUrl, socketOptions);

    this.socket.on("get_room_name", () => {
      this.joinRooms();
    });
  }

  connect(listenerName, callback, roomName) {
    if (!this.socket) {
      setTimeout(() => {
        this.connect(listenerName, callback, roomName);
      }, 500);
    } else {
      this.socket.on(listenerName, callback);

      this.addRoomName(roomName);
      this.joinRoom(roomName, true);
    }
  }

  disconnect(roomName) {
    this.removeRoomName(roomName);
    this.leaveRoom(roomName);
  }

  hasListeners() {
    return this.roomNames.length > 0;
  }

  addRoomName(roomName) {
    let rooms = [...this.roomNames];
    rooms.push(roomName);
    this.roomNames = uniq(rooms);
  }

  removeRoomName(roomName) {
    this.roomNames = without(this.roomNames, roomName);
  }

  joinRooms() {
    let results = [];
    for (let roomName of this.roomNames) {
      results.push(this.joinRoom(roomName, true));
    }
    return results;
  }

  joinRoom(roomName, force) {
    if (roomName && (force || !includes(this.roomNames, roomName))) {
      this.addRoomName(roomName);
      return this.socket.emit("set_room_name", {
        room_name: roomName
      });
    }
  }

  leaveRoom(roomName) {
    if (roomName && includes(this.roomNames, roomName)) {
      this.removeRoomName(roomName);
      return this.socket.emit("leave_room", {
        room_name: roomName
      });
    }
  }

  leaveRooms() {
    for (let roomName of this.roomNames) {
      this.leaveRoom(roomName);
    }
  }
}

export default new SocketClient();
