import Formsy from "formsy-react";
import React from "react";

import withCreateNewTag from "../../../../../hocs/withCreateNewTag";
import withTagDetailsForm from "../../../../../hocs/withTagDetailsForm";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import SquaberButton from "../../../common/SquaberButton";

@withCreateNewTag
@withTagDetailsForm
class CreateNewTag extends React.PureComponent {
  render() {
    const {
      formBuilder,
      translate,
      createNewTag,
      afterTagCreated
    } = this.props;

    return (
      <div className="add-tag-form">
        <Formsy
          onValidSubmit={() => {
            createNewTag(formBuilder.fields)
              .then(() => {
                afterTagCreated();
              })
              .catch(error => {
                console.log(error);
              });
          }}
        >
          <FormBuilderWeb {...formBuilder} />
          <SquaberButton type="submit" fullWidth>
            {translate("Add tag")}
          </SquaberButton>
        </Formsy>
      </div>
    );
  }
}

export default CreateNewTag;
