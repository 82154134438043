import { createAction, handleActions } from "redux-actions";

export const SET_AUTH_LOGIN_LOADING =
  "app/authLoginLoading/SET_AUTH_LOGIN_LOADING";

export const setAuthLoginLoading = createAction(
  SET_AUTH_LOGIN_LOADING,
  (loading: boolean) => ({ loading })
);

const initialState = {
  loading: false
};

export default handleActions(
  {
    [SET_AUTH_LOGIN_LOADING]: (state, { payload }) => ({
      loading: payload.loading
    })
  },
  initialState
);
