import "../styles/default.scss";

import PropTypes from "prop-types";
import React from "react";

import Price from "../../../../../common/Price";
import ChangePercent from "../../ChangePercent";

const PortfolioSummaryItem = ({ label, data, errorMessage }) => {
  const renderers = { Price, ChangePercent };
  const { type, ...rest } = data;

  const Renderer = renderers[type];

  return (
    <div className="portfolio-summary-item">
      <span className="label">{label}</span>
      <span className={"value"}>
        {errorMessage ? errorMessage : <Renderer {...rest} />}
      </span>
    </div>
  );
};

PortfolioSummaryItem.propTypes = { errorMessage: PropTypes.string };

export default PortfolioSummaryItem;
