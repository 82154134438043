import { takeLatest, fork, put } from "redux-saga/effects";
import { fetchFaqQuestionsRoutine } from "./index";
import ApiClient from "../../../api/ApiClient";

function* fetchFaqQuestions() {
  try {
    const { data } = yield ApiClient.get("app.faq.questions");

    yield put(fetchFaqQuestionsRoutine.success(data));
  } catch (e) {
    yield put(fetchFaqQuestionsRoutine.failure(e));
  }
}

function* watchFetchFaqQuestions() {
  yield takeLatest(fetchFaqQuestionsRoutine.TRIGGER, fetchFaqQuestions);
}

export default [fork(watchFetchFaqQuestions)];
