import React from "react";
import autoBind from "auto-bind";
import ApiClientAsync from "../api/ApiClientAsync";

export default class OtherNotificationsSettingsAbstract extends React.Component {
  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  state = {
    manualStrategiesSettingsCategories: []
  };

  handleChange(element) {
    const { updateAlertManagementData } = this.props;

    element.channels.forEach(channel => {
      updateAlertManagementData({
        channel,
        category: element.category,
        status: element.status
      });
    });
  }
}
