import autoBind from "auto-bind";
import React from "react";

import config from "../config";
import { connect } from "../i18n/connect";
import { getIsWeb } from "../lib/platformHelper";
import {
  addPortfolioRoutine,
  clearAddEditPortfolioValidationErrors,
  editPortfolioRoutine
} from "../redux/modules/portfolios";
import { getAddEditPortfolioState } from "../redux/modules/portfolios/selector";
import withFormBuilder from "./withFormBuilder";

export default function withPortfolioAddEdit(WrappedComponent) {
  const mapStateToProps = state => ({
    formState: getAddEditPortfolioState(state)
  });

  const mapDispatchToProps = {
    addPortfolio: addPortfolioRoutine,
    editPortfolio: editPortfolioRoutine,
    clearValidationError: clearAddEditPortfolioValidationErrors
  };

  @connect(mapStateToProps, mapDispatchToProps)
  @withFormBuilder(props => [
    {
      name: "name",
      label: "Name",
      value: String(props.portfolioData.name),
      type: "text",
      required: true,
      maxLength: 20,
      validations: {
        maxLength: 20
      },
      validationErrors: {
        isDefaultRequiredValue: "This field is required",
        maxLength: "Portfolio name can't be longer than 20 characters"
      },
      fullWidth: true
    },
    {
      name: "description",
      label: "Description",
      value: String(props.portfolioData.description),
      type: "text",
      required: false,
      fullWidth: true,
      multiline: true,
      rows: 5
    },
    {
      name: "starting_capital",
      label: "Starting capital",
      value:
        props.portfolioData.starting_capital !== null &&
        typeof props.portfolioData.starting_capital !== "undefined"
          ? String(props.portfolioData.starting_capital)
          : "",
      type: "text",
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      fullWidth: true,
      disabled: props.action !== "add",
      inputMode: "numeric"
    },
    {
      name: "commission",
      label: "Commission",
      value:
        typeof props.portfolioData.commission === "number"
          ? `${Math.round(props.portfolioData.commission * 100 * 10000) /
              10000}%`
          : "0.38%",
      type: "text",
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      fullWidth: true,
      inputMode: "numeric"
    },
    {
      name: "commission_min",
      label: "Minimal commission",
      value: props.portfolioData.starting_capital
        ? String(props.portfolioData.commission_min)
        : "5",
      type: "text",
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      fullWidth: true,
      inputMode: "numeric"
    },
    {
      name: "currency",
      label: "Currency",
      type: getIsWeb() ? "select" : "radio",
      options: config.availableCurrencies.map(currency => ({
        label: currency,
        value: currency
      })),
      value: props.portfolioData.currency || null,
      disabled: props.action !== "add",
      showInitialValue: true,
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "is_ike_ikze",
      label: "IKE/IKZE Portfolio",
      type: "checkbox",
      selected: props.portfolioData.is_ike_ikze ? ["1"] : [],
      options: [
        {
          label: "IKE/IKZE Portfolio",
          value: "1"
        }
      ]
    }
  ])
  class WithPortfolioAddEdit extends React.Component {
    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    defaultData = {
      currency: "PLN"
    };

    getRequestData() {
      const { portfolioData, action, formBuilder } = this.props;

      let data = action === "add" ? this.defaultData : portfolioData;

      formBuilder.fields.forEach(field => {
        if (field.name === "commission") {
          let preparedValue = field.value.replace("%", "").replace(",", ".");
          data[field.name] = Number(
            Number(Number(preparedValue) / 100).toFixed(7)
          );
        } else if (field.type !== "checkbox") {
          data[field.name] = field.value;
        } else {
          data[field.name] = field.selected.length > 0;
        }
      });

      return data;
    }

    execute() {
      const { action, addPortfolio, editPortfolio, hideModal } = this.props;

      const data = this.getRequestData();

      if (action === "add") {
        addPortfolio({ data, hideModal });
      } else {
        editPortfolio({ data, hideModal });
      }
    }

    render() {
      return <WrappedComponent {...this.props} execute={this.execute} />;
    }
  }

  return WithPortfolioAddEdit;
}
