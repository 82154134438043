import { put, takeLatest, fork } from "redux-saga/effects";
import { fetchAnalysisCategories } from "./index";
import ApiClient from "../../../../api/ApiClient";

function* onFetchAnalysisCategories() {
  try {
    const { data } = yield ApiClient.get("app.stock.analysisCategories");
    yield put(fetchAnalysisCategories.success(data));
  } catch (e) {
    yield put(fetchAnalysisCategories.failure(e));
  }
}

function* watchAnalysisCategories() {
  yield takeLatest(fetchAnalysisCategories.TRIGGER, onFetchAnalysisCategories);
}

export default [fork(watchAnalysisCategories)];
