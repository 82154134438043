import "../styles/default.scss";

import React, { useState } from "react";
import { Col } from "reactstrap";

import withTranslations from "../../../../../hocs/withTranslations";
import SearchBar from "../../../common/SearchBar";

const Page404 = ({ translate }) => {
  const [searchBarOpen, setSearchBarOpen] = useState(false);

  return (
    <div className="page404">
      <h1>404</h1>
      <h2>{translate("Sorry, page was not found")}</h2>
      <p>
        <span
          dangerouslySetInnerHTML={{
            __html: translate(
              `<a href="/">Move to the main page</a> or search for companies that interest you`
            )
          }}
        />
        :
      </p>
      <Col sm={{ size: 6, order: 2, offset: 3 }}>
        <SearchBar
          inContent
          searchBarOpen={searchBarOpen}
          setSearchBarOpen={setSearchBarOpen}
        />
      </Col>
    </div>
  );
};

Page404.propTypes = {};

export default withTranslations(Page404);
