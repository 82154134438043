import SquaberIntervalTypes from "../TradingView/SquaberIntervalTypes";

const DEFAULT_DRAWN_OBJECTS_OPTIONS = [
  {
    key: "enable_squaber_shapes",
    label: "Show automatic chart shapes",
    value: true
  },
  {
    key: "show_dividends",
    label: "Show dividends on chart",
    value: true,
    enabled_intervals: [SquaberIntervalTypes.daily, SquaberIntervalTypes.weekly]
  },
  {
    key: "show_price_alerts",
    label: "Show price alerts on chart",
    value: true
  },
  {
    key: "show_financial_reports_dates",
    label: "Show financial reports dates on chart",
    value: true,
    enabled_intervals: [SquaberIntervalTypes.daily, SquaberIntervalTypes.weekly]
  },
  {
    key: "show_media_monitor_messages",
    label: "Show media monitor messages on chart",
    value: false,
    enabled_intervals: [SquaberIntervalTypes.daily, SquaberIntervalTypes.weekly]
  },
  {
    key: "show_current_last-min-max-points",
    label: "Show current last min/max points",
    value: true,
    enabled_intervals: [SquaberIntervalTypes.daily]
  },
  {
    key: "show_previous_last-min-max-points",
    label: "Show previous last min/max points",
    value: false,
    enabled_intervals: [SquaberIntervalTypes.daily]
  },
  {
    key: "show_insider_transactions",
    label: "Show insider transactions",
    value: false,
    enabled_intervals: [SquaberIntervalTypes.daily]
  }
];

export default DEFAULT_DRAWN_OBJECTS_OPTIONS;
