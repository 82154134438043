import getClientTimeZone from "../getClientTimeZone";

export default {
  layout: "s",
  charts: [
    {
      panes: [
        {
          sources: [
            {
              type: "MainSeries",
              id: "wEp1zL",
              state: {
                style: 1,
                esdShowDividends: true,
                esdShowSplits: true,
                esdShowEarnings: true,
                esdShowBreaks: false,
                esdBreaksStyle: {
                  color: "rgba( 235, 77, 92, 1)",
                  style: 2,
                  width: 1
                },
                esdFlagSize: 2,
                showCountdown: false,
                showInDataWindow: true,
                visible: true,
                showPriceLine: true,
                priceLineWidth: 1,
                priceLineColor: "",
                baseLineColor: "#B2B5BE",
                showPrevClosePriceLine: false,
                prevClosePriceLineWidth: 1,
                prevClosePriceLineColor: "rgba( 85, 85, 85, 1)",
                minTick: "default",
                extendedHours: false,
                sessVis: false,
                statusViewStyle: {
                  fontSize: 16,
                  showExchange: true,
                  showInterval: true,
                  symbolTextSource: "description",
                  showSymbolAsDescription: false
                },
                candleStyle: {
                  upColor: "#6CA584",
                  downColor: "#D65748",
                  drawWick: true,
                  drawBorder: true,
                  borderColor: "#378658",
                  borderUpColor: "#235438",
                  borderDownColor: "#5A1C16",
                  wickColor: "#737375",
                  wickUpColor: "#737375",
                  wickDownColor: "#737375",
                  barColorsOnPrevClose: false
                },
                hollowCandleStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  drawWick: true,
                  drawBorder: true,
                  borderColor: "#378658",
                  borderUpColor: "#26a69a",
                  borderDownColor: "#ef5350",
                  wickColor: "#737375",
                  wickUpColor: "#26a69a",
                  wickDownColor: "#ef5350"
                },
                haStyle: {
                  upColor: "#6CA584",
                  downColor: "#D65748",
                  drawWick: true,
                  drawBorder: true,
                  borderColor: "#378658",
                  borderUpColor: "#235438",
                  borderDownColor: "#5A1C16",
                  wickColor: "#737375",
                  wickUpColor: "#26a69a",
                  wickDownColor: "#ef5350",
                  showRealLastPrice: false,
                  barColorsOnPrevClose: false,
                  inputs: {},
                  inputInfo: {}
                },
                barStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  barColorsOnPrevClose: false,
                  dontDrawOpen: false,
                  thinBars: true
                },
                hiloStyle: {
                  color: "#2196f3",
                  showBorders: true,
                  borderColor: "#2196f3",
                  showLabels: true,
                  labelColor: "#2196f3",
                  fontSize: 7
                },
                lineStyle: {
                  color: "#2196f3",
                  linestyle: 0,
                  linewidth: 3,
                  priceSource: "close",
                  styleType: 2
                },
                areaStyle: {
                  color1: "rgba(33, 150, 243, 0.05)",
                  color2: "rgba(33, 150, 243, 0.05)",
                  linecolor: "#2196f3",
                  linestyle: 0,
                  linewidth: 3,
                  priceSource: "close",
                  transparency: 95
                },
                renkoStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  borderUpColor: "#26a69a",
                  borderDownColor: "#ef5350",
                  upColorProjection: "#a9dcc3",
                  downColorProjection: "#f5a6ae",
                  borderUpColorProjection: "#a9dcc3",
                  borderDownColorProjection: "#f5a6ae",
                  wickUpColor: "#26a69a",
                  wickDownColor: "#ef5350",
                  inputs: {
                    source: "close",
                    boxSize: 3,
                    style: "ATR",
                    atrLength: 14,
                    wicks: true
                  },
                  inputInfo: {
                    source: { name: "Source" },
                    boxSize: { name: "Box size" },
                    style: { name: "Style" },
                    atrLength: { name: "ATR Length" },
                    wicks: { name: "Wicks" }
                  }
                },
                pbStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  borderUpColor: "#26a69a",
                  borderDownColor: "#ef5350",
                  upColorProjection: "#a9dcc3",
                  downColorProjection: "#f5a6ae",
                  borderUpColorProjection: "#a9dcc3",
                  borderDownColorProjection: "#f5a6ae",
                  inputs: { source: "close", lb: 3 },
                  inputInfo: {
                    source: { name: "Source" },
                    lb: { name: "Number of line" }
                  }
                },
                kagiStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  upColorProjection: "#a9dcc3",
                  downColorProjection: "#f5a6ae",
                  inputs: {
                    source: "close",
                    style: "ATR",
                    atrLength: 14,
                    reversalAmount: 1
                  },
                  inputInfo: {
                    source: { name: "Source" },
                    style: { name: "Style" },
                    atrLength: { name: "ATR Length" },
                    reversalAmount: { name: "Reversal amount" }
                  }
                },
                pnfStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  upColorProjection: "#a9dcc3",
                  downColorProjection: "#f5a6ae",
                  inputs: {
                    sources: "Close",
                    reversalAmount: 3,
                    boxSize: 1,
                    style: "ATR",
                    atrLength: 14
                  },
                  inputInfo: {
                    sources: { name: "Source" },
                    boxSize: { name: "Box size" },
                    reversalAmount: { name: "Reversal amount" },
                    style: { name: "Style" },
                    atrLength: { name: "ATR Length" }
                  }
                },
                baselineStyle: {
                  baselineColor: "rgba( 117, 134, 150, 1)",
                  topFillColor1: "rgba( 38, 166, 154, 0.05)",
                  topFillColor2: "rgba( 38, 166, 154, 0.05)",
                  bottomFillColor1: "rgba( 239, 83, 80, 0.05)",
                  bottomFillColor2: "rgba( 239, 83, 80, 0.05)",
                  topLineColor: "rgba( 38, 166, 154, 1)",
                  bottomLineColor: "rgba( 239, 83, 80, 1)",
                  topLineWidth: 3,
                  bottomLineWidth: 3,
                  priceSource: "close",
                  transparency: 50,
                  baseLevelPercentage: 50
                },
                rangeStyle: {
                  upColor: "#26a69a",
                  downColor: "#ef5350",
                  thinBars: true,
                  upColorProjection: "#a9dcc3",
                  downColorProjection: "#f5a6ae",
                  inputs: { range: 10, phantomBars: false },
                  inputInfo: {
                    range: { name: "Range" },
                    phantomBars: { name: "Phantom Bars" }
                  }
                },
                symbol: "KGH",
                shortName: "KGH",
                timeframe: "",
                onWidget: false,
                interval: "D",
                silentIntervalChange: false,
                showSessions: false,
                dividendsAdjustment: {},
                priceAxisProperties: {
                  autoScale: true,
                  autoScaleDisabled: false,
                  lockScale: false,
                  percentage: false,
                  percentageDisabled: false,
                  log: false,
                  logDisabled: false,
                  alignLabels: true,
                  isInverted: false,
                  indexedTo100: false
                }
              },
              zorder: 5,
              haStyle: { studyId: "BarSetHeikenAshi@tv-basicstudies-60" },
              renkoStyle: { studyId: "BarSetRenko@tv-prostudies-15" },
              pbStyle: { studyId: "BarSetPriceBreak@tv-prostudies-15" },
              kagiStyle: { studyId: "BarSetKagi@tv-prostudies-15" },
              pnfStyle: { studyId: "BarSetPnF@tv-prostudies-15" },
              rangeStyle: { studyId: "BarSetRange@tv-basicstudies-72" }
            },
            {
              type: "Study",
              id: "zNvT7f",
              state: {
                palettes: {
                  palette_1: {
                    colors: {
                      "0": { color: "rgba(0,128,0,0.15)" },
                      "1": { color: "rgba(0,128,0,0.1)" },
                      "2": { color: "rgba(0,128,0,0.05)" },
                      "3": { color: "rgba(255,0,0,0.15)" },
                      "4": { color: "rgba(255,0,0,0.1)" },
                      "5": { color: "rgba(255,0,0,0.05)" }
                    }
                  }
                },
                styles: {
                  plot_0: {
                    linestyle: 0,
                    visible: true,
                    linewidth: 1,
                    plottype: 2,
                    trackPrice: true,
                    transparency: 50,
                    color: "#FF6D00",
                    histogramBase: 0,
                    joinPoints: false,
                    title: "plot_0"
                  }
                },
                inputs: {},
                precision: "default",
                bands: {},
                area: {},
                graphics: {},
                showInDataWindow: true,
                visible: true,
                showStudyArguments: true,
                plots: {
                  "0": {
                    id: "plot_0",
                    type: "bg_colorer",
                    palette: "palette_1"
                  }
                },
                _metainfoVersion: 47,
                id: "trendy@tv-basicstudies",
                name: "trendy@tv-basicstudies",
                description: "[Squaber] Trendy",
                shortDescription: "[Squaber] Trendy",
                scriptIdPart: "",
                is_price_study: true,
                is_hidden_study: false,
                isCustomIndicator: true,
                description_localized: "[Squaber] Trendy",
                shortId: "trendy",
                packageId: "tv-basicstudies",
                version: "1",
                fullId: "trendy@tv-basicstudies-1",
                productId: "tv-basicstudies",
                format: { type: "price", precision: 4 },
                matchPriority: 2,
                matchIndex: 10
              },
              zorder: 4,
              metaInfo: {
                palettes: { palette_1: {} },
                inputs: [],
                plots: [
                  { id: "plot_0", type: "bg_colorer", palette: "palette_1" }
                ],
                graphics: {},
                defaults: {
                  inputs: {},
                  palettes: {
                    palette_1: {
                      colors: [
                        { color: "rgba(0,128,0,0.15)" },
                        { color: "rgba(0,128,0,0.1)" },
                        { color: "rgba(0,128,0,0.05)" },
                        { color: "rgba(255,0,0,0.15)" },
                        { color: "rgba(255,0,0,0.1)" },
                        { color: "rgba(255,0,0,0.05)" }
                      ]
                    }
                  },
                  styles: {
                    plot_0: {
                      linestyle: 0,
                      visible: true,
                      linewidth: 1,
                      plottype: 2,
                      trackPrice: true,
                      transparency: 50,
                      color: "#8264b8"
                    }
                  }
                },
                _metainfoVersion: 47,
                id: "trendy@tv-basicstudies-1",
                name: "trendy@tv-basicstudies",
                description: "[Squaber] Trendy",
                shortDescription: "[Squaber] Trendy",
                scriptIdPart: "",
                is_price_study: true,
                is_hidden_study: false,
                isCustomIndicator: true,
                description_localized: "[Squaber] Trendy",
                shortId: "trendy",
                packageId: "tv-basicstudies",
                version: "1",
                fullId: "trendy@tv-basicstudies-1",
                productId: "tv-basicstudies",
                format: { type: "price", precision: 4 },
                matchPriority: 2,
                matchIndex: 10
              }
            },
            {
              type: "Study",
              id: "sWmrAQ",
              state: {
                styles: {
                  vol: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 5,
                    trackPrice: false,
                    transparency: 65,
                    visible: true,
                    color: "#000080",
                    histogramBase: 0,
                    joinPoints: false,
                    title: "Volume"
                  },
                  vol_ma: {
                    linestyle: 0,
                    linewidth: 1,
                    plottype: 4,
                    trackPrice: false,
                    transparency: 65,
                    visible: false,
                    color: "#0496ff",
                    histogramBase: 0,
                    joinPoints: false,
                    title: "Volume MA"
                  }
                },
                palettes: {
                  volumePalette: {
                    colors: {
                      "0": {
                        color: "#eb4d5c",
                        width: 1,
                        style: 0,
                        name: "Falling"
                      },
                      "1": {
                        color: "#53b987",
                        width: 1,
                        style: 0,
                        name: "Growing"
                      }
                    }
                  }
                },
                precision: "default",
                inputs: { showMA: false, maLength: 20 },
                bands: {},
                area: {},
                graphics: {},
                showInDataWindow: true,
                visible: true,
                showStudyArguments: true,
                plots: {
                  "0": { id: "vol", type: "line" },
                  "1": {
                    id: "volumePalette",
                    palette: "volumePalette",
                    target: "vol",
                    type: "colorer"
                  },
                  "2": { id: "vol_ma", type: "line" }
                },
                _metainfoVersion: 47,
                isTVScript: false,
                isTVScriptStub: false,
                is_hidden_study: false,
                transparency: 65,
                description: "Volume",
                shortDescription: "Volume",
                is_price_study: false,
                id: "Volume@tv-basicstudies",
                description_localized: "Wolumen",
                shortId: "Volume",
                packageId: "tv-basicstudies",
                version: "1",
                fullId: "Volume@tv-basicstudies-1",
                productId: "tv-basicstudies",
                name: "Volume@tv-basicstudies",
                format: { type: "volume" },
                matchPriority: 1,
                matchIndex: 0
              },
              zorder: 0,
              metaInfo: {
                palettes: {
                  volumePalette: {
                    colors: {
                      "0": { name: "Falling" },
                      "1": { name: "Growing" }
                    }
                  }
                },
                inputs: [
                  {
                    id: "showMA",
                    name: "show MA",
                    defval: false,
                    type: "bool"
                  },
                  {
                    id: "maLength",
                    name: "MA Length",
                    defval: 20,
                    type: "integer",
                    min: 1,
                    max: 2000
                  }
                ],
                plots: [
                  { id: "vol", type: "line" },
                  {
                    id: "volumePalette",
                    palette: "volumePalette",
                    target: "vol",
                    type: "colorer"
                  },
                  { id: "vol_ma", type: "line" }
                ],
                graphics: {},
                defaults: {
                  styles: {
                    vol: {
                      linestyle: 0,
                      linewidth: 1,
                      plottype: 5,
                      trackPrice: false,
                      transparency: 65,
                      visible: true,
                      color: "#000080"
                    },
                    vol_ma: {
                      linestyle: 0,
                      linewidth: 1,
                      plottype: 4,
                      trackPrice: false,
                      transparency: 65,
                      visible: false,
                      color: "#0496FF"
                    }
                  },
                  palettes: {
                    volumePalette: {
                      colors: {
                        "0": { color: "#eb4d5c", width: 1, style: 0 },
                        "1": { color: "#53b987", width: 1, style: 0 }
                      }
                    }
                  },
                  inputs: { showMA: false, maLength: 20 }
                },
                _metainfoVersion: 47,
                isTVScript: false,
                isTVScriptStub: false,
                is_hidden_study: false,
                transparency: 65,
                styles: {
                  vol: { title: "Volume", histogramBase: 0 },
                  vol_ma: { title: "Volume MA", histogramBase: 0 }
                },
                description: "Volume",
                shortDescription: "Volume",
                is_price_study: false,
                id: "Volume@tv-basicstudies-1",
                description_localized: "Wolumen",
                shortId: "Volume",
                packageId: "tv-basicstudies",
                version: "1",
                fullId: "Volume@tv-basicstudies-1",
                productId: "tv-basicstudies",
                name: "Volume@tv-basicstudies",
                format: { type: "volume" },
                matchPriority: 1,
                matchIndex: 0
              }
            }
          ],
          leftAxisesState: [],
          rightAxisesState: [
            {
              state: {
                id: "eqIyRN5Ay05P",
                m_priceRange: { m_maxValue: 101.55, m_minValue: 73.5 },
                m_isAutoScale: true,
                m_isPercentage: false,
                m_isIndexedTo100: false,
                m_isLog: false,
                m_isLockScale: false,
                m_isInverted: false,
                m_height: 392,
                m_topMargin: 0.1,
                m_bottomMargin: 0.08,
                alignLabels: true
              },
              sources: ["wEp1zL", "zNvT7f"]
            }
          ],
          overlayPriceScales: {
            sWmrAQ: {
              id: "YDYPzFW0wQgW",
              m_priceRange: { m_maxValue: 1425770, m_minValue: 0 },
              m_isAutoScale: true,
              m_isPercentage: false,
              m_isIndexedTo100: false,
              m_isLog: false,
              m_isLockScale: false,
              m_isInverted: false,
              m_height: 392,
              m_topMargin: 0.75,
              m_bottomMargin: 0,
              alignLabels: true
            }
          },
          stretchFactor: 2000,
          mainSourceId: "wEp1zL",
          priceScaleRatio: null
        }
      ],
      timeScale: { m_barSpacing: 6, m_rightOffset: 10 },
      chartProperties: {
        paneProperties: {
          background: "#FFFFFF",
          gridProperties: { color: "#e1ecf2", style: 0 },
          vertGridProperties: { color: "#e1ecf2", style: 0 },
          horzGridProperties: { color: "#e1ecf2", style: 0 },
          crossHairProperties: {
            color: "rgba(117, 134, 150, 1)",
            style: 2,
            transparency: 0,
            width: 1
          },
          topMargin: 10,
          bottomMargin: 8,
          axisProperties: {
            autoScale: true,
            autoScaleDisabled: false,
            lockScale: false,
            percentage: false,
            percentageDisabled: false,
            indexedTo100: false,
            log: false,
            logDisabled: false,
            alignLabels: true,
            isInverted: false
          },
          legendProperties: {
            showStudyArguments: true,
            showStudyTitles: true,
            showStudyValues: true,
            showSeriesTitle: true,
            showSeriesOHLC: true,
            showLegend: true,
            showBarChange: true,
            showOnlyPriceSource: true
          },
          leftAxisProperties: {
            autoScale: true,
            autoScaleDisabled: false,
            lockScale: false,
            percentage: false,
            percentageDisabled: false,
            log: false,
            logDisabled: false,
            alignLabels: true
          },
          rightAxisProperties: {
            autoScale: true,
            autoScaleDisabled: false,
            lockScale: false,
            percentage: false,
            percentageDisabled: false,
            log: false,
            logDisabled: false,
            alignLabels: true,
            isInverted: false,
            indexedTo100: false
          },
          rightOffset: {}
        },
        scalesProperties: {
          backgroundColor: "#ffffff",
          lineColor: "#50535E",
          textColor: "#50535E",
          fontSize: 11,
          scaleSeriesOnly: false,
          showSeriesLastValue: true,
          seriesLastValueMode: 1,
          showSeriesPrevCloseValue: false,
          showStudyLastValue: false,
          showSymbolLabels: false,
          showStudyPlotLabels: false,
          barSpacing: 6,
          showLeftScale: false,
          showRightScale: true
        },
        chartEventsSourceProperties: {
          visible: true,
          futureOnly: true,
          breaks: {
            color: "rgba(85, 85, 85, 1)",
            visible: false,
            style: 2,
            width: 1
          }
        },
        priceScaleSelectionStrategyName: "auto"
      },
      version: 2,
      timezone: getClientTimeZone(),
      sessions: {
        properties: {
          graphics: {
            backgrounds: {
              inSession: { color: "#6fa8dc", transparency: 60, visible: false },
              outOfSession: {
                color: "#ffe599",
                transparency: 60,
                visible: false
              }
            },
            vertlines: {
              sessBreaks: {
                color: "#4985e7",
                style: 2,
                visible: false,
                width: 1
              }
            }
          }
        }
      }
    }
  ]
};
