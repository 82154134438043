import React from "react";
import { connect } from "../i18n/connect";
import { getAppStatus } from "../redux/modules/status/selector";
import { clearError } from "../redux/modules/status";

export default function withAppStatus(WrappedComponent) {
  const mapStateToProps = state => ({
    appStatus: getAppStatus(state)
  });

  const mapDispatchToProps = {
    clearError
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithAppStatus extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithAppStatus;
}
