import React from "react";

import withTranslations from "./withTranslations";

export default function withPortfolioSummary(WrappedComponent) {
  @withTranslations
  class WithPortfolioSummary extends React.Component {
    render() {
      const { data, translate } = this.props;
      const items = [
        {
          label: translate("Sum of deposit/withdraw"),
          data: {
            type: "Price",
            value: data.starting_capital,
            currency: data.currency,
            roundToSecondNumber: true
          }
        },
        {
          label: translate("Commission"),
          data: {
            type: "Price",
            value: data.commission * 100,
            currency: "%",
            roundToSecondNumber: true
          }
        },
        {
          label: translate("Change last 24 hours"),
          data: {
            type: "ChangePercent",
            change: data.daily_rate_of_return
          }
        },
        {
          label: translate("Rate of return"),
          data: {
            type: "ChangePercent",
            change: data.rate_of_return
          }
        },
        {
          label: translate("Cash result"),
          data: {
            type: "Price",
            value: data.profit,
            currency: data.currency,
            roundToSecondNumber: true
          }
        },
        {
          label: translate("Portfolio value"),
          key: "current_equity",
          data: {
            type: "Price",
            value:
              data.statistics.find(stat => stat.key === "current_equity")
                .value || 0,
            currency: data.currency,
            roundToSecondNumber: true
          }
        }
      ];

      return <WrappedComponent {...this.props} items={items} />;
    }
  }

  return WithPortfolioSummary;
}
