import React from "react";
import autoBind from "auto-bind";
import connect from "../i18n/connect";
import { getAllTags } from "../redux/modules/tagsService/selector";
import TagsHelper from "../lib/TagsHelper/TagsHelper";

export default function withTagsSearchAutocomplete(includeAdminTags = false) {
  return WrappedComponent => {
    const mapStateToProps = state => ({
      allTags: getAllTags(state)
    });

    @connect(mapStateToProps)
    class WithTagsSearchAutocomplete extends React.Component {
      state = {
        inputValue: ""
      };

      constructor(props) {
        super(props);

        autoBind.react(this);
      }

      onInputChange(value) {
        this.setState({
          inputValue: value
        });
      }

      render() {
        const { allTags } = this.props;
        const { inputValue } = this.state;

        const tagsWithQuery = {
          loading: false,
          error: null,
          data: allTags
            .filter(
              tag =>
                TagsHelper.isUserTag(tag) ||
                TagsHelper.isAdminTag(tag) === includeAdminTags ||
                TagsHelper.isAutomatedTag(tag) === includeAdminTags
            )
            .filter(tag => {
              if (tag.description) {
                return (
                  tag.name.toLowerCase().includes(inputValue) ||
                  tag.description.toLowerCase().includes(inputValue)
                );
              }

              return tag.name.toLowerCase().includes(inputValue);
            })
        };

        return (
          <WrappedComponent
            {...this.props}
            tagsWithQuery={tagsWithQuery}
            onInputChange={this.onInputChange}
            inputValue={inputValue}
          />
        );
      }
    }

    return WithTagsSearchAutocomplete;
  };
}
