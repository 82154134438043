import React from "react";
import PropTypes from "prop-types";
import Formsy from "formsy-react";
import withEditUserPassword from "../../../../../hocs/withEditUserPassword";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import SquaberButton from "../../../common/SquaberButton";

class UserPasswordForm extends React.Component {
  render() {
    const {
      translate,
      formBuilder,
      editUserPassword,
      validationErrors,
      clearValidationError
    } = this.props;

    return (
      <div className="user-password-form">
        <Formsy
          ref={element => (this.form = element)}
          onValidSubmit={() => {
            editUserPassword(formBuilder.fields, this.form);
          }}
          validationErrors={validationErrors}
        >
          <h2>{translate("Change password")}</h2>
          <div className="login-field-wrapper">
            <FormBuilderWeb
              {...formBuilder}
              validationErrors={validationErrors}
              clearValidationError={clearValidationError}
              fields={formBuilder.fields.filter(
                field => field.name === "password_old"
              )}
            />
          </div>
          <FormBuilderWeb
            {...formBuilder}
            className="personal-data-form-builder"
            validationErrors={validationErrors}
            clearValidationError={clearValidationError}
            fields={formBuilder.fields.filter(
              field => field.name !== "password_old"
            )}
          />
          <div className="submit-wrapper">
            <SquaberButton type="submit" disabled={!formBuilder.canSubmit}>
              {translate("Save settings")}
            </SquaberButton>
          </div>
        </Formsy>
      </div>
    );
  }
}

UserPasswordForm.propTypes = {};

export default withEditUserPassword(UserPasswordForm);
