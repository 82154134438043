import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../styles/default.scss";

const Label = ({
  success,
  error,
  warning,
  type,
  fullWidth,
  className,
  children
}) => (
  <span
    className={classNames({
      "squaber-label": true,
      success,
      error,
      warning,
      "full-width": fullWidth,
      [type]: !!type,
      [className]: !!className
    })}
  >
    {children}
  </span>
);

Label.propTypes = {
  success: PropTypes.bool,
  error: PropTypes.bool,
  warning: PropTypes.bool,
  type: PropTypes.oneOf(["success", "error", "info"]),
  fullWidth: PropTypes.bool
};

export default Label;
