import React from "react";
import connect from "../i18n/connect";
import { actions as alertsActions } from "../redux/modules/alerts";

export default function withAddAlert(WrappedComponent) {
  const mapDispatchToProps = {
    addInfoAlert: alertsActions.addInfoAlert,
    addWarningAlert: alertsActions.addWarningAlert,
    addSuccessAlert: alertsActions.addSuccessAlert,
    addErrorAlert: alertsActions.addErrorAlert,
    hideAlert: alertsActions.hideAlert
  };
  @connect(null, mapDispatchToProps)
  class WithAddAlert extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithAddAlert;
}
