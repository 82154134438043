import classNames from "classnames";
import React from "react";

export const PlanBox = ({ className, children, mode }) => {
  return (
    <div
      className={classNames(className, "p-6 rounded-2xl", {
        "bg-yellow": mode === "dark",
        "bg-white": mode === "light"
      })}
    >
      {children}
    </div>
  );
};
