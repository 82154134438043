import React, { useState } from "react";
import { compose } from "redux";
import TagsHelper from "../../../../../lib/TagsHelper/TagsHelper";
import TagsSettingsSearchBox from "../../../settings/TagsSettingsSearchBox";
import withTagsSearchAutocomplete from "../../../../../hocs/withTagsSearchAutocomplete";
import withStocksListNavigationHeader from "../../../../../hocs/withStocksListNavigationHeader";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import DoubleButtonSwitcher from "../../../common/DoubleButtonSwitcher/modules/default";
import Tag from "../../../common/Tag";

import "../styles/default.scss";

const StocksListNavigationHeader = ({
  inputValue,
  onInputChange,
  updateStocksListSource,
  tagsWithQuery,
  translate,
  stocksListSource,
  setValue,
  advancedMode,
  setAdvancedMode
}) => {
  const [showTagsSearch, setShowTagsSearch] = useState(false);
  const [showAutocomplete, setShowAutocomplete] = useState(false);

  const currentTag =
    stocksListSource || TagsHelper.getAlertedTagMockupData(translate);

  let tags = [];

  if (showAutocomplete) {
    tags = tagsWithQuery.data;
  }

  return (
    <div className="stocks-list-navigation-header">
      {!showTagsSearch ? (
        <div>
          <div className="source-heading">
            <h5>{translate("Select list")}:</h5>
            <Tag
              {...currentTag}
              onClick={() => {
                setShowTagsSearch(true);
                setShowAutocomplete(true);
              }}
            />
            <span
              className="stocks-search-trigger"
              onClick={() => {
                setValue("search");
              }}
            >
              <i className="fal fa-search" />
            </span>
          </div>
          <DoubleButtonSwitcher
            labels={[translate("Basic"), translate("Advanced")]}
            value={advancedMode}
            onChange={value => setAdvancedMode(value)}
          />
        </div>
      ) : (
        <div className="source-selection">
          <div className="source-selection-heading">
            <h5>{translate("Select list")}:</h5>
            <span
              className="cancel-selection"
              onClick={() => {
                onInputChange("");
                setShowTagsSearch(false);
                setShowAutocomplete(false);
              }}
            >
              <i className="fal fa-times" />
            </span>
          </div>
          <div className="tags-list-wrapper">
            <TagsSettingsSearchBox
              label={translate("Type tag you're looking for")}
              value={inputValue}
              onFocus={() => {
                setShowAutocomplete(true);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setShowAutocomplete(false);
                }, 150);
              }}
              onChange={event => {
                onInputChange(event.target.value);
              }}
            />
            {tags.length ? (
              <div className="tags-list">
                {tags.map(tag => (
                  <Tag
                    key={tag.id}
                    {...tag}
                    onClick={() => {
                      onInputChange("");
                      updateStocksListSource(tag);
                      setShowTagsSearch(false);
                    }}
                  />
                ))}
              </div>
            ) : null}
          </div>
        </div>
      )}
    </div>
  );
};

StocksListNavigationHeader.propTypes = {};

export default compose(
  withTagsSearchAutocomplete(true),
  withStocksListNavigationHeader,
  withBottomNavigation("bottomNavigationValue")
)(StocksListNavigationHeader);
