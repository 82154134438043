import React from "react";
import connect from "../i18n/connect";
import {
  updateStocksListSource
} from "../redux/modules/tags";
import { getAllTags } from "../redux/modules/tagsService/selector";

export default function withAllAvailableTagsList(WrappedComponent) {
  const mapStateToProps = state => ({
    allAvailableTags: {
      data: getAllTags(state),
      loading: false,
      error: null
    }
  });

  const mapDispatchToProps = {
    updateStocksListSource
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithAllAvailableTagsList extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithAllAvailableTagsList;
}
