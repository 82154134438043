import React from "react";
import { isEqual } from "lodash";

import withFollowedStocksMap from "../../../../../hocs/withFollowedStocksMap";
import withStockPageTVChart from "../../../../../hocs/withStockPageTVChart";
import withUserData from "../../../../../hocs/withUserData";
import Loader from "../../../common/Loader";
import PageTitle from "../../../common/PageTitle";
import TVChart from "../../../common/TVChart";
import withMarketTransltaions from "../../../../../hocs/withMarketTranslations";
import { getDefaultMarketId } from "../../../../../lib/getDefaultMarketId";

@withUserData
@withStockPageTVChart
@withFollowedStocksMap
@withMarketTransltaions
class FullScreenChart extends React.Component {
  state = {
    tvChartLoading: false
  };
  classNameToAdd = "tv-full-screen";

  componentDidMount(): void {
    this.fetchStock();
    document.body.classList.add(this.classNameToAdd);
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    const prevParams = {
      marketId: prevProps?.match?.params?.marketId,
      stockId: prevProps?.match?.params?.stockId
    };

    const currentParams = {
      marketId: this.props?.match?.params?.marketId,
      stockId: this.props?.match?.params?.stockId
    };

    if (!isEqual(prevParams, currentParams)) {
      this.fetchStock();
    }
  }

  fetchStock() {
    const { fetchStock, match } = this.props;

    const stockId = match?.params?.stockId;
    const marketId = match?.params?.marketId;

    fetchStock({ stockId, marketId });
  }

  componentWillUnmount(): void {
    document.body.classList.remove(this.classNameToAdd);
  }

  render() {
    const {
      stock,
      translate,
      match,
      stockLoading,
      followedStocksMap,
      marketTranslations
    } = this.props;

    const stockTicker = stock?.stock?.ticker?.toLowerCase?.();
    const stockMarket = stock?.stock?.market?.toLowerCase?.();
    const matchMarket = match?.params?.marketId?.toLowerCase?.();
    const matchTicker = match?.params?.stockId?.toLowerCase?.();
    const userStock = followedStocksMap?.[stock?.stock?.id];

    const canShowTvChart =
      !stockLoading &&
      !this.state.tvChartLoading &&
      stock &&
      stockTicker === matchTicker &&
      stockMarket ===
        getDefaultMarketId(marketTranslations, matchMarket).toLowerCase();

    const handleLoadingStarted = () => {
      this.setState({ tvChartLoading: true });
    };
    const handleLoadingEnded = () => {
      this.setState({ tvChartLoading: false });
    };

    return (
      <div>
        {canShowTvChart ? (
          <React.Fragment>
            <PageTitle title={stock.stock.name} />
            <TVChart
              onRefreshStarted={handleLoadingStarted}
              onRefreshEnded={handleLoadingEnded}
              key={stock.stock}
              stock={stock.stock}
              userStock={userStock}
              latestQuoteDatetimeUtc={stock.latest_quote_datetime_utc}
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <PageTitle
              title={translate("Loading %{ticker}", { ticker: matchTicker })}
            />
            <Loader />
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default FullScreenChart;
