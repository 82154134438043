// Load css
import "./styles/style.scss";

/* global document */
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/es/integration/react";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";

import { persistor, store, Firebase } from "../redux/configureStore";

// Components
import RootComponent from "./RootComponent";
import Loading from "./components/Loading";
import SnackbarProvider from "./components/common/SnackbarProvider";

import { history } from "../redux/configureStore";

const rootElement = document.getElementById("root");

const Root = () => (
  <Provider store={store}>
    <ReactReduxFirebaseProvider
      firebase={Firebase}
      config={{}}
      dispatch={store.dispatch}
    >
      <PersistGate loading={<Loading />} persistor={persistor}>
        <RootComponent history={history} />
        <SnackbarProvider />
      </PersistGate>
    </ReactReduxFirebaseProvider>
  </Provider>
);

render(<Root />, rootElement);

if (module.hot) {
  module.hot.accept();
}
