import React from "react";
import classNames from "classnames";
import DoubleButtonSwitcherAbstract from "../../../../../abstract/DoubleButtonSwitcherAbstract";

import "../styles/default.scss";

class DoubleButtonSwitcher extends DoubleButtonSwitcherAbstract {
  render() {
    const options = this.getOptions();

    return (
      <div className="double-button-switcher">
        {options.map(option => (
          <button
            key={String(option.value)}
            className={classNames({
              "double-button-switcher__button": true,
              "is-active": option.isActive
            })}
            onClick={() => {
              this.handleChange(option.value);
            }}
          >
            <span>{option.label}</span>
          </button>
        ))}
      </div>
    );
  }
}

export default DoubleButtonSwitcher;
