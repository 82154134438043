import "../styles/default.scss";

import React, { useState } from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import SearchBar from "../../SearchBar/";

const TVChartStocksList = ({ translate, close, userData }) => {
  const [searchBarOpen, setSearchBarOpen] = useState(false);
  const isShownInNativeWebView = window.ReactNativeWebView;

  const hasUserData = userData?.token && userData?.userAccessType;

  const additionalUrlFields = hasUserData
    ? {
        authToken: userData.token,
        userAccessType: userData.userAccessType
      }
    : {};

  return (
    <div className="tv-chart-options-window wide tvchart-stocks-list">
      <h4>
        <span className="title">{translate("Select stock")}</span>
        <span className="close-button" onClick={() => close()}>
          <i className="fal fa-times" />
        </span>
      </h4>
      <SearchBar
        inContent
        resultUrlPattern={
          hasUserData ? "fe.tradingViewWidget" : "fe.fullScreenChart"
        }
        additionalUrlFields={isShownInNativeWebView ? additionalUrlFields : {}}
        searchAlwaysOpen={true}
        searchBarOpen={searchBarOpen}
        setSearchBarOpen={setSearchBarOpen}
      />
    </div>
  );
};

export default withTranslations(TVChartStocksList);
