import React from "react";
import Loader from "../../../common/Loader";
import PageTitle from "../../../common/PageTitle/modules/default";
import ContentSection from "../../../common/ContentSection";
import withConfigPage from "../../../../../hocs/withConfigPage";

const CookiesPage = ({ translate, configPageState }) => {
  if (configPageState.loading || !configPageState.data) {
    return <Loader />;
  }

  return (
    <div>
      <PageTitle title={translate("Cookies")} />
      <ContentSection>
        <div
          className="config-page-content"
          dangerouslySetInnerHTML={{
            __html: configPageState.data.cookiesPolicy
          }}
        />
      </ContentSection>
    </div>
  );
};

CookiesPage.propTypes = {};

export default withConfigPage("cookiesPolicy")(CookiesPage);
