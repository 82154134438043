import React from "react";
import withQuoteData from "../../../../../../hocs/withQuoteData";
import Price from "../../../../../../common/Price";

const AveragePercentDailyChangeInStock_50Quotes = ({
  quoteData,
  resultMetaData: { average_daily_change_in_stock_50_quotes }
}) => {
  if (!quoteData) {
    return null;
  }

  return (
    <Price
      value={(average_daily_change_in_stock_50_quotes / quoteData.close) * 100}
      roundToSecondNumber
      useCommaAsDecimalPointer={true}
      currency={"%"}
    />
  );
};

export default withQuoteData()(AveragePercentDailyChangeInStock_50Quotes);
