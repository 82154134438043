import { createRoutine } from "redux-saga-routines";
import { createAction, handleActions } from "redux-actions";

export const fetchHistoryListRoutine = createRoutine(
  "app/historyList/FETCH_INTERESTING_STOCKS"
);
export const fetchNextPageRoutine = createRoutine(
  "app/historyList/FETCH_NEXT_PAGE"
);

export const CLEAR_DATA = "app/historyList/CLEAR_DATA";

export const clearData = createAction(CLEAR_DATA, () => ({}));

export const initialState = {
  loading: true,
  loadingNextPage: false,
  data: null,
  error: null
};

export default handleActions(
  {
    [fetchHistoryListRoutine.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [fetchHistoryListRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload
    }),
    [fetchHistoryListRoutine.ERROR]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    [fetchNextPageRoutine.TRIGGER]: state => ({
      ...state,
      loadingNextPage: true
    }),
    [fetchNextPageRoutine.SUCCESS]: (state, { payload }) => {
      const newResults = [...state.data.results, ...payload.results];

      return {
        ...state,
        loadingNextPage: false,
        data: {
          ...state.data,
          ...payload,
          results: newResults
        }
      };
    },
    [fetchNextPageRoutine.ERROR]: (state, { payload }) => ({
      ...state,
      loadingNextPage: false,
      error: payload
    }),
    [CLEAR_DATA]: () => initialState
  },
  initialState
);
