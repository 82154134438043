const apiHostName = "expalerts.squaber.com";

module.exports = {
  apiHostName: `http://${apiHostName}/`,
  apiHostNameWithoutTrailingSlash: `http://${apiHostName}`,
  apiUrl: `http://${apiHostName}/:locale/api/`,
  baseHostName: "new.expalerts.squaber.com",
  firebase: {
    authDomain: "investio-backup.firebaseapp.com",
    databaseURL: "https://expalerts-staging.firebaseio.com/",
    storageBucket: "investio-backup.appspot.com"
  },
  websocketUrl: "http://new.expalerts.squaber.com/",
  defaultPremiumPopupUrl: "https://l.squaber.com/pop-up-premium/",
  tradingViewOptions: {
    hasIntraday: true,
    intervals: ["1", "5", "15", "30", "60", "240", "D", "W", "M"]
  },
  quoteConvertersInUse: false,
  webAppUrl: "http://new.expalerts.squaber.com/",
  native_tv_enabled: true
};
