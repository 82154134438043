import React from "react";
import connect from "../i18n/connect";
import { getUserMarkets, toggleMarket } from "../redux/modules/userMarkets";
import { getUserMarketsState } from "../redux/modules/userMarkets/selector";

export default function withUserMarkets(WrappedComponent) {
  const mapStateToProps = state => ({
    userMarkets: getUserMarketsState(state)
  });

  const mapDispatchToProps = {
    getUserMarkets,
    toggleMarket
  };
  @connect(mapStateToProps, mapDispatchToProps)
  class WithUserMarkets extends React.Component {
    componentDidMount() {
      const { getUserMarkets } = this.props;

      getUserMarkets();
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithUserMarkets;
}
