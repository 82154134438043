import React from "react";
import NoAccessDisclaimer from "../../NoAccessDisclaimer/modules/default";
import withTranslations from "../../../../../hocs/withTranslations";
import "../styles/DesktopTagNotFound.scss";

const DesktopTagOnlyForPremiumUsers = ({ translate }) => (
  <div className="tag-not-found">
    <h4>{translate("Premium access required")}</h4>
    <NoAccessDisclaimer />
  </div>
);

export default withTranslations(DesktopTagOnlyForPremiumUsers);
