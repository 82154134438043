import React from "react";
import SquaberLink from "../../SquaberLink";
import { globalConfigFields } from "../../../../../redux/modules/globalConfig";
import withGlobalConfigFields from "../../../../../hocs/withGlobalConfigFields";
import config from "../../../../../config";

const NoAccessDisclaimer = ({ translate, globalConfig: { offerUrl } }) => (
  <p className="no-access-disclaimer">
    <span>
      {translate(
        "This is the content available in the premium Squaber version. Use one of our bundles to get access to it"
      )}
      :{" "}
    </span>
    <SquaberLink to={offerUrl || config.defaultPremiumOfferUrl} target="_blank">
      {translate("i want to use it")}
    </SquaberLink>
  </p>
);

export default withGlobalConfigFields([globalConfigFields.OFFER_URL])(
  NoAccessDisclaimer
);
