import { firebaseReducer } from "react-redux-firebase";
import { routerReducer } from "react-router-redux";

import { getIsWeb } from "../lib/platformHelper";
import { reducer as modalProvider } from "../lib/react-redux-modal-provider";
import actionHandler from "./modules/actionHandler/index";
import actionInProgress from "./modules/actionInProgress/index";
import advancedChartVisible from "./modules/advancedChartVisible";
import affiliateProgram from "./modules/affiliateProgram";
import alertManagement from "./modules/alertManagement/index";
import alerts from "./modules/alerts/index";
import analysisCategories from "./modules/analysis/analysisCategories";
import stockAnalysis from "./modules/analysis/stockAnalysis";
import auth from "./modules/auth/index";
import authLoginLoading from "./modules/authLoginLoading/index";
import autocomplete from "./modules/autocomplete/index";
import bottomNavigation from "./modules/bottomNavigation/index";
import browsedPagesCount from "./modules/browsedPagesCount";
import browserView from "./modules/browserView";
import chartAnalyses from "./modules/chartAnalyses";
import chartTemplates from "./modules/chartTemplates";
import collapsibleSections from "./modules/collapsibleSections/index";
import configPage from "./modules/configPage/index";
import contactForm from "./modules/contactForm/index";
import emailConfirmation from "./modules/emailConfirmation/index";
import expoUpdate from "./modules/expoUpdate";
import faq from "./modules/faq/index";
import financialSituation from "./modules/financialSituation";
import followed from "./modules/followed/index";
import globalConfig from "./modules/globalConfig";
import historyList from "./modules/historyList/index";
import homeLandingPage from "./modules/homeLandingPage/index";
import instrumentsList from "./modules/instrumentsList/index";
import lastAction from "./modules/lastAction";
import locale from "./modules/locale/index";
import manualStrategies from "./modules/manualStrategies";
import marketingBars from "./modules/marketingBars/index";
import marketingBarsSeen from "./modules/marketingBarsSeen/index";
import marketingMessages from "./modules/marketingMessages";
import mediaMonitor from "./modules/mediaMonitor";
import member from "./modules/member/index";
import menu from "./modules/menu/index";
import nativeSceneHandler from "./modules/nativeSceneHandler";
import navbarHeight from "./modules/navbarHeight";
import notifications from "./modules/notifications/index";
import partnership from "./modules/partnership";
import partnershipTokenElementVisible from "./modules/partnershipTokenElementVisible";
import payment from "./modules/payment/index";
import portfolios from "./modules/portfolios/index";
import preloadedStockPageData from "./modules/preloadedStockPageData";
import priceAlerts from "./modules/priceAlerts/index";
import recommendedStocks from "./modules/recommendedStocks/index";
import reduxSagaTest from "./modules/reduxSagaTest/index";
import salesManagoEventTracker from "./modules/salesManagoEventTracker";
import screenOrientation from "./modules/screenOrientation";
import scrollToTop from "./modules/scrollToTop/index";
import search from "./modules/search/index";
import seenNotificationModal from "./modules/seenNotificationModal";
import status from "./modules/status/index";
import stockData from "./modules/stockData/index";
import stockPageSections from "./modules/stockPageSections";
import stockPageTvChart from "./modules/stockPageTvChart";
import stockRecommendations from "./modules/stockRecommendations";
import tags from "./modules/tags";
import tagsService from "./modules/tagsService";
import tickerAttention from "./modules/tickerAttention";
import transactionHistory from "./modules/transactionHistory/index";
import translations from "./modules/translations/index";
import tvChart from "./modules/tvchartData/index";
import universalTable from "./modules/universalTable";
import userActions from "./modules/userActions/index";
import userMarkets from "./modules/userMarkets/index";
import userMessages from "./modules/userMessages";

// For the sake of readability in dev tools, please try to keep those reducers sorted alphabetically

const rehydrated = (state = false, action) => {
  switch (action.type) {
    case "persist/REHYDRATE":
      return true;
    default:
      return state;
  }
};

export default () => {
  let reducers = {
    actionHandler,
    actionInProgress,
    advancedChartVisible,
    affiliateProgram,
    alertManagement,
    alerts,
    analysisCategories,
    auth,
    authLoginLoading,
    autocomplete,
    bottomNavigation,
    browsedPagesCount,
    browserView,
    chartAnalyses,
    chartTemplates,
    collapsibleSections,
    configPage,
    contactForm,
    emailConfirmation,
    expoUpdate,
    faq,
    financialSituation,
    firebase: firebaseReducer,
    followed,
    globalConfig,
    historyList,
    homeLandingPage,
    instrumentsList,
    member,
    modalProvider,
    lastAction,
    locale,
    manualStrategies,
    marketingBars,
    marketingBarsSeen,
    marketingMessages,
    mediaMonitor,
    menu,
    navbarHeight,
    notifications,
    partnership,
    partnershipTokenElementVisible,
    payment,
    portfolios,
    preloadedStockPageData,
    priceAlerts,
    recommendedStocks,
    rehydrated,
    reduxSagaTest,
    salesManagoEventTracker,
    scrollToTop,
    search,
    seenNotificationModal,
    status,
    stockAnalysis,
    stockData,
    stockPageSections,
    stockPageTvChart,
    stockRecommendations,
    tags,
    tagsService,
    tickerAttention,
    transactionHistory,
    translations,
    tvChart,
    universalTable,
    userActions,
    userMarkets,
    userMessages
  };

  if (getIsWeb()) {
    reducers = {
      ...reducers,
      router: routerReducer
    };
  } else {
    reducers = {
      ...reducers,
      nativeSceneHandler,
      screenOrientation
    };
  }

  return reducers;
};
