import React from "react";
import autoBind from "auto-bind";

export default function withStockTagsModal(TagsList, CreateNewTag) {
  return WrappedComponent => {
    class WithStockTagsModal extends React.Component {
      tabs = [
        {
          label: "Tags list",
          key: "tags-list",
          Component: TagsList
        },
        {
          label: "Create new tag",
          key: "create-new-tag",
          Component: CreateNewTag
        }
      ];

      state = {
        currentTab: "tags-list"
      };

      constructor(props) {
        super(props);

        autoBind.react(this);
      }

      setCurrentTab(key) {
        this.setState({
          currentTab: key
        });
      }

      getCurrentTabComponent(props = {}) {
        const { currentTab } = this.state;

        const { Component } = this.tabs.find(tab => tab.key === currentTab);

        return <Component {...props} />;
      }

      getTabIsActive(key) {
        const { currentTab } = this.state;

        return key === currentTab;
      }

      showCloseButton() {
        return this.getTabIsActive("tags-list");
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            tabs={this.tabs}
            getCurrentTabComponent={this.getCurrentTabComponent}
            setCurrentTab={this.setCurrentTab}
            showCloseButton={this.showCloseButton()}
            getTabIsActive={this.getTabIsActive}
          />
        );
      }
    }

    return WithStockTagsModal;
  };
}
