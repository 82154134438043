import { push } from "react-router-redux";
import { fork, put, select, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import { UrlProvider } from "../../../api/UrlProvider";
import config from "../../../config";
import CardModelValidator from "../../../lib/CardModelValidator";
import decisionModal from "../../../lib/decisionModal";
import { getIsWeb } from "../../../lib/platformHelper";
import openBrowserAsync from "../../../native/lib/WebBrowser/openBroswerAsync";
import { actions as alertActions } from "../alerts";
import { getAuthToken } from "../auth/login/selector";
import { getLocale } from "../translations/selector";
import {
  clearPaymentDetails,
  fetchCurrentOfferRoutine,
  fetchSelectedOfferRoutine,
  finalizePaymentRoutine,
  handlePaymentAfterRedirectRoutine,
  paymentDetailsRoutine,
  releasePaymentRoutine,
  updateCreditCardRoutine
} from "./index";
import { getPaymentDetails as getPaymentDetailsSelector } from "./selector";

function transformCardModel(cardModel) {
  if (cardModel.expiration_year.length === 2) {
    cardModel.expiration_year = `20${cardModel.expiration_year}`;
  }

  return cardModel;
}

function validateCardModel(cardModel) {
  const cardModelValidator = new CardModelValidator(cardModel);

  return cardModelValidator.validate();
}

function* triggerPaymentDetails() {
  let authToken = yield select(getAuthToken);

  if (authToken) {
    yield put(paymentDetailsRoutine.request());
  }
}

function* getPaymentDetails({ payload }) {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.payment.details",
      variables: payload,
      skipAccessCheck: true
    });

    yield put(paymentDetailsRoutine.success(data));
  } catch (error) {
    yield put(paymentDetailsRoutine.failure(error));
  }
}

function* updateCreditCard({ payload: { cardModel } }) {
  const currentPaymentDetails = yield select(getPaymentDetailsSelector);

  cardModel = transformCardModel(cardModel);

  const isValid = validateCardModel(cardModel);

  if (!isValid) {
    yield put(
      alertActions.addErrorAlert({
        title:
          "Your card info contains invalid data. Re-check your credentials and try again."
      })
    );
    return;
  }

  try {
    const response = yield ApiClient.put({
      // TODO: Change endpoint
      urlPath: "app.payment.authorize",
      data: {
        card: { ...cardModel },
        offer: currentPaymentDetails.user_offer_id
      }
    });

    console.log(response);
  } catch (e) {
    console.log(e);
  }
}

function* finalizePayment({
  payload: {
    paymentMethodAlias: paymentMethod,
    paymentMethod: paymentMethodRealName,
    cardModel,
    offer,
    subscriptionId
  }
}) {
  const locale = yield select(getLocale);

  if (paymentMethod === "CARD") {
    cardModel = transformCardModel(cardModel);

    const isValid = validateCardModel(cardModel);

    if (!isValid) {
      yield put(
        alertActions.addErrorAlert({
          title:
            "Your card info contains invalid data. Re-check your credentials and try again."
        })
      );
      yield put(finalizePaymentRoutine.failure());
      return;
    }
  }

  const apiData = getPaymentApi(paymentMethod);

  if (!apiData) {
    yield put(finalizePaymentRoutine.failure());
    return;
  }

  let requestData = {
    method: paymentMethod,
    offer
  };

  if (paymentMethod === "CARD") {
    requestData = {
      ...requestData,
      card: cardModel
    };
  }

  if (paymentMethod === "PAYU") {
    requestData = {
      ...requestData,
      continue_url: UrlProvider.getUrl(
        "fe.settingsPageCategoryAfterPayment",
        {
          locale,
          settingsCategory: "payments"
        },
        config.webAppUrl
      )
    };
  }

  if (paymentMethod === "Bluemedia") {
    requestData = {
      ...requestData,
      method: "BLUEMEDIA"
    };
  }

  if (paymentMethod === "BLUEMEDIA_RECURRING") {
    requestData = {
      ...requestData,
      method: "BLUEMEDIA",
      init_auto_payment: true
    };
  }

  if (paymentMethod === "Stripe") {
    requestData = {
      ...requestData,
      continue_url: UrlProvider.getUrl(
        "fe.settingsPageCategoryAfterPayment",
        {
          locale,
          settingsCategory: "payments"
        },
        config.webAppUrl
      )
    };
  }

  if (paymentMethod === "Stripe Subscription") {
    requestData = {
      ...requestData,
      offer: subscriptionId,
      continue_url: UrlProvider.getUrl(
        "fe.settingsPageCategoryAfterPayment",
        {
          locale,
          settingsCategory: "payments"
        },
        config.webAppUrl
      ),
      mode: "subscription"
    };
  }

  try {
    const {
      data: { message },
      status
    } = yield ApiClient[apiData.method]({
      urlPath: apiData.urlPath,
      data: requestData
    });

    if (status >= 200 && status < 300) {
      yield put(
        push(
          UrlProvider.getUrl("fe.settingsPageCategory", {
            locale,
            settingsCategory: "payments"
          })
        )
      );
      yield put(
        alertActions.addSuccessAlert({
          title: message
        })
      );
      yield put(finalizePaymentRoutine.success());
    }
  } catch (e) {
    let response, status;
    if (e.request) {
      status = e.request.status;
      response = JSON.parse(e.request.response);
    }

    if (status === 300 && response.redirect_url) {
      window.open(response.redirect_url, "_self");
    } else {
      yield put(finalizePaymentRoutine.failure());

      const errorMessage =
        typeof response?.message === "string"
          ? response?.message
          : response?.message?.non_field_errors?.[0];

      yield put(
        alertActions.addErrorAlert({
          title: errorMessage
        })
      );
    }
  }
}

function* releasePayment({ payload: offerId }) {
  const decision = yield decisionModal(
    "ConfirmModal",
    {
      title: "Are you sure?",
      message: "Are you sure you want to release payment method and proceed?"
    },
    ["confirm", "cancel"]
  );
  const locale = yield select(getLocale);

  if (decision === "confirm") {
    try {
      yield ApiClient.put({
        urlPath: "app.payment.release"
      });

      yield put(clearPaymentDetails());
      yield put(paymentDetailsRoutine.trigger());

      if (offerId) {
        const redirectUrl = UrlProvider.getUrl(
          "fe.paymentForm",
          {
            locale,
            offerId
          },
          getIsWeb() ? "/" : config.webAppUrl
        );
        if (getIsWeb()) {
          yield put(push(redirectUrl));
        } else {
          yield openBrowserAsync(redirectUrl);
        }
      }
    } catch (error) {
      console.log(error);
    }
  }
}

function* handlePaymentAfterRedirect({ payload }) {
  const locale = yield select(getLocale);

  try {
    const { data } = yield ApiClient.put({
      urlPath: "app.payment.authorize",
      data: payload
    });

    if (data && data.message) {
      yield put(
        alertActions.addSuccessAlert({
          title: data.message
        })
      );
    } else {
      yield put(
        alertActions.addSuccessAlert({
          title:
            "The transaction has been successful, your access to Squaber.com was granted"
        })
      );
    }

    yield put(
      push(
        UrlProvider.getUrl("fe.stockPage", {
          locale,
          marketId: "GPW",
          stockId: "KGH"
        })
      )
    );
  } catch (error) {
    const { offer } = payload;
    const response = JSON.parse(error.request.response);

    if (response) {
      yield put(
        alertActions.addErrorAlert({
          title: response.message
        })
      );
    }

    yield put(
      push(
        UrlProvider.getUrl("fe.paymentForm", {
          locale,
          offerId: offer
        })
      )
    );
  }
}

function getPaymentApi(paymentMethod) {
  switch (paymentMethod) {
    case "PAYPAL":
    case "CARD":
      return {
        method: "put",
        urlPath: "app.payment.authorize"
      };

    case "PAYU":
      return {
        method: "post",
        urlPath: "app.payu.create"
      };

    case "BLUEMEDIA":
    case "BLUEMEDIA_RECURRING":
      return {
        method: "post",
        urlPath: "app.bluemedia.pretransaction"
      };

    case "Stripe":
    case "Stripe Subscription":
      return {
        method: "post",
        urlPath: "app.stripe.createOrder"
      };

    default:
      return null;
  }
}

function* fetchCurrentOffer() {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.payment.userOffer",
      skipAccessCheck: true
    });

    yield put(fetchCurrentOfferRoutine.success(data));
  } catch (e) {
    yield put(fetchCurrentOfferRoutine.failure(e));
  }
}

function* fetchSelectedOffer({ payload: { offerId } }) {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.payment.offers",
      variables: {
        offerId
      }
    });

    if (data && data.offers.length) {
      yield put(fetchSelectedOfferRoutine.success(data.offers[0]));
    } else {
      yield put(fetchSelectedOfferRoutine.failure());
    }
  } catch (e) {
    yield put(fetchSelectedOfferRoutine.failure(e));
  }
}

function* watchGetStockData() {
  yield takeLatest(paymentDetailsRoutine.TRIGGER, triggerPaymentDetails);
  yield takeLatest(paymentDetailsRoutine.REQUEST, getPaymentDetails);
  yield takeLatest(updateCreditCardRoutine.TRIGGER, updateCreditCard);
  yield takeLatest(finalizePaymentRoutine.TRIGGER, finalizePayment);
  yield takeLatest(releasePaymentRoutine.TRIGGER, releasePayment);
  yield takeLatest(fetchCurrentOfferRoutine.TRIGGER, fetchCurrentOffer);
  yield takeLatest(fetchSelectedOfferRoutine.TRIGGER, fetchSelectedOffer);
  yield takeLatest(
    handlePaymentAfterRedirectRoutine.TRIGGER,
    handlePaymentAfterRedirect
  );
}

export default [fork(watchGetStockData)];
