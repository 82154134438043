import "../styles/default.scss";

import React from "react";

const LandingTypography = ({ children }) => {
  return (
    <div className={"landing-typography landing-typography_dark"}>
      {children}
    </div>
  );
};

export default LandingTypography;
