import { takeLatest, fork, put } from "redux-saga/effects";
import { fetchMarketingBarsRoutine } from "./index";
import ApiClient from "../../../api/ApiClient";

function* getMarketingBars() {
  try {
    const { data } = yield ApiClient.get("app.marketing.bar");

    yield put(fetchMarketingBarsRoutine.success(data));
  } catch (e) {}
}

function* watchGetMarketingBars() {
  yield takeLatest(fetchMarketingBarsRoutine.TRIGGER, getMarketingBars);
}

export default [fork(watchGetMarketingBars)];
