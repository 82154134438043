import React from "react";
import PropTypes from "prop-types";
import InputMask from "react-input-mask";
import withTranslations from "../../../../../hocs/withTranslations";
import chipImage from "../../../../../assets/images/chip.png";
import leadingZero from "../../../../../lib/leadingZero";
import "../styles/default.scss";

class CardMockup extends React.Component {
  static propTypes = {
    cardModel: PropTypes.object,
    onValueChange: PropTypes.func
  };

  render() {
    const {
      translate,
      onValueChange,
      card_number,
      name_on_card,
      expiration_month,
      expiration_year,
      card_code
    } = this.props;

    return (
      <div className="card-mockup-wrapper">
        <div className="card-mockup">
          <div className="side face">
            <img src={chipImage} alt="Chip" className="chip" />
            <div className="input-wrapper card-number">
              <InputMask
                mask="9999 9999 9999 9999"
                value={card_number}
                maskChar=" "
                onChange={event => {
                  onValueChange(
                    "card_number",
                    event.target.value.replace(/\s/g, "")
                  );
                }}
              >
                {inputProps => (
                  <input
                    {...inputProps}
                    type="text"
                    name="card_number"
                    placeholder={"1234 5678 9012 3456"}
                  />
                )}
              </InputMask>
            </div>
            <div className="input-wrapper name-on-card">
              <input
                type="text"
                name="name_on_card"
                placeholder={translate("John Doe")}
                value={name_on_card}
                onChange={event => {
                  onValueChange("name_on_card", event.target.value);
                }}
              />
            </div>
            <div className="input-wrapper expiration">
              <select
                name="expiration_month"
                value={expiration_month}
                onChange={event => {
                  const { value } = event.target;
                  onValueChange("expiration_month", value);
                }}
              >
                {new Array(12).fill(0).map((element, index) => (
                  <option key={index} value={leadingZero(index + 1)}>
                    {leadingZero(index + 1)}
                  </option>
                ))}
              </select>
              <span className="slash">/</span>
              <select
                name="expiration_year"
                value={expiration_year}
                onChange={event => {
                  const { value } = event.target;
                  onValueChange("expiration_year", value);
                }}
              >
                {new Array(14).fill(0).map((element, index) => (
                  <option key={index} value={leadingZero(index + 18)}>
                    {leadingZero(index + 18)}
                  </option>
                ))}
              </select>
            </div>
          </div>
          <div className="side reverse">
            <div className="magnet-bar" />
            <div className="cvv-description">CVC/CVV</div>
            <div className="input-wrapper card-code">
              <input
                type="text"
                name="card_code"
                placeholder={""}
                maxLength={3}
                value={card_code}
                onChange={event => {
                  onValueChange("card_code", event.target.value);
                }}
              />
            </div>
          </div>
        </div>
        <div className="card-mockup-disclaimer">
          <span className="description">
            {translate(
              "Investio sp.z o.o does not save payment card information. PayLane provides security and payment service."
            )}
          </span>
          <i className="fal fa-lock-alt icon" />
        </div>
      </div>
    );
  }
}

export default withTranslations(CardMockup);
