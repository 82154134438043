import React from "react";
import { AllHtmlEntities } from "html-entities";
import moment from "moment-mini";
import SquaberLink from "../../SquaberLink";
import { UrlProvider } from "../../../../../api/UrlProvider";
import withQuoteData from "../../../../../hocs/withQuoteData";
import Price from "../../../../../common/Price";
import ChangePercent from "../../ChangePercent";
import SquaberButton from "../../SquaberButton";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import stripHtmlTags from "../../../../../lib/stripHtmlTags";
import config from "../../../../../config";
import SimpleAlertButton from "../../SimpleAlertButton/modules/default";

const LongTermSignalsListItem = ({
  translate,
  locale,
  created,
  quoteData,
  updated,
  description,
  author,
  stock: { ticker, market, name: stock_name, currency, id: stockId }
}) => {
  const { close: closePrice, change: dailyChange } = quoteData || {};
  const updateDateDiff = Math.floor(
    moment(updated).diff(moment(created)) / 60000
  );

  const entities = new AllHtmlEntities();

  let transformedContent = entities.decode(
    stripHtmlTags(description, { allowedTags: [] })
  );

  const maxLength = config.squaberCommentDescriptionMaxLength;

  if (transformedContent.length > maxLength) {
    transformedContent = transformedContent.substring(0, maxLength) + "...";
  }

  return (
    <div className="content-section history-list-item">
      <div className="history-list-item__row">
        <div className="basic-info">
          <SquaberLink
            to={UrlProvider.getUrl("fe.stockPage", {
              locale,
              marketId: market,
              stockId: ticker
            })}
          >
            <h2 className="stock-ticker">{ticker}</h2>
          </SquaberLink>
          <p className="stock-name">{stock_name}</p>
        </div>
        <div className="alert-status">
          <SimpleAlertButton stockId={stockId} ticker={ticker} market={market} />
        </div>
      </div>
      <div className="history-list-item__row">
        <div className="basic-info">
          <p className="secondary-text description">
            {translate("Created on")}:{" "}
            {moment(created).format("DD.MM.YYYY, HH:mm")}
          </p>
        </div>
        <div className="cells-wrapper">
          <div className="cell">
            <div className="cell__label">{translate("Current price")}</div>
            <div className="cell__content">
              {closePrice ? (
                <Price value={closePrice} currency={currency} />
              ) : (
                <i className="fal fa-spinner fa-spin" />
              )}
            </div>
          </div>
          <div className="cell">
            <div className="cell__label">{translate("Daily change")}</div>
            <div className="cell__content">
              {dailyChange ? (
                <ChangePercent change={dailyChange} />
              ) : (
                <i className="fal fa-spinner fa-spin" />
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="history-list-item__row comment-summary">
        <div dangerouslySetInnerHTML={{ __html: transformedContent }} />
      </div>
      <div className="history-list-item__footer">
        <div className="author-data">
          <p className="secondary-text multiline description">
            {translate("Author")}: {author.first_name} {author.last_name}
          </p>
          {updateDateDiff > 0 ? (
            <p className="secondary-text multiline">
              {translate("Updated on")}:{" "}
              {moment(updated).format("DD.MM.YYYY HH:mm")}
            </p>
          ) : null}
        </div>
        <div className="button-wrapper">
          <SquaberButton
            color="primary_small"
            onClick={() => {
              showModal("InterestingStockDescription", {
                title: translate("Long term signal - %{ticker}", { ticker }),
                content: description
              });
            }}
          >
            {translate("Description")}
          </SquaberButton>
        </div>
      </div>
    </div>
  );
};

LongTermSignalsListItem.propTypes = {};

export default withQuoteData()(LongTermSignalsListItem);
