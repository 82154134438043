import React from "react";

import ApiClientAsync from "../api/ApiClientAsync";
import withUserData from './withUserData';

export default function withFirstAnalysis(WrappedComponent) {
  @withUserData
  class WithFirstAnalysis extends React.Component {
    state = {
      firstAnalysis: []
    };
    async componentDidMount() {
      await this.getFirstAnalysis();
    }

    async getFirstAnalysis() {
      const { authToken, stock, locale } = this.props;
      const apiClient = new ApiClientAsync(locale, authToken);

      const { ticker, market } = stock ?? {};
      try {
        const response = await apiClient.get({
          urlPath: "app.stocks.stockPageAnalysisFiltered",
          variables: {
            limit: 1,
            ticker,
            market
          }
        });

        this.setState({ firstAnalysis: response.results });
      } catch (e) {
        console.error(e);
        return [];
      }
    }

    async componentDidUpdate(prevProps) {
      const { stock } = this.props;
      const { ticker, market } = stock?.stock ?? {};
      const prevTicker = prevProps?.stock?.stock?.ticker;
      const prevMarket = prevProps?.stock?.stock?.market;
      const stockChanged = ticker !== prevTicker || market !== prevMarket;

      if (!ticker || !market || !stockChanged) return;

      await this.getFirstAnalysis();
    }

    render() {
      const { firstAnalysis } = this.state;

      return <WrappedComponent {...this.props} firstAnalysis={firstAnalysis} />;
    }
  }

  return WithFirstAnalysis;
}
