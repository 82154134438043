import "../styles/default.scss";

import React from "react";
import { Container } from "reactstrap";

import PaymentAlertAbstract from "../../../../../abstract/PaymentAlertAbstract";
import withMarketingMessages from "../../../../../hocs/withMarketingMessages";
import withOverlay from "../../../../../hocs/withOverlay";
import withPaymentDetails from "../../../../../hocs/withPaymentDetails";
import withUserData from "../../../../../hocs/withUserData";
import SquaberLink from "../../SquaberLink";

@withPaymentDetails
@withUserData
@withMarketingMessages
@withOverlay
class PaymentAlert extends PaymentAlertAbstract {
  render() {
    const {
      marketingMessagesTopBar,
      dismissMarketingMessageTopBar,
      marketingMessageTopBarDismissed,
      clickCtaMarketingMessage,
      disabledOverlay
    } = this.props;

    if (disabledOverlay) {
      return null;
    }

    const { accessTime } = this.state;

    const availableMarketingMessage =
      !marketingMessageTopBarDismissed &&
      marketingMessagesTopBar &&
      marketingMessagesTopBar.length
        ? marketingMessagesTopBar[0]
        : null;

    if (!availableMarketingMessage) {
      return null;
    }

    const linkUrl = availableMarketingMessage.cta_button_url;

    let message = availableMarketingMessage.message;

    if (message.indexOf("{{access_time_days}}") !== -1) {
      if (!accessTime) {
        return null;
      } else {
        message = message.replace("{{access_time_days}}", accessTime.days);
      }
    }

    if (message.indexOf("{{access_time}}") !== -1) {
      if (!accessTime) {
        return null;
      } else {
        message = message.replace("{{access_time}}", accessTime.time);
      }
    }

    const closable = availableMarketingMessage.can_skip;
    const marketingMessageActionPayload = {
      messageId: availableMarketingMessage.id,
      messageCtaUrl: availableMarketingMessage.cta_button_url,
      messageType: availableMarketingMessage.type,
      messageText: availableMarketingMessage.message
    };

    return (
      <SquaberLink
        onClick={() => clickCtaMarketingMessage(marketingMessageActionPayload)}
        to={linkUrl}
        className="payment-alert-wrapper"
        target="_blank"
      >
        <Container fluid className="payment-alert">
          <Container className="alert-wrapper">
            <div className="alert-content">
              <div
                className="alert-message"
                dangerouslySetInnerHTML={{ __html: message }}
              />
              {availableMarketingMessage.cta_button_label ? (
                <span className="cta-label">
                  {availableMarketingMessage.cta_button_label}
                </span>
              ) : null}
              <i className="fal fa-angle-right" />
            </div>
            {closable ? (
              <span
                className="close-information"
                onClick={event => {
                  event.preventDefault();
                  event.stopPropagation();
                  dismissMarketingMessageTopBar(marketingMessageActionPayload);
                }}
              >
                <i className="fal fa-times" />
              </span>
            ) : null}
          </Container>
        </Container>
      </SquaberLink>
    );
  }
}

export default PaymentAlert;
