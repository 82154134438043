import React, { useEffect } from "react";
import { compose } from "redux";
import withSimpleChartData from "../../../../../hocs/withSimpleChartData";
import SimpleChart from "../../../common/SimpleChart/modules/default";
import withQuoteData from "../../../../../hocs/withQuoteData";
import "../styles/default.scss";

const LightweightChartPage = ({
  simpleChartData,
  simpleChartDataLoading,
  quoteData,
  chartDataFetchError
}) => {
  const appContentElement = document.querySelector(".app-content");

  useEffect(() => {
    appContentElement.classList.add("tv-full-screen");

    return () => {
      appContentElement.classList.remove("tv-full-screen");
    };
  }, []);

  return (
    <div className="full-page-simple-chart-wrapper">
      <SimpleChart
        data={simpleChartData}
        loading={simpleChartDataLoading}
        quoteData={quoteData}
        defaultChartType={"CANDLES"}
        chartDataFetchError={chartDataFetchError}
        showOptions
        showVolumeStudy
        onFullscreenChartActivated={() => {
          if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage("ADVANCED_CHART_ACTIVATED");
          }
        }}
      />
    </div>
  );
};

LightweightChartPage.propTypes = {};

export default compose(
  withSimpleChartData(props => props.match.params.stockId),
  withQuoteData(false, props => props.match.params.stockId)
)(LightweightChartPage);
