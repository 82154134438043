import { isEmpty, isEqual } from "lodash";
import { delay, fork, put, select, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import AnalyticsEventTracker from "../../../lib/AnalyticsEventTracker";
import decisionModal from "../../../lib/decisionModal";
import AddStockToListEvent from "../../../lib/SalesManago/events/AddStockToListEvent";
import DeleteStockFromListEvent from "../../../lib/SalesManago/events/DeleteStockFromListEvent";
import StockListEvent from "../../../lib/SalesManago/events/StockListEvent";
import TagsHelper from "../../../lib/TagsHelper/TagsHelper";
import transformFormData from "../../../lib/transformFormData";
import { actions as alertActions } from "../alerts";
import { trackSMEvent } from "../salesManagoEventTracker";
import { addTag, deleteTag, updateTag } from "../tagsService";
import {
  addTagInNeedOfRefresh,
  connectTagToStockRoutine,
  createNewTagRoutine,
  deleteSingleTagRoutine,
  disconnectTagFromStockRoutine,
  editSingleTagDetailsRoutine,
  fetchAllAvailableTagsRoutine,
  fetchAllTagsRoutine,
  fetchSingleTagDetailsRoutine,
  fetchStocksListFromSourceRoutine,
  fetchStockTagsRoutine,
  fetchTagsWithQueryRoutine,
  refreshStocksListSourceTagDetailsRoutine,
  removeTagInNeedOfRefresh,
  UPDATE_STOCKS_LIST_SOURCE,
  updateSingleTagDetails,
  updateStocksListSource
} from "./index";
import {
  getAllTags,
  getSingleTagDetails,
  getStocksListSource,
  getTagsInNeedOfRefresh
} from "./selector";

function* onFetchStockTags({ payload }) {
  const { stockId } = payload;

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.tags.stockTags",
      skipAccessCheck: true,
      variables: {
        stockId: stockId
      }
    });

    yield put(fetchStockTagsRoutine.success(data));
  } catch (error) {
    yield put(fetchStockTagsRoutine.failure(error));
  }
}

function* onFetchAllTags({ payload }) {
  let requestData = {
    urlPath: "app.tags.allTags"
  };

  if (payload && payload.limit) {
    requestData = {
      urlPath: "app.tags.allTagsWithLimit",
      skipAccessCheck: true,
      variables: {
        limit: payload.limit
      }
    };
  }

  try {
    const { data } = yield ApiClient.get(requestData);

    yield put(fetchAllTagsRoutine.success(data));
  } catch (error) {
    yield put(fetchAllTagsRoutine.failure(error));
  }
}

function* onCreateNewTag({ payload }) {
  let newTagData = {};

  for (let field of payload) {
    newTagData[field.name] = field.value;
  }

  try {
    const { data } = yield ApiClient.post({
      urlPath: "app.tags.tag",
      skipAccessCheck: true,
      data: newTagData
    });
    const smData = {
      id: data.id,
      action: "CREATION",
      name: data.name
    };

    const smEvent = new StockListEvent(smData);
    yield put(trackSMEvent(smEvent));

    yield put(createNewTagRoutine.success(data));
    yield put(addTag(data));
    yield put(
      alertActions.addSuccessAlert({ title: "Tag created successfully" })
    );

    yield AnalyticsEventTracker.trackTagCreatedEvent();
  } catch (e) {
    yield put(
      alertActions.addErrorAlert({ title: "Unexpected error occurred" })
    );
  }
}

function* onConnectTagToStock({ payload }) {
  try {
    yield ApiClient.post({
      urlPath: "app.tags.tagToStockConnection",
      skipAccessCheck: true,
      variables: payload
    });

    const { tagId, stockId, ticker } = payload;

    const smData = {
      stockListId: tagId,
      stockId: stockId,
      stockTicker: ticker
    };

    const smEvent = new AddStockToListEvent(smData);
    yield put(trackSMEvent(smEvent));

    yield put(connectTagToStockRoutine.success({ payload }));

    yield put(
      alertActions.addSuccessAlert({
        title: "Tag settings changed successfully"
      })
    );

    yield put(refreshStocksListSourceTagDetailsRoutine());

    if (payload.shouldRefreshStockPageTags) {
      yield put(fetchStockTagsRoutine.trigger({ stockId: payload.stockId }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* onDisconnectTagFromStock({ payload }) {
  try {
    yield ApiClient.delete({
      urlPath: "app.tags.tagToStockConnection",
      skipAccessCheck: true,
      variables: payload
    });

    const { tagId, stockId, ticker } = payload;

    const smData = {
      stockListId: tagId,
      stockId: stockId,
      stockTicker: ticker
    };

    const smEvent = new DeleteStockFromListEvent(smData);

    yield put(trackSMEvent(smEvent));

    yield put(disconnectTagFromStockRoutine.success({ payload }));

    yield put(
      alertActions.addSuccessAlert({
        title: "Tag settings changed successfully"
      })
    );

    yield put(refreshStocksListSourceTagDetailsRoutine());

    if (payload.shouldRefreshStockPageTags) {
      yield put(fetchStockTagsRoutine.trigger({ stockId: payload.stockId }));
    }
  } catch (error) {
    console.log(error);
  }
}

function* refreshTags(tagsInNeedOfRefresh = []) {
  const expireTime = 300000;

  const { data: currentSingleTagData } = yield select(getSingleTagDetails);
  const { data: currentAllTags } = yield select(getAllTags);
  const currentStockListSourceTagData = yield select(getStocksListSource);

  for (let tag of tagsInNeedOfRefresh) {
    const { id, created } = tag;
    let newTagData = [];

    const hasExpired = new Date().getTime() - created > expireTime;
    if (hasExpired) {
      yield put(removeTagInNeedOfRefresh(id));
      continue;
    }

    try {
      const { data } = yield ApiClient.get({
        urlPath: "app.tags.singleTag",
        skipAccessCheck: true,
        variables: {
          id
        }
      });

      newTagData = TagsHelper.flatStocks(data);
    } catch (err) {
      yield put(removeTagInNeedOfRefresh(id));
      console.error(err);
      continue;
    }

    const currentTagData = currentAllTags.find(({ id: tagId }) => tagId === id);

    if (!isEmpty(currentTagData) && !isEqual(currentTagData, newTagData)) {
      yield put(updateTag(newTagData));
    }

    if (
      currentStockListSourceTagData?.id === id &&
      !isEqual(currentStockListSourceTagData, newTagData)
    ) {
      yield put(updateStocksListSource(newTagData));
    }

    if (
      currentSingleTagData?.id === id &&
      !isEqual(currentSingleTagData, newTagData)
    ) {
      yield put(updateSingleTagDetails(newTagData));
    }
  }
}

function* onRefreshTagsOnDelay() {
  const delayTime = 5000;
  const maxExecutions = 5;
  let currentExecution = 0;

  while (currentExecution < maxExecutions) {
    currentExecution++;

    const tagsInNeedOfRefresh = yield select(getTagsInNeedOfRefresh);
    if (isEmpty(tagsInNeedOfRefresh)) break;

    yield refreshTags(tagsInNeedOfRefresh);
    yield delay(delayTime);
  }
}

function* onFetchSingeTagDetails({ payload }) {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.tags.singleTag",
      skipAccessCheck: true,
      variables: {
        id: payload
      }
    });

    yield put(fetchSingleTagDetailsRoutine.success(data));
  } catch (error) {
    yield put(fetchSingleTagDetailsRoutine.failure(error));
  }
}

function* onEditSingeTagDetails({ payload }) {
  const { tagId, fields } = payload;

  const formData = transformFormData(fields);

  try {
    const { data } = yield ApiClient.put({
      urlPath: "app.tags.singleTag",
      skipAccessCheck: true,
      variables: {
        id: tagId
      },
      data: formData
    });

    yield put(
      alertActions.addSuccessAlert({
        title: "Tag settings changed successfully"
      })
    );
    yield put(editSingleTagDetailsRoutine.success(data));
    yield put(updateTag(data));
  } catch (error) {
    yield put(editSingleTagDetailsRoutine.failure(error));
  }
}

function* onDeleteSingleTag({ payload }) {
  const { tagId, tagName } = payload;

  const decision = yield decisionModal(
    "ConfirmModal",
    {
      title: "Are you sure?",
      message:
        "Are you sure you want to remove this tag? It will also be removed from any stock you assigned it to."
    },
    ["confirm", "cancel"]
  );

  if (decision === "cancel") {
    yield put(deleteSingleTagRoutine.failure());
    yield put(
      alertActions.addInfoAlert({
        title: "Canceled"
      })
    );

    return;
  }

  try {
    const { data } = yield ApiClient.delete({
      urlPath: "app.tags.singleTag",
      skipAccessCheck: true,
      variables: {
        id: tagId
      }
    });

    const smData = {
      id: tagId,
      action: "DELETION",
      name: tagName
    };

    const smEvent = new StockListEvent(smData);
    yield put(trackSMEvent(smEvent));

    yield put(
      alertActions.addSuccessAlert({ title: "Tag removed successfully" })
    );
    yield put(deleteSingleTagRoutine.success(data));
    yield put(deleteTag(tagId));
  } catch (error) {
    yield put(deleteSingleTagRoutine.failure(error));
  }
}

function* onFetchTagsWithQuery({ payload }) {
  const { query, includeAdminTags } = payload;

  const urlPath = includeAdminTags
    ? "app.tags.allTagsWithQuery"
    : "app.tags.userTagsWithQuery";

  const variables = {
    query,
    returnAdminTags: includeAdminTags,
    limit: 0
  };

  try {
    const { data } = yield ApiClient.get({
      urlPath,
      variables,
      skipAccessCheck: true
    });

    const dataToReturn = Array.isArray(data) ? data : data.results;

    yield put(fetchTagsWithQueryRoutine.success(dataToReturn));
  } catch (error) {
    yield put(fetchTagsWithQueryRoutine.failure(error));
  }
}

function* onUpdateStocksListSource({ payload: { source } }) {
  if (source) {
    yield put(refreshStocksListSourceTagDetailsRoutine());
  }
}

function* onFetchStocksListFromSource() {
  const source = yield select(getStocksListSource);

  if (!source) {
    return;
  }

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.tags.singleTag",
      skipAccessCheck: true,
      variables: {
        id: source.id
      }
    });

    const stocks = data.stocks.map(stock => ({
      stock,
      user_stock: {}
    }));

    yield put(fetchStocksListFromSourceRoutine.success(stocks));
  } catch (error) {
    console.log(error);
  }
}

export function* onFetchAllAvailableTags() {
  try {
    const { data } = yield ApiClient.get("app.tags.allAvailableTags");

    yield put(fetchAllAvailableTagsRoutine.success(data));
  } catch (error) {
    yield put(fetchAllAvailableTagsRoutine.failure(error));
  }
}

function* onRefreshStocksListSourceTagDetails() {
  const stocksListSource = yield select(getStocksListSource);

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.tags.singleTag",
      skipAccessCheck: true,
      variables: {
        id: stocksListSource.id
      }
    });

    data.stocks = data.stocks.map(stock => stock.id);

    yield put(updateTag(data));
    yield put(refreshStocksListSourceTagDetailsRoutine.success(data));
  } catch (error) {}
}

function* watchFetchTags() {
  yield takeLatest(fetchStockTagsRoutine.TRIGGER, onFetchStockTags);
  yield takeLatest(fetchAllTagsRoutine.TRIGGER, onFetchAllTags);
  yield takeLatest(createNewTagRoutine.TRIGGER, onCreateNewTag);
  yield takeLatest(connectTagToStockRoutine.TRIGGER, onConnectTagToStock);
  yield takeLatest(
    disconnectTagFromStockRoutine.TRIGGER,
    onDisconnectTagFromStock
  );
  yield takeLatest(
    fetchSingleTagDetailsRoutine.TRIGGER,
    onFetchSingeTagDetails
  );
  yield takeLatest(editSingleTagDetailsRoutine.TRIGGER, onEditSingeTagDetails);
  yield takeLatest(deleteSingleTagRoutine.TRIGGER, onDeleteSingleTag);
  yield takeLatest(fetchTagsWithQueryRoutine.TRIGGER, onFetchTagsWithQuery);
  yield takeLatest(UPDATE_STOCKS_LIST_SOURCE, onUpdateStocksListSource);
  yield takeLatest(
    fetchStocksListFromSourceRoutine.TRIGGER,
    onFetchStocksListFromSource
  );
  yield takeLatest(
    fetchAllAvailableTagsRoutine.TRIGGER,
    onFetchAllAvailableTags
  );
  yield takeLatest(
    refreshStocksListSourceTagDetailsRoutine.TRIGGER,
    onRefreshStocksListSourceTagDetails
  );
  yield takeLatest(addTagInNeedOfRefresh, onRefreshTagsOnDelay);
}

export default [fork(watchFetchTags)];
