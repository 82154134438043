import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

interface MarketingMessageActivityMetaData {
  marketingMessageId: string;
  marketingMessageText: string;
  marketingMessageCtaUrl: string;
  action: "CTA" | "close" | "accept" | "skipped";
  marketingMessageType: string;
}

class MarketingMessageActivityEvent extends BaseEvent {
  constructor(
    marketingMessageActivityMetaData: MarketingMessageActivityMetaData
  ) {
    super();
    this.marketingMessageActivityMetaData = marketingMessageActivityMetaData;
  }

  getEventName() {
    return "marketing_messages_activity";
  }

  getEventTriggerName() {
    return "Marketing Message (/admin/marketing/marketingmessage/) has few options - close, accept button, and CTA button - this event should tell us what user does";
  }

  getEventDetailsMatrix() {
    return `detail5=marketing message id
detail6=marketing message text
detail7=mraketing message CTA url
detail8=action (CTA/close/accept/skipped)
detail9=marketing message type`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: this.marketingMessageActivityMetaData.marketingMessageId,
      detail6: this.marketingMessageActivityMetaData.marketingMessageText,
      detail7: this.marketingMessageActivityMetaData.marketingMessageCtaUrl,
      detail8: this.marketingMessageActivityMetaData.action,
      detail9: this.marketingMessageActivityMetaData.marketingMessageType
    };
  }
}

export default MarketingMessageActivityEvent;
