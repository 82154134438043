import type { ContactEventData } from "../types/SalesManagoEventData.type";
import smEventTypes from "../utils/smEventTypes";
import { BaseEvent } from "./BaseEvent";

interface UserLoginMetaData {
  referrer: string;
  redirectionAfterLogin: string;
  loginType: "email" | "gmail" | "facebook" | "apple";
}

class UserLoginEvent extends BaseEvent {
  constructor(userLoginMetaData: UserLoginMetaData) {
    super();
    this.userLoginMetaData = userLoginMetaData;
  }

  getEventName() {
    return "log_in";
  }

  getEventTriggerName() {
    return "Successful login";
  }

  getEventDetailsMatrix() {
    return `detail5=Source URL (referrer)
detail6=redirection after login
detail7=login type`;
  }

  getContactEventData(): ContactEventData {
    return {
      contactExtEventType: smEventTypes.LOGIN,
      detail5: this.userLoginMetaData.referrer,
      detail6: this.userLoginMetaData.redirectionAfterLogin,
      detail7: this.userLoginMetaData.loginType
    };
  }
}

export default UserLoginEvent;
