import React from "react";
import { compose } from "redux";
import { Redirect } from "react-router";
import qs from "qs";
import { UrlProvider } from "../../../../../api/UrlProvider";
import withFollowedStocksList from "../../../../../hocs/withFollowedStocksList";
import withAddAlert from "../../../../../hocs/withAddAlert";
import withMarketTransltaions from "../../../../../hocs/withMarketTranslations";
import { getTranslatedMarket } from "../../../../../lib/getTranslatedMarket";

const MyStocksRedirect = ({
  followedStocks,
  locale,
  addInfoAlert,
  marketTranslations
}) => {
  const query = qs.parse(window.location.search, { ignoreQueryPrefix: true });

  let stockToRedirectTo = null;
  let urlToRedirectTo = UrlProvider.getUrl("fe.search", { locale });

  if (followedStocks.length) {
    stockToRedirectTo = followedStocks[0].stock;

    const translatedMarketId = getTranslatedMarket(
      locale,
      stockToRedirectTo.market,
      marketTranslations
    );

    urlToRedirectTo = UrlProvider.getUrl("fe.stockPage", {
      locale,
      marketId: translatedMarketId,
      stockId: stockToRedirectTo.ticker
    });
  } else if (query.after_login) {
    urlToRedirectTo = UrlProvider.getUrl("fe.stockPage", {
      locale,
      marketId: "GPW",
      stockId: "KGH"
    });
  } else {
    addInfoAlert({
      title:
        "You don't have any alerted stocks yet. Search for the ones that interests you."
    });
  }

  return <Redirect to={urlToRedirectTo} />;
};

MyStocksRedirect.propTypes = {};

export default compose(
  withFollowedStocksList,
  withAddAlert,
  withMarketTransltaions
)(MyStocksRedirect);
