import { UrlProvider } from "../api/UrlProvider";

export default class PageHelper {
  static getIsRegisterPage(location, locale) {
    return location.pathname === UrlProvider.getUrl("fe.register", { locale });
  }

  static getIsLoginPage(location, locale) {
    return location.pathname === UrlProvider.getUrl("fe.login", { locale });
  }

  static getIsPaymentPage(location, locale) {
    return location.pathname.startsWith(
      UrlProvider.getUrl("fe.paymentDetails", { locale })
    );
  }

  static getIsHomePage(location, locale) {
    return (
      location.pathname === UrlProvider.getUrl("fe.home") ||
      location.pathname === UrlProvider.getUrl("fe.homeWithLocale", { locale })
    );
  }

  static getIsLandingPage(location) {
    return location.pathname.indexOf("/l/") === 0;
  }
}
