import React from "react";
import { isBrowser, isMobile } from "react-device-detect";

export default function withDeviceType(WrappedComponent) {
  class WithDeviceType extends React.Component {
    render() {
      let deviceProps = { isMobile, isBrowser };

      return <WrappedComponent {...this.props} {...deviceProps} />;
    }
  }

  return WithDeviceType;
}
