function getStockMessages(stock, translate) {
  if (!stock) {
    return [];
  }

  const stockIsActive = stock.stock.is_active;
  const comment = stock.stock.attention;
  const oldQuote = stock.old_quote;
  const messages = [];
  const stockHasQuote = stock?.stock && stock?.latest_quote_datetime_utc;

  if (typeof stockIsActive !== "undefined" && !stockIsActive) {
    messages.push({
      header: translate("stock_not_active_heading"),
      content: translate("stock_not_active_content")
    });
  }

  if (comment) {
    messages.push({
      header: translate("squaber-team-comment"),
      content: comment
    });
  }

  if (typeof oldQuote === "number" && oldQuote > 5) {
    messages.push({
      header: translate("Warning! No listing"),
      content: translate(
        'No listing for the company for %{days} days. For various reasons, the Company may have suspended quotations, no longer be listed on the stock exchange or on these days there were no transactions on it for other reasons. More information in the "Market messages" section and in our chat.',
        {
          days: oldQuote
        }
      )
    });
  }
  if (!stockHasQuote) {
    messages.push({
      header: translate("empty-latest-quote-datetime-header"),
      content: translate("empty-latest-quote-datetime-content")
    });
  }

  return messages;
}

export default getStockMessages;
