// @flow
import axios from "axios";
import qs from "qs";
import { select, put } from "redux-saga/effects";
import { UrlProvider } from "./UrlProvider";
import NetworkError from "./NetworkError";
import { RequestParams } from "./RequestParamsType";
import config from "../config";
import { getLocale } from "../redux/modules/translations/selector";
import { getAuthToken } from "../redux/modules/auth/login/selector";
import { getIsWeb } from "../lib/platformHelper";
import { showModal } from "../lib/react-redux-modal-provider";
import { setError } from "../redux/modules/status";
import envVariables from "../lib/envHelpers/getEnvVariables";

class ApiClient {
  static *getAuthToken() {
    const { search } = getIsWeb() ? window.location : {};
    const urlParams = search ? qs.parse(search.replace("?", "")) : {};
    const existingAuthToken = yield select(getAuthToken);

    return urlParams.auth_token || existingAuthToken;
  }

  static *get(requestParams: RequestParams | string) {
    if (typeof requestParams === "string") {
      requestParams = {
        urlPath: requestParams
      };
    }
    return yield this._makeRequest("GET", requestParams);
  }

  static *post(requestParams: RequestParams) {
    if (typeof requestParams === "string") {
      requestParams = {
        urlPath: requestParams
      };
    }
    return yield this._makeRequest("POST", requestParams);
  }

  static *put(requestParams: RequestParams) {
    return yield this._makeRequest("PUT", requestParams);
  }

  static *patch(requestParams: RequestParams) {
    return yield this._makeRequest("PATCH", requestParams);
  }

  static *delete(requestParams: RequestParams) {
    return yield this._makeRequest("DELETE", requestParams);
  }

  static *_makeRequest(
    method = "GET",
    {
      url,
      urlPath,
      data,
      variables,
      headers,
      skipAccessCheck,
      skipErrorHandler,
      skipAuthorizationHeader = false
    }
  ) {
    if (!url && !urlPath) {
      throw new Error(
        "You have to specify 'url' or 'urlPath' to make request."
      );
    }

    const locale = yield select(getLocale);

    const apiUrl = config.apiUrl.replace(":locale", locale);
    const authToken = yield this.getAuthToken();

    if (urlPath) {
      url = UrlProvider.getUrl(urlPath, variables, apiUrl);
    }

    headers = {
      "Content-Type": "application/json",
      ...headers
    };

    if (authToken && !skipAuthorizationHeader) {
      headers = {
        ...headers,
        Authorization: `Token ${authToken}`
      };
    }

    if (envVariables?.["STAGING_ACCESS_TOKEN"]) {
      headers = {
        ...headers,
        ["X-Staging-Access-Token"]: envVariables["STAGING_ACCESS_TOKEN"]
      };
    }

    try {
      const response = yield axios({
        method,
        url,
        data,
        headers
      });

      return response;
    } catch (error) {
      const { request } = error;
      const { status } = request;

      if (!skipAccessCheck) {
        if (status === 401) {
          showModal("RegisterModal");
        } else if (status === 403) {
          showModal("PremiumModal", {
            iframeUrl: request.getResponseHeader("x-popup-url")
          });
        }
      }

      if (skipErrorHandler && (status === 404 || status > 500)) {
        yield put(setError(status));
      }

      throw new NetworkError(error);
    }
  }
}

export default ApiClient;
