import { handleActions } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

export const fetchUserMessagesRoutine = createRoutine(
  "app/userMessages/FETCH_USER_MESSAGES"
);
export const acceptUserMessageRoutine = createRoutine(
  "app/userMessages/ACCEPT_USER_MESSAGE"
);
export const skipUserMessageRoutine = createRoutine(
  "app/userMessages/SKIP_USER_MESSAGES"
);

export const initialState = {
  data: [],
  loading: false,
  error: null
};

export default handleActions(
  {
    [fetchUserMessagesRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
      error: null
    }),
    [fetchUserMessagesRoutine.ERROR]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    })
  },
  initialState
);
