import { PortfolioData } from "../../../types/PortfolioData.type";
import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

class PortfolioCreatedEvent extends BaseEvent {
  constructor(portfolio: PortfolioData) {
    super();
    this.portfolio = portfolio;
  }

  getEventName() {
    return "portfolio_created";
  }

  getEventTriggerName() {
    return "Portfolio creation by user";
  }

  getEventDetailsMatrix() {
    return `detail5=portfolio id
detail6=initial cash amount
detail7=currency
detail8=is ike/ikze`;
  }

  getContactEventData(): ContactEventData {
    return {
      externalId: String(this.portfolio.id),
      detail5: String(this.portfolio.id),
      detail6: String(this.portfolio.starting_capital),
      detail7: String(this.portfolio.currency),
      detail8: String(this.portfolio.is_ike_ikze)
    };
  }
}

export default PortfolioCreatedEvent;
