import React from "react";
import PropTypes from "prop-types";

export default class AdminCommentAbstract extends React.Component {
  static propTypes = {
    comment: PropTypes.string,
    oldQuote: PropTypes.number
  };

  getMessages() {
    const { comment, translate, oldQuote, stockIsActive } = this.props;
    let messages = [];

    if (typeof stockIsActive !== "undefined" && !stockIsActive) {
      messages.push({
        header: translate("stock_not_active_heading"),
        content: translate("stock_not_active_content")
      });
    }

    if (comment) {
      messages.push({
        header: translate("squaber-team-comment"),
        content: comment
      });
    }

    if (typeof oldQuote === "number" && oldQuote > 5) {
      messages.push({
        header: translate("Warning! No listing"),
        content: translate(
          'No listing for the company for %{days} days. For various reasons, the Company may have suspended quotations, no longer be listed on the stock exchange or on these days there were no transactions on it for other reasons. More information in the "Market messages" section and in our chat.',
          {
            days: oldQuote
          }
        )
      });
    }

    return messages;
  }

  render() {
    throw new Error("This is abstract component");
  }
}
