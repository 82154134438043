import "../styles/default.scss";

import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router";

import MarketingBarsAbstract from "../../../../../abstract/MarketingBarsAbstract";
import withDeviceType from "../../../../../hocs/withDeviceType";
import withMarketingBars from "../../../../../hocs/withMarketingBars";
import withMobileMediaQuery from "../../../../../hocs/withMobileMediaQuery";
import withOverlay from "../../../../../hocs/withOverlay";
import withSmEventTracker from "../../../../../hocs/withSmEventTracker";
import withUserActions from "../../../../../hocs/withUserActions/withUserActions";
import PageHelper from "../../../../../lib/PageHelper";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import SquaberButton from "../../../common/SquaberButton";

@withMobileMediaQuery
@withRouter
@withMarketingBars
@withDeviceType
@withOverlay
@withUserActions
@withSmEventTracker
class MarketingBars extends MarketingBarsAbstract {
  constructor(props) {
    super(props);
    this.state = {
      ...MarketingBarsAbstract.defaultState,
      showedModal: false
    };
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    const {
      translate,
      locale,
      location,
      disabledOverlay,
      isMobileMediaQuery,
      setMarketingBarSeen,
      defaultUserAction
    } = this.props;

    if (isMobileMediaQuery === false) {
      return;
    }

    if (window.ReactNativeWebView) {
      return;
    }

    const isRegisterPage = PageHelper.getIsRegisterPage(location, locale);
    const isLoginPage = PageHelper.getIsLoginPage(location, locale);
    const isPaymentPage = PageHelper.getIsPaymentPage(location, locale);
    const isHomePage = PageHelper.getIsHomePage(location);
    const isLandingPage = PageHelper.getIsLandingPage(location);
    const isAfterUserAction =
      defaultUserAction.count && defaultUserAction.count >= 4;

    if (
      isRegisterPage ||
      isLoginPage ||
      isPaymentPage ||
      isHomePage ||
      isLandingPage ||
      this.shouldReturnNull() ||
      disabledOverlay ||
      !isAfterUserAction
    ) {
      return;
    }

    const finalMarketingBar = this.getFinalMarketingBar();

    if (!finalMarketingBar) {
      return;
    }

    if (this.state.showedModal) {
      return;
    }

    showModal("PageSheetBase", {
      message: <p>{finalMarketingBar.text}</p>,
      confirmButtonText: finalMarketingBar.button_text,
      cancelButtonText: translate("Close"),
      onConfirm: () => {
        setMarketingBarSeen(finalMarketingBar.id);
        window.open(finalMarketingBar.url, "_blank");
        const { trackSMEvent } = this.props;
        this.trackInteraction(finalMarketingBar, "CTA", trackSMEvent);

        this.hide();
      },
      onCancel: () => {
        setMarketingBarSeen(finalMarketingBar.id);
        const { trackSMEvent } = this.props;
        this.trackInteraction(finalMarketingBar, "close", trackSMEvent);
        this.hide();
      },
      translate
    });

    this.setState({ showedModal: true });
  }

  render() {
    const {
      translate,
      locale,
      location,
      setMarketingBarSeen,
      disabledOverlay,
      isMobileMediaQuery,
      defaultUserAction
    } = this.props;

    if (window.ReactNativeWebView) {
      return null;
    }

    const isRegisterPage = PageHelper.getIsRegisterPage(location, locale);
    const isLoginPage = PageHelper.getIsLoginPage(location, locale);
    const isPaymentPage = PageHelper.getIsPaymentPage(location, locale);
    const isHomePage = PageHelper.getIsHomePage(location);
    const isLandingPage = PageHelper.getIsLandingPage(location);
    const isAfterUserAction =
      defaultUserAction.count && defaultUserAction.count >= 4;

    const { isExpanded } = this.state;

    if (
      isRegisterPage ||
      isLoginPage ||
      isPaymentPage ||
      isHomePage ||
      isLandingPage ||
      this.shouldReturnNull() ||
      disabledOverlay ||
      isMobileMediaQuery ||
      !isAfterUserAction
    ) {
      return null;
    }

    const finalMarketingBar = this.getFinalMarketingBar();

    if (!finalMarketingBar) {
      return null;
    }

    const shouldEllipsize = this.shouldEllipsize(finalMarketingBar);

    const expandedState = !shouldEllipsize || (shouldEllipsize && isExpanded);

    return (
      <div
        className={classNames({
          "marketing-bars": true,
          "expanded-state": expandedState
        })}
      >
        <div
          className="marketing-bar-inside"
          onClick={() => {
            this.expand();
          }}
        >
          {!expandedState ? (
            <div className="icon-wrapper">
              <i className="fas fa-info-circle" />
            </div>
          ) : null}
          <div className="content">
            <div className="content-text">
              <p>
                {!expandedState
                  ? `${finalMarketingBar.text
                      .split("")
                      .slice(0, this.maxLength)
                      .join("")}...`
                  : finalMarketingBar.text}
              </p>
              {!expandedState ? (
                <span className="expand">{translate("Read more")}</span>
              ) : null}
            </div>
            {expandedState ? (
              <div className="buttons-wrapper">
                <a
                  href={finalMarketingBar.url}
                  target="_blank"
                  className="marketing-bar-button button-cta"
                >
                  {finalMarketingBar.button_text}
                </a>
                <SquaberButton
                  className="marketing-bar-button button-close"
                  onClick={event => {
                    event.preventDefault();
                    this.hide();
                    setMarketingBarSeen(finalMarketingBar.id);
                  }}
                  buttonStyle={false}
                >
                  {translate("Close")}
                </SquaberButton>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default MarketingBars;
