import stripHtmlTags from "./stripHtmlTags";

export const premiumTextReplacement = "Zawartość dostępna w wersji premium. ";

export default function transformPremiumText(text, userHasAccess) {
  if (userHasAccess) {
    return text;
  }

  text = stripHtmlTags(text);
  const textBaseCount = Math.ceil(text.length / premiumTextReplacement.length);
  let transformedText = "";

  for (let i = 0; i < textBaseCount; i++) {
    transformedText += premiumTextReplacement;
  }

  return transformedText.trim();
}
