import React from "react";
import withInstrumentsList from "../../../../../hocs/withInstrumentsList";
import { UrlProvider } from "../../../../../api/UrlProvider";
import SquaberLink from "../../SquaberLink";

import "../styles/default.scss";

const InstrumentsList = ({
  instrumentsListState: { data, loading },
  locale
}) => {
  if (loading || !data) {
    return (
      <div className="instruments-list-loader text-center">
        <i className="fal fa-spinner fa-spin" />
      </div>
    );
  }
  return (
    <div className="instruments-list">
      <ul>
        {data.map(item => (
          <li className="instruments-list-item" key={item.id}>
            <SquaberLink
              to={UrlProvider.getUrl("fe.stockPage", {
                locale,
                marketId: item.market,
                stockId: item.ticker
              })}
              className="ticker"
            >
              {item.ticker}
            </SquaberLink>{" "}
            - <span className="name">{item.name}</span>
          </li>
        ))}
      </ul>
    </div>
  );
};

InstrumentsList.propTypes = {};

export default withInstrumentsList(InstrumentsList);
