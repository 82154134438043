import React, { Component } from "react";
import PropTypes from "prop-types";
import { every, isEqual } from "lodash";

class FormBuilderCore extends Component {
  static propTypes = {
    handleChange: PropTypes.func,
    afterChange: PropTypes.func,
    handleCheckboxChange: PropTypes.func,
    clearValidationError: PropTypes.func,
    validationErrors: PropTypes.object,
    fields: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        label: PropTypes.string,
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        selected: PropTypes.arrayOf(
          PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        ),
        type: PropTypes.oneOf([
          "text",
          "email",
          "password",
          "select",
          "checkbox",
          "checkbox-group",
          "radio",
          "date"
        ]),
        options: PropTypes.arrayOf(
          PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          })
        ),
        required: PropTypes.bool,
        validations: PropTypes.object,
        validationErrors: PropTypes.object
      })
    )
  };

  handleCheckboxChange(field, value) {
    let newValue = field.selected;

    if (newValue.indexOf(value) === -1) {
      newValue.push(value);
    } else {
      newValue.splice(newValue.indexOf(value), 1);
    }

    this.props.handleCheckboxChange(field, newValue);

    this.clearFieldOrAllFieldsValidationErrors(field.name);
  }

  handleChange(field, value) {
    this.props.handleChange(field, value);

    this.clearFieldOrAllFieldsValidationErrors(field.name);

    if (this.props.afterChange) {
      this.props.afterChange(field, value);
    }
  }

  clearFieldOrAllFieldsValidationErrors(fieldName) {
    if (this.props.clearValidationError) {
      if (
        this.props.validationErrors &&
        this.props.validationErrors[fieldName]
      ) {
        this.props.clearValidationError(fieldName);
      } else if (
        this.props.validationErrors &&
        this.props.validationErrors["__all__"]
      ) {
        this.props.clearValidationError("__all__");
      }
    }
  }

  getValidationProps(field) {
    return {
      required: field.required,
      validations: field.validations,
      validationErrors: field.validationErrors
    };
  }

  getProcessedCheckboxGroupFields(controlledFields, allFields) {
    return controlledFields.map(field => {
      let singleField = allFields.find(x => x.name === field);

      return {
        name: singleField.name,
        selected: singleField.selected,
        options: singleField.options.map(option => option.value)
      };
    });
  }

  getIsCheckboxGroupActive(controlledFields, allFields) {
    let processedFields = this.getProcessedCheckboxGroupFields(
      controlledFields,
      allFields
    );

    return every(processedFields, _field => {
      return isEqual(_field.options, _field.selected);
    });
  }

  handleGroupCheckboxChange(controlledFields, allFields, newValue: boolean) {
    let processedFields = this.getProcessedCheckboxGroupFields(
      controlledFields,
      allFields
    );

    processedFields.forEach(field => {
      this.props.handleCheckboxChange(field, newValue ? field.options : []);
    });
  }

  render() {
    throw new Error(
      "FormBuilder is abstract component. It should not be executed without overriding. Use FormBuilderWeb or FormBuilderNative instead"
    );
  }
}

export default FormBuilderCore;
