import React, { Component } from "react";
import autoBind from "auto-bind";

class FormWrapper extends Component {
  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  state = {
    fields: [],
    canSubmit: true
  };

  static getCanSubmit(fields) {
    let canSubmit = true;

    for (const field of fields) {
      if (field.type === "checkbox") {
        if (field.required && field.selected && field.selected.length === 0) {
          canSubmit = false;
        }
      } else if (field.required && !field.value) {
        canSubmit = false;
      }
    }

    return canSubmit;
  }

  handleChange(field, value) {
    let fields = this.getFields();

    let fieldToChange = fields.find(x => x.name === field.name);

    fieldToChange.value = value;
    fieldToChange.edited = true;

    this.updateFields(fields);
  }

  handleCheckboxChange(field, value) {
    let fields = this.getFields();

    fields.find(x => x.name === field.name).selected = value;

    this.updateFields(fields);
  }

  getFields() {
    return [...this.state.fields];
  }

  updateFields(fields) {
    let canSubmit = FormWrapper.getCanSubmit(fields);

    this.setState({ fields, canSubmit });
  }

  clear() {
    let fields = this.getFields();

    for (let field of fields) {
      field.value = "";
      field.edited = false;
    }

    this.setState({ fields });
  }

  render() {
    throw new Error(
      "FormWrapper is abstract component. It should not be executed without overriding."
    );
  }
}

export default FormWrapper;
