import "../styles/default.scss";

import autoBind from "auto-bind";
import classNames from "classnames";
import React from "react";

import withDeviceType from "../../../../../hocs/withDeviceType";
import withUniversalTable from "../../../../../hocs/withUniversalTable";
import accessLevelsMap from "../../../../../lib/access/accessLevelsMap";
import getAccessLevel from "../../../../../lib/access/getAccessLevel";
import UniversalTableContext from "../../../../../lib/UniversalTable/UniversalTableContext";
import Loader from "../../Loader";
import NoAccessDisclaimer from "../../NoAccessDisclaimer";
import SquaberTable from "../../SquaberTable";
import ActiveFilters from "../components/ActiveFilters/ActiveFilters";
import LoadMore from "../components/LoadMore/LoadMore";
import Pagination from "../components/Pagination/Pagination";
import Settings from "../components/Settings/Settings";
import SettingsButton from "../components/SettingsButton/SettingsButton";
import Tabs from "../components/Tabs/Tabs";
import Renderer from "../renderers";
import withMarketTransltaions from "../../../../../hocs/withMarketTranslations";

@withUniversalTable
@withDeviceType
@withMarketTransltaions
class UniversalTableLiteWeb extends React.Component {
  static contextType = UniversalTableContext;

  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  state = {
    settingsVisible: false
  };

  renderCell(result, cell, userHasAccess) {
    const { marketTranslations } = this.props;
    const { columnsFontSize } = this.context;

    return (
      <Renderer
        key={`${result.id}_${cell.attribute_name}`}
        blurred={!userHasAccess && cell.is_premium}
        value={result[cell.attribute_name]}
        type={cell.values_type}
        format={cell.values_format}
        columnFontSize={columnsFontSize[cell.attribute_name]}
        resultMetaData={result}
        attributeName={cell.attribute_name}
        rendererName={cell.renderer_name}
        marketTranslations={marketTranslations}
      />
    );
  }

  renderHeading(column) {
    return (
      <Renderer
        key={`${column.attribute_name}_${column.label}`}
        value={column.label}
        type={"heading"}
        columnMetaData={column}
        attributeName={column.attribute_name}
      />
    );
  }

  showSettings() {
    this.setState({
      settingsVisible: true
    });
  }

  hideSettings() {
    this.setState({
      settingsVisible: false
    });
  }

  render() {
    const {
      userData,
      isMobile,
      getColumns,
      getColumnPairs,
      fetchNextPage,
      setTab,
      usePagination
    } = this.props;

    const { settingsVisible } = this.state;

    const {
      data,
      config,
      currentTab,
      currentTabId,
      dataLoading,
      configLoading,
      isFetchingNextPage
    } = this.context;

    const accessLevel = getAccessLevel(userData, {});
    const userHasAccess = accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS;

    if (configLoading) {
      return <Loader />;
    }

    const userHasAccessToCurrentTab = !(
      !userHasAccess && currentTab.is_premium
    );

    const shouldRenderColumnPairs = isMobile;

    const columns = getColumns();
    const columnPairs = getColumnPairs();

    const { tabs } = config;

    return (
      <div className="universal-table-lite">
        <SettingsButton
          settingsVisible={settingsVisible}
          showSettings={this.showSettings}
          hideSettings={this.hideSettings}
        />
        {tabs && tabs.length && tabs.length > 1 ? (
          <Tabs tabs={tabs} currentTabId={currentTabId} setTab={setTab} />
        ) : null}
        {userHasAccessToCurrentTab ? (
          <React.Fragment>
            <ActiveFilters />
            <div
              className={classNames({
                "table-wrapper": true,
                "data-loading": dataLoading
              })}
            >
              <SquaberTable
                withDistinctRows
                heading={
                  shouldRenderColumnPairs && columnPairs
                    ? columnPairs.map(columnPair =>
                        [
                          columnPair.csv_data_column_first,
                          columnPair.csv_data_column_second
                        ]
                          .filter(column => !!column)
                          .map(this.renderHeading)
                      )
                    : columns.map(this.renderHeading)
                }
                rows={
                  columns && data && data.results
                    ? data.results.map(result =>
                        shouldRenderColumnPairs
                          ? columnPairs.map(columnPair =>
                              [
                                columnPair.csv_data_column_first,
                                columnPair.csv_data_column_second
                              ]
                                .filter(column => !!column)
                                .map(column =>
                                  this.renderCell(result, column, userHasAccess)
                                )
                            )
                          : columns.map(column =>
                              this.renderCell(result, column, userHasAccess)
                            )
                      )
                    : []
                }
              />
              {dataLoading ? (
                <div className="table-loading-indicator">
                  <Loader small />
                </div>
              ) : null}
            </div>
            {usePagination ? (
              <Pagination />
            ) : (
              <React.Fragment>
                {data && data.next ? (
                  <LoadMore
                    isFetchingNextPage={isFetchingNextPage}
                    fetchNextPage={fetchNextPage}
                  />
                ) : null}
              </React.Fragment>
            )}
            {settingsVisible ? (
              <Settings hideSettings={this.hideSettings} />
            ) : null}
          </React.Fragment>
        ) : (
          <NoAccessDisclaimer />
        )}
      </div>
    );
  }
}

export default UniversalTableLiteWeb;
