import { createAction, handleActions } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

export const getResultsRoutine = createRoutine("app/autocomplete/GET_RESULTS");
export const CLEAR_RESULTS = "app/autocomplete/CLEAR_RESULTS";
export const clearResults = createAction(CLEAR_RESULTS, () => ({}));

const initialState = {
  loading: false,
  result: []
};

export default handleActions(
  {
    [getResultsRoutine.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [getResultsRoutine.FAILURE]: state => ({
      ...state,
      loading: false
    }),
    [getResultsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      result: payload
    }),
    [CLEAR_RESULTS]: state => ({
      ...state,
      result: []
    })
  },
  initialState
);
