import "./styles/default.scss";

import React from "react";

import squaberIcon from "../../../../assets/images/squaber-icon-yellow-300-300.png";

const SquaberLogoLoader = () => {
  return (
    <div className={"logoWrapper"}>
      <div className="logo">
        <img src={squaberIcon} alt="Squaber Logo" />
        <div className="circle"></div>
      </div>
    </div>
  );
};

export default SquaberLogoLoader;
