import React from "react";
import ContentSection from "../../../common/ContentSection/";
import withUserData from "../../../../../hocs/withUserData";
import SquaberButton from "../../../common/SquaberButton/";
import SquaberTable from "../../../common/SquaberTable/";
import SquaberLink from "../../../common/SquaberLink/";
import "../styles/default.scss";

const PartnershipSettings = ({ userData: { tokens }, translate }) => (
  <ContentSection className="partnership-page">
    <h1>{translate("partnership-page-heading")}</h1>
    <p>{translate("partnership-page-description")}</p>
    {translate("partnership-page-button-url") !==
    "partnership-page-button-url" ? (
      <SquaberButton href={translate("partnership-page-button-url")}>
        {translate("partnership-page-button-label")}
      </SquaberButton>
    ) : null}
    <SquaberTable
      withDistinctRows
      className="partnership-tokens-list"
      heading={[
        translate("Partner name"),
        translate("Your unique authorization code"),
        translate("Offer details")
      ]}
      rows={tokens.map(token => {
        const partner = token.partner || {};
        return [
          partner.name,
          token.token,
          <SquaberLink to={partner.offer_url} forceExternalLink>
            {translate("partner-token-action-label")}
          </SquaberLink>
        ];
      })}
    />
  </ContentSection>
);

PartnershipSettings.propTypes = {};

export default withUserData(PartnershipSettings);
