import "../styles/default.scss";

import PropTypes from "prop-types";
import React, { forwardRef, useEffect } from "react";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withMenu from "../../../../../hocs/withMenu";
import withTranslations from "../../../../../hocs/withTranslations";
import withUserData from "../../../../../hocs/withUserData";
import useWindowSize, { commonSizes } from "../../../../hooks/useWindowSize";
import DesktopNavigation from "../components/desktop/DesktopNavigation";
import MobileNavigation from "../components/mobile/MobileNavigation";
import toDesktopNavigationItems from "../dto/toDesktopNavigationItems";

function MainMenu({
  menu,
  userData,
  userHasAccess,
  value: mobileMenuValue,
  setValue: setMobileMenuValue,
  forwardedRef,
  translate,
  locale,
  logout
}) {
  const { width } = useWindowSize();

  useEffect(() => {
    if (!forwardedRef?.current) {
      return;
    }

    const { isOpen, close, open } = forwardedRef.current ?? {};

    if (mobileMenuValue === "menu") {
      open();
      return;
    }

    if (isOpen) {
      close();
    }
  }, [mobileMenuValue]);

  return width < commonSizes.MOBILE ? (
    <MobileNavigation
      items={toDesktopNavigationItems({
        menu: [
          {
            name: translate("Account"),
            url: null,
            target: "_self",
            native_view: "",
            show_for_anonymous: false,
            show_for_logged_in: true,
            show_in_native: false,
            show_for_freemium: true,
            show_for_premium: true,
            children: [
              {
                name: translate("Account"),
                url: UrlProvider.getUrl("fe.settingsPageCategory", {
                  locale,
                  settingsCategory: "personal"
                }),
                target: "_self",
                native_view: "",
                show_for_anonymous: false,
                show_for_logged_in: true,
                show_in_native: false,
                show_for_freemium: true,
                show_for_premium: true,
                children: [],
                marketing_menu_message: null
              },
              {
                name: translate("Settings"),
                url: UrlProvider.getUrl("fe.settingsPageCategory", {
                  locale,
                  settingsCategory: "notifications"
                }),
                target: "_self",
                native_view: "",
                show_for_anonymous: false,
                show_for_logged_in: true,
                show_in_native: false,
                show_for_freemium: true,
                show_for_premium: true,
                children: [],
                marketing_menu_message: null
              },
              {
                name: translate("Logout"),
                url: null,
                target: "_self",
                native_view: "",
                show_for_anonymous: false,
                show_for_logged_in: true,
                show_in_native: false,
                show_for_freemium: true,
                show_for_premium: true,
                children: [],
                marketing_menu_message: null,
                onClick: () => logout()
              }
            ],
            marketing_menu_message: null
          },
          ...menu
        ],
        userData,
        userHasAccess
      })}
      onClose={() => {
        setMobileMenuValue(null);
      }}
      ref={forwardedRef}
    />
  ) : (
    <DesktopNavigation
      ref={forwardedRef}
      items={toDesktopNavigationItems({ menu, userData, userHasAccess })}
    />
  );
}

MainMenu.displayName = "MainMenu";

const ComposedMainMenu = compose(
  withMenu,
  withUserData,
  withBottomNavigation(),
  withTranslations
)(MainMenu);

export default forwardRef((props, ref) => (
  <ComposedMainMenu {...props} forwardedRef={ref} />
));

MainMenu.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      children: PropTypes.arrayOf(
        PropTypes.shape({
          order: PropTypes.number,
          label: PropTypes.string,
          href: PropTypes.string
        })
      ),
      order: PropTypes.number,
      label: PropTypes.string,
      href: PropTypes.string
    })
  )
};
