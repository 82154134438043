import { createAction, handleActions } from "redux-actions";

const DEFAULT_USER_ACTION = "app/userActions/DEFAULT_USER_ACTION";

export const defaultUserAction = createAction(DEFAULT_USER_ACTION);

export const initialState = {
  defaultUserAction: {
    count: 0
  }
};

export default handleActions(
  {
    [DEFAULT_USER_ACTION]: state => {
      return {
        ...state,
        defaultUserAction: {
          ...state.defaultUserAction,
          count: state.defaultUserAction.count + 1
        }
      };
    }
  },
  initialState
);
