import "../styles/default.scss";

import PropTypes from "prop-types";
import React from "react";

import Price from "../../../../../common/Price";
import ChangePercent from "../../ChangePercent";

function Quote({ close, change, quoteClose, quoteChange, currency }) {
  const hasPrice = (close || quoteClose) && currency;

  return (
    <>
      {hasPrice ? (
        <span className="close">
          <Price currency={currency} value={quoteClose ?? close} />
        </span>
      ) : null}
      {change || quoteChange ? (
        <span className="change">
          <ChangePercent change={quoteChange ?? change} />
        </span>
      ) : null}
    </>
  );
}

Quote.propTypes = {
  change: PropTypes.string,
  close: PropTypes.string,
  currency: PropTypes.string,
  quoteClose: PropTypes.string,
  quoteChange: PropTypes.string
};

export default Quote;
