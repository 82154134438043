import React from "react";
import connect from "../i18n/connect";
import {
  releasePaymentRoutine,
  updateCreditCardRoutine
} from "../redux/modules/payment";

export default function withUpdateCreditCard(WrappedComponent) {
  const mapDispatchToProps = {
    updateCreditCard: updateCreditCardRoutine,
    releasePayment: releasePaymentRoutine
  };

  @connect(null, mapDispatchToProps)
  class WithUpdateCreditCard extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithUpdateCreditCard;
}
