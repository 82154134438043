import { createAction, handleActions } from "redux-actions";

const TOGGLE_SECTION_COLLAPSED =
  "app/collapsibleSections/TOGGLE_SECTION_COLLAPSED";

const EXPAND_SECTION = "app/collapsibleSections/EXPAND_SECTION";

const COLLAPSE_SECTION = "app/collapsibleSections/COLLAPSE_SECTION";

export const toggleSectionCollapsed = createAction(
  TOGGLE_SECTION_COLLAPSED,
  (section: string) => ({ section })
);

export const expandSection = createAction(
  EXPAND_SECTION,
  (section: string) => ({ section })
);

export const collapseSection = createAction(
  COLLAPSE_SECTION,
  (section: string) => ({ section })
);

const initialState = {
  "section-description": true
};

export default handleActions(
  {
    [TOGGLE_SECTION_COLLAPSED]: (state, { payload: { section } }) => ({
      ...state,
      [section]: !state[section]
    }),
    [EXPAND_SECTION]: (state, { payload: { section } }) => ({
      ...state,
      [section]: true
    }),
    [COLLAPSE_SECTION]: (state, { payload: { section } }) => ({
      ...state,
      [section]: false
    })
  },
  initialState
);
