import StockCard from "../../StockCard";
import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { isFunction } from "lodash";
import withTranslations from "../../../../../hocs/withTranslations";
import getStockCardType from "../../../../../lib/getStockCardType";

import "../styles/default.scss";

const getClassName = type => {
  const baseClassName = "stock-cards";

  switch (type) {
    case "results":
      return `${baseClassName}--results`;
    default:
      return `${baseClassName}--default`;
  }
};

function StockCards({
  title,
  stocks,
  className,
  type,
  onStockClick,
  translate
}) {
  if (!stocks || !stocks.length) {
    return null;
  }

  return (
    <div className={classNames(className, getClassName(type))}>
      <p className="stock-cards__title">{translate(title)}</p>
      <ul className={"stock-cards__list"}>
        {stocks.map((stock, index) => (
          <li key={`${stock.ticker}-${index}`}>
            <StockCard
              onClick={stock => {
                if (!isFunction(onStockClick)) return;
                onStockClick({ ...stock, index, stockCardsTitle: title });
              }}
              type={getStockCardType(type)}
              {...stock}
            />
          </li>
        ))}
      </ul>
    </div>
  );
}

StockCards.propTypes = {
  stocks: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      ticker: PropTypes.string.isRequired,
      marker: PropTypes.string,
      name: PropTypes.string,
      change: PropTypes.string,
      close: PropTypes.string,
      onStockClick: PropTypes.func
    })
  )
};

export default withTranslations(StockCards);
