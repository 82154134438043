import React from "react";
import withTranslations from "../../../../../hocs/withTranslations";
import ContentSection from "../../../common/ContentSection";
import MarketingEmailsSettings from "../../MarketingEmailsSettings";
import OtherNotificationsSettings from "../../OtherNotificationsSettings";
import config from "../../../../../config";

import "../styles/default.scss";

const NotificationsSettings = ({ translate }) => (
  <div className="notifications-settings">
    <ContentSection>
      <h1>{translate("Notifications")}</h1>
      <p>{translate("Here you can manage your notifications")}</p>
      {config.vendor !== "expalerts" ? <MarketingEmailsSettings /> : null}
    </ContentSection>
    <ContentSection>
      <OtherNotificationsSettings />
    </ContentSection>
  </div>
);

NotificationsSettings.propTypes = {};

export default withTranslations(NotificationsSettings);
