import "../styles/default.scss";

import classNames from "classnames";
import React from "react";
import { withRouter } from "react-router";
import { compose } from "redux";

import flagEN from "../../../../../assets/images/flag_en.png";
import flagPL from "../../../../../assets/images/flag_pl.png";
import withDeleteAccount from "../../../../../hocs/withDeleteAccount";
import withTranslations from "../../../../../hocs/withTranslations";
import ContentSection from "../../../common/ContentSection";
import SquaberButton from "../../../common/SquaberButton";
import UserPasswordForm from "../../UserPasswordForm";
import UserProfileForm from "../../UserProfileForm";

const languages = [
  {
    code: "pl",
    icon: flagPL,
    label: "Polish"
  },
  {
    code: "en",
    icon: flagEN,
    label: "English"
  }
];

const PersonalSettings = ({
  translate,
  locale,
  setLocale,
  deleteAccount,
  deletionDatetime
}) => {
  return (
    <div className="personal-settings">
      {!!deletionDatetime ? (
        <ContentSection>
          <h1 className="deletion-started">
            {translate("deletion_account_started_title")}
          </h1>
          <p className="deletion-started deletion-started-paragraph">
            {translate("deletion_account_started_description")}
          </p>
        </ContentSection>
      ) : null}
      <ContentSection>
        <h1>{translate("Account settings")}</h1>
        <p>{translate("Here you can change your account settings")}</p>
        <h2>{translate("Language")}</h2>
        <div className="language-switcher">
          {languages.map((language, key) => (
            <div
              className={classNames({
                "language-element": true,
                active: language.code === locale
              })}
              key={key}
              onClick={() => setLocale(language.code)}
            >
              <img src={language.icon} alt={language.code} />
              <span>{translate(language.label)}</span>
            </div>
          ))}
        </div>
        <UserProfileForm />
      </ContentSection>
      <ContentSection>
        <UserPasswordForm />
      </ContentSection>
      {!deletionDatetime ? (
        <ContentSection>
          <h1>{translate("delete_account_section_title")}</h1>
          <SquaberButton onClick={deleteAccount}>
            {translate("delete_account_section_button")}
          </SquaberButton>
        </ContentSection>
      ) : null}
    </div>
  );
};

export default compose(
  withTranslations,
  withRouter,
  withDeleteAccount
)(PersonalSettings);
