import { isEqual } from "lodash";
import React from "react";

import connect from "../i18n/connect";
import { getMediaMonitorEntries } from "../redux/modules/mediaMonitor";
import {
  getMediaMonitor,
  getMediaMonitorEntriesLoading
} from "../redux/modules/mediaMonitor/selector";
import { getMarketTranslations } from "../redux/modules/translations/selector";
import { getDefaultMarketId } from "../lib/getDefaultMarketId";

export default function withMediaMonitorEntries(
  enableSkipFetchMonitorEntries = false
) {
  return WrappedComponent => {
    const mapStateToProps = state => ({
      mediaMonitor: getMediaMonitor(state),
      mediaMonitorEntriesLoading: getMediaMonitorEntriesLoading(state),
      marketTranslations: getMarketTranslations(state)
    });

    const mapDispatchToProps = {
      fetchMediaMonitor: getMediaMonitorEntries
    };

    @connect(mapStateToProps, mapDispatchToProps)
    class WithMediaMonitorEntries extends React.Component {
      state = {
        skipFetchMonitorEntries: enableSkipFetchMonitorEntries
      };

      componentDidMount(): void {
        let {
          fetchMediaMonitor,
          match,
          ticker,
          market,
          marketTranslations
        } = this.props;

        const preparedMarketId = getDefaultMarketId(
          marketTranslations,
          match?.params?.marketId
        ) || market;

        const { skipFetchMonitorEntries } = this.state;

        if (skipFetchMonitorEntries) {
          return;
        }

        let stockId;

        if (match && match.params) {
          stockId = match.params.stockId;
        }

        if (ticker && market) {
          stockId = ticker;
        }

        fetchMediaMonitor({
          preparedMarketId,
          stockId,
          marketId: preparedMarketId
        });
      }

      componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>): void {
        const { fetchMediaMonitor, match } = this.props;
        const { skipFetchMonitorEntries } = this.state;

        if (!prevProps.match || !match) {
          return;
        }

        if (skipFetchMonitorEntries) {
          return;
        }

        const prevParams = {
          marketId: prevProps.match.params.marketId,
          stockId: prevProps.match.params.stockId,
          skipFetchMonitorEntries: prevState.skipFetchMonitorEntries
        };

        const currentParams = {
          marketId: match.params.marketId,
          stockId: match.params.stockId,
          skipFetchMonitorEntries
        };

        if (!isEqual(prevParams, currentParams)) {
          fetchMediaMonitor(currentParams);
        }
      }

      render() {
        return (
          <WrappedComponent
            {...this.props}
            setSkipFetchMonitorEntries={value =>
              this.setState({ skipFetchMonitorEntries: value })
            }
            skipFetchMonitorEntries={this.state.skipFetchMonitorEntries}
          />
        );
      }
    }

    return WithMediaMonitorEntries;
  };
}
