import "../styles/default.scss";

import moment from "moment-mini";
import PropTypes from "prop-types";
import React from "react";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withActionInProgress from "../../../../../hocs/withActionInProgress";
import withDeviceType from "../../../../../hocs/withDeviceType";
import withPortfolio from "../../../../../hocs/withPortfolio";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import ContentSection from "../../ContentSection";
import Loader from "../../Loader";
import PageTitle from "../../PageTitle";
import PortfolioChart from "../../PortfolioChart";
import PortfolioComponents from "../../PortfolioComponents";
import PortfolioStatistics from "../../PortfolioStatistics";
import PortfolioSummary from "../../PortfolioSummary";
import PortfolioTransactionHistory from "../../PortfolioTransactionHistory";
import SquaberButton from "../../SquaberButton";
import SquaberLink from "../../SquaberLink";

@withPortfolio
@withActionInProgress
@withDeviceType
class Portfolio extends React.Component {
  static propTypes = {
    id: PropTypes.string
  };

  componentWillUnmount(): void {
    this.props.hideActionInProgress();
  }

  render() {
    const {
      translate,
      id: portfolioId,
      dataFromWebSocket,
      portfolio: { data: defaultPortfolioData, loading },
      locale,
      isMobile
    } = this.props;

    if (loading) {
      return (
        <>
          <PageTitle
            title={translate("Loading %{ticker}", {
              ticker: ""
            })}
          />
          <Loader />
        </>
      );
    }

    if (!this.props.portfolio.data) {
      return (
        <>
          <PageTitle
            title={translate("Portfolio", {
              ticker: ""
            })}
          />
          <ContentSection>
            <h1>{translate("Unexpected error occurred")}</h1>
            <p>
              {translate(
                "Your portfolio could not be loaded. Please contact support for more information"
              )}
            </p>
          </ContentSection>
        </>
      );
    }

    const data = {
      ...defaultPortfolioData,
      ...dataFromWebSocket
    };

    return data ? (
      <div className="portfolio">
        <PageTitle title={data.name} />
        {data.errors && data.errors.length ? (
          <div className="portfolio-errors">
            <p>{translate("Your portfolio contains errors")}:</p>
            <ul>
              {data.errors.map((error, index) => (
                <li key={index}>
                  {moment(error.date).format("DD.MM.YYYY")} - {error.message}
                </li>
              ))}
            </ul>
          </div>
        ) : null}
        <div className="portfolio-name">
          <div className="portfolio-name-first-row-wrapper">
            <h1>{data.name}</h1>
            <div className="actions">
              <SquaberButton
                onClick={() => {
                  showModal("PortfolioDetailsModal", {
                    title: translate("Edit portfolio"),
                    portfolioData: data,
                    action: "edit"
                  });
                }}
                className={"edit-portfolio-button"}
                buttonStyle={false}
              >
                {translate("Edit portfolio")}
              </SquaberButton>
              {!isMobile ? (
                <SquaberLink
                  to={UrlProvider.getUrl("fe.desktopPageWithTagId", {
                    locale,
                    tagId: data.automated_stock_tag_id
                  })}
                >
                  {translate("Go to desktop")}
                </SquaberLink>
              ) : null}
            </div>
          </div>
          {data.description ? <p>{data.description}</p> : null}
        </div>
        <PortfolioSummary
          data={data}
          errors={
            typeof data.value === "undefined" || data.value === null
              ? { current_equity: translate("portfolio_value_error") }
              : undefined
          }
        />
        <PortfolioChart
          portfolioId={portfolioId}
          portfolioStatus={data.status}
          hasError={data.errors.length}
        />
        <PortfolioComponents
          components={data.components}
          currency={data.currency}
          portfolioId={portfolioId}
          portfolioData={data}
        />
        <PortfolioStatistics statistics={data.statistics} />
        <PortfolioTransactionHistory portfolioId={portfolioId} />
        {isMobile ? (
          <SquaberLink
            to={UrlProvider.getUrl("fe.desktopPageWithTagId", {
              locale,
              tagId: data.automated_stock_tag_id
            })}
            className="floating-tag-desktop-link"
          >
            <i className="far fa-tag" />
          </SquaberLink>
        ) : null}
      </div>
    ) : null;
  }
}

export default Portfolio;
