const ANALYSIS_SORT_OPTIONS = [
  {
    label: "analysis_sort_option_stock__ticker",
    value: "stock__ticker"
  },
  {
    label: "-analysis_sort_option_stock__ticker",
    value: "-stock__ticker"
  },
  {
    label: "analysis_sort_option_stock__market",
    value: "stock__market"
  },
  {
    label: "-analysis_sort_option_stock__market",
    value: "-stock__market"
  },
  { label: "analysis_sort_option_name_created", value: "created" },
  { label: "-analysis_sort_option_name_created", value: "-created" },
  { label: "analysis_sort_option_updated", value: "updated" },
  { label: "-analysis_sort_option_updated", value: "-updated" }
];

export default ANALYSIS_SORT_OPTIONS;
