import React from "react";
import { compose } from "redux";
import { withRouter } from "react-router";
import withTranslations from "../../../../../hocs/withTranslations";
import { UrlProvider } from "../../../../../api/UrlProvider";
import { getIsMobile } from "../../../../../lib/platformHelper";
import TagsSettingsTagsList from "../../TagsSettingsTagsList";
import SquaberLink from "../../../common/SquaberLink";
import TagsSettingsAddNewTag from "../../TagsSettingsAddNewTag";
import TagsSettingsEditTag from "../../TagsSettingsEditTag";
import ContentSection from "../../../common/ContentSection/modules/default";

import "../styles/default.scss";

class TagsSettings extends React.Component {
  renderMain() {
    const { translate } = this.props;

    return (
      <React.Fragment>
        <h1>{translate("Tags settings panel")}</h1>
        <p>{translate("Here you can manage your tags.")}</p>
        <TagsSettingsTagsList />
      </React.Fragment>
    );
  }

  renderAddNewTag() {
    return (
      <React.Fragment>
        {this.renderReturnToMainPartial()}
        <TagsSettingsAddNewTag />
      </React.Fragment>
    );
  }

  renderEditTag() {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <React.Fragment>
        {this.renderReturnToMainPartial()}
        <TagsSettingsEditTag id={id} />
      </React.Fragment>
    );
  }

  renderReturnToMainPartial() {
    const {
      translate,
      locale,
      match: {
        params: { id }
      }
    } = this.props;

    return (
      <React.Fragment>
        <SquaberLink
          className="return-to-tags-list"
          to={UrlProvider.getUrl("fe.settingsPageCategory", {
            locale,
            settingsCategory: "tags"
          })}
        >
          <i className="fal fa-arrow-left" />
          <span>{translate("Return to tags list")}</span>
        </SquaberLink>
        {!getIsMobile() ? (
          <SquaberLink
            className="return-to-tags-list"
            to={UrlProvider.getUrl("fe.desktopPageWithTagId", {
              locale,
              tagId: id
            })}
          >
            <i className="far fa-tag" />
            <span>{translate("See desktop")}</span>
          </SquaberLink>
        ) : null}
      </React.Fragment>
    );
  }

  render() {
    const {
      match: { path }
    } = this.props;

    return (
      <ContentSection className={"tags-settings"}>
        {(path => {
          switch (path) {
            case "/:locale/settings/:settingsCategory/new":
              return this.renderAddNewTag();

            case "/:locale/settings/:settingsCategory/tag/:id":
              return this.renderEditTag();

            default:
              return this.renderMain();
          }
        })(path)}
      </ContentSection>
    );
  }
}

export default compose(withTranslations, withRouter)(TagsSettings);
