import "../styles/default.scss";

import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withEmailConfirmation from "../../../../../hocs/withEmailConfirmation";
import withNavbarHeight from "../../../../../hocs/withNavbarHeight";
import withUserData from "../../../../../hocs/withUserData";
import useNavigationDynamicPaddingTop from "../../../../hooks/useNavigationDynamicPaddingTop";
import SquaberButton from "../../SquaberButton";

const MobileUserMenu = ({
  userData,
  logout: onLogout,
  setValue,
  translate,
  locale,
  resendConfirmationEmail,
  navbarHeight
}) => {
  const paddingTop = useNavigationDynamicPaddingTop();

  return (
    <div
      className="mobile-menu mobile-user-menu"
      style={{ paddingTop: paddingTop + navbarHeight }}
    >
      <ul>
        {userData && userData.username ? (
          <>
            <li className="mobile-menu-item">
              <Link
                to={UrlProvider.getUrl("fe.settingsPage", {
                  locale
                })}
                onClick={() => {
                  setValue();
                }}
              >
                {translate("Settings")}
              </Link>
            </li>
            <li className="mobile-menu-item">
              <a
                onClick={event => {
                  // Hide user layer
                  setValue("user");

                  event.preventDefault();
                  onLogout();
                }}
              >
                {translate("Logout")}
              </a>
            </li>
          </>
        ) : (
          <>
            <li className="mobile-menu-item">
              <Link
                to={UrlProvider.getUrl("fe.loginWithRedirect", {
                  locale,
                  redirectUrl: window.location.pathname
                })}
                onClick={() => {
                  setValue("user");
                }}
              >
                {translate("Login")}
              </Link>
            </li>
            <li className="mobile-menu-item">
              <Link
                to={UrlProvider.getUrl("fe.registerWithRedirect", {
                  locale,
                  redirectUrl: window.location.pathname
                })}
                onClick={() => {
                  setValue("user");
                }}
              >
                {translate("Register")}
              </Link>
            </li>
          </>
        )}
      </ul>
      {userData && !userData.email_validated ? (
        <div className="email-verification-information">
          <strong>{translate("Warning!")}</strong>{" "}
          {translate(
            "email-confirmation-notification-part-1 %{email_address}",
            {
              email_address: userData.email
            }
          )}{" "}
          <SquaberButton
            buttonStyle={false}
            onClick={() => {
              resendConfirmationEmail();
            }}
          >
            {translate("click here")}
          </SquaberButton>
          {translate("email-confirmation-notification-part-2")}
        </div>
      ) : null}
    </div>
  );
};

MobileUserMenu.propTypes = {
  logout: PropTypes.func,
  setValue: PropTypes.func
};

export default compose(
  withBottomNavigation(),
  withUserData,
  withNavbarHeight,
  withEmailConfirmation
)(MobileUserMenu);
