import React from "react";
import { connect } from "../i18n/connect";
import {
  getFollowedStocks,
  getFollowedStocksLoading,
  getShouldRenderFollowedStocks
} from "../redux/modules/followed/selector";
import { followedStocksRoutine } from "../redux/modules/followed";

export default function withFollowedStocksList(WrappedComponent) {
  const mapStateToProps = state => ({
    followedStocks: getFollowedStocks(state),
    loading: getFollowedStocksLoading(state),
    shouldRenderFollowedStocks: getShouldRenderFollowedStocks(state)
  });

  const mapDispatchToProps = {
    fetchFollowedStocks: followedStocksRoutine.trigger
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithFollowedStocksList extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithFollowedStocksList;
}
