import { takeLatest, fork, put } from "redux-saga/effects";
import { getHomeLandingPageRoutine } from "./index";
import ApiClient from "../../../api/ApiClient";

function* fetchHomeLandingPage() {
  try {
    const { data } = yield ApiClient.get("app.config.homeLandingPage");

    yield put(getHomeLandingPageRoutine.success(data));
  } catch (e) {
    yield put(getHomeLandingPageRoutine.failure(e));
  }
}

function* watchFetchHomeLandingPage() {
  yield takeLatest(getHomeLandingPageRoutine.TRIGGER, fetchHomeLandingPage);
}

export default [fork(watchFetchHomeLandingPage)];
