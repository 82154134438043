import { throttle } from "lodash";
import { useEffect, useState } from "react";

export const commonSizes = {
  MOBILE: 992
};

function getWindowSize() {
  const { innerWidth: width, innerHeight: height } = window ?? {
    innerWidth: null,
    innerHeight: null
  };
  return {
    width,
    height
  };
}

function useWindowSize() {
  const [size, setSize] = useState({ width: null, height: null });

  useEffect(() => {
    const handleResize = throttle(() => {
      setSize(getWindowSize());
    }, 500);

    setSize(getWindowSize());

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return size;
}

export default useWindowSize;
