import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { compose } from "redux";
import withToggleAlert from "../../../../../hocs/withToggleAlert";
import withUserData from "../../../../../hocs/withUserData";
import withStockIsFollowed from "../../../../../hocs/withStockIsFollowed";
import YellowSwitch from "../../../../../lib/SquaberThemeComponents/YellowSwitch";

import "../styles/default.scss";

const StockAlertStatus = ({
  stockIsFollowed: alerted,
  toggleAlert,
  stockId,
  sliderOnMobile,
  ticker,
  market
}) => (
  <div
    className={classNames({
      "stock-alert-status": true,
      "slider-on-mobile": sliderOnMobile
    })}
  >
    <div
      className={classNames({
        "alert-button": true,
        active: alerted
      })}
      onClick={() => {
        toggleAlert({ stockId, ticker, market });
      }}
    >
      <i className="fa fa-bell" />
    </div>
    <div
      className="alert-switcher-heading"
      onClick={() => {
        toggleAlert({ stockId, ticker, market });
      }}
    >
      <span>Alert</span> <i className="far fa-bell" />
    </div>
    <div className="alert-simple-switcher">
      <YellowSwitch
        checked={alerted || false}
        onChange={() => {
          toggleAlert({ stockId, ticker, market });
        }}
        value="alert_stock"
        color="primary"
      />
    </div>
  </div>
);

StockAlertStatus.propTypes = {
  stockId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  sliderOnMobile: PropTypes.bool
};

StockAlertStatus.defaultProps = {
  sliderOnMobile: false
};

export default compose(
  withToggleAlert,
  withUserData,
  withStockIsFollowed(props => props.stockId)
)(StockAlertStatus);
