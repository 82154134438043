import React from "react";
import connect from "../i18n/connect";
import {
  fetchMoreStockRecommendations,
  fetchStockRecommendations
} from "../redux/modules/stockRecommendations";
import {
  getHasMoreStockRecommendations,
  getMoreStockRecommendationsLoading,
  getStockRecommendations,
  getStockRecommendationsLoading
} from "../redux/modules/stockRecommendations/selector";

export default function withStockRecommendations(WrappedComponent) {
  const mapStateToProps = state => ({
    recommendations: getStockRecommendations(state),
    recommendationsLoading: getStockRecommendationsLoading(state),
    hasMoreStockRecommendations: getHasMoreStockRecommendations(state),
    moreRecommendationsLoading: getMoreStockRecommendationsLoading(state)
  });

  const mapDispatchToProps = {
    fetchStockRecommendations,
    fetchMoreStockRecommendations
  };
  @connect(mapStateToProps, mapDispatchToProps)
  class WithStockRecommendations extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithStockRecommendations;
}
