import "../styles/default.scss";

import classNames from "classnames";
import moment from "moment-mini";
import React from "react";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withAnalysisCategories from "../../../../../hocs/withAnalysisCategories";
import withHistoryListItemAccessHandler from "../../../../../hocs/withHistoryListItemAccessHandler";
import withUserData from "../../../../../hocs/withUserData";
import useMobileMediaQuery from "../../../../hooks/useMobileMediaQuery";
import ContentSection from "../../ContentSection";
import SimpleAlertButton from "../../SimpleAlertButton/modules/default";
import SquaberButton from "../../SquaberButton";
import SquaberLink from "../../SquaberLink";
import SquaberTooltip from "../../SquaberTooltip";

const SquaberCommentsRowItem = ({
  translate,
  stock: { ticker, market, name: stock_name, id: stockId },
  author,
  active,
  created,
  updated,
  handleDescriptionButtonClick,
  locale,
  id,
  category,
  content,
  analysisCategories,
  userHasAccess
}) => {
  const isMobile = useMobileMediaQuery();
  const commentCategory = analysisCategories.find(cat => cat.id === category);

  const limitedAccess = active && content === null;
  const shouldShowContent = !limitedAccess || userHasAccess;

  return (
    <ContentSection className="squaber-comments-row-item">
      <SimpleAlertButton stockId={stockId} />
      <SquaberLink
        to={UrlProvider.getUrl("fe.stockPage", {
          locale,
          marketId: market,
          stockId: ticker
        })}
        className="squaber-comments-row-item__ticker-wrapper"
      >
        <SquaberTooltip title={ticker}>
          <h2 className="squaber-comments-row-item__ticker">{ticker}</h2>
        </SquaberTooltip>
        <span className="squaber-comments-row-item__market">{market}</span>
      </SquaberLink>

      <SquaberTooltip title={stock_name}>
        <p className="squaber-comments-row-item__stock-name">{stock_name}</p>
      </SquaberTooltip>
      <SquaberTooltip title={commentCategory?.name ?? ""}>
        <p className="squaber-comments-row-item__secondary-text squaber-comments-row-item__ellipsis">
          {commentCategory?.name}
        </p>
      </SquaberTooltip>
      <div className="squaber-comments-row-item__secondary-text">
        {isMobile ? null : `${translate("Created on")}: `}
        {moment(created).format("DD.MM.YYYY, HH:mm")}
        {updated !== created ? (
          <SquaberTooltip
            title={`${translate("Updated on")}: ${moment(updated).format(
              "DD.MM.YYYY HH:mm"
            )}`}
          >
            <span>
              {" "}
              <i className="fal fa-info-circle" />
            </span>
          </SquaberTooltip>
        ) : null}
      </div>
      <div className="squaber-comments-row-item__secondary-text">
        {isMobile ? " " : `${translate("Status")} `}
        <span
          className={classNames({
            "squaber-comments-row-item__signal_active": active,
            "squaber-comments-row-item__signal_inactive": !active
          })}
        >
          {translate(`signal_status_${active ? "active" : "inactive"}`)}
        </span>
      </div>
      {author.first_name && author.last_name ? (
        <SquaberTooltip
          title={`${translate("Author")}: ${author.first_name} ${
            author.last_name
          }`}
        >
          <p className="squaber-comments-row-item__secondary-text squaber-comments-row-item__author">
            {isMobile ? "" : `${translate("Author")}: `}
            {author.first_name}&nbsp;{author.last_name}
          </p>
        </SquaberTooltip>
      ) : (
        <span />
      )}
      <div className={"squaber-comments-row-item__buttons-wrapper"}>
        {isMobile && !shouldShowContent ? (
          <SquaberTooltip
            onClick={e => e.preventDefault()}
            title={
              "This is the content available in the premium Squaber version. Use one of our bundles to get access to it"
            }
          >
            <span>
              <i className="fal fa-info-circle" />
            </span>
          </SquaberTooltip>
        ) : null}
        <SquaberTooltip
          title={
            !shouldShowContent
              ? "This is the content available in the premium Squaber version. Use one of our bundles to get access to it"
              : ""
          }
        >
          <SquaberLink
            to={
              shouldShowContent
                ? UrlProvider.getUrl("fe.singleAnalysisPage", {
                    locale,
                    id
                  })
                : "#!"
            }
            onClick={event => {
              handleDescriptionButtonClick(hasAccess => {
                if (!hasAccess) {
                  event.preventDefault();
                }
              }, limitedAccess);
            }}
          >
            <SquaberButton color="primary_small" disabled={!shouldShowContent}>
              {translate("Description")}
            </SquaberButton>
          </SquaberLink>
        </SquaberTooltip>
      </div>
    </ContentSection>
  );
};

export default compose(
  withHistoryListItemAccessHandler,
  withUserData,
  withAnalysisCategories
)(SquaberCommentsRowItem);
