import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";

export const getSpecificAlertManagementData = createRoutine(
  "app/alertManagement/GET_SPECIFIC_DATA"
);
export const getGlobalAlertManagementData = createRoutine(
  "app/alertManagement/GET_GLOBAL_DATA"
);
export const updateAlertManagementData = createRoutine(
  "app/alertManagement/UPDATE_DATA"
);

const initialState = {
  loading: {
    global: false,
    specific: false
  },
  data: null
};

export default handleActions(
  {
    [getSpecificAlertManagementData.TRIGGER]: state => ({
      ...state,
      loading: {
        ...state.loading,
        specific: true
      }
    }),
    [getSpecificAlertManagementData.FAILURE]: state => ({
      ...state,
      loading: {
        ...state.loading,
        specific: false
      }
    }),
    [getSpecificAlertManagementData.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: {
        ...state.loading,
        specific: false
      },
      data: {
        ...state.data,
        specific: payload.subscriptions
      }
    }),
    [getGlobalAlertManagementData.TRIGGER]: state => ({
      ...state,
      loading: {
        ...state.loading,
        global: true
      }
    }),
    [getGlobalAlertManagementData.FAILURE]: state => ({
      ...state,
      loading: {
        ...state.loading,
        global: false
      }
    }),
    [getGlobalAlertManagementData.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: {
        ...state.loading,
        global: false
      },
      data: {
        ...state.data,
        global: payload.subscriptions
      }
    }),
    [updateAlertManagementData.TRIGGER]: (state, { payload }) => {
      let payloadCopy = { ...payload };
      payloadCopy.type = payloadCopy.category;

      const newSubscriptions = payload.stockId
        ? [...state.data.specific]
        : [...state.data.global];

      const elementToChange = newSubscriptions.findIndex(
        element =>
          element.channel === payloadCopy.channel &&
          element.type === payloadCopy.type
      );

      delete payloadCopy.category;

      if (elementToChange !== -1) {
        newSubscriptions[elementToChange].status = payloadCopy.status;
      } else {
        newSubscriptions.push(payloadCopy);
      }

      let newData = {
        ...state.data
      };

      if (payload.stockId) {
        newData.specific = newSubscriptions;
      } else {
        newData.global = newSubscriptions;
      }

      return {
        ...state,
        data: newData
      };
    }
  },
  initialState
);
