import React from "react";
import * as autoBind from "auto-bind";
import withUserData from "./withUserData";
import getAccessLevel from "../lib/access/getAccessLevel";
import handleAccess from "../lib/access/handleAccess";
import accessLevelsMap from "../lib/access/accessLevelsMap";

export default function withHistoryListItemAccessHandler(WrappedComponent) {
  @withUserData
  class WithHistoryListItemAccessHandler extends React.Component {
    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    handleDescriptionButtonClick(callback, shouldCheckAccess) {
      const accessLevel = this.getAccessLevel();

      if (shouldCheckAccess) {
        handleAccess(accessLevel);

        callback(accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS);
      } else {
        callback(true);
      }
    }

    getAccessLevel() {
      const { userData } = this.props;

      return getAccessLevel(userData, {});
    }

    render() {
      const accessLevel = this.getAccessLevel();

      return (
        <WrappedComponent
          {...this.props}
          accessLevel={accessLevel}
          handleDescriptionButtonClick={this.handleDescriptionButtonClick}
        />
      );
    }
  }

  return WithHistoryListItemAccessHandler;
}
