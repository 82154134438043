import "../styles/default.scss";

import React from "react";

import FollowedStocksList from "../../FollowedStocksList";
import classNames from "classnames";

const MobileFollowedStocksList = ({ canDisplayVerifyEmailBar }) => {
  return (
    <div
      className={classNames(
        "followed-stocks-mobile",
        canDisplayVerifyEmailBar
          ? "followed-stocks-mobile_emailVerification"
          : null
      )}
    >
      <FollowedStocksList isMobile />
    </div>
  );
};

export default MobileFollowedStocksList;
