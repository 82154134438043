import classNames from "classnames";
import { isFunction, isString } from "lodash";
import React, { useCallback } from "react";
import { compose } from "redux";

import withTranslations from "../../../../../../hocs/withTranslations";
import BaseLink from "../BaseLink";

function MobileNavigationLink({
  label,
  href,
  children,
  onClick,
  active,
  translate
}) {
  const hasHref = isString(href) && !["#", ""].includes(href);

  const handleOnClick = useCallback(async event => {
    if (!isFunction(onClick)) return;

    await onClick(event);
  }, []);

  const Label = useCallback(() => {
    const hasChildren = !!children;

    if (!hasChildren) return translate(label);

    return (
      <>
        <span>{translate(label)}</span>
        <i
          onClick={async event => {
            event.preventDefault();
            event.stopPropagation();

            await handleOnClick(event);
          }}
          className="far fa-chevron-right"
        />
      </>
    );
  }, [children, label, active]);

  return (
    <BaseLink
      href={hasHref ? href : undefined}
      onClick={hasHref ? undefined : handleOnClick}
      label={<Label />}
      children={children}
      className={classNames({
        "mobile-navigation__link": true,
        "mobile-navigation__link_active": active
      })}
    />
  );
}

export default compose(withTranslations)(MobileNavigationLink);
