import autoBind from "auto-bind";
import moment from "moment-mini";
import React from "react";

import { connect } from "../i18n/connect";
import { isNumberWithMaximumTwoDecimalDigits } from "../lib/customValidators";
import detectDateFormat from "../lib/detectDateFormat";
import { getIsWeb } from "../lib/platformHelper";
import {
  addPortfolioCashTransactionRoutine,
  clearAddCashTransactionValidationError
} from "../redux/modules/portfolios";
import { getAddPortfolioCashTransactionState } from "../redux/modules/portfolios/selector";
import withFormBuilder from "./withFormBuilder";
import withPortfolioTransactionHistoryTable from "./withPortfolioTransactionHistoryTable";

export default function withPortfolioAddCashTransaction(WrappedComponent) {
  const mapStateToProps = state => ({
    addPortfolioCashTransactionState: getAddPortfolioCashTransactionState(state)
  });
  const mapDispatchToProps = {
    addPortfolioCashTransaction: addPortfolioCashTransactionRoutine,
    clearValidationError: clearAddCashTransactionValidationError
  };

  @connect(mapStateToProps, mapDispatchToProps)
  @withPortfolioTransactionHistoryTable
  @withFormBuilder(props => [
    {
      name: "type",
      label: "Transaction type",
      type: getIsWeb() ? "select" : "radio",
      value: "1",
      options: Object.keys(props.transactionTypes.cash).map(key => ({
        label: props.transactionTypes.cash[key],
        value: String(key)
      }))
    },
    {
      name: "date",
      label: "Date",
      type: "date",
      value: moment().format("DD.MM.YYYY"),
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "amount",
      label: "Amount",
      type: "text",
      value: "",
      required: true,
      validations: {
        isNumberWithMaximumTwoDecimalDigits
      },
      validationErrors: {
        isDefaultRequiredValue: "This field is required",
        isNumberWithMaximumTwoDecimalDigits:
          "You can use maximum two digits after decimal pointer"
      },
      inputMode: "numeric"
    }
  ])
  class WithPortfolioAddStockTransaction extends React.Component {
    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    addTransaction(data, hideForm) {
      const { portfolioId, setShowAddCashTransaction } = this.props;
      let requestData = {};

      if (!hideForm) {
        hideForm = function() {
          setShowAddCashTransaction(false);
        };
      }

      data.formBuilder.fields.forEach(field => {
        if (field.name === "date") {
          requestData[field.name] = moment(
            field.value,
            detectDateFormat(field.value)
          ).format("YYYY-MM-DD");
        } else {
          requestData[field.name] = field.value;
        }
      });

      this.props.addPortfolioCashTransaction({
        ...requestData,
        portfolioId,
        hideForm
      });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          addTransaction={this.addTransaction}
        />
      );
    }
  }

  return WithPortfolioAddStockTransaction;
}
