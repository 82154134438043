import React from "react";
import { connect } from "../i18n/connect";
import { actions } from "../redux/modules/auth/login";

export default function withSocialLogin(WrappedComponent) {
  const mapDispatchToProps = {
    initSocialLogin: actions.socialLogin,
    executeSocialLogin: actions.executeSocialLogin,
    executeSocialLoginV2: actions.executeSocialLoginV2
  };

  @connect(null, mapDispatchToProps)
  class WithSocialLogin extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithSocialLogin;
}
