import React from "react";
import { compose } from "redux";
import PropTypes from "prop-types";
import classNames from "classnames";
import withFollowUnfollow from "../../../../../hocs/withFollowUnfollow";
import withStockIsFollowed from "../../../../../hocs/withStockIsFollowed";

import "../styles/default.scss";

const SimpleAlertButton = ({
  stockIsFollowed,
  follow,
  unFollow,
  stockId,
  className,
  ticker,
  market
}) => (
  <div
    className={classNames({
      "simple-alert-button": true,
      [className]: !!className,
      "is-alerted": stockIsFollowed
    })}
  >
    <i
      className="fas fa-bell"
      onClick={() => {
        if (stockIsFollowed) {
          unFollow({
            stockId: stockId,
            isUnfollowing: true,
            ticker,
            market
          });
        } else {
          follow({ stockId: stockId, type: "single_stock", ticker, market });
        }
      }}
    />
  </div>
);

SimpleAlertButton.propTypes = {
  stockId: PropTypes.number.isRequired,
  stockIsFollowed: PropTypes.bool,
  className: PropTypes.string
};

export default compose(
  withFollowUnfollow,
  withStockIsFollowed(props => props.stockId)
)(SimpleAlertButton);
