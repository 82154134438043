import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import AlwaysScrollableDialogContent from "../../AlwaysScrollableDialogContent";
import withTranslations from "../../../../../hocs/withTranslations";
import ModalBase from "../../ModalBase";
import SquaberButton from "../../../common/SquaberButton";

import "../styles/default.scss";

@withTranslations
class IntroductionModal extends ModalBase {
  getClassName(): null {
    return "introduction-modal is-mobile-page-sheet";
  }

  getContent() {
    const { hideModal, translate } = this.props;

    return (
      <React.Fragment>
        <DialogTitle id="interesting-stock-dialog-title">
          {translate("introduction-modal-title")}
          <span className="close-dialog" onClick={() => hideModal()}>
            <i className="fal fa-times" />
          </span>
        </DialogTitle>
        <AlwaysScrollableDialogContent>
          <div className="iframe-wrapper">
            <iframe
              src={translate("introduction-modal-video")}
              width="100%"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          <div
            className="text-block"
            dangerouslySetInnerHTML={{
              __html: translate("introduction-modal-content")
            }}
          />
          <div className="button-wrapper">
            <SquaberButton fullWidth onClick={() => hideModal()}>
              {translate("Continue")}
            </SquaberButton>
          </div>
        </AlwaysScrollableDialogContent>
      </React.Fragment>
    );
  }
}

export default IntroductionModal;
