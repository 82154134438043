import React from "react";
import classNames from "classnames";
import { compose } from "redux";
import withMarketingBarsSeen from "../../../../../hocs/withMarketingBarsSeen";
import withUserData from "../../../../../hocs/withUserData";
import getAccessLevel from "../../../../../lib/access/getAccessLevel";
import accessLevelsMap from "../../../../../lib/access/accessLevelsMap";
import PageHelper from "../../../../../lib/PageHelper";
import Icon from "../components/icon";

import "../styles/default.scss";

const MARKETING_BAR_ID = "FLOATING_TEXT";

const FloatingInformation = ({
  translate,
  marketingBarsSeen,
  setMarketingBarSeen,
  unsetMarketingBarSeen,
  userData
}) => {
  const hidden = marketingBarsSeen.indexOf(MARKETING_BAR_ID) !== -1;
  const accessLevel = getAccessLevel(userData);
  const userHasAccess = accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS;

  const isLandingPage = PageHelper.getIsLandingPage(location);

  if (!userHasAccess || isLandingPage) {
    return null;
  }

  return (
    <div
      className={classNames({
        "floating-information": true,
        hidden
      })}
    >
      <div
        className="description"
        dangerouslySetInnerHTML={{
          __html: translate("floating_info_description_html")
        }}
      />
      <div
        className="icon-wrapper"
        onClick={() => {
          if (hidden) {
            unsetMarketingBarSeen(MARKETING_BAR_ID);
          } else {
            setMarketingBarSeen(MARKETING_BAR_ID);
          }
        }}
      >
        <Icon />
        <span className="fold">
          {translate("fold")} <i className="far fa-angle-up" />
        </span>
      </div>
    </div>
  );
};

FloatingInformation.propTypes = {};

export default compose(
  withMarketingBarsSeen,
  withUserData
)(FloatingInformation);
