import React from "react";
import ModalBase from "../../ModalBase";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogActions from "@material-ui/core/DialogActions";
import SquaberButton from "../../../common/SquaberButton";
import { UrlProvider } from "../../../../../api/UrlProvider";
import AnalyticsEventTracker from "../../../../../lib/AnalyticsEventTracker";

import "../styles/default.scss";

class RegisterModal extends ModalBase {
  getClassName() {
    return "register-modal";
  }

  componentDidMount() {
    let { trigger } = this.props;

    if (!trigger) {
      trigger = "user_action";
    }

    AnalyticsEventTracker.trackRegistrationModalEvent(trigger);
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ): void {
    const { hideModal } = this.props;
    if (!prevProps.userData && this.props.userData) {
      hideModal();
    }
  }

  getTitle() {
    return "Register";
  }

  getRegisterForm() {
    return null;
  }

  getRegisterButton() {
    return null;
  }

  getContent() {
    const { translate, hideModal, locale, history } = this.props;

    return (
      <React.Fragment>
        <DialogTitle id="form-dialog-title">
          {translate(this.getTitle())}
          <span className="close-dialog" onClick={() => hideModal()}>
            <i className="fal fa-times" />
          </span>
        </DialogTitle>
        {this.getRegisterForm()}
        <DialogActions>
          <SquaberButton
            color="secondary"
            onClick={() => {
              hideModal();
              history.push(
                UrlProvider.getUrl("fe.loginWithRedirect", {
                  locale,
                  redirectUrl: window.location.pathname
                })
              );
            }}
          >
            {translate("Log in")}
          </SquaberButton>
          {this.getRegisterButton()}
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default RegisterModal;
