import config from "../../config/index";
import type { AccessLevel } from "./accessLevelsMap";
import accessLevelsMap from "./accessLevelsMap";

type UserStockType = {
  active: boolean,
  alerted: boolean
};

// TODO: userStock is deprecated and will be deleted
export default function getAccessLevel(
  userData,
  userStock?: UserStockType
): AccessLevel {
  const { vendor } = config;

  if (vendor === "squaber") {
    if (!userData) {
      return accessLevelsMap.NOT_LOGGED_IN;
    } else if (userData.has_access) {
      return accessLevelsMap.HAS_PREMIUM_ACCESS;
    } else {
      return accessLevelsMap.NO_PREMIUM_ACCESS;
    }
  } else {
    if (!userData) {
      return accessLevelsMap.NOT_LOGGED_IN;
    } else if (
      (userStock.active && !userStock.alerted) ||
      (!userStock.active && !userStock.alerted)
    ) {
      return accessLevelsMap.NO_PREMIUM_ACCESS;
    } else if (!userStock.active && userStock.alerted) {
      return accessLevelsMap.PREMIUM_ACCESS_EXPIRED;
    } else {
      return accessLevelsMap.HAS_PREMIUM_ACCESS;
    }
  }
}
