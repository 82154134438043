import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

interface ChangeAlertSettingsMetaData {
  type: string;
  channel: string;
  status: boolean;
  stock_id: string;
}

class ChangeAlertSettingsEvent extends BaseEvent {
  constructor(changeAlertSettings: ChangeAlertSettingsMetaData) {
    super();
    this.changeAlertSettings = changeAlertSettings;
  }

  getEventName() {
    return "change_alerts_setting";
  }

  getEventTriggerName() {
    return "Alert setting changed";
  }

  getEventDetailsMatrix() {
    return `detail5=alert type name
detail6=on/off`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: this.changeAlertSettings.type,
      detail6: this.changeAlertSettings.status ? "on" : "off"
    };
  }
}
export default ChangeAlertSettingsEvent;
