import Autolinker from "autolinker";
import { isString } from "lodash";

import { getIsWeb } from "../platformHelper";

class MediaMonitorHelper {
  constructor(content, provider, translate, attachments, title) {
    this.content = content;
    this.provider = provider;
    this.translate = translate;
    this.attachments = attachments;
    this.title = title;
  }

  removeEmptyParagraphs() {
    this.content = this.content.replace(/<p><\/p>/gim, "");

    return this;
  }

  parseLinks() {
    this.content = Autolinker.link(this.content, {
      stripPrefix: false
    });

    return this;
  }

  removeSpacesBeforeDot() {
    this.content = this.content.replace(/ \./gim, ".");

    return this;
  }

  addProviderInformation() {
    if (!isString(this.provider)) return this;

    this.content += `<p>${this.translate(
      "This telegram comes from full version of %{provider}",
      {
        provider: this.provider
      }
    )}</p>`;

    return this;
  }

  addTitle() {
    if (!!this.title) {
      const headingTagName = this.getHeadingTagName();
      this.content =
        `<${headingTagName}>${this.title}</${headingTagName}>` + this.content;
    }

    return this;
  }

  addAttachmentsLinks() {
    if (!this.attachments || !this.attachments.length) {
      return this;
    }

    const headingTagName = this.getHeadingTagName();

    this.content += `<${headingTagName}>${this.translate(
      "Attachments"
    )}</${headingTagName}>`;
    this.content += "<ul class='attachments'>";
    this.content += this.attachments
      .map(
        attachment =>
          `<li><a href="${
            attachment.url
          }" target="_blank" rel="noopener">${attachment.filename ||
            attachment.url}</a></li>`
      )
      .join("");

    this.content += "</ul>";
    return this;
  }

  formatMediaMonitorEntry() {
    return this.removeEmptyParagraphs()
      .removeSpacesBeforeDot()
      .parseLinks()
      .addTitle()
      .addProviderInformation()
      .addAttachmentsLinks()
      .toString();
  }

  getHeadingTagName() {
    return getIsWeb() ? "h5" : "h3";
  }

  toString() {
    return this.content;
  }
}

export default MediaMonitorHelper;
