import React from "react";
import classNames from "classnames";

import "../styles/StockInfo.scss";

const getClassName = type => {
  switch (type) {
    case "simple":
      return "stock-info--simple";
    default:
      return "";
  }
};

function StockInfo({ type, ticker, market, name }) {
  return (
    <div className={classNames("stock-info", getClassName(type))}>
      <b className="ticker-superscript">
        <span className="ticker">{ticker}</span>
        {market ? <span className="superscript">{market}</span> : null}
      </b>
      {name ? <span className="text">{name}</span> : null}
    </div>
  );
}

export default StockInfo;
