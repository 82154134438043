import React, { useState } from "react";
import Formsy from "formsy-react";
import { compose } from "redux";
import { withRouter } from "react-router";
import { UrlProvider } from "../../../../../api/UrlProvider";
import withPortfoliosList from "../../../../../hocs/withPortfoliosList";
import FormsySelect from "../../../../../lib/FormBuilder/FormsyMaterialUI/FormsySelect";
import SquaberButton from "../../SquaberButton";
import HistoryWithStockAddPortfolio from "../../HistoryWithStockAddPortfolio";
import envVariables from "../../../../../lib/envHelpers/getEnvVariables";

import "../styles/default.scss";

const HistoryWithStockAddToPortfolio = ({
  translate,
  locale,
  portfoliosListState,
  history,
  currency,
  stock,
  quote,
  showDescription
}) => {
  if (portfoliosListState.loading || !portfoliosListState.data) {
    return <i className="fal fa-spinner fa-spin" />;
  }

  if (portfoliosListState.data.portfolios.length) {
    const portfoliosToUse = portfoliosListState.data.portfolios.filter(
      portfolio => portfolio.currency === currency
    );

    // TODO: Show info about ability to add portfolio with different currency when proper
    // back-end implementation will be available
    if (!portfoliosToUse.length && envVariables.NODE_ENV === "development") {
      return <HistoryWithStockAddPortfolio forSpecificCurrency />;
    } else if (
      !portfoliosToUse.length &&
      envVariables.NODE_ENV === "production"
    ) {
      return (
        <p style={{ marginBottom: 0 }}>
          {translate("You can't add this stock to portfolio")}
        </p>
      );
    }

    const [currentPortfolio, setCurrentPortfolio] = useState(
      portfoliosToUse[0].id
    );

    return (
      <div className="history-with-stock-add-to-portfolio">
        {showDescription ? (
          <p className="description">
            <span>
              {translate(
                "This stock doesn't belong to any of your portfolios - add it!"
              )}
            </span>
            <span>
              {translate(
                "Remember, you can only add stocks to portfolios with currency matching stock's currency."
              )}
            </span>
          </p>
        ) : null}
        <Formsy>
          <div className="portfolio-selector">
            <FormsySelect
              name="portfolio"
              label=" "
              options={portfoliosListState.data.portfolios.map(portfolio => ({
                label: portfolio.name,
                value: portfolio.id
              }))}
              value={currentPortfolio}
              onChange={value => {
                setCurrentPortfolio(value);
              }}
              showInitialValue={false}
            />
            <SquaberButton
              onClick={() => {
                localStorage.setItem(
                  "stockToAddToPortfolio",
                  JSON.stringify({
                    ...stock,
                    quote
                  })
                );

                history.push(
                  UrlProvider.getUrl("fe.portfolio", {
                    locale,
                    id: currentPortfolio
                  })
                );
              }}
            >
              {translate("Add to portfolio")}
            </SquaberButton>
          </div>
        </Formsy>
      </div>
    );
  }

  return null;
};

HistoryWithStockAddToPortfolio.propTypes = {};

export default compose(
  withRouter,
  withPortfoliosList
)(HistoryWithStockAddToPortfolio);
