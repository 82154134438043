import { UrlProvider } from "../../../../api/UrlProvider";
import getStockPageSectionFromNotificationType, {
  NOTIFICATION_TYPES
} from "../../../../lib/getStockPageSectionFromNotificationType";
import { showModal } from "../../../../lib/react-redux-modal-provider";

const getUrl = ({ urlPath, urlParams }) => {
  return { url: UrlProvider.getUrl(urlPath, urlParams) };
};

export const notificationItemFactory = ({
  item,
  analysisCategories,
  manualStrategies,
  locale
}): { url?: string, action?: Promise<void>, redirectTo?: string } => {
  const notificationType = item.notification_type;

  switch (notificationType) {
    case NOTIFICATION_TYPES.SQUABER_TEAM_COMMENT: {
      return getUrl({
        urlPath: "fe.singleAnalysisPage",
        urlParams: {
          locale,
          id: item.config.related_object_id
        }
      });
    }
    case NOTIFICATION_TYPES.DEMAND_ZONE: {
      return getUrl({
        urlPath: "fe.stockPage",
        urlParams: {
          locale,
          marketId: item.config.market,
          stockId: item.config.ticker
        }
      });
    }
    case NOTIFICATION_TYPES.RECOMMENDATIONS:
    case NOTIFICATION_TYPES.PRICE_ALERTS:
    case NOTIFICATION_TYPES.MEDIA_MONITOR: {
      const notificationStockPageSection = item.notification_type
        ? getStockPageSectionFromNotificationType(item.notification_type)
        : null;

      const urlPath = notificationStockPageSection
        ? "fe.stockPageSection"
        : "fe.stockPage";

      const urlParams = {
        locale,
        marketId: item.config.market,
        stockId: item.config.ticker
      };

      if (notificationStockPageSection) {
        urlParams.section = notificationStockPageSection;
      }

      return getUrl({
        urlPath,
        urlParams
      });
    }
    default: {
      const redirectTo = item?.config?.redirect_to;

      if (redirectTo) {
        return { redirectTo };
      }

      const isHtmlMessage = item?.config?.html_message;

      if (isHtmlMessage) {
        return {
          action: () =>
            showModal("InterestingStockDescription", {
              title: item.shortcut,
              content: item.config.html_message
            })
        };
      }

      const isAnalysisCategoryNotification =
        analysisCategories?.findIndex?.(
          ({ notifications_unique_id }) =>
            notifications_unique_id === notificationType
        ) > -1;

      if (isAnalysisCategoryNotification) {
        return getUrl({
          urlPath: "fe.singleAnalysisPage",
          urlParams: {
            locale,
            id: item.config.related_object_id
          }
        });
      }

      const isManualStrategiesNotification =
        manualStrategies?.data?.findIndex?.(
          ({ key }) => key === notificationType
        ) > -1;

      if (isManualStrategiesNotification) {
        return getUrl({
          urlPath: "fe.manualStrategyPage",
          urlParams: {
            locale,
            id: item.config.related_object_id
          }
        });
      }

      // Unhandled notifications
      console.warn(`Unhandled ${notificationType}`);
      return;
    }
  }
};
