import React from "react";
import classNames from "classnames";
import UniversalTableContext from "../../../../../lib/UniversalTable/UniversalTableContext";
import ApiHelper from "../../../../../lib/UniversalTable/CoreHelpers/ApiHelper";
import withDeviceType from "../../../../../hocs/withDeviceType";

import "../rendererStyles/Heading.scss";

@withDeviceType
class Heading extends React.Component {
  static contextType = UniversalTableContext;

  state = {
    searchVisible: false,
    searchValue: ""
  };

  setSearchValue(value) {
    this.setState({
      searchValue: value
    });
  }

  showSearch() {
    this.initSearchValue();

    this.setState({
      searchVisible: true
    });
  }

  hideSearch() {
    this.setState({
      searchVisible: false
    });
  }

  applySearch() {
    const { columnMetaData } = this.props;
    const { searchValue } = this.state;
    const { search, setSearch } = this.context;

    const attributeForFiltering = ApiHelper.getColumnAttributeForFiltering(
      columnMetaData
    );

    delete search[attributeForFiltering];

    if (searchValue && searchValue.length) {
      search[attributeForFiltering] = searchValue;
    }

    setSearch(search);
    this.hideSearch();
  }

  initSearchValue() {
    const { columnMetaData } = this.props;
    const { search } = this.context;

    const attributeForFiltering = ApiHelper.getColumnAttributeForFiltering(
      columnMetaData
    );

    const searchValueFromTableState = search[attributeForFiltering] || "";

    this.setState({
      searchValue: searchValueFromTableState
    });
  }

  render() {
    const { value, isMobile, columnMetaData } = this.props;
    const { searchVisible, searchValue } = this.state;
    const TableState = this.context;

    const attributeForSorting = ApiHelper.getColumnAttributeForSorting(
      columnMetaData
    );
    const attributeForFiltering = ApiHelper.getColumnAttributeForFiltering(
      columnMetaData
    );

    const { sortBy } = TableState;

    const isSortedBy = sortBy.field === attributeForSorting;
    const sortDirection = sortBy.direction;

    const sortable = ApiHelper.getIsSortable(columnMetaData);
    const searchable = ApiHelper.getIsSearchable(columnMetaData);

    return (
      <div className="heading-renderer">
        {searchVisible ? (
          <div className="heading-renderer-search">
            <label htmlFor={`search_${attributeForFiltering}`}>{value}</label>
            <div className="input-wrapper">
              <input
                type="text"
                id={`search_${attributeForFiltering}`}
                autoFocus
                value={searchValue}
                onChange={event => {
                  this.setSearchValue(event.target.value);
                }}
                onKeyDown={event => {
                  if (event.which === 13) {
                    this.applySearch();
                  }
                }}
              />
              <i
                className="fal fa-check apply-search"
                onClick={() => {
                  this.applySearch();
                }}
              />
              <i
                className="fal fa-times"
                onClick={() => {
                  this.hideSearch();
                }}
              />
            </div>
          </div>
        ) : (
          <div
            className={classNames({
              "heading-renderer-default": true,
              sortable
            })}
            onClick={() => {
              if (isMobile || !sortable) {
                return;
              }

              const shouldSort = !isSortedBy || sortDirection === "desc";
              const finalDirection = !isSortedBy ? "desc" : "asc";

              TableState.setSorting({
                field: shouldSort ? attributeForSorting : null,
                direction: finalDirection
              });
            }}
          >
            <span>{value}</span>
            {isSortedBy ? (
              <i
                className={classNames({
                  far: true,
                  "fa-long-arrow-down": sortDirection === "desc",
                  "fa-long-arrow-up": sortDirection === "asc"
                })}
              />
            ) : null}
            {!isMobile && searchable ? (
              <span
                className="show-search-input"
                onClick={event => {
                  event.stopPropagation();
                  this.showSearch();
                }}
              >
                <i className="fal fa-search" />
              </span>
            ) : null}
          </div>
        )}
      </div>
    );
  }
}

export default Heading;
