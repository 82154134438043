import React from "react";
import classNames from "classnames";
import ChangeAbstract from "../../../../../abstract/ChangeAbstract";

import "../styles/default.scss";

class ChangePercent extends ChangeAbstract {
  render() {
    const changeRate = this.getChangeRate();

    if (changeRate === null) {
      return <span>-</span>;
    }

    return (
      <span
        className={classNames({
          "change-percent": true,
          "change-plus": Number(changeRate) === 1,
          "change-minus": Number(changeRate) === -1
        })}
      >
        {changeRate === 1 ? "+" : null}
        {this.getChange()}%
      </span>
    );
  }
}

ChangePercent.propTypes = {};

export default ChangePercent;
