import "../styles/default.scss";

import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import classNames from "classnames";
import React from "react";

import SquaberButton from "../../../common/SquaberButton";

class ModalBase extends React.Component {
  fullscreen = false;

  static DIALOG_CLOSE_REASONS = {
    BACKDROP_CLICK: "backdropClick",
    ESCAPE_KEY_DOWN: "escapeKeyDown"
  };

  state = {
    visuallyHidden: false
  };

  setVisuallyHidden(value) {
    this.setState({
      visuallyHidden: value
    });
  }

  getContent() {
    const {
      title,
      message,
      hideModal,
      showCancelButton = true,
      classNameContentText,
      confirmButtonText,
      onConfirm,
      onCancel
    } = this.props;

    return (
      <React.Fragment>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText className={classNameContentText}>
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {showCancelButton ? (
            <SquaberButton
              onClick={() => {
                onCancel?.();
                hideModal?.();
              }}
              color="primary"
            >
              Cancel
            </SquaberButton>
          ) : null}
          <SquaberButton
            onClick={() => {
              onConfirm?.();
              hideModal?.();
            }}
            color="primary"
          >
            {confirmButtonText ? confirmButtonText : "Ok"}
          </SquaberButton>
        </DialogActions>
      </React.Fragment>
    );
  }

  getClassName() {
    return null;
  }

  render() {
    const {
      show,
      hideModal,
      disableBackdropClick = false,
      disableEscapeKeyDown = false,
      messageIsMarketingMessage = false,
      onCancel
    } = this.props;

    const { visuallyHidden } = this.state;

    const customClassName = this.getClassName();

    if (visuallyHidden) {
      return null;
    }

    return (
      <Dialog
        fullScreen={this.fullscreen}
        open={show}
        onClose={(event, reason) => {
          if (
            (reason === ModalBase.DIALOG_CLOSE_REASONS.BACKDROP_CLICK &&
              disableBackdropClick) ||
            (reason === ModalBase.DIALOG_CLOSE_REASONS.ESCAPE_KEY_DOWN &&
              disableEscapeKeyDown)
          ) {
            return;
          }

          onCancel?.();
          hideModal();
        }}
        aria-labelledby="form-dialog-title"
        classes={{
          paper: classNames({
            "squaber-modal-base": true,
            [customClassName]: !!customClassName
          })
        }}
        style={{
          zIndex: 10000
        }}
        maxWidth={messageIsMarketingMessage ? "lg" : undefined}
      >
        {this.getContent()}
      </Dialog>
    );
  }
}

export default ModalBase;
