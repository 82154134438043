import { createAction, handleActions } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

export const TOGGLE_MARKET = "app/userMarkets/TOGGLE_MARKET";

export const getUserMarkets = createRoutine("app/userMarkets/GET_USER_MARKETS");
export const setUserMarkets = createRoutine("app/userMarkets/SET_USER_MARKETS");
export const toggleMarket = createAction(
  TOGGLE_MARKET,
  (market: string, status: boolean) => ({
    market,
    status
  })
);

export const initialState = {
  markets: null,
  loading: false
};

export default handleActions(
  {
    [getUserMarkets.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [getUserMarkets.SUCCESS]: (state, { payload }) => {
      return {
        ...state,
        loading: false,
        markets: payload.data
      };
    },

    [TOGGLE_MARKET]: (state, { payload: { market } }) => {
      if (!state.markets) {
        return state;
      }
      let newMarkets = state.markets;
      let marketToToggleIndex = newMarkets.findIndex(
        element => element === market
      );

      if (marketToToggleIndex !== -1) {
        newMarkets.splice(marketToToggleIndex, 1);
      } else {
        newMarkets.push(market);
      }

      return {
        ...state,
        markets: newMarkets
      };
    }
  },
  initialState
);
