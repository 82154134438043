import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

interface ToggleStockToListMetaData {
  stockListId: number;
  stockId: number;
  stockTicker: string;
}

class AddStockToListEvent extends BaseEvent {
  constructor(metaData: ToggleStockToListMetaData) {
    super();
    this.metaData = metaData;
  }

  getEventName() {
    return "add_stock_to_list";
  }

  getEventTriggerName() {
    return "Adding stock to user stock list";
  }

  getEventDetailsMatrix() {
    return `detail5=stock list id
detail6=stock id
detail7=stock ticker`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: this.metaData.stockListId,
      detail6: this.metaData.stockId,
      detail7: this.metaData.stockTicker
    };
  }
}

export default AddStockToListEvent;
