import React from "react";
import classNames from "classnames";
import withTranslations from "../../../../../hocs/withTranslations";
import "../rendererStyles/String.scss";

const String = ({ value, format, translate, columnFontSize }) => {
  return (
    <div
      className={classNames({
        "string-renderer": true,
        [`column-size-${columnFontSize}`]: !!columnFontSize
      })}
    >
      {format === "string_translated" ? translate(value) : value}
    </div>
  );
};

export default withTranslations(String);
