import withStyles from "@material-ui/core/styles/withStyles";
import Switch from "@material-ui/core/Switch";

const YellowSwitch = withStyles({
  switchBase: {
    color: "#DDD",
    "&$checked": {
      color: "#F1C12C"
    },
    "& + &track": {
      backgroundColor: "#F1C12C"
    },
    "&$checked + $track": {
      backgroundColor: "#F1C12C"
    }
  },
  checked: {},
  track: {}
})(Switch);

export default YellowSwitch;
