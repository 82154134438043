import React from "react";
import withLocation from "../../../../../hocs/withLocation";
import { withRouter } from "react-router";
import withUserData from "../../../../../hocs/withUserData";
import withMenu from "../../../../../hocs/withMenu";
import withPaymentDetails from "../../../../../hocs/withPaymentDetails";
import withAppStatus from "../../../../../hocs/withAppStatus";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withDeviceType from "../../../../../hocs/withDeviceType";
import withStockDataLoading from "../../../../../hocs/withStockDataLoading";

import HeaderAbstractWeb from "./default";

import logo from "../../../../../logo";
import config from "../../../../../config";

import "../styles/expalerts.scss";

@withLocation
@withRouter
@withUserData
@withMenu
@withPaymentDetails
@withAppStatus
@withBottomNavigation("bottomNavigation")
@withDeviceType
@withStockDataLoading
class HeaderExpAlerts extends HeaderAbstractWeb {
  getTextLogo() {
    return (
      <img src={logo.text} className="img-fluid logo-text" alt={config.title} />
    );
  }

  getRegisterUrl() {
    return config.registerUrl;
  }

  getRegisterTarget() {
    return "_blank";
  }
}

export default HeaderExpAlerts;
