import "../styles/default.scss";

import moment from "moment-mini";
import React from "react";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import config from "../../../../../config";
import withAnalysisCategories from "../../../../../hocs/withAnalysisCategories";
import withGlobalConfigFields from "../../../../../hocs/withGlobalConfigFields";
import withTranslations from "../../../../../hocs/withTranslations";
import withUserData from "../../../../../hocs/withUserData";
import useGetRequest from "../../../../../hooks/useGetRequest";
import { globalConfigFields } from "../../../../../redux/modules/globalConfig";
import Loader from "../../../common/Loader";
import PageTitle from "../../../common/PageTitle";
import SquaberButton from "../../../common/SquaberButton";
import SquaberLink from "../../../common/SquaberLink/modules/default";
import ContentWrapper from "../components/ContentWrapper";
import ClickableContent from "../../../common/ClickableContent";

function SingleAnalysisPage({
  match,
  translate,
  locale,
  analysisCategories,
  userIsNotLoggedIn,
  globalConfig: { offerUrl }
}) {
  const { id } = match.params;

  const { response, loading, error } = useGetRequest({
    urlPath: "app.stockAnalysis.get",
    variables: {
      stockAnalysisId: id
    },
    locale,
    skipAccessCheck: true
  });

  const contentWrapperProps = {
    className: "analysis-content",
    stock: response?.stock
  };

  if (loading) {
    return (
      <ContentWrapper {...contentWrapperProps}>
        <Loader small />
      </ContentWrapper>
    );
  }

  if (error) {
    switch (error?.response?.status) {
      case 403: {
        const requirePremium = error?.response?.data?.detail?.endsWith?.(
          "premium"
        );

        if (requirePremium && !userIsNotLoggedIn) {
          return (
            <ContentWrapper {...contentWrapperProps}>
              <h2>{translate("Premium access required")}</h2>
              <p>
                {translate(
                  "This is the content available in the premium Squaber version. Use one of our bundles to get access to it"
                )}
              </p>
              <div className={"buttons"}>
                <SquaberLink
                  to={offerUrl || config.defaultPremiumOfferUrl}
                  target="_blank"
                >
                  <SquaberButton>{translate("i want to use it")}</SquaberButton>
                </SquaberLink>
              </div>
            </ContentWrapper>
          );
        }

        if (requirePremium) {
          return (
            <ContentWrapper {...contentWrapperProps}>
              <h2>{translate("Premium access required")}</h2>
              <p>
                {translate(
                  "This is the content available in the premium Squaber version. Use one of our bundles to get access to it"
                )}
              </p>
              <div className={"buttons"}>
                <SquaberLink
                  to={UrlProvider.getUrl("fe.loginWithRedirect", {
                    locale,
                    redirectUrl: window.location.pathname
                  })}
                >
                  <SquaberButton>{translate("Log in")}</SquaberButton>
                </SquaberLink>
                <SquaberLink
                  to={UrlProvider.getUrl("fe.registerWithRedirect", {
                    locale,
                    redirectUrl: window.location.pathname
                  })}
                >
                  <SquaberButton>{translate("Register")}</SquaberButton>
                </SquaberLink>
              </div>
            </ContentWrapper>
          );
        }

        return (
          <ContentWrapper {...contentWrapperProps}>
            <h2>{translate("Register first")}</h2>
            <p>{translate("single_analysis_page_403_freemium_description")}</p>
            <div className={"buttons"}>
              <SquaberLink
                to={UrlProvider.getUrl("fe.loginWithRedirect", {
                  locale,
                  redirectUrl: window.location.pathname
                })}
              >
                <SquaberButton>{translate("Log in")}</SquaberButton>
              </SquaberLink>
              <SquaberLink
                to={UrlProvider.getUrl("fe.registerWithRedirect", {
                  locale,
                  redirectUrl: window.location.pathname
                })}
              >
                <SquaberButton>{translate("Register")}</SquaberButton>
              </SquaberLink>
            </div>
          </ContentWrapper>
        );
      }

      default: {
        return (
          <ContentWrapper {...contentWrapperProps}>
            <h2>{translate("Analysis not found")}</h2>
          </ContentWrapper>
        );
      }
    }
  }

  if (!response) {
    return (
      <ContentWrapper {...contentWrapperProps}>
        <h2>{translate("Analysis not found")}</h2>
      </ContentWrapper>
    );
  }

  const { stock, content, created, category } = response;

  const title = `${translate("Squaber team comment for")} ${
    stock.ticker
  } ${translate("from day")} ${moment(created).format("DD.MM.YYYY HH:mm")}`;

  const analysisCategory = analysisCategories?.find(c => c.id === category);

  return (
    <ContentWrapper {...contentWrapperProps}>
      <PageTitle title={title} />
      <h1>{title}</h1>
      <ClickableContent dangerouslySetInnerHTML={{ __html: content }} />
      {analysisCategory?.description ? (
        <small
          className="category-description"
          dangerouslySetInnerHTML={{ __html: analysisCategory.description }}
        />
      ) : null}
    </ContentWrapper>
  );
}

export default compose(
  withTranslations,
  withAnalysisCategories,
  withUserData,
  withGlobalConfigFields([globalConfigFields.OFFER_URL])
)(SingleAnalysisPage);
