import React from "react";
import { actions as authActions } from "../redux/modules/auth/login";
import { connect } from "../i18n/connect";

export default function withExecuteAuthRedirect(WrappedComponent) {
  const mapDispatchToProps = {
    executeAuthRedirect: authActions.executeAuthRedirect
  };

  @connect(null, mapDispatchToProps)
  class WithExecuteAuthRedirect extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithExecuteAuthRedirect;
}
