import React, { useCallback } from "react";
import classNames from "classnames";
import { showModal } from "../../../../../lib/react-redux-modal-provider";

import "../styles/default.scss";

const ClickableContent = ({ children, className, dangerouslySetInnerHTML }) => {
  const onContentClick = useCallback(event => {
    if (event.target.tagName === "IMG") {
      showModal("PicturePresentationModal", {
        image: event.target.src,
        alt: event.target.alt
      });
    }
  }, []);

  return (
    <div
      onClick={onContentClick}
      className={classNames(className, "clickable-content")}
      dangerouslySetInnerHTML={dangerouslySetInnerHTML}
    >
      {children}
    </div>
  );
};

export default ClickableContent;
