import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";

export const fetchPartnershipDataRoutine = createRoutine(
  "app/partnership/FETCH_PARTNERSHIP_DATA"
);

export const initialState = {
  data: {},
  loading: false,
  error: null
};

export default handleActions(
  {
    [fetchPartnershipDataRoutine.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [fetchPartnershipDataRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload
    }),
    [fetchPartnershipDataRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    })
  },
  initialState
);
