import classNames from "classnames";
import { isEqual, isFunction, isString } from "lodash";
import moment from "moment-mini";
import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import decisionModal from "../../../../../lib/decisionModal";
import SquaberTooltip from "../../SquaberTooltip/";
import DefaultEditTemplate from "./EditTemplate";

@withTranslations
class TemplatesListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      form: {
        name: props.template.name,
        is_default: props.template.is_default
      }
    };
  }

  initForm() {
    const { template } = this.props;

    const form = {};

    if (template?.name) form.name = template.name;
    if (template?.is_default) form.is_default = template.is_default;
    if (template?.description) form.description = template.description;

    this.setState({
      form
    });
  }

  setIsEditing(value) {
    if (value) this.initForm();

    this.setState({
      isEditing: value
    });
  }

  componentDidUpdate(prevProps: Readonly<P>) {
    if (!isEqual(prevProps.template, this.props.template)) {
      this.setIsEditing(false);
    }
  }

  getActions() {
    const {
      editable = true,
      template,
      removeChartTemplate,
      fetchChartTemplateDetails,
      uploadChartTemplateToChart,
      updateChartTemplate,
      extractTemplateFromCurrentChartSettings,
      translateNamespace = "template",
      addChartAnalysisAsync,
      translate
    } = this.props;

    const { isEditing, form } = this.state;

    if (!isEditing) {
      return (
        <React.Fragment>
          {!!editable ? (
            <SquaberTooltip title={`tooltip-edit-${translateNamespace}`}>
              <span
                className="edit"
                onClick={() => {
                  this.setIsEditing(true);
                }}
              >
                <i className="fal fa-pencil" />
              </span>
            </SquaberTooltip>
          ) : null}
          {isFunction(updateChartTemplate) &&
          isFunction(extractTemplateFromCurrentChartSettings) ? (
            <SquaberTooltip title={`tooltip-overwrite-${translateNamespace}`}>
              <span
                className="overwrite"
                onClick={async () => {
                  const decision = await decisionModal(
                    "ConfirmModal",
                    {
                      title: "Are you sure?",
                      message: `Are you sure you want to overwrite this ${translateNamespace} with current chart settings?`
                    },
                    ["confirm", "cancel"]
                  );

                  if (decision !== "confirm") return;

                  extractTemplateFromCurrentChartSettings(chartData => {
                    if (!chartData) return;

                    updateChartTemplate({
                      id: template.id,
                      settings: chartData
                    });
                  }, true);
                }}
              >
                <i className="fal fa-cloud-upload" />
              </span>
            </SquaberTooltip>
          ) : null}
          {isFunction(uploadChartTemplateToChart) ? (
            <SquaberTooltip title={`tooltip-apply-${translateNamespace}`}>
              <span
                className="apply"
                onClick={async () => {
                  const decision = await decisionModal(
                    "ConfirmModal",
                    {
                      title: "Are you sure?",
                      message: `Are you sure you want to apply ${translateNamespace} settings to this chart?`
                    },
                    isFunction(fetchChartTemplateDetails) &&
                      isFunction(addChartAnalysisAsync)
                      ? ["confirm", "cancel", "secondaryConfirm"]
                      : ["confirm", "cancel"],
                    {
                      secondaryConfirm: `Save current ${translateNamespace} and use it`
                    }
                  );

                  if (
                    decision === "confirm" ||
                    decision === "secondaryConfirm"
                  ) {
                    if (isFunction(fetchChartTemplateDetails)) {
                      const settings = await fetchChartTemplateDetails({
                        id: template.id
                      });

                      if (!settings) return;

                      if (decision === "secondaryConfirm") {
                        extractTemplateFromCurrentChartSettings(chartData => {
                          if (!chartData) return;

                          addChartAnalysisAsync({
                            settings: chartData
                          });
                        }, true);
                      }

                      const { chart, drawnObjectsOptions } = settings ?? {};

                      uploadChartTemplateToChart({
                        chart,
                        settings: drawnObjectsOptions
                      });
                    }
                  }
                }}
              >
                <i className="fal fa-cloud-download" />
              </span>
            </SquaberTooltip>
          ) : null}
          {isFunction(removeChartTemplate) ? (
            <SquaberTooltip title={`tooltip-remove-${translateNamespace}`}>
              <span
                className="remove"
                onClick={async () => {
                  const removeMessage = translate(
                    `Do you really want to delete %{templateName}`,
                    { templateName: template.name }
                  );

                  const decision = await decisionModal(
                    "ConfirmModal",
                    {
                      title: "Are you sure?",
                      message: removeMessage
                    },
                    ["confirm", "cancel"]
                  );

                  if (decision === "confirm") {
                    removeChartTemplate({ id: template.id });
                  }
                }}
              >
                <i className="fal fa-trash-alt" />
              </span>
            </SquaberTooltip>
          ) : null}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <SquaberTooltip title="tooltip-apply-template-changes">
          <span
            className="apply-edit"
            onClick={() => {
              updateChartTemplate({
                ...template,
                ...form
              });
            }}
          >
            <i className="fal fa-check" />
          </span>
        </SquaberTooltip>
        <SquaberTooltip title="tooltip-discard-template-changes">
          <span
            className="abort-editing"
            onClick={() => {
              this.setIsEditing(false);
              this.initForm();
            }}
          >
            <i className="fal fa-times" />
          </span>
        </SquaberTooltip>
      </React.Fragment>
    );
  }

  getSubtitle(author, date) {
    const formattedDate = isString(date)
      ? moment(date).format("DD.MM.YYYY")
      : "";

    const hasAuthor = !!author;
    const hasDate = !!formattedDate;

    if (hasAuthor && hasDate) {
      return `${author} - ${moment(date).format("DD.MM.YYYY")}`;
    }

    if (hasAuthor) {
      return author;
    }

    if (hasDate) {
      return moment(date).format("DD.MM.YYYY, HH:mm");
    }
  }

  getContent() {
    const { translate, template, hideDefault } = this.props;
    const { user_full_name, created, name } = template ?? {};

    const subtitle = this.getSubtitle(user_full_name, created);

    const content = (
      <div className="basic-info">
        {!!subtitle ? <span className="template-name">{subtitle}</span> : null}
        {!!name ? <span className="template-name">{name}</span> : null}
        {!hideDefault && template?.is_default ? (
          <span className="template-is-default">
            {translate("Default template")}
          </span>
        ) : null}
      </div>
    );

    return !!template?.description ? (
      <SquaberTooltip title={template.description}>{content}</SquaberTooltip>
    ) : (
      content
    );
  }

  render() {
    const { isEditing, form } = this.state;
    const {
      translate,
      EditTemplate = DefaultEditTemplate,
      addChartTemplateErrors
    } = this.props;

    return (
      <li
        className={classNames({
          "is-editing": isEditing
        })}
      >
        <span>
          {isEditing && !!EditTemplate ? (
            <EditTemplate
              initModel={form}
              onChange={newForm => this.setState({ form: newForm })}
              translate={translate}
              addChartTemplateErrors={addChartTemplateErrors}
            />
          ) : (
            this.getContent()
          )}
        </span>
        <div className="actions">{this.getActions()}</div>
      </li>
    );
  }
}

export default TemplatesListItem;
