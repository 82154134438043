import "../styles/default.scss";

import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";

const AnalysisDisplayStyles = ({
  onClick,
  translate,
  selectedDisplayStyle
}) => {
  return (
    <div className={"analysis-display-styles"}>
      <label className={"analysis-display-styles__label"}>
        {translate("analysis_display_label")}
      </label>
      <button
        className={classNames("analysis-display-styles__button", {
          "analysis-display-styles__button_selected":
            selectedDisplayStyle === "grid"
        })}
        onClick={async () => await onClick("grid")}
      >
        <i className="fas fa-th"></i>
      </button>
      <button
        className={classNames("analysis-display-styles__button", {
          "analysis-display-styles__button_selected":
            selectedDisplayStyle === "list"
        })}
        onClick={async () => await onClick("list")}
      >
        <i className="fas fa-bars"></i>
      </button>
    </div>
  );
};

AnalysisDisplayStyles.propTypes = {
  onClick: PropTypes.func,
  selectedDisplayStyle: PropTypes.string
};

export default withTranslations(AnalysisDisplayStyles);
