type WaitTimeout = {
  timeout: ?number
};

export default function* wait(duration: number, waitTimeout: WaitTimeout = {}) {
  return yield new Promise(resolve => {
    waitTimeout.timeout = setTimeout(() => {
      resolve();
    }, duration);
  });
}
