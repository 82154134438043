import autoBind from "auto-bind";
import React from "react";
import { bindPromiseCreators } from "redux-saga-routines";

import connect from "../i18n/connect";
import {
  addChartAnalysisRoutinePromiseCreator,
  clearChartAnalysis,
  clearErrorMessages,
  deleteChartAnalysis,
  fetchChartAnalysesDetailsRoutinePromiseCreator,
  fetchChartAnalysesRoutine,
  updateChartAnalysis
} from "../redux/modules/chartAnalyses";
import { getChartAnalysesState } from "../redux/modules/chartAnalyses/selector";

export default function withChartAnalysis(WrappedComponent) {
  const mapStateToProps = state => ({
    chartAnalysesState: getChartAnalysesState(state)
  });

  const mapDispatchToProps = {
    fetchChartAnalyses: fetchChartAnalysesRoutine,
    updateChartAnalysis,
    deleteChartAnalysis,
    clearErrorMessages,
    clearChartAnalysis
  };

  const additionalMapDispatchToProps = dispatch => ({
    ...bindPromiseCreators(
      {
        fetchChartAnalysesDetails: fetchChartAnalysesDetailsRoutinePromiseCreator,
        addChartAnalysisAsync: addChartAnalysisRoutinePromiseCreator
      },
      dispatch
    )
  });

  @connect(mapStateToProps, mapDispatchToProps)
  @connect(null, additionalMapDispatchToProps)
  class WithChartAnalysis extends React.Component {
    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    componentDidMount(): void {
      this.props.fetchChartAnalyses();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithChartAnalysis;
}
