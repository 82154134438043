import React from "react";
import { compose } from "redux";
import Formsy from "formsy-react";
import { withRouter } from "react-router";
import withTagDetailsForm from "../../../../../hocs/withTagDetailsForm";
import withCreateNewTag from "../../../../../hocs/withCreateNewTag";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import { UrlProvider } from "../../../../../api/UrlProvider";
import SquaberButton from "../../../common/SquaberButton";

const TagsSettingsAddNewTag = ({
  formBuilder,
  createNewTag,
  translate,
  history,
  locale
}) => (
  <Formsy
    onValidSubmit={() => {
      createNewTag(formBuilder.fields).then(response => {
        const { id: createdTagId } = response;

        history.push(
          UrlProvider.getUrl("fe.tagSettings", {
            locale,
            id: createdTagId
          })
        );
      });
    }}
  >
    <FormBuilderWeb {...formBuilder} />

    <div className="text-right">
      <SquaberButton color="primary" type="submit">
        {translate("Add tag")}
      </SquaberButton>
    </div>
  </Formsy>
);

TagsSettingsAddNewTag.propTypes = {};

export default compose(
  withTagDetailsForm,
  withCreateNewTag,
  withRouter
)(TagsSettingsAddNewTag);
