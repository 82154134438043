import React from "react";

import snakeToPascal from "../../../../../../../lib/snakeToPascal";
import * as Renderers from "./FrontEndRenderers/";

const Renderer = (props: {
  value: string,
  column: { values_type: string, attribute_name: string }
}) => {
  const { column, value } = props;

  const rendererName = snakeToPascal(column.attribute_name);
  const Component = Renderers[rendererName];

  if (!Component || column.values_type !== "generated_in_frontend") {
    return value;
  }

  return <Component {...props} />;
};

export default Renderer;
