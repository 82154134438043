import { groupBy, isEqual } from "lodash";
import moment from "moment-mini";
import {
  aggregationMarkers,
  markerTypes,
  markerTypesNames
} from "./markerTypes";
import getShape from "./getShape";

class SquaberTVMarkersController {
  constructor(tvWidget, dataFeed, translate) {
    this.tvWidget = tvWidget;
    this.chart = tvWidget.chart();
    this.dataFeed = dataFeed;
    this.translate = translate;
  }

  drawnObjects = [];
  savedMarkers = [];

  prevMarkersData = {};
  markersData = {};

  prevDrawnObjectsOptions = [];
  drawnObjectsOptions = [];

  previouslyAppliedMarks = [];

  getMark(data, config) {
    const unixTime = this.getUnixTime(config.getMarkerTime(data));

    const markerColor =
      typeof config.markerColor === "function"
        ? config.markerColor(data)
        : config.markerColor;

    return {
      id: `mark-${config.name}-${Number(unixTime)}`,
      type: config.name,
      time: unixTime,
      color: {
        border: markerColor,
        background: markerColor
      },
      text: `<div style="font-family: Arial, sans-serif; font-weight: 400; color: black;">${
        config.getHtmlLabelInline
          ? config.getHtmlLabelInline(data, this.translate)
          : config.getLabel(data, this.translate)
      }</div>`,
      label: config.markerLabel,
      labelFontColor: "white",
      minSize: 15,
      markerMetaData: config.getMarkerMetaData(data)
    };
  }

  drawMarkersOnChart(force = false) {
    if (
      !force &&
      isEqual(this.prevMarkersData, this.markersData) &&
      isEqual(this.prevDrawnObjectsOptions, this.drawnObjectsOptions)
    ) {
      return;
    }

    this.prevMarkersData = this.markersData;
    this.prevDrawnObjectsOptions = this.drawnObjectsOptions;

    let drawnObjects = [];
    let marksToApply = [];

    for (let markerType of markerTypes) {
      if (!this.shouldDraw(markerType.shouldDrawSettingName)) {
        const objectsToRemove = this.drawnObjects.filter(
          object => object.name === markerType.name
        );

        for (let drawnObject of objectsToRemove) {
          this.chart.removeEntity(drawnObject.id);
        }

        this.drawnObjects = this.drawnObjects.filter(
          object => !objectsToRemove.includes(object)
        );

        continue;
      }

      const markers = this.markersData[markerType.name];

      if (!markers || !markers.length) {
        continue;
      }

      if (markerType.name === markerTypesNames.mediaMonitorMessages) {
        const groupedMarkersByDate = groupBy(markers, ({ pub_datetime }) =>
          moment(pub_datetime).format("YYYY-MM-DD")
        );

        for (const [key, value] of Object.entries(groupedMarkersByDate)) {
          if (value?.length === 1) {
            this.createMarker(value[0], markerType, drawnObjects, marksToApply);
          } else {
            this.createMarker(
              {
                attachments: [],
                aggregatedMarkers: value,
                is_premium: false,
                pub_datetime: `${key}T00:00:00`
              },
              aggregationMarkers.aggregatedMediaMonitorMessages,
              drawnObjects,
              marksToApply
            );
          }
        }
      } else {
        for (let marker of markers) {
          this.createMarker(marker, markerType, drawnObjects, marksToApply);
        }
      }
    }
    this.drawnObjects = [...this.drawnObjects, ...drawnObjects];
    this.dataFeed.setMarks(marksToApply);

    try {
      if (this.previouslyAppliedMarks.length !== marksToApply.length) {
        this.chart.clearMarks();
      }
      this.chart.refreshMarks();
    } catch (e) {}

    this.previouslyAppliedMarks = marksToApply;
  }

  drawCustomMarkers(refDrawnObjects, marker, unixTime, markerType) {
    let objectsToRedraw = [];

    try {
      const drawnObject = this.drawCustomMarkerOnChart(
        marker,
        unixTime,
        markerType
      );

      refDrawnObjects.push(drawnObject);
    } catch (error) {
      objectsToRedraw.push({
        marker,
        unixTime,
        markerType
      });
    }

    if (objectsToRedraw.length) {
      setTimeout(() => {
        for (let obj of objectsToRedraw) {
          const drawnObject = this.drawCustomMarkerOnChart(
            marker,
            unixTime,
            markerType
          );

          refDrawnObjects.push(drawnObject);
        }
      }, 2000);
    }
  }

  drawCustomMarkerOnChart(marker, unixTime, markerType) {
    const defaultPoint = {
      time: unixTime,
      channel: "close"
    };

    const point = isNaN(marker.value)
      ? { ...defaultPoint }
      : {
          ...defaultPoint,
          price: Number(marker.value)
        };

    return {
      id: this.chart.createShape(
        point,
        getShape(marker, { ...markerType, translate: this.translate })
      ),
      unixTime,
      name: markerType.name
    };
  }

  setMarkersData(key, value) {
    this.markersData[key] = value;

    return this;
  }

  setDrawnObjectsOptions(value) {
    this.drawnObjectsOptions = value;

    return this;
  }

  shouldDraw(key) {
    const correspondingSetting = this.drawnObjectsOptions.find(
      setting => setting.key === key
    );

    if (correspondingSetting?.disabled) return false;
    return correspondingSetting ? correspondingSetting.value : true;
  }

  saveMarker(marker) {
    const alreadySavedMarkerIndex = this.savedMarkers.findIndex(
      foundMarker => foundMarker.id === marker.id
    );

    if (alreadySavedMarkerIndex !== -1) {
      this.savedMarkers.splice(alreadySavedMarkerIndex, 1);
    }

    this.savedMarkers.push(marker);
  }

  createMarker(marker, markerType, refDrawnObjects, refMarksToApply) {
    const markerTime = markerType.getMarkerTime(marker);
    const unixTime = this.getUnixTime(markerTime);

    const drawnObjectIndex = this.drawnObjects.findIndex(object => {
      return markerType.name === object.name && unixTime === object.unixTime;
    });
    const hasBeenDrawn = drawnObjectIndex !== -1;

    if (typeof markerType.shape !== "undefined" || hasBeenDrawn) {
      if (drawnObjectIndex !== -1) {
        const { id } = this.drawnObjects[drawnObjectIndex];

        try {
          const { time } = this.chart.getShapeById(id).getPoints()[0] || {};

          if (
            typeof time !== "undefined" &&
            Math.abs(time - unixTime) <= 86400
          ) {
            return;
          }
        } catch (e) {}

        if (id) {
          this.chart.removeEntity(id);
        }
        this.drawnObjects.splice(drawnObjectIndex, 1);
      }
      this.drawCustomMarkers(refDrawnObjects, marker, unixTime, markerType);
    } else if (moment().diff(moment(markerTime)) >= 0) {
      const mark = this.getMark(marker, markerType);

      this.saveMarker(mark);
      refMarksToApply.push(mark);
    } else {
      this.drawCustomMarkers(refDrawnObjects, marker, unixTime, markerType);
    }
  }

  getUnixTime(time, offsetInHours = 2) {
    return moment(time)
      .add(offsetInHours, "hours")
      .unix();
  }

  getMarkerById(id) {
    return this.savedMarkers.find(marker => marker.id === id);
  }
}

export default SquaberTVMarkersController;
