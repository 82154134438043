import React from "react";
import autoBind from "auto-bind";
import classNames from "classnames";
import withTranslations from "../../../../../../../hocs/withTranslations";
import SettingsComponentPropTypes from "../../../../../../../lib/UniversalTable/SettingsComponentPropTypes";
import ApiHelper from "../../../../../../../lib/UniversalTable/CoreHelpers/ApiHelper";

@withTranslations
class Sorting extends React.Component {
  static propTypes = SettingsComponentPropTypes;

  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  getDefaultSortingOption() {
    const {
      translate,
      settingsState: { sortBy }
    } = this.props;

    const label = translate("Default");

    const sortValue = {
      field: null,
      direction: "asc"
    };

    let isActive = sortBy.field === sortValue.field;

    return (
      <div
        className={classNames({
          option: true,
          active: isActive
        })}
        onClick={() => {
          this.setSortBy(sortValue);
        }}
      >
        <span>{label}</span>
      </div>
    );
  }

  getMultiSortingOption(column) {
    const {
      translate,
      settingsState: { sortBy }
    } = this.props;

    const label = column ? column.label : translate("Default");

    const sortValue = {
      field: column ? ApiHelper.getColumnAttributeForSorting(column) : null,
      direction: "desc"
    };

    let isActive = sortBy.field === sortValue.field;

    return (
      <div
        key={column.attribute_name}
        className={classNames({
          option: true,
          active: isActive
        })}
      >
        {this.getDirectionArrow("asc", sortValue, isActive, sortBy)}
        <span className="label">{label}</span>
        {this.getDirectionArrow("desc", sortValue, isActive, sortBy)}
      </div>
    );
  }

  getDirectionArrow(direction, sortValue, isActive, sortBy) {
    const directionSortValue = {
      ...sortValue,
      direction
    };

    const directionIsActive = isActive && sortBy.direction === direction;

    return (
      <div
        key={`sort-direction-${direction}`}
        className={classNames({
          direction: true,
          [`sort-direction-${direction}`]: true,
          active: directionIsActive
        })}
        onClick={event => {
          this.setSortBy(directionSortValue);
          event.stopPropagation();
        }}
      >
        <i
          className={classNames({
            fa: true,
            "fa-angle-down": direction === "desc",
            "fa-angle-up": direction === "asc"
          })}
        />
      </div>
    );
  }

  setSortBy(sortBy) {
    const { setSettingsState } = this.props;

    setSettingsState({ sortBy });
  }

  render() {
    const { columns, userHasAccess } = this.props;

    return (
      <div className="sorting-options">
        {this.getDefaultSortingOption()}
        {columns
          .filter(column => ApiHelper.getIsSortable(column))
          .filter(column => {
            if (column.is_premium) {
              return userHasAccess;
            }
            return true;
          })
          .map(this.getMultiSortingOption)}
      </div>
    );
  }
}

export default Sorting;
