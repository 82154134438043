const isNative = require("../../lib/envHelpers/isNative");
const mobileApiHostName = "api-mobile-s.squaber.com";
const defaultApiHostName = "api-s.squaber.com";

const apiHostName = isNative() ? mobileApiHostName : defaultApiHostName;

module.exports = {
  apiHostName: `https://${apiHostName}/`,
  apiHostNameWithoutTrailingSlash: `https://${apiHostName}`,
  apiUrl: `https://${apiHostName}/:locale/api/`,
  baseHostName: "localhost:3001",
  firebase: {
    apiKey:
      "AAAAM9wTFK0:APA91bETjQMG58ETrGdkb3ODFGIPgdlTzr9FhVg_o3O2U1mrJpCV1uzZYSzACD2_Q_vWU2tO-YjrgL7f4gJEot0uXwe_wk3e0wdnU2tQk9j2p7ooBM6b22JP843zvyfB7Rge2FiKPiXS",
    authDomain: "investio-backup.firebaseapp.com",
    databaseURL: "https://squaber-staging-aws.firebaseio.com/",
    storageBucket: "investio-backup.appspot.com"
  },
  googleReCaptcha: {
    url: "http://localhost",
    sitekey: "6LftYFEoAAAAAFR4I9IodaMD7m2PzlLOg1SppCKv"
  },
  websocketUrl: "https://socketio-s.squaber.com:444",
  tradingViewOptions: {
    hasIntraday: true,
    marketsIntervals: [
      {
        markets: ["gpw", "nc", "crypto", "glo"],
        intervals: ["1", "5", "15", "30", "60", "240", "D", "W", "M"]
      },
      {
        markets: ["metals", "energies", "commodities", "indices", "forex"],
        intervals: ["30", "60", "240", "D", "W", "M"]
      }
    ],
    defaultMarketsIntervals: ["D", "W", "M"]
  },
  quoteConvertersInUse: false,
  defaultPremiumPopupUrl: "https://l.squaber.com/pop-up-premium/",
  webAppUrl: "https://s.squaber.com/",
  native_tv_enabled: true,
  cookieDomain: "localhost",
  buildNumber: "30.rc-1",
  currentTag: "5.4.0",
  gtm: {
    trackingId: "GTM-W37N2CN"
  },
  amplitude: {
    trackRegistration: false,
    trackRegistrationConfirmation: false,
    trackRevenue: false
  },
  expoExperienceId: "@squaber/squaber-staging",
  purchasesDebugLog: true,
  availableMarkets: [
    "GPW",
    "NYSE",
    "NASDAQ",
    "FUNDPL",
    "NC",
    "GLO",
    "METALS",
    "ENERGIES",
    "COMMODITIES",
    "INDICES",
    "FOREX",
    "CRYPTO"
  ]
};
