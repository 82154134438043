import React from "react";
import ModalBase from "../../ModalBase";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Formsy from "formsy-react";
import withResetPasswordModal from "../../../../../hocs/withResetPasswordModal";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import SquaberButton from "../../../common/SquaberButton";

@withResetPasswordModal
class ResetPasswordModal extends ModalBase {
  getContent() {
    const { translate, formBuilder, hideModal } = this.props;

    return (
      <React.Fragment>
        <DialogTitle id="form-dialog-title">
          {translate("Reset password")}
        </DialogTitle>
        <DialogContent>
          <Formsy>
            <FormBuilderWeb {...formBuilder} />
          </Formsy>
        </DialogContent>
        <DialogActions>
          <SquaberButton
            color="secondary"
            onClick={() => {
              hideModal();
            }}
          >
            {translate("Cancel")}
          </SquaberButton>
          <SquaberButton
            onClick={() => {
              this.props.trigger(formBuilder.fields);
            }}
            color="primary"
          >
            {translate("Send")}
          </SquaberButton>
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default ResetPasswordModal;
