import "../styles/default.scss";

import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import ContentSection from "../../ContentSection";

const PortfoliosError = ({ translate }) => (
  <ContentSection className="portfolios-error">
    <h1>{translate("Portfolio")}</h1>
    <p>{translate("Something went wrong with loading portfolios.")}</p>
  </ContentSection>
);

export default withTranslations(PortfoliosError);
