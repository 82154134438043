import React from "react";
import { connect } from "../i18n/connect";
import { getPortfolioCashTransactionsState } from "../redux/modules/portfolios/selector";

export default function withPortfolioCashOperations(WrappedComponent) {
  const mapStateToProps = state => ({
    portfolioCashTransactionsState: getPortfolioCashTransactionsState(state)
  });

  @connect(mapStateToProps)
  class WithPortfolioCashOperations extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithPortfolioCashOperations;
}
