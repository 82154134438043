import React from "react";
import { isEmpty } from "lodash";
import withSingleTagDetails from "../../../../../hocs/withSingleTagDetails";
import withStocksListSourceSetter from "../../../../../hocs/withStocksListSourceSetter";
import withAlertedTagDetails from "../../../../../hocs/withAlertedTagDetails";
import TagsHelper from "../../../../../lib/TagsHelper/TagsHelper";
import Loader from "../../Loader";
import PageTitle from "../../PageTitle";
import DesktopHeading from "../components/DesktopHeading";
import DesktopSettings from "../components/DesktopSettings";
import DesktopStocksTable from "../components/DesktopStocksTable";
import DesktopStocksGrid from "../components/DesktopStocksGrid";
import DesktopTagNotFound from "../components/DesktopTagNotFound";
import DesktopTagOnlyForRegisteredUsers from "../components/DesktopTagOnlyForRegisteredUsers";
import DesktopTagOnlyForPremiumUsers from "../components/DesktopTagOnlyForPremiumUsers";

import "../styles/default.scss";

@withStocksListSourceSetter
@withSingleTagDetails(
  true,
  Loader,
  DesktopTagNotFound,
  DesktopTagOnlyForRegisteredUsers,
  DesktopTagOnlyForPremiumUsers
)
@withAlertedTagDetails
class DesktopDetails extends React.Component {
  componentDidMount() {
    const { updateStocksListSource, tagDetails } = this.props;

    if (tagDetails && tagDetails.data && !isEmpty(tagDetails.data)) {
      updateStocksListSource(tagDetails.data);
    }
  }

  render() {
    const {
      tagDetails,
      alertedTagDetails,
      translate,
      settings,
      options,
      setSortBy,
      setViewType,
      setAdvancedView
    } = this.props;

    let { data } = tagDetails || {};

    const { viewType, advancedView } = settings;

    const isAlertedTag = TagsHelper.isAlertedTag(tagDetails.data);

    if (isAlertedTag) {
      data = alertedTagDetails.data;
    }

    if (!data) {
      return null;
    }

    return (
      <div className="desktop-details">
        <PageTitle
          title={`${translate("Desktop")} - ${translate(data.name)}`}
        />
        <DesktopHeading tag={data} />
        <DesktopSettings
          settings={settings}
          options={options}
          setSortBy={setSortBy}
          setViewType={setViewType}
          setAdvancedView={setAdvancedView}
          tagData={data}
        />
        <div className="desktop-content">
          {viewType === "table" ? (
            <DesktopStocksTable stocks={data.stocks} />
          ) : null}
          {viewType === "grid" ? (
            <DesktopStocksGrid
              stocks={data.stocks}
              advancedView={advancedView}
              settings={settings}
              key={settings.sortBy}
              isAlertedTag={isAlertedTag}
            />
          ) : null}
        </div>
      </div>
    );
  }
}

export default DesktopDetails;
