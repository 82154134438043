import React from "react";
import withQuoteData from "../../../../../../hocs/withQuoteData";

const AverageVolumeChangeFromFirebase = ({
  quoteData,
  resultMetaData: { average_volume }
}) => {
  if (
    !quoteData ||
    typeof average_volume === "undefined" ||
    average_volume === null
  ) {
    return null;
  }

  return <div>{Math.round((quoteData.volume / average_volume) * 100)}% </div>;
};

export default withQuoteData()(AverageVolumeChangeFromFirebase);
