import React from "react";
import withTranslations from "../../../../../../hocs/withTranslations";
import TransactionHelper from "../../../../../../lib/UniversalTable/RendererHelpers/TransactionHelper";

const CashBalanceChange = ({ resultMetaData, translate }) => {
  const transactionTypeName = TransactionHelper.getTransactionTypeName(
    "cash",
    resultMetaData.type
  );

  return (
    <div className="cash-balance-change">
      {transactionTypeName ? translate(transactionTypeName) : ""}
    </div>
  );
};

export default withTranslations(CashBalanceChange);
