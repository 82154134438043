import React from "react";
import PropTypes from "prop-types";

class ChangeAbstract extends React.Component {
  static propTypes = {
    change: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  };

  getChange() {
    const { change } = this.props;

    if (typeof change === "undefined" || change === null) {
      return null;
    }

    let newChange = Number(change) * 100;
    return newChange.toFixed(2);
  }

  getChangeRate() {
    let change = this.getChange();

    if (!change) {
      return null;
    }

    if (change > 0) {
      return 1;
    } else if (change < 0) {
      return -1;
    }
    return 0;
  }
}

export default ChangeAbstract;
