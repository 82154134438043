import classNames from "classnames";
import moment from "moment-mini";
import React, { useState } from "react";

import withUserData from "../../../../../hocs/withUserData";
import { getAuthorData } from "../../../../../lib/analysisHelper";
import ClickableContent from "../../ClickableContent";
import NoAccessDisclaimer from "../../NoAccessDisclaimer";

function Analysis({ analysis, translate, index, categoryDescription }) {
  const [isOpen, setIsIsOpen] = useState(index === 0);

  const hasContent = analysis.content !== null;

  return (
    <div key={analysis.id} className="squaber-team-stock-analysis">
      <div
        className="heading"
        onClick={() => {
          setIsIsOpen(!isOpen);
        }}
      >
        <div className="heading-text">
          {getAuthorData(analysis)}
          {moment(analysis.created).format("HH:mm DD.MM.YYYY")}{" "}
          {new Date(analysis.created).getTime() !==
          new Date(analysis.updated).getTime() ? (
            <span>
              {"("}
              {translate("updated")}:{" "}
              {moment(analysis.updated).format("HH:mm DD.MM.YYYY")}
              {")"}
            </span>
          ) : null}
        </div>
        <div className="heading-arrow">
          <i
            className={classNames({
              fal: true,
              "fa-angle-up": isOpen,
              "fa-angle-down": !isOpen
            })}
          />
        </div>
      </div>
      {isOpen ? (
        <React.Fragment>
          {hasContent ? (
            <ClickableContent
              className="content"
              dangerouslySetInnerHTML={{
                __html: analysis.content
              }}
            />
          ) : (
            <NoAccessDisclaimer />
          )}
          <small dangerouslySetInnerHTML={{ __html: categoryDescription }} />
        </React.Fragment>
      ) : null}
    </div>
  );
}

export default withUserData(Analysis);
