import "../styles/default.scss";

import classNames from "classnames";
import moment from "moment-mini";
import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";

import withAnalysisCategories from "../../../../../hocs/withAnalysisCategories";
import withManualStrategies from "../../../../../hocs/withManualStrategies";
import withTranslations from "../../../../../hocs/withTranslations";
import { STOCK_NOTIFICATION_TYPE } from "../../../../../redux/modules/notifications/saga";
import SquaberLink from "../../../common/SquaberLink";
import { notificationItemFactory } from "../notificationItemFactory";

const NotificationItem = ({
  item,
  markAsReadAction,
  onToggle,
  locale,
  analysisCategories,
  manualStrategies
}) => {
  const { blurred } = item;

  const { url = "#!", action, redirectTo } =
    notificationItemFactory({
      item,
      locale,
      analysisCategories,
      manualStrategies
    }) ?? {};

  return (
    <SquaberLink
      to={url}
      className={classNames({
        "notification-item": true,
        unread: !item.read
      })}
      onClick={async event => {
        if (blurred) {
          return;
        }

        if (!item.read) {
          markAsReadAction(item.id);
        }

        if (!event.ctrlKey && !event.metaKey) {
          onToggle();
        }

        if (item.content_type === STOCK_NOTIFICATION_TYPE) {
          return;
        }

        if (redirectTo) {
          window.open(redirectTo, "_blank");
        }

        if (action) {
          await action();
        }
      }}
    >
      <div className="item-content">
        {item.shortcut ? <div className="shortcut">{item.shortcut}</div> : null}
        <span
          className={classNames({
            blurred
          })}
        >
          {!blurred
            ? item.message
            : "Lorem Ipsum is simply dummy text of the printing and typesetting industry"}
        </span>
        <span className="time">
          {moment(item.date).format("YYYY-MM-DD HH:mm:SS")}
        </span>
      </div>
    </SquaberLink>
  );
};

NotificationItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    message: PropTypes.string,
    read: PropTypes.bool,
    date: PropTypes.string,
    shortcut: PropTypes.string
  }),
  onToggle: PropTypes.func
};

export default compose(
  withManualStrategies,
  withAnalysisCategories,
  withTranslations
)(NotificationItem);
