export default function({ stock, quote }) {
  return {
    label: `${stock.ticker} - ${stock.name}`,
    ticker: stock.ticker,
    name: stock.name,
    value: {
      ticker: stock.ticker,
      market: stock.market,
      stock: {
        id: stock.id
      }
    },
    price: quote.close
  };
}
