import React from "react";
import connect from "../i18n/connect";
import { userNotesRoutine } from "../redux/modules/userNotes";
import autoBind from "auto-bind";
import { debounce } from "lodash";

export default function withUserNote(WrappedComponent) {
  const mapDispatchToProps = {
    executeSaveUserNote: userNotesRoutine
  };

  @connect(null, mapDispatchToProps)
  class WithUserNote extends React.Component {
    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    debouncedExecuteSaveUserNote = debounce(this.executeSaveUserNote, 200);

    executeSaveUserNote(payload) {
      payload.text = payload.text.trim();
      this.props.executeSaveUserNote(payload);
    }

    saveUserNote(payload) {
      this.debouncedExecuteSaveUserNote.cancel();

      this.debouncedExecuteSaveUserNote(payload);
    }

    render() {
      return (
        <WrappedComponent {...this.props} saveUserNote={this.saveUserNote} />
      );
    }
  }

  return WithUserNote;
}
