import classNames from "classnames";
import Formsy from "formsy-react";
import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import decisionModal from "../../../../../lib/decisionModal";
import FormsyCheckbox from "../../../../../lib/FormBuilder/FormsyMaterialUI/FormsyCheckbox";
import FormsyComposedTextField from "../../../../../lib/FormBuilder/FormsyMaterialUI/FormsyComposedTextField";
import SquaberTooltip from "../../SquaberTooltip/";

@withTranslations
class TemplatesListItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEditing: false,
      form: {
        name: props.template.name,
        is_default: props.template.is_default
      }
    };
  }

  initForm() {
    const { template } = this.props;

    this.setState({
      form: {
        name: template.name,
        is_default: template.is_default
      }
    });
  }

  setIsEditing(value) {
    this.setState({
      isEditing: value
    });
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    if (
      prevProps.template.name !== this.props.template.name ||
      prevProps.template.is_default !== this.props.template.is_default
    ) {
      this.setIsEditing(false);
      this.initForm();
    }
  }

  getActions() {
    const {
      template,
      removeChartTemplate,
      fetchChartTemplateDetails,
      uploadChartTemplateToChart,
      updateChartTemplate,
      extractTemplateFromCurrentChartSettings
    } = this.props;

    const { isEditing, form } = this.state;

    if (!isEditing) {
      return (
        <React.Fragment>
          <SquaberTooltip title="tooltip-edit-template">
            <span
              className="edit"
              onClick={() => {
                this.setIsEditing(true);
              }}
            >
              <i className="fal fa-pencil" />
            </span>
          </SquaberTooltip>
          <SquaberTooltip title="tooltip-overwrite-template">
            <span
              className="overwrite"
              onClick={async () => {
                const decision = await decisionModal(
                  "ConfirmModal",
                  {
                    title: "Are you sure?",
                    message:
                      "Are you sure you want to overwrite this template with current chart settings?"
                  },
                  ["confirm", "cancel"],
                  {
                    confirm: "Overwrite template"
                  }
                );

                if (decision === "confirm") {
                  extractTemplateFromCurrentChartSettings(chartData => {
                    if (chartData) {
                      updateChartTemplate({
                        id: template.id,
                        settings: chartData
                      });
                    }
                  });
                }
              }}
            >
              <i className="fal fa-cloud-upload" />
            </span>
          </SquaberTooltip>
          <SquaberTooltip title="tooltip-apply-template">
            <span
              className="apply"
              onClick={async () => {
                const decision = await decisionModal(
                  "ConfirmModal",
                  {
                    title: "Are you sure?",
                    message:
                      "Are you sure you want to apply template settings to this chart?"
                  },
                  ["confirm", "cancel"],
                  {
                    confirm: "Apply template"
                  }
                );

                if (decision === "confirm") {
                  let settings = await fetchChartTemplateDetails({
                    id: template.id
                  });

                  if (settings.layout && settings.charts) {
                    settings = {
                      chart: settings
                    };
                  }

                  uploadChartTemplateToChart({
                    chart: settings.chart,
                    drawnObjectsOptions: settings.drawnObjectsOptions
                  });
                }
              }}
            >
              <i className="fal fa-cloud-download" />
            </span>
          </SquaberTooltip>
          <SquaberTooltip title="tooltip-remove-template">
            <span
              className="remove"
              onClick={() => {
                removeChartTemplate({ id: template.id });
              }}
            >
              <i className="fal fa-trash-alt" />
            </span>
          </SquaberTooltip>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <SquaberTooltip title="tooltip-apply-template-changes">
          <span
            className="apply-edit"
            onClick={() => {
              updateChartTemplate({
                id: template.id,
                ...form
              });
            }}
          >
            <i className="fal fa-check" />
          </span>
        </SquaberTooltip>
        <SquaberTooltip title="tooltip-discard-template-changes">
          <span
            className="abort-editing"
            onClick={() => {
              this.setIsEditing(false);
              this.initForm();
            }}
          >
            <i className="fal fa-times" />
          </span>
        </SquaberTooltip>
      </React.Fragment>
    );
  }

  setValue(key, value) {
    const { form } = this.state;

    form[key] = value;

    this.setState({ form });
  }

  render() {
    const { translate, template } = this.props;

    const { isEditing, form } = this.state;

    return (
      <li
        className={classNames({
          "is-editing": isEditing
        })}
      >
        <span className="basic-info">
          {isEditing ? (
            <Formsy>
              <FormsyComposedTextField
                name="template-name"
                label={translate("Template name")}
                value={form.name}
                fullWidth
                required
                maxLength={50}
                validationErrors={{
                  isDefaultRequiredValue: "This field is required"
                }}
                onChange={value => {
                  this.setValue("name", value);
                }}
              />
              <FormsyCheckbox
                label="Is default"
                name="template-is-default"
                value={form.is_default}
                onChange={value => this.setValue("is_default", value)}
              />
            </Formsy>
          ) : (
            <React.Fragment>
              <span className="template-name">{template.name}</span>
              {template.is_default ? (
                <span className="template-is-default">
                  {translate("Default template")}
                </span>
              ) : null}
            </React.Fragment>
          )}
        </span>
        <div className="actions">{this.getActions()}</div>
      </li>
    );
  }
}

export default TemplatesListItem;
