import { takeLatest, fork, put, delay, select } from "redux-saga/effects";
import { actions, constants, searchSymbolRoutine } from "./index";
import ApiClient from "../../../api/ApiClient";
import { getFetchMoreResultsUrl } from "./selector";
import wait from "../../../lib/wait";

function* onSearch({ payload: { phrase } }) {
  // debounce by 200ms
  yield delay(200);

  const limit = 20;

  if (phrase.length === 0) {
    yield put(actions.searchSuccess({ results: [] }));
    return;
  }

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.stock.fastSearch",
      variables: {
        phrase: phrase.trim(),
        limit
      }
    });

    yield put(actions.searchSuccess(data));
  } catch (error) {
    yield put(actions.searchError());

    console.error(error);
  }
}

function* onFetchMoreSearchResults() {
  const url = yield select(getFetchMoreResultsUrl);

  if (!url) {
    return;
  }

  try {
    const { data } = yield ApiClient.get({
      url
    });

    yield put(actions.fetchMoreSearchResultsSuccess(data));
  } catch (e) {
    console.log(e);
  }
}

function* onSearchSymbol({ payload: phrase }) {
  const limit = 0;

  if (phrase.length === 0) {
    yield put(searchSymbolRoutine.success([]));
    return;
  }

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.stock.paginatedSearch",
      variables: {
        phrase: phrase.trim(),
        limit
      }
    });

    const result = data.map(stock => ({
      symbol: stock.stock.ticker,
      full_name: stock.stock.name,
      description: stock.stock.basic_info.description || "",
      exchange: stock.stock.market,
      ticker: stock.stock.ticker,
      type: "stock"
    }));

    yield put(searchSymbolRoutine.success(result));
  } catch (error) {
    yield put(actions.searchError());

    console.error(error);
  }
}

function* watchSearch() {
  yield takeLatest(constants.SEARCH, onSearch);
  yield takeLatest(
    constants.FETCH_MORE_SEARCH_RESULTS,
    onFetchMoreSearchResults
  );
  yield takeLatest(searchSymbolRoutine.TRIGGER, onSearchSymbol);
}

export default [fork(watchSearch)];
