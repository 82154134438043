export const getStockData = state => state.stockPageTvChart.stock.data;
export const getStockError = state => state.stockPageTvChart.stock.error;
export const getStockLoading = state => state.stockPageTvChart.stock.loading;
export const getStockFullName = state => {
  const stockFullName = state?.stockPageTvChart?.stock?.data?.stock?.name;

  const hasStockFullName =
    stockFullName && !state.stockPageTvChart.stock.loading;

  if (hasStockFullName) {
    return stockFullName;
  }

  return null;
};
export const getStockCurrency = state => {
  const currency = state.stockPageTvChart?.stock?.data?.stock?.currency;

  const hasCurrency = currency && !state.stockPageTvChart.stock.loading;

  if (hasCurrency) {
    return currency;
  }

  return "";
};
export const getStockId = state =>
  state.stockPageTvChart?.stock?.data?.stock?.id;
