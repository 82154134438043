import React from "react";
import { connect } from "../i18n/connect";
import {
  clearValidationError,
  registerRoutine
} from "../redux/modules/auth/register";
import withFormBuilder from "./withFormBuilder";
import {
  getRegisterLoading,
  getRegisterValidationErrors
} from "../redux/modules/auth/register/selector";
import { getUserData } from "../redux/modules/auth/login/selector";
import config from "../config/";

export default function withRegisterForm(WrappedComponent) {
  const mapStateToProps = state => ({
    userData: getUserData(state),
    registerLoading: getRegisterLoading(state),
    validationErrors: getRegisterValidationErrors(state)
  });

  const mapDispatchToProps = {
    // The routine itself is trigger action creator
    register: registerRoutine.trigger,
    clearValidationError
  };

  const fields = [
    {
      name: "email",
      label: "E-mail",
      type: "text",
      required: true,
      fullWidth: true,
      validations: {
        isEmail: true
      },
      validationErrors: {
        isEmail: "Your email seems to be invalid",
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "password",
      value: "",
      type: "password",
      required: true,
      fullWidth: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      label: "Password"
    },
    {
      name: "password_confirm",
      value: "",
      type: "password",
      required: true,
      fullWidth: true,
      validations: {
        equalsField: "password"
      },
      validationErrors: {
        equalsField: "Your password doesn't match",
        isDefaultRequiredValue: "This field is required"
      },
      label: "Repeat password"
    },
    {
      name: "all_agreements",
      type: "checkbox-group",
      fields: ["terms", "personal_data_processing", "marketing_emails"],
      label: "Select all agreements"
    },
    {
      name: "terms",
      selected: [],
      type: "checkbox",
      required: true,
      options: [
        {
          label: "Terms and conditions agreement",
          value: "terms",
          translateData: {
            termsAndConditionsUrl: config.termsAndConditionsUrl,
            privacyPolicyUrl: config.privacyPolicyUrl
          }
        }
      ]
    },
    {
      name: "personal_data_processing",
      selected: [],
      type: "checkbox",
      options: [
        {
          label: "Personal data processing agreement",
          value: "personal_data_processing",
          expands: true,
          expandedLabel: "Personal data processing agreement expanded"
        }
      ]
    },
    {
      name: "marketing_emails",
      selected: [],
      type: "checkbox",
      options: [
        {
          label: "Electronic communication agreement",
          value: "marketing_emails",
          expands: true,
          expandedLabel: "Electronic communication agreement expanded"
        }
      ]
    }
  ];

  @connect(mapStateToProps, mapDispatchToProps)
  @withFormBuilder(fields)
  class WithRegisterForm extends React.Component {
    state = {};

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithRegisterForm;
}
