import React from "react";

import TemplatesListItem from "./TemplatesListItem";

class TemplatesList extends React.Component {
  render() {
    const {
      templates,
      removeChartTemplate,
      fetchChartTemplateDetails,
      uploadChartTemplateToChart,
      extractTemplateFromCurrentChartSettings,
      updateChartTemplate
    } = this.props;

    return templates.length ? (
      <ul className="chart-templates-list">
        {templates.map(template => (
          <TemplatesListItem
            template={template}
            key={template.id}
            removeChartTemplate={removeChartTemplate}
            fetchChartTemplateDetails={fetchChartTemplateDetails}
            uploadChartTemplateToChart={uploadChartTemplateToChart}
            extractTemplateFromCurrentChartSettings={
              extractTemplateFromCurrentChartSettings
            }
            updateChartTemplate={updateChartTemplate}
          />
        ))}
      </ul>
    ) : null;
  }
}

export default TemplatesList;
