import React from "react";
import connect from "../i18n/connect";
import { toggleAlert } from "../redux/modules/stockData";

export default function withToggleAlert(WrappedComponent) {
  const mapDispatchToProps = {
    toggleAlert
  };

  @connect(null, mapDispatchToProps)
  class WithToggleAlert extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithToggleAlert;
}
