import { isObject } from "lodash";

export default class ConsentModeHelperAbstract {
  static STORAGE_ITEM_NAME = "squaber_cookie_consent";

  static consentOptions = [
    "functionality_storage",
    "security_storage",
    "ad_user_data",
    "ad_personalization",
    "ad_storage",
    "analytics_storage",
    "personalization_storage"
  ];

  static validConsentValues = ["denied", "granted"];

  static consentEnabledByDefault = [
    "functionality_storage",
    "security_storage"
  ];

  static applyDefaultConsent() {
    throw new Error("Not implemented");
  }

  static attemptLoadConsentSettings() {
    throw new Error("Not implemented");
  }

  static applyConsentSettings(settings) {
    throw new Error("Not implemented");
  }

  static validateSettings(settings) {
    const isSettingsObject = !!settings && isObject(settings);
    let isSettingsKeysValid = true;
    let isSettingsValuesValid = true;

    if (!isSettingsObject) {
      return false;
    }

    for (const key of Object.keys(settings)) {
      if (ConsentModeHelperAbstract.consentOptions.indexOf(key) === -1) {
        isSettingsKeysValid = false;
        break;
      }
    }

    for (const value of Object.values(settings)) {
      if (ConsentModeHelperAbstract.validConsentValues.indexOf(value) === -1) {
        isSettingsValuesValid = false;
        break;
      }
    }

    return isSettingsKeysValid && isSettingsValuesValid;
  }
}
