import { createAction, handleActions } from "redux-actions";

const SET_STOCK = "app/preloadedStockPageData/SET_PRELOADED_STOCK";
export const setPreloadedStock = createAction(
  "app/preloadedStockPageData/SET_PRELOADED_STOCK",
  (preloadedStock: {
    name: string,
    id: number,
    ticker: string,
    currency: string
  }) => preloadedStock
);
export const initialState = {
  stock: {
    loading: false,
    data: { name: null, currency: null, ticker: null, id: null }
  }
};

export default handleActions(
  {
    [SET_STOCK]: (state, { payload }) => ({
      ...state,
      stock: {
        ...state.stock,
        data: payload,
        loading: false
      }
    })
  },
  initialState
);
