import "../styles/default.scss";

import PropTypes from "prop-types";
import React, { useEffect, useRef } from "react";
import { compose } from "redux";

import withEmailConfirmation from "../../../../../hocs/withEmailConfirmation";
import withTranslations from "../../../../../hocs/withTranslations";
import withUserData from "../../../../../hocs/withUserData";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import useMobileMediaQuery from "../../../../hooks/useMobileMediaQuery";
import SquaberButton from "../../SquaberButton";

const VerifyEmailBar = ({
  onHeightChange,
  translate,
  userData,
  resendConfirmationEmail
}) => {
  const isMobile = useMobileMediaQuery();
  const wrapperRef = useRef(null);
  const canDisplayVerifyEmailBar =
    isMobile && userData && !userData.email_validated;

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      for (const entry of entries) {
        const { height } = entry.contentRect;
        onHeightChange?.(height);
      }
    });

    if (wrapperRef.current) {
      observer.observe(wrapperRef.current);
    }

    return () => {
      if (wrapperRef.current) {
        observer.unobserve(wrapperRef.current);
      }
    };
  }, [onHeightChange]);

  if (!canDisplayVerifyEmailBar) {
    return null;
  }

  return (
    <button
      ref={wrapperRef}
      className={"verify-email-bar__wrapper"}
      onClick={() => {
        showModal("ModalBase", {
          title: translate("verify_email_bar_message"),
          message: (
            <>
              {translate(
                "email-confirmation-notification-part-1 %{email_address}",
                {
                  email_address: userData.email
                }
              )}
              <SquaberButton
                buttonStyle={false}
                onClick={() => {
                  resendConfirmationEmail();
                }}
              >
                {translate("click here")}
              </SquaberButton>
              {translate("email-confirmation-notification-part-2")}
            </>
          ),
          showCancelButton: false,
          classNameContentText: "verify-email-bar__modal-text",
          translate
        });
      }}
    >
      <div className={"verify-email-bar__text"}>
        {translate("verify_email_bar_message")}
      </div>
    </button>
  );
};

VerifyEmailBar.propTypes = { onHeightChange: PropTypes.func };

export default compose(
  withTranslations,
  withUserData,
  withEmailConfirmation
)(VerifyEmailBar);
