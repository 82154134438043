import React from "react";
import PropTypes from "prop-types";
import StockPageSection from "../../StockPageSection";
import AutomaticTransactionSignal from "../../AutomaticTransactionSignal";
import { getSectionSlugBySectionId } from "../../../../../lib/getSectionSlugBySectionId";

const TransactionSignalSections = ({
  strategies,
  signals,
  currency,
  stockId,
  userStock,
  preparedSlugList,
  ticker,
  market
}) => (
  <React.Fragment>
    {strategies.map(strategy => (
      <StockPageSection
        ticker={ticker}
        market={market}
        key={strategy.id}
        title={strategy.name}
        sectionId={strategy.notifications_unique_id}
        isPremium
        slug={getSectionSlugBySectionId(
          preparedSlugList,
          strategy.notifications_unique_id
        )}
      >
        <AutomaticTransactionSignal
          strategy={strategy}
          signals={signals[strategy.id]}
          currency={currency}
          stockId={stockId}
          userStock={userStock}
        />
      </StockPageSection>
    ))}
  </React.Fragment>
);

TransactionSignalSections.propTypes = {
  strategies: PropTypes.array,
  signals: PropTypes.object,
  stockId: PropTypes.number
};

export default TransactionSignalSections;
