import React from "react";
import YellowSwitch from "../../../../../lib/SquaberThemeComponents/YellowSwitch";
import withUserData from "../../../../../hocs/withUserData";

const MarketingEmailsSettings = ({
  translate,
  userData,
  toggleMarketingEmails
}) => (
  <div>
    <h2>{translate("Marketing emails")}</h2>
    <div className="marketing-emails-agreement">
      <p>
        {translate(
          "Do you want to receive educational e-mails, webinars invitations and other marketing communication?"
        )}
      </p>
      <YellowSwitch
        checked={userData.marketing_emails}
        onChange={() => {
          toggleMarketingEmails();
        }}
        value="marketing_emails"
        color="primary"
      />
    </div>
  </div>
);

MarketingEmailsSettings.propTypes = {};

export default withUserData(MarketingEmailsSettings);
