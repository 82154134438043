import React from "react";

import connect from "../i18n/connect";
import { getStockData } from "../redux/modules/stockPageTvChart/selector";
import withQuoteData from "./withQuoteData";

export default function withStockBasicInfo(WrappedComponent) {
  const mapStateToProps = state => ({
    stock: getStockData(state)
  });

  @connect(mapStateToProps)
  @withQuoteData(false, props => props.stock?.stock?.id)
  class WithStockBasicInfo extends React.Component {
    getBasicInfo() {
      const defaultBasicInfo = {};

      const { stock, quoteData } = this.props;

      const hasBasicInfo = typeof stock?.stock?.basic_info === "object";

      if (hasBasicInfo) {
        const basicInfo = stock.stock.basic_info;

        const { share_amount } = basicInfo || {};
        const { close } = quoteData || {};
        const capitalization = Math.round(share_amount * close);
        return isNaN(capitalization)
          ? basicInfo
          : { ...basicInfo, capitalization };
      }

      return defaultBasicInfo;
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          stockBasicInfo={this.getBasicInfo()}
        />
      );
    }
  }

  return WithStockBasicInfo;
}
