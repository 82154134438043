import React from "react";
import { bindPromiseCreators } from "redux-saga-routines";

import connect from "../i18n/connect";
import {
  fetchManualStrategies,
  fetchManualStrategiesRoutinePromiseCreator
} from "../redux/modules/manualStrategies";
import { getManualStrategies } from "../redux/modules/manualStrategies/selector";

export default function withManualStrategies(WrappedComponent) {
  const mapStateToProps = state => ({
    manualStrategies: getManualStrategies(state)
  });

  const mapDispatchToProps = {
    fetchManualStrategies
  };

  const additionalMapDispatchToProps = dispatch => ({
    ...bindPromiseCreators(
      {
        fetchManualStrategiesAsync: fetchManualStrategiesRoutinePromiseCreator
      },
      dispatch
    )
  });

  @connect(mapStateToProps, mapDispatchToProps)
  @connect(null, additionalMapDispatchToProps)
  class WithManualStrategies extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithManualStrategies;
}
