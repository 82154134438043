const consolidateDividends = dividends => {
  const payoutDayMap = new Map();

  for (const dividend of dividends) {
    const { dividend_day, dividend_yield, payout, payout_day } = dividend;
    const numericDividendYield =
      dividend_yield !== null ? parseFloat(dividend_yield) : 0;

    if (payoutDayMap.has(dividend_day)) {
      const existing = payoutDayMap.get(dividend_day);
      existing.dividend_yield += numericDividendYield;
      existing.payouts.push({ payout, payout_day });
    } else {
      payoutDayMap.set(dividend_day, {
        ...dividend,
        dividend_yield: numericDividendYield,
        payouts: [{ payout, payout_day }]
      });
    }
  }

  return Array.from(payoutDayMap.values());
};

export default consolidateDividends;
