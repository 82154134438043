import React from "react";
import autoBind from "auto-bind";
import { isEqual } from "lodash";
import { UniversalTableQuery } from "../lib/UniversalTable/UniversalTableQueryBuilder";
import ApiClientAsync from "../api/ApiClientAsync";
import ResourceResolver from "../lib/UniversalTable/ResourceResolver";
import withTranslations from "./withTranslations";
import withUserData from "./withUserData";

export default function withTagStocksData(
  chunkSize = 6,
  stocksExtractor = props => props.stocks
) {
  return WrappedComponent => {
    const csvDataId = ResourceResolver["desktop_stocks_list"];
    const tabId = ResourceResolver["desktop_stocks_list_tab"];

    @withTranslations
    @withUserData
    class WithTagStocksData extends React.Component {
      state = {
        results: [],
        next: null,
        loading: true,
        initialLoading: true,
        stocks: []
      };

      constructor(props) {
        super(props);

        autoBind.react(this);
      }

      applyStocksFromProps() {
        const { stocks } = this.state;

        const newStocks = stocksExtractor(this.props);

        if (!isEqual(stocks, newStocks)) {
          this.setState(
            {
              stocks: newStocks
            },
            async () => {
              await this.fetchData(true);
            }
          );
        }
      }

      async componentDidMount() {
        const { locale, authToken } = this.props;

        this.applyStocksFromProps();

        this.apiClient = new ApiClientAsync(locale, authToken);

        await this.fetchData(true);
      }

      componentDidUpdate() {
        this.applyStocksFromProps();
      }

      async fetchData(initial = false) {
        this.setLoading(true);

        if (initial) {
          this.setInitialLoading(true);
        }

        const { settings } = this.props;
        const { stocks } = this.state;

        let sortBy = settings ? settings.sortBy : null;

        if (!sortBy) {
          sortBy = "ticker";
        }

        const { next: nextPageUrl } = this.state;

        if ((!initial && !nextPageUrl) || !stocks.length) {
          this.setState({
            results: []
          });

          this.setLoading(false);
          return;
        }

        let data;

        if (initial) {
          const stockIds = stocks.map(stock => {
            if (typeof stock === "object") {
              return stock.id;
            }

            return stock;
          });
          const query = new UniversalTableQuery();

          query
            .addOrdering({
              field: sortBy,
              direction: "asc"
            })
            .addLimit({
              rowsPerPage: chunkSize
            })
            .addFilters(
              {
                stock_id: stockIds
              },
              []
            );

          data = await this.apiClient.get({
            urlPath: "app.csvData.tabWithOptions",
            variables: {
              id: csvDataId,
              tabId,
              options: query.toString()
            }
          });
        } else {
          data = await this.apiClient.get({
            url: nextPageUrl
          });
        }

        const { results, next } = data;

        if (initial) {
          this.setState({
            results,
            next
          });
        } else {
          this.setState(({ results: oldResults }) => ({
            results: [...oldResults, ...results],
            next
          }));
        }

        this.setLoading(false);
      }

      async fetchMore() {
        await this.fetchData();
      }

      setLoading(value) {
        this.setState({
          loading: value
        });

        if (value === false) {
          this.setInitialLoading(value);
        }
      }

      setInitialLoading(value) {
        this.setState({
          initialLoading: value
        });
      }

      render() {
        const { results, loading, initialLoading, next } = this.state;

        return (
          <WrappedComponent
            {...this.props}
            results={results}
            loading={loading}
            initialLoading={initialLoading}
            next={next}
            fetchMore={this.fetchMore}
          />
        );
      }
    }

    return WithTagStocksData;
  };
}
