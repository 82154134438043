import "../styles/default.scss";

import autoBind from "auto-bind";
import React from "react";

import withChartAnalysis from "../../../../../hocs/withChartAnalysis";
import TemplatesList from "../../TemplatesList";
import AddAnalysis from "../components/AddAnalysis";
import EditAnalysis from "../components/EditAnalysis";

@withChartAnalysis
class TVChartAnalysisOptions extends React.Component {
  MODE_BROWSE = "browse";
  MODE_ADD = "add";

  state = {
    mode: this.MODE_BROWSE
  };

  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  setMode(mode) {
    this.setState({
      mode
    });
  }

  getModeSwitcherIcon() {
    const { mode } = this.state;

    switch (mode) {
      case this.MODE_BROWSE:
        return <i className="fal fa-plus" />;

      case this.MODE_ADD:
      default:
        return <i className="far fa-list" />;
    }
  }

  toggleMode() {
    const { mode } = this.state;

    const nextMode = mode === this.MODE_ADD ? this.MODE_BROWSE : this.MODE_ADD;

    this.setMode(nextMode);
  }

  componentDidMount() {
    const { clearChartAnalysis } = this.props;

    clearChartAnalysis();
  }

  render() {
    const {
      chartAnalysesState,
      addChartAnalysisAsync,
      deleteChartAnalysis,
      fetchChartAnalysesDetails,
      uploadChartTemplateToChart,
      extractTemplateFromCurrentChartSettings,
      updateChartAnalysis,
      clearErrorMessages,
      translate
    } = this.props;

    const { mode } = this.state;

    const fetchChartAnalyses = async ({ id: currentId }) => {
      const { url } =
        chartAnalysesState?.list?.find(({ id }) => id === currentId) ?? {};

      return await fetchChartAnalysesDetails(url);
    };

    const sharedAnalytics =
      chartAnalysesState?.list?.filter(({ is_public }) => is_public) ?? [];

    const userAnalytics =
      chartAnalysesState?.list?.filter(({ is_public }) => !is_public) ?? [];

    return (
      <div className="tv-chart-options-window wide chart-templates-options">
        <h4>
          <span className="title">{translate("Analytics")}</span>
          <span
            className="mode-switcher"
            onClick={() => {
              this.toggleMode();
            }}
          >
            {this.getModeSwitcherIcon()}
          </span>
          <span className="close-button" onClick={() => this.props.close()}>
            <i className="fal fa-times" />
          </span>
        </h4>
        {mode === this.MODE_BROWSE ? (
          <div>
            <p>{translate("shared_analyzes")}</p>
            <TemplatesList
              templates={sharedAnalytics}
              fetchChartAnalyses={fetchChartAnalyses}
              uploadChartTemplateToChart={uploadChartTemplateToChart}
              extractTemplateFromCurrentChartSettings={
                extractTemplateFromCurrentChartSettings
              }
              fetchChartTemplateDetails={fetchChartAnalyses}
              addChartAnalysisAsync={addChartAnalysisAsync}
              editable={false}
              translateNamespace="analysis"
            />
            <hr />
            <p>{translate("your_analyses")}</p>
            <TemplatesList
              templates={userAnalytics}
              updateChartTemplate={updateChartAnalysis}
              removeChartTemplate={deleteChartAnalysis}
              fetchChartTemplateDetails={fetchChartAnalyses}
              uploadChartTemplateToChart={uploadChartTemplateToChart}
              hideDefault
              translateNamespace="analysis"
              addChartTemplateErrors={chartAnalysesState.addChartAnalysisErrors}
              EditTemplate={EditAnalysis}
            />
          </div>
        ) : null}
        {mode === this.MODE_ADD ? (
          <AddAnalysis
            toggleMode={this.toggleMode}
            addChartTemplateAsync={addChartAnalysisAsync}
            addChartTemplateErrors={chartAnalysesState.addChartAnalysisErrors}
            extractTemplateFromCurrentChartSettings={
              extractTemplateFromCurrentChartSettings
            }
            clearErrorMessages={clearErrorMessages}
          />
        ) : null}
      </div>
    );
  }
}

export default TVChartAnalysisOptions;
