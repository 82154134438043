import "../styles/default.scss";

import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import withStyles from "@material-ui/core/styles/withStyles";
import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withNotifications from "../../../../../hocs/withNotifications";
import withTranslations from "../../../../../hocs/withTranslations";
import withUserData from "../../../../../hocs/withUserData";
import theme from "../../../../../theme";
import VerifyEmailBar from "../../../common/VerifyEmailBar";
import MobileNavigationIcon from "../../MobileNavigationIcon";

const styles = {
  root: {
    width: "100%",
    position: "fixed",
    left: 0,
    bottom: 0,
    zIndex: 1300,
    ...theme.navigation.tabBar,
    [`@media(min-width: ${theme.breakpoints.screen_md}px)`]: {
      display: "none"
    }
  },
  action: {
    outline: "none !important",
    minWidth: 64,
    paddingTop: "9px !important"
  },
  actionLabel: {
    ...theme.navigation.label,
    fontSize: "0.5rem !important",
    paddingTop: "3px !important"
  },
  actionLabelSelected: {
    color: `${theme.navigation.labelColor.active} !important`
  }
};

@withTranslations
@withStyles(styles)
@withBottomNavigation()
@withNotifications
@withUserData
@withRouter
class MobileNavigation extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      verifyEmailBarHeight: 0
    };
  }

  handleChange = (event, value) => {
    const { history, locale } = this.props;

    if (value === "analysisList") {
      history.push(UrlProvider.getUrl("fe.analysisList", { locale }));
    }

    this.props.setValue(value);
  };

  render() {
    const { value, unreadCount, classes, translate, userData } = this.props;

    if (window.ReactNativeWebView) {
      return null;
    }

    const elements = userData
      ? [
          {
            icon: "list",
            label: translate("Stocks lists"),
            value: "watched"
          },
          {
            icon: "bell",
            label: translate("Notifications"),
            value: "notifications",
            unreadCount: unreadCount > 99 ? "99+" : unreadCount
          },
          {
            icon: "chart-line",
            label: translate("Analysis"),
            value: "analysisList"
          },
          {
            icon: "wallet",
            label: translate("Portfolios"),
            value: "portfolios"
          },
          {
            icon: "bars",
            label: translate("Menu"),
            value: "menu"
          }
        ]
      : [
          {
            icon: "list",
            label: translate("Stocks lists"),
            value: "watched"
          },
          {
            icon: "bell",
            label: translate("Notifications"),
            value: "notifications",
            unreadCount: unreadCount > 99 ? "99+" : unreadCount
          },
          {
            icon: "chart-line",
            label: translate("Analysis"),
            value: "analysisList"
          },
          {
            icon: "user",
            label: translate("Account"),
            value: "user",
            unreadCount: userData && !userData.email_validated ? 1 : 0
          },

          {
            icon: "bars",
            label: translate("Menu"),
            value: "menu"
          }
        ];

    const navigationClassName = classNames({
      [classes.root]: true,
      "squaber-bottom-navigation": true
    });

    return (
      <>
        <BottomNavigation
          value={value}
          onChange={this.handleChange}
          showLabels
          className={navigationClassName}
          style={{ bottom: this.state.verifyEmailBarHeight }}
        >
          {elements.map((element, index) => (
            <BottomNavigationAction
              key={index}
              value={element.value}
              classes={{
                root: classes.action,
                label: classes.actionLabel,
                selected: classes.actionLabelSelected
              }}
              showLabel
              label={element.label}
              icon={
                <MobileNavigationIcon
                  {...element}
                  isLast={index + 1 === elements.length}
                />
              }
            />
          ))}
        </BottomNavigation>
        <VerifyEmailBar
          onHeightChange={height => {
            this.setState({ verifyEmailBarHeight: height });
          }}
        />
      </>
    );
  }
}

export default MobileNavigation;
