import React from "react";
import { bindPromiseCreators } from "redux-saga-routines";
import connect from "../i18n/connect";
import {
  deleteSingleTagRoutinePromiseCreator,
  editSingleTagDetailsRoutinePromiseCreator,
  fetchAllTagsRoutine
} from "../redux/modules/tags";

export default function withEditOrDeleteSingleTag(WrappedComponent) {
  const mapDispatchToProps = dispatch => ({
    ...bindPromiseCreators(
      {
        editSingleTag: editSingleTagDetailsRoutinePromiseCreator,
        deleteSingleTag: deleteSingleTagRoutinePromiseCreator
      },
      dispatch
    )
  });

  const regularMapDispatchToProps = {
    fetchAllTags: fetchAllTagsRoutine
  };

  @connect(null, mapDispatchToProps)
  @connect(null, regularMapDispatchToProps)
  class WithEditOrDeleteSingleTag extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithEditOrDeleteSingleTag;
}
