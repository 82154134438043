export default function buildRedirectUrl(url, login_token, userId) {
  if (url.indexOf("?") === -1) {
    url += "?";
  } else {
    url += "&";
  }

  url += "authLoginToken=" + login_token;
  url += "&authUserId=" + userId;

  return url;
}
