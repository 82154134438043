import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";
import { stockDataRoutine } from "../stockData";

export const fetchFinancialSituation = createRoutine(
  "app/financialSituation/FETCH_FINANCIAL_SITUATION"
);

const initialState = {
  loading: false,
  data: {},
  error: null
};

export default handleActions(
  {
    [fetchFinancialSituation.REQUEST]: state => ({
      ...state,
      loading: true
    }),
    [fetchFinancialSituation.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload,
      error: null
    }),
    [fetchFinancialSituation.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    [stockDataRoutine.TRIGGER]: () => initialState
  },
  initialState
);
