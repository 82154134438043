import React from "react";
import * as Actions from "./Actions/";
import snakeToPascal from "../../../../../lib/snakeToPascal";

export default ({ value, ...props }) => {
  if (!value) {
    return null;
  }

  const actionName = snakeToPascal(value);
  const Action = Actions[actionName];

  if (!Action) {
    return null;
  }

  return (
    <div className="action-renderer">
      <Action {...props} />
    </div>
  );
};
