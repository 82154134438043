import React from "react";
import withGlobalConfigFields from "../../../../../hocs/withGlobalConfigFields";
import { globalConfigFields } from "../../../../../redux/modules/globalConfig";
import ContentSection from "../../../common/ContentSection";

function UpcomingFeatures({ globalConfig: { upcomingFeatures: content } }) {
  return <ContentSection dangerouslySetInnerHTML={{ __html: content }} />;
}

export default withGlobalConfigFields([globalConfigFields.UPCOMING_FEATURES])(
  UpcomingFeatures
);
