import React from "react";
import { connect } from "../i18n/connect";
import {
  getMarketingBars,
  getMarketingBarsHidden
} from "../redux/modules/marketingBars/selector";
import { getMarketingBarsSeen } from "../redux/modules/marketingBarsSeen/selector";
import { setMarketingBarSeen } from "../redux/modules/marketingBarsSeen";
import { setMarketingBarsHidden } from "../redux/modules/marketingBars";

export default function withMarketingBars(WrappedComponent) {
  const mapStateToProps = state => ({
    marketingBars: getMarketingBars(state),
    marketingBarsSeen: getMarketingBarsSeen(state),
    marketingBarsHidden: getMarketingBarsHidden(state)
  });

  const mapDispatchToProps = {
    setMarketingBarSeen,
    setMarketingBarsHidden
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithMarketingBars extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithMarketingBars;
}
