import React from "react";

import { connect } from "../i18n/connect";
import {
  clickCtaMarketingMessageRoutine,
  dismissMarketingMessageTopBarRoutine
} from "../redux/modules/marketingMessages";
import {
  getMarketingMessagesOverlay,
  getMarketingMessagesTopBar,
  getMarketingMessageTopBarDismissed
} from "../redux/modules/marketingMessages/selector";

export default function withMarketingMessages(WrappedComponent) {
  const mapStateToProps = state => ({
    marketingMessagesTopBar: getMarketingMessagesTopBar(state),
    marketingMessagesOverlay: getMarketingMessagesOverlay(state),
    marketingMessageTopBarDismissed: getMarketingMessageTopBarDismissed(state)
  });

  const mapDispatchToProps = {
    dismissMarketingMessageTopBar: dismissMarketingMessageTopBarRoutine,
    clickCtaMarketingMessage: clickCtaMarketingMessageRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithMarketingMessages extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithMarketingMessages;
}
