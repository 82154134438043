import React from "react";
import ApiHelper from "../lib/UniversalTable/CoreHelpers/ApiHelper";

export default class SingleRangeFilterAbstract extends React.Component {
  state = {
    errors: {
      min: null,
      max: null
    }
  };

  handleChange(field, value) {
    const { value: currentValue, onChange } = this.props;

    let newValue = {
      min: null,
      max: null,
      ...(currentValue || {})
    };

    newValue[field] = value;

    onChange(newValue);

    return newValue;
  }

  validateValues(providedValue) {
    let { value: currentValue, translate, onChange, column } = this.props;
    let { errors } = this.state;

    currentValue = {
      ...(currentValue || {}),
      ...(providedValue || {})
    };

    let newValue = {
      min: null,
      max: null,
      ...(currentValue || {})
    };

    for (let key in currentValue) {
      if (currentValue.hasOwnProperty(key)) {
        if (currentValue[key] === null) {
          errors[key] = null;
          newValue[key] = null;
        } else if (
          currentValue[key] !== null &&
          ApiHelper.getIsDateRange(column)
        ) {
          errors[key] = null;
          newValue[key] = currentValue[key];
        } else if (
          currentValue[key] !== null &&
          (!isNaN(currentValue[key]) ||
            !isNaN(String(currentValue[key]).replace(",", ".")))
        ) {
          errors[key] = null;
          newValue[key] = Number(String(currentValue[key]).replace(",", "."));
        } else if (isNaN(currentValue[key]) && currentValue[key] !== null) {
          errors[key] = translate("This value should be a number");
        }
      }
    }

    onChange(newValue);

    this.setState({ errors });
  }

  getValue(field) {
    const {
      column: { filter_data },
      value
    } = this.props;

    return value && typeof value[field] !== "undefined" && value[field] !== null
      ? value[field]
      : filter_data[field];
  }

  getValueExists(field) {
    const { value } = this.props;

    return value && typeof value[field] !== undefined && value[field] !== null;
  }

  clearValue(field) {
    const valueAfterClearing = this.handleChange(field, null);
    this.validateValues(valueAfterClearing);
  }

  getValueIsInvalid() {
    const { value } = this.props;

    let min = this.getValue("min");
    let max = this.getValue("max");

    return value && min > max;
  }
}
