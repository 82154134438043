import { takeLatest, fork, put } from "redux-saga/effects";
import ApiClient from "../../../api/ApiClient";
import {
  FETCH_GLOBAL_CONFIG,
  globalConfigFields,
  setGlobalConfigValue
} from "./index";

function* fetchAndSaveGlobalConfigField(field) {
  const urlPath = `app.config.${field}`;

  try {
    const { data } = yield ApiClient.get(urlPath);

    yield put(setGlobalConfigValue(field, data[field]));
  } catch (error) {
    console.log(error);
  }
}

function* onFetchGlobalConfig() {
  const fields = Object.values(globalConfigFields);

  for (let field of fields) {
    yield fetchAndSaveGlobalConfigField(field);
  }
}

function* watchFetchGlobalConfig() {
  yield takeLatest(FETCH_GLOBAL_CONFIG, onFetchGlobalConfig);
}

export default [fork(watchFetchGlobalConfig)];
