/* global window */
import "firebase/compat/database";

import firebase from "firebase/compat/app";
import { createBrowserHistory } from "history";
import { routerMiddleware as createRouterMiddleware } from "react-router-redux";
import { applyMiddleware, compose, createStore } from "redux";
import { persistCombineReducers, persistStore } from "redux-persist";
import { createFilter } from "redux-persist-transform-filter";
import createSagaMiddleware from "redux-saga";

import config from "../config";
import envVariables from "../lib/envHelpers/getEnvVariables";
import { getIsWeb } from "../lib/platformHelper";
import reducers from "./rootReducers";
import sagas from "./sagas";

const storage = getIsWeb()
  ? require("redux-persist/lib/storage").default
  : require("@react-native-async-storage/async-storage").default;

export const history = getIsWeb() ? createBrowserHistory() : null;

// Redux Persist config
const persistorConfig = {
  key: "root",
  storage,
  whitelist: [
    "auth",
    "browsedPagesCount",
    "collapsibleSections",
    "expoUpdate",
    "followed",
    "marketingBarsSeen",
    "partnershipTokenElementVisible",
    "seenNotificationModal",
    "tickerAttention",
    "translations"
  ],
  transforms: [
    createFilter("auth", ["login"]),
    createFilter("browsedPagesCount", ["lastModalWasShownAt"]),
    createFilter("expoUpdate", ["previousVersion"]),
    createFilter("followed.data"),
    createFilter("translations.translations")
  ]
};

const firebaseConfig = {
  apiKey: config.firebase.apiKey,
  authDomain: config.firebase.authDomain,
  databaseURL: config.firebase.databaseURL,
  storageBucket: config.firebase.storageBucket
};

if (!firebase.apps.length) {
  try {
    firebase.initializeApp(firebaseConfig);
  } catch (error) {
    console.log("Firebase error on initialization:");
    console.log(error);
  }
}

const reducer = persistCombineReducers(persistorConfig, reducers());

const configureStore = () => {
  let composeEnhancer;

  if (envVariables.NODE_ENV !== "production") {
    composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  } else {
    composeEnhancer = compose;
  }

  const sagaMiddleware = createSagaMiddleware();
  const routerMiddleware = createRouterMiddleware(history);

  const middleware = [sagaMiddleware, routerMiddleware];

  const store = createStore(
    reducer,
    composeEnhancer(applyMiddleware(...middleware))
  );

  sagaMiddleware.run(sagas);

  const persistor = persistStore(store, null, () => {
    store.getState();
  });

  return { persistor, store };
};

const { persistor, store } = configureStore();
export { persistor, store };
export const Firebase = firebase;
