import React from "react";
import connect from "../i18n/connect";
import { getStockTags } from "../redux/modules/tags/selector";

export default function withStockTagsList(WrappedComponent) {
  const mapStateToProps = state => ({
    stockTags: getStockTags(state)
  });

  @connect(mapStateToProps)
  class WithStockTagsList extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithStockTagsList;
}
