// @flow
import axios from "axios";

import config from "../config";
import { showModal } from "../lib/react-redux-modal-provider";
import NetworkError from "./NetworkError";
import { UrlProvider } from "./UrlProvider";
import type { RequestParams } from "./RequestParamsType";
import envVariables from "../lib/envHelpers/getEnvVariables";

class ApiClientAsync {
  constructor(locale, authToken) {
    this.locale = locale;
    this.authToken = authToken;
  }

  async get(requestParams: RequestParams) {
    if (typeof requestParams === "string") {
      requestParams = {
        urlPath: requestParams
      };
    }
    return this._makeRequest("GET", requestParams);
  }

  async post(requestParams) {
    if (typeof requestParams === "string") {
      requestParams = {
        urlPath: requestParams
      };
    }
    return this._makeRequest("POST", requestParams);
  }

  async put(requestParams) {
    if (typeof requestParams === "string") {
      requestParams = {
        urlPath: requestParams
      };
    }
    return this._makeRequest("PUT", requestParams);
  }

  async _makeRequest(
    method = "GET",
    { url, urlPath, data, variables, headers, skipAccessCheck }
  ) {
    if (!url && !urlPath) {
      throw new Error(
        "You have to specify 'url' or 'urlPath' to make request."
      );
    }

    const locale = this.locale;
    const authToken = this.authToken;

    const apiUrl = config.apiUrl.replace(":locale", locale);

    if (urlPath) {
      url = UrlProvider.getUrl(urlPath, variables, apiUrl);
    }

    headers = {
      "Content-Type": "application/json",
      ...headers
    };

    if (authToken) {
      headers = {
        ...headers,
        Authorization: `Token ${authToken}`
      };
    }

    if (envVariables?.["STAGING_ACCESS_TOKEN"]) {
      headers = {
        ...headers,
        ["X-Staging-Access-Token"]: envVariables["STAGING_ACCESS_TOKEN"]
      };
    }

    try {
      const response = await axios({
        method,
        url,
        data,
        headers
      });

      return response.data;
    } catch (error) {
      const { request } = error;
      const { status } = request;

      if (!skipAccessCheck) {
        if (status === 401) {
          showModal("RegisterModal");
        } else if (status === 403) {
          showModal("PremiumModal", {
            iframeUrl: request.getResponseHeader("x-popup-url")
          });
        }
      }

      throw new NetworkError(error);
    }
  }
}

export default ApiClientAsync;
