import { createAction, handleActions } from "redux-actions";

export const SET_MARKETING_BAR_SEEN =
  "app/marketingBars/SET_MARKETING_BAR_SEEN";

export const UNSET_MARKETING_BAR_SEEN =
  "app/marketingBars/UNSET_MARKETING_BAR_SEEN";

export const setMarketingBarSeen = createAction(
  SET_MARKETING_BAR_SEEN,
  (id: number | string) => ({
    id
  })
);

export const unsetMarketingBarSeen = createAction(
  UNSET_MARKETING_BAR_SEEN,
  (id: number | string) => ({
    id
  })
);

const initialState = [];

export default handleActions(
  {
    [SET_MARKETING_BAR_SEEN]: (state, { payload }) => {
      let newSeen = state;

      // Here we create new Set to ensure there are no duplicates, and then we convert it back to array
      newSeen = [...new Set([...newSeen, payload.id])];

      return newSeen;
    },
    [UNSET_MARKETING_BAR_SEEN]: (state, { payload }) => {
      let newSeen = state;

      newSeen = newSeen.filter(element => element !== payload.id);

      return newSeen;
    }
  },
  initialState
);
