import { isEmpty } from "lodash";
import { call, fork, put, select, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import { getDefaultMarketId } from "../../../lib/getDefaultMarketId";
import { getIsWeb } from "../../../lib/platformHelper";
import { constants } from "../translations";
import { getMarketTranslations } from "../translations/selector";
import { getStockRoutine } from "./index";

function* getStockData({ payload }) {
  try {
    const marketTranslations = yield select(getMarketTranslations);
    const { marketId, stockId } = payload;
    let defaultMarketId = marketId;

    if (!isEmpty(marketTranslations)) {
      defaultMarketId = getDefaultMarketId(marketTranslations, marketId);
    }

    const { data } = yield ApiClient.get({
      urlPath: "app.stocks.stock",
      variables: { marketId: defaultMarketId, stockId: stockId }
    });

    yield put(getStockRoutine.success(data));
  } catch (error) {
    yield put(getStockRoutine.failure(error));
    console.error(error);
  }
}

function* getStockDataProxy({ payload }) {
  if (getIsWeb()) {
    const marketTranslations = yield select(getMarketTranslations);

    if (!isEmpty(marketTranslations)) {
      yield call(getStockData, { payload });
    } else {
      yield takeLatest(
        constants.FETCH_MARKET_TRANSLATIONS_SUCCESS,
        getStockData,
        { payload }
      );
    }
  } else {
    yield call(getStockData, { payload });
  }
}

function* watchStockPageTvChart() {
  yield takeLatest(getStockRoutine.TRIGGER, getStockDataProxy);
}

export default [fork(watchStockPageTvChart)];
