import withTranslations from "../../../../../../../../hocs/withTranslations";

const SEPARATOR = ", ";

const TransactionType = ({ value, translate }) => {
  const values = value?.split?.(SEPARATOR);

  if (Array.isArray(values)) {
    return values
      .map(value => translate(`insider_transaction_type_${value}`))
      .join(SEPARATOR);
  }

  return translate(`insider_transaction_type_${value}`);
};

export default withTranslations(TransactionType);
