import React from "react";

import FooterLink from "./footerLink";

export const FooterLinks = ({ navIndex, linksCount, className }) => {
  const links = [];

  for (let i = 1; i <= linksCount; i++) {
    links.push(
      <li key={`navIndex-${navIndex}_linkIndex-${i}`}>
        <FooterLink navIndex={navIndex} linkIndex={i} />
      </li>
    );
  }

  return <ul className={className}>{links}</ul>;
};
