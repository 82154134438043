import { connect } from "../i18n/connect";
import React from "react";
import { show, hide } from "../redux/modules/actionInProgress/";

export default function withActionInProgress(WrappedComponent) {
  const mapDispatchToProps = {
    showActionInProgress: show,
    hideActionInProgress: hide
  };

  @connect(null, mapDispatchToProps)
  class WithActionInProgress extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithActionInProgress;
}
