import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";

export const GET_MEDIA_MONITOR_ENTRIES =
  "app/mediaMonitor/GET_MEDIA_MONITOR_ENTRIES";
export const GET_MORE_MEDIA_MONITOR_ENTRIES =
  "app/mediaMonitor/GET_MORE_MEDIA_MONITOR_ENTRIES";
export const REFRESH_MEDIA_MONITOR_ENTRIES =
  "app/mediaMonitor/REFRESH_MEDIA_MONITOR_ENTRIES";

export const getMediaMonitorEntries = createRoutine(GET_MEDIA_MONITOR_ENTRIES);
export const getMoreMediaMonitorEntries = createRoutine(
  GET_MORE_MEDIA_MONITOR_ENTRIES
);
export const refreshMediaMonitorEntries = createRoutine(
  REFRESH_MEDIA_MONITOR_ENTRIES
);

export const initialState = {
  loading: false,
  error: null,
  messages: []
};

export default handleActions(
  {
    [getMediaMonitorEntries.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [getMediaMonitorEntries.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload.message
    }),
    [getMediaMonitorEntries.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      messages: payload.results
    }),
    [getMoreMediaMonitorEntries.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [getMoreMediaMonitorEntries.FAILURE]: state => ({
      ...state,
      loading: false
    }),
    [getMoreMediaMonitorEntries.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      is_last_page: !payload.next,
      messages: [...state.messages, ...payload.results]
    }),
    [refreshMediaMonitorEntries.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [refreshMediaMonitorEntries.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload.message
    }),
    [refreshMediaMonitorEntries.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      messages: payload.results
    })
  },
  initialState
);
