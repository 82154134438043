import React from "react";
import qs from "qs";
import connect from "../i18n/connect";
import { actions as authActions } from "../redux/modules/auth/login";

export default function withAutoLogin(WrappedComponent) {
  const mapDispatchToProps = {
    autoLogin: authActions.autoLogin
  };
  @connect(null, mapDispatchToProps)
  class WithAutoLogin extends React.Component {
    componentDidMount(): void {
      const { autoLogin } = this.props;
      const { search } = window.location;
      let loginData = qs.parse(search.replace("?", ""));

      const authToken = loginData["auth-access-token"];
      if (authToken) {
        autoLogin(authToken);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithAutoLogin;
}
