import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

interface PortfolioCashOperationMetaData {
  portfolioId: number;
  action: "addition" | "deletion";
  operationDate: string;
  type: "deposit" | "withdraw";
  cashAmount: string | number;
}

class PortfolioCashOperationEvent extends BaseEvent {
  constructor(portfolioCashOperationMetaData: PortfolioCashOperationMetaData) {
    super();
    this.portfolioCashOperationMetaData = portfolioCashOperationMetaData;
  }

  getEventName() {
    return "portfolio_cash_operation";
  }

  getEventTriggerName() {
    return "Cash operation added/deleted to portfolio by user";
  }

  getEventDetailsMatrix() {
    return `detail5=portfolio id
detail6=addition/deletion
detail7=operation date
detail8=cash amount
detail9=type (deposit/withdraw)
`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: this.portfolioCashOperationMetaData.portfolioId,
      detail6: this.portfolioCashOperationMetaData.action,
      detail7: this.portfolioCashOperationMetaData.operationDate,
      detail8: this.portfolioCashOperationMetaData.cashAmount,
      detail9: this.portfolioCashOperationMetaData.type
    };
  }
}

export default PortfolioCashOperationEvent;
