import React from "react";
import { compose } from "redux";
import PortfoliosList from "../../../common/PortfoliosList";
import withPortfoliosList from "../../../../../hocs/withPortfoliosList";
import withNavbarHeight from "../../../../../hocs/withNavbarHeight";
import classNames from "classnames";
import "../styles/default.scss";

const MobilePortfoliosList = ({
  portfoliosListState,
  navbarHeight,
  canDisplayVerifyEmailBar
}) => {
  return (
    <div
      className={classNames(
        "portfolios-list-mobile",
        canDisplayVerifyEmailBar
          ? "portfolios-list-mobile_emailVerification"
          : null
      )}
      style={{ paddingTop: navbarHeight }}
    >
      <PortfoliosList portfoliosListState={portfoliosListState} />
    </div>
  );
};

MobilePortfoliosList.propTypes = {};

export default compose(
  withPortfoliosList,
  withNavbarHeight
)(MobilePortfoliosList);
