import { put, call, takeLatest, fork } from "redux-saga/effects";
import { push } from "react-router-redux";
import { resetPasswordExecuteRoutine, resetPasswordRoutine } from "./index";
import transformFormData from "../../../lib/transformFormData";
import ApiClient from "../../../api/ApiClient";
import { actions as alertsActions } from "../alerts";
import { afterLogin } from "../auth/login/saga";

function* onResetPassword({ payload }) {
  const requestData = transformFormData(payload);
  const apiClient = new ApiClient();

  try {
    const { data } = yield ApiClient.post({
      urlPath: "app.auth.resetPassword",
      data: requestData
    });

    yield put(resetPasswordRoutine.success());

    yield put(
      alertsActions.addSuccessAlert({
        title: data.message
      })
    );
  } catch (error) {
    const response = JSON.parse(error.request.response);
    yield put(resetPasswordRoutine.failure(error));

    yield put(
      alertsActions.addErrorAlert({
        title: response.message
      })
    );
  }
}

function* onResetPasswordExecute({ payload: requestData }) {
  const apiClient = new ApiClient();

  try {
    const { data } = yield ApiClient.post({
      urlPath: "app.auth.setPassword",
      data: requestData
    });

    yield put(push("/"));
    yield afterLogin(data.token);
  } catch (error) {
    const response = JSON.parse(error.request.response);
    yield put(resetPasswordRoutine.failure(error));

    yield put(
      alertsActions.addErrorAlert({
        title: response.message
      })
    );
  }
}

function* watchRemindPassword() {
  yield takeLatest(resetPasswordRoutine.TRIGGER, onResetPassword);
  yield takeLatest(resetPasswordExecuteRoutine.TRIGGER, onResetPasswordExecute);
}

export default [fork(watchRemindPassword)];
