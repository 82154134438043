import React from "react";

import useIsHttpRequestsDone from "../hooks/useIsHttpRequestsDone";

const withIsHttpRequestsDone = WrappedComponent => {
  return function WithIsGetResourcesDone(props) {
    const { isHttpRequestsDone } = useIsHttpRequestsDone();

    return (
      <WrappedComponent {...props} isHttpRequestsDone={isHttpRequestsDone} />
    );
  };
};

export default withIsHttpRequestsDone;
