export const getPaymentDetails = state => state.payment.data;
export const getPaymentDetailsLoading = state => state.payment.loading;
export const getPaymentFormLoading = state => state.payment.paymentFormLoading;

export const getCurrentOffer = state => state.payment.currentOffer;
export const getCurrentOfferLoading = state =>
  state.payment.currentOfferLoading;
export const getSelectedOffer = state => state.payment.selectedOffer;
export const getSelectedOfferLoading = state => state.payment.selectedOfferLoading;
export const getSelectedOfferError = state => state.payment.selectedOfferError;
