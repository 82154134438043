import React from "react";
import DialogContent from "@material-ui/core/DialogContent";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { cloneDeep } from "lodash";
import { withRouter } from "react-router";

import ConsentModeHelperWeb from "../../../../../lib/ConsentModeHelper/ConsentModeHelperWeb";
import withTranslations from "../../../../../hocs/withTranslations";
import { UrlProvider } from "../../../../../api/UrlProvider";
import SquaberButton from "../../../common/SquaberButton";
import SquaberLink from "../../../common/SquaberLink";
import ModalBase from "../../ModalBase";
import "../styles/default.scss";

@withTranslations
@withRouter
class ConsentV2Modal extends ModalBase {
  constructor(props) {
    super(props);

    const consentSettings = {};

    ConsentModeHelperWeb.consentOptions.forEach(option => {
      consentSettings[option] = true;
    });

    this.settingsGrantedByDefault = cloneDeep(consentSettings);

    this.state = {
      acceptAll: true,
      consentSettings
    };
  }

  componentDidMount() {
    this.updateVisuallyHiddenState();
  }

  componentDidUpdate(prevProps) {
    const {
      location: { pathname: prevPathname }
    } = prevProps;
    const {
      location: { pathname }
    } = this.props;

    if (pathname !== prevPathname) {
      this.updateVisuallyHiddenState();
    }
  }

  updateVisuallyHiddenState() {
    const {
      location: { pathname },
      locale
    } = this.props;

    if (
      pathname ===
      UrlProvider.getUrl("fe.privacyPolicy", {
        locale
      })
    ) {
      this.setVisuallyHidden(true);
    } else {
      this.setVisuallyHidden(false);
    }
  }

  setAcceptAll(value) {
    this.setState({
      acceptAll: value
    });
  }

  setConsentOption(name, value) {
    this.setState(prevState => ({
      ...prevState,
      consentSettings: {
        ...prevState.consentSettings,
        [name]: value
      }
    }));
  }

  transformSettings(settings) {
    const settingsCopy = cloneDeep(settings);

    for (let key of Object.keys(settingsCopy)) {
      settingsCopy[key] = settingsCopy[key] === true ? "granted" : "denied";
    }

    return settingsCopy;
  }

  getContent() {
    const { translate, locale, onApplySettings, hideModal } = this.props;
    const { acceptAll, consentSettings } = this.state;

    return (
      <DialogContent className="consent-mode-v2-modal-content">
        <h4>{translate("cookie_consent_modal_heading")}</h4>
        <p>
          {translate("cookie_consent_modal_description")}{" "}
          <SquaberLink
            to={UrlProvider.getUrl("fe.privacyPolicy", {
              locale
            })}
          >
            {translate("consent_modal_link_to_privacy_policy")}
          </SquaberLink>
        </p>
        <br />
        <FormControlLabel
          className={"checkbox-wrapper"}
          classes={{
            label: "checkbox-label-wrapper"
          }}
          control={
            <Checkbox
              className="consent-mode-checkbox"
              checked={acceptAll}
              onChange={(event, value) => this.setAcceptAll(value)}
            />
          }
          label={
            <span className={"checkbox-label"}>
              {translate("Accept all cookies")}
            </span>
          }
        />
        {!acceptAll ? (
          <>
            <h6>{translate("Select specific types of cookies")}:</h6>
            {Object.keys(consentSettings).map(option => (
              <div key={option}>
                <FormControlLabel
                  className={"checkbox-wrapper"}
                  classes={{
                    label: "checkbox-label-wrapper"
                  }}
                  control={
                    <Checkbox
                      disabled={ConsentModeHelperWeb.consentEnabledByDefault.includes(
                        option
                      )}
                      className="consent-mode-checkbox"
                      checked={consentSettings[option]}
                      onChange={(event, value) => {
                        this.setConsentOption(option, value);
                      }}
                    />
                  }
                  label={
                    <span className={"checkbox-label"}>
                      {translate(`cookie_consent__${option}`)}
                    </span>
                  }
                />
              </div>
            ))}
          </>
        ) : null}
        <div className="buttons-wrapper">
          <SquaberButton
            color="secondary"
            onClick={() => {
              onApplySettings(this.transformSettings(consentSettings));
              hideModal();
            }}
          >
            {translate("Accept selected")}
          </SquaberButton>
          <SquaberButton
            onClick={() => {
              onApplySettings(
                this.transformSettings(this.settingsGrantedByDefault)
              );
              hideModal();
            }}
          >
            {translate("Accept all")}
          </SquaberButton>
        </div>
      </DialogContent>
    );
  }
}

export default ConsentV2Modal;
