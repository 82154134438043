import "../styles/default.scss";

import Formsy from "formsy-react";
import moment from "moment-mini";
import React, { useState } from "react";
import { compose } from "redux";

import Price from "../../../../../common/Price";
import withDeviceType from "../../../../../hocs/withDeviceType";
import withPortfolioAddStockTransaction from "../../../../../hocs/withPortfolioAddStockTransaction";
import withPortfolioStockTransactionsList from "../../../../../hocs/withPortfolioStockTransactionsList";
import withPortfolioTransactionHistoryTable, {
  DIVIDEND_TRANSACTION_TYPE
} from "../../../../../hocs/withPortfolioTransactionHistoryTable";
import withQuoteData from "../../../../../hocs/withQuoteData";
import Loader from "../../Loader";
import PortfolioAddTransaction from "../../PortfolioAddTransaction";
import SquaberButton from "../../SquaberButton";
import SquaberTable from "../../SquaberTable";

const PortfolioStockTransactions = ({
  portfolioStockTransactionsState,
  currency,
  transactionTypes,
  translate,
  deleteTransaction,
  ticker,
  market,
  formBuilder,
  stock,
  addTransaction,
  calculateCommission,
  clearValidationError,
  addPortfolioStockTransactionState: { error: validationErrors, loading },
  isMobile
}) => {
  if (portfolioStockTransactionsState.loading) {
    return <Loader small />;
  }

  const [showAddTransaction, setShowAddTransaction] = useState(false);

  const stockData = {
    stock: {
      id: stock.stock_id,
      ticker,
      market
    }
  };

  return (
    <div className="portfolio-stock-transactions">
      <Formsy
        onValidSubmit={() => {
          addTransaction({ formBuilder, stockData }, () => {
            setShowAddTransaction(false);
          });
        }}
        validationErrors={validationErrors}
      >
        <SquaberTable
          heading={[
            translate("Transaction type"),
            translate("Date"),
            translate("Price"),
            translate("Stocks count"),
            translate("Commission"),
            ""
          ]}
          rows={[
            ...portfolioStockTransactionsState.data.transactions.map(
              transaction => {
                const isDividend =
                  transaction.type === DIVIDEND_TRANSACTION_TYPE;

                return [
                  transactionTypes.stock[transaction.type],
                  moment(transaction.date).format("DD.MM.YYYY"),
                  <Price
                    value={isDividend ? transaction.amount : transaction.price}
                    currency={currency}
                  />,
                  !isDividend ? transaction.shares : "",
                  !isDividend ? (
                    <Price value={transaction.commission} roundToSecondNumber />
                  ) : (
                    ""
                  ),
                  !isDividend ? (
                    <i
                      className="far fa-trash-alt delete-transaction"
                      onClick={() => {
                        deleteTransaction(transaction);
                      }}
                    />
                  ) : (
                    ""
                  )
                ];
              }
            ),
            !isMobile ? (
              <PortfolioAddTransaction
                formBuilder={formBuilder}
                calculateCommission={calculateCommission}
                clearValidationError={clearValidationError}
                validationErrors={validationErrors}
                loading={loading}
                key="add-transaction-form"
              />
            ) : (
              []
            )
          ]}
        />

        {isMobile ? (
          <React.Fragment>
            <SquaberButton
              color="secondary"
              className="add-transaction-button"
              onClick={() => {
                setShowAddTransaction(true);
              }}
            >
              {translate("Add transaction")}
            </SquaberButton>
            {showAddTransaction ? (
              <div className="portfolio-add-cash-transactions is-mobile-page-sheet">
                <h2>{translate("Add stock transaction")}</h2>
                <div
                  className="close-page-sheet"
                  onClick={() => {
                    setShowAddTransaction(false);
                  }}
                >
                  <i className="fal fa-times" />
                </div>
                <SquaberTable
                  headings={[]}
                  rows={[
                    <PortfolioAddTransaction
                      formBuilder={formBuilder}
                      calculateCommission={calculateCommission}
                      clearValidationError={clearValidationError}
                      validationErrors={validationErrors}
                      loading={loading}
                      key="add-transaction-form"
                    />
                  ]}
                />
              </div>
            ) : null}
          </React.Fragment>
        ) : null}
      </Formsy>
    </div>
  );
};

PortfolioStockTransactions.propTypes = {};

export default compose(
  withQuoteData(true),
  withPortfolioStockTransactionsList,
  withPortfolioTransactionHistoryTable,
  withPortfolioAddStockTransaction,
  withDeviceType
)(PortfolioStockTransactions);
