import React from "react";
import { connect } from "../i18n/connect";
import {
  getGlobalAlertManagementData,
  getSpecificAlertManagementData,
  updateAlertManagementData
} from "../redux/modules/alertManagement";
import {
  getAlertManagementLoading,
  getAlertManagementState
} from "../redux/modules/alertManagement/selector";
import { fetchManualStrategies } from "../redux/modules/manualStrategies";
import {
  getManualStrategies,
  getTransactionStrategies
} from "../redux/modules/manualStrategies/selector";
import { getAnalysisCategories } from "../redux/modules/analysis/analysisCategories/selector";

export default function withAlertManagement(WrappedComponent) {
  const mapStateToProps = state => ({
    alertManagementData: getAlertManagementState(state),
    loading: getAlertManagementLoading(state),
    manualStrategies: getManualStrategies(state),
    transactionStrategies: getTransactionStrategies(state),
    analysisCategories: getAnalysisCategories(state)
  });

  const mapDispatchToProps = {
    getSpecificAlertManagementData,
    getGlobalAlertManagementData,
    updateAlertManagementData,
    fetchManualStrategies
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithAlertManagement extends React.Component {
    componentDidMount(): void {
      const {
        getSpecificAlertManagementData,
        getGlobalAlertManagementData,
        fetchManualStrategies,
        stockId
      } = this.props;

      if (stockId) {
        getSpecificAlertManagementData({ stockId });
      }
      getGlobalAlertManagementData();
      fetchManualStrategies();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithAlertManagement;
}
