import "../styles/default.scss";

import autoBind from "auto-bind";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";

import withNotifications from "../../../../../hocs/withNotifications";
import withUserData from "../../../../../hocs/withUserData";
import handleAccess from "../../../../../lib/access/handleAccess";
import NotificationList from "../../NotificationList";

@withNotifications
@withUserData
class NotificationIndicator extends Component {
  constructor(props: P, context: any) {
    super(props, context);
    autoBind.react(this);
  }

  onToggle() {
    const {
      userHasAccess,
      userAccessLevel,
      setNotificationsOpen,
      notificationsOpen
    } = this.props;

    setNotificationsOpen(!notificationsOpen);
  }

  render() {
    const { unreadCount, loading, notificationsOpen } = this.props;

    return (
      <div className="notification-indicator">
        <Dropdown isOpen={notificationsOpen} toggle={this.onToggle}>
          <DropdownToggle nav>
            {loading ? (
              <i className="fas fa-spinner fa-spin" />
            ) : unreadCount > 99 ? (
              "99+"
            ) : (
              String(unreadCount)
            )}
          </DropdownToggle>
          <DropdownMenu right>
            <NotificationList {...this.props} onToggle={this.onToggle} />
          </DropdownMenu>
        </Dropdown>
      </div>
    );
  }
}

NotificationIndicator.propTypes = {
  unreadCount: PropTypes.number,
  messages: PropTypes.array
};

export default NotificationIndicator;
