import React from "react";
import withPortfolioTransactionHistory from "../../../../../hocs/withPortfolioTransactionHistory";
import {
  addPortfolioCashTransactionRoutine,
  addPortfolioTransactionRoutine,
  deleteTransactionRoutine
} from "../../../../../redux/modules/portfolios";
import ContentSection from "../../ContentSection";
import UniversalTableLiteWeb from "../../UniversalTableLiteWeb";

import "../styles/default.scss";

const PortfolioTransactionHistory = ({ translate, portfolioId }) => (
  <ContentSection className="portfolio-transactions-history">
    <h2 className="big-heading">{translate("Transaction history")}</h2>
    <p>{translate("Below you will find history of your transactions")}</p>
    <UniversalTableLiteWeb
      type="TransactionHistory"
      resourceId={portfolioId}
      refreshOn={[
        deleteTransactionRoutine.SUCCESS,
        addPortfolioTransactionRoutine.SUCCESS,
        addPortfolioCashTransactionRoutine.SUCCESS
      ]}
      options={{
        portfolioId
      }}
    />
  </ContentSection>
);

PortfolioTransactionHistory.propTypes = {};

export default withPortfolioTransactionHistory(PortfolioTransactionHistory);
