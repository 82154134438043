import React from "react";
import connect from "../i18n/connect";
import { resendConfirmationEmail } from "../redux/modules/emailConfirmation";

export default function withEmailConfirmation(WrappedComponent) {
  const mapDispatchToProps = {
    resendConfirmationEmail
  };

  @connect(null, mapDispatchToProps)
  class WithEmailConfirmation extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithEmailConfirmation;
}
