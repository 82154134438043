import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

interface PortfolioTransactionMetaData {
  portfolioId: number;
  action: "addition" | "deletion";
  transactionDate: string;
  stockId: number;
  stockTicker: string;
  type: "buy" | "sell";
  stockCount: string | number;
  transactionPrice: string | number;
  transactionCommission: string | number;
}

class PortfolioTransactionEvent extends BaseEvent {
  constructor(portfolioTransactionMetaData: PortfolioTransactionMetaData) {
    super();
    this.portfolioTransactionMetaData = portfolioTransactionMetaData;
  }

  getEventName() {
    return "portfolio_transaction";
  }

  getEventTriggerName() {
    return "Transaction added/deleted to portfolio by user";
  }

  getEventDetailsMatrix() {
    return `detail5=portfolio id
detail6=addition/deletion
detail7=transaction date
detail8=stock id
detail9=stock ticker
detail10=type (buy/sell)
detail11=stocks count
detail12=transaction price
detail13=transaction commission`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: this.portfolioTransactionMetaData.portfolioId,
      detail6: this.portfolioTransactionMetaData.action,
      detail7: this.portfolioTransactionMetaData.transactionDate,
      detail8: this.portfolioTransactionMetaData.stockId,
      detail9: this.portfolioTransactionMetaData.stockTicker,
      detail10: this.portfolioTransactionMetaData.type,
      detail11: this.portfolioTransactionMetaData.stockCount,
      detail12: this.portfolioTransactionMetaData.transactionPrice,
      detail13: this.portfolioTransactionMetaData.transactionCommission
    };
  }
}

export default PortfolioTransactionEvent;
