import { push } from "react-router-redux";
import { fork, put, select, takeEvery, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import { UrlProvider } from "../../../api/UrlProvider";
import { getDefaultMarketId } from "../../../lib/getDefaultMarketId";
import { getIsWeb } from "../../../lib/platformHelper";
import { getAuthToken, getUserData } from "../auth/login/selector";
import { getUserStocks } from "../followed/selector";
import { getLocale, getMarketTranslations } from "../translations/selector";
import { actions, constants } from "./index";
import { getLastId, getMessage, getMessages } from "./selector";

export const STOCK_NOTIFICATION_TYPE = "stock";
const PREMIUM_RECIPIENTS_TYPE = "premium";

const MESSAGE_MAX_LENGTH = 100;

function extractContent(html) {
  return html.replace(/<[^>]+>/g, "").replace(/&nbsp;|\n/g, " ");
}

function getMessageShortcut(message) {
  if (message?.config?.title) {
    return message.config.title;
  }

  return message.message;
}

function setupMessage(message, markets, tickers, userData) {
  if (message.config === null) {
    message.config = {
      market: markets[message.item_id],
      ticker: tickers[message.item_id],
      recipients: {
        type: PREMIUM_RECIPIENTS_TYPE
      },
      title: null,
      content_type: STOCK_NOTIFICATION_TYPE,
      check_user_stock: true
    };
  }

  message.shortcut =
    message.content_type === STOCK_NOTIFICATION_TYPE
      ? message.config.ticker
      : getMessageShortcut(message);

  if (
    message.content_type !== STOCK_NOTIFICATION_TYPE &&
    message.config.html_message
  ) {
    const htmlContent = extractContent(message.config.html_message);

    if (htmlContent.length > MESSAGE_MAX_LENGTH) {
      message.message = htmlContent.substring(0, MESSAGE_MAX_LENGTH) + "...";
    } else {
      message.message = htmlContent;
    }
  }

  const messageIsPremium =
    message.config.recipients.type === PREMIUM_RECIPIENTS_TYPE;

  if (messageIsPremium && (!userData || !userData.has_access)) {
    message.blurred = true;
  }

  return message;
}

function* onFetchNotifications({
  payload: { loadNext, authToken: passedToken }
}) {
  const lastId = loadNext ? yield select(getLastId) : null;
  const authToken = yield select(getAuthToken) || passedToken;
  const userData = yield select(getUserData);

  if (!authToken) {
    yield put(actions.fetchNotificationsCancel());
    return;
  }

  let requestData = {
    lastId: lastId || ""
  };

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.notification.browser",
      variables: requestData
    });

    data.messages = data.messages.map(message => {
      return setupMessage(message, data.markets, data.tickers, userData);
    });

    yield put(
      actions.fetchNotificationsSuccess({
        messages: data.messages,
        unread: data.unread
      })
    );
  } catch (error) {
    yield put(actions.fetchNotificationsError());

    console.error(error);
  }
}

function* onMarkAsRead({ payload: { id, shouldRedirect } }) {
  let requestData = {};

  if (id) {
    requestData["id"] = id;
  }

  try {
    yield ApiClient.put({
      urlPath: id ? "app.notification.read" : "app.notification.read_all",
      variables: requestData
    });

    yield put(actions.markAsReadSuccess(requestData));

    if (id && shouldRedirect) {
      if (getIsWeb()) {
        let locale = yield select(getLocale);
        let message = yield select(getMessage, id);

        const marketTranslations = yield select(getMarketTranslations);
        let defaultMarketId = message.market;

        if (marketTranslations) {
          defaultMarketId = getDefaultMarketId(
            marketTranslations,
            message.market
          );
        }

        yield put(
          push(
            UrlProvider.getUrl("fe.stockPage", {
              locale,
              marketId: defaultMarketId,
              stockId: message.ticker
            })
          )
        );
      }
    }
  } catch (error) {
    yield put(actions.markAsReadError());

    console.error(error);
  }
}

function* onAddNotification({ payload: { data: notification } }) {
  const userData = yield select(getUserData);
  const userStocksData = yield select(getUserStocks);

  if (!userStocksData) {
    return;
  }

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.stock.item",
      variables: {
        id: notification.item_id
      }
    });

    const markets = { [data.stock.id]: data.stock.market };
    const tickers = { [data.stock.id]: data.stock.ticker };

    const message = setupMessage(notification, markets, tickers, userData);

    yield put(
      actions.addNotificationSuccess({
        message
      })
    );
  } catch (error) {
    yield put(actions.markAsReadError());

    console.error(error);
  }
}

function* onAttemptBackgroundNotificationsUpdate() {
  const authToken = yield select(getAuthToken);
  const userData = yield select(getUserData);
  const currentNotifications = yield select(getMessages);

  if (!authToken) {
    yield put(actions.fetchNotificationsCancel());
    return;
  }

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.notification.browser",
      variables: {
        lastId: ""
      }
    });

    data.messages = data.messages.map(message => {
      return setupMessage(message, data.markets, data.tickers, userData);
    });

    if (data?.messages?.[0]?.id !== currentNotifications?.[0]?.id) {
      yield put(
        actions.attemptBackgroundNotificationsUpdateSuccess({
          messages: data.messages,
          unread: data.unread
        })
      );
    }
  } catch (error) {
    yield put(actions.attemptBackgroundNotificationsUpdateError());

    console.error(error);
  }
}

function* watchFetchNotifications() {
  yield takeLatest(constants.FETCH_NOTIFICATIONS, onFetchNotifications);
  yield takeLatest(
    constants.ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE,
    onAttemptBackgroundNotificationsUpdate
  );
  yield takeEvery(constants.MARK_AS_READ, onMarkAsRead);
  yield takeEvery(constants.ADD_NOTIFICATION, onAddNotification);
}

export default [fork(watchFetchNotifications)];
