import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

interface CookiesConsentMetaData {
  ad_personalization: "granted" | "denied";
  ad_storage: "granted" | "denied";
  ad_user_data: "granted" | "denied";
  analytics_storage: "granted" | "denied";
}

class CookiesConsentEvent extends BaseEvent {
  constructor(cookiesConsentMetaData: CookiesConsentMetaData) {
    super();
    this.cookiesConsentMetaData = cookiesConsentMetaData;
  }

  getEventName() {
    return "cookies_constant";
  }

  getEventTriggerName() {
    return "An information about what option was chosen by user";
  }

  getEventDetailsMatrix() {
    return `detail5=ad_personalization (granted/denied)
detail6=ad_storage (granted/denied)
detail7=ad_user_data (granted/denied)
detail8=analytics_storage (granted/denied)`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: this.cookiesConsentMetaData.ad_personalization,
      detail6: this.cookiesConsentMetaData.ad_storage,
      detail7: this.cookiesConsentMetaData.ad_user_data,
      detail8: this.cookiesConsentMetaData.analytics_storage
    };
  }
}

export default CookiesConsentEvent;
