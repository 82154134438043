import React from "react";
import withTranslations from "../../../../../hocs/withTranslations";
import SquaberTable from "../../SquaberTable";
import PortfolioStockTransactions from "../../PortfolioStockTransactions";
import PortfolioStocksTableRow from "../../PortfolioStocksTableRow";

import "../styles/default.scss";

const PortfolioStocksTable = ({
  translate,
  stocks,
  currency,
  currentStock,
  setCurrentStock
}) => {
  const rows = stocks.map(stock => {
    const isCurrent = currentStock
      ? stock.ticker === currentStock.ticker &&
        stock.market === currentStock.market
      : false;

    return (
      <PortfolioStocksTableRow
        key={stock.stock_id}
        stock={stock}
        isCurrent={isCurrent}
        setCurrentStock={setCurrentStock}
      />
    );
  });

  if (currentStock) {
    const indexToAppendAfter = stocks.findIndex(
      stock =>
        stock.ticker === currentStock.ticker &&
        stock.market === currentStock.market
    );

    if (indexToAppendAfter !== -1) {
      rows.splice(indexToAppendAfter + 1, 0, [
        {
          content: (
            <PortfolioStockTransactions
              ticker={currentStock.ticker}
              market={currentStock.market}
              stock={currentStock}
              currency={currency}
            />
          ),
          colspan: 9
        }
      ]);
    }
  }

  return (
    <div className="scrollable-table-wrapper">
      <SquaberTable
        className="portfolio-stocks-table"
        heading={[
          translate("Ticker"),
          translate("Stocks count"),
          translate("Transactions value [%{currency}]", {
            currency
          }),
          translate("Result [%{currency}]", {
            currency
          }),
          translate("Rate of return"),
          translate("Average purchase price [%{currency}]", {
            currency
          }),
          translate("Current price [%{currency}]", {
            currency
          }),
          "",
          ""
        ]}
        rows={[...rows]}
      />
    </div>
  );
};

export default withTranslations(PortfolioStocksTable);
