import React from "react";
import { withRouter } from "react-router";
import { Helmet } from "react-helmet";
import Url from "url-parse";

function CanonicalTagProvider({ location }) {
  const parsedCurrentUrl = new Url(window.location);

  return (
    <Helmet>
      <link
        rel="canonical"
        key="canonical"
        href={parsedCurrentUrl.origin + location.pathname}
      />
    </Helmet>
  );
}

export default withRouter(CanonicalTagProvider);
