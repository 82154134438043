import { UrlProvider } from "../api/UrlProvider";

export default function navigateToDesktop(tag, history, locale) {
  history.push(
    UrlProvider.getUrl("fe.desktopPageWithTagId", {
      locale,
      tagId: tag.id
    })
  );
}
