import React from "react";
import connect from "../i18n/connect";
import {
  followStockRoutine,
  unfollowStockRoutine
} from "../redux/modules/followed";

export default function withFollowUnfollow(WrappedComponent) {
  const mapDispatchToProps = {
    follow: followStockRoutine,
    unFollow: unfollowStockRoutine
  };

  @connect(null, mapDispatchToProps)
  class WithFollowUnfollow extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithFollowUnfollow;
}
