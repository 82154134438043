import React from "react";

import connect from "../../../../../../i18n/connect";
import useIsDesktop from "../../../hooks/useIsDesktop";
import NoneIcon from "../assets/line_10.svg";
import BothIcon from "../assets/vector_3.svg";
import OneIcon from "../assets/vector_4.svg";

const Option = ({ value, translate }) => {
  const isDesktop = useIsDesktop();

  const common = isDesktop
    ? { width: "18", height: "18" }
    : {
        width: "12",
        height: "12"
      };

  if (value === "+") {
    return (
      <img
        src={BothIcon}
        alt={translate("lp_plan-table_option_both")}
        {...common}
      />
    );
  }

  if (value === "++") {
    return (
      <img
        src={OneIcon}
        alt={translate("lp_plan-table_option_one")}
        {...common}
      />
    );
  }

  return (
    <img
      src={NoneIcon}
      alt={translate("lp_plan-table_option_none")}
      width={isDesktop ? "20" : "10"}
    />
  );
};

export default connect()(Option);
