import React from "react";
import PropTypes from "prop-types";
import theme from "../../../../../theme";

import "../styles/default.scss";

const MobileNavigationIcon = ({ icon, unreadCount, isLast }) => (
  <React.Fragment>
    <i className={`fal fa-${icon}`} />
    {unreadCount ? (
      <div className="notifications-badge">{unreadCount}</div>
    ) : null}
    {!isLast ? (
      <div
        className="separator"
        style={{
          position: "absolute",
          right: -1,
          top: 10,
          background: theme.colors.baseGreyLight,
          height: 30,
          width: 1
        }}
      />
    ) : null}
  </React.Fragment>
);

MobileNavigationIcon.propTypes = {
  icon: PropTypes.string.isRequired,
  withNotifications: PropTypes.bool,
  isLast: PropTypes.bool
};

MobileNavigationIcon.defaultProps = {
  withNotifications: false
};

export default MobileNavigationIcon;
