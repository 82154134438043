import qs from "qs";

type Sort = string;

type FilteredCategoryIds = string[];

export type DisplayStyle = "grid" | "list";

const ALLOWED_DISPLAY_STYLES = ["grid", "list"];

class AnalysisPersistenceLayer {
  _storageKeyName = "analysisPersistenceLayer";
  _storageDisplayStyleKeyName = "analysisDisplayStyle";

  /**
   *
   * @param storageProvider - Use localStorage or any storageProvider with implements functions localStorage like
   */
  constructor(storageProvider) {
    this.storageProvider = storageProvider;
  }

  setSortFilter = async (value: Sort) => {
    return await this.appendFilters({ sort: value });
  };

  setFilteredCategories = async (filteredCategoryIds: FilteredCategoryIds) => {
    return await this.appendFilters({ filteredCategoryIds });
  };

  setDisplayStyle = async (style: DisplayStyle) => {
    if (ALLOWED_DISPLAY_STYLES.includes(style)) {
      await this.storageProvider.setItem(
        this._storageDisplayStyleKeyName,
        style
      );

      return style;
    }
  };

  getDisplayStyle = async () => {
    const style = await this.storageProvider.getItem(
      this._storageDisplayStyleKeyName
    );

    if (ALLOWED_DISPLAY_STYLES.includes(style)) {
      return style;
    }
  };

  getFilters = async (): Promise<{
    sort: Sort | undefined,
    filteredCategoryIds: FilteredCategoryIds | undefined
  }> => {
    const result = {};

    try {
      const data = JSON.parse(
        await this.storageProvider.getItem(this._storageKeyName)
      );

      if (data?.sort) {
        result.sort = data.sort;
      }

      if (data?.filteredCategoryIds) {
        result.filteredCategoryIds = data.filteredCategoryIds;
      }
    } catch (e) {
      console.error(e);
    }

    return result;
  };

  toString = async () => {
    const { filteredCategoryIds, sort } = await this.getFilters();

    return qs.stringify(
      { ordering: sort, category_id: filteredCategoryIds },
      { arrayFormat: "repeat" }
    );
  };

  purgeString = string => {
    if (typeof string !== "string") {
      return;
    }

    const params = qs.parse(string, {
      ignoreQueryPrefix: true
    });

    delete params?.ordering;
    delete params?.category_id;

    return qs.stringify(params, { arrayFormat: "repeat" });
  };

  restoreFromString = async (value: string) => {
    try {
      const params = qs.parse(value);

      const restoredFilters = {};

      if (typeof params?.ordering === "string") {
        restoredFilters.sort = params.ordering;
      }

      if (params.category_id) {
        const categoryIds = Array.isArray(params.category_id)
          ? params.category_id
          : [params.category_id];

        let filteredCategoryIds = [];

        categoryIds.forEach(category => {
          const canPushCategory =
            category && typeof category === "string" && !isNaN(category);

          if (canPushCategory) {
            filteredCategoryIds.push(Number(category));
          }
        });

        if (filteredCategoryIds.length > 0) {
          restoredFilters.filteredCategoryIds = filteredCategoryIds;
        }
      }

      if (Object.keys(restoredFilters).length > 0) {
        return await this.save(restoredFilters);
      }
    } catch (e) {
      console.error(e);
    }
  };

  save = async value => {
    await this.storageProvider.setItem(
      this._storageKeyName,
      JSON.stringify(value)
    );

    return value;
  };

  appendFilters = async value => {
    try {
      const data = await this.getFilters();
      const newFilters = { ...data, ...value };

      return await this.save(newFilters);
    } catch (e) {
      console.error(e);
    }
  };
}

export default AnalysisPersistenceLayer;
