import React from "react";

import withWebHistoryList from "../../../../../hocs/withHistoryList/withWebHisotryList";
import Loader from "../../Loader";
import SquaberButton from "../../SquaberButton";
import SquaberCommentsListItem from "../../SquaberCommentsListItem";
import SquaberCommentsRowItem from "../../SquaberCommentsRowItem";

const SquaberCommentsList = ({
  historyListState,
  fetchNextPage,
  translate,
  displayStyle
}) => (
  <div className="history-list">
    {historyListState.loading ? (
      <Loader small />
    ) : (
      <React.Fragment>
        {historyListState.data.results.length ? (
          historyListState.data.results.map((signal, index) => {
            if (displayStyle === "list") {
              return <SquaberCommentsRowItem key={index} {...signal} />;
            }
            return <SquaberCommentsListItem key={index} {...signal} />;
          })
        ) : (
          <p className="no-results">
            {translate("There are no entries for selected stock.")}
          </p>
        )}
        {historyListState.data.next ? (
          <div className="text-center load-more-button-wrapper">
            <SquaberButton
              color="tertiary"
              onClick={async () => {
                await fetchNextPage();
              }}
              disabled={historyListState.loadingNextPage}
            >
              {translate("Load more stocks")}
              {historyListState.loadingNextPage ? (
                <i className="fal fa-spinner fa-spin" />
              ) : null}
            </SquaberButton>
          </div>
        ) : null}
      </React.Fragment>
    )}
  </div>
);

export default withWebHistoryList("stock-analysis")(SquaberCommentsList);
