import React from "react";
import { bindPromiseCreators } from "redux-saga-routines";

import { connect } from "../i18n/connect";
import {
  clearDividends,
  clearInsiderTransactions,
  clearLastMinMaxPoints,
  clearMediaMonitorEntries,
  clearPriceAlerts,
  clearReportDates,
  getDividendsRoutine,
  getPriceAlertsRoutine,
  getReportDatesRoutine,
  refreshDividends,
  refreshInsiderTransactions,
  refreshPriceAlerts,
  refreshReportDates,
  rejectChartUserDataRoutinePromise,
  tvChartGetUserDataRoutinePromiseCreator,
  tvChartRoutine,
  tvChartRoutinePromiseCreator,
  tvChartUserDataRoutinePromiseCreator
} from "../redux/modules/tvchartData";
import {
  getChartData,
  getChartMediaMonitorEntries,
  getChartReportDates,
  getChartReportDatesLoading,
  getDividendsData,
  getDividendsLoading,
  getInsiderTransactions,
  getLastMinMaxPoints,
  getPriceAlertsData,
  getPriceAlertsLoading
} from "../redux/modules/tvchartData/selector";

export default function withChartData(WrappedComponent) {
  const mapStateToProps = state => ({
    chartData: getChartData(state),
    reportDates: getChartReportDates(state),
    reportDatesLoading: getChartReportDatesLoading(state),
    mediaMonitorMessages: getChartMediaMonitorEntries(state),
    lastMinMaxPoints: getLastMinMaxPoints(state),
    dividends: getDividendsData(state),
    dividendsLoading: getDividendsLoading(state),
    priceAlerts: getPriceAlertsData(state),
    priceAlertsLoading: getPriceAlertsLoading(state),
    insiderTransactions: getInsiderTransactions(state)
  });

  const mapDispatchToProps = {
    fetchChartData: tvChartRoutine,
    fetchReportDates: getReportDatesRoutine,
    fetchDividends: getDividendsRoutine,
    fetchPriceAlerts: getPriceAlertsRoutine,
    refreshReportDates,
    refreshDividends,
    refreshPriceAlerts,
    refreshInsiderTransactions,
    clearMediaMonitorEntries,
    clearLastMinMaxPoints,
    clearReportDates,
    clearDividends,
    clearPriceAlerts,
    clearInsiderTransactions,
    rejectChartUserDataRoutinePromise
  };

  const additionalMapDispatchToProps = dispatch => ({
    ...bindPromiseCreators(
      {
        tvChartRoutinePromiseCreator,
        tvChartUserDataRoutinePromiseCreator,
        tvChartGetUserDataRoutinePromiseCreator
      },
      dispatch
    )
  });

  @connect(mapStateToProps, mapDispatchToProps)
  @connect(null, additionalMapDispatchToProps)
  class WithChartData extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithChartData;
}
