import React from "react";
import autoBind from "auto-bind";
import withFormBuilder from "./withFormBuilder";
import { connect } from "../i18n/connect";
import { contactFormRoutine } from "../redux/modules/contactForm";
import transformFormData from "../lib/transformFormData";

export default function withContactForm(WrappedComponent) {
  const mapDispatchToProps = {
    send: contactFormRoutine
  };

  @connect(null, mapDispatchToProps)
  @withFormBuilder([
    {
      name: "name",
      label: "Name and surname",
      value: "",
      type: "text",
      fullWidth: true,
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "email",
      label: "Email address",
      value: "",
      type: "text",
      fullWidth: true,
      required: true,
      validations: {
        isEmail: true
      },
      validationErrors: {
        isEmail: "Your email seems to be invalid",
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "topic",
      label: "Topic",
      value: "",
      type: "text",
      fullWidth: true,
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "message",
      label: "Message",
      value: "",
      type: "text",
      fullWidth: true,
      multiline: true,
      rows: 3
    }
  ])
  class WithContactForm extends React.Component {
    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    send(fields, recaptcha, form) {
      let data = {
        captcha: {
          response: recaptcha
        },
        ...transformFormData(fields)
      };

      this.props.send({ data, form });
    }

    render() {
      return <WrappedComponent {...this.props} send={this.send} />;
    }
  }

  return WithContactForm;
}
