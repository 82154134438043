import { getIsWeb } from "../platformHelper";
import AnalyticsEventTrackerWeb from "./AnalyticsEventTrackerWeb";
import AnalyticsEventTrackerNative from "./AnalyticsEventTrackerNative";

let AnalyticsEventTracker;

if (getIsWeb()) {
  AnalyticsEventTracker = AnalyticsEventTrackerWeb;
} else {
  AnalyticsEventTracker = AnalyticsEventTrackerNative;
}

export default AnalyticsEventTracker;
