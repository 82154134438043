import React from "react";
import { connect } from "../i18n/connect";
import {
  getAuthToken,
  getUserData
} from "../redux/modules/auth/login/selector";
import getAccessLevel from "../lib/access/getAccessLevel";
import accessLevelsMap from "../lib/access/accessLevelsMap";

export default function withUserDataGetter(WrappedComponent) {
  const mapStateToProps = state => ({
    userData: getUserData(state),
    authToken: getAuthToken(state)
  });

  @connect(mapStateToProps)
  class WithUserDataGetter extends React.Component {
    render() {
      const { userData } = this.props;

      const accessLevel = getAccessLevel(userData, {});
      const userHasAccess = accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS;
      const userIsNotLoggedIn = accessLevel === accessLevelsMap.NOT_LOGGED_IN;

      return (
        <WrappedComponent
          {...this.props}
          accessLevel={accessLevel}
          userHasAccess={userHasAccess}
          userIsNotLoggedIn={userIsNotLoggedIn}
        />
      );
    }
  }

  return WithUserDataGetter;
}
