import React from "react";
import SquaberTable from "../../SquaberTable";
import getCashTransactionsSum from "../../../../../lib/getCashTransactionsSum";
import withTranslations from "../../../../../hocs/withTranslations";
import Price from "../../../../../common/Price";

import "../styles/default.scss";

const PortfolioCashTable = ({
  cash,
  dividends,
  portfolioCashTransactionsState,
  currency,
  startingCapital,
  translate
}) => {
  let rows = [
    [
      translate("Current cash balance"),
      <Price value={cash || 0} currency={currency} />
    ]
  ];

  if (dividends) {
    rows.push([
      translate("Dividends to withdraw"),
      <Price value={dividends || 0} currency={currency} />
    ]);
  }

  if (portfolioCashTransactionsState && portfolioCashTransactionsState.data) {
    const transactionsSum = getCashTransactionsSum(
      portfolioCashTransactionsState.data.results
    );

    rows.push([
      translate("Cash operations balance"),
      <Price
        value={startingCapital + transactionsSum}
        currency={currency}
        roundToSecondNumber
      />
    ]);
  }

  return <SquaberTable className="portfolio-cash-table" rows={rows} />;
};

PortfolioCashTable.propTypes = {};

export default withTranslations(PortfolioCashTable);
