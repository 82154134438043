import PropTypes from "prop-types";
import React from "react";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withTranslations from "../../../../../hocs/withTranslations";
import ContentSection from "../../../common/ContentSection";
import SquaberLink from "../../../common/SquaberLink";

function ContentWrapper({ children, translate, locale, className, stock }) {
  return (
    <ContentSection className={className}>
      <div className={"buttons buttons_column"}>
        <SquaberLink to={UrlProvider.getUrl("fe.analysisList", { locale })}>
          <i className="fal fa-arrow-left" /> {translate("Analysis list")}
        </SquaberLink>
        {stock ? (
          <SquaberLink
            to={UrlProvider.getUrl("fe.stockPage", {
              locale,
              marketId: stock.market,
              stockId: stock.ticker
            })}
          >
            <i className="fal fa-arrow-left" />{" "}
            {translate("Go to %{name}", { name: stock.ticker })}
          </SquaberLink>
        ) : null}
      </div>
      {children}
    </ContentSection>
  );
}

ContentWrapper.propTypes = {
  stock: PropTypes.shape({
    name: PropTypes.string,
    market: PropTypes.string,
    ticker: PropTypes.string
  }),
  className: PropTypes.string
};

export default withTranslations(ContentWrapper);
