import { takeLatest, fork, put, select } from "redux-saga/effects";
import { getResultsRoutine } from "./index";
import ApiClient from "../../../api/ApiClient";

function* getResults({ payload }) {
  const { source, query, params = {} } = payload;

  try {
    const { data } = yield ApiClient.get({
      urlPath: source,
      variables: {
        query,
        ...params
      }
    });

    yield put(getResultsRoutine.success(data));
  } catch (e) {
    yield put(getResultsRoutine.failure());
  }
}

function* watchGetResults() {
  yield takeLatest(getResultsRoutine.TRIGGER, getResults);
}

export default [fork(watchGetResults)];
