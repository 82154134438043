import React from "react";

import { connect } from "../i18n/connect";
import { getPortfoliosStockRoutine } from "../redux/modules/portfolios";
import { getPortfoliosStockState } from "../redux/modules/portfolios/selector";

export default function withPortfoliosStock(WrappedComponent) {
  const mapStateToProps = state => ({
    portfoliosStockState: getPortfoliosStockState(state)
  });

  const mapDispatchToProps = {
    getPortfoliosStockRoutine: getPortfoliosStockRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithPortfoliosList extends React.Component {
    componentDidMount() {
      const { getPortfoliosStockRoutine, stockId } = this.props;

      getPortfoliosStockRoutine({ stockId });
    }

    componentDidUpdate(
      prevProps: Readonly<P>,
      prevState: Readonly<S>,
      snapshot: SS
    ) {
      if (prevProps.stockId === this.props.stockId) {
        return;
      }

      this.props.getPortfoliosStockRoutine({ stockId: this.props.stockId });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithPortfoliosList;
}
