import { takeLatest, fork, put, select } from "redux-saga/effects";
import { fetchFinancialSituation } from "./index";
import ApiClient from "../../../api/ApiClient";
import { getFinancialSituation } from "./selector";
import { isEmpty } from "lodash";

function* beforeFetchFinancialSituation({ payload }) {
  const financialSituationData = yield select(getFinancialSituation);

  if (!isEmpty(financialSituationData.data) || financialSituationData.loading) {
    return;
  }

  yield put(fetchFinancialSituation.request(payload));
}

function* onFetchFinancialSituation({ payload }) {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.financialSituation.stock",
      variables: {
        stockId: payload.stockId
      }
    });

    yield put(fetchFinancialSituation.success(data));
  } catch (e) {
    yield put(fetchFinancialSituation.failure(e));
  }
}

function* watchFinancialSituation() {
  yield takeLatest(
    fetchFinancialSituation.TRIGGER,
    beforeFetchFinancialSituation
  );
  yield takeLatest(fetchFinancialSituation.REQUEST, onFetchFinancialSituation);
}

export default [fork(watchFinancialSituation)];
