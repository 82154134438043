import { isEqual, unionBy } from "lodash";
import { createAction, handleActions } from "redux-actions";
import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const tvChartRoutine = createRoutine("app/tvChartData/GET_DATA");
export const tvChartRoutinePromiseCreator = promisifyRoutine(tvChartRoutine);

export const tvChartUserDataRoutine = createRoutine("app/tvChartUserData");
export const tvChartUserDataRoutinePromiseCreator = promisifyRoutine(
  tvChartUserDataRoutine
);

export const mediaMonitorEntriesRoutine = createRoutine(
  "app/tvChartData/mediaMonitorEntries"
);

export const tvChartGetUserDataRoutine = createRoutine(
  "app/tvChartGetUserData"
);
export const tvChartGetUserDataRoutinePromiseCreator = promisifyRoutine(
  tvChartGetUserDataRoutine
);

export const getDividendsRoutine = createRoutine(
  "app/tvChartData/GET_DIVIDENDS"
);

export const getPriceAlertsRoutine = createRoutine(
  "app/tvChartData/GET_PRICE_ALERTS"
);

export const getInsiderTransactionsRoutine = createRoutine(
  "app/tvChartData/GET_INSIDER_TRANSACTIONS"
);

export const GET_REPORT_DATES = "app/tvChartData/GET_REPORT_DATES";
export const LAST_MIN_MAX_POINTS = "app/tvChartData/LAST_MIN_MAX_POINTS";
export const CURRENT_MIN_MAX_POINTS = "app/tvChartData/CURRENT_MIN_MAX_POINTS";

export const getReportDatesRoutine = createRoutine(GET_REPORT_DATES);

export const lastMinMaxPointsRoutine = createRoutine(LAST_MIN_MAX_POINTS);
export const currentMinMaxPointsRoutine = createRoutine(CURRENT_MIN_MAX_POINTS);

export const ADD_MEDIA_MONITOR_ENTRIES =
  "app/tvChartData/ADD_MEDIA_MONITOR_ENTRIES";

export const SET_PRICE_ALERTS = "app/tvChartData/SET_PRICE_ALERTS";
export const CLEAR_LAST_MIN_MAX_POINTS =
  "app/tvChartData/CLEAR_LAST_MIN_MAX_POINTS";
export const CLEAR_MEDIA_MONITOR_ENTRIES =
  "app/tvChartData/CLEAR_MEDIA_MONITOR_ENTRIES";
export const CLEAR_REPORT_DATES = "app/tvChartData/CLEAR_REPORT_DATES";
export const CLEAR_DIVIDENDS = "app/tvChartData/CLEAR_DIVIDENDS";
export const CLEAR_PRICE_ALERTS = "app/tvChartData/CLEAR_PRICE_ALERTS";
export const CLEAR_INSIDER_TRANSACTIONS =
  "app/tvChartData/CLEAR_INSIDER_TRANSACTIONS";
export const REFRESH_REPORT_DATES = "app/tvChartData/REFRESH_REPORT_DATES";
export const REFRESH_DIVIDENDS = "app/tvChartData/REFRESH_DIVIDENDS";
export const REFRESH_PRICE_ALERTS = "app/tvChartData/REFRESH_PRICE_ALERTS";
export const REFRESH_INSIDER_TRANSACTIONS =
  "app/tvChartData/REFRESH_INSIDER_TRANSACTIONS";
export const REJECT_CHART_USER_DATA_ROUTINE_PROMISE =
  "app/tvChartData/REJECT_CHART_USER_DATA_ROUTINE_PROMISE";

export const addMediaMonitorEntries = createAction(
  ADD_MEDIA_MONITOR_ENTRIES,
  (entries: Array) => ({ entries })
);

export const setPriceAlerts = createAction(SET_PRICE_ALERTS);

export const clearMediaMonitorEntries = createAction(
  CLEAR_MEDIA_MONITOR_ENTRIES
);
export const clearLastMinMaxPoints = createAction(CLEAR_LAST_MIN_MAX_POINTS);
export const clearReportDates = createAction(CLEAR_REPORT_DATES);
export const clearDividends = createAction(CLEAR_DIVIDENDS);
export const clearPriceAlerts = createAction(CLEAR_PRICE_ALERTS);
export const clearInsiderTransactions = createAction(
  CLEAR_INSIDER_TRANSACTIONS
);

export const refreshReportDates = createAction(REFRESH_REPORT_DATES);
export const refreshDividends = createAction(REFRESH_DIVIDENDS);
export const refreshPriceAlerts = createAction(REFRESH_PRICE_ALERTS);
export const refreshInsiderTransactions = createAction(
  REFRESH_INSIDER_TRANSACTIONS
);

export const rejectChartUserDataRoutinePromise = createAction(
  REJECT_CHART_USER_DATA_ROUTINE_PROMISE
);

const initialState = {
  loading: false,
  error: null,
  data: null,
  reportDates: { loading: null, data: [], error: null },
  mediaMonitorEntries: [],
  lastMinMaxPoints: { current: [], previous: [] },
  dividends: { loading: null, data: [], error: null },
  priceAlerts: { loading: null, data: [], error: null },
  insiderTransactions: { loading: null, data: [], error: null }
};

export default handleActions(
  {
    [tvChartRoutine.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [tvChartRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false
    }),
    [tvChartRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false
    }),
    [getReportDatesRoutine.TRIGGER]: state => ({
      ...state,
      reportDates: {
        ...state.reportDates,
        loading: true
      }
    }),
    [getReportDatesRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      reportDates: {
        ...state.reportDates,
        data: payload,
        error: null,
        loading: false
      }
    }),
    [getReportDatesRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      reportDates: {
        ...state.reportDates,
        error: payload,
        loading: false
      }
    }),
    [ADD_MEDIA_MONITOR_ENTRIES]: (state, { payload }) => ({
      ...state,
      mediaMonitorEntries: [...state.mediaMonitorEntries, ...payload.entries]
    }),
    [mediaMonitorEntriesRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      mediaMonitorEntries: unionBy(
        state.mediaMonitorEntries,
        payload.entries,
        ({ pub_datetime, title }) => `${pub_datetime}${title}`
      )
    }),
    [currentMinMaxPointsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      lastMinMaxPoints: {
        ...state.lastMinMaxPoints,
        current: payload
      }
    }),
    [lastMinMaxPointsRoutine.SUCCESS]: (state, { payload: betweenFromTo }) => {
      const current = state.lastMinMaxPoints.current;

      const isCurrentPoint = point =>
        current.some(cPoint => isEqual(cPoint, point));

      const filteredBetweenFromTo = betweenFromTo.filter(
        point => !isCurrentPoint(point)
      );

      return {
        ...state,
        lastMinMaxPoints: {
          ...state.lastMinMaxPoints,
          previous: [
            ...state.lastMinMaxPoints.previous,
            ...filteredBetweenFromTo
          ]
        }
      };
    },
    [getDividendsRoutine.TRIGGER]: state => ({
      ...state,
      dividends: {
        ...state.dividends,
        loading: true
      }
    }),
    [getDividendsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      dividends: {
        ...state.dividends,
        data: payload,
        error: null,
        loading: false
      }
    }),
    [getDividendsRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      dividends: {
        ...state.dividends,
        error: payload,
        loading: false
      }
    }),
    [getInsiderTransactionsRoutine.TRIGGER]: state => ({
      ...state,
      insiderTransactions: {
        ...state.insiderTransactions,
        loading: true
      }
    }),
    [getInsiderTransactionsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      insiderTransactions: {
        ...state.insiderTransactions,
        data: [...state.insiderTransactions.data, ...payload],
        error: null,
        loading: false
      }
    }),
    [getInsiderTransactionsRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      insiderTransactions: {
        ...state.insiderTransactions,
        loading: false,
        error: payload
      }
    }),
    [SET_PRICE_ALERTS]: (state, { payload }) => ({
      ...state,
      priceAlerts: {
        ...state.priceAlerts,
        data: payload
      }
    }),
    [getPriceAlertsRoutine.TRIGGER]: state => ({
      ...state,
      priceAlerts: {
        ...state.priceAlerts,
        loading: true
      }
    }),
    [getPriceAlertsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      priceAlerts: {
        ...state.priceAlerts,
        data: payload,
        error: null,
        loading: false
      }
    }),
    [getPriceAlertsRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      priceAlerts: {
        ...state.priceAlerts,
        error: payload,
        loading: false
      }
    }),
    [CLEAR_LAST_MIN_MAX_POINTS]: state => ({
      ...state,
      lastMinMaxPoints: initialState.lastMinMaxPoints
    }),
    [CLEAR_MEDIA_MONITOR_ENTRIES]: state => ({
      ...state,
      mediaMonitorEntries: initialState.mediaMonitorEntries
    }),
    [CLEAR_REPORT_DATES]: state => ({
      ...state,
      reportDates: initialState.reportDates
    }),
    [CLEAR_DIVIDENDS]: state => ({
      ...state,
      dividends: initialState.dividends
    }),
    [CLEAR_PRICE_ALERTS]: state => ({
      ...state,
      priceAlerts: initialState.priceAlerts
    }),
    [CLEAR_INSIDER_TRANSACTIONS]: state => ({
      ...state,
      insiderTransactions: initialState.insiderTransactions
    }),
    [REFRESH_REPORT_DATES]: state => ({
      ...state,
      reportDates: initialState.reportDates
    }),
    [REFRESH_DIVIDENDS]: state => ({
      ...state,
      dividends: initialState.dividends
    }),
    [REFRESH_PRICE_ALERTS]: state => ({
      ...state,
      priceAlerts: initialState.priceAlerts
    })
  },
  initialState
);
