import { fork, put, select, takeEvery } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import ChangeAlertSettingsEvent from "../../../lib/SalesManago/events/ChangeAlertSettingsEvent";
import { actions as alertsActions } from "../alerts";
import { getUserData } from "../auth/login/selector";
import { trackSMEvent } from "../salesManagoEventTracker";
import {
  getGlobalAlertManagementData,
  getSpecificAlertManagementData,
  updateAlertManagementData
} from "./index";

function* fetchSubscriptionStatus(type, variables, routine) {
  try {
    const { data } = yield ApiClient.get({
      urlPath: `app.${type}.get`,
      variables
    });

    yield put(routine.success(data));
  } catch (e) {
    yield put(routine.failure(error));
  }
}

function* onGetSpecificAlertManagementData({ payload: { stockId } }) {
  yield fetchSubscriptionStatus(
    "subscriptionStatus",
    {
      stockId
    },
    getSpecificAlertManagementData
  );
}

function* onGetGlobalAlertManagementData() {
  yield fetchSubscriptionStatus(
    "globalSubscriptionStatus",
    undefined,
    getGlobalAlertManagementData
  );
}

function* onUpdateAlertManagementData({ payload }) {
  const { stockId } = payload;
  const userData = yield select(getUserData);

  const urlNamespace =
    typeof stockId !== "undefined"
      ? "subscriptionStatus"
      : "globalSubscriptionStatus";

  const urlPath = `app.${urlNamespace}.${
    payload.channel ? "setSpecific" : "setDefault"
  }`;

  try {
    const { data } = yield ApiClient.put({
      urlPath,
      variables: payload,
      data: {
        status: payload.status
      }
    });

    yield put(updateAlertManagementData.success(data));
    const smEvent = new ChangeAlertSettingsEvent(userData.contact_id, data);
    yield put(trackSMEvent(smEvent));

    if (payload.channel !== "android") {
      yield put(
        alertsActions.addSuccessAlert({
          title: "Notification settings saved"
        })
      );
    }
  } catch (e) {
    console.log(e);
    yield put(
      alertsActions.addErrorAlert({
        title: "There was an error while saving notification settings"
      })
    );
  }
}

function* watchAlertManagement() {
  yield takeEvery(
    getSpecificAlertManagementData.TRIGGER,
    onGetSpecificAlertManagementData
  );
  yield takeEvery(
    getGlobalAlertManagementData.TRIGGER,
    onGetGlobalAlertManagementData
  );
  yield takeEvery(
    updateAlertManagementData.TRIGGER,
    onUpdateAlertManagementData
  );
}

export default [fork(watchAlertManagement)];
