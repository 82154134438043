import "../styles/default.scss";

import autoBind from "auto-bind";
import { throttle } from "lodash";
import React, { lazy, Suspense } from "react";
import Scrollbar from "react-scrollbars-custom";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withAlertedTagDetails from "../../../../../hocs/withAlertedTagDetails";
import withAllAvailableTagsList from "../../../../../hocs/withAllAvailableTagsList";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withDeviceType from "../../../../../hocs/withDeviceType";
import withStocksListFromSource from "../../../../../hocs/withStocksListFromSource";
import withTagsSearchAutocomplete from "../../../../../hocs/withTagsSearchAutocomplete";
import withTagStocksData from "../../../../../hocs/withTagStocksData";
import TagsHelper from "../../../../../lib/TagsHelper/TagsHelper";
import StocksListNavigationHeader from "../../../navigation/StocksListNavigationHeader";
import SquaberLink from "../../SquaberLink";

const StocksList = lazy(() => import("../../StocksList"));

@withAlertedTagDetails
@withTagsSearchAutocomplete(true)
@withStocksListFromSource
@withDeviceType
@withBottomNavigation("bottomNavigationValue")
@withAllAvailableTagsList
@withTagStocksData(10, props => {
  const { stocksListSource, alertedTagDetails } = props;

  if (stocksListSource) {
    return stocksListSource.stocks;
  }

  if (alertedTagDetails && alertedTagDetails.data) {
    return alertedTagDetails.data.stocks;
  }

  return [];
})
class FollowedStocksList extends React.Component {
  constructor(props) {
    super(props);

    autoBind.react(this);

    this.state = {
      showAutocomplete: false,
      advancedMode: false
    };
  }

  componentDidMount() {
    const { isMobile } = this.props;

    this.scrollbarWidth = this.getScrollbarWidth();

    if (!isMobile) {
      this.wrapper.addEventListener("mouseenter", this.onStocksListMouseEnter);
      this.wrapper.addEventListener("mouseleave", this.onStocksListMouseLeave);
    }
  }

  componentDidUpdate(prevProps) {
    const { userIsNotLoggedIn, updateStocksListSource } = this.props;

    if (
      prevProps.allAvailableTags.data.length === 0 &&
      this.props.allAvailableTags.data.length
    ) {
      const wig20Tag = this.props.allAvailableTags.data.find(tag =>
        TagsHelper.isDefaultTag(tag)
      );

      const alertedTag = this.props.allAvailableTags.data.find(tag =>
        TagsHelper.isAlertedTag(tag)
      );

      if (userIsNotLoggedIn) {
        updateStocksListSource(wig20Tag);
      } else {
        updateStocksListSource(alertedTag);
      }
    }
  }

  componentWillUnmount() {
    const { isMobile } = this.props;

    if (!isMobile) {
      //prettier-ignore
      this.wrapper.removeEventListener("mouseenter", this.onStocksListMouseEnter);
      //prettier-ignore
      this.wrapper.removeEventListener("mouseleave", this.onStocksListMouseLeave);

      this.onStocksListMouseLeave();
    }
  }

  onStocksListMouseEnter() {
    document.body.style.overflow = "hidden";
    document.body.style.paddingRight = `${this.scrollbarWidth}px`;
    const floatingInformationElement = document.querySelector(
      ".floating-information"
    );
    if (floatingInformationElement) {
      floatingInformationElement.style.marginRight = `${this.scrollbarWidth}px`;
    }
    const intercomElement = this.getIntercomElement();
    if (intercomElement) {
      intercomElement.style.marginRight = `${this.scrollbarWidth}px`;
    }
    const intercomBadgeElement = this.getIntercomBadgeElement();
    if (intercomBadgeElement) {
      intercomBadgeElement.style.marginRight = `${this.scrollbarWidth}px`;
    }
  }

  onStocksListMouseLeave() {
    document.body.style.overflow = null;
    document.body.style.paddingRight = null;
    const navbarElement = document.querySelector(".navbar");
    if (navbarElement) {
      navbarElement.style.paddingRight = null;
    }
    const floatingInformationElement = document.querySelector(
      ".floating-information"
    );
    if (floatingInformationElement) {
      floatingInformationElement.style.marginRight = null;
    }
    const intercomElement = this.getIntercomElement();
    if (intercomElement) {
      intercomElement.style.marginRight = null;
    }
    const intercomBadgeElement = this.getIntercomBadgeElement();
    if (intercomBadgeElement) {
      intercomBadgeElement.style.marginRight = null;
    }
  }

  getScrollbarWidth() {
    // Creating invisible container
    const outer = document.createElement("div");
    outer.style.visibility = "hidden";
    outer.style.overflow = "scroll"; // forcing scrollbar to appear
    outer.style.msOverflowStyle = "scrollbar"; // needed for WinJS apps
    document.body.appendChild(outer);

    // Creating inner element and placing it in the container
    const inner = document.createElement("div");
    outer.appendChild(inner);

    // Calculating difference between container's full width and the child width
    const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

    // Removing temporary elements from the DOM
    outer.parentNode.removeChild(outer);

    return scrollbarWidth;
  }

  getIntercomElement() {
    let intercomElement = document.querySelector(
      ".intercom-lightweight-app-launcher"
    );

    if (!intercomElement) {
      intercomElement = document.querySelector(".intercom-launcher-frame");
    }

    return intercomElement;
  }

  getIntercomBadgeElement() {
    return document.querySelector(".intercom-launcher-badge-frame");
  }

  onStocksListScroll(event) {
    const { loading, next, fetchMore } = this.props;

    if (!next || loading) {
      return;
    }

    const { scrollTop, clientHeight } = event;
    if (scrollTop / clientHeight >= 0.5) {
      fetchMore();
    }
  }

  setAdvancedMode(value) {
    this.setState({
      advancedMode: value
    });
  }

  render() {
    const {
      translate,
      locale,
      stocksListSource,
      results,
      loading,
      next,
      fetchMore,
      isMobile,
      setValue
    } = this.props;

    const { advancedMode } = this.state;

    const stocksListSourceIsPortfolioTag =
      stocksListSource && TagsHelper.isPortfolioTag(stocksListSource);

    const stocksListSourceIsEditableTag =
      stocksListSource && TagsHelper.isEditableTag(stocksListSource);

    return (
      <div
        className="followed-stocks-list-wrapper"
        ref={element => (this.wrapper = element)}
      >
        <Scrollbar onScroll={throttle(this.onStocksListScroll, 200)}>
          <StocksListNavigationHeader
            advancedMode={advancedMode}
            setAdvancedMode={this.setAdvancedMode}
          />
          <div className="stocks-list-inside">
            {results.length ? (
              <Suspense fallback={<div />}>
                <StocksList
                  stocks={results}
                  hideRemoveButton={!stocksListSourceIsEditableTag}
                  advancedMode={advancedMode}
                />
                {next ? (
                  <div
                    className="show-remaining-stocks"
                    onClick={() => {
                      if (!loading) {
                        fetchMore();
                      }
                    }}
                  >
                    <span>{translate("Show remaining stocks")}</span>
                    {loading ? (
                      <i className="fal fa-spinner fa-spin" />
                    ) : (
                      <i className="fal fa-angle-right" />
                    )}
                  </div>
                ) : null}
              </Suspense>
            ) : (
              <div className="no-watched-stocks-placeholder">
                <i className="fal fa-bell" />
                <p className="text-center placeholder-text">
                  {translate("no-watched-stocks-message")}
                </p>
              </div>
            )}
          </div>
        </Scrollbar>
        {isMobile && stocksListSourceIsPortfolioTag ? (
          <SquaberLink
            to={UrlProvider.getUrl("fe.portfolio", {
              locale,
              id: stocksListSource.automated_tag_object_id
            })}
            onClick={() => {
              setValue("");
            }}
            className="floating-tag-desktop-link"
          >
            <i className="far fa-wallet" />
          </SquaberLink>
        ) : null}
      </div>
    );
  }
}

export default FollowedStocksList;
