import React from "react";
import connect from "../i18n/connect";
import { getTransactionHistoryData } from "../redux/modules/transactionHistory/selector";
import { getTransactionHistoryRoutine } from "../redux/modules/transactionHistory";

export default function withTransactionHistory(WrappedComponent) {
  const mapStateToProps = state => ({
    transactionHistoryData: getTransactionHistoryData(state)
  });
  const mapDispatchToProps = {
    fetchTransactionHistory: getTransactionHistoryRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithTransactionHistory extends React.Component {
    componentDidMount(): void {
      this.props.fetchTransactionHistory();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithTransactionHistory;
}
