import React from "react";
import { connect } from "../i18n/connect";
import { actions } from "../redux/modules/notifications";
import {
  getEndReached,
  getLoading,
  getMessages,
  getUnreadCount
} from "../redux/modules/notifications/selector";

export default function withNotifications(WrappedComponent) {
  const mapStateToProps = state => ({
    unreadCount: getUnreadCount(state),
    messages: getMessages(state),
    loading: getLoading(state),
    endReached: getEndReached(state)
  });

  const mapDispatchToProps = {
    fetchNotificationsAction: actions.fetchNotifications,
    markAsReadAction: actions.markAsRead
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithNotifications extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithNotifications;
}
