import React, { useRef } from "react";
import { compose } from "redux";

import withTranslations from "../../../../../../hocs/withTranslations";
import useSize from "../../../../../hooks/useSize";

function MarketingMessage({
  translate,
  message,
  image,
  minDisplayWidth = 300
}) {
  const marketingMessageRef = useRef(null);
  const { width } = useSize(marketingMessageRef);

  return (
    <div className={"marketing-message"} ref={marketingMessageRef}>
      {width === null || width >= minDisplayWidth ? (
        <>
          {message ? (
            <div
              className={"marketing-message__content"}
              dangerouslySetInnerHTML={{ __html: message }}
            />
          ) : null}
          {image ? (
            <img
              className={"marketing-message__image"}
              src={image}
              alt={translate("header_marketing_message_image_alt")}
            />
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default compose(withTranslations)(MarketingMessage);
