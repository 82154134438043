import React from "react";
import classNames from "classnames";
import withActionInProgressHandler from "../../../../../hocs/withActionInProgressHandler";

import "../styles/default.scss";

const ActionInProgress = ({
  translate,
  actionInProgressState: { visible, description }
}) => (
  <div
    className={classNames({
      "action-in-progress": true,
      visible
    })}
  >
    <span className="description">
      {description ? translate(description) : ""}
    </span>
    <i className="far fa-spinner fa-spin" />
  </div>
);

export default withActionInProgressHandler(ActionInProgress);
