import React, { useEffect } from "react";

const MOBILE_MAX_WIDTH = 991;

function useMobileMediaQuery() {
  const [mobileMediaQuery, setMobileMediaQuery] = React.useState({
    matches: window.innerWidth <= MOBILE_MAX_WIDTH
  });

  useEffect(() => {
    const mediaQuery = window.matchMedia(`(max-width: ${MOBILE_MAX_WIDTH}px)`);
    mediaQuery.addEventListener("change", setMobileMediaQuery);

    return () => mediaQuery.removeEventListener("change", setMobileMediaQuery);
  }, []);

  return mobileMediaQuery.matches;
}

export default useMobileMediaQuery;
