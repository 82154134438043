import React, { useState } from "react";
import Formsy from "formsy-react";
import { compose } from "redux";
import withUserData from "../../../../../hocs/withUserData";
import withEditUserProfileForm from "../../../../../hocs/withEditUserProfileForm";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import SquaberButton from "../../../common/SquaberButton";

import "../styles/default.scss";

const UserProfileForm = ({
  translate,
  formBuilder,
  editUserData,
  validationErrors,
  clearValidationError
}) => {
  const [showPasswordField, setShowPasswordField] = useState(false);
  return (
    <div className="user-profile-form">
      <Formsy
        validationErrors={validationErrors}
        onValidSubmit={() => {
          const emailField = formBuilder.fields.find(
            field => field.name === "email"
          );
          const passwordField = formBuilder.fields.find(
            field => field.name === "password_old"
          );

          let shouldShowPasswordField =
            emailField.edited === true && !passwordField.edited;

          if (shouldShowPasswordField && !showPasswordField) {
            setShowPasswordField(true);
          } else {
            editUserData(formBuilder.fields, () => {
              emailField.edited = false;
              passwordField.edited = false;
              passwordField.value = "";

              setShowPasswordField(false);
            });
          }
        }}
      >
        <h2 className="with-margin">{translate("Account details")}</h2>
        <div className="login-field-wrapper">
          <FormBuilderWeb
            {...formBuilder}
            fields={formBuilder.fields.filter(
              field => field.name === "username"
            )}
            validationErrors={validationErrors}
            clearValidationError={clearValidationError}
          />
        </div>
        <FormBuilderWeb
          {...formBuilder}
          className="personal-data-form-builder"
          fields={formBuilder.fields.filter(
            field => field.name === "first_name" || field.name === "last_name"
          )}
          validationErrors={validationErrors}
          clearValidationError={clearValidationError}
        />
        <h2>{translate("Contact details")}</h2>
        <FormBuilderWeb
          {...formBuilder}
          className="personal-data-form-builder"
          fields={formBuilder.fields.filter(
            field => field.name === "email" || field.name === "phone"
          )}
          validationErrors={validationErrors}
          clearValidationError={clearValidationError}
        />
        {showPasswordField ? (
          <div className="login-field-wrapper">
            <span className="password-required-notice">
              {translate(
                "In order to change email you have to provide your password:"
              )}
            </span>
            <FormBuilderWeb
              {...formBuilder}
              fields={formBuilder.fields.filter(
                field => field.name === "password_old"
              )}
              validationErrors={validationErrors}
              clearValidationError={clearValidationError}
            />
          </div>
        ) : null}
        <div className="submit-wrapper">
          <SquaberButton type="submit" disabled={!formBuilder.canSubmit}>
            {translate("Save settings")}
          </SquaberButton>
        </div>
      </Formsy>
    </div>
  );
};

UserProfileForm.propTypes = {};

export default compose(withUserData, withEditUserProfileForm)(UserProfileForm);
