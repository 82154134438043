const snakeToPascal = str =>
  str
    .replace(/([-_][a-z])/g, group =>
      group
        .toUpperCase()
        .replace("-", "")
        .replace("_", "")
    )
    .replace(/^\w/, c => c.toUpperCase());

export default snakeToPascal;
