import React from "react";
import { UrlProvider } from "../../../../../api/UrlProvider";
import withTranslations from "../../../../../hocs/withTranslations";
import SquaberLink from "../../SquaberLink/modules/default";

import "../styles/DesktopTagNotFound.scss";

const DesktopTagOnlyForRegisteredUsers = ({ translate, locale }) => (
  <div className="tag-not-found">
    <h4>{translate("Register first")}</h4>
    <p>
      {translate("This desktop is available only for registered users.")}{" "}
      <SquaberLink
        to={UrlProvider.getUrl("fe.loginWithRedirect", {
          locale,
          redirectUrl: window.location.pathname
        })}
      >
        {translate("Log in")}
      </SquaberLink>{" "}
      {translate("or")}{" "}
      <SquaberLink
        to={UrlProvider.getUrl("fe.registerWithRedirect", {
          locale,
          redirectUrl: window.location.pathname
        })}
      >
        {translate("register")}
      </SquaberLink>
    </p>
  </div>
);

export default withTranslations(DesktopTagOnlyForRegisteredUsers);
