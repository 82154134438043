import React from "react";
import { globalConfigFields } from "../../../../../redux/modules/globalConfig";
import withGlobalConfigFields from "../../../../../hocs/withGlobalConfigFields";
import SquaberButton from "../../../common/SquaberButton";
import ContentSection from "../../../common/ContentSection";
import config from "../../../../../config";

const PremiumOfferBox = ({ translate, globalConfig: { offerUrl } }) => (
  <ContentSection>
    <h1>{translate("Check Squaber offer")}</h1>
    <p>{translate("Check latest offer and choose the right plan for you")}</p>
    <SquaberButton
      onClick={() => {
        window.open(offerUrl || config.defaultPremiumOfferUrl, "_blank");
      }}
    >
      {translate("Whole Squaber offer")}
    </SquaberButton>
  </ContentSection>
);

export default withGlobalConfigFields([globalConfigFields.OFFER_URL])(
  PremiumOfferBox
);
