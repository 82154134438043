import { createAction, handleActions } from "redux-actions";

const updateAttentionStatePrefix = "app/attentionData/UPDATE_ATTENTION_STATE";

export const updateAttentionState = createAction(
  updateAttentionStatePrefix,
  (
    market: string,
    ticker: string,
    currentAttentionState: boolean,
    attentionStateUpdateDate: string
  ) => ({
    market,
    ticker,
    currentAttentionState,
    attentionStateUpdateDate
  })
);

const initialState = {};

const attentionReducer = handleActions(
  {
    [updateAttentionStatePrefix]: (state, { payload }) => {
      const ticker = payload.ticker.toLowerCase();
      const market = payload.market.toLowerCase();
      const { currentAttentionState, attentionStateUpdateDate } = payload;

      return {
        ...state,
        [market]: {
          ...state[market],
          [ticker]: {
            ...state[ticker],
            currentAttentionState,
            attentionStateUpdateDate
          }
        }
      };
    }
  },
  initialState
);

export default attentionReducer;
