import responseStatus from "../../../../../lib/api/responseStatus";

const unsubscribeElectronicCommunication = async (
  apiClient,
  { userId, hash }
) => {
  try {
    const response = await apiClient.put({
      urlPath: "app.auth.unsubscribe.electronicCommunication",
      variables: {
        userId,
        hash
      }
    });

    const status = response?.message ? responseStatus.OK : responseStatus.ERROR;

    return { status };
  } catch (e) {
    return { status: responseStatus.ERROR };
  }
};

export default unsubscribeElectronicCommunication;
