import DEFAULT_DRAWN_OBJECTS_OPTIONS from "../../../../lib/TvChart/defaultDrawnObjectsOptions";

type DeprecatedBackupData = {
  chart: string,
  settings?: { key: string, value: boolean }[]
};
type BackupData = {
  chart: { [key: string]: any },
  drawn_objects_options: { key: string, value: boolean }[]
};

export const toFrontendBackupData = (
  data: BackupData | DeprecatedBackupData
):
  | {
      chart: { [key: string]: any },
      settings: { key: string, value: boolean }[]
    }
  | undefined => {
  let result = {};

  const isDeprecatedChartBackupData = typeof data.chart === "string";

  if (isDeprecatedChartBackupData) {
    result.chart = data.chart;
  }

  const isDeprecatedSettingsBackupData = Array.isArray(data.settings);

  if (isDeprecatedSettingsBackupData) {
    result.settings = data.settings;
  }

  const isBackupDataChart = typeof data.chart === "object";

  if (isBackupDataChart) {
    result.chart = JSON.stringify(data.chart);
  }

  const isBackupDataSettings = Array.isArray(data.drawn_objects_options);

  if (isBackupDataSettings) {
    result.settings = data.drawn_objects_options;
  }

  if (Object.keys(result).length === 0) {
    return undefined;
  }

  if (typeof result?.settings === "undefined") {
    result.settings = DEFAULT_DRAWN_OBJECTS_OPTIONS;
  }

  return result;
};
