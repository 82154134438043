import "../styles/default.scss";

import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";

import withAnalysisCategories from "../../../../../hocs/withAnalysisCategories";
import withTranslations from "../../../../../hocs/withTranslations";
import theme from "../../../../../theme";
import Tag from "../../Tag";

const AnalysisFilters = ({
  analysisCategories,
  analysisFilters,
  appendFilteredCategory,
  removeFilteredCategory,
  translate
}) => {
  const filters = analysisCategories.map(({ name, id }) => {
    let isFilteredCategory = false;

    if (analysisFilters?.filteredCategoryIds) {
      isFilteredCategory =
        analysisFilters.filteredCategoryIds.findIndex(
          categoryId => categoryId === id
        ) !== -1;
    }

    return (
      <Tag
        key={id}
        name={name}
        forceAlertedTagStyle={!!isFilteredCategory}
        color={theme.colors.baseGreyDark}
        onClick={async () =>
          isFilteredCategory
            ? await removeFilteredCategory(id)
            : await appendFilteredCategory(id)
        }
      />
    );
  });

  return (
    <div className={"analysis-filters"}>
      <div className={"analysis-filters__label"}>
        {translate("analysis_filters_label")}
      </div>
      <div className={"analysis-filters__filters"}>{filters}</div>
    </div>
  );
};

AnalysisFilters.propTypes = {
  analysisCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  analysisFilters: PropTypes.shape({
    filteredCategoryIds: PropTypes.arrayOf(
      PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    )
  }),
  appendFilteredCategory: PropTypes.func,
  removeFilteredCategory: PropTypes.func
};

export default compose(
  withAnalysisCategories,
  withTranslations
)(AnalysisFilters);
