import React from "react";

import connect from "../i18n/connect";
import { updateAttentionState } from "../redux/modules/tickerAttention";
import { getAttentionData } from "../redux/modules/tickerAttention/selector";

const mapStateToProps = (state, props) => {
  const market = props?.stock?.stock?.market || props?.stock?.market;
  const ticker = props?.stock?.stock?.ticker || props?.stock?.ticker;

  return {
    attentionState: getAttentionData(state, market, ticker)
  };
};

const mapDispatchToProps = {
  updateAttentionState
};

function withAttention(WrappedComponent) {
  const withAttention = props => {
    return <WrappedComponent {...props} />;
  };

  return connect(mapStateToProps, mapDispatchToProps)(withAttention);
}

export default withAttention;
