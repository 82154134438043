import React from "react";
import connect from "../i18n/connect";
import { getMoreMediaMonitorEntries } from "../redux/modules/mediaMonitor";
import { getMediaMonitorEntriesLoading } from "../redux/modules/mediaMonitor/selector";

export default function withLoadMoreMediaMonitorEntries(WrappedComponent) {
  const mapStateToProps = state => ({
    mediaMonitorEntriesLoading: getMediaMonitorEntriesLoading(state)
  });

  const mapDispatchToProps = {
    getMoreMediaMonitorEntries
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithLoadMoreMediaMonitorEntries extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithLoadMoreMediaMonitorEntries;
}
