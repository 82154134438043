import hasDescription from "./hasDescription";

/**
 *
 * @param descriptions Return first description with match test
 * @return {string}
 */
const getOfferDescription = (descriptions: string[]) => {
  for (const description of descriptions) {
    if (hasDescription(description)) {
      return description;
    }
  }
};

export default getOfferDescription;
