import React from "react";
import withAutoLogin from "../../../../../hocs/withAutoLogin";

const AutoLogin = () => (
  <div className="social-redirect-page">
    <i className="fal fa-spinner fa-spin" />
  </div>
);

export default withAutoLogin(AutoLogin);
