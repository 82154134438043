export const countLeaveTouchDelay = ({
  title,
  defaultDelay = 2000
}: {
  title: string,
  defaultDelay: number
}) => {
  if (typeof title !== "string") {
    return defaultDelay;
  }

  const AVERAGE_WORD_PER_MINUTE = 210;
  const AVERAGE_WORD_LENGTH = 5;

  const AVERAGE_CHARACTERS_PER_MINUTE =
    AVERAGE_WORD_PER_MINUTE * AVERAGE_WORD_LENGTH;

  const titleCharacters = title.length;

  const readingTimePerMinute = titleCharacters / AVERAGE_CHARACTERS_PER_MINUTE;

  return Math.ceil(readingTimePerMinute * 60000);
};
