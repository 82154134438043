import React from "react";
import { withRouter } from "react-router-dom";
import withTranslations from "../../../../../hocs/withTranslations";
import navigateToDesktop from "../../../../../lib/navigateToDesktop";
import Tag from "../../Tag/";

import "../styles/default.scss";

@withTranslations
@withRouter
class DesktopTagsList extends React.Component {
  LIMIT = 15;

  state = {
    expanded: false
  };

  toggleExpanded() {
    this.setState(({ expanded }) => ({
      expanded: !expanded
    }));
  }

  render() {
    const { LIMIT } = this;
    const { translate, locale, allAvailableTags, history } = this.props;
    const { expanded } = this.state;

    let tags = allAvailableTags.data;

    const realTagsLength = tags ? tags.length : Infinity;
    const couldShowMore = realTagsLength > LIMIT;

    tags = tags.slice(0, expanded ? Infinity : LIMIT);

    return (
      <div className="desktop-tags-list">
        <h5>{translate("Browse tags")}</h5>
        <div className="tags-list">
          {tags.map(tag => (
            <Tag
              key={tag.id}
              {...tag}
              onClick={() => {
                navigateToDesktop(tag, history, locale);
              }}
            />
          ))}
          {couldShowMore ? (
            <div
              className="toggle-expanded"
              onClick={() => {
                this.toggleExpanded();
              }}
            >
              {!expanded ? (
                <span>
                  {translate("Show more")} ({realTagsLength - tags.length})
                </span>
              ) : (
                <span>{translate("Show less")}</span>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

export default DesktopTagsList;
