import React, { useEffect } from "react";
import moment from "moment-mini";
import { compose } from "redux";
import Formsy from "formsy-react";
import { globalConfigFields } from "../../../../../redux/modules/globalConfig";
import withAffiliateProgram from "../../../../../hocs/withAffiliateProgram";
import withAffiliateProgramForm from "../../../../../hocs/withAffiliateProgramForm";
import withGlobalConfigFields from "../../../../../hocs/withGlobalConfigFields";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import { UrlProvider } from "../../../../../api/UrlProvider";
import SquaberTable from "../../../common/SquaberTable";
import ContentSection from "../../../common/ContentSection";
import Loader from "../../../common/Loader/modules/default";
import SquaberTooltip from "../../../common/SquaberTooltip";
import config from "../../../../../config";
import SquaberButton from "../../../common/SquaberButton";

import "../styles/default.scss";

function AffiliateProgram({
  translate,
  affiliateProgram,
  getAffiliateDetails,
  getAffiliateHistory,
  locale,
  formBuilder,
  globalConfig,
  acceptRegulations
}) {
  useEffect(() => {
    getAffiliateDetails();
    getAffiliateHistory();
  }, [getAffiliateDetails, getAffiliateHistory]);

  const { affiliationTermsAndConditionsPdf } = globalConfig;

  const { webAppUrl = "" } = config;
  const {
    detailsLoading,
    historyLoading,
    history = [],
    details
  } = affiliateProgram;
  const isLoading = detailsLoading && historyLoading;

  const {
    token,
    amount_of_points: amountOfPoints = 0,
    amount_of_pending_points: amountOfPendingPoints = 0,
    agreement_accepted
  } = details || {};

  const hasHistory = Array.isArray(history) && history.length > 0;

  const affiliateLink = UrlProvider.getUrl(
    "fe.affiliateLink",
    {
      locale,
      token: token ? encodeURIComponent(token) : ""
    },
    webAppUrl
  );

  return (
    <div className="relation-program">
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <ContentSection>
            <h1>{translate("headline-affiliate-program")}</h1>
            <p>{translate("description-affiliate-program")}</p>
            <h2 className={"no-margin-bottom"}>
              <span className={"points"}>
                {translate("Your Points")}: {amountOfPoints}
              </span>
              {amountOfPendingPoints ? (
                <SquaberTooltip title="pending-points-tooltip">
                  <span className="pending-points">
                    ({translate("pending-points")}: {amountOfPendingPoints})
                  </span>
                </SquaberTooltip>
              ) : null}
            </h2>
          </ContentSection>
          {agreement_accepted ? (
            <ContentSection>
              <h2>{translate("headline-affiliate-link")}</h2>
              <p>{translate("description-affiliate-link")}</p>
              <div className="relation-link">{affiliateLink}</div>
            </ContentSection>
          ) : (
            <ContentSection>
              <h2>{translate("headline-affiliate-terms")}</h2>
              <p>{translate("description-affiliate-terms")}</p>
              <p>
                <a href={affiliationTermsAndConditionsPdf} target="_blank">
                  {translate("Download regulations document")}
                </a>
              </p>
              <Formsy
                onValidSubmit={() => {
                  acceptRegulations();
                }}
              >
                <FormBuilderWeb {...formBuilder} />
                <SquaberButton type="submit" disabled={!formBuilder.canSubmit}>
                  {translate("Accept")}
                </SquaberButton>
              </Formsy>
            </ContentSection>
          )}
          <ContentSection>
            <h2>{translate("headline-affiliate-history")}</h2>
            {hasHistory ? (
              <>
                <p>{translate("description-affiliate-history")}</p>
                <SquaberTable
                  withDistinctRows
                  heading={[
                    translate("Modified"),
                    translate("Created"),
                    translate("Description"),
                    translate("Points earned")
                  ]}
                  rows={history.map(
                    ({
                      balance_change: balanceChange,
                      description,
                      updated,
                      created
                    }) => {
                      return [
                        updated ? moment(updated).format("DD.MM.YYYY") : "",
                        created ? moment(created).format("DD.MM.YYYY") : "",
                        description || "",
                        balanceChange || ""
                      ];
                    }
                  )}
                />
              </>
            ) : (
              <p className="no-margin-bottom">
                {translate("secondary-description-affiliate-history")}
              </p>
            )}
          </ContentSection>
          <ContentSection>
            <p className="no-margin-bottom">
              <a href={affiliationTermsAndConditionsPdf} target="_blank">
                {translate("Download regulations document")}
              </a>
            </p>
          </ContentSection>
        </>
      )}
    </div>
  );
}

export default compose(
  withGlobalConfigFields([
    globalConfigFields.AFFILIATE_PROGRAM_TERMS_AND_CONDITIONS,
    globalConfigFields.AFFILIATE_PROGRAM_TERMS_AND_CONDITIONS_PDF
  ]),
  withAffiliateProgramForm,
  withAffiliateProgram
)(AffiliateProgram);
