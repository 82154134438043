import React from "react";
import NumberHelper from "../../../../../lib/UniversalTable/RendererHelpers/NumberHelper";

export default ({ value }) => {
  if (typeof value === "undefined" || value === null) {
    return null;
  }

  return (
    <div className="number-renderer">
      {NumberHelper.getFinalValue(value, false, true)}
    </div>
  );
};
