import React, { useState } from "react";
import PropTypes from "prop-types";
import colorArray from "../../../../../lib/colorArray";
import withTranslations from "../../../../../hocs/withTranslations";

import "../styles/default.scss";

const StockHoldersLegendItem = ({
  stockholder,
  stocksSum,
  index,
  translate
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <li className="legend-item">
      <div
        className="legend-tile"
        style={{
          background: colorArray[index]
        }}
      />
      <div className="legend-description">
        <span
          className="expander"
          onClick={() => {
            setIsExpanded(!isExpanded);
          }}
        >
          {stockholder.name} (
          {((stockholder.stock_count / stocksSum) * 100).toFixed(2)}%)
          <span className="legend-expander">
            {isExpanded ? (
              <i className="fal fa-angle-down" />
            ) : (
              <i className="fal fa-angle-right" />
            )}
          </span>
        </span>
        {isExpanded ? (
          <React.Fragment>
            <span>
              {translate("Stocks count")}: {stockholder.stock_count}
            </span>
            <span>
              {translate("Votes count")}: {stockholder.vote_count}
            </span>
            <span>
              {translate("Last update")}: {stockholder.last_update}
            </span>
          </React.Fragment>
        ) : null}
      </div>
    </li>
  );
};

StockHoldersLegendItem.propTypes = {};

export default withTranslations(StockHoldersLegendItem);
