import classNames from "classnames";
import moment from "moment-mini";
import React from "react";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withLatestMediaMonitorEntry from "../../../../../hocs/withLatestMediaMonitorEntry";
import withSimpleChartData from "../../../../../hocs/withSimpleChartData";
import getDesktopItemDetailsBoxes from "../../../../../lib/getDesktopItemDetailsBoxes";
import CustomSimpleChart from "../../CustomSimpleChart";
import SquaberLink from "../../SquaberLink";

const AdvancedView = props => {
  const {
    locale,
    translate,
    quoteData,
    latestMediaMonitorEntry,
    ticker,
    market,
    setValue,
    is_index,
    stock_id
  } = props;

  const { priceChangeBoxes, financialStatsBoxes } = getDesktopItemDetailsBoxes(
    props
  );

  return (
    <div className="advanced-view">
      <CustomSimpleChart
        showVolumeStudy={true}
        stockId={stock_id}
        quoteData={quoteData}
        ticker={ticker}
        locale={locale}
        market={market}
        showRedirectToFullChart={true}
        redirectToFullScrenChart={true}
      />
      <div className="advanced-view-data">
        {latestMediaMonitorEntry ? (
          <div className="advanced-view-section media-monitory-entry">
            <div className="media-monitor-entry-heading">
              <h5>{translate("Media monitor")}</h5>
              <div className="see-more-media-monitor-entries">
                <SquaberLink
                  to={UrlProvider.getUrl("fe.stockPageSection", {
                    locale,
                    marketId: market,
                    stockId: ticker,
                    section: "section-media-monitor"
                  })}
                  onClick={event => {
                    event.stopPropagation();
                    setValue("");
                  }}
                >
                  {translate("see all")}
                </SquaberLink>
              </div>
            </div>
            <div className="media-monitor-entry-date">
              {moment(latestMediaMonitorEntry.pub_datetime).format(
                "hh:mm, DD.MM.YYYY"
              )}
              , {latestMediaMonitorEntry.source}
            </div>
            <div className="media-monitor-entry-title">
              {latestMediaMonitorEntry.title}{" "}
              {latestMediaMonitorEntry.link ? (
                <React.Fragment>
                  [
                  <SquaberLink
                    to={latestMediaMonitorEntry.link}
                    target="_blank"
                  >
                    {translate("More")}
                  </SquaberLink>
                  ]
                </React.Fragment>
              ) : null}
            </div>
          </div>
        ) : null}
        <div className="advanced-view-section">
          <div className="advanced-view-boxes-list">
            {priceChangeBoxes.map(priceChangeBox => (
              <div className="advanced-view-box" key={priceChangeBox.label}>
                <div className="label">{priceChangeBox.label}</div>
                <div className="value">
                  {priceChangeBox.value !== null &&
                  typeof priceChangeBox.value !== "undefined" ? (
                    <span>{Number(priceChangeBox.value).toFixed(2)}%</span>
                  ) : (
                    "-"
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="advanced-view-section">
          <div
            className={classNames({
              "advanced-view-boxes-list": true,
              "has-6-elements": !is_index,
              "has-3-elements": is_index
            })}
          >
            {financialStatsBoxes.map(financialStatsBox => (
              <div className="advanced-view-box" key={financialStatsBox.label}>
                <div className="label">{financialStatsBox.label}</div>
                {financialStatsBox.label2 ? (
                  <div className="label-2">{financialStatsBox.label2}</div>
                ) : null}
                <div className="value">
                  {financialStatsBox.value}
                  {financialStatsBox.value2 ? (
                    <React.Fragment>
                      {" "}
                      / {financialStatsBox.value2}
                    </React.Fragment>
                  ) : null}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default compose(
  withSimpleChartData(props => props.stock_id),
  withLatestMediaMonitorEntry
)(AdvancedView);
