import React from "react";
import ApiClientAsync from "../api/ApiClientAsync";
import withMarketTransltaions from "./withMarketTranslations";
import { getDefaultMarketId } from "../lib/getDefaultMarketId";

export default function withLatestMediaMonitorEntry(WrappedComponent) {
  @withMarketTransltaions
  class WithLatestMediaMonitorEntry extends React.Component {
    state = {
      data: null,
      loading: true
    };

    async componentDidMount() {
      const {
        locale,
        authToken,
        ticker,
        market,
        marketTranslations
      } = this.props;
      const preparedMarketId = getDefaultMarketId(marketTranslations, market);

      this.apiClient = new ApiClientAsync(locale, authToken);

      const data = await this.apiClient.get({
        urlPath: "app.stocks.mediaMonitor",
        variables: {
          market: preparedMarketId,
          ticker,
          count: 1,
          start: 0
        }
      });

      if (data.results.length) {
        this.setState({
          data: data.results[0]
        });
      }

      this.setState({
        loading: false
      });
    }

    render() {
      const { data, loading } = this.state;

      return (
        <WrappedComponent
          {...this.props}
          latestMediaMonitorEntry={data}
          latestMediaMonitorEntryLoading={loading}
        />
      );
    }
  }

  return WithLatestMediaMonitorEntry;
}
