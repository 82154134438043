import "../styles/default.scss";

import React, { useState } from "react";
import { Col, Row } from "reactstrap";

import config from "../../../../../config";
import withTranslations from "../../../../../hocs/withTranslations";
import logo from "../../../../../logo";
import PageTitle from "../../../common/PageTitle/modules/default";
import SearchBar from "../../../common/SearchBar";

const SearchPage = ({ translate }) => {
  const [searchBarOpen, setSearchBarOpen] = useState(false);

  return (
    <div className="search-page">
      <div className="search-page-section">
        <PageTitle title={translate("Find your stock")} />
        <div className="logo-wrapper">
          <img src={logo.main} alt={config.title} />
        </div>
        <h1 className="page-title">{config.title}</h1>

        <ul className="benefits">
          <li>
            <span>{translate("Search for interesting stocks")}</span>
          </li>
          <li>
            <span>
              {translate("Check out our analyses and investing signals")}
            </span>
          </li>
          <li>
            <span>
              {translate("Find out what can happen to stocks in your wallet")}
            </span>
          </li>
        </ul>
        <Row>
          <Col sm={{ size: 6, order: 2, offset: 3 }}>
            <SearchBar
              inContent
              searchBarOpen={searchBarOpen}
              setSearchBarOpen={setSearchBarOpen}
            />
          </Col>
        </Row>
      </div>
    </div>
  );
};

SearchPage.propTypes = {};

export default withTranslations(SearchPage);
