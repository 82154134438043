export default function transformFormData(formData) {
  let dataToReturn = {};

  formData.forEach(field => {
    if (field.type !== "checkbox") {
      dataToReturn[field.name] = field.value;
    } else {
      dataToReturn[field.name] = Boolean(field.selected.length);
    }
  });

  return dataToReturn;
}
