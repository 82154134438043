import React, { useEffect, useState } from "react";
import { compose } from "redux";

import withAddAlert from "../../../../../hocs/withAddAlert";
import withTranslations from "../../../../../hocs/withTranslations";
import useWebNetworkStatus from "../../../../../hooks/useWebNetworkStatus";

const NetworkStatusProvider = ({
  translate,
  addWarningAlert,
  addSuccessAlert
}) => {
  const [isMounted, setIsMounted] = useState(false);

  const { networkStatus } = useWebNetworkStatus({
    defaultNetworkStatus: "online"
  });

  useEffect(() => {
    if (!isMounted) {
      setIsMounted(true);
      return;
    }

    if (networkStatus === "offline") {
      addWarningAlert({
        title: "offline_alert_title"
      });
    } else if (networkStatus === "online") {
      addSuccessAlert({
        title: "online_alert_title"
      });
    }
  }, [networkStatus]);

  return networkStatus === "offline" ? (
    <div className="content-section">
      <h1 className="big-heading questions-list-heading">
        {translate("offline_header")}
      </h1>
      <p>{translate("offline_description")}</p>
    </div>
  ) : null;
};

export default compose(withAddAlert, withTranslations)(NetworkStatusProvider);
