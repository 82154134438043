import React from "react";
import { getFinancialSituation } from "../redux/modules/financialSituation/selector";
import { fetchFinancialSituation } from "../redux/modules/financialSituation";
import connect from "../i18n/connect";

export default function withFinancialSituationData(WrappedComponent) {
  const mapStateToProps = state => ({
    data: getFinancialSituation(state).data,
    loading: getFinancialSituation(state).loading
  });

  const mapDispatchToProps = {
    fetchFinancialSituation
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithFinancialSituationData extends React.Component {
    componentDidMount() {
      this.props.fetchFinancialSituation({ stockId: this.props.stockId });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithFinancialSituationData;
}
