import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

export interface StockAlertEventMetadata {
  stockId: number;
  ticker: string;
  market: string;
}

class StockAlertOnEvent extends BaseEvent {
  constructor(stockAlertEventMetadata: StockAlertEventMetadata) {
    super();
    this.stockAlertEventMetadata = stockAlertEventMetadata;
  }

  getEventName() {
    return "stock_alert_on";
  }

  getEventTriggerName() {
    return "User turned ON alert on stock";
  }

  getEventDetailsMatrix() {
    return `detail5=stock id
detail6=ticker
detail7=market`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: String(this.stockAlertEventMetadata.stockId),
      detail6: String(this.stockAlertEventMetadata.ticker),
      detail7: String(this.stockAlertEventMetadata.market)
    };
  }
}

export default StockAlertOnEvent;
