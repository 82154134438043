import responseStatus from "../../../../../lib/api/responseStatus";

const unsubscribeNotificationType = async (
  apiClient,
  { notificationType, notificationChannel, userId, hash }
) => {
  try {
    await apiClient.put({
      urlPath: "app.notification.unsubscribe.notificationType",
      data: {},
      variables: {
        notificationType,
        notificationChannel,
        userId,
        hash
      }
    });

    return { status: responseStatus.OK };
  } catch (e) {
    return { status: responseStatus.ERROR };
  }
};

export default unsubscribeNotificationType;
