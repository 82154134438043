import { createAction } from "redux-actions";

const namespace = "app/bottomNavigation";

const SET_VALUE_ACTION = `${namespace}/SET_VALUE`;

export const constants = {
  SET_VALUE_ACTION
};

const setValueAction = createAction(SET_VALUE_ACTION, value => ({ value }));

export const actions = {
  setValueAction
};

const initialState = {
  currentValue: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_VALUE_ACTION:
      let previousValue = state.currentValue;
      let newValue = action.payload.value;

      return {
        currentValue: previousValue !== newValue ? newValue : null
      };

    default:
      return state;
  }
}
