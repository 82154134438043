import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import moment from "moment-mini";
import withUserData from "../../../../../hocs/withUserData";
import withLoadMoreTransactionSignals from "../../../../../hocs/withLoadMoreTransactionSignals";
import getAccessLevel from "../../../../../lib/access/getAccessLevel";
import accessLevelsMap from "../../../../../lib/access/accessLevelsMap";
import SquaberTable from "../../SquaberTable";
import SquaberButton from "../../SquaberButton";
import Price from "../../../../../common/Price";
import NoAccessDisclaimer from "../../NoAccessDisclaimer";

import "../styles/default.scss";

@withUserData
@withLoadMoreTransactionSignals
class AutomaticTransactionSignal extends React.Component {
  static propTypes = {
    strategy: PropTypes.object,
    signals: PropTypes.array,
    currency: PropTypes.string
  };

  userHasAccess() {
    const { userData, userStock } = this.props;

    const accessLevel = getAccessLevel(userData, userStock);
    return accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS;
  }

  render() {
    const {
      strategy,
      signals,
      currency,
      translate,
      stockId,
      getMoreTransactionSignals,
      mediaMonitorEntriesLoading
    } = this.props;

    const userHasAccess = this.userHasAccess();

    return (
      <div
        className={classNames({
          "automatic-transation-signal": true,
          [strategy.notification_type]: true,
          blurred: !userHasAccess
        })}
      >
        <div
          className="description"
          dangerouslySetInnerHTML={{ __html: strategy.description }}
        />
        {!userHasAccess ? <NoAccessDisclaimer /> : null}
        <SquaberTable
          className="automatic-transaction-signal-table"
          withDistinctRows
          heading={["Date", "Type", "Price", "SL", "TP"]}
          rows={signals.map(signal => [
            moment(signal.datetime_utc).format("DD.MM.YYYY HH:mm [(UTC)]"),
            userHasAccess ? translate(`transaction-signal-${signal.type}`) : "",
            <Price
              value={userHasAccess ? signal.price : 0}
              currency={currency}
            />,
            signal.sl ? (
              <Price
                value={userHasAccess ? signal.sl : 0}
                currency={currency}
              />
            ) : (
              " "
            ),
            signal.tp ? (
              <Price
                value={userHasAccess ? signal.tp : 0}
                currency={currency}
              />
            ) : (
              " "
            )
          ])}
        />
        {userHasAccess && !strategy.allTransactionSignalsShown ? (
          <div className="button-wrapper">
            <SquaberButton
              disabled={mediaMonitorEntriesLoading[strategy.id]}
              onClick={() => {
                getMoreTransactionSignals({
                  stockId,
                  strategyId: strategy.id,
                  limit: 10,
                  offset: signals.length
                });
              }}
            >
              {translate("More")}
            </SquaberButton>
          </div>
        ) : null}
      </div>
    );
  }
}

export default AutomaticTransactionSignal;
