import "../styles/default.scss";

import classNames from "classnames";
import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";

import withPreloadedStockPageData from "../../../../../hocs/withPreloadedStockPageData";
import withQuoteData from "../../../../../hocs/withQuoteData";
import Loader from "../../Loader/modules/default";
import SquaberCard from "../../SquaberCard";
import Quote from "../components/Quote";
import StockInfo from "../components/StockInfo";
import withMarketTransltaions from "../../../../../hocs/withMarketTranslations";
import { getTranslatedMarket } from "../../../../../lib/getTranslatedMarket";

const getClassName = type => {
  switch (type) {
    case "simple":
      return "stock-card-simple";
    default:
      return "stock-card-default";
  }
};

function StockCard({
  type = "default",
  id,
  ticker,
  market,
  name,
  close,
  change,
  onClick,
  currency,
  quoteData,
  setPreloadedStock,
  marketTranslations,
  locale
}) {
  const canGetQuoteData = !!id;
  const { change: quoteChange, close: quoteClose } = quoteData ?? {};
  const loadingQuote = !quoteClose && !quoteChange && canGetQuoteData;

  const translatedMarketId = getTranslatedMarket(
    locale,
    market,
    marketTranslations
  );

  const preparedData = {
    market: translatedMarketId,
    ticker: ticker.toLowerCase()
  };

  return (
    <SquaberCard
      onClick={() => {
        onClick?.(preparedData);
        setPreloadedStock?.({
          id,
          ticker,
          name: market,
          currency
        });
      }}
    >
      <div className={classNames("stock-card", getClassName(type))}>
        <div className="stock-info-wrapper">
          <StockInfo ticker={ticker} market={market} name={name} type={type} />
        </div>
        {loadingQuote ? (
          <Loader className={"stock-card__loader"} />
        ) : (
          <Quote
            close={close}
            change={change}
            quoteClose={quoteClose}
            quoteChange={quoteChange}
            currency={currency}
          />
        )}
      </div>
    </SquaberCard>
  );
}

StockCard.propTypes = {
  type: PropTypes.string,
  ticker: PropTypes.string,
  market: PropTypes.string,
  name: PropTypes.string,
  change: PropTypes.string,
  close: PropTypes.string,
  currency: PropTypes.string
};

export default compose(
  withPreloadedStockPageData,
  withQuoteData(false, props => props?.id),
  withMarketTransltaions
)(StockCard);
