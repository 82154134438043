import React from "react";
import { isEmpty } from "lodash";
import Formsy from "formsy-react";
import moment from "moment-mini";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import ModalBase from "../../ModalBase";
import SquaberButton from "../../../common/SquaberButton";
import withTranslations from "../../../../../hocs/withTranslations";
import withPriceAlertsModal from "../../../../../hocs/withPriceAlertsModal";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import SquaberTable from "../../../common/SquaberTable";

import "../styles/default.scss";

@withTranslations
@withPriceAlertsModal
class PriceAlertsModal extends ModalBase {
  componentDidMount() {
    const { price, setPrice } = this.props;
    setPrice(price);
  }

  renewButton(alertId, value, comment, sent_date) {
    const { renewAlert } = this.props;

    const sentDate = `${moment(sent_date).format("DD.MM.YYYY")}, ${moment(
      sent_date
    ).format("HH:mm:SS")}`;

    return (
      <span
        className="price-alert-action"
        onClick={() => renewAlert({ id: alertId, value, comment })}
      >
        <span>
          {sentDate}
          <i className="fal fa-redo" />
        </span>
      </span>
    );
  }
  removeButton(alertId) {
    const { removeAlert } = this.props;

    return (
      <span
        className="price-alert-action"
        onClick={() => removeAlert({ id: alertId })}
      >
        <i className="far fa-trash-alt" />
      </span>
    );
  }

  getContent() {
    const {
      translate,
      formBuilder,
      hideModal,
      priceAlerts = [],
      currency,
      addAlert
    } = this.props;

    return (
      <div className={"price-alerts-modal"}>
        <DialogTitle id="form-dialog-title">
          {translate("Price Alerts")}
          <span
            className="close"
            aria-label={translate("Close")}
            onClick={hideModal}
          >
            <i className="fal fa-times" />
          </span>
        </DialogTitle>
        <DialogContent>
          <Formsy
            ref={element => (this.form = element)}
            onValidSubmit={() => {
              addAlert({
                fields: formBuilder.fields,
                form: this.form,
                clearFormBuilder: formBuilder.clear
              });
            }}
          >
            <FormBuilderWeb {...formBuilder} />
            <SquaberButton type="submit">
              {translate("Add alert")}
            </SquaberButton>
          </Formsy>
          <div className={"alerts-wrapper"}>
            {!isEmpty(priceAlerts) ? (
              <>
                <p>{translate("Your price alerts")}:</p>
                <SquaberTable
                  withDistinctRows
                  className="partnership-tokens-list"
                  heading={[
                    translate("Price"),
                    translate("Description"),
                    translate("Sent"),
                    translate("Delete")
                  ]}
                  rows={priceAlerts
                    .sort((a, b) => Number(a.price) - Number(b.price))
                    .map(({ comment, price, sent_date, id }) => [
                      `${Number(price).toFixed(2)}\xa0${currency}`,
                      comment ? comment : "-",
                      sent_date
                        ? this.renewButton(id, price, comment, sent_date)
                        : translate("No"),
                      this.removeButton(id)
                    ])}
                />
              </>
            ) : (
              <p>{translate("price-alerts-modal-no-alerts")}</p>
            )}
          </div>
        </DialogContent>
      </div>
    );
  }
}

export default PriceAlertsModal;
