import React from "react";

import { connect } from "../i18n/connect";
import { actions as priceAlertActions } from "../redux/modules/priceAlerts";
import { getStockCurrency } from "../redux/modules/stockPageTvChart/selector";
import { getPriceAlertsData } from "../redux/modules/tvchartData/selector";
import withFormBuilder from "./withFormBuilder";

export default function withPriceAlertsModal(WrappedComponent) {
  const mapStateToProps = state => ({
    priceAlerts: getPriceAlertsData(state),
    currency: getStockCurrency(state)
  });

  const mapDispatchToProps = {
    addPriceAlert: priceAlertActions.addPriceAlert,
    deletePriceAlert: priceAlertActions.deletePriceAlert
  };

  @connect(mapStateToProps, mapDispatchToProps)
  @withFormBuilder(() => [
    {
      name: "value",
      type: "text",
      value: "",
      label: "Price",
      required: false,
      fullWidth: true,
      validations: {
        customIsFloat: ({ value = "" }) => {
          const parsedValue = value.replace(",", ".");
          return !isNaN(parsedValue) ? true : "This is not a valid price";
        }
      },
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "comment",
      type: "text",
      fullWidth: true,
      value: "",
      label: "Description",
      required: false,
      maxLength: 100
    }
  ])
  class WithPriceAlertsModal extends React.Component {
    setPrice(price) {
      const priceField = this.props.formBuilder?.fields[0];

      if (typeof price === "undefined" || typeof priceField === "undefined") {
        return;
      }

      priceField.value = price.toFixed(2).toString();
    }
    addAlert({ fields, form, clearFormBuilder }) {
      const { addPriceAlert, stockId } = this.props;

      const payload = {
        value: fields[0].value,
        comment: fields[1].value
      };
      addPriceAlert(stockId, payload, form, clearFormBuilder);
    }
    removeAlert({ id }) {
      const { deletePriceAlert, stockId } = this.props;

      if (typeof stockId === "undefined") return;

      deletePriceAlert(stockId, id);
    }
    renewAlert({ id, value, comment }) {
      const { addPriceAlert, deletePriceAlert, stockId } = this.props;

      if (typeof stockId === "undefined") return;

      deletePriceAlert(stockId, id, true, () => {
        addPriceAlert(
          stockId,
          {
            value,
            comment
          },
          null,
          null
        );
      });
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          addAlert={payload => this.addAlert(payload)}
          removeAlert={payload => this.removeAlert(payload)}
          renewAlert={payload => this.renewAlert(payload)}
          setPrice={payload => this.setPrice(payload)}
        />
      );
    }
  }

  return WithPriceAlertsModal;
}
