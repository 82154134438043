import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";

export const resendConfirmationEmail = createRoutine(
  "app/emailConfirmation/resendConfirmationEmail"
);

export const initialState = {};

export default handleActions({}, initialState);
