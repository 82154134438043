import React from "react";
import { connect } from "../i18n/connect";
import {
  toggleSectionCollapsed,
  collapseSection,
  expandSection
} from "../redux/modules/collapsibleSections";

export default function withCollapsibleSectionControl(WrappedComponent) {
  const mapDispatchToProps = {
    toggleSectionCollapsed,
    expandSection,
    collapseSection
  };

  @connect(null, mapDispatchToProps)
  class WithCollapsibleSectionControl extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithCollapsibleSectionControl;
}
