import autoBind from "auto-bind";
import React from "react";
import { bindPromiseCreators } from "redux-saga-routines";

import connect from "../i18n/connect";
import {
  addChartTemplateRoutine,
  addChartTemplateRoutinePromiseCreator,
  clearErrorMessages,
  fetchChartTemplateDetailsRoutinePromiseCreator,
  fetchChartTemplatesRoutine,
  removeChartTemplateRoutine,
  updateChartTemplateRoutine
} from "../redux/modules/chartTemplates";
import { getChartTemplatesState } from "../redux/modules/chartTemplates/selector";
import Loader from "../web/components/common/Loader";

export default function withChartTemplates(WrappedComponent) {
  const mapStateToProps = state => ({
    chartTemplatesState: getChartTemplatesState(state)
  });

  const mapDispatchToProps = {
    fetchChartTemplates: fetchChartTemplatesRoutine,
    addChartTemplate: addChartTemplateRoutine,
    updateChartTemplate: updateChartTemplateRoutine,
    removeChartTemplate: removeChartTemplateRoutine,
    clearErrorMessages: clearErrorMessages
  };

  const additionalMapDispatchToProps = dispatch => ({
    ...bindPromiseCreators(
      {
        fetchChartTemplateDetails: fetchChartTemplateDetailsRoutinePromiseCreator,
        addChartTemplateAsync: addChartTemplateRoutinePromiseCreator
      },
      dispatch
    )
  });

  @connect(mapStateToProps, mapDispatchToProps)
  @connect(null, additionalMapDispatchToProps)
  class WithChartTemplates extends React.Component {
    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    componentDidMount(): void {
      this.props.fetchChartTemplates();
    }

    render() {
      const {
        chartTemplatesState: { list }
      } = this.props;

      if (list === null) {
        return <Loader />;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return WithChartTemplates;
}
