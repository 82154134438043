import "../styles/default.scss";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import React from "react";

import MarketsSettingsAbstract from "../../../../../abstract/MarketsSettingsAbstract";
import withUserMarkets from "../../../../../hocs/withUserMarkets";
import YellowSwitch from "../../../../../lib/SquaberThemeComponents/YellowSwitch";
import ContentSection from "../../../common/ContentSection";
import Loader from "../../../common/Loader";
import SquaberTable from "../../../common/SquaberTable";

class MarketsSettings extends MarketsSettingsAbstract {
  render() {
    const {
      translate,
      userMarkets: { markets, loading }
    } = this.props;

    return (
      <div className="markets-settings">
        <ContentSection>
          <h1>{translate("Markets")}</h1>
          <p>{translate("Choose markets you want to see in squaber.com")}</p>
          {!loading && markets ? (
            <SquaberTable
              className="markets-settings-table"
              heading={[translate("Market"), translate("Activity")]}
              rows={this.availableMarkets.map(market => [
                translate(market.toUpperCase()),
                <FormControlLabel
                  classes={{
                    root: "switcher-form-control",
                    label: "switcher-label"
                  }}
                  control={
                    <YellowSwitch
                      checked={markets.indexOf(market) !== -1}
                      onChange={event => {
                        const status = event.target.checked;
                        this.onMarketChange(market, status);
                      }}
                      value={market}
                      color="primary"
                    />
                  }
                  label={null}
                />
              ])}
            />
          ) : (
            <Loader />
          )}
        </ContentSection>
      </div>
    );
  }
}

export default withUserMarkets(MarketsSettings);
