import { md5 } from "js-md5";
import moment from "moment-mini";
import { Actions } from "react-native-router-flux";

import { getIsMobile, getIsWeb, getSystem } from "../../platformHelper";
import { ISalesManagoEvent } from "../interfaces/ISalesManagoEvent";
import type {
  ContactEventData,
  SalesManagoEventData
} from "../types/SalesManagoEventData.type";
import smEventTypes from "../utils/smEventTypes";
import { utf8ArrayToString } from "../utils/utf8ArrayToString";

export class BaseEvent implements ISalesManagoEvent {
  contactId = "";
  eventDate = "";

  constructor() {
    this.eventDate = this.getEventDate();
  }

  setContactId(contactId: string) {
    this.contactId = contactId;
  }

  getSalt() {
    // prettier-ignore
    const arr = [97, 102, 99, 99, 49, 55, 57, 98, 45, 54, 102, 51, 57, 45, 52, 98, 52, 57, 45, 56, 52, 52, 97, 45, 52, 52, 56, 101, 57, 98, 102, 50, 102, 99, 48, 57];
    return utf8ArrayToString(arr);
  }

  getEventDate() {
    return String(moment().unix() * 1000);
  }

  getFingerprint(): string {
    return md5.hex(this.getSalt() + this.contactId + this.eventDate);
  }

  getEventName() {
    throw new Error("Not implemented");
  }

  getEventSystem() {
    const system = getSystem();

    if (system === "web") {
      return getIsMobile() ? "mobile" : "web";
    }

    return system;
  }

  getCurrentUrlOrView() {
    if (getIsWeb() && typeof window !== "undefined") {
      return window.location.pathname;
    } else if (Actions) {
      return Actions.currentScene;
    }
  }

  getContactEventData(): ContactEventData {
    // Empty by default, not throwing "Not implemented" because there might be events
    // which don't have any additional details (e.g. page visit)
    return {};
  }

  getEventTriggerName() {
    return "";
  }

  getEventDetailsMatrix() {
    return "";
  }

  getEventDescription() {
    return `${this.getEventTriggerName()}
detail1=event name
detail2=platform
detail3=url
${this.getEventDetailsMatrix()}`;
  }

  getCurrentTime() {
    return moment().format("HH:mm:ss");
  }

  getSMEventData(): SalesManagoEventData {
    return {
      contactId: this.contactId,
      contactEvent: {
        date: this.eventDate,
        contactExtEventType: smEventTypes.OTHER, // Overridable by getContactEventData
        description: this.getEventDescription(),
        detail1: this.getEventName(),
        detail2: this.getEventSystem(),
        detail3: this.getCurrentUrlOrView(),
        detail4: this.getCurrentTime(),
        ...this.getContactEventData()
      }
    };
  }
}
