import { fork, put, takeLatest } from "redux-saga/effects";
import moment from "moment-mini";
import ApiClient from "../../../api/ApiClient";
import { getIsWeb } from "../../../lib/platformHelper";
import { actions as alertsActions } from "../alerts";
import {
  acceptRegulations,
  affiliate,
  getAffiliateDetails,
  getAffiliateHistory,
  setAffiliateCookie
} from "./index";
import config from "../../../config";

let Cookies;
const { numberOfDaysUntilAffiliateCookieExpiry } = config;
const affiliateCookieName = "squaber_affiliate_token";
const affiliationCreatedOnCookieName = "squaber_affiliate_token_created_at";

if (getIsWeb()) {
  Cookies = require("js-cookie");
}

let affiliateActionRequested = false;

function* onSetAffiliateCookie({ payload }) {
  try {
    if (!getIsWeb()) return;

    const { token } = payload || {};

    if (typeof token !== "string" && typeof token !== "number") {
      throw Error("Suplement payload token is not a string nor a number");
    }

    yield Cookies.set(affiliateCookieName, token, {
      expires: numberOfDaysUntilAffiliateCookieExpiry
    });
    yield Cookies.set(
      affiliationCreatedOnCookieName,
      // Get current time and cut off timezone offset info
      moment()
        .format()
        .split("+")[0],
      {
        expires: numberOfDaysUntilAffiliateCookieExpiry
      }
    );
  } catch (error) {
    console.error(error);
  }
}

function* getAffiliateCookies() {
  try {
    if (!getIsWeb()) return;

    return [
      Cookies.get(affiliateCookieName),
      Cookies.get(affiliationCreatedOnCookieName)
    ];
  } catch (error) {
    console.error(error);
  }
}

function* removeAffiliateCookie() {
  try {
    if (!getIsWeb()) return;

    yield Cookies.remove(affiliateCookieName);
  } catch (error) {
    console.error(error);
  }
}

function* onAffiliate() {
  try {
    const [token, created] = yield getAffiliateCookies();

    if (affiliateActionRequested || typeof token === "undefined") return;

    yield ApiClient.post({
      urlPath: "app.affiliateProgram.relation",
      data: { token, created }
    });

    yield removeAffiliateCookie();

    affiliateActionRequested = true;

    yield put(
      alertsActions.addSuccessAlert({
        title: "Affiliate link was successfully used"
      })
    );
  } catch (error) {
    if (error.request && error.request.response) {
      const response = JSON.parse(error.request.response);
      const { message } = response;

      yield put(
        alertsActions.addErrorAlert({
          title: message || "Affiliate link can't be used"
        })
      );
    }
  }

  yield removeAffiliateCookie();
}

function* onGetAffiliateProgramDetails() {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.affiliateProgram.details"
    });

    yield put(getAffiliateDetails.success(data));
  } catch (error) {
    yield put(getAffiliateDetails.failure(error));
    console.error(error);
  }
}

function* onGetAffiliateProgramHistory() {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.affiliateProgram.history"
    });

    yield put(getAffiliateHistory.success(data));
  } catch (error) {
    yield put(getAffiliateHistory.failure(error));
  }
}

function* onAcceptRegulations() {
  try {
    yield ApiClient.post("app.affiliateProgram.acceptRegulations");
    yield put(getAffiliateDetails.trigger());
  } catch (e) {
    console.log(e);
  }
}

function* watchAffiliateProgram() {
  yield takeLatest(getAffiliateDetails.TRIGGER, onGetAffiliateProgramDetails);
  yield takeLatest(getAffiliateHistory.TRIGGER, onGetAffiliateProgramHistory);
  yield takeLatest(acceptRegulations.TRIGGER, onAcceptRegulations);
  yield takeLatest(affiliate, onAffiliate);
  yield takeLatest(setAffiliateCookie, onSetAffiliateCookie);
}

export default [fork(watchAffiliateProgram)];
