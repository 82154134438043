import React from "react";

import { connect } from "../i18n/connect";
import { setPreloadedStock } from "../redux/modules/preloadedStockPageData";
import { getStockFromStockPageData } from "../redux/modules/preloadedStockPageData/selector";

export default function withPreloadedStockPageData(WrappedComponent) {
  const mapStateToProps = state => ({
    preloadedStockPageData: {
      stock: getStockFromStockPageData(state)
    }
  });

  const mapDispatchToProps = {
    setPreloadedStock: setPreloadedStock
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithStockPageData extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithStockPageData;
}
