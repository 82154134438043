import "../styles/default.scss";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import classNames from "classnames";
import React from "react";

import AlertManagementSwitchersAbstract from "../../../../../abstract/AlertManagementSwitchersAbstract";
import withTranslations from "../../../../../hocs/withTranslations";
import YellowSwitch from "../../../../../lib/SquaberThemeComponents/YellowSwitch";
import SquaberTooltip from "../../SquaberTooltip";

class AlertManagementSwitchers extends AlertManagementSwitchersAbstract {
  handleChange(element, value) {
    const { onElementChange } = this.props;

    element.status = value;

    onElementChange(element);
  }

  getElement({ element, isHeader }) {
    const { translate } = this.props;
    const { type, value, category } = element;

    if (type !== "description" && type !== "switcher") {
      return null;
    }

    switch (type) {
      case "switcher":
        return (
          <React.Fragment>
            <span
              className={classNames({
                "switch-wrapper": true,
                disabled: element.disabled || element.globallyDisabled,
                "globally-disabled": element.globallyDisabled
              })}
            >
              <FormControlLabel
                classes={{
                  root: "switcher-form-control",
                  label: "switcher-label"
                }}
                control={
                  <YellowSwitch
                    checked={element.globallyDisabled ? false : value}
                    onChange={() => {
                      this.handleChange(element, !value);
                    }}
                    disabled={element.disabled || element.globallyDisabled}
                    value={`${element.category}_${element.channels.join("_")}`}
                    color="primary"
                  />
                }
                label={translate(element.label)}
              />
            </span>
            {element.globallyDisabled ? (
              <span className="globally-disabled-notice">
                {translate("This setting is disabled globally")}
              </span>
            ) : null}
          </React.Fragment>
        );
      case "description":
      default:
        if (isHeader) {
          return <span className="option-description">{translate(value)}</span>;
        }

        return (
          <span className="option-description">
            {translate(value)}{" "}
            {category ? (
              <SquaberTooltip title={`tooltip_notifications_${category}`}>
                <i className="fal fa-info-circle"></i>
              </SquaberTooltip>
            ) : null}
          </span>
        );
    }
  }

  render() {
    const { disabled } = this.props;
    const { processedElements } = this.state;

    return (
      <div
        className={classNames({
          "alert-management-switchers": true,
          disabled
        })}
      >
        {processedElements.map((group, index) => (
          <div className="switcher-group" key={index}>
            {group.map((element, key) => (
              <div className="switcher-element" key={key}>
                {this.getElement({ element, isHeader: index === 0 })}
              </div>
            ))}
          </div>
        ))}
      </div>
    );
  }
}

export default withTranslations(AlertManagementSwitchers);
