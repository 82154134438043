import React from "react";
import moment from "moment-mini";
import MediaMonitorAbstract from "../../../../../abstract/MediaMonitorAbstract";
import withLoadMoreMediaMonitorEntries from "../../../../../hocs/withLoadMoreMediaMonitorEntries";
import withUserData from "../../../../../hocs/withUserData";
import SquaberTable from "../../SquaberTable";
import SquaberButton from "../../SquaberButton";
import MediaMonitorEntryContent from "../../MediaMonitorEntryContent/modules/default";
import PremiumIcon from "../../PremiumIcon/modules/default";

import "../styles/default.scss";

@withLoadMoreMediaMonitorEntries
@withUserData
class MediaMonitor extends MediaMonitorAbstract {
  render() {
    const {
      data,
      mediaMonitorEntriesLoading,
      translate,
      userHasAccess
    } = this.props;

    return (
      <div className="media-monitor">
        <SquaberTable
          withDistinctRows
          className="media-monitor-table"
          rows={data.messages.map(message => {
            const { pub_datetime, source } = message;
            const hour = moment(pub_datetime).format("HH:mm");

            return [
              <React.Fragment>
                {hour !== "00:00" ? <div>{hour}</div> : null}
                <div>{moment(pub_datetime).format("DD.MM.YYYY")}</div>
                <div>{source}</div>
              </React.Fragment>,
              <React.Fragment>
                {message.is_premium ? (
                  <PremiumIcon userHasAccess={userHasAccess} />
                ) : null}
              </React.Fragment>,
              <MediaMonitorEntryContent {...message} />
            ];
          })}
        />
        <div className="button-wrapper text-center">
          {!data.is_last_page ? (
            <SquaberButton
              disabled={mediaMonitorEntriesLoading}
              onClick={this.onButtonClick}
            >
              <span>
                {translate("More")}
                {mediaMonitorEntriesLoading ? (
                  <i className="loading-indicator fal fa-spinner fa-spin" />
                ) : null}
              </span>
            </SquaberButton>
          ) : null}
        </div>
      </div>
    );
  }
}

export default MediaMonitor;
