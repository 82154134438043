import React from "react";
import { connect } from "../i18n/connect";
import withFormBuilder from "./withFormBuilder";
import { resetPasswordRoutine } from "../redux/modules/resetPassword";

export default function withResetPasswordModal(WrappedComponent) {
  const mapStateToProps = state => ({});

  const mapDispatchToProps = {
    trigger: resetPasswordRoutine
  };

  const fields = [
    {
      name: "user",
      label: "User name or email",
      type: "text",
      required: true,
      fullWidth: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      value: ""
    }
  ];

  @connect(mapStateToProps, mapDispatchToProps)
  @withFormBuilder(fields)
  class WithResetPasswordModal extends React.Component {
    state = {};

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithResetPasswordModal;
}
