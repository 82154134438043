import { isEmpty } from "lodash";

export const getTranslatedMarket = (locale, market, marketList) => {
  if (locale && market && !isEmpty(marketList)) {
    for (let key in marketList) {
      if (
        marketList[key][locale] === market.toLowerCase() ||
        key === market.toLowerCase()
      ) {
        return marketList[key][locale];
      }
    }
  }
  return market;
};
