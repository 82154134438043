export default function getDividedNumberString(value: number) {
  const reversedValueArray = String(value)
    .split("")
    .reverse();

  let dividedValueArray = [];

  reversedValueArray.forEach((char, key) => {
    dividedValueArray.push(char);
    if (key % 3 === 2) {
      dividedValueArray.push(" ");
    }
  });

  return dividedValueArray
    .reverse()
    .join("")
    .trim();
}
