import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";

export const getHomeLandingPageRoutine = createRoutine(
  "app/homeLandingPage/GET_HOME_LANDING_PAGE"
);

const initialState = {
  data: null,
  error: null,
  loading: true
};

export default handleActions(
  {
    [getHomeLandingPageRoutine.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [getHomeLandingPageRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      data: payload
    }),
    [getHomeLandingPageRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      error: payload,
      loading: false
    })
  },
  initialState
);
