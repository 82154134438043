import theme from "../index";

export default {
  typography: {
    useNextVariants: true
  },
  palette: {
    primary: {
      "50": "#e0e3e7",
      "100": "#b3b9c2",
      "200": "#808b9a",
      "300": "#4d5c71",
      "400": "#263952",
      "500": theme.colors.baseGrey2,
      "600": "#00132f",
      "700": "#4d5c71",
      "800": "#000c21",
      "900": "#000615",
      A100: "#5569ff",
      A200: "#223cff",
      A400: theme.colors.baseGreyDark,
      A700: theme.colors.baseGrey2,
      contrastDefaultColor: "light"
    },
    secondary: {
      "50": "#e0e3e7",
      "100": "#b3b9c2",
      "200": "#808b9a",
      "300": "#4d5c71",
      "400": "#263952",
      "500": theme.colors.baseGrey2,
      "600": "#00132f",
      "700": "#4d5c71",
      "800": "#000c21",
      "900": "#000615",
      A100: "rgba(67, 232, 162, 0.31)",
      A200: "rgba(255, 255, 255, 0)", //backgroundColor
      A400: theme.colors.baseGreyDark, //hover
      A700: theme.colors.baseGrey2,
      contrastDefaultColor: "light"
    },
    background: {
      default: "#ffffff"
    }
  }
};
