import React from "react";
import { bindPromiseCreators } from "redux-saga-routines";
import connect from "../i18n/connect";
import {
  connectTagToStockRoutinePromiseCreator,
  disconnectTagFromStockRoutinePromiseCreator
} from "../redux/modules/tags";
import TagsHelper from "../lib/TagsHelper/TagsHelper";
import { getAllTags } from "../redux/modules/tagsService/selector";
import { updateTag } from "../redux/modules/tagsService";

export default function withAllTagsList(WrappedComponent) {
  const mapStateToProps = state => ({
    allTags: getAllTags(state)
  });

  const mapDispatchToProps = dispatch => ({
    ...bindPromiseCreators(
      {
        connectTagToStockRoutinePromiseCreator,
        disconnectTagFromStockRoutinePromiseCreator
      },
      dispatch
    )
  });

  const additionalMapDispatchToProps = {
    updateTag
  };

  @connect(mapStateToProps, mapDispatchToProps)
  @connect(null, additionalMapDispatchToProps)
  class WithAllTagsList extends React.Component {
    render() {
      const { allTags: allTagsBase } = this.props;

      const allTags = {
        loading: false,
        error: null,
        data: allTagsBase.filter(tag => TagsHelper.isUserTag(tag))
      };

      return <WrappedComponent {...this.props} allTags={allTags} />;
    }
  }

  return WithAllTagsList;
}
