const canAccessLink = ({ link, userData, userHasAccess }) => {
  const { show_for_anonymous, show_for_freemium, show_for_premium } =
    link ?? {};

  let canShow;

  if (!userData) {
    canShow = show_for_anonymous;
  } else if (!userHasAccess) {
    canShow = show_for_freemium;
  } else {
    canShow = show_for_premium;
  }

  return canShow;
};

export default canAccessLink;
