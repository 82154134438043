import { fork, put, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import { actions as alertsActions } from "../alerts";
import { setPriceAlerts } from "../stockData";
import { setPriceAlerts as setChartPriceAlerts } from "../tvchartData";
import { constants, actions } from "./index";

function* onAddPriceAlert({
  payload: {
    stockId,
    alertData: { value, comment },
    form,
    clearFormBuilder
  }
}) {
  try {
    const requestPayload = {
      price: value
    };

    if (comment) {
      requestPayload.comment = comment;
    }

    const {
      data: { message }
    } = yield ApiClient.post({
      urlPath: "app.priceAlerts.add",
      variables: {
        stockId
      },
      data: requestPayload
    });

    yield put(
      alertsActions.addSuccessAlert({
        title: message
      })
    );

    yield put(actions.fetchPriceAlerts(stockId));

    if (form && form.reset) {
      form.reset();
    }
    if (clearFormBuilder) {
      clearFormBuilder();
    }
  } catch (error) {
    if (error?.request?.response) {
      const { message } = JSON.parse(error.request.response);
      yield put(
        alertsActions.addErrorAlert({
          title: message
        })
      );
    }
  }
}

function* onDeletePriceAlert({
  payload: { stockId, alertId, silent, callback }
}) {
  try {
    const {
      data: { message }
    } = yield ApiClient.delete({
      urlPath: "app.priceAlerts.delete",
      variables: {
        stockId,
        alertId
      }
    });

    if (!silent) {
      yield put(
        alertsActions.addSuccessAlert({
          title: message
        })
      );
    }

    if (callback && typeof callback === "function") {
      callback();
    }

    yield put(actions.fetchPriceAlerts(stockId));
  } catch (error) {
    const response = JSON.parse(error.request.response);

    if (response) {
      yield put(
        alertsActions.addErrorAlert({
          title: response.message || response.detail
        })
      );
    }
  }
}

function* onFetchPriceAlerts({ payload: { stockId } }) {
  try {
    const {
      data: { alerts: newAlerts }
    } = yield ApiClient.get({
      urlPath: "app.priceAlerts.get",
      variables: {
        stockId
      },
      skipAccessCheck: true
    });

    yield put(actions.fetchPriceAlertsSuccess(newAlerts));
    yield put(setPriceAlerts(newAlerts));
    yield put(setChartPriceAlerts(newAlerts));
  } catch (e) {
    yield put(actions.fetchPriceAlertsError(e));
  }
}

function* watchPriceAlerts() {
  yield takeLatest(constants.ADD_PRICE_ALERT, onAddPriceAlert);
  yield takeLatest(constants.DELETE_PRICE_ALERT, onDeletePriceAlert);
  yield takeLatest(constants.FETCH_PRICE_ALERTS, onFetchPriceAlerts);
}

export default [fork(watchPriceAlerts)];
