import classNames from "classnames";
import React from "react";

import useIsDesktop from "../../../hooks/useIsDesktop";

export const BoxIconTitle = ({
  icon,
  title,
  children,
  classNameChildren,
  classNameTitle
}) => {
  const isDesktop = useIsDesktop();

  return (
    <div
      className={classNames("flex items-center", {
        "gap-4": !isDesktop,
        "gap-6": isDesktop
      })}
    >
      <img
        src={icon}
        width={isDesktop ? "64" : "48"}
        height={isDesktop ? "64" : "48"}
        alt={""}
      />
      {children ? (
        <div className={classNameChildren}>
          <h3 className={classNameTitle}>{title}</h3>
          {children}
        </div>
      ) : (
        <h3 className={classNameTitle}>{title}</h3>
      )}
    </div>
  );
};
