import { createAction, handleActions } from "redux-actions";

export const SET_PARTNERSHIP_TOKEN_ELEMENT_VISIBLE =
  "app/partnershipTokenElementVisible/SET_PARTNERSHIP_TOKEN_ELEMENT_VISIBLE";

export const setPartnershipTokenElementVisible = createAction(
  SET_PARTNERSHIP_TOKEN_ELEMENT_VISIBLE,
  (value: boolean) => ({ value })
);

const initialState = true;

export default handleActions(
  {
    [SET_PARTNERSHIP_TOKEN_ELEMENT_VISIBLE]: (state, { payload }) =>
      payload.value
  },
  initialState
);
