import { isArray, isEmpty } from "lodash";

import canAccessLink from "../../../../../lib/Menu/canAccessLink";

const toDesktopNavigationItems = ({ menu, userData, userHasAccess }) => {
  if (!isArray(menu) || isEmpty(menu)) {
    return;
  }

  let items = [];

  for (const item of menu ?? []) {
    const {
      name,
      children,
      url,
      target,
      marketing_menu_message,
      onClick,
      native_view
    } = item ?? {};

    if (!canAccessLink({ link: item, userData, userHasAccess })) {
      continue;
    }

    const skipItemWithNativeView =
      ["", "#"].includes(url) && native_view !== "";

    if (skipItemWithNativeView) {
      continue;
    }

    items.push({
      label: name,
      children: toDesktopNavigationItems({
        menu: children,
        userData,
        userHasAccess
      }),
      onClick,
      href: url,
      target,
      additional: {
        ...(marketing_menu_message
          ? { marketingMessage: marketing_menu_message }
          : {})
      }
    });
  }

  return items;
};

export default toDesktopNavigationItems;
