import React from "react";
import { withRouter } from "react-router";
import withTranslations from "../../../../../hocs/withTranslations";
import withAllAvailableTagsList from "../../../../../hocs/withAllAvailableTagsList";
import withTagsSearchAutocomplete from "../../../../../hocs/withTagsSearchAutocomplete";
import { UrlProvider } from "../../../../../api/UrlProvider";
import TagsHelper from "../../../../../lib/TagsHelper/TagsHelper";
import SquaberButton from "../../../common/SquaberButton";
import SquaberLink from "../../../common/SquaberLink";
import TagsSettingsSearchBox from "../../TagsSettingsSearchBox";

import "../styles/default.scss";

@withTranslations
@withRouter
@withAllAvailableTagsList
@withTagsSearchAutocomplete(false)
class TagsSettingsTagsList extends React.Component {
  render() {
    const {
      translate,
      locale,
      history,
      allAvailableTags,
      inputValue,
      onInputChange,
      tagsWithQuery
    } = this.props;

    let tags = [];

    if (inputValue.length) {
      tags = tagsWithQuery.data;
    } else if (allAvailableTags?.data) {
      tags = allAvailableTags.data;
    }

    return (
      <div className="tags-settings-tags-list">
        <div className="tags-settings-tags-list-heading">
          <TagsSettingsSearchBox
            label={translate("Type tag you're looking for")}
            value={inputValue}
            onChange={event => {
              onInputChange(event.target.value);
            }}
          />
          <SquaberButton
            color="tertiary"
            fullWidth
            onClick={() => {
              history.push(
                UrlProvider.getUrl("fe.tagsSettingsAddTag", { locale })
              );
            }}
          >
            + {translate("Add new tag")}
          </SquaberButton>
        </div>
        <div className="tags-settings-tags-list-details">
          {tags
            .filter(tag => TagsHelper.isUserTag(tag))
            .map(tag => (
              <SquaberLink
                to={UrlProvider.getUrl("fe.tagSettings", {
                  locale,
                  id: tag.id
                })}
                key={tag.id}
              >
                <div className="tag-name">
                  <span>{tag.name}</span> <i className="fal fa-pen edit-icon" />
                </div>
                <div className="tag-description">{tag.description}</div>
              </SquaberLink>
            ))}
        </div>
      </div>
    );
  }
}

TagsSettingsTagsList.propTypes = {};

export default TagsSettingsTagsList;
