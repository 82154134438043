import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import ResourceResolver from "../../../../../lib/UniversalTable/ResourceResolver";
import ContentSection from "../../../common/ContentSection";
import PageTitle from "../../../common/PageTitle";
import UniversalTableLiteWeb from "../../../common/UniversalTableLiteWeb";

const InsiderTransactionsSummary = ({ translate }) => (
  <ContentSection>
    <PageTitle title={translate("insider_transactions_page_title")} />
    <h1>{translate("insider_transactions_summary_header")}</h1>
    <p
      dangerouslySetInnerHTML={{
        __html: translate("insider_transactions_summary_description")
      }}
    />
    <UniversalTableLiteWeb
      resourceId={ResourceResolver["insider_transactions_summary"]}
      fetchConfigParams={{
        urlPath: "app.csvData.financialReport.insiderTransactions.config",
        urlPathWithSettings:
          "app.csvData.financialReport.insiderTransactions.configWithSettings"
      }}
      fetchDataParams={{
        urlPath:
          "app.csvData.financialReport.insiderTransactions.tabWithOptions"
      }}
      defaultSettings={{
        sortBy: {
          field: "message_date",
          direction: "desc"
        }
      }}
      useUrlSettings
    />
  </ContentSection>
);

export default withTranslations(InsiderTransactionsSummary);
