import _ from "lodash";
import getUtcTimestamp from "./getUtcTimestamp";

class SquaberTVShapeManager {
  plotLevelColors = {
    Supply: {
      backgroundColor: "#e5d5d7",
      color: "#9b5a5f"
    },
    Demand: {
      backgroundColor: "#d5fcd5",
      color: "#5cf359"
    },
    None: {
      backgroundColor: "#e6e6e6",
      color: "#8b8b8b"
    }
  };

  constructor(shapes, stock) {
    this.shapes = shapes;
    this.stock = stock;
  }

  lineOptions() {
    return {
      shape: "ray",
      overrides: {
        linecolor: "#000000"
      }
    };
  }

  plotLevelOptions(plotLevel) {
    return {
      shape: "rectangle",
      overrides: this.plotLevelColors[plotLevel.type]
    };
  }

  preparePoint(pointData, dateKey) {
    if (!dateKey) {
      dateKey = "x";
    }

    return {
      time: getUtcTimestamp(new Date(pointData[dateKey])) / 1000,
      price: parseFloat(
        parseFloat(pointData["y"]).toFixed(
          Math.log(1 / parseFloat(this.stock.pipsize)) * Math.LOG10E
        )
      )
    };
  }

  manageSquaberShapesOnChart(data, intervalTypeId) {
    const shapeTypes = [
      {
        name: "trendlines",
        options: this.lineOptions.bind(this)
      },
      {
        name: "channels",
        options: this.lineOptions.bind(this)
      },
      {
        name: "plotlevels",
        options: this.plotLevelOptions.bind(this)
      },
      {
        name: "plot_levels",
        options: this.plotLevelOptions.bind(this)
      }
    ];

    return shapeTypes.map(shapeType => {
      const result = [];

      if (!data[shapeType.name]) {
        return;
      }

      for (let shapeData of data[shapeType.name]) {
        const shapeKey =
          shapeType.name + "_" + intervalTypeId + "_" + shapeData.id;

        if (!this.shapes[shapeKey]) {
          result.push(
            (this.shapes[shapeKey] = {
              intervalTypeId,
              points: [
                this.preparePoint(shapeData.points[0]),
                this.preparePoint(shapeData.points[1])
              ],
              options: shapeType.options(shapeData)
            })
          );
        } else {
          result.push(undefined);
        }
      }

      return result;
    });
  }

  setDefaultShapesOptions() {
    return Object.values(this.shapes).map(
      shape =>
        (shape.options = _.defaults(shape.options, {
          lock: true,
          disableSave: true,
          disableUndo: true,
          disableSelection: true,
          showInObjectsTree: false
        }))
    );
  }

  manage(data, intervalTypeId) {
    this.manageSquaberShapesOnChart(data, intervalTypeId);
    return this.setDefaultShapesOptions();
  }
}

export default SquaberTVShapeManager;
