import React from "react";
import connect from "../i18n/connect";
import { getPortfolioChartState } from "../redux/modules/portfolios/selector";
import { getPortfolioChartRoutine } from "../redux/modules/portfolios";

export default function withPortfolioChart(WrappedComponent) {
  const mapStateToProps = state => ({
    portfolioChart: getPortfolioChartState(state)
  });

  const mapDispatchToProps = {
    getPortfolioChart: getPortfolioChartRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithPortfolioChart extends React.Component {
    availableCharts = [
      { name: "rate_of_return_chart", label: "Rate of return" },
      { name: "chart", label: "Asset" }
    ];

    render() {
      return (
        <WrappedComponent
          {...this.props}
          availableCharts={this.availableCharts}
        />
      );
    }
  }

  return WithPortfolioChart;
}
