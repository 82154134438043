import React from "react";
import { throttle } from "lodash";
import autoBind from "auto-bind";
import withTagStocksData from "../../../../../hocs/withTagStocksData";
import Loader from "../../Loader/modules/default";
import DesktopStocksGridItem from "./DesktopStocksGridItem";

import "../styles/DesktopStocksGrid.scss";

@withTagStocksData(9)
class DesktopStocksGrid extends React.Component {
  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  componentDidMount() {
    this.throttledHandleScroll = throttle(this.handleScroll, 200);

    window.addEventListener("scroll", this.throttledHandleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.throttledHandleScroll);
  }

  handleScroll() {
    const { fetchMore, loading, next } = this.props;

    if (loading || !next) {
      return;
    }

    const estimatedDistanceFromBottomOfThePage =
      document.body.scrollHeight - (window.outerHeight + window.pageYOffset);

    if (estimatedDistanceFromBottomOfThePage < 300) {
      fetchMore();
    }
  }

  render() {
    const {
      results,
      loading,
      advancedView,
      isAlertedTag,
      translate
    } = this.props;

    return (
      <div className="desktop-stocks-grid">
        {results.map(result => (
          <DesktopStocksGridItem
            {...result}
            key={result.stock_id}
            advancedView={advancedView}
          />
        ))}
        {loading ? <Loader small /> : null}
        {!loading && !results.length ? (
          <div className="empty-portfolio-notice">
            {translate(
              isAlertedTag
                ? "There are no stocks in this desktop. Add an alert to stock to see it here."
                : "There are no stocks in this desktop. Add this tag to stock to see it here."
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

export default DesktopStocksGrid;
