import { takeLatest, fork, put } from "redux-saga/effects";
import { userNotesRoutine } from "./index";
import { updateUserNote } from "../stockData";

import ApiClient from "../../../api/ApiClient";

function* onSaveNote({ payload }) {
  try {
    yield ApiClient.put({
      urlPath: "app.notices.save",
      variables: {
        noticeId: payload.id
      },
      data: payload
    });

    yield put(updateUserNote(payload.text));
  } catch (error) {
    console.log(error);
  }
}

function* watchSaveNote() {
  yield takeLatest(userNotesRoutine.TRIGGER, onSaveNote);
}

export default [fork(watchSaveNote)];
