import { useCallback, useEffect, useState } from "react";

const defaultProps = { defaultNetworkStatus: null };

const useWebNetworkStatus = ({ defaultNetworkStatus } = defaultProps) => {
  const [networkStatus, setNetworkStatus] = useState(defaultNetworkStatus);

  const handleOnline = useCallback(() => {
    setNetworkStatus("online");
  }, []);

  const handleOffline = useCallback(() => {
    setNetworkStatus("offline");
  }, []);

  useEffect(() => {
    if (!navigator.onLine) {
      handleOffline();
    } else if (networkStatus !== "online") {
      handleOnline();
    }
  }, []);

  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [handleOnline, handleOffline]);

  return { networkStatus };
};

export default useWebNetworkStatus;
