const SquaberIntervalTypes = {
  one_minute: 1,
  five_minutes: 2,
  fifteen_minutes: 3,
  thirty_minutes: 4,
  one_hour: 5,
  four_hours: 6,
  daily: 7,
  weekly: 8,
  monthly: 9
};

export default SquaberIntervalTypes;
