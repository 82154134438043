import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

import withStockTagsList from "../../../../../hocs/withStockTagsList";
import StockPageTags from "../../../common/StockPageTags";
import ModalBase from "../../ModalBase";

@withStockTagsList
class StockTagsListModal extends ModalBase {
  getClassName() {
    return "stock-tags-list-modal is-mobile-page-sheet";
  }

  getContent() {
    const { translate, hideModal, stockTags, stockId } = this.props;

    const tags = stockTags.data;

    return (
      <React.Fragment>
        <DialogTitle id="interesting-stock-dialog-title">
          {translate("Stock tags")} <i className="far fa-tag" />
          <span className="close-dialog" onClick={() => hideModal()}>
            <i className="fal fa-times" />
          </span>
        </DialogTitle>
        <DialogContent>
          <StockPageTags
            tags={tags}
            stockId={stockId}
            isMobileTagsList={true}
            hideModal={hideModal}
          />
        </DialogContent>
      </React.Fragment>
    );
  }
}

StockTagsListModal.propTypes = {};

export default StockTagsListModal;
