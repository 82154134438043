import { createRoutine, promisifyRoutine } from "redux-saga-routines";
import { createAction } from "redux-actions";

const namespace = "app/search";

const SEARCH = `${namespace}/SEARCH`;
const SEARCH_ERROR = `${namespace}/SEARCH_ERROR`;
const SEARCH_SUCCESS = `${namespace}/SEARCH_SUCCESS`;
const FETCH_MORE_SEARCH_RESULTS = `${namespace}/FETCH_MORE_SEARCH_RESULTS`;
const FETCH_MORE_SEARCH_RESULTS_ERROR = `${namespace}/FETCH_MORE_SEARCH_RESULTS_ERROR`;
const FETCH_MORE_SEARCH_RESULTS_SUCCESS = `${namespace}/FETCH_MORE_SEARCH_RESULTS_SUCCESS`;
const SET_FETCH_MORE_URL = `${namespace}/SET_FETCH_MORE_URL`;
const CLEAR = `${namespace}/CLEAR`;

export const searchSymbolRoutine = createRoutine(`${namespace}/SEARCH_SYMBOL`);
export const searchSymbolRoutinePromiseCreator = promisifyRoutine(
  searchSymbolRoutine
);

export const constants = {
  SEARCH,
  SEARCH_ERROR,
  SEARCH_SUCCESS,
  FETCH_MORE_SEARCH_RESULTS,
  FETCH_MORE_SEARCH_RESULTS_ERROR,
  FETCH_MORE_SEARCH_RESULTS_SUCCESS,
  SET_FETCH_MORE_URL,
  CLEAR
};

const search = createAction(SEARCH, (phrase: String) => ({ phrase }));
const searchError = createAction(SEARCH_ERROR, () => ({}));
const searchSuccess = createAction(SEARCH_SUCCESS, (data: Object) => ({
  data
}));
const fetchMoreSearchResults = createAction(
  FETCH_MORE_SEARCH_RESULTS,
  () => ({})
);
const fetchMoreSearchResultsError = createAction(
  FETCH_MORE_SEARCH_RESULTS_ERROR,
  (data: Object) => ({ data })
);
const fetchMoreSearchResultsSuccess = createAction(
  FETCH_MORE_SEARCH_RESULTS_SUCCESS,
  (data: Object) => ({ data })
);
const setFetchMoreUrl = createAction(SET_FETCH_MORE_URL, (url: string) => ({
  url
}));
const clear = createAction(CLEAR, () => ({}));

export const actions = {
  search,
  searchError,
  searchSuccess,
  fetchMoreSearchResults,
  fetchMoreSearchResultsError,
  fetchMoreSearchResultsSuccess,
  setFetchMoreUrl,
  clear
};

const initialState = {
  loading: false,
  loadingMore: false,
  phrase: null,
  next: null,
  stocks: [],
  symbols: []
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SEARCH:
      return {
        ...state,
        phrase: action.payload.phrase,
        loading: true
      };

    case SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        stocks: action.payload.data.results,
        next: action.payload.data.next
      };

    case SEARCH_ERROR:
      return {
        ...state,
        loading: false,
        stocks: []
      };

    case FETCH_MORE_SEARCH_RESULTS:
      return {
        ...state,
        loadingMore: true
      };

    case FETCH_MORE_SEARCH_RESULTS_ERROR:
      return {
        ...state,
        loadingMore: false
      };

    case FETCH_MORE_SEARCH_RESULTS_SUCCESS:
      return {
        ...state,
        loadingMore: false,
        stocks: [...state.stocks, ...action.payload.data.results],
        next: action.payload.data.next
      };

    case SET_FETCH_MORE_URL:
      return {
        ...state,
        next: action.payload.url
      };

    case searchSymbolRoutine.SUCCESS:
      return {
        ...state,
        symbols: action.payload.data
      };

    case CLEAR:
      return {
        ...state,
        phrase: "",
        stocks: []
      };

    default:
      return state;
  }
}
