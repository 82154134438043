import "../styles/default.scss";

import React, { useState } from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import SearchBar from "../../../common/SearchBar";

const ErrorPage = ({ translate }) => {
  const [searchBarOpen, setSearchBarOpen] = useState(false);

  return (
    <div className="error-page">
      <h1 className="error-page_title">500</h1>
      <h2 className="error-page_text">
        {translate("Sorry, unexpected error occured")}
      </h2>
      <p className="error-page_text-small">
        <span>{translate("Contact support for more details.")}</span>
        <span
          dangerouslySetInnerHTML={{
            __html: translate(
              `<a href="/">Move to the main page</a> or search for companies that interest you`
            )
          }}
        />
      </p>
      <SearchBar
        inContent
        searchBarOpen={searchBarOpen}
        setSearchBarOpen={setSearchBarOpen}
      />
    </div>
  );
};

ErrorPage.propTypes = {};

export default withTranslations(ErrorPage);
