import "../styles/default.scss";

import Tooltip from "@material-ui/core/Tooltip";
import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import { countLeaveTouchDelay } from "../lib/countLeaveTouchDelay";

const SquaberTooltip = ({
  title,
  placement = "bottom",
  children,
  translate,
  onClick
}) => {
  return (
    <Tooltip
      title={translate(title)}
      placement={placement}
      leaveTouchDelay={countLeaveTouchDelay({ title: translate(title) })}
      enterTouchDelay={0}
      classes={{
        tooltip: "squaber-tooltip"
      }}
      onClick={e => onClick?.(e)}
    >
      {children}
    </Tooltip>
  );
};

export default withTranslations(SquaberTooltip);
