import React from "react";
import PropTypes from "prop-types";
import SquaberTable from "../../SquaberTable";

const RateOfReturns = ({ data }) => (
  <div className="rate-of-returns">
    <SquaberTable
      heading={[
        " ",
        "1D",
        "1W",
        "1M",
        "3M",
        "6M",
        "1Y",
        "2Y",
        "3Y",
        "5Y",
        "YTD"
      ]}
      rows={data.map(row => [
        {
          content: row.ticker,
          isHeading: true
        },
        ...row.rate_of_returns.map(rate => (rate ? `${rate}%` : "-"))
      ])}
      withDistinctRows
    />
  </div>
);

RateOfReturns.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      ticker: PropTypes.string,
      rate_of_returns: PropTypes.arrayOf(PropTypes.number)
    })
  )
};

export default RateOfReturns;
