import moment from "moment-mini";

function transformDateTimeIntoDate(value, format = "YYYY-MM-DD") {
  if (moment(value, "YYYY-MM-DDTHH:mm:ss", true).isValid()) {
    return moment(value).format(format);
  }

  return value;
}

export default transformDateTimeIntoDate;
