export const getLoginLoading = state => state.auth.login.loading;
export const getAuthToken = state => state.auth.login.token;
export const getUserData = state => state.auth.login.user;
export const getRegisteredForPushNotifications = state =>
  state.auth.registeredForPushNotifications;
export const getUserID = state => {
  let data = getUserData(state);

  return data ? data.id : null;
};
export const getPushNotificationsToken = state =>
  state.auth.login.pushNotificationsToken;
export const getValidationErrors = state => state.auth.login.validationErrors;
export const getUserEditValidationErrors = state =>
  state.auth.login.userDataEditError;
export const getPartnershipTokens = state => {
  if (state.auth.login.user) {
    return state.auth.login.user.tokens;
  }

  return null;
};
export const getUserWasLoggedIn = state => state.auth.login.userWasLoggedIn;
