import React from "react";
import { Firebase } from "../redux/configureStore";
import withTranslations from "./withTranslations";

const defaultIntervalTypeId = 7;

export default function withQuoteData(
  wait = false,
  stockIdExtractor = () => null
) {
  function getStockIdFromProps(props) {
    const { stock, stock_id: providedStockId, resultMetaData } = props;

    const { id: stockId, stock_id } = stock || {};
    const { stock_id: stockIdFromUniversalTableResult } = resultMetaData || {};
    const stockIdFromExtractor = stockIdExtractor(props);

    return (
      stockIdFromExtractor ||
      stockId ||
      stock_id ||
      providedStockId ||
      stockIdFromUniversalTableResult ||
      (typeof stock !== "undefined" && typeof stock === "number" ? stock : null)
    );
  }

  function getIntervalTypeIdFromProps(props) {
    return props.intervalTypeId || defaultIntervalTypeId;
  }

  return function(WrappedComponent) {
    @withTranslations
    class WithQuoteData extends React.Component {
      quoteRef = null;

      constructor(props) {
        super(props);

        this.valueCallback = this.valueCallback.bind(this);
      }

      state = {
        quoteData: null,
        emptyQuoteData: true
      };

      initQuoteRef() {
        const stockId = getStockIdFromProps(this.props);
        const intervalTypeId = getIntervalTypeIdFromProps(this.props);

        if (stockId !== null && intervalTypeId !== null) {
          this.quoteRef = Firebase.database()
            .ref("quotes")
            .child(stockId)
            .child(intervalTypeId);

          return this.quoteRef;
        }

        return false;
      }

      valueCallback(dataSnapshot) {
        const quoteData = dataSnapshot.val();

        this.setState({
          quoteData
        });
      }

      componentDidMount() {
        this.initQuoteRef();

        if (this.quoteRef) {
          this.quoteRef.on("value", this.valueCallback);
        }
      }
      componentDidUpdate(prevProps) {
        const oldStockId = getStockIdFromProps(prevProps);
        const newStockId = getStockIdFromProps(this.props);

        if (newStockId !== oldStockId) {
          if (this.quoteRef) {
            this.quoteRef.off("value", this.valueCallback);
            this.quoteRef = null;
          }

          const result = this.initQuoteRef();
          if (result) {
            this.quoteRef.on("value", this.valueCallback);
          }
        }
      }

      componentWillUnmount() {
        if (this.quoteRef) {
          this.quoteRef.off("value", this.valueCallback);
        }
      }

      static defaultProps = {
        intervalTypeId: defaultIntervalTypeId
      };

      render() {
        const { quoteData } = this.state;

        const emptyQuoteData = quoteData === null;

        if (wait && !quoteData) {
          return null;
        }

        return (
          <WrappedComponent
            {...this.props}
            quoteData={quoteData}
            emptyQuoteData={emptyQuoteData}
          />
        );
      }
    }

    return WithQuoteData;
  };
}
