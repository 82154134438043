import React from "react";
import { isEmpty } from "lodash";
import connect from "../i18n/connect";
import { getSingleTagDetails } from "../redux/modules/tags/selector";
import { fetchSingleTagDetailsRoutine } from "../redux/modules/tags";

export default function withSingleTagDetails(
  wait = false,
  LoaderComponent = () => null,
  TagNotFoundComponent = undefined,
  TagOnlyForRegisteredUsersComponent = undefined,
  TagOnlyForPremiumUsersComponent = undefined
) {
  return WrappedComponent => {
    const mapStateToProps = state => ({
      tagDetails: getSingleTagDetails(state)
    });

    const mapDispatchToProps = {
      fetchSingleTagDetails: fetchSingleTagDetailsRoutine
    };

    @connect(mapStateToProps, mapDispatchToProps)
    class WithSingleTagDetails extends React.Component {
      componentDidMount() {
        this.props.fetchSingleTagDetails(this.props.id);
      }

      componentDidUpdate(
        prevProps: Readonly<P>,
        prevState: Readonly<S>,
        snapshot: SS
      ) {
        if (prevProps.id !== this.props.id) {
          this.props.fetchSingleTagDetails(this.props.id);
        }
      }

      render() {
        const {
          tagDetails: { data, error }
        } = this.props;

        if (error && error.response) {
          const {
            request: { status }
          } = error;

          if (TagOnlyForRegisteredUsersComponent && status === 401) {
            return <TagOnlyForRegisteredUsersComponent />;
          }

          if (TagOnlyForPremiumUsersComponent && status === 403) {
            return <TagOnlyForPremiumUsersComponent />;
          }

          if (TagNotFoundComponent && status === 404) {
            return <TagNotFoundComponent />;
          }
        }

        if (wait && (!data || isEmpty(data))) {
          return <LoaderComponent small />;
        }

        return <WrappedComponent {...this.props} />;
      }
    }

    return WithSingleTagDetails;
  };
}
