import moment from "moment-mini";
import { capitalize } from "lodash";
import Price from "../../common/Price";
import theme from "../../theme";

export const markerTypesNames = {
  currentLastMinMaxPoints: "currentLastMinMaxPoints",
  previousLastMinMaxPoints: "previousLastMinMaxPoints",
  dividends: "dividends",
  financialReports: "financialReports",
  mediaMonitorMessages: "mediaMonitorMessages",
  aggregatedMediaMonitorMessages: "aggregatedMediaMonitorMessages",
  insiderTransactions: "insiderTransactions"
};

export const aggregationMarkers = {
  aggregatedMediaMonitorMessages: {
    name: markerTypesNames.aggregatedMediaMonitorMessages,
    shouldDrawSettingName: "show_media_monitor_messages",
    markerColor: theme.colors.info,
    markerLabel: "K",
    getMarkerTime: message => message.pub_datetime,
    getHtmlLabelInline: (message, translate) => {
      const displayedAmount = 3;
      const aggregatedMarkers = message?.aggregatedMarkers ?? [];

      let label = "";
      let i = 0;

      for (const marker of aggregatedMarkers) {
        if (i++ >= displayedAmount) {
          const remainingAmount =
            aggregatedMarkers.length - displayedAmount ?? 0;
          label += `... (+${remainingAmount})<br><br>`;
          break;
        }

        label += `${moment(marker.pub_datetime).format("DD.MM.YYYY, HH:mm")}${
          marker.source ? " - " + marker.source : ""
        }<br>${marker.title}<br><br>`;
      }

      label += `${translate("Click on a marker to learn more")}`;

      return label;
    },
    getMarkerMetaData: message => message.aggregatedMarkers
  }
};

const lastMinMaxPoints = {
  name: markerTypesNames.currentLastMinMaxPoints,
  shouldDrawSettingName: "show_current_last-min-max-points",
  shape: ({ type }) => {
    if (type === "OPD") return "arrow-up";
    return "arrow-down";
  },
  getMarkerTime: report => report.datetime_utc,
  getLabel: (points, translate) =>
    translate(`%{type}: %{price}`, {
      price: Number(points.value).toFixed(2),
      type: points.type
    }),
  getMarkerMetaData: () => {}
};

export const markerTypes = [
  {
    name: markerTypesNames.dividends,
    shouldDrawSettingName: "show_dividends",
    markerColor: theme.colors.warning,
    markerLabel: "D",
    getMarkerTime: dividend => dividend.last_day_with_right_to_dividend,
    //prettier-ignore
    getLabel: (dividend, translate) => dividend.has_multiple_payouts ?
      `${translate("Last trading day with right to dividend in year %{dividend_year}", { dividend_year: dividend.year, })}
${translate("Last day with right to dividend: %{last_day_with_right_to_dividend}", {last_day_with_right_to_dividend: moment(dividend.last_day_with_right_to_dividend).format("DD.MM.YYYY")})}
${translate("Dividend day: %{dividend_day}", {dividend_day: moment(dividend.dividend_day).format("DD.MM.YYYY")})}
${translate("Payout days:")}
${dividend.payouts.map(payout => `- ${moment(payout.day).format("DD.MM.YYYY")}: ${new Price({ value: payout.payout, currency: dividend.currency }).render()}`).join(`
`)}
${translate("The next business day, the dividend will be cut off from the exchange rate.")}` :
      `${translate("Last trading day with right to dividend in year %{dividend_year}", { dividend_year: dividend.year, })}
${translate("Last day with right to dividend: %{last_day_with_right_to_dividend}", {last_day_with_right_to_dividend: moment(dividend.last_day_with_right_to_dividend).format("DD.MM.YYYY")})}
${translate("Dividend day: %{dividend_day}", {dividend_day: moment(dividend.dividend_day).format("DD.MM.YYYY")})}
${translate("Amount: %{price}", {price: new Price({ value: dividend.payout, currency: dividend.currency }).render(),})}
${translate("Dividend payout day: %{payout_day}", {payout_day: moment(dividend.payout_day).format("DD.MM.YYYY")})}
${translate("The next business day, the dividend will be cut off from the exchange rate.")}
`,
    // prettier-ignore
    getHtmlLabelInline: (dividend, translate) => dividend.has_multiple_payouts ? `${translate("Last trading day with right to dividend in year %{dividend_year}<br>", { dividend_year: dividend.year, })}${translate("Last day with right to dividend: %{last_day_with_right_to_dividend}<br>", {last_day_with_right_to_dividend: moment(dividend.last_day_with_right_to_dividend).format("DD.MM.YYYY")})}${translate("Dividend day: %{dividend_day}<br>", {dividend_day: moment(dividend.dividend_day).format("DD.MM.YYYY")})}${translate("Payout days:<br>")}${dividend.payouts.map(payout => `- ${moment(payout.day).format("DD.MM.YYYY")}: ${new Price({ value: payout.payout, currency: dividend.currency }).render()}`).join("<br>")}<br>${translate("The next business day, the dividend will be cut off from the exchange rate.")}` : `${translate("Last trading day with right to dividend in year %{dividend_year}<br>", { dividend_year: dividend.year, })}${translate("Last day with right to dividend: %{last_day_with_right_to_dividend}<br>", {last_day_with_right_to_dividend: moment(dividend.last_day_with_right_to_dividend).format("DD.MM.YYYY")})}${translate("Dividend day: %{dividend_day}<br>", {dividend_day: moment(dividend.dividend_day).format("DD.MM.YYYY")})}${translate("Amount: %{price}<br>", {price: new Price({ value: dividend.payout, currency: dividend.currency }).render(),})}${translate("Dividend payout day: %{payout_day}<br>", {payout_day: moment(dividend.payout_day).format("DD.MM.YYYY")})}${translate("The next business day, the dividend will be cut off from the exchange rate.")}`,
    getMarkerMetaData: () => {}
  },
  {
    name: markerTypesNames.financialReports,
    shouldDrawSettingName: "show_financial_reports_dates",
    markerColor: theme.colors.errorText,
    markerLabel: "R",
    getMarkerTime: report => report.report_date,
    getLabel: (report, translate) =>
      translate(`%{report_date} %{report_year} report_type_${report.type}`, {
        report_date: moment(report.report_date).format("DD.MM.YYYY"),
        report_year: report.year
      }),
    getMarkerMetaData: () => {}
  },
  lastMinMaxPoints,
  {
    ...lastMinMaxPoints,
    name: markerTypesNames.previousLastMinMaxPoints,
    shouldDrawSettingName: "show_previous_last-min-max-points",
    shape: ({ type }) => {
      if (type === "OPD") return "arrow-up-disabled";
      return "arrow-down-disabled";
    }
  },
  {
    name: markerTypesNames.mediaMonitorMessages,
    shouldDrawSettingName: "show_media_monitor_messages",
    markerColor: theme.colors.info,
    markerLabel: "K",
    getMarkerTime: message => message.pub_datetime,
    getLabel: (message, translate) =>
      `${moment(message.pub_datetime).format("DD.MM.YYYY, HH:mm")}${
        message.source ? " - " + message.source : ""
      }
${message.title}

${translate("Click on a marker to learn more")}`,
    // prettier-ignore
    getHtmlLabelInline: (message, translate) => `${moment(message.pub_datetime).format("DD.MM.YYYY, HH:mm")}${message.source ? " - " + message.source : ""}<br>${message.title}<br><br>${translate("Click on a marker to learn more")}`,
    getMarkerMetaData: message => message
  },
  {
    name: markerTypesNames.insiderTransactions,
    shouldDrawSettingName: "show_insider_transactions",
    markerColor: transaction => {
      switch (transaction.transaction_type) {
        case 0:
          return theme.colors.error;
        case 1:
          return theme.colors.success;
        default:
          return theme.colors.baseGreyDark3;
      }
    },
    markerLabel: "I",
    getMarkerTime: transaction => transaction.date_from,
    // prettier-ignore
    getHtmlLabelInline: (transaction, translate) => `${transaction.date_from && transaction.date_to && transaction.date_from !== transaction.date_to ? translate("From") : translate("On day")}: ${moment(transaction.date_from).format("DD.MM.YYYY")}<br>${transaction.date_to && transaction.date_from !== transaction.date_to ? `${translate("To")}: ${moment(transaction.date_to).format("DD.MM.YYYY")}<br>` : ""}${transaction.insider_name ? `${transaction.insider_name} -` : ""} ${transaction.function_name ? transaction.function_name : ""}<br>${capitalize(translate(`insider_transaction_type_${transaction.transaction_type}`))} - ${transaction.shares_number} - ${transaction.price_avg || transaction.price_min}<br><a href="${window.location.href.split("#"[0])}#insiders-transactions" target="_top">${translate("Details")}</a>
`,
    getMarkerMetaData: transaction => transaction
  }
];
