import React from "react";
import { connect } from "../i18n/connect";
import { actions as authActions } from "../redux/modules/auth/login";
import { getAuthLoginLoading } from "../redux/modules/authLoginLoading/selector";
import withFormBuilder from "./withFormBuilder";

export default function withLoginForm(WrappedComponent) {
  const mapStateToProps = state => ({
    loginLoading: getAuthLoginLoading(state)
  });

  const mapDispatchToProps = {
    login: authActions.login
  };

  const fields = [
    {
      name: "user",
      label: "User name or email",
      type: "text",
      required: true,
      fullWidth: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      value: "",
      autoCorrect: false,
      autoComplete: false
    },
    {
      name: "password",
      value: "",
      type: "password",
      required: true,
      fullWidth: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      },
      label: "Password"
    },
    {
      name: "rememberme",
      selected: [],
      type: "checkbox",
      options: [
        {
          label: "Remember me",
          value: "rememberme"
        }
      ]
    }
  ];

  @connect(mapStateToProps, mapDispatchToProps)
  @withFormBuilder(fields)
  class WithLoginForm extends React.Component {
    state = {};

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithLoginForm;
}
