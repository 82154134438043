import React from "react";
import Scrollbar from "react-scrollbars-custom";
import autoBind from "auto-bind";
import "../styles/default.scss";

class AlwaysScrollableDialogContent extends React.Component {
  state = {
    wrapperMaxHeight: null
  };

  images = [];
  imagesToBeLoaded = 0;
  imagesLoaded = 0;

  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  componentDidMount() {
    this.setWrapperMaxHeight();

    this.listenToImagesLoaded();

    window.addEventListener("resize", this.setWrapperMaxHeight);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.setWrapperMaxHeight);

    for (const image of this.images) {
      image.removeEventListener("load", this.handleImageLoaded);
    }
  }

  listenToImagesLoaded() {
    const images = this.content.getElementsByTagName("img");

    if (!images.length) {
      return;
    }

    this.images = images;
    this.imagesToBeLoaded = images.length;

    for (const image of images) {
      image.addEventListener("load", this.handleImageLoaded);
    }
  }

  handleImageLoaded() {
    this.imagesLoaded += 1;

    if (this.imagesLoaded === this.imagesToBeLoaded) {
      this.setWrapperMaxHeight();
    }
  }

  setWrapperMaxHeight() {
    const contentHeight = this.content.clientHeight;

    this.setState({
      wrapperMaxHeight: contentHeight + 10
    });
  }

  render() {
    const { children } = this.props;
    const { wrapperMaxHeight } = this.state;

    return (
      <div
        className="always-scrollable-dialog-content-wrapper"
        style={{
          maxHeight: wrapperMaxHeight
        }}
      >
        <Scrollbar noScrollX>
          <div
            className="always-scrollable-dialog-content"
            ref={element => {
              this.content = element;
            }}
          >
            {children}
          </div>
        </Scrollbar>
      </div>
    );
  }
}

export default AlwaysScrollableDialogContent;
