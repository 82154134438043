import "../styles/default.scss";

import classNames from "classnames";
import React from "react";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import Price from "../../../../../common/Price";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withPortfoliosListItem from "../../../../../hocs/withPortfoliosListItem";
import portfolioStatus from "../../../../../lib/portfolioStatus";
import ChangePercent from "../../ChangePercent";
import SquaberLink from "../../SquaberLink";

const PortfoliosListItem = ({
  data: portfolioData,
  dataFromWebSocket,
  removePortfolio,
  active,
  translate,
  locale,
  setValue
}) => {
  const data = {
    ...portfolioData,
    ...dataFromWebSocket
  };

  const {
    id,
    name,
    rate_of_return,
    value,
    currency,
    daily_rate_of_return = null,
    errors,
    status
  } = data;

  return (
    <SquaberLink
      to={UrlProvider.getUrl("fe.portfolio", {
        locale,
        id: id
      })}
      className={classNames({
        "portfolios-list-item": true,
        "has-error": errors.length,
        active
      })}
      onClick={() => {
        setValue("");
      }}
    >
      <div className="basic-info">
        <span className="portfolio-name">
          <span className="portfolio-name-inside">{name}</span>
          {status === portfolioStatus.IN_PROGRESS ? (
            <i className="in-progress-indicator far fa-sync-alt fa-spin" />
          ) : null}
        </span>
        <span className="latest-change">
          {translate("Change last 24 hours")}:{" "}
          <ChangePercent change={daily_rate_of_return} />
        </span>
      </div>
      <div className="statistics">
        <span className="rate-of-return">
          <ChangePercent change={rate_of_return} />
        </span>
        <span className="current-value">
          {typeof value === "undefined" || value === null ? (
            <span>{translate("portfolio_value_error")}</span>
          ) : (
            <Price value={value} currency={currency} roundToSecondNumber />
          )}
        </span>
      </div>
      <div
        className="remove-portfolio"
        onClick={event => {
          event.stopPropagation();
          event.preventDefault();

          removePortfolio(id);
        }}
      >
        <i className="far fa-trash-alt" />
      </div>
    </SquaberLink>
  );
};

export default compose(
  withPortfoliosListItem,
  withBottomNavigation("bottomNav")
)(PortfoliosListItem);
