import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import Button from "@material-ui/core/Button";
import theme from "../../../../../theme";

import "../styles/default.scss";

const SquaberButton = ({
  children,
  color,
  style,
  buttonStyle = true,
  ...props
}) => {
  let styles;
  if (buttonStyle) {
    styles = theme.button.default;

    if (props.disabled) {
      styles = {
        ...styles,
        ...theme.button.disabled
      };
    }

    styles = {
      ...styles,
      ...theme.button[color],
      ...style
    };
  }

  const className = classNames({
    "squaber-button": buttonStyle,
    [props.className]: !!props.className
  });

  return (
    <Button variant="contained" style={styles} className={className} {...props}>
      {children}
    </Button>
  );
};

SquaberButton.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "primary_small",
    "secondary",
    "secondary_small",
    "tertiary",
    "dark"
  ])
};

SquaberButton.defaultProps = {
  color: "primary"
};

export default SquaberButton;
