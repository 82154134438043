import React from "react";
import { connect } from "../i18n/connect";
import { actions } from "../redux/modules/bottomNavigation";
import { getCurrentValue } from "../redux/modules/bottomNavigation/selector";

const withBottomNavigation = (propName = "value") => WrappedComponent => {
  const mapStateToProps = state => ({
    [propName]: getCurrentValue(state)
  });

  const mapDispatchToProps = {
    setValue: actions.setValueAction
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithBottomNavigation extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithBottomNavigation;
};

export default withBottomNavigation;
