import { handleActions } from "redux-actions";
import { createRoutine, promisifyRoutine } from "redux-saga-routines";

export const fetchManualStrategies = createRoutine(
  "app/manualStrategies/FETCH_MANUAL_STRATEGIES"
);

export const fetchManualStrategiesRoutinePromiseCreator = promisifyRoutine(
  fetchManualStrategies
);

const initialState = {
  loading: false,
  data: [],
  error: null
};

export default handleActions(
  {
    [fetchManualStrategies.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [fetchManualStrategies.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload
    }),
    [fetchManualStrategies.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    })
  },
  initialState
);
