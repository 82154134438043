import { fork, put, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../../api/ApiClient";
import { UrlProvider } from "../../../../api/UrlProvider";
import config from "../../../../config";
import logError from "../../../../lib/logError";
import { actions as alertsActions } from "../../alerts";
import { actions } from "../login";
import { startAccountDeletionProcess } from "./index";

function* showSuccessfullyStartedDeletionProcessAlert() {
  yield put(
    alertsActions.addSuccessAlert({
      title: "successfully_started_deletion_process_alert"
    })
  );
}

function* showErrorWhileStartingDeletionProcessAlert() {
  yield put(
    alertsActions.addErrorAlert({
      title: "error_while_starting_deletion_process_alert"
    })
  );
}

function* onStartAccountDeletionProcess() {
  try {
    yield ApiClient.delete({
      url: UrlProvider.getUrl("app.auth.delete", {}, config.apiHostName)
    });

    yield put(actions.refreshUserData.trigger());
    yield put(startAccountDeletionProcess.success());
    yield showSuccessfullyStartedDeletionProcessAlert();
  } catch (error) {
    logError(error);

    yield put(startAccountDeletionProcess.failure(error));
    yield showErrorWhileStartingDeletionProcessAlert();
  }
}

function* watchAuthDelete() {
  yield takeLatest(
    startAccountDeletionProcess.TRIGGER,
    onStartAccountDeletionProcess
  );
}

export default [fork(watchAuthDelete)];
