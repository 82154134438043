import { createRoutine } from "redux-saga-routines";
import { createAction, handleActions } from "redux-actions";

const SET_MARKETING_BARS_HIDDEN = "app/marketingBars/SET_MARKETING_BARS_HIDDEN";

export const fetchMarketingBarsRoutine = createRoutine(
  "app/marketingBars/GET_MARKETING_BARS"
);

export const setMarketingBarsHidden = createAction(
  SET_MARKETING_BARS_HIDDEN,
  (value: boolean) => ({ value })
);

export const initialState = {
  data: null,
  hidden: false
};

export default handleActions(
  {
    [fetchMarketingBarsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      hidden: false
    }),
    [SET_MARKETING_BARS_HIDDEN]: (state, { payload }) => ({
      ...state,
      hidden: payload.value
    })
  },
  initialState
);
