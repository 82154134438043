import React from "react";
import connect from "../i18n/connect";
import {
  acceptRegulations,
  getAffiliateDetails,
  getAffiliateHistory,
  setAffiliateCookie
} from '../redux/modules/affiliateProgram';
import {
  getAffiliateProgramDetails,
  getAffiliateProgramDetailsLoading,
  getAffiliateProgramHistory,
  getAffiliateProgramHistoryLoading
} from "../redux/modules/affiliateProgram/selector";

export default function withAffiliateProgram(WrappedComponent) {
  const mapStateToProps = state => ({
    affiliateProgram: {
      detailsLoading: getAffiliateProgramDetailsLoading(state),
      historyLoading: getAffiliateProgramHistoryLoading(state),
      details: getAffiliateProgramDetails(state),
      history: getAffiliateProgramHistory(state)
    }
  });

  const mapDispatchToProps = {
    getAffiliateDetails,
    getAffiliateHistory,
    setAffiliateCookie,
    acceptRegulations
  };
  @connect(mapStateToProps, mapDispatchToProps)
  class WithAffiliateProgram extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithAffiliateProgram;
}
