import React from "react";
import connect from "../i18n/connect";
import { getLocation } from "react-router-redux";

export default function withLocation(WrappedComponent) {
  const mapStateToProps = state => ({
    location: getLocation(state)
  });

  @connect(mapStateToProps)
  class WithLocation extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithLocation;
}
