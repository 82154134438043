import { createAction, handleActions } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

export const fetchMarketingMessagesRoutine = createRoutine(
  "app/marketingMessages/FETCH_MARKETING_NOTIFICATIONS"
);
export const acceptMarketingMessageRoutine = createRoutine(
  "app/marketingMessages/ACCEPT_MARKETING_NOTIFICATION"
);
export const skipMarketingMessageRoutine = createRoutine(
  "app/marketingMessages/SKIP_MARKETING_NOTIFICATION"
);
export const dismissMarketingMessageTopBarRoutine = createRoutine(
  "app/marketingMessages/DISMISS_MARKETING_MESSAGE_TOP_BAR"
);
export const clickCtaMarketingMessageRoutine = createRoutine(
  "app/userMessages/CLICK_CTA_MARKETING_MESSAGES"
);

const CLEAR_MARKETING_MESSAGES =
  "app/marketingMessages/CLEAR_MARKETING_MESSAGES";
export const clearMarketingMessages = createAction(CLEAR_MARKETING_MESSAGES);

export const initialState = {
  data: [],
  loading: false,
  error: null,
  marketingMessagesTopBarDismissed: false
};

export default handleActions(
  {
    [fetchMarketingMessagesRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      loading: false,
      error: null
    }),
    [fetchMarketingMessagesRoutine.ERROR]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    [dismissMarketingMessageTopBarRoutine.TRIGGER]: state => ({
      ...state,
      marketingMessagesTopBarDismissed: true
    }),
    [CLEAR_MARKETING_MESSAGES]: () => ({ ...initialState })
  },
  initialState
);
