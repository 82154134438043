import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withAdvancedChart from "../../../../../hocs/withAdvancedChart";
import withQuoteData from "../../../../../hocs/withQuoteData";
import withSimpleChartData from "../../../../../hocs/withSimpleChartData";
import chart from "../../../../../lib/simplechart/simplechart.html";

const CustomSimpleChart = ({
  simpleChartData,
  quoteData,
  showVolumeStudy,
  setAdvancedChartVisible,
  locale,
  ticker,
  market,
  showAdditionalChartButtons,
  translate,
  history,
  redirectToFullScrenChart = false
}) => {
  const [chartInitialized, setChartInitialized] = useState(false);
  const [iframeLoaded, setIframeLoaded] = useState(false);
  const iframeRef = useRef();

  useEffect(() => {
    if (!chartInitialized && iframeLoaded && simpleChartData.length) {
      iframeRef.current.contentWindow.postMessage(
        JSON.stringify({
          action: "init",
          data: {
            quotesList: simpleChartData,
            showVolumeStudy,
            showAdditionalChartButtons,
            chartType: "CANDLES",
            advancedChartButtonText: translate("Show advanced chart"),
            advancedChartRedirectText: translate("Advanced chart"),
            advancedChartLink: UrlProvider.getUrl(
              "fe.fullScreenChartWithReturnTo",
              {
                locale,
                marketId: market,
                stockId: ticker,
                returnTo: window.location.pathname
              }
            ),
            quoteData
          }
        })
      );

      setChartInitialized(true);
    }
  }, [iframeLoaded, simpleChartData, chartInitialized, quoteData]);

  useEffect(() => {
    iframeRef.current.contentWindow.postMessage(
      JSON.stringify({
        action: "quote-data-update",
        data: { quoteData }
      })
    );
  }, [quoteData]);

  useEffect(() => {
    if (iframeLoaded && iframeRef.current) {
      iframeRef.current.contentWindow.addEventListener("message", event => {
        const data = JSON.parse(event.data);

        if (data.action === "OPEN_FULLSCREEN") {
          if (redirectToFullScrenChart) {
            const url = UrlProvider.getUrl("fe.fullScreenChartWithReturnTo", {
              locale,
              marketId: market,
              stockId: ticker,
              returnTo: window.location.pathname
            });
            history.push(url);
          }

          setAdvancedChartVisible(true);
        }
      });
    }
  }, [iframeRef.current, iframeLoaded]);

  return (
    <div>
      <div style={{ height: 200 }}>
        <iframe
          ref={iframeRef}
          src={chart}
          style={{
            flex: 1,
            height: 200,
            width: "100%",
            border: "none"
          }}
          onLoad={() => {
            setIframeLoaded(true);
          }}
        />
      </div>
    </div>
  );
};

CustomSimpleChart.propTypes = {
  stockId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showVolumeStudy: PropTypes.bool
};

export default compose(
  withQuoteData(false, props => props.stockId),
  withSimpleChartData(props => props.stockId, false),
  withAdvancedChart,
  withRouter
)(CustomSimpleChart);
