import React from "react";
import { Redirect } from "react-router";
import connect from "../../../../../i18n/connect";
import { UrlProvider } from "../../../../../api/UrlProvider";

const HomePageRedirect = ({ locale }) => (
  <Redirect
    to={UrlProvider.getUrl("fe.search", { locale }) + window.location.search}
  />
);

export default connect()(HomePageRedirect);
