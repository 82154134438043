import React from "react";
import { getIsWeb } from "../lib/platformHelper";
import config from "../config";

export default function withOverlay(WrappedComponent) {
  class WithOverlay extends React.Component {
    state = {
      disabledOverlay: true,
      pathname: ""
    };

    componentDidUpdate(
      nextProps: Readonly<P>,
      nextState: Readonly<S>,
      nextContext: any
    ) {
      const { pathnamesWithoutOverlies = [] } = config || {};
      const pathname = this.getPathname();

      if (nextState.pathname === pathname) return;

      this.setState({ pathname });

      if (typeof pathname !== "string") {
        this.enableOverlies();
        return;
      }

      const isDisabled = pathnamesWithoutOverlies.some(name =>
        pathname.includes(name)
      );

      if (isDisabled) {
        this.disableOverlies();
        return;
      }

      this.enableOverlies();
    }

    getPathname() {
      if (!getIsWeb()) return;

      const { location: { pathname } = {} } = window || {};
      return pathname;
    }

    enableOverlies() {
      this.setState({ disabledOverlay: false });
    }

    disableOverlies() {
      this.setState({ disabledOverlay: true });
    }

    render() {
      const { disabledOverlay } = this.state;

      return (
        <WrappedComponent {...this.props} disabledOverlay={disabledOverlay} />
      );
    }
  }

  return WithOverlay;
}
