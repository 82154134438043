import React, { useEffect } from "react";
import qs from "qs";
import withAffiliateProgram from "../../../../../hocs/withAffiliateProgram";
import useAffiliateRedirectParse from "./useAffiliateRedirectParse";
import { compose } from "redux";
import Loader from "../../../common/Loader";

function AffiliatePageRedirect({ location, setAffiliateCookie }) {
  const redirectParse = useAffiliateRedirectParse();

  const { value: token } = qs.parse(location.search, {
    ignoreQueryPrefix: true
  });

  useEffect(() => {
    if (typeof token === "undefined") return;
    setAffiliateCookie(token);
  }, [token]);

  useEffect(() => {
    const redirectUrl = redirectParse(token);

    if (typeof redirectUrl !== "string") return;
    window.location.href = redirectUrl;
  }, [redirectParse]);

  return <Loader />;
}

export default compose(withAffiliateProgram)(AffiliatePageRedirect);
