import { takeLatest, takeEvery, fork, put, select } from "redux-saga/effects";
import {
  followedStocksRoutine,
  notifyAboutFetchingFollowedStocks,
  setFollowedStocksMap,
  unfollowStockRoutine,
  getAlertedTagDetailsRoutine,
  followStockRoutine,
  bulkFollowRoutine,
  bulkUnFollowRoutine
} from "./index";
import ApiClient from "../../../api/ApiClient";
import { getAuthToken, getUserData } from "../auth/login/selector";
import { actions as alertActions } from "../alerts";
import decisionModal from "../../../lib/decisionModal";
import AnalyticsEventTracker from "../../../lib/AnalyticsEventTracker";
import { getStockData as getSimpleStockData } from "../stockPageTvChart/selector";
import { setAlert } from "../stockData";
import {
  disconnectTagFromStockRoutine,
  fetchStocksListFromSourceRoutine,
  fetchStockTagsRoutine,
  updateStocksListSource
} from "../tags";
import { getStocksListSource } from "../tags/selector";
import TagsHelper from "../../../lib/TagsHelper/TagsHelper";
import { getAllTags } from "../tagsService/selector";
import wait from "../../../lib/wait";
import { updateTag } from "../tagsService";
import { trackSMEvent } from "../salesManagoEventTracker";
import StockAlertOnEvent from "../../../lib/SalesManago/events/StockAlertOnEvent";
import StockAlertOffEvent from "../../../lib/SalesManago/events/StockAlertOffEvent";

function* triggerFollowingStocks() {
  let authToken = yield select(getAuthToken);

  if (authToken) {
    yield put(followedStocksRoutine.request());
    yield put(getAlertedTagDetailsRoutine());
  }
}

export function* getFollowedStocks() {
  yield put(notifyAboutFetchingFollowedStocks());

  try {
    const { data } = yield ApiClient.get("app.stocks.follow");

    yield updateAlertedTag(data);

    const followedStocksMap = {};

    for (let stock of data.stocks) {
      followedStocksMap[stock.stock.id] = stock.user_stock;
    }

    yield put(followedStocksRoutine.success(data));
    yield put(setFollowedStocksMap(followedStocksMap));
  } catch (error) {
    yield put(followedStocksRoutine.failure(error));
  }
}

function* unfollowStock(stockId, isCurrentStock, ticker, market) {
  const decision = yield decisionModal(
    "ConfirmModal",
    {
      title: "Are you sure?",
      message: "Do you really want to cancel alerts for this stock?"
    },
    ["confirm", "cancel"]
  );

  if (decision === "cancel") {
    return;
  }

  try {
    yield ApiClient.delete({
      urlPath: "app.stocks.unfollow",
      variables: {
        stockId
      }
    });

    yield put(
      alertActions.addSuccessAlert({
        title: "Stock settings changed successfully"
      })
    );

    const smEvent = new StockAlertOffEvent({
      stockId,
      ticker,
      market
    });

    yield put(trackSMEvent(smEvent));

    yield put(followedStocksRoutine());
    yield put(fetchStocksListFromSourceRoutine());

    if (isCurrentStock) {
      yield put(fetchStockTagsRoutine({ stockId }));
      yield put(setAlert(false));
    }
  } catch (e) {
    console.log(e);
  }
}

function* removeStockFromTag(stockId, tag, isCurrentStock) {
  const decision = yield decisionModal(
    "ConfirmModal",
    {
      title: "Are you sure?",
      message: "Do you really want to remove this stock from tag?"
    },
    ["confirm", "cancel"]
  );

  if (decision === "cancel") {
    return;
  }

  yield put(
    disconnectTagFromStockRoutine({
      stockId,
      tagId: tag.id,
      shouldRefreshStockPageTags: isCurrentStock
    })
  );
}

function* onFollow({ payload }) {
  const { type, market, ticker, stockId } = payload;

  try {
    yield ApiClient.put({
      urlPath: "app.stocks.toggleAlert",
      variables: {
        stockId: stockId
      }
    });

    yield put(
      alertActions.addSuccessAlert({
        title: "Stock settings changed successfully"
      })
    );

    yield put(followedStocksRoutine());
    yield put(fetchStocksListFromSourceRoutine());

    AnalyticsEventTracker.trackStockAlertOnEvent({
      type,
      market,
      name: ticker
    });

    const smEvent = new StockAlertOnEvent({
      stockId,
      ticker,
      market
    });

    yield put(trackSMEvent(smEvent));
  } catch (e) {
    console.log(e);
  }
}

function* onUnfollow({ payload }) {
  const userData = yield select(getUserData);
  let stockId, ticker, market;

  if (payload !== null && typeof payload === "object") {
    stockId = payload.stockId;
    ticker = payload.ticker;
    market = payload.market;
  } else {
    stockId = payload;
  }

  const { isUnfollowing } = payload;

  const currentStockData = yield select(getSimpleStockData);
  const currentStockId = currentStockData ? currentStockData.stock.id : null;
  const stocksListSource = yield select(getStocksListSource);
  const stocksListSourceIsAlertedTag =
    stocksListSource && TagsHelper.isAlertedTag(stocksListSource);

  if (isUnfollowing || !stocksListSource || stocksListSourceIsAlertedTag) {
    yield unfollowStock(
      stockId,
      currentStockId === stockId,
      ticker,
      market,
      userData
    );
  } else {
    yield removeStockFromTag(
      payload,
      stocksListSource,
      currentStockId === payload
    );
  }
}

function* fetchAlertedTagDetails() {
  const allTags = yield select(getAllTags);

  if (!allTags.length) {
    yield wait(500);
    return yield fetchAlertedTagDetails();
  }

  return allTags.find(tag => TagsHelper.isAlertedTag(tag)) || null;
}

function* onGetAlertedTagDetails() {
  const alertedTag = yield fetchAlertedTagDetails();

  yield put(getAlertedTagDetailsRoutine.success(alertedTag));
}

function* updateAlertedTag(followedData) {
  let alertedTag = yield fetchAlertedTagDetails();
  const stocksListSource = yield select(getStocksListSource);

  const followedStockIds = followedData.stocks.map(item => item.stock.id);
  const currentStocksListSourceIsAlertedTag =
    stocksListSource && TagsHelper.isAlertedTag(stocksListSource);

  const data = {
    ...alertedTag,
    stocks: followedStockIds
  };

  if (currentStocksListSourceIsAlertedTag) {
    yield put(updateStocksListSource(data));
  }

  yield put(updateTag(data));
  yield put(getAlertedTagDetailsRoutine.success(data));
}

function* onBulkFollow({ payload }) {
  const { stocks } = payload;

  try {
    yield ApiClient.put({
      urlPath: "app.stocks.bulkFollowUnfollow",
      data: {
        stocks
      }
    });

    yield put(
      alertActions.addSuccessAlert({
        title: "Stocks alerted successfully"
      })
    );

    yield put(followedStocksRoutine());
  } catch (e) {
    console.log(e);
  }
}

function* onBulkUnFollow({ payload }) {
  const { stocks } = payload;

  const decision = yield decisionModal(
    "ConfirmModal",
    {
      title: "Are you sure?",
      message:
        "Do you really want to remove alerts from all stocks in this desktop? This will also affect stocks that are not visible on screen right now."
    },
    ["confirm", "cancel"]
  );

  if (decision === "cancel") {
    return;
  }

  try {
    yield ApiClient.delete({
      urlPath: "app.stocks.bulkFollowUnfollow",
      data: {
        stocks
      }
    });

    yield put(
      alertActions.addSuccessAlert({
        title: "Alerts removed successfully"
      })
    );
    yield put(followedStocksRoutine());
  } catch (e) {
    console.log(e);
  }
}

function* watchGetFollowedStocks() {
  yield takeLatest(followedStocksRoutine.TRIGGER, triggerFollowingStocks);
  yield takeLatest(followedStocksRoutine.REQUEST, getFollowedStocks);
  yield takeEvery(followStockRoutine.TRIGGER, onFollow);
  yield takeEvery(unfollowStockRoutine.TRIGGER, onUnfollow);
  yield takeLatest(getAlertedTagDetailsRoutine.TRIGGER, onGetAlertedTagDetails);
  yield takeLatest(bulkFollowRoutine.TRIGGER, onBulkFollow);
  yield takeLatest(bulkUnFollowRoutine.TRIGGER, onBulkUnFollow);
}

export default [fork(watchGetFollowedStocks)];
