import React from "react";
import PropTypes from "prop-types";
import CloseIcon from "@material-ui/icons/Close";
import IconButton from "@material-ui/core/IconButton";
import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import theme from "../../../../../theme";
import withAlerts from "../../../../../hocs/withAlerts";
import {
  TransitionComponent,
  getTransitionStyles,
  defaultAnchorOrigin
} from "../utils";
import "../styles/default.scss";

const { colors, snackbarDefaults } = theme;

const getAlertBackground = level =>
  colors[level] ? colors[level] : snackbarDefaults.background;

const SnackbarProvider = ({ alerts, attemptPopAlert, translate }) =>
  alerts.map((alert, index) => {
    let currentAlert = alert;

    return (
      <Snackbar
        key={alert.id}
        TransitionComponent={TransitionComponent}
        TransitionProps={{
          direction: "right"
        }}
        anchorOrigin={defaultAnchorOrigin}
        style={{
          ...getTransitionStyles(index),
          zIndex: 20000
        }}
        open={currentAlert && currentAlert.visible}
        autoHideDuration={0}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          style={{
            background: currentAlert
              ? getAlertBackground(currentAlert.level)
              : snackbarDefaults.background
          }}
          message={
            <span
              id="client-snackbar"
              style={{
                color: snackbarDefaults.color
              }}
            >
              {currentAlert
                ? translate(currentAlert.title, currentAlert.translationVars)
                : " "}
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={() => {
                if (currentAlert && currentAlert.actionCallback) {
                  currentAlert.actionCallback();
                }
                attemptPopAlert(currentAlert.id);
              }}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </Snackbar>
    );
  });

SnackbarProvider.propTypes = {
  currentAlert: PropTypes.object,
  attemptPopAlert: PropTypes.func
};

export default withAlerts(SnackbarProvider);
