import React from "react";

export default class FiltersWrapperAbstract extends React.Component {
  applySetting(attribute, value) {
    const {
      settingsState: { filters }
    } = this.props;

    let newFilters = { ...filters };

    delete newFilters[attribute];

    if (Array.isArray(value)) {
      if (value.length) {
        newFilters[attribute] = value;
      }
    } else if (value) {
      if (
        typeof value.min !== "undefined" ||
        typeof value.max !== "undefined"
      ) {
        if (value.min !== null || value.max !== null) {
          newFilters[attribute] = value;
        }
      } else {
        newFilters[attribute] = value;
      }
    }

    this.props.setSettingsState({
      filters: newFilters
    });
  }
}
