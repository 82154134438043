import React from "react";
import connect from "../i18n/connect";
import { getFollowedStocksMap } from "../redux/modules/followed/selector";

export default function withFollowedStocksMap(WrappedComponent) {
  const mapStateToProps = state => ({
    followedStocksMap: getFollowedStocksMap(state)
  });

  @connect(mapStateToProps)
  class WithFollowedStocksMap extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithFollowedStocksMap;
}
