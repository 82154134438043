import "../styles/default.scss";

import React from "react";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import config from "../../../../../config";
import withGlobalConfigFields from "../../../../../hocs/withGlobalConfigFields";
import withTranslations from "../../../../../hocs/withTranslations";
import withUserData from "../../../../../hocs/withUserData";
import { globalConfigFields } from "../../../../../redux/modules/globalConfig";
import ContentSection from "../../ContentSection";
import Loader from "../../Loader";
import PageTitle from "../../PageTitle";
import SquaberButton from "../../SquaberButton";
import SquaberLink from "../../SquaberLink/modules/default";

const Wrapper = ({ children }) => {
  return <ContentSection className={"generic-page"}>{children}</ContentSection>;
};

const GenericPage = ({
  title,
  children,
  notFound,
  noAccess,
  loading,
  userIsNotLoggedIn,
  translate,
  locale,
  buttons,
  globalConfig: { offerUrl }
}: {
  title: string,
  children: React.ReactElement,
  noAccess: "freemium" | "premium",
  notFound: boolean,
  loading: boolean
}) => {
  if (loading) {
    return (
      <Wrapper>
        <Loader small />
      </Wrapper>
    );
  }

  if (noAccess) {
    if (noAccess === "premium" && !userIsNotLoggedIn) {
      return (
        <Wrapper>
          <h1>{translate("Premium access required")}</h1>
          <p>
            {translate(
              "This is the content available in the premium Squaber version. Use one of our bundles to get access to it"
            )}
          </p>
          <div className={"buttons"}>
            <SquaberLink
              to={offerUrl || config.defaultPremiumOfferUrl}
              target="_blank"
            >
              <SquaberButton>{translate("i want to use it")}</SquaberButton>
            </SquaberLink>
          </div>
        </Wrapper>
      );
    }

    if (noAccess === "premium") {
      return (
        <Wrapper>
          <h1>{translate("Premium access required")}</h1>
          <p>
            {translate(
              "This is the content available in the premium Squaber version. Use one of our bundles to get access to it"
            )}
          </p>
          <div className={"buttons"}>
            <SquaberLink
              to={UrlProvider.getUrl("fe.loginWithRedirect", {
                locale,
                redirectUrl: window.location.pathname
              })}
            >
              <SquaberButton>{translate("Log in")}</SquaberButton>
            </SquaberLink>
            <SquaberLink
              to={UrlProvider.getUrl("fe.registerWithRedirect", {
                locale,
                redirectUrl: window.location.pathname
              })}
            >
              <SquaberButton>{translate("Register")}</SquaberButton>
            </SquaberLink>
          </div>
        </Wrapper>
      );
    }

    return (
      <Wrapper>
        <h1>{translate("Register first")}</h1>
        <p>{translate("single_analysis_page_403_freemium_description")}</p>
        <div className={"buttons"}>
          <SquaberLink
            to={UrlProvider.getUrl("fe.loginWithRedirect", {
              locale,
              redirectUrl: window.location.pathname
            })}
          >
            <SquaberButton>{translate("Log in")}</SquaberButton>
          </SquaberLink>
          <SquaberLink
            to={UrlProvider.getUrl("fe.registerWithRedirect", {
              locale,
              redirectUrl: window.location.pathname
            })}
          >
            <SquaberButton>{translate("Register")}</SquaberButton>
          </SquaberLink>
        </div>
      </Wrapper>
    );
  }

  if (notFound) {
    return (
      <Wrapper>
        <h1>{translate("Page not found")}</h1>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <PageTitle title={title} />
      {buttons ? (
        <div className={"buttons buttons_column"}>
          {buttons.map(({ url, label }) => {
            return (
              <SquaberLink to={url} key={url}>
                <i className="fal fa-arrow-left" /> {label}
              </SquaberLink>
            );
          })}
        </div>
      ) : null}
      <div>
        <h1>{title}</h1>
        {children}
      </div>
    </Wrapper>
  );
};

GenericPage.propTypes = {};

export default compose(
  withTranslations,
  withUserData,
  withGlobalConfigFields([globalConfigFields.OFFER_URL])
)(GenericPage);
