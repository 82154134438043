import React, { lazy, Suspense } from "react";
import Formsy from "formsy-react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { Redirect } from "react-router";
import qs from "qs";
import { UrlProvider } from "../../../../../api/UrlProvider";
import withRegisterForm from "../../../../../hocs/withRegisterForm";
import withUserData from "../../../../../hocs/withUserData";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import RegisterFormAbstract from "../../../../../abstract/RegisterFormAbstract";
import PageTitle from "../../../common/PageTitle";
import SquaberButton from "../../../common/SquaberButton";
import withTranslations from "../../../../../hocs/withTranslations";
import config from "../../../../../config/";
import logo from "../../../../../logo";
import SquaberLink from "../../../common/SquaberLink";

import "../styles/default.scss";

const SocialLogin = lazy(() => import("../../SocialLogin"));

@withTranslations
@withUserData
@withRegisterForm
class RegisterForm extends RegisterFormAbstract {
  render() {
    const {
      registerLoading,
      formBuilder,
      register: executeRegister,
      validationErrors,
      clearValidationError,
      translate,
      locale,
      userData
    } = this.props;

    const { isValid } = this.state;

    if (userData) {
      return <Redirect to={"/"} />;
    }

    const redirectTo = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    }).redirectTo;

    return (
      <div className={"register-form"}>
        <div className="logo-holder">
          <img src={logo.main} className="img-fluid" alt={config.title} />
        </div>
        <PageTitle title={translate("Register")} />
        <Row>
          <Col lg={{ size: 6, offset: 1 }}>
            <Card className={"register-wrapper"}>
              <CardHeader>
                {translate("Register")}
                <SquaberLink
                  target={config.vendor === "squaber" ? "_self" : "_blank"}
                  to={
                    redirectTo
                      ? UrlProvider.getUrl("fe.loginWithRedirect", {
                          locale,
                          redirectUrl: redirectTo
                        })
                      : UrlProvider.getUrl("fe.login", { locale })
                  }
                >
                  <div className="register-link">
                    <span className="first-part">
                      {translate("Already have account?")}
                    </span>
                    <span className="second-part">
                      {translate("Log in")}
                      <i className="fas fa-angle-double-right" />
                    </span>
                  </div>
                </SquaberLink>
              </CardHeader>
              <CardBody>
                <Formsy
                  onValidSubmit={() => {
                    executeRegister({
                      fields: formBuilder.fields
                    });
                  }}
                  onValid={() => this.setIsValid(true)}
                  onInvalid={() => this.setIsValid(false)}
                  validationErrors={validationErrors}
                >
                  <FormBuilderWeb
                    {...formBuilder}
                    clearValidationError={clearValidationError}
                    validationErrors={validationErrors}
                  />
                  <div className="actions">
                    <SquaberButton
                      color="primary"
                      type="submit"
                      disabled={
                        !formBuilder.canSubmit || registerLoading || !isValid
                      }
                    >
                      <span>{translate("Register")}</span>
                      {registerLoading ? (
                        <i
                          style={{ marginLeft: 10 }}
                          className="fal fa-spinner fa-spin"
                        />
                      ) : null}
                    </SquaberButton>
                  </div>
                  <div
                    className="gdpr-disclaimer"
                    dangerouslySetInnerHTML={{
                      __html: translate("gdpr_disclaimer", {
                        gdprInfoUrl: config.gdprInfoUrl
                      })
                    }}
                  />
                </Formsy>
              </CardBody>
            </Card>
          </Col>
          <Col lg={{ size: 4, offset: 0 }}>
            <Card className={"login-wrapper register-wrapper"}>
              <CardHeader>{translate("Or use social account")}</CardHeader>
              <CardBody>
                <Suspense fallback={<div />}>
                  <SocialLogin />
                </Suspense>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default RegisterForm;
