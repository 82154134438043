const getShape = (data, config) => {
  let { shape, translate } = config;

  if (typeof shape === "function") {
    shape = shape(data);
  }

  const defaultShape = {
    shape: "note",
    text: config.getHtmlLabelInline
      ? config.getHtmlLabelInline(data, translate).replace(/<br>/gm, ``)
      : config.getLabel(data, translate),
    disableSave: true,
    disableUndo: true,
    showInObjectsTree: false,
    disableSelection: false,
    lock: true,
    zOrder: "top",
    textColor: "#ffffff",
    overrides: {
      editable: false,
      fixedSize: false,
      markerColor: config.markerColor,
      backgroundColor: "#FFFFFF",
      textColor: "#000000",
      fontsize: 13
    }
  };

  switch (shape) {
    case "balloon-secondary": {
      return {
        ...defaultShape,
        shape: "balloon",
        overrides: {
          editable: false,
          fixedSize: false,
          borderColor: "#991515",
          backgroundColor: "#991515",
          transparency: 50,
          color: "#ffffff",
          fontsize: 10
        }
      };
    }
    case "balloon-primary": {
      return {
        ...defaultShape,
        shape: "balloon",
        overrides: {
          editable: false,
          fixedSize: false,
          borderColor: "#00796b",
          backgroundColor: "#00796b",
          transparency: 50,
          color: "#ffffff",
          fontsize: 10
        }
      };
    }
    case "balloon-disabled": {
      return {
        ...defaultShape,
        shape: "balloon",
        disableSelection: true,
        overrides: {
          editable: false,
          fixedSize: false,
          borderColor: "#2a2e39",
          backgroundColor: "#d1d4dc",
          transparency: 20,
          color: "#2a2e39",
          fontsize: 10,
          fontWeight: "bold"
        }
      };
    }
    case "arrow-up": {
      return {
        ...defaultShape,
        shape: "arrow_up",
        overrides: {
          editable: false,
          fixedSize: false,
          color: "#00796b",
          fontsize: 10
        }
      };
    }
    case "arrow-up-disabled": {
      return {
        ...defaultShape,
        shape: "arrow_up",
        overrides: {
          editable: false,
          fixedSize: false,
          color: "#99a0b2",
          fontsize: 10
        }
      };
    }
    case "arrow-down": {
      return {
        ...defaultShape,
        shape: "arrow_down",
        overrides: {
          editable: false,
          fixedSize: false,
          color: "#991515",
          fontsize: 10
        }
      };
    }
    case "arrow-down-disabled": {
      return {
        ...defaultShape,
        shape: "arrow_down",
        overrides: {
          editable: false,
          fixedSize: false,
          color: "#99a0b2",
          fontsize: 10
        }
      };
    }
    default:
      return defaultShape;
  }
};

export default getShape;
