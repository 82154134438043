import React from "react";
import connect from "../i18n/connect";
import { getInstrumentsListState } from "../redux/modules/instrumentsList/selector";
import { instrumentsListRoutine } from "../redux/modules/instrumentsList";

export default function withInstrumentsList(WrappedComponent) {
  const mapStateToProps = state => ({
    instrumentsListState: getInstrumentsListState(state)
  });

  const mapDispatchToProps = {
    instrumentsListRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithInstrumentsList extends React.Component {
    componentDidMount() {
      this.props.instrumentsListRoutine();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithInstrumentsList;
}
