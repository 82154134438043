import { connect } from "../i18n/connect";
import React from "react";
import { getActionInProgressState } from "../redux/modules/actionInProgress/selector";

export default function withActionInProgressHandler(WrappedComponent) {
  const mapStateToProps = state => ({
    actionInProgressState: getActionInProgressState(state)
  });

  @connect(mapStateToProps)
  class WithActionInProgressHandler extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithActionInProgressHandler;
}
