import { createAction } from "redux-actions";

const namespace = "app/reduxSagaTest";

const TEST_ACTION = `${namespace}/TEST_ACTION`;
const TEST_ACTION_SUCCESS = `${namespace}/TEST_ACTION_SUCCESS`;
const TEST_ACTION_ERROR = `${namespace}/TEST_ACTION_ERROR`;

export const constants = {
  TEST_ACTION,
  TEST_ACTION_SUCCESS,
  TEST_ACTION_ERROR
};

const testAction = createAction(TEST_ACTION, () => ({}));
const testActionSuccess = createAction(TEST_ACTION_SUCCESS, (data: any) => ({
  data
}));
const testActionError = createAction(TEST_ACTION_ERROR, (data: any) => ({
  data
}));

export const actions = {
  testAction,
  testActionSuccess,
  testActionError
};

const initialState = {
  loading: false,
  error: null,
  data: null
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case TEST_ACTION:
      return {
        loading: true,
        data: null
      };

    case TEST_ACTION_SUCCESS:
      return {
        loading: false,
        error: null,
        data: action.payload.data
      };

    case TEST_ACTION_ERROR:
      return {
        loading: false,
        error: action.payload.data,
        data: null
      };

    default:
      return state;
  }
}
