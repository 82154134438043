import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import withFollowedStocksMap from "../../../../../hocs/withFollowedStocksMap";

import "../styles/default.scss";

const StockElement = lazy(() => import("../../StockElement"));

const StocksList = ({
  stocks,
  inline,
  hideRemoveButton,
  limit,
  scrollToSection,
  followedStocksMap,
  advancedMode
}) => {
  let stocksToRender = [...stocks];

  if (limit && stocks.length) {
    stocksToRender = stocksToRender.splice(0, limit);
  }

  let stocksList = stocksToRender
    ? stocksToRender.map(stock => {
        let key = stock.stock_id;

        if (stock.stock) {
          key = stock.stock.id;
        }

        return (
          <Suspense fallback={<div />} key={key}>
            <StockElement
              {...stock}
              user_stock={
                !isEmpty(stock.user_stock)
                  ? stock.user_stock
                  : followedStocksMap[key] || {}
              }
              hideRemoveButton={hideRemoveButton}
              scrollToSection={scrollToSection}
              advancedMode={advancedMode}
            />
          </Suspense>
        );
      })
    : null;

  if (inline) {
    return <div className="stocks-list-inline">{stocksList}</div>;
  }

  return stocksList;
};

StocksList.propTypes = {
  stocks: PropTypes.array,
  inline: PropTypes.bool,
  hideRemoveButton: PropTypes.bool
};

StocksList.defaultProps = {
  inline: false,
  hideRemoveButton: false,
  limit: null
};

export default withFollowedStocksMap(StocksList);
