import { isEqual, uniqBy } from "lodash";
import React from "react";

import ApiClientAsync from "../api/ApiClientAsync";
import { getIsMobile } from "../lib/platformHelper";
import withTranslations from "./withTranslations";
import withUserData from "./withUserData";

export default function withSimpleChartData(
  stockIdExtractor = () => null,
  onlyOnMobile = false
) {
  return WrappedComponent => {
    @withTranslations
    @withUserData
    class WithSimpleChartData extends React.Component {
      state = {
        simpleChartData: [],
        loading: true,
        chartDataFetchError: false
      };

      async init() {
        if (onlyOnMobile && !getIsMobile()) {
          return;
        }

        try {
          this.mounted = true;

          const { locale, authToken } = this.props;

          this.apiClient = new ApiClientAsync(locale, authToken);

          let stockId = stockIdExtractor(this.props);

          if (!stockId) {
            stockId = this.props.stock.id;
          }

          const chartData = await this.apiClient.get({
            urlPath: "app.stocks.simpleChartData",
            variables: {
              stockId,
              count: 60
            }
          });

          chartData.quotes = chartData.quotes.length
            ? uniqBy(chartData.quotes, "datetime_utc")
            : [];

          const simpleChartData = chartData.quotes.map(quote => [
            quote.datetime_utc,
            Number(quote.close),
            quote
          ]);

          if (this.mounted) {
            this.setState({
              simpleChartData,
              loading: false
            });
          }
        } catch (e) {
          this.setState({
            loading: false,
            chartDataFetchError: true
          });
        }
      }

      getRefreshProps(props) {
        return {
          locale: props.locale,
          authToken: props.authToken,
          stock: props.stock
        };
      }

      async componentDidUpdate(
        prevProps: Readonly<P>,
        prevState: Readonly<S>,
        snapshot: SS
      ) {
        const canInit = !isEqual(
          this.getRefreshProps(this.props),
          this.getRefreshProps(prevProps)
        );

        if (canInit) {
          await this.init();
        }
      }

      async componentDidMount() {
        await this.init();
      }

      componentWillUnmount() {
        this.mounted = false;
      }

      render() {
        const { simpleChartData, loading } = this.state;

        return (
          <WrappedComponent
            {...this.props}
            simpleChartData={simpleChartData}
            simpleChartDataLoading={loading}
          />
        );
      }
    }

    return WithSimpleChartData;
  };
}
