import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import SquaberButton from "../../../common/SquaberButton";
import ModalBase from "../../ModalBase";

@withTranslations
class ConfirmModal extends ModalBase {
  getContent() {
    const {
      title,
      message,
      hideModal,
      cancel,
      confirm,
      secondaryConfirm,
      translate,
      decisionLabels
    } = this.props;

    const buttonStyles = { flex: "1 1 auto", marginLeft: 0 };

    return (
      <React.Fragment>
        <DialogTitle id="form-dialog-title">{translate(title)}</DialogTitle>
        <DialogContent>
          <DialogContentText>{translate(message)}</DialogContentText>
        </DialogContent>
        <DialogActions
          className="confirm-modal-actions"
          style={{
            alignItems: "stretch",
            flexWrap: "wrap",
            margin: 0,
            gap: "8px"
          }}
        >
          <SquaberButton
            onClick={() => {
              cancel();
              hideModal();
            }}
            color="secondary"
            style={{ marginRight: "auto", ...buttonStyles }}
          >
            {translate(decisionLabels.cancel || "Cancel")}
          </SquaberButton>
          <SquaberButton
            className=""
            onClick={() => {
              confirm();
              hideModal();
            }}
            color="primary"
            style={buttonStyles}
          >
            {translate(decisionLabels.confirm || "Confirm")}
          </SquaberButton>
          {secondaryConfirm ? (
            <SquaberButton
              onClick={() => {
                secondaryConfirm();
                hideModal();
              }}
              color="primary"
              style={buttonStyles}
            >
              {translate(
                decisionLabels.secondaryConfirm || "Secondary confirm"
              )}
            </SquaberButton>
          ) : null}
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default ConfirmModal;
