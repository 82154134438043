export const MarketingMessageTypes = {
  TOP_BAR: "top_bar",
  OVERLAY: "overlay"
};

export const getMarketingMessages = state => state.marketingMessages.data;

export const getMarketingMessagesTopBar = state => {
  const marketingMessages = getMarketingMessages(state);

  return marketingMessages.filter(
    message => message.type === MarketingMessageTypes.TOP_BAR
  );
};

export const getMarketingMessagesOverlay = state => {
  const marketingMessages = getMarketingMessages(state);

  return marketingMessages.filter(
    message => message.type === MarketingMessageTypes.OVERLAY
  );
};

export const getMarketingMessageTopBarDismissed = state =>
  state.marketingMessages.marketingMessagesTopBarDismissed;

export const shouldShowMarketingMessageBar = state => {
  return (
    !getMarketingMessageTopBarDismissed(state) &&
    getMarketingMessagesTopBar(state).length
  );
};
