import { createAction, handleActions } from "redux-actions";

const REGISTER_SECTION = "app/stockPageSections/REGISTER_SECTION";
const UNREGISTER_SECTION = "app/stockPageSections/UNREGISTER_SECTION";

export const constants = {
  REGISTER_SECTION,
  UNREGISTER_SECTION
};

const registerSection = createAction(REGISTER_SECTION, (sectionId, title) => ({
  sectionId,
  title
}));

const unregisterSection = createAction(UNREGISTER_SECTION, sectionId => ({
  sectionId
}));

export const actions = {
  registerSection,
  unregisterSection
};

export const initialState = [];

export default handleActions(
  {
    [REGISTER_SECTION]: (state, { payload: { sectionId, title } }) => [
      ...state,
      { sectionId, title }
    ],
    [UNREGISTER_SECTION]: (state, { payload: { sectionId } }) =>
      state.filter(item => item.sectionId !== sectionId)
  },
  initialState
);
