import { fork, put, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import { getIsWeb } from "../../../lib/platformHelper";
import { actions, constants } from "./index";

function* onFetchMenu() {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.menu",
      skipAccessCheck: true
    });

    yield put(actions.fetchMenuSuccess(data));

    if (getIsWeb()) {
      let event = new Event("resize");
      window.dispatchEvent(event);
    }
  } catch (error) {
    yield put(actions.fetchMenuError());

    console.error(error);
  }
}

function* watchFetchMenu() {
  yield takeLatest(constants.FETCH_MENU, onFetchMenu);
}

export default [fork(watchFetchMenu)];
