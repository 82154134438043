import React from "react";
import { Redirect } from "react-router";
import { UrlProvider } from "../../../../../api/UrlProvider";
import withTranslations from "../../../../../hocs/withTranslations";
import config from "../../../../../config";

const SettingsPageRedirect = ({ locale }) => (
  <Redirect
    to={UrlProvider.getUrl("fe.settingsPageCategory", {
      locale,
      settingsCategory:
        config.vendor !== "expalerts" ? "personal" : "notifications"
    })}
  />
);

export default withTranslations(SettingsPageRedirect);
