import Url from "url-parse";

import config from "../config";

const internalLinkHostNames = config.internalLinkHostNames;
const followableLinkHostNames = config.followableLinkHostNames;

export function isInternalLink(url) {
  try {
    const urlObj = new Url(url);
    const hostname = urlObj.hostname;

    return internalLinkHostNames.includes(hostname);
  } catch (e) {
    return false;
  }
}

export function isFollowableLink(url) {
  try {
    const urlObj = new Url(url);
    const hostname = urlObj.hostname;

    return followableLinkHostNames.includes(hostname);
  } catch (e) {
    return false;
  }
}

export function isExternalLink(url) {
  return !isInternalLink(url);
}

export function isNotFollowableLink(url) {
  return !isFollowableLink(url);
}
