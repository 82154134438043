import NumberHelper from "./UniversalTable/RendererHelpers/NumberHelper";
import { getIsWeb } from "./platformHelper";

export default function getDesktopItemDetailsBoxes(props) {
  const {
    quoteData,
    translate,
    rate_of_returns_1w,
    rate_of_returns_1m,
    rate_of_returns_6m,
    rate_of_returns_3m,
    rate_of_returns_1y,
    rate_of_returns_2y,
    rate_of_returns_3y,
    rate_of_returns_5y,
    rate_of_returns_ytd,
    priceearnings_ratio,
    price_to_book_value_ratio,
    stock_beta_parameter,
    stock_freefloat_value,
    capitalization,
    stock_turnover_parameter,
    is_index
  } = props;

  const priceChangeBoxes = [
    {
      label: "1D",
      value: quoteData ? quoteData.change * 100 : 0
    },
    {
      label: "1W",
      value: rate_of_returns_1w
    },
    {
      label: "1M",
      value: rate_of_returns_1m
    },
    {
      label: "3M",
      value: rate_of_returns_3m
    },
    {
      label: "6M",
      value: rate_of_returns_6m
    },
    {
      label: "1Y",
      value: rate_of_returns_1y
    },
    {
      label: "2Y",
      value: rate_of_returns_2y
    },
    {
      label: "3Y",
      value: rate_of_returns_3y
    },
    {
      label: "5Y",
      value: rate_of_returns_5y
    },
    {
      label: "YTD",
      value: rate_of_returns_ytd
    }
  ];

  let financialStatsBoxes = [];
  let turnoverBoxes = [];

  if (getIsWeb()) {
    if (is_index) {
      financialStatsBoxes = [
        {
          label: translate("Beta"),
          value:
            stock_beta_parameter !== null &&
            typeof stock_beta_parameter !== "undefined"
              ? Number(stock_beta_parameter).toFixed(2)
              : "-"
        },
        {
          label: translate("Turnover today"),
          value: quoteData
            ? NumberHelper.render(
                quoteData.volume * quoteData.close,
                "number_abbreviated_with_dot"
              )
            : "-"
        },
        {
          label: translate("Avg. 50 sessions"),
          value:
            stock_turnover_parameter !== null &&
            typeof stock_turnover_parameter !== "undefined" &&
            quoteData
              ? NumberHelper.render(
                  stock_turnover_parameter * quoteData.close,
                  "number_abbreviated_with_dot"
                )
              : "-"
        }
      ];
    } else {
      financialStatsBoxes = [
        {
          label: translate("C/Z"),
          value:
            priceearnings_ratio !== null &&
            typeof priceearnings_ratio !== "undefined"
              ? Number(priceearnings_ratio).toFixed(2)
              : "-",
          width: "23%"
        },
        {
          label: translate("C/WK"),
          value:
            price_to_book_value_ratio !== null &&
            typeof price_to_book_value_ratio !== "undefined"
              ? Number(price_to_book_value_ratio).toFixed(2)
              : "-",
          width: "23%"
        },
        {
          label: translate("Beta"),
          value:
            stock_beta_parameter !== null &&
            typeof stock_beta_parameter !== "undefined"
              ? Number(stock_beta_parameter).toFixed(2)
              : "-",
          width: "21%"
        },
        {
          label: translate("Freefloat"),
          value:
            stock_freefloat_value !== null &&
            typeof stock_freefloat_value !== "undefined"
              ? `${Number(stock_freefloat_value).toFixed(2)}%`
              : "-",
          width: "33%"
        },
        {
          label: translate("Captialization"),
          value:
            capitalization !== null && typeof capitalization !== "undefined"
              ? NumberHelper.render(
                  capitalization,
                  "number_abbreviated_with_dot"
                )
              : "-",
          width: "43%"
        },
        {
          label: translate("Turnover today"),
          label2: translate("Avg. 50 sessions"),
          value: quoteData
            ? NumberHelper.render(
                quoteData.volume * quoteData.close,
                "number_abbreviated_with_dot"
              )
            : "-",
          value2:
            stock_turnover_parameter !== null &&
            typeof stock_turnover_parameter !== "undefined" &&
            quoteData
              ? NumberHelper.render(
                  stock_turnover_parameter * quoteData.close,
                  "number_abbreviated_with_dot"
                )
              : "-",
          width: "46%",
          pullRight: true
        }
      ];
    }
  } else {
    if (is_index) {
      financialStatsBoxes = [
        {
          label: translate("Beta"),
          value:
            stock_beta_parameter !== null &&
            typeof stock_beta_parameter !== "undefined"
              ? Number(stock_beta_parameter).toFixed(2)
              : "-",
          width: "33.3333%"
        },
        {
          label: translate("Turnover today"),
          value: quoteData
            ? NumberHelper.render(
                quoteData.volume * quoteData.close,
                "number_abbreviated_with_dot"
              )
            : "-",
          width: "33.3333%"
        },
        {
          label: translate("Avg. 50 sessions"),
          value:
            stock_turnover_parameter !== null &&
            typeof stock_turnover_parameter !== "undefined" &&
            quoteData
              ? NumberHelper.render(
                  stock_turnover_parameter * quoteData.close,
                  "number_abbreviated_with_dot"
                )
              : "-",
          width: "33.3333%"
        }
      ];
    } else {
      financialStatsBoxes = [
        {
          label: translate("C/Z"),
          value:
            priceearnings_ratio !== null &&
            typeof priceearnings_ratio !== "undefined"
              ? Number(priceearnings_ratio).toFixed(2)
              : "-",
          width: "20%"
        },
        {
          label: translate("C/WK"),
          value:
            price_to_book_value_ratio !== null &&
            typeof price_to_book_value_ratio !== "undefined"
              ? Number(price_to_book_value_ratio).toFixed(2)
              : "-",
          width: "20%"
        },
        {
          label: translate("Beta"),
          value:
            stock_beta_parameter !== null &&
            typeof stock_beta_parameter !== "undefined"
              ? Number(stock_beta_parameter).toFixed(2)
              : "-",
          width: "20%"
        },
        {
          label: translate("Freefloat"),
          value:
            stock_freefloat_value !== null &&
            typeof stock_freefloat_value !== "undefined"
              ? `${Number(stock_freefloat_value).toFixed(2)}%`
              : "-",
          width: "20%"
        },
        {
          label: translate("Captialization"),
          value:
            capitalization !== null && typeof capitalization !== "undefined"
              ? NumberHelper.render(
                  capitalization,
                  "number_abbreviated_with_dot"
                )
              : "-",
          width: "20%"
        }
      ];

      turnoverBoxes = [
        {
          label: translate("Turnover today"),
          value: quoteData
            ? NumberHelper.render(
                quoteData.volume * quoteData.close,
                "number_abbreviated_with_dot"
              )
            : "-",
          width: "50%"
        },
        {
          label: translate("Avg. 50 sessions"),
          value:
            stock_turnover_parameter !== null &&
            typeof stock_turnover_parameter !== "undefined" &&
            quoteData
              ? NumberHelper.render(
                  stock_turnover_parameter * quoteData.close,
                  "number_abbreviated_with_dot"
                )
              : "-",
          width: "50%"
        }
      ];
    }
  }

  return { priceChangeBoxes, financialStatsBoxes, turnoverBoxes };
}
