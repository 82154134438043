import React from "react";
import classNames from "classnames";

import "../styles/default.scss";

const Loader = ({ small = false, className }) => (
  <div
    className={classNames(
      {
        "squaber-loader": true,
        "text-center": true,
        small
      },
      className
    )}
  >
    <i className="fal fa-spinner fa-spin" />
  </div>
);

export default Loader;
