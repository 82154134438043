import React from "react";

import config from "../config";
import { getIsAndroid, getIsWeb } from "../lib/platformHelper";
import MarketingBarEvent from "../lib/SalesManago/events/MarketingBarEvent";

export default class MarketingBarsAbstract extends React.Component {
  static defaultState = {
    isExpanded: getIsAndroid(),
    isHidden: false
  };

  state = MarketingBarsAbstract.defaultState;

  maxLength = 30;

  expand() {
    this.setState({
      isExpanded: true
    });
  }

  hide() {
    this.props.setMarketingBarsHidden(true);
  }

  shouldReturnNull() {
    const { marketingBars, marketingBarsHidden } = this.props;

    return (
      config.vendor === "expalerts" || !marketingBars || marketingBarsHidden
    );
  }

  shouldEllipsize(finalMarketingBar) {
    const { isMobile } = this.props;

    return (
      (isMobile || !getIsWeb()) &&
      finalMarketingBar.text.length > this.maxLength
    );
  }

  getFinalMarketingBar() {
    const { marketingBars, marketingBarsSeen } = this.props;

    const matchingMarketingBars = marketingBars.filter(
      marketingBar => marketingBarsSeen.indexOf(marketingBar.id) === -1
    );

    return matchingMarketingBars[0];
  }

  trackInteraction(finalMarketingBar, action, trackSMEvent) {
    const smEvent = new MarketingBarEvent({
      id: finalMarketingBar.id,
      text: finalMarketingBar.text,
      url: finalMarketingBar.url,
      action: action
    });
    trackSMEvent(smEvent);
  }
}
