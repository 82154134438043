import "../styles/default.scss";

import classNames from "classnames";
import moment from "moment-mini";
import PropTypes from "prop-types";
import React from "react";

import Price from "../../../../../common/Price";
import withUserData from "../../../../../hocs/withUserData";
import accessLevelsMap from "../../../../../lib/access/accessLevelsMap";
import getAccessLevel from "../../../../../lib/access/getAccessLevel";
import transformPremiumText from "../../../../../lib/transformPremiumText";
import NoAccessDisclaimer from "../../NoAccessDisclaimer";

const SignalDetails = ({
  translate,
  signal,
  currency,
  userData,
  userStock
}) => {
  let qualityConvert = signal.quality * 10;
  const accessLevel = getAccessLevel(userData, userStock);
  const userHasAccess = accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS;

  return (
    <div className={"signal-details-wrapper"}>
      <div className="signal-information">
        {translate(
          "This signal was generated on %{createDate} at %{createTime}. ",
          {
            createDate: moment(signal.created).format("DD.MM.YYYY"),
            createTime: moment(signal.created).format("HH:mm:SS")
          }
        )}

        {signal.created
          ? translate(
              "The last change of parameters passed on %{createDate} at %{createTime}. ",
              {
                createDate: moment(signal.updated).format("DD.MM.YYYY"),
                createTime: moment(signal.updated).format("HH:mm:SS")
              }
            )
          : null}

        {translate("Details are detailed below.")}
      </div>
      {signal?.strategy?.name ? (
        <div className={"name--strategy"}>
          <strong>{signal.strategy.name}</strong>
        </div>
      ) : null}
      <div
        className={classNames({
          "signal-details": true,
          blurred: !userHasAccess,
          [`signal-type-${signal.type}`]: true
        })}
      >
        <div className="details-item opening-price">
          <span className="title">{translate("Opening price")}</span>
          <span className="value">
            <Price
              value={userHasAccess ? signal.price : 0}
              currency={currency}
            />
          </span>
        </div>
        <div className="details-item signal-quality">
          <span className="title">{translate("Signal quality")}</span>
          <span className="value">
            {new Array(10).fill(0).map((element, index) => (
              <span
                className={`signal ${
                  qualityConvert >= index + 1 ? "filled" : ""
                }`}
                key={index}
              />
            ))}
          </span>
        </div>
        <div className="details-item scenario-negation">
          <span className="title">{translate("Negation of the scenario")}</span>
          <span className="value">
            <Price value={userHasAccess ? signal.sl : 0} currency={currency} />
          </span>
        </div>
        <div className="details-item target-price">
          <span className="title">{translate("Target price")}</span>
          <span className="value">
            <Price value={userHasAccess ? signal.tp : 0} currency={currency} />
          </span>
        </div>
      </div>
      {!userHasAccess ? <NoAccessDisclaimer /> : null}
      <div
        className={classNames({
          "signal-content": true,
          "text-block": userHasAccess,
          blurred: !userHasAccess
        })}
        dangerouslySetInnerHTML={{
          __html: transformPremiumText(signal.description, userHasAccess)
        }}
      />
      {signal?.strategy?.description ? (
        <div
          className={"description--strategy"}
          dangerouslySetInnerHTML={{ __html: signal.strategy.description }}
        />
      ) : null}
    </div>
  );
};

SignalDetails.propTypes = {
  signal: PropTypes.object,
  currency: PropTypes.string
};

export default withUserData(SignalDetails);
