import FormsyComposedTextField from "../../../../../lib/FormBuilder/FormsyMaterialUI/FormsyComposedTextField";
import FormsyCheckbox from "../../../../../lib/FormBuilder/FormsyMaterialUI/FormsyCheckbox";
import Formsy from "formsy-react";
import React from "react";

function EditTemplate({ initModel, onChange, translate }) {
  return (
    <Formsy onChange={form => onChange(form)}>
      <FormsyComposedTextField
        name="name"
        label={translate("Analytic name")}
        value={initModel.name}
        fullWidth
        required
        maxLength={50}
        validationErrors={{
          isDefaultRequiredValue: "This field is required"
        }}
      />
      <FormsyCheckbox
        label="Is default"
        name="is_default"
        value={!!initModel?.is_default}
      />
    </Formsy>
  );
}

export default EditTemplate;

EditTemplate.propTypes = {};
