import React from "react";

import config from "../config";
import ConsentModeHelperWeb from "../lib/ConsentModeHelper/ConsentModeHelperWeb";
import { showModal } from "../lib/react-redux-modal-provider";
import CookiesConsentEvent from "../lib/SalesManago/events/CookiesConsentEvent";
import withIsHttpRequestsDone from "../web/hocs/withIsHttpRequestsDone";
import withSmEventTracker from "./withSmEventTracker";

export default function withConsentSettingsWeb(WrappedComponent) {
  @withSmEventTracker
  @withIsHttpRequestsDone
  class WithConsentSettingsWeb extends React.Component {
    constructor() {
      super();
      this.state = { showConsent: false };
    }
    componentDidMount() {
      const { userData } = this.props;

      // This needs to happen before GTM scripts execution
      if (config.trackSMEvents && userData?.contact_id) {
        window._smclientid = userData.contact_id;
      }

      ConsentModeHelperWeb.applyDefaultConsent();
      const consentSettings = ConsentModeHelperWeb.attemptLoadConsentSettings();

      if (
        consentSettings &&
        ConsentModeHelperWeb.validateSettings(consentSettings)
      ) {
        ConsentModeHelperWeb.applyConsentSettings(consentSettings);
      } else {
        this.setState({ showConsent: true });
      }
    }

    componentDidUpdate(
      prevProps: Readonly<P>,
      prevState: Readonly<S>,
      snapshot: SS
    ) {
      const { showConsent } = this.state;
      const { trackSMEvent, isHttpRequestsDone } = this.props;

      if (
        isHttpRequestsDone &&
        ConsentModeHelperWeb.shouldShowModal() &&
        showConsent
      ) {
        showModal("ConsentV2Modal", {
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
          onApplySettings: settings => {
            if (ConsentModeHelperWeb.validateSettings(settings)) {
              ConsentModeHelperWeb.applyConsentSettings(settings);

              const smEvent = new CookiesConsentEvent(settings);
              trackSMEvent(smEvent);
            }
          }
        });
        this.setState({ showConsent: false });
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithConsentSettingsWeb;
}
