import React from "react";
import { isEmpty } from "lodash";
import withTranslations from "./withTranslations";
import withFormBuilder from "./withFormBuilder";

export default function withTagDetailsForm(WrappedComponent) {
  @withTranslations
  @withFormBuilder(props => {
    const { tagDetails } = props;

    let nameValue = "";
    let descriptionValue = "";

    if (tagDetails && tagDetails.data && !isEmpty(tagDetails.data)) {
      nameValue = tagDetails.data.name;
      descriptionValue = tagDetails.data.description;
    }

    return [
      {
        name: "name",
        label: "Tag name",
        value: nameValue,
        type: "text",
        fullWidth: true,
        required: true,
        maxLength: 20,
        validations: {
          maxLength: 20
        },
        validationErrors: {
          isDefaultRequiredValue: "This field is required",
          maxLength: "Tag name can't be longer than 20 characters"
        }
      },
      {
        name: "description",
        label: "Tag description",
        value: descriptionValue,
        type: "text",
        fullWidth: true,
        required: false,
        multiline: true,
        validationErrors: {
          isDefaultRequiredValue: "This field is required"
        }
      }
    ];
  })
  class WithTagDetailsForm extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithTagDetailsForm;
}
