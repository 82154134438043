import { fork, put, select, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import { UrlProvider } from "../../../api/UrlProvider";
import config from "../../../config";
import { actions as alertsActions } from "../alerts";
import { getLocale } from "../translations/selector";
import { fetchHistoryListRoutine, fetchNextPageRoutine } from "./index";
import { getInterestingStocksState } from "./selector";

const defaultLimit = 9;

function* onFetchHistoryList({
  payload: {
    urlPath,
    filterByStock,
    filterByCategoryIds,
    reCaptchaToken,
    apiMethod
  }
}) {
  let additionalVariables = {};

  if (filterByStock) {
    additionalVariables = {
      ticker: filterByStock.ticker,
      market: filterByStock.market
    };
  }

  try {
    const locale = yield select(getLocale);
    const apiUrl = config.apiUrl.replace(":locale", locale);

    let url = UrlProvider.getUrl(
      urlPath,
      {
        limit: defaultLimit,
        ...additionalVariables
      },
      apiUrl
    );

    if (filterByCategoryIds) {
      if (url.includes("?")) {
        url += `&${filterByCategoryIds}`;
      } else {
        url += `?${filterByCategoryIds}`;
      }
    }

    let data;

    if (apiMethod === "post") {
      const response = yield ApiClient.post({
        url,
        data: { recaptcha: reCaptchaToken }
      });

      data = response.data;
    } else {
      const response = yield ApiClient.get({
        url
      });

      data = response.data;
    }

    yield put(fetchHistoryListRoutine.success(data));
  } catch (error) {
    console.error(error);

    if (error?.response?.data?.message === "Recaptcha failed") {
      yield put(
        alertsActions.addErrorAlert({
          title: "Recaptcha failed"
        })
      );
    }

    yield put(fetchHistoryListRoutine.failure(error));
  }
}

function* onFetchNextPage({ payload: { apiMethod, reCaptchaToken } }) {
  const currentState = yield select(getInterestingStocksState);

  const {
    data: { next: nextPageUrl }
  } = currentState;

  try {
    let data;

    if (apiMethod === "post") {
      const response = yield ApiClient.post({
        url: nextPageUrl,
        data: { recaptcha: reCaptchaToken }
      });

      data = response.data;
    } else {
      const response = yield ApiClient.get({
        url: nextPageUrl
      });

      data = response.data;
    }

    yield put(fetchNextPageRoutine.success(data));
  } catch (error) {
    console.error(error);

    if (error?.response?.data?.message === "Recaptcha failed") {
      yield put(
        alertsActions.addErrorAlert({
          title: "Recaptcha failed"
        })
      );
    }

    yield put(fetchNextPageRoutine.failure(error));
  }
}

function* watchFetchInterestingStocks() {
  yield takeLatest(fetchHistoryListRoutine.TRIGGER, onFetchHistoryList);

  yield takeLatest(fetchNextPageRoutine.TRIGGER, onFetchNextPage);
}

export default [fork(watchFetchInterestingStocks)];
