import React from "react";
import connect from "../i18n/connect";
import { getGlobalConfigField } from "../redux/modules/globalConfig/selector";

/**
 *
 * @param {string[]} fields [Required]
 * @returns {function(*): WithGlobalConfigFields}
 */
export default function withGlobalConfigFields(fields: Array<string>) {
  return WrappedComponent => {
    const mapStateToProps = state => {
      const globalConfigFields = {};

      for (let field of fields) {
        globalConfigFields[field] = getGlobalConfigField(state, field);
      }

      return {
        globalConfig: globalConfigFields
      };
    };

    @connect(mapStateToProps)
    class WithGlobalConfigFields extends React.Component {
      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return WithGlobalConfigFields;
  };
}
