import { fork, put, select, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import ChangeMarketsSettingsEvent from "../../../lib/SalesManago/events/ChangeMarketsSettingsEvent";
import { actions as alertsActions } from "../alerts";
import { getUserData } from "../auth/login/selector";
import { trackSMEvent } from "../salesManagoEventTracker";
import { getUserMarkets, TOGGLE_MARKET } from "./index";
import { getUserMarketsState } from "./selector";

function* onGetUserMarkets() {
  try {
    const { data } = yield ApiClient.get("app.auth.markets");

    yield put(getUserMarkets.success({ data: data.message }));
  } catch (error) {
    console.log(error);
  }
}

function* onToggleMarket({ payload }) {
  const { markets } = yield select(getUserMarketsState);

  try {
    yield ApiClient.put({
      urlPath: "app.auth.markets",
      data: {
        value: markets
      }
    });
    const smEvent = new ChangeMarketsSettingsEvent(payload);
    yield put(trackSMEvent(smEvent));

    yield put(
      alertsActions.addSuccessAlert({
        title: "Changes saved"
      })
    );
  } catch (error) {
    console.log(error);
  }
}

function* watchGetUserMarkets() {
  yield takeLatest(getUserMarkets.TRIGGER, onGetUserMarkets);
  yield takeLatest(TOGGLE_MARKET, onToggleMarket);
}

export default [fork(watchGetUserMarkets)];
