import React from "react";

import ApiClientAsync from "../api/ApiClientAsync";

export default function withStockAnalysesCategories(WrappedComponent) {
  class WithStockAnalysesCategories extends React.Component {
    state = {
      stockPageAnalysisCategories: []
    };
    async componentDidMount() {
      this.setState({
        stockPageAnalysisCategories: await this.getAnalysisCategories()
      });
    }

    async getAnalysisCategories() {
      const { stock, locale, authToken } = this.props;
      const apiClient = new ApiClientAsync(locale, authToken);

      const { ticker, market } = stock?.stock ?? {};

      if (!market || !ticker) {
        return this.state.stockPageAnalysisCategories;
      }

      try {
        const response = await apiClient.get({
          urlPath: "app.stocks.stockAnalysesFilteredByCategory",
          variables: {
            ticker,
            market
          }
        });

        return response.filter(analyse => analyse?.analysis_count > 0);
      } catch (e) {
        console.error(e);
        return [];
      }
    }

    async componentDidUpdate(prevProps) {
      const { stock } = this.props;
      const { ticker, market } = stock?.stock ?? {};
      const prevTicker = prevProps?.stock?.stock?.ticker;
      const prevMarket = prevProps?.stock?.stock?.market;
      const stockChanged = ticker !== prevTicker || market !== prevMarket;

      if (!ticker || !market || !stockChanged) return;

      this.setState({
        stockPageAnalysisCategories: await this.getAnalysisCategories()
      });
    }

    render() {
      const analysisCategories = this.state.stockPageAnalysisCategories;
      return (
        <WrappedComponent
          {...this.props}
          analysisCategories={analysisCategories}
        />
      );
    }
  }

  return WithStockAnalysesCategories;
}
