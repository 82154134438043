import React from "react";
import UniversalTableLiteWeb from "../../../common/UniversalTableLiteWeb";
import ContentSection from "../../../common/ContentSection";
import withTranslations from "../../../../../hocs/withTranslations";
import PageTitle from "../../../common/PageTitle";
import ResourceResolver from "../../../../../lib/UniversalTable/ResourceResolver";

const CurrenciesTable = ({ translate }) => (
  <ContentSection>
    <PageTitle title={translate("list_of_currencies_page_title")} />
    <h1>{translate("list_of_currencies_page_header")}</h1>
    <p
      dangerouslySetInnerHTML={{
        __html: translate("list_of_currencies_page_description")
      }}
    />
    <UniversalTableLiteWeb
      resourceId={ResourceResolver["financial_instruments_list"]}
      defaultSettings={{
        filters: {
          market: ["FOREX"]
        }
      }}
      useUrlSettings
    />
  </ContentSection>
);

export default withTranslations(CurrenciesTable);
