import { isEqual } from "lodash";
import React from "react";

import connect from "../i18n/connect";
import { clearStockData, stockDataRoutine } from "../redux/modules/stockData";
import {
  getStockData,
  getStockDataErrorResponse,
  getStockDataLoading
} from "../redux/modules/stockData/selector";

export default function withStockData(
  wait = false,
  placeholder = null,
  enableSkipFetchStockData = false
) {
  return WrappedComponent => {
    const mapStateToProps = state => ({
      stockData: getStockData(state),
      stockDataLoading: getStockDataLoading(state),
      stockDataErrorResponse: getStockDataErrorResponse(state)
    });

    const mapDispatchToProps = {
      fetchStockData: stockDataRoutine,
      clearStockData
    };

    @connect(mapStateToProps, mapDispatchToProps)
    class WithStockData extends React.Component {
      state = {
        skipFetchStockData: enableSkipFetchStockData
      };

      componentDidMount(): void {
        const {
          fetchStockData,
          clearStockData,
          match,
          ticker,
          market
        } = this.props;

        const { skipFetchStockData } = this.state;

        if (skipFetchStockData) {
          clearStockData();
          return;
        }

        if (wait) {
          clearStockData();
        }

        let stockId, marketId;

        if (match && match.params) {
          stockId = match.params.stockId;
          marketId = match.params.marketId;
        }

        if (ticker && market) {
          stockId = ticker;
        }

        fetchStockData({ marketId, stockId });
      }

      componentDidUpdate(prevProps: Readonly<P>, prevState: Readonly<S>): void {
        const { fetchStockData } = this.props;
        const { skipFetchStockData } = this.state;

        if (!prevProps.match || !this.props.match) {
          return;
        }

        if (skipFetchStockData) {
          return;
        }

        const prevParams = {
          marketId: prevProps.match.params.marketId,
          stockId: prevProps.match.params.stockId,
          skipFetchStockData: prevState.skipFetchStockData
        };

        const currentParams = {
          marketId: this.props.match.params.marketId,
          stockId: this.props.match.params.stockId,
          skipFetchStockData
        };

        if (!isEqual(prevParams, currentParams)) {
          fetchStockData(currentParams);
        }
      }

      render() {
        if (wait && !this.props.stockData) {
          return placeholder;
        }

        return (
          <WrappedComponent
            {...this.props}
            setSkipFetchStockData={value =>
              this.setState({ skipFetchStockData: value })
            }
            skipFetchStockData={this.state.skipFetchStockData}
          />
        );
      }
    }

    return WithStockData;
  };
}
