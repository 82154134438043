import React from "react";
import connect from "../i18n/connect";
import {
  fetchSelectedOfferRoutine,
  finalizePaymentRoutine
} from "../redux/modules/payment";
import {
  getPaymentFormLoading,
  getSelectedOffer,
  getSelectedOfferError,
  getSelectedOfferLoading
} from "../redux/modules/payment/selector";

export default function withPaymentForm(WrappedComponent) {
  const mapStateToProps = state => ({
    selectedOffer: getSelectedOffer(state),
    selectedOfferLoading: getSelectedOfferLoading(state),
    selectedOfferError: getSelectedOfferError(state),
    paymentFormLoading: getPaymentFormLoading(state)
  });

  const mapDispatchToProps = {
    finalizePayment: finalizePaymentRoutine,
    fetchSelectedOffer: fetchSelectedOfferRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithPaymentForm extends React.Component {
    componentDidMount() {
      const {
        fetchSelectedOffer,
        match: {
          params: { offerId }
        }
      } = this.props;

      fetchSelectedOffer({ offerId });
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithPaymentForm;
}
