import React from "react";
import connect from "../../../i18n/connect";
import classNames from "classnames";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import RadioGroup from "@material-ui/core/RadioGroup";
import { withFormsy } from "formsy-react";
import FormsyBase from "./FormsyBase";

@connect()
@withFormsy
class FormsyRadioGroup extends FormsyBase {
  render() {
    const {
      radioGroupClassName,

      children,

      errorMessage,
      value,
      translate
    } = this.props;

    const showError = this.showError();

    let classes = {};

    if (radioGroupClassName) {
      classes[radioGroupClassName] = true;
    }

    classes = classNames({
      ...classes,
      "jtc-formsy-radio-group": true
    });

    return (
      <FormControl error={showError}>
        <RadioGroup
          value={value || ""}
          className={classes}
          onChange={(event, value) => this.handleChange(value)}
        >
          {children}
        </RadioGroup>
        {showError ? (
          <FormHelperText>{translate(errorMessage)}</FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}

export default FormsyRadioGroup;
