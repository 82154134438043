function getDiscountAmount({ amount = 0, discounted_amount = 0 }) {
  const minPriceAfterDiscount = 1;

  if (amount === 0) return 0;
  if (discounted_amount >= amount) return 0;

  if (discounted_amount < minPriceAfterDiscount) {
    return amount - minPriceAfterDiscount;
  }

  return amount - discounted_amount;
}

export default getDiscountAmount;
