import theme from "../theme";

export default function getValueColor(
  value: number,
  type: string = null,
  forText: boolean = false
) {
  if (type) {
    switch (type) {
      case "positive":
      case "very_positive":
        return theme.colors.success;

      case "negative":
      case "very_negative":
        return theme.colors.error;

      case "unknown":
        return theme.colors.baseGrey2;

      case "neutral":
      default:
        return theme.colors.warningText;
    }
  }

  if (value === null) {
    return theme.colors.baseGrey2;
  }

  if (value > 3) {
    return theme.colors.success;
  } else if (value > 2) {
    return theme.colors.warningText;
  } else {
    return theme.colors.error;
  }
}
