import "../styles/default.scss";

import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

import config from "../../../../../config";
import withSmEventTracker from "../../../../../hocs/withSmEventTracker";
import withTranslations from "../../../../../hocs/withTranslations";
import AnalyticsEventTracker from "../../../../../lib/AnalyticsEventTracker";
import OfferModalEvent from "../../../../../lib/SalesManago/events/OfferModalEvent";
import SquaberButton from "../../../common/SquaberButton";
import ModalBase from "../../ModalBase";
import prepareEmbeddedLandingPagUrl from "../../../../../lib/prepareEmbeddedLandingPagUrl";

@withTranslations
@withSmEventTracker
class PremiumModal extends ModalBase {
  static defaultProps = {
    name: "Generic missing access handler"
  };

  componentDidMount() {
    const { name, trackSMEvent } = this.props;

    const smEvent = new OfferModalEvent(name);
    trackSMEvent(smEvent);

    AnalyticsEventTracker.trackOfferModalEvent();
  }

  getClassName(): null {
    return "squaber-premium-modal";
  }

  getContent() {
    const { hideModal, translate, iframeUrl } = this.props;

    const finalIframeUrl = iframeUrl || config.defaultPremiumPopupUrl;

    return (
      <React.Fragment>
        <DialogTitle id="form-dialog-title">
          {translate("premium-modal-heading")}
        </DialogTitle>
        <DialogContent className="premium-modal-content">
          <iframe src={prepareEmbeddedLandingPagUrl(finalIframeUrl)} />
        </DialogContent>
        <DialogActions className="premium-modal-actions">
          <SquaberButton onClick={hideModal} color="primary">
            {translate("Close")}
          </SquaberButton>
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default PremiumModal;
