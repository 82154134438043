import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import config from "../../../../../config";
import withTranslations from "../../../../../hocs/withTranslations";
import withUserData from "../../../../../hocs/withUserData";

const parseLang = (lang: string) => {
  const defaultLang = config.defaultLocale;
  const acceptedLanguages = config.supportedLocales;

  if (typeof lang !== "string") return defaultLang;

  const lowerCaseLang = lang.toLowerCase();
  if (acceptedLanguages.includes(lowerCaseLang)) return lowerCaseLang;

  return defaultLang;
};

const getFallbackLocale = () => {
  const pathname = window?.location?.pathname;
  const match = pathname?.match(/\/([a-z]{2})(?:\/|$)/);

  return config.supportedLocales.includes(match?.[1]) ? match[1] : undefined;
};

function LanguageHelper({ setLocale, userData, history, locale }) {
  const lang = userData?.lang;

  useEffect(() => {
    handleLanguageChange();
  }, [lang, history?.location?.pathname]);

  useEffect(() => {
    const fallbackLocale = getFallbackLocale();

    if (
      typeof lang === "undefined" &&
      typeof locale !== "undefined" &&
      typeof fallbackLocale !== "undefined" &&
      locale !== fallbackLocale
    ) {
      setLocale(fallbackLocale);
    }
  }, [lang, locale]);

  const handleLanguageChange = () => {
    if (typeof setLocale !== "function" || typeof lang !== "string") return;

    const pathname = window?.location?.pathname;

    if (typeof pathname === "undefined") return;

    const parsedLang = parseLang(lang);
    const containCurrentLang = new RegExp(`/${parsedLang}/`, "g");

    if (containCurrentLang.test(pathname)) return;

    const newUrl = UrlProvider.replaceLocaleFromUrl(pathname, parsedLang);

    setLocale(parsedLang);
    history.push(newUrl);
  };

  return null;
}

export default compose(
  withTranslations,
  withRouter,
  withUserData
)(LanguageHelper);
