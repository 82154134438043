import { takeLatest, fork, put } from "redux-saga/effects";
import { fetchConfigPageRoutine } from "./index";
import ApiClient from "../../../api/ApiClient";

function* fetchConfigPage({ payload: type }) {
  try {
    const { data } = yield ApiClient.get(`app.config.${type}`);

    yield put(fetchConfigPageRoutine.success(data));
  } catch (e) {
    yield put(fetchConfigPageRoutine.failure(e));
  }
}

function* watchFetchConfigPage() {
  yield takeLatest(fetchConfigPageRoutine.TRIGGER, fetchConfigPage);
}

export default [fork(watchFetchConfigPage)];
