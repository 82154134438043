import "../styles/default.scss";

import { makeStyles } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Select from "@material-ui/core/Select";
import PropTypes from "prop-types";
import React from "react";
import { compose } from "redux";

import withAnalysisCategories from "../../../../../hocs/withAnalysisCategories";
import withTranslations from "../../../../../hocs/withTranslations";

const useStyles = makeStyles(theme => ({
  select: {
    fontSize: "14px"
  },
  outlinedInput: {
    paddingTop: "8px",
    paddingBottom: "8px"
  },
  iconOutlined: {
    marginTop: "-2px"
  }
}));

const AnalysisSort = ({
  setAnalysisSort,
  analysisFilters,
  translate,
  analysisSortOptions
}) => {
  const classes = useStyles();

  return (
    <div className={"analysis-sort"}>
      <label className={"analysis-sort__label"} id="analysis-sort">
        {translate("analysis_sort_label")}
      </label>
      <Select
        className={classes.select}
        labelId={"analysis-sort"}
        value={analysisFilters.sort ?? analysisSortOptions?.[0]?.value}
        onChange={event => {
          const value = event.target.value;

          if (typeof value === "string") {
            setAnalysisSort(value);
          }
        }}
        variant={"outlined"}
        input={<OutlinedInput classes={{ input: classes.outlinedInput }} />}
        inputProps={{
          classes: {
            iconOutlined: classes.iconOutlined
          }
        }}
      >
        {analysisSortOptions.map(({ label, value }) => {
          return (
            <MenuItem
              key={value}
              value={value}
              className="analysis-sort__option"
            >
              {translate(label)}
            </MenuItem>
          );
        })}
      </Select>
    </div>
  );
};

AnalysisSort.propTypes = {
  analysisCategories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  analysisFilters: PropTypes.shape({
    sort: PropTypes.string
  }),
  analysisSortOptions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  setAnalysisSort: PropTypes.func
};

export default compose(withAnalysisCategories, withTranslations)(AnalysisSort);
