import classNames from "classnames";
import PropTypes from "prop-types";
import qs from "qs";
import React, { lazy, Suspense } from "react";
import { withRouter } from "react-router";
import { Col, Container, Row } from "reactstrap";

import { UrlProvider } from "../../api/UrlProvider";
import withDeviceType from "../../hocs/withDeviceType";
import withExecuteAuthRedirect from "../../hocs/withExecuteAuthRedirect";
import withOverlay from "../../hocs/withOverlay";
import withUserData from "../../hocs/withUserData";
import PageHelper from "../../lib/PageHelper";
import { getIsMobile } from "../../lib/platformHelper";
import useMobileMediaQuery from "../hooks/useMobileMediaQuery";
import SEOHelper from "../../lib/SEOHelper/SEOHelper";

const AppInstallationCTA = lazy(() => import("./common/AppInstallationCTA"));
const Header = lazy(() => import("./navigation/Header"));
// prettier-ignore
const MobileNotificationList = lazy(() => import("./navigation/MobileNotificationList"));
// prettier-ignore
const MobilePortfoliosList = lazy(() => import("./navigation/MobilePortfoliosList"));
// prettier-ignore
const MobileFollowedStocksList = lazy(() => import("./common/MobileFollowedStocksList"));
const MobileUserMenu = lazy(() => import("./common/MobileUserMenu"));
const Footer = lazy(() => import("./common/Footer/"));
const MobileNavigation = lazy(() => import("./navigation/MobileNavigation"));

@withRouter
@withUserData
@withExecuteAuthRedirect
@withDeviceType
@withOverlay
class Template extends React.Component {
  static propTypes = { children: PropTypes.element.isRequired };

  previousPathName = null;

  componentDidMount(): void {
    const {
      location,
      executeAuthRedirect,
      userData,
      history,
      locale,
      match: {
        params: { section }
      }
    } = this.props;

    if (location.search) {
      const urlParams = qs.parse(window.location.search, {
        ignoreQueryPrefix: true
      });

      if (urlParams.authRedirect) {
        if (userData) {
          executeAuthRedirect(urlParams.authRedirect);
        } else {
          history.push(
            UrlProvider.getUrl("fe.login", {
              locale
            }) + `?authRedirect=${encodeURIComponent(urlParams.authRedirect)}`
          );
        }
      }
    }

    this.unListenHistory = history.listen(newLocation => {
      if (newLocation.pathname !== this.previousPathName) {
        const sectionArray = SEOHelper.getStockSlugList(locale);
        if (
          section &&
          !sectionArray.some(current => current[locale].slug === section)
        ) {
          window.scrollTo(0, 0);
        }
      }

      this.previousPathName = newLocation.pathname;
    });
  }

  componentWillUnmount() {
    this.unListenHistory();
  }

  render() {
    const {
      children,
      location,
      locale,
      isDeviceMobile,
      disabledOverlay,
      userData
    } = this.props;

    const isRegisterPage = PageHelper.getIsRegisterPage(location, locale);
    const isHomePage = PageHelper.getIsHomePage(location, locale);
    const isLandingPage = isHomePage || PageHelper.getIsLandingPage(location);
    const isMobile = getIsMobile() || isDeviceMobile;

    const canDisplayVerifyEmailBar =
      isMobile && userData && !userData.email_validated;

    const shouldRenderRegularAppContent =
      !isRegisterPage && !isHomePage && !isLandingPage;

    return (
      <React.Fragment>
        {shouldRenderRegularAppContent ? (
          <Suspense fallback={<div />}>
            {getIsMobile() ? <AppInstallationCTA /> : null}
            <Header />
          </Suspense>
        ) : null}
        <Container
          className={classNames({
            "app-inside": true,
            "is-register-page": isRegisterPage,
            "is-landing-page": isLandingPage
          })}
        >
          {shouldRenderRegularAppContent && isMobile ? (
            <Suspense fallback={<div />}>
              <MobileFollowedStocksList
                canDisplayVerifyEmailBar={canDisplayVerifyEmailBar}
              />
              <MobileNotificationList
                canDisplayVerifyEmailBar={canDisplayVerifyEmailBar}
              />
              <MobileUserMenu />
              <MobilePortfoliosList
                canDisplayVerifyEmailBar={canDisplayVerifyEmailBar}
              />
            </Suspense>
          ) : null}
          <Row>
            <Col sm="12">{children}</Col>
          </Row>
        </Container>
        {shouldRenderRegularAppContent ? (
          <Suspense fallback={<div />}>
            <MobileNavigation />
          </Suspense>
        ) : null}
        {!isLandingPage ? (
          <Suspense fallback={<div />}>
            <Footer />
          </Suspense>
        ) : null}
      </React.Fragment>
    );
  }
}

export default Template;
