import React from "react";
import { bindPromiseCreators } from "redux-saga-routines";
import { connect } from "../i18n/connect";
import {
  getAuthToken,
  getUserData,
  getUserID,
  getUserWasLoggedIn
} from "../redux/modules/auth/login/selector";
import { actions as authActions } from "../redux/modules/auth/login";
import getAccessLevel from "../lib/access/getAccessLevel";
import accessLevelsMap from "../lib/access/accessLevelsMap";

export default function withUserData(WrappedComponent) {
  const mapStateToProps = state => ({
    userID: getUserID(state),
    userData: getUserData(state),
    authToken: getAuthToken(state),
    userWasLoggedIn: getUserWasLoggedIn(state)
  });

  const mapDispatchToProps = {
    logout: authActions.logout,
    refreshUserData: authActions.refreshUserData,
    toggleMarketingEmails: authActions.toggleMarketingEmails
  };

  const promiseMapDispatchToProps = dispatch => ({
    ...bindPromiseCreators(
      {
        refreshUserDataPromiseCreator: authActions.refreshUserDataPromiseCreator
      },
      dispatch
    )
  });

  @connect(mapStateToProps, mapDispatchToProps)
  @connect(null, promiseMapDispatchToProps)
  class WithUserData extends React.Component {
    render() {
      const { userData } = this.props;

      const accessLevel = getAccessLevel(userData, {});
      const userHasAccess = accessLevel === accessLevelsMap.HAS_PREMIUM_ACCESS;
      const userIsNotLoggedIn = accessLevel === accessLevelsMap.NOT_LOGGED_IN;

      return (
        <WrappedComponent
          {...this.props}
          userAccessLevel={accessLevel}
          userHasAccess={userHasAccess}
          userIsNotLoggedIn={userIsNotLoggedIn}
        />
      );
    }
  }

  return WithUserData;
}
