import React from "react";

import SubmenuLink from "./SubmenuLink";

function ShowMoreSubmenuLink({ showMore, setShowMore }) {
  return (
    <SubmenuLink
      label={showMore ? "Show less" : "Show more"}
      onClick={() => setShowMore(!showMore)}
    />
  );
}

export default ShowMoreSubmenuLink;
