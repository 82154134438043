import Formsy from "formsy-react";
import React from "react";

import FormsyComposedTextField from "../../../../../lib/FormBuilder/FormsyMaterialUI/FormsyComposedTextField";

function EditAnalysis({
  initModel,
  onChange,
  translate,
  addChartTemplateErrors
}) {
  return (
    <Formsy onChange={form => onChange(form)}>
      <FormsyComposedTextField
        name="name"
        label={translate("Analysis name")}
        value={initModel.name}
        fullWidth
        required
        maxLength={50}
        validationErrors={{
          isDefaultRequiredValue: "This field is required"
        }}
      />
      <FormsyComposedTextField
        name="description"
        label={translate("Analysis description")}
        value={initModel.description}
        fullWidth
        maxLength={50}
      />
      {!!addChartTemplateErrors ? (
        <p className="form-error-message">
          {translate(addChartTemplateErrors.validation[0].value)}
        </p>
      ) : null}
    </Formsy>
  );
}

export default EditAnalysis;

EditAnalysis.propTypes = {};
