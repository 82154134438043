import { createAction, handleActions } from "redux-actions";

export const SET_EXPO_UPDATE_AVAILABLE =
  "app/expoUpdate/SET_EXPO_UPDATE_AVAILABLE";

export const SET_PREVIOUS_VERSION = "app/expoUpdate/SET_PREVIOUS_VERSION";

export const setExpoUpdateAvailable = createAction(
  SET_EXPO_UPDATE_AVAILABLE,
  (value: Boolean) => ({ value })
);

export const setPreviousVersion = createAction(
  SET_PREVIOUS_VERSION,
  (value: String) => ({ value })
);

const initialState = {
  updateAvailable: false,
  previousVersion: null
};

export default handleActions(
  {
    [SET_EXPO_UPDATE_AVAILABLE]: (state, { payload }) => ({
      ...state,
      updateAvailable: payload.value
    }),
    [SET_PREVIOUS_VERSION]: (state, { payload }) => ({
      ...state,
      previousVersion: payload.value
    })
  },
  initialState
);
