import "../styles/default.scss";

import { isArray, isEmpty } from "lodash";
import React from "react";

import TemplatesListItem from "../components/TemplatesListItem";

class TemplatesList extends React.Component {
  render() {
    const {
      templates,
      removeChartTemplate,
      fetchChartTemplateDetails,
      fetchChartAnalyses,
      uploadChartTemplateToChart,
      extractTemplateFromCurrentChartSettings,
      updateChartTemplate,
      editable,
      EditTemplate,
      translateNamespace,
      addChartAnalysisAsync,
      addChartTemplateErrors
    } = this.props;

    return !isEmpty(templates) && isArray(templates) ? (
      <ul className="chart-templates-list">
        {templates.map(template => (
          <TemplatesListItem
            template={template}
            key={template.id}
            removeChartTemplate={removeChartTemplate}
            fetchChartAnalyses={fetchChartAnalyses}
            fetchChartTemplateDetails={fetchChartTemplateDetails}
            uploadChartTemplateToChart={uploadChartTemplateToChart}
            extractTemplateFromCurrentChartSettings={
              extractTemplateFromCurrentChartSettings
            }
            updateChartTemplate={updateChartTemplate}
            editable={editable}
            EditTemplate={EditTemplate}
            translateNamespace={translateNamespace}
            addChartAnalysisAsync={addChartAnalysisAsync}
            addChartTemplateErrors={addChartTemplateErrors}
          />
        ))}
      </ul>
    ) : null;
  }
}

export default TemplatesList;
