import React from "react";
import PropTypes from "prop-types";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContentText from "@material-ui/core/DialogContentText";
import ModalBase from "../../ModalBase";
import SquaberButton from "../../../common/SquaberButton";
import withTranslations from "../../../../../hocs/withTranslations";

@withTranslations
class SocialLoginModal extends ModalBase {
  static propTypes = {
    cancel: PropTypes.func,
    createNewAccount: PropTypes.func,
    linkWithExistingAccount: PropTypes.func
  };

  getContent() {
    const {
      title,
      message,
      hideModal,
      cancel,
      createNewAccount,
      linkWithExistingAccount,
      translate
    } = this.props;

    return (
      <React.Fragment>
        <DialogTitle id="form-dialog-title">{translate(title)}</DialogTitle>
        <DialogContent>
          <DialogContentText>{translate(message)}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <SquaberButton
            onClick={() => {
              cancel();
              hideModal();
            }}
            color="secondary"
            style={{ marginRight: "auto" }}
          >
            {translate("Cancel")}
          </SquaberButton>
          <SquaberButton
            onClick={() => {
              createNewAccount();
              hideModal();
            }}
            color="primary"
          >
            {translate("Create new account")}
          </SquaberButton>
          <SquaberButton
            onClick={() => {
              linkWithExistingAccount();
              hideModal();
            }}
            color="primary"
          >
            {translate("Link with existing account")}
          </SquaberButton>
        </DialogActions>
      </React.Fragment>
    );
  }
}

export default SocialLoginModal;
