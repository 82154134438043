import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";

export const fetchRecommendedStocksRoutine = createRoutine(
  "app/recommendedStocks/FETCH_RECOMMENDED_STOCKS"
);

const initialState = {
  loading: false,
  error: null,
  data: {
    has_recommended: false
  }
};

export default handleActions(
  {
    [fetchRecommendedStocksRoutine.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [fetchRecommendedStocksRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: null,
      data: payload
    }),
    [fetchRecommendedStocksRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    })
  },
  initialState
);
