import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Pie } from "react-chartjs-2";
import getValueColor from "../../../../../lib/getValueColor";

import "../styles/default.scss";

const GaugeChart = ({ value, maximum, small, precision }) => (
  <div
    className={classNames({
      "gauge-chart": true,
      small
    })}
  >
    <div className="gauge-chart-inside">
      <Pie
        data={{
          datasets: [
            {
              data: [value, maximum - value],
              backgroundColor: [getValueColor(value), "#eee"],
              hoverBackgroundColor: [getValueColor(value), "#eee"],
              hoverBorderColor: ["transparent", "transparent"],
              borderWidth: [0, 0],
              hoverBorderWidth: [0, 0]
            }
          ]
        }}
        options={{
          maintainAspectRatio: false,
          circumference: Math.PI * 1.5,
          rotation: (3 / 4) * Math.PI,
          cutoutPercentage: 85, // precent
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          }
        }}
      />
      <div
        className="gauge-chart-value"
        style={{
          color: getValueColor(value)
        }}
      >
        {value || value === 0
          ? Number.isInteger(value)
            ? value
            : value.toFixed(precision)
          : "?"}
      </div>
    </div>
  </div>
);

GaugeChart.propTypes = {
  value: PropTypes.number,
  maximum: PropTypes.number,
  small: PropTypes.bool,
  precision: PropTypes.number
};

GaugeChart.defaultProps = {
  maximum: 5,
  small: false,
  precision: 2
};

export default GaugeChart;
