import React from "react";
import qs from "qs";
import { withRouter } from "react-router-dom";
import { connect } from "../i18n/connect";
import { actions } from "../redux/modules/auth/login";
import { UrlProvider } from "../api/UrlProvider";

export default function withSocialLoginHandler(WrappedComponent) {
  const mapDispatchToProps = {
    executeSocialLogin: actions.executeSocialLogin
  };

  @connect(null, mapDispatchToProps)
  @withRouter
  class WithSocialLoginHandler extends React.Component {
    componentDidMount() {
      const {
        history: { push },
        locale
      } = this.props;

      const { search } = window.location;

      let socialLoginData = qs.parse(search, { ignoreQueryPrefix: true });

      if (socialLoginData.is_native) {
        delete socialLoginData.is_native;

        window.location.href = `https://auth.expo.io/@squaber/squaber?${qs.stringify(
          socialLoginData
        )}`;

        return;
      }

      if (socialLoginData.access_token) {
        this.props.executeSocialLogin(socialLoginData);
      } else if (socialLoginData.error) {
        push(UrlProvider.getUrl("fe.login", { locale }));
      }
    }
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithSocialLoginHandler;
}
