export function getAuthorData(analysis) {
  const { first_name, last_name } = analysis.author;

  let name = "";

  if (first_name || last_name) {
    name = `${first_name} ${last_name}`.trim();
    name = `${name}, `;
  }

  return name;
}
