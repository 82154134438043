import { put, call, takeLatest, fork } from "redux-saga/effects";
import { constants, actions } from "./index";
import ApiClient from "../../../api/ApiClient";
import { actions as alertsActions } from "../alerts";

function* onTestAction() {
  const todoId = Math.round(Math.random() * 100);

  try {
    const postResponse = yield ApiClient.post({
      urlPath: "test.placeholderJson",
      variables: {
        todoId: 1
      },
      data: {
        foo: "bar"
      },
      headers: {
        HeaderName: "HeaderValue"
      }
    });
  } catch (error) {
    yield put(actions.testActionError(error.response.status));
  }

  try {
    const response = yield ApiClient.get({
      urlPath: "test.placeholderJson",
      variables: {
        todoId
      }
    });

    const { data } = response;

    yield put(actions.testActionSuccess(data));
    yield put(
      alertsActions.addSuccessAlert({
        title: "Request success",
        duration: 5000
      })
    );
  } catch (error) {
    yield put(actions.testActionError(error.response.status));
  }
}

function* watchTestAction() {
  yield takeLatest(constants.TEST_ACTION, onTestAction);
}

export default [fork(watchTestAction)];
