import { delay, fork, race, take } from "redux-saga/effects";

export const debounceWithPreservingRangeParams = (ms, pattern, task, ...args) =>
  fork(function*() {
    let fromArr = [];
    let toArr = [];

    while (true) {
      let action = yield take(pattern);

      while (true) {
        const { debounced, latestAction } = yield race({
          debounced: delay(ms),
          latestAction: take(pattern)
        });

        fromArr.push(action.payload.from);
        toArr.push(action.payload.to);

        if (debounced) {
          const from = Math.min(...fromArr);
          const to = Math.max(...toArr);
          action.payload.from = from;
          action.payload.to = to;

          yield fork(task, ...args, action);

          fromArr = [];
          toArr = [];
          break;
        }

        action = latestAction;
      }
    }
  });
