import React from "react";
import { connect } from "../i18n/connect";
import { actions } from "../redux/modules/menu";
import { getMenu } from "../redux/modules/menu/selector";

export default function withMenu(WrappedComponent) {
  const mapStateToProps = state => ({
    menu: getMenu(state)
  });

  const mapDispatchToProps = {
    fetchMenu: actions.fetchMenu
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithMenu extends React.Component {
    componentDidMount(): void {
      this.props.fetchMenu();
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithMenu;
}
