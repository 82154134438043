import { handleActions } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

export const fetchStockAnalysis = createRoutine(
  "app/stockAnalysis/FETCH_STOCK_ANALYSIS"
);

export const fetchStockPageAnalysis = createRoutine(
  "app/stockAnalysis/FETCH_STOCK_PAGE_ANALYSIS"
);

const initialState = {
  data: [],
  stockPageData: [],
  error: null,
  loading: null
};

export default handleActions(
  {
    [fetchStockAnalysis.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [fetchStockPageAnalysis.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [fetchStockAnalysis.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    [fetchStockPageAnalysis.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    [fetchStockAnalysis.SUCCESS]: (state, { payload }) => {
      const ticker = payload.ticker.toLowerCase();
      const market = payload.market.toLowerCase();
      const data = payload.data;

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          [market]: {
            ...(state.data?.[market] ?? {}),
            [ticker]: data
          }
        }
      };
    },
    [fetchStockPageAnalysis.SUCCESS]: (state, { payload }) => {
      const ticker = payload.ticker.toLowerCase();
      const market = payload.market.toLowerCase();
      const data = payload.data;
      return {
        ...state,
        loading: false,
        stockPageData: {
          [market]: {
            ...(state.data?.[market] ?? {}),
            [ticker]: data
          }
        }
      };
    }
  },
  initialState
);
