import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

interface ChangeMarketsSettingsMetaData {
  market: string;
  status: boolean;
}

class ChangeMarketsSettingsEvent extends BaseEvent {
  constructor(changeMarketsSettings: ChangeMarketsSettingsMetaData) {
    super();
    this.changeMarketsSettings = changeMarketsSettings;
  }

  getEventName() {
    return "change_markets_setting";
  }

  getEventTriggerName() {
    return "Market setting changed";
  }

  getEventDetailsMatrix(): string {
    return `detail5=market name
detail6=on/off`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: this.changeMarketsSettings.market,
      detail6: this.changeMarketsSettings.status ? "on" : "off"
    };
  }
}
export default ChangeMarketsSettingsEvent;
