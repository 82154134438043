import "../styles/default.scss";

import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import logo from "../../../../../logo/assets/squaber-icon-yellow-300-300.png";

const AppErrorStateView = ({ translate }) => (
  <div className="app-error-state-view">
    <img src={logo} alt="Squaber" />
    <p>{translate("We're very sorry. Unexpected error occurred.")}</p>
    <p>{translate("Please try again later.")}</p>
  </div>
);

export default withTranslations(AppErrorStateView);
