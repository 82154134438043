const colorArray = [
  "#262B3E",
  "#A8B1C1",
  "#EBF2F8",
  "#6FA6CD",
  "#F1C12C",
  "#F03E3E",
  "#12B886",
  "#cd906f",
  "#b16fcd",
  "#81b812",
  "#4c12b8",
  "#b82e12",
  "#3ef03e",
  "#b85712",
  "#b8aa12",
  "#9c12b8",
  "#2eb812",
  "#f0973e",
  "#3e3ef0",
  "#cdbf6f"
];

export default colorArray;
