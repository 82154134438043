import React from "react";
import { fetchConfigPageRoutine } from "../redux/modules/configPage";
import { getConfigPageState } from "../redux/modules/configPage/selector";
import connect from "../i18n/connect";

export default function withConfigPage(type) {
  return function(WrappedComponent) {
    const mapStateToProps = state => ({
      configPageState: getConfigPageState(state)
    });

    const mapDispatchToProps = {
      fetchConfigPage: fetchConfigPageRoutine
    };

    @connect(mapStateToProps, mapDispatchToProps)
    class WithConfigPage extends React.Component {
      componentDidMount(): void {
        this.props.fetchConfigPage(type);
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return WithConfigPage;
  };
}
