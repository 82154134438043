import iconWithTextWebLogo from "../assets/logo-squaber-134-40.png";
import iconWithTextWebPremiumLogo from "../assets/logo-squaber-134-40-premium.png";
import iconWithTextLogo from "../assets/logo-squaber-134-40-transparent.png";
import mainLogo from "../assets/squaber-icon-yellow-300-300.png";
import textLogo from "../assets/squaber-logo-text.png";

export default {
  main: mainLogo,
  text: textLogo,
  iconWithText: iconWithTextLogo,
  iconWithTextWeb: iconWithTextWebLogo,
  iconWithTextWebPremium: iconWithTextWebPremiumLogo
};
