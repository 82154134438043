import { createAction, handleActions } from "redux-actions";

export const INCREMENT_COUNTER = "app/browsedPagesCount/INCREMENT_COUNTER";
export const incrementCounter = createAction(INCREMENT_COUNTER, () => ({}));

export const SET_MODAL_SHOWING_LOCKED =
  "app/browsedPagesCount/SET_MODAL_SHOWING_LOCKED";
export const setModalShowingLocked = createAction(
  SET_MODAL_SHOWING_LOCKED,
  (value: boolean) => ({ value })
);

export const SET_LAST_MODAL_WAS_SHOWN_AT =
  "app/browsedPagesCount/SET_LAST_MODAL_WAS_SHOWN_AT";
export const setLastModalWasShownAt = createAction(
  SET_LAST_MODAL_WAS_SHOWN_AT,
  value => ({ value })
);

export const initialState = {
  count: 0,
  modalShowingLocked: false,
  lastModalWasShownAt: null
};

export default handleActions(
  {
    [INCREMENT_COUNTER]: state => ({
      ...state,
      count: state.count + 1
    }),
    [SET_LAST_MODAL_WAS_SHOWN_AT]: (state, { payload }) => ({
      ...state,
      modalShowingLocked: true,
      lastModalWasShownAt: payload.value
    })
  },
  initialState
);
