import React from "react";

import useMobileMediaQuery from "../web/hooks/useMobileMediaQuery";

function withMobileMediaQuery(WrappedComponent) {
  return props => {
    const isMobileMediaQuery = useMobileMediaQuery();

    return (
      <WrappedComponent isMobileMediaQuery={isMobileMediaQuery} {...props} />
    );
  };
}

export default withMobileMediaQuery;
