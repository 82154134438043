import React from "react";
import autoBind from "auto-bind";

export default class PortfolioChartAbstract extends React.Component {
  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  fetchChartDataInterval = null;

  state = {
    currentChart: "rate_of_return_chart"
  };

  getChartData() {
    const { getPortfolioChart, portfolioId, portfolioStatus } = this.props;
    const { currentChart } = this.state;

    this.fetchChartDataInterval = setInterval(() => {
      if (portfolioStatus === 3 || portfolioStatus === 4) {
        clearInterval(this.fetchChartDataInterval);
        getPortfolioChart({
          portfolioId,
          type: currentChart
        });
      }
    }, 500);
  }

  setChartType(name) {
    const { currentChart } = this.state;

    if (currentChart !== name) {
      this.setState(
        {
          currentChart: name
        },
        () => {
          this.getChartData();
        }
      );
    }
  }
}
