import "../styles/default.scss";

import React, { useState } from "react";

import withUserDataGetter from "../../../../../hocs/withUserDataGetter";
import MediaMonitorHelper from "../../../../../lib/MediaMonitor/MediaMonitorHelper";
import { getIsMobile } from "../../../../../lib/platformHelper";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import NoAccessDisclaimer from "../../NoAccessDisclaimer";
import SquaberLink from "../../SquaberLink";

const MediaMonitorEntryContent = ({
  title,
  link,
  description,
  translate,
  provider,
  attachments,
  is_premium,
  userHasAccess,
  userIsNotLoggedIn
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const linkToUse = link || "#!";
  let userHasAccessToContent = true;

  if (is_premium) {
    userHasAccessToContent = userHasAccess;
  }

  const helper = new MediaMonitorHelper(
    description,
    provider,
    translate,
    attachments
  );

  return (
    <React.Fragment>
      <span>{title}</span> [
      <SquaberLink
        to={linkToUse}
        target={link ? "_blank" : null}
        onClick={() => {
          if (userHasAccessToContent) {
            if (getIsMobile()) {
              showModal("InterestingStockDescription", {
                title,
                content: helper.formatMediaMonitorEntry()
              });
            } else {
              if (!link && (description || !userHasAccessToContent)) {
                setIsExpanded(!isExpanded);
              }
            }
          } else {
            if (userIsNotLoggedIn) {
              showModal("RegisterModal");
            } else {
              showModal("PremiumModal", {
                name: "open media monitor entry content attempt"
              });
            }
          }
        }}
      >
        {translate("More")}
      </SquaberLink>
      ]
      {isExpanded ? (
        <React.Fragment>
          {userHasAccessToContent ? (
            <div
              className="media-monitor-entry-description"
              dangerouslySetInnerHTML={{
                __html: helper.formatMediaMonitorEntry()
              }}
            />
          ) : (
            <NoAccessDisclaimer />
          )}
        </React.Fragment>
      ) : null}
    </React.Fragment>
  );
};

MediaMonitorEntryContent.propTypes = {};

export default withUserDataGetter(MediaMonitorEntryContent);
