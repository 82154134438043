import React from "react";
import { connect } from "react-redux";

import { getStockRoutine } from "../redux/modules/stockPageTvChart";
import {
  getStockData,
  getStockError,
  getStockLoading
} from "../redux/modules/stockPageTvChart/selector";

export default function withStockPageTVChart(WrappedComponent) {
  const mapStateToProps = state => ({
    stock: getStockData(state),
    stockError: getStockError(state),
    stockLoading: getStockLoading(state)
  });

  const mapDispatchToProps = {
    fetchStock: getStockRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithStockPageTVChart extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithStockPageTVChart;
}
