import React, { useContext } from "react";
import UniversalTableContext from "../../../../../../lib/UniversalTable/UniversalTableContext";
import ApiHelper from "../../../../../../lib/UniversalTable/CoreHelpers/ApiHelper";

import "./SettingsButton.scss";

const SettingsButton = ({ settingsVisible, showSettings, hideSettings }) => {
  const universalTableContext = useContext(UniversalTableContext);
  const columns = universalTableContext.getColumns();

  const searchableColumnsPresent =
    columns.filter(column => ApiHelper.getIsSearchable(column)).length > 0;

  if (!searchableColumnsPresent) {
    return null;
  }

  return (
    <div className="settings-control">
      <div
        className="settings-button"
        onClick={() => {
          if (!settingsVisible) {
            showSettings();
          } else {
            hideSettings();
          }
        }}
      >
        <i className="fas fa-filter" />
      </div>
    </div>
  );
};

export default SettingsButton;
