import { createAction, handleActions } from "redux-actions";

const SHOW = "app/actionInProgress/SHOW";
const HIDE = "app/actionInProgress/HIDE";

export const show = createAction(SHOW, (description: String) => ({
  description
}));

export const hide = createAction(HIDE, () => ({}));

export const actions = {
  show,
  hide
};

const initialState = {
  visible: false,
  description: ""
};

export default handleActions(
  {
    [SHOW]: (state, { payload }) => ({
      visible: true,
      description: payload.description
    }),
    [HIDE]: state => ({
      ...state,
      visible: false
    })
  },
  initialState
);
