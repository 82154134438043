import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import withTranslations from "../../../../../hocs/withTranslations";
import SquaberButton from "../../SquaberButton";
import SquaberTooltip from "../../SquaberTooltip";

@withTranslations
class TVChartDrawnObjectsOptions extends React.Component {
  state = {
    currentDrawnObjectsOptions: []
  };

  componentDidMount(): void {
    const { drawnObjectsOptions } = this.props;

    this.setState({
      currentDrawnObjectsOptions: [...drawnObjectsOptions]
    });
  }

  handleChange(option, value) {
    const { currentDrawnObjectsOptions } = this.state;

    const newOptions = JSON.parse(JSON.stringify(currentDrawnObjectsOptions));

    newOptions.find(
      currentOption => currentOption.key === option.key
    ).value = value;

    this.setState({ currentDrawnObjectsOptions: newOptions });
  }

  getOption({ option }) {
    const { translate } = this.props;

    const checkbox = (
      <FormControlLabel
        className={"checkbox-wrapper"}
        classes={{
          label: "checkbox-label-wrapper"
        }}
        disabled={option?.disabled}
        control={
          <Checkbox
            checked={option.value}
            onChange={(event, value) => {
              this.handleChange(option, value);
            }}
          />
        }
        label={
          <span className={"checkbox-label"}>
            <span>{translate(option.label)}</span>
          </span>
        }
      />
    );

    return (
      <div key={option.key}>
        {option?.disabled && !!option?.disabledMessage ? (
          <SquaberTooltip title={option.disabledMessage}>
            {checkbox}
          </SquaberTooltip>
        ) : (
          checkbox
        )}
      </div>
    );
  }

  render() {
    const { translate } = this.props;
    const { currentDrawnObjectsOptions } = this.state;

    return (
      <div className="tv-chart-options-window">
        <div className="options-wrapper">
          {currentDrawnObjectsOptions.map(option => this.getOption({ option }))}
        </div>
        <div className="actions-wrapper">
          <SquaberButton
            color="secondary"
            onClick={() => {
              this.props.close();
            }}
          >
            {translate("Cancel")}
          </SquaberButton>
          <SquaberButton
            onClick={() => {
              this.props.updateDrawnObjectsOptions(
                this.state.currentDrawnObjectsOptions
              );
              this.props.close();
            }}
          >
            {translate("Apply")}
          </SquaberButton>
        </div>
      </div>
    );
  }
}

export default TVChartDrawnObjectsOptions;
