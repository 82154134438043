import autoBind from "auto-bind";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router";
import Notification from "react-web-notification";
import { compose } from "redux";
import { isEqual } from "lodash";

import notificationSound from "../../../../../assets/sounds/alert_notification.mp3";
import withAnalysisCategories from "../../../../../hocs/withAnalysisCategories";
import withManualStrategies from "../../../../../hocs/withManualStrategies";
import withTranslations from "../../../../../hocs/withTranslations";
import logo from "../../../../../logo";
import { notificationItemFactory } from "../../../navigation/NotificationItem/notificationItemFactory";

class BrowserNotification extends React.Component {
  constructor(props) {
    super(props);

    autoBind.react(this);

    this.audio = new Audio(notificationSound);

    this.state = {
      ignore: true,
      timeout: 5000,
      data: {
        title: "",
        body: "",
        callback: () => {}
      },
      options: {
        tag: Date.now(),
        body: "",
        icon: logo.main,
        lang: props.locale,
        dir: "ltr"
      }
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.data !== state.data) {
      return {
        data: props.data
      };
    }

    return state;
  }

  handlePermissionGranted() {
    this.setState({
      ignore: false
    });
  }

  handlePermissionDenied() {
    this.setState({
      ignore: true
    });
  }

  handleNotSupported() {
    console.log("Web Notification not Supported");

    this.setState({
      ignore: true
    });
  }

  async handleNotificationOnClick() {
    const { analysisCategoriesLoading, manualStrategies } = this.props;

    setTimeout(async () => {
      const canContinue =
        analysisCategoriesLoading === false &&
        manualStrategies.loading === false;

      if (!canContinue) {
        await this.handleNotificationOnClick();
        return;
      }

      await this.handleNotification();
    }, 100);
  }

  async handleNotification() {
    const {
      locale,
      analysisCategories,
      manualStrategies,
      history
    } = this.props;

    const notificationMetaData = this.state.data?.notificationMetaData;

    if (notificationMetaData) {
      const {
        config: { redirect_to }
      } = notificationMetaData;

      if (redirect_to) {
        window.open(redirect_to, "_blank");
      }

      const { action, url, redirectTo } =
        notificationItemFactory({
          item: notificationMetaData,
          locale,
          analysisCategories,
          manualStrategies
        }) ?? {};

      if (url) {
        window.open(url, "_blank");
      }

      if (redirectTo) {
        window.open(url, "_blank");
      }

      if (action) {
        await action();
      }
    }
    if (this.state.data.callback) {
      this.state.data.callback();
    }
  }

  handleNotificationOnError(e, tag) {}

  handleNotificationOnClose(e, tag) {}

  handleNotificationOnShow(e, tag) {
    this.audio.play().catch(error => {
      console.log(error);
    });
  }

  shouldComponentUpdate(
    nextProps: Readonly<P>,
    nextState: Readonly<S>,
    nextContext: any
  ): boolean {
    return !isEqual(this.state.data, nextState.data);
  }

  render() {
    return (
      <Notification
        ignore={this.state.ignore && this.state.title}
        notSupported={this.handleNotSupported}
        onPermissionGranted={this.handlePermissionGranted}
        onPermissionDenied={this.handlePermissionDenied}
        onShow={this.handleNotificationOnShow}
        onClick={this.handleNotificationOnClick}
        onClose={this.handleNotificationOnClose}
        onError={this.handleNotificationOnError}
        timeout={this.state.timeout}
        title={this.state.data.title}
        options={{
          ...this.state.options,
          body: this.state.data.body
        }}
      />
    );
  }
}

BrowserNotification.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    body: PropTypes.string
  })
};

export default compose(
  withTranslations,
  withAnalysisCategories,
  withManualStrategies,
  withRouter
)(BrowserNotification);
