import React from "react";
import { connect } from "../i18n/connect";
import withFormBuilder from "./withFormBuilder";
import { actions as authActions } from "../redux/modules/auth/login";
import { getValidationErrors } from "../redux/modules/auth/login/selector";

export default function withEditUserPassword(WrappedComponent) {
  const mapStateToProps = state => ({
    validationErrors: getValidationErrors(state)
  });

  const mapDispatchToProps = {
    editUserPassword: authActions.editUserPassword,
    clearValidationError: authActions.clearValidationError
  };

  const fields = [
    {
      name: "password_old",
      label: "Old password",
      type: "password",
      fullWidth: true,
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "password",
      label: "New password",
      type: "password",
      fullWidth: true,
      required: true,
      validationErrors: {
        isDefaultRequiredValue: "This field is required"
      }
    },
    {
      name: "password_confirm",
      label: "Confirm password",
      type: "password",
      fullWidth: true,
      required: true,
      validations: {
        equalsField: "password"
      },
      validationErrors: {
        equalsField: "Your password doesn't match",
        isDefaultRequiredValue: "This field is required"
      }
    }
  ];

  @connect(mapStateToProps, mapDispatchToProps)
  @withFormBuilder(fields)
  class WithEditUserPassword extends React.Component {
    state = {};

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithEditUserPassword;
}
