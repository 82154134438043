import React from "react";
import Formsy from "formsy-react";
import { compose } from "redux";
import autoBind from "auto-bind";
import SweetScroll from "sweet-scroll";
import classNames from "classnames";
import SquaberTable from "../../SquaberTable";
import PortfolioAddTransaction from "../../PortfolioAddTransaction";
import withPortfolioTransactionHistoryTable from "../../../../../hocs/withPortfolioTransactionHistoryTable";
import withPortfolioAddStockTransaction from "../../../../../hocs/withPortfolioAddStockTransaction";

import "../../PortfolioStocksTable/styles/default.scss";
import "../../PortfolioAddCashTransactionWrapper/styles/default.scss";

class PortfolioAddStockTransactionWrapper extends React.Component {
  constructor(props) {
    super(props);

    const { defaultStockData } = props;

    let initialState = {
      stockData: null,
      autocompleteValue: "",
      autocompleteBlurred: false
    };

    if (defaultStockData) {
      const stockData = {
        stock: {
          id: defaultStockData.id
        },
        market: defaultStockData.market,
        ticker: defaultStockData.ticker
      };

      initialState = {
        stockData,
        autocompleteValue: `${defaultStockData.ticker} - ${defaultStockData.name}`,
        autocompleteBlurred: true
      };

      props.formBuilder.handleChange(
        {
          name: "price"
        },
        String(Number(defaultStockData.quote.close).toFixed(4))
      );

      setTimeout(() => {
        localStorage.removeItem("stockToAddToPortfolio");
      }, 5000);
    }

    this.state = initialState;

    autoBind.react(this);
  }

  componentDidMount(): void {
    const { defaultStockData } = this.props;

    if (defaultStockData) {
      this.scroller = SweetScroll.create({
        duration: 500,
        easing: "easeOutExpo"
      });

      this.scroller.toElement(this.formWrapperElement, {
        offset: -150
      });
    }
  }

  setAutocompleteBlurred(value) {
    this.setState({
      autocompleteBlurred: value
    });
  }

  setAutocompleteValue(value) {
    const { formBuilder } = this.props;
    const { autocompleteValue, stockData, price } = value;

    if (typeof price !== "undefined") {
      formBuilder.handleChange(
        {
          name: "price"
        },
        String(Number(price).toFixed(4))
      );
    }

    this.setState({
      autocompleteValue,
      stockData
    });
  }

  render() {
    const {
      translate,
      addTransaction,
      formBuilder,
      clearValidationError,
      calculateCommission,
      setShowAddStockTransaction,
      portfolioData
    } = this.props;

    let {
      addPortfolioStockTransactionState: { loading, error: validationErrors }
    } = this.props;

    const { stockData, autocompleteValue, autocompleteBlurred } = this.state;

    if (autocompleteBlurred && !stockData) {
      validationErrors = {
        ...validationErrors,
        ticker: translate("You have to select stock to continue")
      };
    }

    return (
      <div
        className={classNames({
          "portfolio-add-cash-transactions": true,
          "portfolio-add-stock-transactions": true,
          "scrollable-table-wrapper": true,
          "is-mobile-page-sheet": true
        })}
        ref={element => {
          this.formWrapperElement = element;
        }}
      >
        <h2>{translate("Add stock transaction")}</h2>
        <div
          className="close-page-sheet"
          onClick={() => {
            setShowAddStockTransaction(false);
          }}
        >
          <i className="fal fa-times" />
        </div>
        <Formsy
          onValidSubmit={() => {
            addTransaction({ formBuilder, stockData });
          }}
          validationErrors={validationErrors}
        >
          <SquaberTable
            className="portfolio-stocks-table"
            heading={[
              translate("Ticker"),
              translate("Transaction type"),
              translate("Date"),
              translate("Price"),
              translate("Stocks count"),
              translate("Commission"),
              ""
            ]}
            rows={[
              <PortfolioAddTransaction
                formBuilder={formBuilder}
                loading={loading}
                withTickerSelector
                stockData={stockData}
                autocompleteValue={autocompleteValue}
                setAutocompleteValue={this.setAutocompleteValue}
                setAutocompleteBlurred={this.setAutocompleteBlurred}
                calculateCommission={calculateCommission}
                clearValidationError={clearValidationError}
                validationErrors={validationErrors}
                portfolioData={portfolioData}
                key="add-cash-transaction"
              />
            ]}
          />
        </Formsy>
      </div>
    );
  }
}

export default compose(
  withPortfolioTransactionHistoryTable,
  withPortfolioAddStockTransaction
)(PortfolioAddStockTransactionWrapper);
