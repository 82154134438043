import React from "react";
import ModalBase from "../../ModalBase";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

import "../styles/default.scss";

class PicturePresentationModal extends ModalBase {
  getClassName(): null {
    return "squaber-picture-presentation-modal";
  }

  getContent() {
    const { hideModal, image, alt } = this.props;

    return (
      <React.Fragment>
        <DialogTitle id="form-dialog-title">
          <span className="close-dialog" onClick={() => hideModal()}>
            <i className="fal fa-times" />
          </span>
        </DialogTitle>
        <DialogContent>
          <img src={image} alt={alt} />
        </DialogContent>
      </React.Fragment>
    );
  }
}

PicturePresentationModal.propTypes = {};

export default PicturePresentationModal;
