import { createRoutine } from "redux-saga-routines";
import { createAction, handleActions } from "redux-actions";

export const GET_AFFILIATE_DETAILS =
  "app/affiliateProgram/GET_AFFILIATE_DETAILS";
export const GET_AFFILIATE_HISTORY =
  "app/affiliateProgram/GET_AFFILIATE_HISTORY";
export const AFFILIATE = "app/affiliateProgram/AFFILIATE";
export const SET_AFFILIATE_COOKIE = "app/affiliateProgram/SET_AFFILIATE_COOKIE";
export const ACCEPT_REGULATIONS = "app/affiliateProgram/ACCEPT_REGULATIONS";

export const getAffiliateDetails = createRoutine(GET_AFFILIATE_DETAILS);
export const getAffiliateHistory = createRoutine(GET_AFFILIATE_HISTORY);
export const acceptRegulations = createRoutine(ACCEPT_REGULATIONS);

export const affiliate = createAction(AFFILIATE);
export const setAffiliateCookie = createAction(SET_AFFILIATE_COOKIE, token => ({
  token
}));

const initialState = {
  detailsLoading: false,
  historyLoading: false,
  data: null,
  error: null
};

export default handleActions(
  {
    [getAffiliateDetails.TRIGGER]: state => ({
      ...state,
      detailsLoading: true,
      error: null
    }),
    [getAffiliateDetails.SUCCESS]: (state, { payload }) => ({
      ...state,
      detailsLoading: false,
      data: {
        ...state.data,
        details: payload
      }
    }),
    [getAffiliateDetails.FAILURE]: (state, { payload }) => ({
      ...state,
      detailsLoading: false,
      error: payload
    }),
    [getAffiliateHistory.TRIGGER]: state => ({
      ...state,
      historyLoading: true,
      error: null
    }),
    [getAffiliateHistory.SUCCESS]: (state, { payload }) => ({
      ...state,
      historyLoading: false,
      data: {
        ...state.data,
        history: payload
      }
    }),
    [getAffiliateHistory.FAILURE]: (state, { payload }) => ({
      ...state,
      historyLoading: false,
      error: payload
    })
  },
  initialState
);
