export const getAffiliateProgramDetails = state => {
  if (state.affiliateProgram.detailsLoading) return null;

  const details = state.affiliateProgram?.data?.details;
  if (typeof details === "undefined") return null;

  return details;
};

export const getAffiliateProgramPoints = state => {
  const { amount_of_points = 0 } = getAffiliateProgramDetails(state) || {};

  return amount_of_points;
};

export const getAffiliateProgramHistory = state => {
  if (state.affiliateProgram.historyLoading) return null;

  const history = state.affiliateProgram?.data?.history;
  if (typeof history === "undefined") return null;

  return history;
};
export const getAffiliateProgramHistoryLoading = state => {
  return state.affiliateProgram.historyLoading;
};
export const getAffiliateProgramDetailsLoading = state => {
  return state.affiliateProgram.detailsLoading;
};
