import React from "react";

import withDeleteTransaction from "../../../../../../hocs/withDeleteTransaction";

const DeletePortfolioTransaction = ({ resultMetaData, deleteTransaction }) => {
  return (
    <div className="delete-portfolio-transaction">
      <i
        className="far fa-trash-alt delete-transaction"
        onClick={() => {
          const {
            portfolioId
          } = resultMetaData.action_delete_portfolio_transaction;
          deleteTransaction({ portfolioId, transaction: resultMetaData });
        }}
      />
    </div>
  );
};

export default withDeleteTransaction(DeletePortfolioTransaction);
