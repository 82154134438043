/**
 *
 * @param {{notifications_unique_id: string, name: string, notifications_enabled_by_default?: boolean}[]} settingsCategories
 */
const toAdditionalSettingsCategories = ({ settingsCategories }) => {
  return settingsCategories.map(category => {
    return {
      position:
        category.notifications_unique_id ===
        "stock_analysis_category_technical_analysis"
          ? -1
          : 0,
      key: category.notifications_unique_id,
      name: category.name,
      notificationsEnabledByDefault:
        category?.notifications_enabled_by_default ?? true
    };
  });
};

export default toAdditionalSettingsCategories;
