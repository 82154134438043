import React, { useMemo } from "react";

import connect from "../../../../../../i18n/connect";
import Option from "./option";

const PlanOptions = ({ count = 50, translate, translationExists }) => {
  return useMemo(() => {
    const options = [];

    for (let i = 1; i <= count; i++) {
      const title = `lp_plan-table_row-${i}_title`;
      const translatedTitle = translate(title);

      if (!translationExists(title) || translatedTitle === "") {
        continue;
      }

      options.push(
        <tr key={translatedTitle}>
          <td
            className={"w-2/4"}
            dangerouslySetInnerHTML={{ __html: translatedTitle }}
          />
          <td className={"w-1/4"}>
            <Option value={translate(`lp_plan-table_row-${i}_freemium`)} />
          </td>
          <td className={"w-1/4"}>
            <Option value={translate(`lp_plan-table_row-${i}_premium`)} />
          </td>
        </tr>
      );
    }

    return options;
  }, [count]);
};

export default connect()(PlanOptions);
