import { createAction, handleActions } from "redux-actions";

export const SET_HEIGHT = `app/navbarHeight/SET_HEIGHT`;

export const setHeight = createAction(SET_HEIGHT);

const initialState = {
  height: null
};

export default handleActions(
  {
    [setHeight]: (state, { payload }) => ({
      ...state,
      height: payload
    })
  },
  initialState
);
