import React from "react";
import classNames from "classnames";
import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormsySelect from "./FormsyMaterialUI/FormsySelect";
import FormsyCheckbox from "./FormsyMaterialUI/FormsyCheckbox";
import FormsyRadioGroup from "./FormsyMaterialUI/FormsyRadioGroup";
import FormsyComposedTextField from "./FormsyMaterialUI/FormsyComposedTextField";
import FormBuilderCore from "./Core/FormBuilderCore";
import FormsyDatePicker from "./FormsyMaterialUI/FormsyDatePicker";
import { Text } from "native-base";
import "./FormBuilderWeb.scss";
class FormBuilderWeb extends FormBuilderCore {
  getField(field, key, fields) {
    const { name, label, value, type, options, selected, ...rest } = field;

    const validationProps = this.getValidationProps(field);

    switch (type) {
      case "select":
        return (
          <div key={key}>
            <FormsySelect
              name={name}
              label={label}
              value={value}
              options={options}
              {...validationProps}
              onChange={value => this.handleChange(field, value)}
              {...rest}
            />
          </div>
        );

      case "checkbox":
        return (
          <div key={key}>
            {options.map(({ label: optionLabel, value, ...option }, index) => (
              <FormsyCheckbox
                key={index}
                label={optionLabel}
                name={optionLabel}
                value={selected.indexOf(value) !== -1}
                {...option}
                {...validationProps}
                onChange={() => this.handleCheckboxChange(field, value)}
                {...rest}
              />
            ))}
          </div>
        );

      case "checkbox-group":
        const { fields: controlledFields } = rest;

        let isActive = this.getIsCheckboxGroupActive(controlledFields, fields);

        return (
          <div key={key}>
            <FormsyCheckbox
              label={label}
              name={name}
              value={isActive}
              {...validationProps}
              onChange={() =>
                this.handleGroupCheckboxChange(
                  controlledFields,
                  fields,
                  !isActive
                )
              }
              {...rest}
            />
          </div>
        );

      case "radio":
        return (
          <div key={key}>
            <FormsyRadioGroup
              aria-label={label}
              name={name}
              value={value}
              {...validationProps}
              onChange={value => this.handleChange(field, value)}
              {...rest}
            >
              {options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  value={option.value}
                  control={<Radio />}
                  label={option.label}
                />
              ))}
            </FormsyRadioGroup>
          </div>
        );

      case "date":
        return (
          <div key={key}>
            <FormsyDatePicker
              id={name}
              name={name}
              value={value}
              label={label}
              {...validationProps}
              onChange={value => this.handleChange(field, value)}
              {...rest}
            />
          </div>
        );

      case "password":
      case "email":
      case "text":
      default:
        return (
          <div key={key}>
            <FormsyComposedTextField
              type={type}
              id={name}
              name={name}
              value={value}
              label={label}
              {...validationProps}
              onChange={value => this.handleChange(field, value)}
              {...rest}
            />
          </div>
        );
    }
  }

  render() {
    const { fields, style, className, validationErrors } = this.props;

    const nonFieldErrors = validationErrors?.non_field_errors || validationErrors?.__all__;

    return (
      <div
        className={classNames({
          "form-builder": true,
          [className]: !!className
        })}
        style={style}
      >
        {fields
          ? fields.map((field, index) => this.getField(field, index, fields))
          : "Missing `fields` prop"}
        {nonFieldErrors ? (
          <p className={"error-message"}>{nonFieldErrors}</p>
        ) : null}
      </div>
    );
  }
}

export default FormBuilderWeb;
