import React from "react";
import classNames from "classnames";
import "../styles/DesktopViewTypeSelect.scss";

class DesktopViewTypeSelect extends React.Component {
  onChange(value) {
    this.props.onChange(value);
  }

  render() {
    const { options, value } = this.props;

    const viewTypeIcons = {
      grid: "fas fa-th",
      table: "fas fa-bars"
    };

    return (
      <div className="desktop-view-type-select">
        {options.map(option => (
          <div
            key={option}
            className={classNames({
              "desktop-view-type-select__option": true,
              "is-active": option === value
            })}
            onClick={() => {
              this.onChange(option);
            }}
          >
            <i className={viewTypeIcons[option]} />
          </div>
        ))}
      </div>
    );
  }
}

export default DesktopViewTypeSelect;
