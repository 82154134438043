import React from "react";
import connect from "../i18n/connect";
import { getNavbarHeight } from "../redux/modules/navbarHeight/selector";
import { setHeight } from "../redux/modules/navbarHeight";

export default function withNavbarHeight(WrappedComponent) {
  const mapStateToProps = state => ({
    navbarHeight: getNavbarHeight(state)
  });

  const mapDispatchToProps = {
    setNavbarHeight: setHeight
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithNavbarHeight extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithNavbarHeight;
}
