import { put, takeLatest, fork } from "redux-saga/effects";
import { fetchStockAnalysis, fetchStockPageAnalysis } from "./index";
import ApiClient from "../../../../api/ApiClient";

function* onFetchStockAnalysis({ payload: { limit, ticker, market } }) {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.stocks.stockAnalysisFiltered",
      variables: {
        limit,
        ticker,
        market
      }
    });

    yield put(fetchStockAnalysis.success({ ticker, market, data }));
  } catch (e) {
    console.error(e);
    yield put(fetchStockAnalysis.failure(e));
  }
}

function* onFetchStockPageAnalysis({ payload: { limit, ticker, market } }) {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.stocks.stockPageAnalysisFiltered",
      variables: {
        limit,
        ticker,
        market
      }
    });
    yield put(fetchStockPageAnalysis.success({ ticker, market, data }));
  } catch (e) {
    console.error(e);
    yield put(fetchStockPageAnalysis.failure(e));
  }
}

function* watchStockAnalysis() {
  yield takeLatest(fetchStockAnalysis.TRIGGER, onFetchStockAnalysis);
  yield takeLatest(fetchStockPageAnalysis.TRIGGER, onFetchStockPageAnalysis);
}

export default [fork(watchStockAnalysis)];
