import filterOutExcludedColumns from "../CoreHelpers/filterOutExcludedColumns";
import DataInterceptorBase from "../DataInterceptorBase";
import { UniversalTableQuery } from "../UniversalTableQueryBuilder";

export type FetchConfigParams = {
  urlPath?: string,
  urlPathWithSettings?: string,
  excludedColumnsByAttributeName?: string[]
};
export type FetchTabWithOptionsParams = { urlPath?: string };

export default class DefaultDataInterceptor extends DataInterceptorBase {
  async fetchConfig(id, defaultSettings, params: FetchConfigParams) {
    let settings = null;

    if (defaultSettings) {
      settings = new UniversalTableQuery()
        .addFilters(defaultSettings.filters, [])
        .toString();
    }

    const config = await this.makeRequest({
      urlPath: settings
        ? params?.urlPathWithSettings ?? "app.csvData.configWithSettings"
        : params?.urlPath ?? "app.csvData.config",
      variables: {
        id,
        settings
      }
    });

    filterOutExcludedColumns(config, params?.excludedColumnsByAttributeName);

    return config;
  }

  async fetchTabWithOptions(
    id,
    currentTabId,
    tableState,
    params: FetchTabWithOptionsParams
  ) {
    this.queryBuilder.setTableState(tableState);

    return await this.makeRequest({
      urlPath: params?.urlPath ?? "app.csvData.tabWithOptions",
      variables: {
        id,
        tabId: currentTabId,
        options: this.queryBuilder.getQueryParams()
      }
    });
  }

  async fetchNextPage(url) {
    return await this.makeRequest({ url });
  }
}
