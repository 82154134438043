import Formsy from "formsy-react";
import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import FormsyComposedTextField from "../../../../../lib/FormBuilder/FormsyMaterialUI/FormsyComposedTextField";
import SquaberButton from "../../SquaberButton";

@withTranslations
class AddAnalysis extends React.Component {
  componentWillUnmount() {
    this.props.clearErrorMessages();
  }

  render() {
    const {
      toggleMode,
      addChartTemplateAsync,
      addChartTemplateErrors,
      extractTemplateFromCurrentChartSettings,
      translate
    } = this.props;

    return (
      <div className="add-template">
        <Formsy
          onValidSubmit={({ name, description }) => {
            extractTemplateFromCurrentChartSettings(chartData => {
              addChartTemplateAsync({
                name: name,
                description: description,
                settings: chartData
              })
                .then(() => {
                  toggleMode();
                })
                .catch(() => {});
            }, true);
          }}
        >
          <FormsyComposedTextField
            name="name"
            label={translate("Analysis name")}
            fullWidth
            required
            maxLength={50}
            validationErrors={{
              isDefaultRequiredValue: "This field is required"
            }}
          />
          <FormsyComposedTextField
            name="description"
            label={translate("Analysis description")}
            fullWidth
            maxLength={50}
          />
          {!!addChartTemplateErrors ? (
            <p className="form-error-message">
              {translate(addChartTemplateErrors.validation[0].value)}
            </p>
          ) : null}
          <div className="actions-wrapper">
            <SquaberButton
              color="secondary"
              type="button"
              onClick={() => {
                toggleMode();
              }}
            >
              {translate("Cancel")}
            </SquaberButton>
            <SquaberButton type="submit">
              {translate("Add analysis")}
            </SquaberButton>
          </div>
        </Formsy>
      </div>
    );
  }
}

export default AddAnalysis;
