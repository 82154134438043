import React from "react";
import classNames from "classnames";

import "./Tabs.scss";

export default function Tabs({ tabs, currentTabId, setTab }) {
  return (
    <div className="universal-table-tabs">
      {tabs.map(tab => (
        <div
          className={classNames({
            "universal-table-tab": true,
            active: currentTabId === tab.id
          })}
          key={tab.id}
          onClick={() => {
            setTab(tab.id);
          }}
        >
          {tab.name}
        </div>
      ))}
    </div>
  );
}
