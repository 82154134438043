import React from "react";
import UniversalTableLiteWeb from "../../UniversalTableLiteWeb";
import ResourceResolver from "../../../../../lib/UniversalTable/ResourceResolver";

import "../styles/DesktopStocksTable.scss";

class DesktopStocksTable extends React.Component {
  render() {
    const { stocks } = this.props;

    const stockIds =
      typeof stocks[0] === "number" ? stocks : stocks.map(stock => stock.id);

    return (
      <div className="desktop-stocks-table">
        <UniversalTableLiteWeb
          resourceId={ResourceResolver["stocks_list"]}
          defaultSettings={{
            filters: {
              stock_id: stockIds
            }
          }}
        />
      </div>
    );
  }
}

export default DesktopStocksTable;
