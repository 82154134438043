import responseStatus from "../../../../../lib/api/responseStatus";

const unsubscribeMarketingEmail = async (apiClient, { userId, hash }) => {
  try {
    const response = await apiClient.put({
      urlPath: "app.auth.unsubscribe.marketingEmail",
      variables: {
        userId: userId,
        hash: hash
      }
    });

    const status = response?.message ? responseStatus.OK : responseStatus.ERROR;

    return { status };
  } catch (e) {
    return { status: responseStatus.ERROR };
  }
};

export default unsubscribeMarketingEmail;
