import React from "react";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import {
  getIsAndroidBrowser,
  getIsIosBrowser
} from "../../../../../lib/platformHelper";
import SquaberButton from "../../SquaberButton/modules/default";
import config from "../../../../../config";
import appLogo from "../../../../../images/app_icon_squaber_ios_new.png";
import AnalyticsEventTracker from "../../../../../lib/AnalyticsEventTracker";
import "../styles/default.scss";

const AppInstallationCTA = ({ translate, bottomNavigationValue }) => {
  const isIos = getIsIosBrowser();
  const isAndroid = getIsAndroidBrowser();

  if (window.ReactNativeWebView || (!isIos && !isAndroid)) {
    return null;
  }

  if (bottomNavigationValue && bottomNavigationValue === "search") {
    return null;
  }

  const storeName = isIos ? "App Store" : "Google Play";
  const appLink = isIos ? config.appStoreUrl : config.googlePlayUrl;

  return (
    <div className="app-installation-cta">
      <img className="app-logo" src={appLogo} alt="Squaber" />
      <div className="app-name">
        <span className="title">{translate("Squaber app")}</span>
        <span className="subtitle">
          {translate("Available on %{storeName}", { storeName })}
        </span>
      </div>
      <SquaberButton
        color="secondary_small"
        href={appLink}
        target="_blank"
        onClick={() => {
          AnalyticsEventTracker.trackAppInstallationAttemptEvent();
        }}
      >
        {translate("Open")}
      </SquaberButton>
    </div>
  );
};

AppInstallationCTA.propTypes = {};

export default withBottomNavigation("bottomNavigationValue")(
  AppInstallationCTA
);
