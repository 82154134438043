import classNames from "classnames";
import { isArray, isEmpty, isEqual, isFunction } from "lodash";
import React from "react";

import MobileNavigationLink from "./MobileNavigationLink";

function MobileNavigationLinks({ submenu, onOpenSubmenu, links = [] }) {
  return (
    <ul className={classNames("mobile-navigation__list")}>
      {links.map(link => {
        const { label: childLabel, children, onClick } = link;
        const isActive = isEqual(link, submenu);
        const hasChildren = isArray(children) && !isEmpty(children);

        return (
          <li key={childLabel}>
            <MobileNavigationLink
              {...link}
              onClick={async () => {
                if (hasChildren) {
                  await onOpenSubmenu(link);
                }

                if (isFunction(onClick)) {
                  await onClick();
                }
              }}
              active={isActive}
            />
          </li>
        );
      })}
    </ul>
  );
}

export default MobileNavigationLinks;
