import React from "react";

import { connect } from "../i18n/connect";
import isMessageMarketingMessage from "../lib/isMessageMarketingMessage";
import { showModal } from "../lib/react-redux-modal-provider";
import {
  acceptMarketingMessageRoutine,
  clickCtaMarketingMessageRoutine,
  skipMarketingMessageRoutine
} from "../redux/modules/marketingMessages";
import { getMarketingMessagesOverlay } from "../redux/modules/marketingMessages/selector";
import {
  acceptUserMessageRoutine,
  skipUserMessageRoutine
} from "../redux/modules/userMessages";
import { getUserMessages } from "../redux/modules/userMessages/selector";
import withAdvancedChart from "./withAdvancedChart";
import withOverlay from "./withOverlay";

export default function withUserMessagesHandler(modalComponentName) {
  return WrappedComponent => {
    const mapStateToProps = state => ({
      userMessages: getUserMessages(state),
      marketingMessagesOverlay: getMarketingMessagesOverlay(state)
    });

    const mapDispatchToProps = {
      skipUserMessage: skipUserMessageRoutine,
      acceptUserMessage: acceptUserMessageRoutine,
      skipMarketingMessage: skipMarketingMessageRoutine,
      clickCtaMarketingMessage: clickCtaMarketingMessageRoutine,
      acceptMarketingMessage: acceptMarketingMessageRoutine
    };

    @connect(mapStateToProps, mapDispatchToProps)
    @withOverlay
    @withAdvancedChart
    class WithUserMessagesHandler extends React.Component {
      messageHandled = false;
      componentDidMount() {
        const { userMessages, advancedChartVisible } = this.props;

        if (
          !advancedChartVisible &&
          !this.messageHandled &&
          userMessages.length
        ) {
          this.handleUserMessage(userMessages[0]);
        }
      }

      componentDidUpdate(
        prevProps: Readonly<P>,
        prevState: Readonly<S>,
        snapshot: SS
      ) {
        const {
          userMessages: prevUserMessages,
          marketingMessagesOverlay: prevMarketingMessagesOverlay,
          disabledOverlay: prevDisabledOverlay
        } = prevProps;

        const {
          userMessages,
          marketingMessagesOverlay,
          disabledOverlay,
          advancedChartVisible
        } = this.props;

        const canHandleUserMessage =
          !advancedChartVisible &&
          !this.messageHandled &&
          (prevUserMessages.length !== userMessages.length ||
            prevMarketingMessagesOverlay.length !==
              marketingMessagesOverlay.length ||
            prevDisabledOverlay !== disabledOverlay);

        if (canHandleUserMessage) {
          this.handleUserMessage(
            this.getMessageToHandle(userMessages, marketingMessagesOverlay)
          );
        }
      }

      getMessageToHandle(userMessages, marketingMessagesOverlay) {
        const messages = [...marketingMessagesOverlay, ...userMessages];

        return messages[0];
      }

      handleUserMessage(message) {
        if (typeof message === "undefined") return;

        const {
          skipUserMessage,
          acceptUserMessage,
          skipMarketingMessage,
          acceptMarketingMessage,
          clickCtaMarketingMessage,
          disabledOverlay
        } = this.props;

        const messageIsMarketingMessage = isMessageMarketingMessage(message);

        const skipAction = messageIsMarketingMessage
          ? skipMarketingMessage
          : skipUserMessage;

        const acceptAction = messageIsMarketingMessage
          ? acceptMarketingMessage
          : acceptUserMessage;

        const clickCtaAction = messageIsMarketingMessage
          ? clickCtaMarketingMessage
          : () => {};

        const dismissMessageActionPayload = {
          messageId: message.id,
          messageCtaUrl: message.cta_button_url,
          messageType: message.type,
          messageText: message.message
        };

        if (disabledOverlay) {
          // Don't show modal
          return;
        }

        this.messageHandled = true;

        showModal(modalComponentName, {
          ...message,
          disableBackdropClick: true,
          disableEscapeKeyDown: true,
          messageIsMarketingMessage,
          closable: false,
          onSkip: () => {
            skipAction(dismissMessageActionPayload);
          },
          onAccept: () => {
            acceptAction(dismissMessageActionPayload);
          },
          onCta: () => {
            clickCtaAction(dismissMessageActionPayload);
          }
        });
      }

      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return WithUserMessagesHandler;
  };
}
