import React from "react";
import Formsy from "formsy-react";
import classNames from "classnames";
import withPortfolioAddCashTransaction from "../../../../../hocs/withPortfolioAddCashTransaction";
import SquaberTable from "../../SquaberTable";
import PortfolioAddCashTransaction from "../../PortfolioAddCashTransaction";

import "../styles/default.scss";
import "../../PortfolioStocksTable/styles/default.scss";

const PortfolioAddCashTransactionWrapper = ({
  translate,
  addTransaction,
  formBuilder,
  addPortfolioCashTransactionState: { loading, error },
  clearValidationError,
  setShowAddCashTransaction
}) => (
  <div
    className={classNames({
      "portfolio-add-cash-transactions": true,
      "is-mobile-page-sheet": true
    })}
  >
    <h2>{translate("Add cash")}</h2>
    <div
      className="close-page-sheet"
      onClick={() => {
        setShowAddCashTransaction(false);
      }}
    >
      <i className="fal fa-times" />
    </div>
    <Formsy
      onValidSubmit={() => {
        addTransaction({ formBuilder });
      }}
      validationErrors={error}
    >
      <div className="scrollable-table-wrapper">
        <SquaberTable
          className="portfolio-stocks-table"
          heading={[
            translate("Transaction type"),
            translate("Date"),
            translate("Amount"),
            ""
          ]}
          rows={[
            <PortfolioAddCashTransaction
              formBuilder={formBuilder}
              loading={loading}
              validationErrors={error}
              clearValidationError={clearValidationError}
              key="add-cash-transaction"
            />
          ]}
        />
      </div>
    </Formsy>
  </div>
);

PortfolioAddCashTransactionWrapper.propTypes = {};

export default withPortfolioAddCashTransaction(
  PortfolioAddCashTransactionWrapper
);
