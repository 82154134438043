import { takeLatest, fork, put } from "redux-saga/effects";
import { getTransactionHistoryRoutine } from "./index";
import ApiClient from "../../../api/ApiClient";

function* getTransactionHistory() {
  try {
    const { data } = yield ApiClient.get("app.payment.transactionHistory");

    yield put(getTransactionHistoryRoutine.success(data));
  } catch (e) {
    yield put(getTransactionHistoryRoutine.failure(e));
  }
}

function* watchGetTransactionHistory() {
  yield takeLatest(getTransactionHistoryRoutine.TRIGGER, getTransactionHistory);
}

export default [fork(watchGetTransactionHistory)];
