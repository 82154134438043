import React from "react";
import { connect } from "react-redux";

import {
  getAnalysisCategories,
  getAnalysisCategoriesLoading
} from "../redux/modules/analysis/analysisCategories/selector";

export default function withAnalysisCategories(WrappedComponent) {
  const mapStateToProps = state => ({
    analysisCategories: getAnalysisCategories(state),
    analysisCategoriesLoading: getAnalysisCategoriesLoading(state)
  });

  @connect(mapStateToProps)
  class WithAnalysisCategories extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithAnalysisCategories;
}
