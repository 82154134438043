import "./ActiveFilters.scss";

import React from "react";

import withTranslations from "../../../../../../hocs/withTranslations";
import transformDateTimeIntoDate from "../../../../../../lib/transformDateTimeIntoDate";
import ApiHelper from "../../../../../../lib/UniversalTable/CoreHelpers/ApiHelper";
import UniversalTableContext from "../../../../../../lib/UniversalTable/UniversalTableContext";
import Renderer from "./renderers";

@withTranslations
class ActiveFilters extends React.Component {
  static contextType = UniversalTableContext;

  renderSearchFilter(searchField, searchValue) {
    const columns = this.context.getAllAvailableColumns();

    const columnData = columns.find(column =>
      ApiHelper.compareColumnAttributeNameForFiltering(column, searchField)
    );

    return (
      <div className="active-filter" key={`active-filter-${searchField}`}>
        <span className="field">{columnData.label}:</span>
        <span className="value">{searchValue}</span>
        <span
          className="remove-filter"
          onClick={() => {
            this.removeSearch(searchField);
          }}
        >
          <i className="fal fa-times" />
        </span>
      </div>
    );
  }

  renderFilter(filterField, filterValue) {
    const { translate } = this.props;
    const columns = this.context.getAllAvailableColumns();

    const columnData = columns.find(column =>
      ApiHelper.compareColumnAttributeNameForFiltering(column, filterField)
    );

    let valueLabel = "";

    if (
      typeof filterValue.min !== "undefined" ||
      typeof filterValue.max !== "undefined"
    ) {
      let min =
        filterValue.min !== null ? filterValue.min : columnData.filter_data.min;
      let max =
        filterValue.max !== null ? filterValue.max : columnData.filter_data.max;

      valueLabel = `${transformDateTimeIntoDate(
        min
      )} - ${transformDateTimeIntoDate(max)}`;
    } else if (Array.isArray(filterValue)) {
      valueLabel = filterValue
        .map(value => translate(String(value)))
        .join(", ");
    }

    return (
      <div className="active-filter" key={`active-filter-${filterField}`}>
        <span className="field">{columnData.label}:</span>
        <span className="value">
          <Renderer value={valueLabel} column={columnData} />
        </span>
        <span
          className="remove-filter"
          onClick={() => {
            this.removeFilter(filterField);
          }}
        >
          <i className="fal fa-times" />
        </span>
      </div>
    );
  }

  removeSearch(searchField) {
    const { search, setSearch } = this.context;

    delete search[searchField];

    setSearch(search);
  }

  removeFilter(filterField) {
    const { filters, setFilters } = this.context;

    delete filters[filterField];

    setFilters(filters);
  }

  render() {
    const { search, filters, getExcludedSettings } = this.context;

    const excludedSettings = getExcludedSettings();

    const searchKeys = Object.keys(search).filter(
      key => excludedSettings.indexOf(key) === -1
    );

    const filtersKeys = Object.keys(filters).filter(
      key => excludedSettings.indexOf(key) === -1
    );

    if (!searchKeys.length && !filtersKeys.length) {
      return null;
    }

    return (
      <div className="active-filters">
        {searchKeys.map(key => this.renderSearchFilter(key, search[key]))}
        {filtersKeys.map(key => this.renderFilter(key, filters[key]))}
      </div>
    );
  }
}

export default ActiveFilters;
