import autoBind from "auto-bind";
import React, { Component } from "react";

import config from "../config";

export default class StockRecommendationsAbstract extends Component {
  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  onButtonClick() {
    const {
      recommendations,
      fetchMoreStockRecommendations,
      stockId
    } = this.props;
    fetchMoreStockRecommendations({
      stockId,
      start: recommendations.length,
      count: config.recommendationsToFetch
    });
  }
}
