import { createAction } from "redux-actions";

const namespace = "app/notifications";

const FETCH_NOTIFICATIONS = `${namespace}/FETCH_NOTIFICATIONS`;
const FETCH_NOTIFICATIONS_ERROR = `${namespace}/FETCH_NOTIFICATIONS_ERROR`;
const FETCH_NOTIFICATIONS_SUCCESS = `${namespace}/FETCH_NOTIFICATIONS_SUCCESS`;
const FETCH_NOTIFICATIONS_CANCEL = `${namespace}/FETCH_NOTIFICATIONS_CANCEL`;

const MARK_AS_READ = `${namespace}/MARK_AS_READ`;
const MARK_AS_READ_ERROR = `${namespace}/MARK_AS_READ_ERROR`;
const MARK_AS_READ_SUCCESS = `${namespace}/MARK_AS_READ_SUCCESS`;

const ADD_NOTIFICATION = `${namespace}/ADD_NOTIFICATION`;
const ADD_NOTIFICATION_SUCCESS = `${namespace}/ADD_NOTIFICATION_SUCCESS`;

const CLEAR_NOTIFICATIONS = `${namespace}/CLEAR_NOTIFICATIONS`;

const ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE = `${namespace}/ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE`;
const ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE_ERROR = `${namespace}/ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE_ERROR`;
const ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE_SUCCESS = `${namespace}/ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE_SUCCESS`;

export const constants = {
  FETCH_NOTIFICATIONS,
  FETCH_NOTIFICATIONS_ERROR,
  FETCH_NOTIFICATIONS_SUCCESS,
  FETCH_NOTIFICATIONS_CANCEL,
  MARK_AS_READ,
  MARK_AS_READ_ERROR,
  MARK_AS_READ_SUCCESS,
  ADD_NOTIFICATION,
  ADD_NOTIFICATION_SUCCESS,
  CLEAR_NOTIFICATIONS,
  ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE,
  ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE_SUCCESS,
  ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE_ERROR
};

const fetchNotifications = createAction(
  FETCH_NOTIFICATIONS,
  (loadNext: boolean, authToken: ?string) => ({ loadNext, authToken })
);
const fetchNotificationsError = createAction(
  FETCH_NOTIFICATIONS_ERROR,
  () => ({})
);
const fetchNotificationsSuccess = createAction(
  FETCH_NOTIFICATIONS_SUCCESS,
  (data: Object) => ({ data })
);
const fetchNotificationsCancel = createAction(
  FETCH_NOTIFICATIONS_CANCEL,
  () => ({})
);
const markAsRead = createAction(
  MARK_AS_READ,
  (id: number, shouldRedirect: boolean) => ({ id, shouldRedirect })
);
const markAsReadError = createAction(MARK_AS_READ_ERROR, () => ({}));
const markAsReadSuccess = createAction(
  MARK_AS_READ_SUCCESS,
  (data: Object) => ({ data })
);
const addNotification = createAction(ADD_NOTIFICATION, (data: Object) => ({
  data
}));
const addNotificationSuccess = createAction(
  ADD_NOTIFICATION_SUCCESS,
  (data: Object) => ({
    data
  })
);
const clearNotifications = createAction(CLEAR_NOTIFICATIONS, () => ({}));
const attemptBackgroundNotificationsUpdate = createAction(
  ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE,
  () => ({})
);
const attemptBackgroundNotificationsUpdateError = createAction(
  ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE_ERROR,
  () => ({})
);
const attemptBackgroundNotificationsUpdateSuccess = createAction(
  ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE_SUCCESS,
  (data: Object) => ({ data })
);

export const actions = {
  fetchNotifications,
  fetchNotificationsError,
  fetchNotificationsSuccess,
  fetchNotificationsCancel,
  markAsRead,
  markAsReadError,
  markAsReadSuccess,
  addNotification,
  addNotificationSuccess,
  clearNotifications,
  attemptBackgroundNotificationsUpdate,
  attemptBackgroundNotificationsUpdateError,
  attemptBackgroundNotificationsUpdateSuccess
};

const initialState = {
  loading: false,
  endReached: false,
  data: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_NOTIFICATIONS:
    case MARK_AS_READ:
    case ADD_NOTIFICATION:
      return {
        ...state,
        loading: true
      };

    case ADD_NOTIFICATION_SUCCESS: {
      let { message } = {
        ...action.payload.data
      };
      let unread = state.data.unread;

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          messages: [
            message,
            ...(state.data.messages ? state.data.messages : [])
          ],
          unread: !message.read ? ++unread : unread
        }
      };
    }

    case FETCH_NOTIFICATIONS_SUCCESS: {
      let { messages } = {
        ...action.payload.data
      };

      return {
        ...state,
        loading: false,
        endReached: messages.length === 0,
        data: {
          ...action.payload.data,
          messages: [
            ...(state.data.messages ? state.data.messages : []),
            ...messages
          ]
        }
      };
    }

    case FETCH_NOTIFICATIONS_CANCEL:
      return {
        ...state,
        loading: false
      };

    case FETCH_NOTIFICATIONS_ERROR:
      return initialState;

    case MARK_AS_READ_SUCCESS:
      let id = action.payload.data.id;
      let tmpMessages = [...state.data.messages];
      let unreadCount = state.data.unread;

      if (id) {
        // make only one message as read and...
        let tmpMessage = tmpMessages.find(obj => {
          return obj.id === id;
        });

        // decrement unread only if message was unread
        if (tmpMessage && !tmpMessage.read) {
          tmpMessage.read = true;
          unreadCount--;
        }
      } else {
        // if id is empty, make all messages as read and...
        tmpMessages.map(obj => {
          obj.read = true;
          return obj;
        });

        // erase unread count
        unreadCount = 0;
      }

      return {
        ...state,
        loading: false,
        data: {
          ...state.data,
          messages: tmpMessages,
          unread: unreadCount
        }
      };

    case MARK_AS_READ_ERROR:
      return {
        ...state,
        loading: false
      };

    case CLEAR_NOTIFICATIONS:
      return initialState;

    case ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE:
      return state;

    case ATTEMPT_BACKGROUND_NOTIFICATIONS_UPDATE_SUCCESS:
      let { messages } = {
        ...action.payload.data
      };

      return {
        ...state,
        loading: false,
        endReached: messages.length === 0,
        data: {
          ...action.payload.data
        }
      };

    default:
      return state;
  }
}
