import React from "react";
import Formsy from "formsy-react";
import classNames from "classnames";
import ApiHelper from "../../../../../../../lib/UniversalTable/CoreHelpers/ApiHelper";
import ComposedTextField from "../../../../../../../lib/FormBuilder/FormsyMaterialUI/ComposedTextField";
import withTranslations from "../../../../../../../hocs/withTranslations";
import SingleRangeFilterAbstract from "../../../../../../../abstract/SingleRangeFilterAbstract";
import FormsyDatePicker from "../../../../../../../lib/FormBuilder/FormsyMaterialUI/FormsyDatePicker";

@withTranslations
class SingleRangeFilter extends SingleRangeFilterAbstract {
  renderRegularInput(field) {
    const { column, translate } = this.props;
    const { errors } = this.state;

    const value = this.getValue(field);
    const label = field === "min" ? "From" : "To";

    return (
      <ComposedTextField
        name={ApiHelper.getColumnAttributeForFiltering(column) + "_" + field}
        label={translate(label)}
        value={String(value)}
        onChange={value => {
          this.handleChange(field, value);
        }}
        onBlur={() => {
          this.validateValues();
        }}
        fullWidth
        className={this.getInputClassName(this.getValueExists(field))}
        error={!!errors[field]}
      />
    );
  }

  renderDatePicker(field) {
    const { column, translate } = this.props;
    const { errors } = this.state;

    const value = this.getValue(field);
    const label = field === "min" ? "From" : "To";

    return (
      <FormsyDatePicker
        id={ApiHelper.getColumnAttributeForFiltering(column) + "_" + field}
        name={ApiHelper.getColumnAttributeForFiltering(column) + "_" + field}
        value={String(value)}
        label={translate(label)}
        onChange={value => {
          this.handleChange(field, value);
        }}
        error={!!errors[field]}
        targetFormat={"YYYY-MM-DD"}
        className={this.getInputClassName(this.getValueExists(field))}
        allowFuture
        formatDatetimeLikeValue
      />
    );
  }

  getInputClassName(valueExists) {
    return classNames({
      "is-greyed-out": !valueExists
    });
  }

  render() {
    const { column, translate } = this.props;
    const { errors } = this.state;
    const { label } = column;

    return (
      <div className="filters-item-wrapper range-filter">
        <h4>{label}</h4>
        <Formsy className="inputs-wrapper">
          <div className="field-outer">
            <div className="field-wrapper">
              {ApiHelper.getIsDateRange(column)
                ? this.renderDatePicker("min")
                : this.renderRegularInput("min")}
              {this.getValueExists("min") ? (
                <span
                  className="clear-value"
                  onClick={() => {
                    this.clearValue("min");
                  }}
                >
                  <i className="fal fa-times" />
                </span>
              ) : null}
            </div>
            {errors.min ? (
              <span className="error-text">{errors.min}</span>
            ) : null}
          </div>
          <div className="field-outer">
            <div className="field-wrapper">
              {ApiHelper.getIsDateRange(column)
                ? this.renderDatePicker("max")
                : this.renderRegularInput("max")}
              {this.getValueExists("max") ? (
                <span
                  className="clear-value"
                  onClick={() => {
                    this.clearValue("max");
                  }}
                >
                  <i className="fal fa-times" />
                </span>
              ) : null}
            </div>
            {errors.max ? (
              <span className="error-text">{errors.max}</span>
            ) : null}
          </div>
        </Formsy>
        {this.getValueIsInvalid() ? (
          <span className="error-text">
            {translate("Minimal value should not be larger than maximal value")}
          </span>
        ) : null}
      </div>
    );
  }
}

export default SingleRangeFilter;
