import React from "react";
import autoBind from "auto-bind";

class FormsyBase extends React.Component {
  static defaultProps = {
    skipPristine: false
  };

  constructor(props) {
    super(props);
    autoBind.react(this);
  }

  handleChange(value) {
    const { value: oldValue, setValue, onChange } = this.props;

    if (oldValue !== value) {
      setValue(value);
    }

    if (onChange) {
      onChange(value);
    }
  }

  showError() {
    const { isValid, isPristine, skipPristine } = this.props;
    return !isValid && (!isPristine || skipPristine);
  }
}

export const styles = theme => ({
  formControl: {
    margin: theme.spacing(),
    marginLeft: 0,
    marginRight: 0,
    minWidth: 166
  }
});

export default FormsyBase;
