import { createAction } from "redux-actions";
import { ISalesManagoEvent } from "../../../lib/SalesManago/interfaces/ISalesManagoEvent";

export const TRACK_SM_EVENT = "app/salesManagoEventTracker/TRACK_SM_EVENT";

export const trackSMEvent = createAction(
  TRACK_SM_EVENT,
  (event: ISalesManagoEvent) => ({
    event
  })
);

const initialState = {};

export default function reducer(state = initialState) {
  return state;
}
