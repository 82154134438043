import "../styles/default.scss";

import classNames from "classnames";
import moment from "moment-mini";
import React from "react";

import { UrlProvider } from "../../../../../api/UrlProvider";
import Price from "../../../../../common/Price";
import config from "../../../../../config";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withFollowUnfollow from "../../../../../hocs/withFollowUnfollow";
import withMarketTransltaions from "../../../../../hocs/withMarketTranslations";
import withPreloadedStockPageData from "../../../../../hocs/withPreloadedStockPageData";
import withQuoteData from "../../../../../hocs/withQuoteData";
import withUserData from "../../../../../hocs/withUserData";
import accessLevelsMap from "../../../../../lib/access/accessLevelsMap";
import getAccessLevel from "../../../../../lib/access/getAccessLevel";
import handleAccess from "../../../../../lib/access/handleAccess";
import getStockData from "../../../../../lib/getStockData";
import { getTranslatedMarket } from "../../../../../lib/getTranslatedMarket";
import ChangePercent from "../../ChangePercent";
import SquaberLink from "../../SquaberLink";
import AdvancedView from "../components/AdvancedView";

@withQuoteData()
@withFollowUnfollow
@withBottomNavigation("bottomNav")
@withUserData
@withPreloadedStockPageData
@withMarketTransltaions
class StockElement extends React.PureComponent {
  getStockLinkUrl() {
    const {
      locale,
      scrollToSection,
      is_blurred,
      userHasAccess,
      marketTranslations
    } = this.props;

    if (is_blurred && !userHasAccess) {
      return "#!";
    }

    const { ticker, market } = getStockData(this.props);

    const translatedMarket = getTranslatedMarket(
      locale,
      market,
      marketTranslations
    );

    let urlPath = "fe.stockPage";
    let urlArgs = {
      locale,
      marketId: translatedMarket,
      stockId: ticker
    };

    if (scrollToSection) {
      urlPath = "fe.stockPageWithScrollToSection";
      urlArgs = {
        ...urlArgs,
        section: scrollToSection
      };
    }

    return UrlProvider.getUrl(urlPath, urlArgs);
  }

  render() {
    let {
      is_blurred,
      quoteData,
      emptyQuoteData,
      user_stock,
      setValue,
      userData,
      hideRemoveButton,
      advancedMode,
      userHasAccess,
      translate,
      setPreloadedStock
    } = this.props;

    const {
      stock_id,
      ticker,
      market,
      name,
      recommended,
      currency,
      average_volume
    } = getStockData(this.props);

    const shouldBlur = is_blurred && !userHasAccess;
    const accessLevel = getAccessLevel(userData, user_stock);

    const stockLinkUrl = this.getStockLinkUrl();

    const content = (
      <React.Fragment>
        <div className="default-view-wrapper">
          {advancedMode ? (
            <SquaberLink
              className="stock-info"
              to={stockLinkUrl}
              onClick={() => {
                handleAccess(accessLevel, [
                  accessLevelsMap.NO_PREMIUM_ACCESS,
                  accessLevelsMap.PREMIUM_ACCESS_EXPIRED
                ]);

                if (accessLevel !== accessLevelsMap.NOT_LOGGED_IN) {
                  setValue("");
                }
              }}
            >
              <div className="ticker">{!shouldBlur ? ticker : "---"}</div>
              <div className="market">{!shouldBlur ? market : "---"}</div>
              <div className="stock-name">{!shouldBlur ? name : "------"}</div>
            </SquaberLink>
          ) : (
            <div className="stock-info">
              <div className="ticker">{!shouldBlur ? ticker : "---"}</div>
              <div className="market">{!shouldBlur ? market : "---"}</div>
              <div className="stock-name">{!shouldBlur ? name : "------"}</div>
            </div>
          )}

          <div className="alert-status">
            {user_stock.alerted ? (
              <i className="fas fa-bell alert-active-icon" />
            ) : null}
            {recommended ? (
              <i className="fas fa-exclamation-circle interesting-marker" />
            ) : null}
          </div>
          <div className="stock-status">
            {!emptyQuoteData ? (
              <React.Fragment>
                <div className="change">
                  <ChangePercent change={quoteData.change} />
                  {average_volume ? (
                    <span className="average-volume">
                      {Math.round((quoteData.volume / average_volume) * 100)}%{" "}
                      <br />
                      {translate("avg. vol.")}
                    </span>
                  ) : null}
                </div>
                <div className="close-value">
                  <Price value={quoteData.close} currency={currency} />
                  {config.vendor !== "expalerts" ? (
                    <span className="update-time">
                      (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: moment(quoteData.datetime_utc)
                            .utc()
                            .add(2, "hours")
                            .format("DD[&#8209;]MM[&#8209;]YYYY")
                        }}
                      />
                      )
                    </span>
                  ) : null}
                </div>
              </React.Fragment>
            ) : null}
          </div>
          {!hideRemoveButton ? (
            <div
              className="remove-alert"
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
                this.props.unFollow({ stockId: stock_id, ticker, market });
              }}
            >
              <i className="far fa-trash-alt" />
            </div>
          ) : null}
        </div>
        {advancedMode ? <AdvancedView {...this.props} /> : null}
      </React.Fragment>
    );

    return !advancedMode ? (
      <SquaberLink
        to={stockLinkUrl}
        className={classNames({
          "stock-element": true,
          "is-recommended": recommended,
          blurred: shouldBlur
        })}
        onClick={() => {
          setPreloadedStock({
            id: stock_id,
            ticker,
            name,
            currency
          });

          if (is_blurred) {
            handleAccess(accessLevel);
          } else {
            setValue("");
          }
        }}
      >
        {content}
      </SquaberLink>
    ) : (
      <div
        className={classNames({
          "stock-element": true,
          "is-recommended": recommended,
          blurred: shouldBlur
        })}
      >
        {content}
      </div>
    );
  }
}

export default StockElement;
