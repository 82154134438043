import classNames from "classnames";
import React from "react";
import { Link } from "react-router-dom";
import useIsDesktop from "../../../hooks/useIsDesktop";

export const PlanBoxContent = ({ title, price, description, link, mode }) => {
  const isDesktop = useIsDesktop();

  return (
    <>
      <div className={"flex justify-between mb-1"}>
        <h3
          className={classNames({
            "text-2xl.1": isDesktop,
            "text-2xl": !isDesktop
          })}
        >
          {title}
        </h3>
        <strong
          className={classNames("font-bold", {
            "text-3xl.1": isDesktop,
            "text-2xl.1": !isDesktop
          })}
        >
          {price}
        </strong>
      </div>
      <p className={"text-right text-xs opacity-50"}>{description}</p>
      <Link
        to={link.href}
        className={classNames("button-1 block w-full", {
          "button-1 block w-full bg-black-2 text-white hover:bg-black focus:bg-black":
            mode === "dark"
        })}
      >
        {link.text}
      </Link>
    </>
  );
};
