import React from "react";
import {
  getDividendsData,
  getDividendsLoading
} from "../redux/modules/tvchartData/selector";
import { getDividendsRoutine } from "../redux/modules/tvchartData";
import connect from "../i18n/connect";

export default function withDividends(WrappedComponent) {
  const mapStateToProps = state => ({
    dividends: getDividendsData(state),
    dividendsLoading: getDividendsLoading(state)
  });

  const mapDispatchToProps = {
    fetchDividends: getDividendsRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithDividends extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithDividends;
}
