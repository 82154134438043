import PropTypes from "prop-types";
import React from "react";
import { isEmpty } from "lodash";
import classNames from "classnames";
import withTranslations from "../../../../../hocs/withTranslations";
import Loader from "../../Loader";
import StockCards from "../../StockCards";

import "../styles/default.scss";

function SearchResults({
  className,
  onResultClick = () => {},
  results,
  loadingResults,
  loadingMoreResults,
  searchPhrase,
  ...rest
}) {
  const { translate } = rest;

  const hasFoundResults = !isEmpty(searchPhrase) && !isEmpty(results);

  const resultsWrapper = hasFoundResults ? (
    <>
      <StockCards
        title="search_modal_results"
        stocks={results}
        type="results"
        onStockClick={onResultClick}
      />
      {loadingMoreResults ? <Loader small /> : null}
    </>
  ) : (
    <div className="search-results__results-not-found">
      {translate("search_modal_results_not_found")}
    </div>
  );

  if (isEmpty(searchPhrase) && !loadingResults) return null;

  return (
    <div className={classNames("search-results__results-wrapper", className)}>
      {loadingResults ? <Loader small /> : resultsWrapper}
    </div>
  );
}

export default withTranslations(SearchResults);

SearchResults.propTypes = {
  className: PropTypes.string,
  onResultClick: PropTypes.func,
  results: PropTypes.array,
  loadingMoreResults: PropTypes.bool,
  searchPhrase: PropTypes.string
};
