import { takeLatest, fork, put, select, delay } from "redux-saga/effects";
import {
  fetchMoreStockRecommendations,
  fetchStockRecommendations
} from "./index";
import ApiClient from "../../../api/ApiClient";
import config from "../../../config";

function* onFetchStockRecommendations({ payload }) {
  const { stockId } = payload;

  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.stocks.recommendations",
      variables: {
        stockId,
        count: config.recommendationsToFetch,
        start: 0
      }
    });

    yield put(fetchStockRecommendations.success(data));
  } catch (e) {
    console.log(e);
  }
}

function* onFetchMoreStockRecommendations({ payload }) {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.stocks.recommendations",
      variables: payload
    });

    yield put(fetchMoreStockRecommendations.success(data));
  } catch (e) {
    console.log(e);
  }
}

function* watchRecommendations() {
  yield takeLatest(
    fetchStockRecommendations.TRIGGER,
    onFetchStockRecommendations
  );
  yield takeLatest(
    fetchMoreStockRecommendations.TRIGGER,
    onFetchMoreStockRecommendations
  );
}

export default [fork(watchRecommendations)];
