import React from "react";
import qs from "qs";
import withTranslations from "../../../../../hocs/withTranslations";
import { Redirect } from "react-router";

const LocalizedRouteRedirect = ({ locale, location }) => {
  const searchParams = qs.parse(location.search, { ignoreQueryPrefix: true });

  if (searchParams.authRedirect) {
    return null;
  }

  return <Redirect to={`/${locale}${location.pathname}`} />;
};

LocalizedRouteRedirect.propTypes = {};

export default withTranslations(LocalizedRouteRedirect);
