import React from "react";
import PropTypes from "prop-types";
import withTranslations from "../../../../../hocs/withTranslations";
import SquaberTable from "../../SquaberTable";
import SquaberLink from "../../SquaberLink";
import DividedNumber from "../../DividedNumber";

import "../styles/default.scss";

const StockBasicInfo = ({ info, translate }) => (
  <div>
    <SquaberTable
      className="basic-info-table"
      rows={Object.keys(info)
        .filter(key => !!info[key])
        .map(key => {
          return [
            {
              isHeading: true,
              content: translate(`basic-info-${key}`)
            },
            (function(key, info) {
              switch (key) {
                case "website":
                  return (
                    <SquaberLink
                      to={info[key]}
                      target="_blank"
                      shouldAddSchema
                      rel="nofollow"
                    >
                      {info[key]}
                    </SquaberLink>
                  );

                case "capitalization":
                case "share_amount":
                  return <DividedNumber value={info[key]} />;

                default:
                  return (
                    <div dangerouslySetInnerHTML={{ __html: info[key] }} />
                  );
              }
            })(key, info)
          ];
        })}
    />
  </div>
);

StockBasicInfo.propTypes = {
  info: PropTypes.shape({
    fullname: PropTypes.string,
    ceo: PropTypes.string,
    sector: PropTypes.string,
    market: PropTypes.string,
    indexes: PropTypes.string,
    website: PropTypes.string,
    description: PropTypes.string
  })
};

export default withTranslations(StockBasicInfo);
