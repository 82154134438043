import React from "react";
import PropTypes from "prop-types";
import getAccessLevel from "../lib/access/getAccessLevel";
import handleAccess from "../lib/access/handleAccess";
import accessLevelsMap from "../lib/access/accessLevelsMap";

class PriceAlertsAbstract extends React.Component {
  static propTypes = {
    priceAlerts: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        price: PropTypes.string,
        sent_date: PropTypes.string
      })
    )
  };

  static defaultProps = {
    priceAlerts: []
  };

  fetchedForStockId = undefined;

  componentDidMount() {
    this.fetchPriceAlerts();
  }

  componentDidUpdate() {
    this.fetchPriceAlerts();
  }

  fetchPriceAlerts() {
    const { stockId, fetchPriceAlerts } = this.props;
    if (
      typeof this.fetchedForStockId === "undefined" ||
      stockId !== this.fetchedForStockId
    ) {
      fetchPriceAlerts(stockId, true);
      this.fetchedForStockId = stockId;
    }
  }

  addAlert(fields, form, clearFormBuilder) {
    const { addPriceAlert, stockId } = this.props;
    const payload = {
      value: fields[0].value,
      comment: fields[1].value
    };

    addPriceAlert(stockId, payload, form, clearFormBuilder);
  }

  removeAlert(alertId) {
    const { deletePriceAlert, stockId } = this.props;

    deletePriceAlert(stockId, alertId);
  }

  renewAlert(alertId, value, comment) {
    const { addPriceAlert, deletePriceAlert, stockId } = this.props;

    deletePriceAlert(stockId, alertId, true, () => {
      addPriceAlert(
        stockId,
        {
          value,
          comment
        },
        null,
        null
      );
    });
  }
}

export default PriceAlertsAbstract;
