import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../styles/default.scss";

const SquaberTable = ({ className, heading, rows, withDistinctRows }) => {
  const table = useRef();
  const wrapper = useRef();
  const [overflowActive, setOverflowActive] = useState(false);

  useEffect(() => {
    const isOverflowActive = (table, wrapper) => {
      return table?.clientWidth > wrapper.clientWidth;
    };

    setOverflowActive(isOverflowActive(table.current, wrapper.current));
  }, []);

  //TODO: Set mediaMessageBar height if overflowActive to thead -> top

  return (
    <div className="table-parent">
      <div
        className={classNames({
          "table-wrapper": true,
          horizontalScroll: overflowActive
        })}
        ref={wrapper}
      >
        <table
          className={classNames({
            "squaber-table": true,
            "with-distinct-rows": withDistinctRows,
            overflow: overflowActive,
            [className]: !!className
          })}
          ref={table}
        >
          {heading ? (
            <thead>
              <tr>
                {heading.map((element, key) => (
                  <th key={key}>{element}</th>
                ))}
              </tr>
            </thead>
          ) : null}
          <tbody>
            {rows.map((Row, rowKey) =>
              Array.isArray(Row) ? (
                <tr key={rowKey}>
                  {Row.map((cell, cellKey) => {
                    const isCellObject =
                      cell !== null &&
                      typeof cell === "object" &&
                      !!cell.content;

                    let Element = "td";

                    if (isCellObject && cell && cell.isHeading) {
                      Element = "th";
                    }

                    return (
                      <Element
                        key={cellKey}
                        className={
                          isCellObject && cell.className ? cell.className : null
                        }
                        colSpan={
                          isCellObject && cell && cell.colspan
                            ? cell.colspan
                            : null
                        }
                      >
                        {isCellObject ? cell.content : cell}
                      </Element>
                    );
                  })}
                </tr>
              ) : (
                Row
              )
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

SquaberTable.propTypes = {
  heading: PropTypes.array,
  withDistinctRows: PropTypes.bool,
  rows: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.arrayOf(
        PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.node,
          PropTypes.shape({
            isHeading: PropTypes.bool,
            content: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
              .isRequired,
            className: PropTypes.string
          })
        ])
      )
    ])
  ).isRequired
};

export default SquaberTable;
