import envVariables from "../../envHelpers/getEnvVariables";
import deepMerge from "../../DeepMerge";
import * as configs from "./modules/";

let config = configs.default;

let specificConfigKey = `${envVariables.VENDOR}_${envVariables.TARGET_ENV ||
  envVariables.NODE_ENV}`;

let vendorConfig = {};

if (specificConfigKey && configs[specificConfigKey]) {
  vendorConfig = deepMerge(configs[specificConfigKey]);
}

config = deepMerge(config, vendorConfig);

export default config;
