import autoBind from "auto-bind";
import { isEqual } from "lodash";
import qs from "qs";
import React from "react";
import { withRouter } from "react-router";

import withAnalysisPersistenceLayerWeb from "../../../../../hocs/withAnalysisPresistenceLayer/withAnalysisPersistenceLayerWeb";
import withTranslations from "../../../../../hocs/withTranslations";
import AnalysisDisplayStyles from "../../../common/AnalysisDisplayStyles";
import AnalysisFilters from "../../../common/AnalysisFilters";
import AnalysisSort from "../../../common/AnalysisSort";
import CopyToClipboardButton from "../../../common/CopyToClipboardButton";
import PageTitle from "../../../common/PageTitle/modules/default";
import SquaberAutocompleteWrapper from "../../../common/SquaberAutocompleteWrapper";
import SquaberCommentsList from "../../../common/SquaberCommentsList";

@withAnalysisPersistenceLayerWeb
@withTranslations
@withRouter
class SquaberCommentsPage extends React.Component {
  state = {
    autocompleteValue: null,
    categoryIds: null
  };

  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  componentDidMount() {
    const queryParams = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    });

    if (queryParams && queryParams.ticker && queryParams.market) {
      this.setAutocompleteValue(
        {
          autocompleteValue: queryParams.ticker,
          stockData: { ticker: queryParams.ticker, market: queryParams.market }
        },
        false
      );
    }
  }

  setAutocompleteValue(autocompleteValue, applyChangesToUrl = true) {
    const {
      history: { push }
    } = this.props;

    if (applyChangesToUrl) {
      if (autocompleteValue) {
        push(
          // prettier-ignore
          `${window.location.pathname}?ticker=${autocompleteValue.stockData.ticker}&market=${autocompleteValue.stockData.market}`
        );
      } else {
        push(window.location.pathname);
      }
    }

    this.setState({
      autocompleteValue
    });
  }

  async handleGetAsyncText() {
    const { getSearchParams } = this.props;

    const shareUrl = new URL(window.location.href);
    const autocompleteSearchParams = new URLSearchParams();

    const search = shareUrl.search;

    if (search && typeof search === "string") {
      const { market, ticker } = qs.parse(search, {
        ignoreQueryPrefix: true
      });

      if (market) {
        autocompleteSearchParams.append("market", market);
      }

      if (ticker) {
        autocompleteSearchParams.append("ticker", ticker);
      }
    }

    shareUrl.search = autocompleteSearchParams.toString();

    if (shareUrl.search) {
      shareUrl.search += `&${await getSearchParams()}`;
    } else {
      shareUrl.search = await getSearchParams();
    }

    return shareUrl;
  }

  async componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    if (!isEqual(this.props.analysisFilters, prevProps.analysisFilters)) {
      this.setState({
        categoryIds: await this.props.getSearchParams()
      });
    }
  }

  render() {
    const {
      translate,
      analysisCategories,
      analysisFilters,
      appendFilteredCategory,
      setAnalysisSort,
      setAnalysisDisplayStyle,
      analysisDisplayStyle,
      analysisSortOptions,
      removeFilteredCategory
    } = this.props;
    const { autocompleteValue, categoryIds } = this.state;

    return (
      <div className="history-list-page">
        <PageTitle title={translate("Squaber comments")} />
        <div className="heading-wrapper">
          <div className="heading">
            <h1>{translate("Squaber comments")}</h1>
            <p>{translate("Stock comments generated by Squaber team.")}</p>
          </div>
          <div className="search-wrapper">
            <CopyToClipboardButton
              getAsyncText={this.handleGetAsyncText}
              icon={<i className="far fa-share-all"></i>}
              className={"shear"}
            />
            <AnalysisFilters
              analysisCategories={analysisCategories}
              analysisFilters={analysisFilters}
              appendFilteredCategory={appendFilteredCategory}
              removeFilteredCategory={removeFilteredCategory}
            />
            <div className="search-box" style={{ marginTop: 0 }}>
              <SquaberAutocompleteWrapper
                regularInput
                htmlFor={"ticker"}
                name={"ticker"}
                label={"Search for stock..."}
                source={"app.stocks.searchAutocompleteWithIndex"}
                autocompleteValue={
                  autocompleteValue ? autocompleteValue.autocompleteValue : null
                }
                setAutocompleteValue={this.setAutocompleteValue}
              />
              {autocompleteValue ? (
                <span
                  className="clear-autocomplete"
                  onClick={() => {
                    this.setAutocompleteValue(null);
                  }}
                >
                  <i className="fal fa-times search-icon" />
                </span>
              ) : (
                <i className="fal fa-search search-icon" />
              )}
            </div>
            <div className={"filters"}>
              <AnalysisDisplayStyles
                onClick={setAnalysisDisplayStyle}
                selectedDisplayStyle={analysisDisplayStyle}
              />
              <AnalysisSort
                analysisFilters={analysisFilters}
                setAnalysisSort={setAnalysisSort}
                analysisSortOptions={analysisSortOptions}
              />
            </div>
          </div>
        </div>
        <SquaberCommentsList
          filterByStock={autocompleteValue ? autocompleteValue.stockData : null}
          filterByCategoryIds={categoryIds ? categoryIds : null}
          displayStyle={analysisDisplayStyle}
        />
      </div>
    );
  }
}

export default SquaberCommentsPage;
