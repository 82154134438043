import React from "react";
import { getGlobalConfigField } from "../../../../../redux/modules/globalConfig/selector";
import { useSelector } from "react-redux";

function useAffiliateRedirectParse() {
  const offerUrl = useSelector(state =>
    getGlobalConfigField(state, "offerUrl")
  );

  const redirectPatterns: Array<{
    tokenPattern: RegExp,
    redirectUrl: string
  }> = [
    {
      tokenPattern: /^info13$/g,
      redirectUrl: "https://squaber.com/l/investcuffs/"
    },
    {
      tokenPattern: /^fxmag$/g,
      redirectUrl: "https://squaber.com/l/fxmag/"
    },
    {
      tokenPattern: /^namzalezy$/g,
      redirectUrl: "https://squaber.com/l/namzalezy/"
    },
    { tokenPattern: /./g, redirectUrl: offerUrl }
  ];

  return (token: string) => {
    const { redirectUrl = "" } =
      redirectPatterns.find(({ tokenPattern }) => {
        return tokenPattern.test(token);
      }) || {};

    return redirectUrl;
  };
}

export default useAffiliateRedirectParse;
