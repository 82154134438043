import { handleActions, createAction } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

const CLEAR_FOLLOWED_STOCKS = "app/followed/CLEAR_FOLLOWED_STOCKS";
const NOTIFY_ABOUT_FETCHING_FOLLOWED_STOCKS =
  "app/followed/NOTIFY_ABOUT_FETCHING_FOLLOWED_STOCKS";
const SET_SHOULD_RENDER_FOLLOWED_STOCKS =
  "app/followed/SET_SHOULD_RENDER_FOLLOWED_STOCKS";
const SET_FOLLOWED_STOCKS_MAP = "app/followed/SET_FOLLOWED_STOCKS_MAP";

export const followedStocksRoutine = createRoutine(
  "app/followed/FOLLOWED_STOCKS"
);

export const followStockRoutine = createRoutine("app/followed/FOLLOW_STOCK");

export const unfollowStockRoutine = createRoutine(
  "app/followed/UNFOLLOW_STOCK"
);

export const getAlertedTagDetailsRoutine = createRoutine(
  "app/followed/GET_ALERTED_TAG_DETAILS"
);

export const bulkFollowRoutine = createRoutine("app/followed/BULK_FOLLOW");
export const bulkUnFollowRoutine = createRoutine("app/followed/BULK_UNFOLLOW");

export const notifyAboutFetchingFollowedStocks = createAction(
  NOTIFY_ABOUT_FETCHING_FOLLOWED_STOCKS
);

export const clearFollowedStocks = createAction(
  CLEAR_FOLLOWED_STOCKS,
  () => ({})
);

export const setShouldRenderFollowedStocks = createAction(
  SET_SHOULD_RENDER_FOLLOWED_STOCKS,
  (value: boolean) => ({ value })
);

export const setFollowedStocksMap = createAction(
  SET_FOLLOWED_STOCKS_MAP,
  (followedStocksMap: Object) => ({ followedStocksMap })
);

export const defaultState = {
  loading: false,
  error: null,
  data: null,
  shouldRenderFollowedStocks: true,
  followedStocksMap: {},
  alertedTagDetails: {
    data: null,
    loading: false,
    error: null
  }
};

export default handleActions(
  {
    [followedStocksRoutine.REQUEST]: state => ({
      ...state,
      loading: true
    }),
    [NOTIFY_ABOUT_FETCHING_FOLLOWED_STOCKS]: state => ({
      ...state,
      loading: true
    }),
    [followedStocksRoutine.SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      data: action.payload
    }),
    [followedStocksRoutine.FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload
    }),
    [SET_SHOULD_RENDER_FOLLOWED_STOCKS]: (state, { payload }) => ({
      ...state,
      shouldRenderFollowedStocks: payload.value
    }),
    [CLEAR_FOLLOWED_STOCKS]: () => ({
      ...defaultState
    }),
    [SET_FOLLOWED_STOCKS_MAP]: (state, { payload }) => ({
      ...state,
      followedStocksMap: payload.followedStocksMap
    }),
    [getAlertedTagDetailsRoutine.TRIGGER]: state => ({
      ...state,
      alertedTagDetails: {
        ...state.alertedTagDetails,
        loading: true
      }
    }),
    [getAlertedTagDetailsRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      alertedTagDetails: {
        ...state.alertedTagDetails,
        loading: false,
        error: payload
      }
    }),
    [getAlertedTagDetailsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      alertedTagDetails: {
        ...state.alertedTagDetails,
        loading: false,
        error: null,
        data: payload
      }
    })
  },
  defaultState
);
