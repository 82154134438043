import React from "react";
import { Link } from "react-router-dom";

import connect from "../../../../../../i18n/connect";

const FooterLink = ({ navIndex, linkIndex, translate }) => {
  return (
    <Link
      to={translate(`lp_footer_nav-${navIndex}_link-${linkIndex}_href`)}
      className={"text-white no-underline hover:text-yellow focus:text-yellow"}
    >
      {translate(`lp_footer_nav-${navIndex}_link-${linkIndex}_text`)}
    </Link>
  );
};

export default connect()(FooterLink);
