import React from "react";
import PropTypes from "prop-types";
import SquaberTable from "../../SquaberTable";
import getValueColor from "../../../../../lib/getValueColor";
import Price from "../../../../../common/Price";
import GaugeChart from "../../GaugeChart";
import withTranslations from "../../../../../hocs/withTranslations";

import "../styles/default.scss";

const FinancialAnalysisDetail = ({
  classification: { group_classification, parameters_classification },
  translate
}) =>
  parameters_classification.length ? (
    <div className="financial-analysis-detail">
      <h4>{group_classification.parameter}</h4>
      <div className="row">
        <div className="col-md-9">
          <SquaberTable
            withDistinctRows
            className="financial-analysis-detail-table"
            rows={parameters_classification.map(parameter => [
              parameter.parameter,
              <span
                className="value-text"
                style={{
                  color: getValueColor(null, parameter.classification, true)
                }}
              >
                <Price
                  roundToSecondNumber
                  value={parameter.value}
                  currency={parameter.unit}
                />
              </span>
            ])}
          />
        </div>
        <div className="col-md-3">
          <GaugeChart small value={group_classification.value} precision={1} />
        </div>
      </div>
    </div>
  ) : null;

FinancialAnalysisDetail.propTypes = {
  classification: PropTypes.shape({
    group_classification: PropTypes.object,
    rank: PropTypes.number,
    parameters_classification: PropTypes.array
  })
};

export default withTranslations(FinancialAnalysisDetail);
