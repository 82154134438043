import "../styles/default.scss";

import Tooltip from "@material-ui/core/Tooltip";
import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useState } from "react";

import withTranslations from "../../../../../hocs/withTranslations";

const CopyToClipboardButton = ({
  getAsyncText,
  icon,
  translate,
  className
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleClick = async () => {
    const text = await getAsyncText();

    navigator.clipboard.writeText(text).catch(() => {
      console.error("Can't copy to clipboard");
    });

    setTooltipOpen(true);

    setTimeout(() => {
      setTooltipOpen(false);
    }, 1500);
  };

  return (
    <Tooltip
      open={tooltipOpen}
      title={translate("copy_to_clipboard_tooltip")}
      placement="bottom"
      className={classNames(className, "copy-to-clipboard")}
    >
      <button
        className={"copy-to-clipboard__button"}
        onClick={handleClick}
        onMouseOver={() => {}}
      >
        {icon}
      </button>
    </Tooltip>
  );
};

CopyToClipboardButton.propTypes = {
  getAsyncText: PropTypes.func.isRequired,
  icon: PropTypes.any,
  className: PropTypes.string
};

export default withTranslations(CopyToClipboardButton);
