import { put, takeLatest, fork } from "redux-saga/effects";
import { fetchPartnershipDataRoutine } from "./index";
import ApiClient from "../../../api/ApiClient";

function* onFetchPartnershipData() {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.partnership.data",
      skipAccessCheck: true,
      variables: {
        limit: 20,
        offset: 0
      }
    });

    yield put(fetchPartnershipDataRoutine.success(data));
  } catch (error) {
    yield put(fetchPartnershipDataRoutine.failure(error));
  }
}

function* watchGetPartnershipData() {
  yield takeLatest(fetchPartnershipDataRoutine.TRIGGER, onFetchPartnershipData);
}

export default [fork(watchGetPartnershipData)];
