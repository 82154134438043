import React from "react";

import connect from "../../../../../../i18n/connect";
import XTB1 from "../assets/xtb.png";
import { Box } from "./box";
import { BoxContent } from "./boxContent";
import { BoxReview } from "./boxReview";

export const Reviews = ({ translate }) => {
  return (
    <>
      <Box>
        <BoxContent className={"h-full flex flex-col"}>
          <BoxReview
            name={translate("lp_section-4_review-1_name")}
            company={translate("lp_section-4_review-1_company")}
            reviewText={translate("lp_section-4_review-1_review-text")}
          />
        </BoxContent>
      </Box>
      <Box className={"border-yellow row-span-2"}>
        <BoxContent className={"h-full flex flex-col"}>
          <BoxReview
            image={{
              src: XTB1,
              alt: "XTB",
              width: "94",
              height: "36"
            }}
            name={translate("lp_section-4_review-2_name")}
            company={translate("lp_section-4_review-2_company")}
            reviewText={translate("lp_section-4_review-2_review-text")}
          />
        </BoxContent>
      </Box>
      <Box>
        <BoxContent className={"h-full flex flex-col"}>
          <BoxReview
            name={translate("lp_section-4_review-3_name")}
            company={translate("lp_section-4_review-3_company")}
            reviewText={translate("lp_section-4_review-3_review-text")}
          />
        </BoxContent>
      </Box>
      <Box>
        <BoxContent className={"h-full flex flex-col"}>
          <BoxReview
            name={translate("lp_section-4_review-4_name")}
            company={translate("lp_section-4_review-4_company")}
            reviewText={translate("lp_section-4_review-4_review-text")}
          />
        </BoxContent>
      </Box>
      <Box>
        <BoxContent className={"h-full flex flex-col"}>
          <BoxReview
            name={translate("lp_section-4_review-5_name")}
            company={translate("lp_section-4_review-5_company")}
            reviewText={translate("lp_section-4_review-5_review-text")}
          />
        </BoxContent>
      </Box>
    </>
  );
};

export default connect()(Reviews);
