export type AccessLevel =
  | "NOT_LOGGED_IN"
  | "NO_PREMIUM_ACCESS"
  | "HAS_PREMIUM_ACCESS"
  | "PREMIUM_ACCESS_EXPIRED";

const accessLevelsMap = {
  NOT_LOGGED_IN: "NOT_LOGGED_IN",
  NO_PREMIUM_ACCESS: "NO_PREMIUM_ACCESS",
  HAS_PREMIUM_ACCESS: "HAS_PREMIUM_ACCESS",
  PREMIUM_ACCESS_EXPIRED: "PREMIUM_ACCESS_EXPIRED"
};

export default accessLevelsMap;
