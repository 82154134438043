import "../styles/default.scss";

import Checkbox from "@material-ui/core/Checkbox";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import AnalyticsEventTracker from "../../../../../lib/AnalyticsEventTracker";
import getFileNameFromUrl from "../../../../../lib/getFileNameFromUrl";
import SquaberButton from "../../../common/SquaberButton";
import SquaberLink from "../../../common/SquaberLink";
import AlwaysScrollableDialogContent from "../../AlwaysScrollableDialogContent/modules/default";
import ModalBase from "../../ModalBase";

@withTranslations
class UserMessageModal extends ModalBase {
  state = {
    checkboxAccepted: false
  };

  componentDidMount() {
    const { messageIsMarketingMessage, id } = this.props;

    if (messageIsMarketingMessage) {
      AnalyticsEventTracker.trackOfferModalEvent(id);
    }
  }

  setCheckboxAccepted(value) {
    this.setState({ checkboxAccepted: value });
  }

  getClassName() {
    return "user-message-modal";
  }

  getContent() {
    const {
      title,
      message,
      checkbox_label,
      accept_button_label,
      skip_button_label,
      can_skip,
      cta_button_label,
      cta_button_url,
      attachments,
      translate,
      onSkip,
      onCta,
      onAccept,
      hideModal
    } = this.props;

    const { checkboxAccepted } = this.state;

    return (
      <React.Fragment>
        <DialogTitle id="interesting-stock-dialog-title">{title}</DialogTitle>
        <AlwaysScrollableDialogContent>
          <div
            className="content"
            dangerouslySetInnerHTML={{ __html: message }}
          />
          {cta_button_label && cta_button_url ? (
            <SquaberButton
              fullWidth
              className="cta-button"
              href={cta_button_url}
              target={"_blank"}
              onClick={onCta}
            >
              {cta_button_label}
            </SquaberButton>
          ) : null}
          {attachments.length ? (
            <div className="attachments">
              <h4>{translate("Attachments")}</h4>
              {attachments.map((file, index) => (
                <SquaberLink to={file.attachment} target="_blank" key={index}>
                  {getFileNameFromUrl(file.attachment)}
                </SquaberLink>
              ))}
            </div>
          ) : null}
          {checkbox_label ? (
            <FormControlLabel
              className={"checkbox-wrapper"}
              classes={{
                label: "checkbox-label-wrapper"
              }}
              control={
                <Checkbox
                  checked={checkboxAccepted}
                  onChange={(event, value) => {
                    this.setCheckboxAccepted(value);
                  }}
                />
              }
              label={
                <span className={"checkbox-label"}>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: checkbox_label
                    }}
                  />
                </span>
              }
            />
          ) : null}
          <div className="buttons-wrapper">
            {can_skip ? (
              <SquaberButton
                color="secondary"
                className="skip-button"
                onClick={() => {
                  onSkip();
                  hideModal();
                }}
              >
                {skip_button_label}
              </SquaberButton>
            ) : null}
            <SquaberButton
              disabled={!checkboxAccepted}
              onClick={() => {
                onAccept();
                hideModal();
              }}
            >
              {accept_button_label}
            </SquaberButton>
          </div>
        </AlwaysScrollableDialogContent>
      </React.Fragment>
    );
  }
}

export default UserMessageModal;
