import AggregatedMarkersModal from "../../web/components/modal/AggregatedMarkersModal";
import ConfirmModal from "../../web/components/modal/ConfirmModal";
import ConsentV2Modal from "../../web/components/modal/ConsentV2Modal";
import InterestingStockDescription from "../../web/components/modal/InterestingStockDescription";
import IntroductionModal from "../../web/components/modal/IntroductionModal";
import ModalBase from "../../web/components/modal/ModalBase";
import PageSheetBase from "../../web/components/modal/PageSheetBase";
import PicturePresentationModal from "../../web/components/modal/PicturePresentationModal";
import PortfolioDetailsModal from "../../web/components/modal/PortfolioDetailsModal";
import PremiumBenefitsModal from "../../web/components/modal/PremiumBenefitsModal";
import PremiumModal from "../../web/components/modal/PremiumModal";
import PriceAlertsModal from "../../web/components/modal/PriceAlertsModal";
import RegisterModal from "../../web/components/modal/RegisterModal";
import ResetPasswordModal from "../../web/components/modal/ResetPasswordModal";
import SocialLoginModal from "../../web/components/modal/SocialLoginModal";
import StockTagsListModal from "../../web/components/modal/StockTagsListModal";
import StockTagsModal from "../../web/components/modal/StockTagsModal";
import UserMessageModal from "../../web/components/modal/UserMessageModal";
import BaseModalProviderAbstract from "./BaseModalProviderAbstract";

export default class BaseModalProviderWeb extends BaseModalProviderAbstract {
  modals = {
    ModalBase,
    SocialLoginModal,
    ResetPasswordModal,
    RegisterModal,
    PageSheetBase,
    PremiumModal,
    PicturePresentationModal,
    ConfirmModal,
    PortfolioDetailsModal,
    InterestingStockDescription,
    IntroductionModal,
    StockTagsModal,
    StockTagsListModal,
    UserMessageModal,
    PriceAlertsModal,
    AggregatedMarkersModal,
    PremiumBenefitsModal,
    ConsentV2Modal
  };
}
