import "../styles/default.scss";

import PropTypes from "prop-types";
import React, { useMemo, useState } from "react";
import { compose } from "redux";

import withDeviceType from "../../../../../hocs/withDeviceType";
import withUserData from "../../../../../hocs/withUserData";
import useWindowSize, { commonSizes } from "../../../../hooks/useWindowSize";
import SettingsPageMenuItem from "../../SettingsPageMenuItem";

const SettingsPageMenu = ({
  translate,
  groups,
  categories,
  currentCategory,
  userHasAccess,
  isMobile
}) => {
  const [open, setMenuOpen] = useState(false);
  const { width } = useWindowSize();

  const currentCategoryData = useMemo(() => {
    return categories.find(category => category.name === currentCategory);
  }, [categories, currentCategory]);

  const isMobileMenu = useMemo(() => isMobile || width < commonSizes.MOBILE, [
    isMobile,
    width
  ]);

  const groupedCategories = useMemo(() => {
    return groups.map(group => {
      const groupCategories = [];
      for (let category of categories) {
        if (category.group === group.name) {
          groupCategories.push(category);
        }
      }
      return { ...group, categories: groupCategories };
    });
  }, [groups, categories]);

  return (
    <div className="settings-page-menu">
      {!open && isMobileMenu ? (
        <SettingsPageMenuItem
          active={false}
          {...currentCategoryData}
          setMenuOpen={setMenuOpen}
          isTrigger={true}
        />
      ) : (
        <React.Fragment>
          <div className="settings-page-menu-selector">
            {groupedCategories.map(group => (
              <div className="settings-group" key={group.name}>
                <h4 className="settings-group-heading">
                  {translate(group.label)}:
                </h4>
                {group.categories
                  ? group.categories.map((category, index) => {
                      if (category.requiresPremium && !userHasAccess) {
                        return null;
                      }

                      return (
                        <SettingsPageMenuItem
                          key={index}
                          active={category.name === currentCategory}
                          setMenuOpen={setMenuOpen}
                          {...category}
                        />
                      );
                    })
                  : null}
              </div>
            ))}
          </div>
          <div
            className="settings-page-menu-selector-underlay"
            onClick={() => {
              setMenuOpen(false);
            }}
          />
        </React.Fragment>
      )}
    </div>
  );
};

SettingsPageMenu.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      label: PropTypes.string,
      icon: PropTypes.string
    })
  ),
  currentCategory: PropTypes.string
};

export default compose(withUserData, withDeviceType)(SettingsPageMenu);
