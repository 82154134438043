import React from "react";
import { Col } from "reactstrap";
import Formsy from "formsy-react";
import ReCAPTCHA from "react-google-recaptcha";
import ContentSection from "../../../common/ContentSection";
import withContactForm from "../../../../../hocs/withContactForm";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import config from "../../../../../config";
import SquaberButton from "../../../common/SquaberButton";

import "../styles/default.scss";

class ContactPage extends React.Component {
  state = {
    recaptchaValue: null
  };

  setRecaptchaValue(value) {
    this.setState({
      recaptchaValue: value
    });
  }

  render() {
    const { translate, formBuilder, send } = this.props;
    const { recaptchaValue } = this.state;

    return (
      <div className="contact-page config-page-content">
        <Col md={{ size: 8, offset: 2 }}>
          <ContentSection>
            <h1>{translate("Contact us")}</h1>
            <div className="contact-additional-informations" dangerouslySetInnerHTML={{ __html: translate("contact__additional_informations") }} />
            <p>
              <strong>{translate("contact__company_name")}</strong>
              <span>{translate("contact__address_line_1")}</span>
              <span>{translate("contact__address_line_2")}</span>
              <span>{translate("contact__country")}</span>
            </p>
            <p>
              <a href={`mailto:${translate("contact__email_address")}`}>
                {translate("contact__email_address")}
              </a>
              <a href={`tel:${translate("contact__phone_number")}`}>
                {translate("contact__phone_number")}
              </a>
            </p>
            <p>
              <span>NIP: {translate("contact__nip")}</span>
              <span>REGON: {translate("contact__regon")}</span>
              <span>KRS: {translate("contact__krs")}</span>
            </p>
            <div className="contact-form">
              <Formsy
                ref={element => (this.form = element)}
                onValidSubmit={() => {
                  send(formBuilder.fields, recaptchaValue, this.form);
                }}
              >
                <FormBuilderWeb {...formBuilder} />
                <div className="contact-form-buttons-wrapper">
                  <ReCAPTCHA
                    sitekey={config.recaptchaKey}
                    onChange={value => {
                      this.setRecaptchaValue(value);
                    }}
                    onExpired={() => {
                      this.setRecaptchaValue(null);
                    }}
                  />
                  <SquaberButton
                    type="submit"
                    disabled={!formBuilder.canSubmit || !recaptchaValue}
                  >
                    {translate("Send")}
                  </SquaberButton>
                </div>
                <div
                  className="data-administrator-notice"
                  dangerouslySetInnerHTML={{
                    __html: translate("contact__data_administrator_notice")
                  }}
                />
              </Formsy>
            </div>
          </ContentSection>
        </Col>
      </div>
    );
  }
}

export default withContactForm(ContactPage);
