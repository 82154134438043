import React from "react";
import PropTypes from "prop-types";
import withSocialLogin from "../../../../../hocs/withSocialLogin";

import "../styles/default.scss";

const SocialLogin = ({ initSocialLogin, translate }) => (
  <div className="social-login">
    <div className="social-login-box" onClick={() => initSocialLogin("GOOGLE")}>
      <span className="icon">
        <i className="fab fa-google" />
      </span>
      <span className="description">{translate("Log in using Google")}</span>
    </div>
  </div>
);

SocialLogin.propTypes = {
  initSocialLogin: PropTypes.func
};

export default withSocialLogin(SocialLogin);
