import React from "react";
import { connect } from "../i18n/connect";
import { actions } from "../redux/modules/search";
import {
  getStocks,
  getPhrase,
  getLoading,
  getFetchMoreResultsUrl,
  getLoadingMore
} from "../redux/modules/search/selector";

export default function withSearchBar(WrappedComponent) {
  const mapStateToProps = state => ({
    loading: getLoading(state),
    loadingMore: getLoadingMore(state),
    stocks: getStocks(state),
    phrase: getPhrase(state),
    next: getFetchMoreResultsUrl(state)
  });

  const mapDispatchToProps = {
    searchAction: actions.search,
    clear: actions.clear,
    fetchMoreSearchResults: actions.fetchMoreSearchResults
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithSearchBar extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithSearchBar;
}
