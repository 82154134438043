import "../styles/default.scss";

import classNames from "classnames";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "redux";

import { UrlProvider } from "../../../../../api/UrlProvider";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withDeviceType from "../../../../../hocs/withDeviceType";
import withStocksListSourceSetter from "../../../../../hocs/withStocksListSourceSetter";
import withUserData from "../../../../../hocs/withUserData";
import navigateToDesktop from "../../../../../lib/navigateToDesktop";
import { showModal } from "../../../../../lib/react-redux-modal-provider";
import SquaberLink from "../../SquaberLink";
import Tag from "../../Tag";

const StockPageTags = ({
  tags = [],
  translate,
  locale,
  userData,
  userHasAccess,
  stockId,
  isMobileTagsList = false,
  updateStocksListSource,
  isMobile,
  setValue,
  hideModal,
  history
}) => {
  const tagsListRef = useRef(null);
  const [maxTagsInRow, setMaxTagsInRow] = useState(0);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isInitialized, setIsInitialized] = useState(false);

  const calculateMaxTagsInRow = () => {
    if (!tagsListRef.current) return;

    requestAnimationFrame(() => {
      const containerWidth = tagsListRef.current.offsetWidth;
      let totalWidth = 0;
      const padding = 4;
      const averageCharWidth = 8;
      let count = 0;

      tags.forEach(tag => {
        const textLength = tag.name.length;
        const estimatedWidth =
          textLength * averageCharWidth +
          2 * padding +
          (tag.is_premium ? 10 : 0);

        if (totalWidth + estimatedWidth < containerWidth) {
          totalWidth += estimatedWidth;
          count++;
        }
      });

      setMaxTagsInRow(count > 1 ? count - 1 : 1);
      setIsInitialized(true);
    });
  };

  useEffect(() => {
    window.addEventListener("resize", calculateMaxTagsInRow);
    calculateMaxTagsInRow();

    return () => {
      window.removeEventListener("resize", calculateMaxTagsInRow);
    };
  }, [tags, isExpanded]);

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const visibleTags =
    isExpanded || !isInitialized ? tags : tags.slice(0, maxTagsInRow);

  return (
    <div
      className={classNames("stock-page-tags", {
        "is-mobile-tags-list": isMobileTagsList,
        expanded: isExpanded
      })}
    >
      <div ref={tagsListRef} className="stock-page-tags__tags-list">
        {visibleTags.map(tag => (
          <Tag
            key={tag.id}
            {...tag}
            onClick={() => {
              if (isMobile) {
                updateStocksListSource(tag);
                setValue("watched");
              } else {
                navigateToDesktop(tag, history, locale);
              }
              if (hideModal) hideModal();
            }}
          />
        ))}
      </div>

      <div className="tags-controls">
        {tags.length - maxTagsInRow > 0 && (
          <button className="tag limit-control" onClick={toggleExpansion}>
            {translate(isExpanded ? "Show less" : "Show more")} (
            {tags.length - maxTagsInRow})
          </button>
        )}
        <div
          className="tag add-tag"
          onClick={() => showModal("StockTagsModal", { stockId })}
        >
          {translate("Add")}
        </div>
        <SquaberLink
          to={UrlProvider.getUrl("fe.settingsPageCategory", {
            locale,
            settingsCategory: "tags"
          })}
          className="tag settings-gear"
        >
          <i className="fas fa-cog"></i>
        </SquaberLink>
      </div>
    </div>
  );
};

StockPageTags.propTypes = {
  tags: PropTypes.array.isRequired
};

export default compose(
  withRouter,
  withUserData,
  withStocksListSourceSetter,
  withDeviceType,
  withBottomNavigation("bottomNavigationValue")
)(StockPageTags);
