import { useEffect, useState } from "react";

const useNavigationDynamicPaddingTop = (
  initialPadding = 60,
  adjustmentFactor = 60
) => {
  const [paddingTop, setPaddingTop] = useState(initialPadding);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      setPaddingTop(Math.max(0, adjustmentFactor - currentScrollY));
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [adjustmentFactor]);

  return paddingTop;
};

export default useNavigationDynamicPaddingTop;
