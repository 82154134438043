import React from "react";
import withSocialLoginHandler from "../../../../../hocs/withSocialLoginHandler";

import "../styles/default.scss";

const SocialRedirect = () => (
  <div className="social-redirect-page">
    <i className="fal fa-spinner fa-spin" />
  </div>
);

export default withSocialLoginHandler(SocialRedirect);
