import React from "react";
import moment from "moment-mini";
import Formsy from "formsy-react";
import PriceAlertsAbstract from "../../../../../abstract/PriceAlertsAbstract";
import withPriceAlerts from "../../../../../hocs/withPriceAlerts";
import withUserData from "../../../../../hocs/withUserData";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import Price from "../../../../../common/Price";
import SquaberButton from "../../SquaberButton";
import Loader from '../../Loader';

import "../styles/default.scss";

@withUserData
@withPriceAlerts
class PriceAlerts extends PriceAlertsAbstract {
  render() {
    const {
      translate,
      priceAlerts,
      priceAlertsLoading,
      currency,
      formBuilder
    } = this.props;

    return (
      <div className="price-alerts">
        <p>{translate("price-alerts-description-top")}</p>
        {priceAlertsLoading ? (
          <Loader small />
        ) : (
          <React.Fragment>
            {priceAlerts.length ? (
              <React.Fragment>
                <div>{translate("Your price alerts")}:</div>
                <ul>
                  {priceAlerts
                    .sort((a, b) => Number(a.price) - Number(b.price))
                    .map(alert => (
                      <li key={alert.id}>
                        <div>
                          <Price value={alert.price} currency={currency} />
                          {alert.sent_date ? (
                            <span className="notification-sent">
                              {translate(
                                "Notification sent on %{day} at %{hour}",
                                {
                                  day: moment(alert.sent_date).format(
                                    "DD.MM.YYYY"
                                  ),
                                  hour: moment(alert.sent_date).format(
                                    "HH:mm:SS"
                                  )
                                }
                              )}
                            </span>
                          ) : null}
                          {alert.sent_date ? (
                            <span
                              className="price-alert-action renew-alert"
                              onClick={() => {
                                this.renewAlert(
                                  alert.id,
                                  alert.price,
                                  alert.comment
                                );
                              }}
                            >
                              <i className="fal fa-redo" />
                            </span>
                          ) : null}
                          <span
                            className="price-alert-action remove-alert"
                            onClick={() => {
                              this.removeAlert(alert.id);
                            }}
                          >
                            <i className="far fa-trash-alt" />
                          </span>
                        </div>
                        {alert.comment && alert.comment.length ? (
                          <p className="alert-comment">{alert.comment}</p>
                        ) : null}
                      </li>
                    ))}
                </ul>
              </React.Fragment>
            ) : null}
          </React.Fragment>
        )}
        {}
        <div className="add-alert-wrapper">
          <p>
            <strong>{translate("Inform me when price will be equal")}:</strong>
          </p>
          <Formsy
            ref={element => (this.form = element)}
            onValidSubmit={() => {
              this.addAlert(formBuilder.fields, this.form, formBuilder.clear);
            }}
          >
            <FormBuilderWeb {...formBuilder} />
            <SquaberButton type="submit">
              {translate("Add alert")}
            </SquaberButton>
          </Formsy>
        </div>
        <p
          className="form-helper-text"
          dangerouslySetInnerHTML={{
            __html: translate("price-alerts-description-bottom")
          }}
        />
      </div>
    );
  }
}

export default PriceAlerts;
