import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import SquaberLink from "../../../common/SquaberLink";
import { UrlProvider } from "../../../../../api/UrlProvider";
import withTranslations from "../../../../../hocs/withTranslations";

import "../styles/default.scss";

const SettingsPageMenuItem = ({
  name,
  label,
  icon,
  active,
  locale,
  translate,
  setMenuOpen,
  isTrigger
}) => (
  <SquaberLink
    className={classNames({
      "settings-page-menu-item": true,
      active
    })}
    to={
      isTrigger
        ? "#!"
        : UrlProvider.getUrl("fe.settingsPageCategory", {
            locale,
            settingsCategory: name
          })
    }
    onClick={() => {
      setMenuOpen(isTrigger);
    }}
  >
    <i className={icon} />
    <span>{translate(label)}</span>
    {isTrigger ? <i className="far fa-angle-down trigger-indicator" /> : null}
  </SquaberLink>
);

SettingsPageMenuItem.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  active: PropTypes.bool
};

export default withTranslations(SettingsPageMenuItem);
