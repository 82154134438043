import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import "../styles/default.scss";

function SquaberCard({ children, className, onClick, ariaLabel }) {
  const hasOnClick = typeof onClick === "function";

  return (
    <div
      onClick={onClick}
      aria-label={ariaLabel}
      className={classNames(className, "squaber-card", {
        "squaber-card__click": hasOnClick
      })}
    >
      {children}
    </div>
  );
}

SquaberCard.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string
};

export default SquaberCard;
