import "../styles/default.scss";

import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";

import { getIsMobile } from "../../../../../lib/platformHelper";
import AlwaysScrollableDialogContent from "../../AlwaysScrollableDialogContent";
import ModalBase from "../../ModalBase";

class InterestingStockDescription extends ModalBase {
  getClassName(): null {
    return "squaber-interesting-stock-description-modal is-mobile-page-sheet";
  }

  getContent() {
    const { title, content, hideModal } = this.props;
    const className = "text-block interesting-stock-content";

    const contentWrapper = React.isValidElement(content) ? (
      <div className={className}>{content}</div>
    ) : (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: content }}
      />
    );

    return (
      <React.Fragment>
        <DialogTitle id="interesting-stock-dialog-title">
          <span className="title">{title}</span>
          <span className="close-dialog" onClick={() => hideModal()}>
            <i className="fal fa-times" />
          </span>
        </DialogTitle>
        {getIsMobile() ? (
          <>{contentWrapper}</>
        ) : (
          <AlwaysScrollableDialogContent>
            {contentWrapper}
          </AlwaysScrollableDialogContent>
        )}
      </React.Fragment>
    );
  }
}

export default InterestingStockDescription;
