import React from "react";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Formsy from "formsy-react";
import SquaberButton from "../../../common/SquaberButton";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import ModalBase from "../../ModalBase";
import withPortfolioAddEdit from "../../../../../hocs/withPortfolioAddEdit";

@withPortfolioAddEdit
class PortfolioDetailsModal extends ModalBase {
  getClassName() {
    return "is-mobile-page-sheet";
  }

  getContent() {
    const {
      translate,
      title,
      hideModal,
      formBuilder,
      execute,
      formState: { error, loading },
      clearValidationError
    } = this.props;

    return (
      <React.Fragment>
        <DialogTitle id="form-dialog-title">{title}</DialogTitle>
        <div
          className="close-page-sheet"
          onClick={() => {
            hideModal();
          }}
        >
          <i className="fal fa-times" />
        </div>
        <Formsy
          onValidSubmit={() => {
            execute();
          }}
          validationErrors={error}
        >
          <DialogContent>
            <FormBuilderWeb
              {...formBuilder}
              validationErrors={error}
              clearValidationError={clearValidationError}
            />
          </DialogContent>
          <DialogActions>
            <SquaberButton type="button" onClick={hideModal} color="secondary">
              {translate("Cancel")}
            </SquaberButton>
            <SquaberButton disabled={loading} type="submit" color="primary">
              {translate("Ok")}
            </SquaberButton>
          </DialogActions>
        </Formsy>
      </React.Fragment>
    );
  }
}

export default PortfolioDetailsModal;
