const getStockCardType = type => {
  switch (type) {
    case "results":
      return "simple";
    case "simple":
      return "simple";
    default:
      return "default";
  }
};

export default getStockCardType;
