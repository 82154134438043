import React from "react";
import { connect } from "../i18n/connect";
import { getCollapsibleSectionState } from "../redux/modules/collapsibleSections/selector";
import { toggleSectionCollapsed } from "../redux/modules/collapsibleSections/index";
import { actions as stockPageSectionsActions } from "../redux/modules/stockPageSections";

export default function withCollapsibleSection(WrappedComponent) {
  const mapStateToProps = (state, props) => ({
    sectionOpen: getCollapsibleSectionState(state, props.sectionId)
  });

  const mapDispatchToProps = {
    toggleSectionCollapsed,
    registerSection: stockPageSectionsActions.registerSection,
    unregisterSection: stockPageSectionsActions.unregisterSection
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithCollapsibleSection extends React.Component {
    componentDidMount() {
      const {
        registerSection,
        sectionId,
        title,
        isPremium,
        userHasAccess
      } = this.props;

      const userHasNoAccessToSection = isPremium && !userHasAccess;

      if (!userHasNoAccessToSection) {
        registerSection(sectionId, title);
      }
    }

    componentWillUnmount() {
      const {
        unregisterSection,
        sectionId,
        isPremium,
        userHasAccess
      } = this.props;

      const userHasNoAccessToSection = isPremium && !userHasAccess;

      if (!userHasNoAccessToSection) {
        unregisterSection(sectionId);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithCollapsibleSection;
}
