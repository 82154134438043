import DEFAULT_DRAWN_OBJECTS_OPTIONS from "../../../../lib/TvChart/defaultDrawnObjectsOptions";

export type ChartTemplatePayload = {
  id?: number,
  name?: string,
  is_default?: boolean,
  settings: {
    chart: { [key: string]: any },
    drawnObjectsOptions: { key: string, label: string, value: boolean }[]
  }
};
type DeprecatedChartTemplateDetails =
  | {
      layout: string,
      charts: any[],
      name?: string
    }
  | {
      chart: {
        layout: string,
        charts: any[],
        symbolLock?: Number,
        intervalLock?: Number,
        trackTimeLock?: Number,
        dateRangeLock?: Number,
        crosshairLock?: Number,
        layoutsSizes?: { s: any[] }
      },
      drawnObjectsOptions?: { key: string, label: string, value: boolean }[]
    };
type ChartTemplateDetails = {
  name?: string,
  is_default?: boolean,
  settings: {
    chart: { [key: string]: any },
    drawnObjectsOptions: { key: string, label: string, value: boolean }[]
  }
};
type FetchChartTemplateDetails = {
  chart: { [key: string]: any },
  drawn_objects_options: { key: string, label: string, value: boolean }[]
};

type FrontendChartTemplateDetails = {
  chart: { [key: string]: any },
  drawnObjectsOptions: { key: string, label: string, value: boolean }[]
};
export const toBackendChartTemplate = (
  data: ChartTemplatePayload
): ChartTemplateDetails => {
  let settings = {};

  if (data?.settings?.chart) {
    settings.chart = data.settings.chart;
  }

  if (data?.settings?.drawnObjectsOptions) {
    settings.drawn_objects_options = data.settings.drawnObjectsOptions;
  }

  return {
    ...(typeof data?.name === "string" ? { name: data.name } : {}),
    ...(typeof data?.is_default === "boolean"
      ? { is_default: data.is_default }
      : {}),
    ...(Object.keys(settings).length > 0 ? { settings } : {})
  };
};
export const toFrontendChartTemplateDetails = (
  data: DeprecatedChartTemplateDetails | FetchChartTemplateDetails
): FrontendChartTemplateDetails | undefined => {
  const isFetchChartTemplateDetails =
    data.chart && Array.isArray(data.drawn_objects_options);

  if (isFetchChartTemplateDetails) {
    return {
      chart: data.chart,
      drawnObjectsOptions: data.drawn_objects_options
    };
  }

  // First deprecated variant
  if (typeof data.layout === "string" && Array.isArray(data.charts)) {
    return {
      chart: { layout: data.layout, charts: data.charts },
      drawnObjectsOptions: DEFAULT_DRAWN_OBJECTS_OPTIONS
    };
  }

  // Second deprecated variant
  if (
    typeof data.chart.layout === "string" &&
    Array.isArray(data.chart.charts)
  ) {
    return {
      chart: { layout: data.chart.layout, charts: data.chart.charts },
      drawnObjectsOptions:
        data.drawnObjectsOptions ?? DEFAULT_DRAWN_OBJECTS_OPTIONS
    };
  }
};
