import classNames from "classnames";
import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import connect from "../../../../../../i18n/connect";
import useIsTablet from "../../../hooks/useIsTablet";
import CloseIcon from "../assets/close_svgrepo.com.svg";
import NavSquaberLogo from "../assets/frame_82.svg";
import SquaberLogo from "../assets/image.png";
import MarcinTuszkiewicz from "../assets/image_36.png";
import HamburgerIcon from "../assets/menu-1_svgrepo.com.svg";
import VideoShortcut from "./videoShortcut";

const NavMobile = ({ isOpen, setIsOpen, translate }) => {
  const isTablet = useIsTablet();
  const location = useLocation();

  useEffect(() => {
    setIsOpen(false);
  }, [location]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <nav
      className={classNames("w-full pt-4 pb-2", {
        "fixed bg-blue h-full z-10": isOpen,
        absolute: !isOpen,
        "px-10": isTablet,
        "px-5": !isTablet
      })}
    >
      <div className={"flex justify-between"}>
        <Link to={translate("lp_squaber-logo_href")}>
          <img src={NavSquaberLogo} alt={"lp_squaber-logo_alt"} />
        </Link>
        <button
          className={"bg-none b-none p-0"}
          onClick={() => {
            setIsOpen(prevState => !prevState);
          }}
        >
          {isOpen ? (
            <img src={CloseIcon} alt={translate("lp_close-menu-button_alt")} />
          ) : (
            <img
              src={HamburgerIcon}
              alt={translate("lp_open-menu-button_alt")}
            />
          )}
        </button>
      </div>
      <div
        hidden={!isOpen}
        className={"pt-6 mt-6 overflow-y-auto"}
        style={{ height: "calc(100% - 59px)" }}
      >
        <ul className={"text-xl flex flex-col gap-10 mb-0 pl-0 list-none"}>
          <li>
            <Link
              to={translate("lp_top-nav_link-1_href")}
              className={
                "text-white no-underline hover:text-yellow focus:text-yellow"
              }
            >
              {translate("lp_top-nav_link-1_text")}
            </Link>
          </li>
          <li>
            <Link
              to={translate("lp_top-nav_link-2_href")}
              className={
                "text-white no-underline hover:text-yellow focus:text-yellow"
              }
            >
              {translate("lp_top-nav_link-2_text")}
            </Link>
          </li>
          <li>
            <Link
              to={translate("lp_top-nav_link-3_href")}
              className={
                "text-white no-underline hover:text-yellow focus:text-yellow"
              }
            >
              {translate("lp_top-nav_link-3_text")}
            </Link>
          </li>
          <li>
            <Link
              to={translate("lp_top-nav_link-4_href")}
              className={
                "text-white no-underline hover:text-yellow focus:text-yellow"
              }
            >
              {translate("lp_top-nav_link-4_text")}
            </Link>
          </li>
          <li>
            <Link
              to={translate("lp_top-nav_link-5_href")}
              className={
                "text-white no-underline hover:text-yellow focus:text-yellow"
              }
            >
              {translate("lp_top-nav_link-5_text")}
            </Link>
          </li>
        </ul>
        <div className={"hr-horizontal my-10"} />
        <VideoShortcut
          className={"mb-6"}
          videoTitle={translate("lp_video-1_video-title")}
          videoHref={translate("lp_video-1_video-href")}
          channelImage={SquaberLogo}
          channelImageAlt={translate("lp_video-1_channel-image-alt")}
          subscriptionCount={translate("lp_video-1_subscription-count")}
        />
        <VideoShortcut
          videoTitle={translate("lp_video-2_video-title")}
          videoHref={translate("lp_video-2_video-href")}
          channelImage={MarcinTuszkiewicz}
          channelImageAlt={translate("lp_video-2_channel-image-alt")}
          subscriptionCount={translate("lp_video-2_subscription-count")}
        />
        <div className={"hr-horizontal my-10"} />
        <Link
          to={translate("lp_create-account-button_href")}
          className={"button button_full-width mb-6"}
        >
          {translate("lp_create-account-button_text")}
        </Link>
        <Link
          to={translate("lp_login-button_href")}
          className={"button button_full-width button_blue mb-6"}
        >
          {translate("lp_login-button_text")}
        </Link>
      </div>
    </nav>
  );
};

export default connect()(NavMobile);
