import { createRoutine, promisifyRoutine } from "redux-saga-routines";
import { createAction, handleActions } from "redux-actions";

export const UPDATE_STOCKS_LIST_SOURCE = "app/tags/UPDATE_STOCKS_LIST_SOURCE";
export const UPDATE_SINGLE_TAG_DETAILS = "app/tags/UPDATE_SINGLE_TAG_DETAILS";
export const ADD_TAG_IN_NEED_OF_REFRESH = "app/tags/addTagInNeedOfRefresh";
export const REMOVE_TAG_IN_NEED_OF_REFRESH =
  "app/tags/removeTagInNeedOfRefresh";

export const fetchStockTagsRoutine = createRoutine("app/tags/fetchStockTags");
export const fetchAllTagsRoutine = createRoutine("app/tags/fetchAllTags");
export const createNewTagRoutine = createRoutine("app/tags/createNewTag");
export const createNewTagPromiseCreator = promisifyRoutine(createNewTagRoutine);
export const connectTagToStockRoutine = createRoutine(
  "app/tags/connectTagToStock"
);
export const disconnectTagFromStockRoutine = createRoutine(
  "app/tags/disconnectTagFromStock"
);
export const connectTagToStockRoutinePromiseCreator = promisifyRoutine(
  connectTagToStockRoutine
);
export const disconnectTagFromStockRoutinePromiseCreator = promisifyRoutine(
  disconnectTagFromStockRoutine
);
export const fetchSingleTagDetailsRoutine = createRoutine(
  "app/tags/fetchSingleTagDetails"
);
export const editSingleTagDetailsRoutine = createRoutine(
  "app/tags/editSingleTagDetails"
);
export const deleteSingleTagRoutine = createRoutine(
  "app/tags/deleteSingleTagDetails"
);
export const editSingleTagDetailsRoutinePromiseCreator = promisifyRoutine(
  editSingleTagDetailsRoutine
);
export const deleteSingleTagRoutinePromiseCreator = promisifyRoutine(
  deleteSingleTagRoutine
);
export const fetchTagsWithQueryRoutine = createRoutine(
  "app/tags/fetchTagsWithQuery"
);

export const updateStocksListSource = createAction(
  UPDATE_STOCKS_LIST_SOURCE,
  (source: Object | null) => ({ source })
);

export const addTagInNeedOfRefresh = createAction(ADD_TAG_IN_NEED_OF_REFRESH);
export const updateSingleTagDetails = createAction(UPDATE_SINGLE_TAG_DETAILS);
export const removeTagInNeedOfRefresh = createAction(
  REMOVE_TAG_IN_NEED_OF_REFRESH
);

export const fetchStocksListFromSourceRoutine = createRoutine(
  "app/tags/fetchStocksListFromSource"
);
export const fetchAllAvailableTagsRoutine = createRoutine(
  "app/tags/fetchAllAvailableTags"
);
export const refreshStocksListSourceTagDetailsRoutine = createRoutine(
  "app/tags/refreshStocksListSourceTagDetails"
);

export const initialState = {
  stockTags: {
    loading: false,
    error: null,
    data: []
  },
  allTags: {
    loading: false,
    error: null,
    data: []
  },
  singleTagDetails: {
    loading: false,
    error: null,
    data: {}
  },
  tagsWithQuery: {
    loading: false,
    error: null,
    data: []
  },
  stocksList: {
    source: null,
    loading: false,
    error: null,
    data: []
  },
  allAvailableTags: {
    loading: false,
    error: null,
    data: []
  },
  tagsInNeedOfRefresh: []
};

export default handleActions(
  {
    [fetchStockTagsRoutine.TRIGGER]: state => ({
      ...state,
      stockTags: {
        ...state.stockTags,
        loading: true
      }
    }),
    [fetchStockTagsRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      stockTags: {
        loading: false,
        error: payload
      }
    }),
    [fetchStockTagsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      stockTags: {
        loading: false,
        error: null,
        data: payload
      }
    }),
    [fetchAllTagsRoutine.TRIGGER]: state => ({
      ...state,
      allTags: {
        ...state.allTags,
        loading: true
      }
    }),
    [fetchAllTagsRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      allTags: {
        loading: false,
        error: payload
      }
    }),
    [fetchAllTagsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      allTags: {
        loading: false,
        error: null,
        data: payload
      }
    }),
    [fetchSingleTagDetailsRoutine.TRIGGER]: state => ({
      ...state,
      singleTagDetails: {
        ...state.singleTagDetails,
        loading: true,
        data: {}
      }
    }),
    [fetchSingleTagDetailsRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      singleTagDetails: {
        loading: false,
        error: payload
      }
    }),
    [fetchSingleTagDetailsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      singleTagDetails: {
        loading: false,
        error: null,
        data: payload
      }
    }),
    [fetchTagsWithQueryRoutine.TRIGGER]: state => ({
      ...state,
      tagsWithQuery: {
        ...state.tagsWithQuery,
        loading: true
      }
    }),
    [fetchTagsWithQueryRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      tagsWithQuery: {
        ...state.tagsWithQuery,
        loading: false,
        error: payload
      }
    }),
    [fetchTagsWithQueryRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      tagsWithQuery: {
        loading: false,
        error: null,
        data: payload
      }
    }),
    [UPDATE_STOCKS_LIST_SOURCE]: (state, { payload }) => ({
      ...state,
      stocksList: {
        ...state.stocksList,
        source: payload.source
      }
    }),
    [ADD_TAG_IN_NEED_OF_REFRESH]: (state, { payload: id }) => {
      if (typeof id !== "number" && typeof id !== "string") return state;

      const newTagsInNeedOfRefresh = state.tagsInNeedOfRefresh;
      const entry = {
        id,
        created: new Date().getTime()
      };
      const entryIndex = newTagsInNeedOfRefresh.findIndex(
        ({ id: tagId }) => tagId == id
      );

      if (entryIndex === -1) {
        newTagsInNeedOfRefresh.push(entry);
      } else {
        newTagsInNeedOfRefresh[entryIndex] = entry;
      }

      return {
        ...state,
        tagsInNeedOfRefresh: newTagsInNeedOfRefresh
      };
    },
    [REMOVE_TAG_IN_NEED_OF_REFRESH]: (state, { payload: id }) => {
      if (typeof id !== "number" && typeof id !== "string") return state;

      const newTagsInNeedOfRefresh = state.tagsInNeedOfRefresh;
      const indexOfTagToRemove = newTagsInNeedOfRefresh.findIndex(
        ({ id: tagId }) => tagId == id
      );

      if (indexOfTagToRemove === -1) return state;

      newTagsInNeedOfRefresh.splice(indexOfTagToRemove, 1);

      return {
        ...state,
        tagsInNeedOfRefresh: newTagsInNeedOfRefresh
      };
    },
    [fetchStocksListFromSourceRoutine.TRIGGER]: state => ({
      ...state,
      stocksList: {
        ...state.stocksList,
        loading: true
      }
    }),
    [fetchStocksListFromSourceRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      stocksList: {
        ...state.stocksList,
        loading: false,
        error: payload
      }
    }),
    [fetchStocksListFromSourceRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      stocksList: {
        ...state.stocksList,
        loading: false,
        error: null,
        data: payload
      }
    }),
    [fetchAllAvailableTagsRoutine.TRIGGER]: state => ({
      ...state,
      allAvailableTags: {
        ...state.allAvailableTags,
        loading: true
      }
    }),
    [fetchAllAvailableTagsRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      allAvailableTags: {
        ...state.allAvailableTags,
        loading: false,
        error: payload
      }
    }),
    [fetchAllAvailableTagsRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      allAvailableTags: {
        ...state.allAvailableTags,
        loading: false,
        data: payload
      }
    }),
    [refreshStocksListSourceTagDetailsRoutine.SUCCESS]: (
      state,
      { payload }
    ) => ({
      ...state,
      stocksList: {
        ...state.stocksList,
        source: payload
      }
    }),
    [UPDATE_SINGLE_TAG_DETAILS]: (state, { payload }) => ({
      ...state,
      singleTagDetails: {
        loading: false,
        error: null,
        data: payload
      }
    })
  },
  initialState
);
