import Url from "url-parse";
import qs from "qs";

export default function prepareEmbeddedLandingPagUrl(url: string): string {
  const parsedUrl = new Url(url);
  const queryParams = qs.parse(parsedUrl.query, { ignoreQueryPrefix: true });

  queryParams.is_embedded_landing_page = "1";

  parsedUrl.query = qs.stringify(queryParams, { addQueryPrefix: true });

  return parsedUrl.toString();
}
