import { createAction } from "redux-actions";
import uuidv4 from "uuid/v4";

const defaultAlert = {
  actionLabel: "Ok",
  duration: 8000,
  translationVars: {}
};

export const ALERT_LEVELS = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info"
};

type Alert = {
  level: "success" | "error" | "warning" | "info",
  duration: ?number,
  title: string,
  actionLabel: ?string,
  actionCallback: ?Function,
  translationVars: ?Object
};

const namespace = "app/alerts";

const ADD_ALERT = `${namespace}/ADD_ALERT`;
const HIDE_ALERT = `${namespace}/HIDE_ALERT`;
const HIDE_CURRENT_ALERT = `${namespace}/HIDE_CURRENT_ALERT`;
const ATTEMPT_POP_ALERT = `${namespace}/ATTEMPT_POP_ALERT`;
const POP_ALERT = `${namespace}/POP_ALERT`;
const SET_QUEUE_RUNNING = `${namespace}/SET_QUEUE_RUNNING`;

export const constants = {
  ADD_ALERT,
  HIDE_ALERT,
  HIDE_CURRENT_ALERT,
  ATTEMPT_POP_ALERT,
  POP_ALERT,
  SET_QUEUE_RUNNING
};

const addAlert = createAction(ADD_ALERT, (alert: Alert) => {
  alert = {
    id: uuidv4(),
    visible: true,
    ...defaultAlert,
    ...alert
  };

  return { alert };
});

const addSuccessAlert = alert =>
  addAlert({
    ...alert,
    level: ALERT_LEVELS.SUCCESS
  });
const addErrorAlert = alert =>
  addAlert({
    ...alert,
    level: ALERT_LEVELS.ERROR
  });
const addWarningAlert = alert =>
  addAlert({
    ...alert,
    level: ALERT_LEVELS.WARNING
  });
const addInfoAlert = alert =>
  addAlert({
    ...alert,
    level: ALERT_LEVELS.INFO
  });

const hideCurrentAlert = createAction(HIDE_CURRENT_ALERT, () => ({}));
const hideAlert = createAction(HIDE_ALERT, (id: string) => ({ id }));
const attemptPopAlert = createAction(ATTEMPT_POP_ALERT, (id: string) => ({
  id
}));
const popAlert = createAction(POP_ALERT, (id: string) => ({
  id
}));
const setQueueRunning = createAction(SET_QUEUE_RUNNING, (running: boolean) => ({
  running
}));

export const actions = {
  addAlert,
  addSuccessAlert,
  addErrorAlert,
  addWarningAlert,
  addInfoAlert,
  hideAlert,
  hideCurrentAlert,
  attemptPopAlert,
  popAlert,
  setQueueRunning
};

const initialState = {
  queue: [],
  queueRunning: false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ALERT: {
      const shouldAddAlert = !state.queue.find(
        alert => alert.id === action.payload.alert.id
      );

      let newQueue = [...state.queue];

      if (shouldAddAlert) {
        newQueue.push(action.payload.alert);
      }

      return {
        ...state,
        queue: newQueue
      };
    }

    case HIDE_ALERT: {
      let newQueue = [...state.queue];

      let elementIndex = newQueue.findIndex(
        item => item.id === action.payload.id
      );

      if (elementIndex !== -1) {
        newQueue[elementIndex].visible = false;
      }

      return {
        ...state,
        queue: newQueue
      };
    }

    case HIDE_CURRENT_ALERT: {
      let newQueue = [...state.queue];
      newQueue[0].visible = false;

      return {
        ...state,
        queue: newQueue
      };
    }

    case POP_ALERT: {
      let newQueue = [...state.queue];
      newQueue.shift();

      return {
        ...state,
        queue: newQueue
      };
    }

    case SET_QUEUE_RUNNING:
      return {
        ...state,
        queueRunning: action.payload.running
      };

    default:
      return state;
  }
}
