import React from "react";
import RCPagination from "rc-pagination";
import PaginationAbstract from "../../../../../../abstract/PaginationAbstract";
import RowsPerPageSelect from "./RowsPerPageSelect";

import "./Pagination.scss";

class Pagination extends PaginationAbstract {
  render() {
    const totalItems = this.getTotalItems();
    const paginationState = this.getPaginationState();

    if (!totalItems) {
      return null;
    }

    return (
      <div className="universal-table-pagination">
        <RowsPerPageSelect
          value={paginationState.rowsPerPage}
          onChange={value => {
            this.setPaginationState({
              current: 1,
              rowsPerPage: value
            });
          }}
        />
        <RCPagination
          locale={"pl_PL"}
          total={totalItems}
          current={paginationState.current}
          pageSize={paginationState.rowsPerPage}
          showSizeChanger={false}
          onChange={value => {
            this.setPaginationState({
              current: value
            });
          }}
        />
      </div>
    );
  }
}

export default Pagination;
