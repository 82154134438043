import { createAction, handleActions } from "redux-actions";

const initialState = false;

export const SET_ADVANCED_CHART_VISIBLE =
  "app/advancedChartVisible/SET_ADVANCED_CHART_VISIBLE";

export const setAdvancedChartVisible = createAction(
  SET_ADVANCED_CHART_VISIBLE,
  (value: boolean) => ({ value })
);

export default handleActions(
  {
    [SET_ADVANCED_CHART_VISIBLE]: (state, { payload }) => payload.value
  },
  initialState
);
