import "../styles/default.scss";

import React, { useEffect, useState } from "react";
import { Col } from "reactstrap";

import ApiClientAsync from "../../../../../api/ApiClientAsync";
import withTranslations from "../../../../../hocs/withTranslations";
import responseStatus from "../../../../../lib/api/responseStatus";
import ContentSection from "../../../common/ContentSection";
import Loader from "../../../common/Loader";
import unsubscribeElectronicCommunication from "../lib/unsubscribeElectronicCommunication";
import unsubscribeMarketingEmail from "../lib/unsubscribeMarketingEmail";

function UnsubscribePage({ match, translate }) {
  const params: {
    locale?: string,
    userId?: string,
    hash?: string
  } = match.params ?? {};

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      const apiClient = new ApiClientAsync(params?.locale);

      const unsubscribeMarketingEmailPromise = unsubscribeMarketingEmail(
        apiClient,
        {
          hash: params.hash,
          userId: params.userId
        }
      );

      const unsubscribeElectronicCommunicationPromise = unsubscribeElectronicCommunication(
        apiClient,
        {
          hash: params.hash,
          userId: params.userId
        }
      );

      const [
        unsubscribedMarketingEmail,
        unsubscribedElectronicCommunicationPromise
      ] = await Promise.all([
        unsubscribeMarketingEmailPromise,
        unsubscribeElectronicCommunicationPromise
      ]);

      const success =
        unsubscribedMarketingEmail?.status === responseStatus.OK &&
        unsubscribedElectronicCommunicationPromise?.status ===
          responseStatus.OK;

      if (success) {
        setError(false);
      } else {
        setError(true);
      }

      setLoading(false);
    };

    fetch().then();
  }, []);

  if (loading) {
    return <Loader />;
  }

  return (
    <Col md={{ size: 8, offset: 2 }} className={"unsubscribe-page"}>
      <ContentSection>
        {error ? (
          <>
            <h1>
              {translate("unsubscribe_electronic_communication_error_title")}
            </h1>
            <p>
              {translate(
                "unsubscribe_electronic_communication_error_description"
              )}
            </p>
          </>
        ) : (
          <>
            <h1>{translate("unsubscribe_electronic_communication_title")}</h1>
            <p>
              {translate("unsubscribe_electronic_communication_description")}
            </p>
          </>
        )}
      </ContentSection>
    </Col>
  );
}

export default withTranslations(UnsubscribePage);
