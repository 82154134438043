import classNames from "classnames";
import React, { useMemo } from "react";

import SquaberLink from "../../../common/SquaberLink/modules/default";

function BaseLink({
  href,
  label,
  onEnter,
  onLeave,
  className,
  tabIndex,
  ...rest
}: {
  onEnter?: ({
    href: string,
    label: string,
    children: any,
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLLinkElement>
  }) => void,
  onLeave?: ({
    href: string,
    label: string,
    children: any,
    event:
      | React.MouseEvent<HTMLButtonElement>
      | React.MouseEvent<HTMLLinkElement>
  }) => void
}) {
  const commonProps = useMemo(() => {
    return {
      onMouseEnter: async event => {
        if (typeof onEnter !== "function") return;

        await onEnter({ href, label, event });
      },
      onMouseLeave: async event => {
        if (typeof onLeave !== "function") return;

        await onLeave({ href, label, event });
      },
      tabIndex,
      className: classNames("base-link", className)
    };
  }, [className, tabIndex, href, label, onEnter, onLeave]);

  if (href) {
    return (
      <SquaberLink {...rest} className={className} to={href} {...commonProps}>
        {label}
      </SquaberLink>
    );
  }

  return (
    <button {...rest} className={className} {...commonProps}>
      {label}
    </button>
  );
}

export default BaseLink;
