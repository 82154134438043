import React from "react";
import ContentSection from "../../ContentSection";
import SquaberTable from "../../SquaberTable";
import withTranslations from "../../../../../hocs/withTranslations";
import Price from "../../../../../common/Price";

import "../styles/default.scss";

const PortfolioStatistics = ({ translate, statistics }) => (
  <ContentSection className="portfolio-statistics">
    <h2 className="big-heading">{translate("Statistics")}</h2>
    <p>{translate("See the statistics of your portfolio.")}</p>
    <SquaberTable
      heading={[translate("Statistic"), translate("Value")]}
      rows={Object.keys(statistics).map(statisticKey => {
        let { key, value, unit } = statistics[statisticKey];

        if (unit === "%") {
          value *= 100;
        }

        return [
          translate(key),
          value
            ? new Price({
                value,
                currency: unit,
                roundToSecondNumber: true
              }).render()
            : "-"
        ];
      })}
    />
  </ContentSection>
);

PortfolioStatistics.propTypes = {};

export default withTranslations(PortfolioStatistics);
