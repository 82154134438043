import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import React from "react";

import TagsListWithSwitchersAbstract from "../../../../../abstract/TagsListWithSwitchersAbstract";
import withAllTagsList from "../../../../../hocs/withAllTagsList";
import withStockBasicInfo from "../../../../../hocs/withStockBasicInfo";
import TagsHelper from "../../../../../lib/TagsHelper/TagsHelper";

@withAllTagsList
@withStockBasicInfo
class TagsList extends TagsListWithSwitchersAbstract {
  render() {
    const {
      allTags: { loading, data },
      translate,
      stock
    } = this.props;

    return (
      <div>
        {loading ? (
          <div className="text-center">
            <i className="fal fa-spinner fa-spin" />
          </div>
        ) : null}
        {data.length
          ? data
              .filter(tag => TagsHelper.isUserTag(tag))
              .map(tag => {
                const tagIsActive = this.getTagIsActive(tag);

                return (
                  <FormControlLabel
                    key={tag.id}
                    className={"checkbox-wrapper"}
                    classes={{
                      label: "checkbox-label-wrapper"
                    }}
                    control={
                      <Checkbox
                        checked={tagIsActive}
                        onChange={() => {
                          this.onTagChange(tag, stock.stock.ticker);
                        }}
                        disabled={tag.is_admin_tag}
                      />
                    }
                    label={<span className={"checkbox-label"}>{tag.name}</span>}
                  />
                );
              })
          : null}
        {!loading && !data.length ? (
          <div className="no-tags-notice">
            {translate(
              "You have no tags. Create one using second tab in this view."
            )}
          </div>
        ) : null}
      </div>
    );
  }
}

export default TagsList;
