import envVariables from "./envHelpers/getEnvVariables";

const developmentMode = "development";

const logError = (message: any): void => {
  const isDevelopment =
    envVariables.TARGET_ENV === developmentMode ||
    envVariables.NODE_ENV === developmentMode;

  if (isDevelopment) console.error(message);
};

export default logError;
