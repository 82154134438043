import { put, takeEvery, fork } from "redux-saga/effects";
import wait from "../../../lib/wait";
import { constants, actions } from "./index";

// It has to be an object so it can be passed as reference to wait function
let timeouts = {};

function* onAddToQueue({ payload: { alert } }) {
  timeouts[alert.id] = { timeout: null };

  yield wait(alert.duration, timeouts[alert.id]);

  yield put(actions.attemptPopAlert(alert.id));
}

function* onAttemptPopAlert({ payload: { id } }) {
  clearTimeout(timeouts[id]);

  yield put(actions.hideAlert(id));
  yield wait(500);
  yield put(actions.popAlert(id));
}

function* watchAddToQueue() {
  yield takeEvery(constants.ADD_ALERT, onAddToQueue);
  yield takeEvery(constants.ATTEMPT_POP_ALERT, onAttemptPopAlert);
}

export default [fork(watchAddToQueue)];
