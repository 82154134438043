import alignMovingAverageOptions from "./alignMovingAverageOptions";

export const MA_STUDY_NAME = "Moving Average@tv-basicstudies";
export const EMA_STUDY_NAME = "Moving Average Exponential@tv-basicstudies";

export default function mergeDefaultTemplateWithCurrentChart(
  defaultTemplate,
  currentChart,
  ticker
) {
  const finalTemplate = JSON.parse(JSON.stringify(defaultTemplate));

  finalTemplate.charts[0].panes = finalTemplate.charts[0].panes
    .filter(pane => {
      let paneHasMainSeriesOrStudy = false;

      for (let source of pane.sources) {
        if (
          [
            "Study",
            "MainSeries",
            "study_ScriptWithDataOffset",
            "study_Volume"
          ].includes(source.type)
        ) {
          paneHasMainSeriesOrStudy = true;
          break;
        }
      }

      return paneHasMainSeriesOrStudy;
    })
    .map((pane, index) => {
      pane.sources = pane.sources.sort(a => {
        const sourceIsMainSeries = a.type === "MainSeries";

        if (sourceIsMainSeries) {
          return -1;
        }

        return 1;
      });

      pane.sources = pane.sources.map(source => {
        if (
          source.state.name === MA_STUDY_NAME ||
          source.state.name === EMA_STUDY_NAME
        ) {
          return alignMovingAverageOptions(source);
        }

        if (source.state.symbol) {
          source.state.symbol = ticker;
        }

        if (source.state.shortName) {
          source.state.shortName = ticker;
        }

        return source;
      });

      const correspondingCurrentChartPane = currentChart.charts[0].panes.find(
        currentChartPane => {
          return currentChartPane.sources[0].metaInfo &&
            pane.sources[0].metaInfo
            ? currentChartPane.sources[0].metaInfo.description ===
                pane.sources[0].metaInfo.description
            : currentChartPane.sources[0].type === pane.sources[0].type;
        }
      );

      if (correspondingCurrentChartPane) {
        const newPaneId = correspondingCurrentChartPane.sources[0].id;
        let correspondingCurrentChartPaneObjects = correspondingCurrentChartPane.sources
          .filter(
            source =>
              source.type !== "Study" &&
              source.type !== "MainSeries" &&
              source.type !== "study_ScriptWithDataOffset" &&
              source.type !== "study_Volume"
          )
          .map(source => ({
            ...source
          }));

        pane.sources.push(...correspondingCurrentChartPaneObjects);

        ["rightAxisesState", "leftAxisesState"].forEach(axisesState => {
          pane[axisesState] = pane[axisesState]
            .map((axis, index) => {
              const axisSources = axis.sources;
              axisSources.shift();

              return correspondingCurrentChartPane[axisesState][index]
                ? {
                    ...correspondingCurrentChartPane[axisesState][index],
                    state: {
                      // prettier-ignore
                      ...correspondingCurrentChartPane[axisesState][index].state,
                      ...axis.state
                    },
                    sources: [
                      // prettier-ignore
                      ...correspondingCurrentChartPane[axisesState][index].sources,
                      ...axisSources
                    ]
                  }
                : null;
            })
            .filter(axis => axis !== null);

          pane[axisesState].forEach((axis, index) => {
            axis.sources = pane[axisesState][index].sources;
          });
        });

        pane.mainSourceId = newPaneId;

        let mainSeries = pane.sources.find(
          source => source.type === "MainSeries"
        );

        if (mainSeries) {
          mainSeries.id = newPaneId;
          mainSeries.state.symbol = ticker;
          mainSeries.state.shortName = ticker;
        }
      }

      return pane;
    });

  return finalTemplate;
}
