import { createAction, handleActions } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

export const TOGGLE_ALERT = "app/stockData/TOGGLE_ALERT";
export const SET_ALERT = "app/stockData/SET_ALERT";
export const SET_PRICE_ALERTS = "app/stockData/SET_PRICE_ALERTS";
export const GET_MORE_RECOMMENDATIONS =
  "app/stockData/GET_MORE_RECOMMENDATIONS";
export const GET_MORE_TRANSACTION_SIGNALS =
  "app/stockData/GET_MORE_TRANSACTION_SIGNALS";
export const UPDATE_USER_NOTE = "app/stockData/UPDATE_USER_NOTE";
export const CLEAR_STOCK_DATA = "app/stockData/CLEAR_STOCK_DATA";

export const stockDataRoutine = createRoutine("app/stockData/GET_STOCK_DATA");

export const toggleAlert = createRoutine(TOGGLE_ALERT);
export const setAlert = createAction(SET_ALERT, (value: boolean) => ({
  value
}));
export const setPriceAlerts = createAction(
  SET_PRICE_ALERTS,
  (alerts: Array<Object>) => ({ alerts })
);

export const getMoreRecommendations = createRoutine(GET_MORE_RECOMMENDATIONS);
export const getMoreTransactionSignals = createRoutine(
  GET_MORE_TRANSACTION_SIGNALS
);
export const updateUserNote = createAction(
  UPDATE_USER_NOTE,
  (note: string) => ({ note })
);
export const clearStockData = createAction(CLEAR_STOCK_DATA, () => ({}));

export const initialState = {
  loading: false,
  recommendationsLoading: false,
  mediaMonitorEntriesLoading: false,
  transactionSignalsLoading: {},
  error: null,
  data: null
};

export default handleActions(
  {
    [stockDataRoutine.TRIGGER]: state => ({
      ...state,
      loading: true,
      error: null
    }),
    [stockDataRoutine.SUCCESS]: (state, action) => ({
      ...state,
      loading: false,
      data: { ...state.data, ...action.payload },
      error: null
    }),
    [stockDataRoutine.FAILURE]: (state, action) => ({
      ...state,
      loading: false,
      error: action.payload
    }),
    [toggleAlert.SUCCESS]: state => ({
      ...state,
    }),
    [SET_PRICE_ALERTS]: (state, action) => ({
      ...state,
      data: {
        ...state.data,
        price_alerts: action.payload.alerts
      }
    }),
    [SET_ALERT]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        user_stock: {
          ...state.data.user_stock,
          alerted: payload.value
        }
      }
    }),
    [getMoreRecommendations.TRIGGER]: state => ({
      ...state,
      recommendationsLoading: true
    }),
    [getMoreRecommendations.FAILURE]: state => ({
      ...state,
      recommendationsLoading: false
    }),
    [getMoreRecommendations.SUCCESS]: (state, { payload }) => ({
      ...state,
      recommendationsLoading: false,
      data: {
        ...state.data,
        recommendations: {
          ...state.data.recommendations,
          more_recommendations: payload.more_recommendations,
          recommendations: [
            ...state.data.recommendations.recommendations,
            ...payload.recommendations
          ]
        }
      }
    }),
    [getMoreTransactionSignals.TRIGGER]: (state, { payload }) => ({
      ...state,
      transactionSignalsLoading: {
        ...state.transactionSignalsLoading,
        [payload.strategyId]: true
      }
    }),
    [getMoreTransactionSignals.FAILURE]: (state, { payload }) => ({
      ...state,
      transactionSignalsLoading: {
        ...state.transactionSignalsLoading,
        [payload.strategyId]: false
      }
    }),
    [getMoreTransactionSignals.SUCCESS]: (state, { payload }) => {
      const newTransactionStrategies = [...state.data.transaction_strategies];
      newTransactionStrategies.find(
        strategy => strategy.id === payload.strategyId
      ).allTransactionSignalsShown = payload.data.next === null;

      return {
        ...state,
        transactionSignalsLoading: {
          ...state.transactionSignalsLoading,
          [payload.strategyId]: false
        },
        data: {
          ...state.data,
          transaction_strategies: newTransactionStrategies,
          transaction_signals: {
            ...state.data.transaction_signals,
            [payload.strategyId]: [
              ...state.data.transaction_signals[payload.strategyId],
              ...payload.data.results
            ]
          }
        }
      };
    },
    [UPDATE_USER_NOTE]: (state, { payload }) => ({
      ...state,
      data: {
        ...state.data,
        notice: {
          ...state.data.notice,
          text: payload.note
        }
      }
    }),
    [CLEAR_STOCK_DATA]: () => initialState
  },
  initialState
);
