import { createAction, handleActions } from "redux-actions";

export const FETCH_GLOBAL_CONFIG = "app/globalConfig/FETCH_GLOBAL_CONFIG";
export const SET_GLOBAL_CONFIG_VALUE =
  "app/globalConfig/SET_GLOBAL_CONFIG_VALUE";

export const fetchGlobalConfig = createAction(FETCH_GLOBAL_CONFIG, () => ({}));
export const setGlobalConfigValue = createAction(
  SET_GLOBAL_CONFIG_VALUE,
  (field: string, value: any) => ({ field, value })
);

export const initialState = {
  foo: "bar",
  fizz: "buzz"
};

export const globalConfigFields = {
  OFFER_URL: "offerUrl",
  AFFILIATE_PROGRAM_TERMS_AND_CONDITIONS: "affiliationTermsAndConditions",
  AFFILIATE_PROGRAM_TERMS_AND_CONDITIONS_PDF:
    "affiliationTermsAndConditionsPdf",
  UPCOMING_FEATURES: "upcomingFeatures",
  CHANGES_HISTORY: "changesHistory"
};

export default handleActions(
  {
    [SET_GLOBAL_CONFIG_VALUE]: (state, { payload }) => ({
      ...state,
      [payload.field]: payload.value
    })
  },
  initialState
);
