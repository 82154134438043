import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

interface PortfolioDeleteMetaData {
  portfolioId: number;
}

class PortfolioDeleteEvent extends BaseEvent {
  constructor(portfolioDeleteMetaData: PortfolioDeleteMetaData) {
    super();
    this.portfolioDeleteMetaData = portfolioDeleteMetaData;
  }

  getEventName() {
    return "portfolio_deleted";
  }

  getEventTriggerName() {
    return "Portfolio deletion by user";
  }

  getEventDetailsMatrix() {
    return `detail5=portfolio id`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: this.portfolioDeleteMetaData.portfolioId
    };
  }
}

export default PortfolioDeleteEvent;
