import { createAction, handleActions } from "redux-actions";
import { CLEAR_STOCK_DATA } from "../stockData";

const nameSpace = "app/priceAlerts";

const ADD_PRICE_ALERT = `${nameSpace}/ADD_PRICE_ALERT`;
const DELETE_PRICE_ALERT = `${nameSpace}/DELETE_PRICE_ALERT`;

const FETCH_PRICE_ALERTS = `${nameSpace}/FETCH_PRICE_ALERTS`;
const FETCH_PRICE_ALERTS_ERROR = `${nameSpace}/FETCH_PRICE_ALERTS_ERROR`;
const FETCH_PRICE_ALERTS_SUCCESS = `${nameSpace}/FETCH_PRICE_ALERTS_SUCCESS`;

export const constants = {
  ADD_PRICE_ALERT,
  DELETE_PRICE_ALERT,
  FETCH_PRICE_ALERTS,
  FETCH_PRICE_ALERTS_ERROR,
  FETCH_PRICE_ALERTS_SUCCESS
};

const addPriceAlert = createAction(
  ADD_PRICE_ALERT,
  (
    stockId: number,
    alertData: Object,
    form: Object,
    clearFormBuilder: Function
  ) => ({
    stockId,
    alertData,
    form,
    clearFormBuilder
  })
);

const deletePriceAlert = createAction(
  DELETE_PRICE_ALERT,
  (stockId: number, alertId: number, silent: boolean, callback: function) => ({
    stockId,
    alertId,
    silent,
    callback
  })
);

const fetchPriceAlerts = createAction(
  FETCH_PRICE_ALERTS,
  (stockId: number) => ({ stockId })
);

const fetchPriceAlertsSuccess = createAction(
  FETCH_PRICE_ALERTS_SUCCESS,
  (data: Array<Object>) => ({ data })
);

const fetchPriceAlertsError = createAction(
  FETCH_PRICE_ALERTS_ERROR,
  (error: any) => ({ error })
);

export const actions = {
  addPriceAlert,
  deletePriceAlert,
  fetchPriceAlerts,
  fetchPriceAlertsSuccess,
  fetchPriceAlertsError
};

const initialState = {
  loading: false,
  data: [],
  error: null
};

export default handleActions(
  {
    [FETCH_PRICE_ALERTS]: state => ({
      ...state,
      loading: true
    }),
    [FETCH_PRICE_ALERTS_SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload.data
    }),
    [FETCH_PRICE_ALERTS_ERROR]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload.error
    }),
    [CLEAR_STOCK_DATA]: () => initialState
  },
  initialState
);
