import { Firebase } from "../../redux/configureStore";
import getUtcTimestamp from "./getUtcTimestamp";
import SquaberIntervalTypes from "./SquaberIntervalTypes";

class SquaberTVQuotationNotifier {
  subscribers = {};
  quoteWatchers = {};

  constructor(stockId, iterateOverIntervals) {
    this.stockId = stockId;
    this.iterateOverIntervals = iterateOverIntervals;

    for (let intervalTypeId of Object.values(SquaberIntervalTypes)) {
      this.subscribers[intervalTypeId] = {};
      this.quoteWatchers[intervalTypeId] = {};
    }
  }

  subscribe(market, ticker, intervalTypeId, notifyCallback) {
    const quoteRef = Firebase.database()
      .ref("quotes")
      .child(this.stockId)
      .child(intervalTypeId);

    this.quoteWatchers[intervalTypeId][this.stockId] = quoteRef;

    quoteRef.on("value", quoteSnapshot => {
      if (!quoteSnapshot) {
        return;
      }

      let quoteData = quoteSnapshot.val();

      setTimeout(() => {
        quoteData.time = getUtcTimestamp(new Date(quoteData.datetime_utc));
        this.notifyObservers(this.stockId, quoteData);
      });
    });

    this.subscribers[intervalTypeId][this.stockId] = notifyCallback;
  }

  unsubscribe(market, ticker, intervalTypeId) {
    this.quoteWatchers?.[intervalTypeId]?.[this.stockId]?.off("value");
    delete this.subscribers?.[intervalTypeId]?.[this.stockId];
  }

  notifyObservers(stockId, quote) {
    if (this.iterateOverIntervals) {
      const result = [];

      for (let intervalTypeId of Object.keys(this.subscribers)) {
        result.push(this.notifyObserver(stockId, quote, intervalTypeId));
      }

      return result;
    } else {
      return this.notifyObserver(
        stockId,
        quote,
        Number(quote["interval_type_id"])
      );
    }
  }

  notifyObserver(stockId, quote, intervalTypeId) {
    if (this.subscribers[intervalTypeId][stockId]) {
      return this.subscribers[intervalTypeId][stockId](quote);
    }
  }
}

export default SquaberTVQuotationNotifier;
