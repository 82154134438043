import { put, takeLatest, fork, select } from "redux-saga/effects";
import {
  acceptUserMessageRoutine,
  fetchUserMessagesRoutine,
  skipUserMessageRoutine
} from "./index";
import ApiClient from "../../../api/ApiClient";

function* onFetchUserMessages() {
  try {
    const { data } = yield ApiClient.get("app.auth.userMessages");

    yield put(fetchUserMessagesRoutine.success(data));
  } catch (error) {
    yield put(fetchUserMessagesRoutine.failure(error));
  }
}

function* onSkipUserMessage({ payload: { messageId } }) {
  try {
    yield ApiClient.get({
      urlPath: "app.auth.skipUserMessage",
      variables: { messageId }
    });
  } catch (error) {
    console.log(error);
  }
}

function* onAcceptUserMessage({ payload: { messageId } }) {
  try {
    yield ApiClient.get({
      urlPath: "app.auth.acceptUserMessage",
      variables: { messageId }
    });
  } catch (error) {
    console.log(error);
  }
}

function* watchGetUserMessages() {
  yield takeLatest(fetchUserMessagesRoutine.TRIGGER, onFetchUserMessages);
  yield takeLatest(skipUserMessageRoutine.TRIGGER, onSkipUserMessage);
  yield takeLatest(acceptUserMessageRoutine.TRIGGER, onAcceptUserMessage);
}

export default [fork(watchGetUserMessages)];
