export const getStockData = state => state.stockData.data;
export const getStockDataErrorResponse = state =>
  state.stockData.error && state.stockData.error.response
    ? state.stockData.error.response
    : null;
export const getStockDataLoading = state => state.stockData.loading;
export const getRecommendationsLoading = state =>
  state.stockData.recommendationsLoading;
export const getTransactionSignalsLoading = state =>
  state.stockData.transactionSignalsLoading;
export const getStockDataPriceAlerts = state => {
  const priceAlerts = state.stockData?.data?.price_alerts;

  if (typeof priceAlerts === "undefined") return [];
  return priceAlerts;
};
