import { createAction, handleActions } from "redux-actions";

export const namespace = "app/nativeSceneHandler";

export const SET_CURRENT_SCENE = `${namespace}/SET_CURRENT_SCENE`;
export const SET_PREVIOUS_SCENE = `${namespace}/SET_PREVIOUS_SCENE`;
export const HANDLE_SCENE_CHANGE = `${namespace}/HANDLE_SCENE_CHANGE`;

export const setCurrentScene = createAction(
  SET_CURRENT_SCENE,
  (scene: string) => ({ scene })
);
export const setPreviousScene = createAction(
  SET_PREVIOUS_SCENE,
  (scene: string) => ({ scene })
);
export const handleSceneChange = createAction(
  HANDLE_SCENE_CHANGE,
  (action: Object) => ({ action })
);

export const initialState = {
  currentScene: null,
  previousScene: null
};

export default handleActions(
  {
    [SET_CURRENT_SCENE]: (state, { payload }) => ({
      ...state,
      previousScene: state.currentScene,
      currentScene: payload.scene
    })
  },
  initialState
);
