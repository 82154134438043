import React from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind";

export default class DoubleButtonSwitcherAbstract extends React.Component {
  static propTypes = {
    labels: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.bool.isRequired
  };

  static defaultProps = {
    value: false
  };

  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  handleChange(newValue) {
    const { value, onChange } = this.props;

    if (newValue !== value) {
      onChange(newValue);
    }
  }

  getOptions() {
    const { labels, value } = this.props;

    return [false, true].map((optionValue, index) => ({
      label: labels[index],
      value: optionValue,
      isActive: value === optionValue,
      isFirst: index === 0,
      isLast: index === labels.length - 1
    }));
  }
}
