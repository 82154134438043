import ApiClientAsync from "../../api/ApiClientAsync";
import UniversalTableQueryBuilder from "./UniversalTableQueryBuilder";
import hash from "object-hash";

export default class DataInterceptorBase {
  constructor(locale, authToken, options = {}) {
    this.apiClient = new ApiClientAsync(locale, authToken);
    this.queryBuilder = new UniversalTableQueryBuilder({});
    this.cache = {};
    this.options = options;
  }

  clearCache() {
    this.cache = {};
  }

  async fetchConfig(id) {
    throw new Error("Not implemented");
  }

  async fetchTabWithOptions(id, currentTabId, tableState) {
    throw new Error("Not implemented");
  }

  async fetchNextPage(url) {
    throw new Error("Not implemented");
  }

  async makeRequest(options) {
    const requestHash = hash(options);

    let response;

    if (this.cache[requestHash]) {
      response = this.cache[requestHash];
    } else {
      try {
        response = await this.apiClient.get(options);

        if (response.results) {
          response.results = this.transformResults(response.results);
        }

        this.cache[requestHash] = response;
      } catch (e) {
        console.error(e);
      }
    }

    return response;
  }

  transformResults(results) {
    return results;
  }
}
