import React from "react";
import connect from "../i18n/connect";
import { getAlertedTagDetails } from "../redux/modules/followed/selector";
import { getAlertedTagDetailsRoutine } from "../redux/modules/followed";

export default function withAlertedTagDetails(WrappedComponent) {
  const mapStateToProps = state => ({
    alertedTagDetails: getAlertedTagDetails(state)
  });
  const mapDispatchToProps = {
    fetchAlertedTagDetails: getAlertedTagDetailsRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithAlertedTagDetails extends React.Component {
    componentDidMount() {
      this.props.fetchAlertedTagDetails();
    }

    render() {
      const { alertedTagDetails } = this.props;

      if (!alertedTagDetails) {
        return null;
      }

      return <WrappedComponent {...this.props} />;
    }
  }

  return WithAlertedTagDetails;
}
