import envVariables from "../lib/envHelpers/getEnvVariables";
import deepMerge from "../lib/DeepMerge";
import * as configs from "./modules/";

let config = configs.default;

let generalConfigKey = `${envVariables.VENDOR}_default`;
let specificConfigKey = `${envVariables.VENDOR}_${envVariables.TARGET_ENV ||
  envVariables.NODE_ENV}`;

let vendorConfig = deepMerge(
  configs[generalConfigKey],
  configs[specificConfigKey]
);

config = deepMerge(config, vendorConfig);

export default config;
