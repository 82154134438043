import autoBind from "auto-bind";
import { isEqual } from "lodash";
import React from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { withGoogleReCaptcha } from "react-google-recaptcha-v3";

import config from "../../config";
import { connect } from "../../i18n/connect";
import {
  clearData,
  fetchHistoryListRoutine,
  fetchNextPageRoutine
} from "../../redux/modules/historyList";
import { getInterestingStocksState } from "../../redux/modules/historyList/selector";
import typeUrlPatterns from "./typeUrlPatterns";

export default function withWebHistoryList(type) {
  return WrappedComponent => {
    const mapStateToProps = state => ({
      historyListState: getInterestingStocksState(state)
    });

    const mapDispatchToProps = {
      fetchHistoryList: fetchHistoryListRoutine,
      fetchNextPage: fetchNextPageRoutine,
      clearData
    };

    @withGoogleReCaptcha
    @connect(mapStateToProps, mapDispatchToProps)
    class WithWebHistoryList extends React.Component {
      state = {
        mounted: false,
        loadedRecaptcha: false,
        refreshReCaptcha: false
      };

      constructor(props) {
        super(props);
        autoBind.react(this);

        this.reCaptchaRef = React.createRef();
      }

      async componentDidMount(): void {
        this.props.clearData();

        this.setState({
          mounted: true
        });
      }

      async componentDidUpdate(
        prevProps: Readonly<P>,
        prevState: Readonly<S>,
        snapshot: SS
      ): void {
        const {
          filterByStock,
          filterByCategoryIds,
          googleReCaptchaProps
        } = this.props;
        const {
          filterByStock: oldFilterByStock,
          filterByCategoryIds: oldFilterByCategoryIds,
          googleReCaptchaProps: oldGoogleReCaptchaProps
        } = prevProps;

        const { mounted } = this.state;
        const { mounted: oldMounted } = prevState;

        const loadingRecaptcha =
          typeof this.reCaptchaRef?.current?.executeAsync !== "function";

        if (this.canUseRecaptcha() && loadingRecaptcha) {
          return;
        }

        if (
          !isEqual(mounted, oldMounted) ||
          !isEqual(filterByStock, oldFilterByStock) ||
          !isEqual(filterByCategoryIds, oldFilterByCategoryIds) ||
          !isEqual(googleReCaptchaProps, oldGoogleReCaptchaProps)
        ) {
          await this.getData();
        }
      }

      getUrlPath() {
        const { filterByStock } = this.props;
        let finalType = type;

        if (filterByStock) {
          finalType += "-filtered";
        }

        return typeUrlPatterns[finalType];
      }

      async getData() {
        const {
          fetchHistoryList,
          filterByStock,
          filterByCategoryIds
        } = this.props;

        const urlPath = this.getUrlPath();
        const canUseRecaptcha = this.canUseRecaptcha();

        const reCaptchaToken = canUseRecaptcha
          ? await this.handleVerifyRecaptcha()
          : undefined;

        fetchHistoryList({
          apiMethod: canUseRecaptcha ? "post" : "get",
          urlPath,
          filterByStock,
          filterByCategoryIds,
          reCaptchaToken
        });
      }

      async handleFetchNextPage() {
        const { fetchNextPage } = this.props;

        const canUseRecaptcha = this.canUseRecaptcha();

        const reCaptchaToken = canUseRecaptcha
          ? await this.handleVerifyRecaptcha()
          : undefined;

        fetchNextPage({
          reCaptchaToken,
          apiMethod: canUseRecaptcha ? "post" : "get"
        });
      }

      handleVerifyRecaptcha = async () => {
        if (typeof this?.reCaptchaRef?.current?.executeAsync !== "function") {
          console.error("Recaptcha has not been loaded");
          return;
        }

        return await this.reCaptchaRef.current.executeAsync();
      };

      canUseRecaptcha() {
        return ["tpsl-signals"].includes(type);
      }

      render() {
        const { mounted } = this.state;

        const component = (
          <WrappedComponent
            {...this.props}
            fetchNextPage={this.handleFetchNextPage}
          />
        );

        return (
          <>
            {mounted ? component : null}
            <ReCAPTCHA
              badge={"inline"}
              ref={this.reCaptchaRef}
              sitekey={config.googleReCaptcha.sitekey}
              size={"invisible"}
            />
          </>
        );
      }
    }

    return WithWebHistoryList;
  };
}
