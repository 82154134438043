import React from "react";

import connect from "../../i18n/connect";
import { defaultUserAction } from "../../redux/modules/userActions";

export default function withTriggerUserActions(WrappedComponent) {
  const mapDispatchToProps = {
    triggerDefaultUserAction: defaultUserAction
  };

  @connect(null, mapDispatchToProps)
  class WithUserActions extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithUserActions;
}
