import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

interface MarketingBarMetaData {
  id: number;
  text: string;
  url: string;
  action: "CTA" | "close";
}

class MarketingBarEvent extends BaseEvent {
  constructor(marketingBars: MarketingBarMetaData) {
    super();
    this.marketingBars = marketingBars;
  }

  getEventName() {
    return "marketing_bar_activity";
  }

  getEventTriggerName() {
    return "Marketing bar activity";
  }

  getEventDetailsMatrix() {
    return `detail5=marketing bar id
detail6=marketing bar text
detail7=marketing bar CTA url
detail8=action`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: String(this.marketingBars.id),
      detail6: this.marketingBars.text.substring(0, 255),
      detail7: this.marketingBars.url,
      detail8: this.marketingBars.action
    };
  }
}
export default MarketingBarEvent;
