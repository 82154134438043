import React from "react";
import "../styles/default.scss";

const TagsSettingsSearchBox = ({
  label,
  value = undefined,
  onChange,
  onFocus,
  onBlur
}) => (
  <div className="tags-settings-search-box">
    <input
      type="text"
      value={value}
      placeholder={label}
      onChange={onChange}
      onFocus={onFocus}
      onBlur={onBlur}
    />
    <i className="fal fa-search" />
  </div>
);

TagsSettingsSearchBox.propTypes = {};

export default TagsSettingsSearchBox;
