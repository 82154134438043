import React from "react";
import withUpdateCreditCard from "../../../../../hocs/withUpdateCreditCard";
import withPaymentDetails from "../../../../../hocs/withPaymentDetails";
import PaymentMethodBoxAbstract from "../../../../../abstract/PaymentMethodBoxAbstract";
import ContentSection from "../../../common/ContentSection";
import SquaberButton from "../../../common/SquaberButton";
import CardMockup from "../../../common/CardMockup";

@withUpdateCreditCard
@withPaymentDetails
class PaymentMethodBox extends PaymentMethodBoxAbstract {
  render() {
    const { translate, updateCreditCard, paymentDetails } = this.props;
    const { showCardMockup, cardModel, canUpdateCreditCard } = this.state;

    return (
      <div className="payment-method-box">
        <ContentSection>
          <h2 className="big-heading">{translate("Payment method")}</h2>
          <p>
            {translate(
              canUpdateCreditCard
                ? "You have chosen recurring payment method. You can change or update it below."
                : "You have chosen recurring payment method. You can change it below."
            )}
          </p>
          <div className="buttons-wrapper">
            {canUpdateCreditCard ? (
              <SquaberButton
                onClick={() => {
                  this.setShowCardMockup(!showCardMockup);
                }}
              >
                {translate("Update")}
              </SquaberButton>
            ) : null}
            <SquaberButton
              color="secondary"
              onClick={() => {
                this.props.releasePayment(paymentDetails.user_offer_id);
              }}
            >
              {translate("Change payment method")}
            </SquaberButton>
          </div>
          {showCardMockup ? (
            <React.Fragment>
              <CardMockup
                {...cardModel}
                onValueChange={this.setCardModelValue}
              />
              <div className="buttons-wrapper update-card-buttons">
                <SquaberButton
                  onClick={() => {
                    updateCreditCard({ cardModel });
                  }}
                >
                  {translate("Update")}
                </SquaberButton>
                <SquaberButton
                  color="secondary"
                  onClick={() => this.setShowCardMockup(false)}
                >
                  {translate("Cancel")}
                </SquaberButton>
              </div>
            </React.Fragment>
          ) : null}
        </ContentSection>
      </div>
    );
  }
}

export default PaymentMethodBox;
