import appendDecimalZerosIfNeeded from "../../appendDecimalZerosIfNeeded";
import HelperWithFormattingBase from "./HelperWithFormattingBase";

export class NumberHelper extends HelperWithFormattingBase {
  renderMethods = {
    number_default: this.renderDefault,
    number_abbreviated: this.renderAbbreviated,
    number_abbreviated_with_dot: this.renderAbbreviatedWithDot,
    number_whole: this.renderWhole,
    number_whole_number: this.renderWhole,
    number_percent: this.renderPercent,
    number_two_decimal: this.renderTwoDecimal
  };

  shouldAbbreviate(value) {
    return value >= 1000;
  }

  getAbbreviatedValue(value) {
    let finalValue;

    if (value >= 1e9) {
      finalValue = `${this.transformFloatingValue(value / 1e9, 1)}B`;
    } else if (value >= 1e6) {
      finalValue = `${this.transformFloatingValue(value / 1e6, 1)}M`;
    } else {
      finalValue = `${this.transformFloatingValue(value / 1e3, 1)}k`;
    }

    return finalValue;
  }

  getFinalValue(value, canAbbreviate = true, shouldAlwaysUseToFixed = false) {
    if (isNaN(value)) {
      return value;
    }

    if (canAbbreviate && this.shouldAbbreviate(value)) {
      return this.getAbbreviatedValue(value);
    } else {
      return this.transformFloatingValue(value, 2, shouldAlwaysUseToFixed);
    }
  }

  transformFloatingValue(number, decimalPlaces, shouldAlwaysUseToFixed) {
    const shouldUseToFixed =
      shouldAlwaysUseToFixed || Math.floor(number) !== number;

    if (!shouldUseToFixed) {
      return number;
    }

    const formattedToSpecifiedDecimalPlace = Number(number).toFixed(
      decimalPlaces
    );
    const removedTrailingZeros = Number(
      formattedToSpecifiedDecimalPlace
    ).toString();

    return appendDecimalZerosIfNeeded({ value: removedTrailingZeros });
  }

  renderDefault(value) {
    return this.transformFloatingValue(value, 6);
  }

  renderAbbreviated(value) {
    if (this.shouldAbbreviate(value)) {
      return this.getAbbreviatedValue(value).replace(".", ",");
    } else {
      return this.renderDefault(value);
    }
  }

  renderAbbreviatedWithDot(value) {
    if (this.shouldAbbreviate(value)) {
      return this.getAbbreviatedValue(value);
    } else {
      return this.renderDefault(value);
    }
  }

  renderWhole(value) {
    return parseInt(Number(value));
  }

  renderPercent(value) {
    // TODO Use ChangePercent component to color it accordingly
    return `${value}%`;
  }

  renderTwoDecimal(value) {
    return value.toFixed(2);
  }
}

export default new NumberHelper();
