import { createAction } from "redux-actions";
import { LOCATION_CHANGE } from "react-router-redux";

export const initialState = {
  error: false,
  errorStatus: null,
  userDataUpdateReturnedUnexpectedError: false
};

const SET_ERROR = "app/status/SET_ERROR";
const CLEAR_ERROR = "app/status/CLEAR_ERROR";
const SET_USER_DATA_UPDATE_ERROR = "app/status/SET_USER_DATA_UPDATE_ERROR";

export const setError = createAction(SET_ERROR, (status: number) => ({
  status
}));
export const clearError = createAction(CLEAR_ERROR, () => ({}));
export const setUserDataUpdateError = createAction(
  SET_USER_DATA_UPDATE_ERROR,
  (value: boolean) => ({ value })
);

export default function appReducer(state = initialState, action) {
  switch (action.type) {
    case SET_ERROR: {
      return {
        ...state,
        error: true,
        errorStatus: action.payload.status
      };
    }

    case CLEAR_ERROR:
    case LOCATION_CHANGE: {
      return initialState;
    }

    case SET_USER_DATA_UPDATE_ERROR: {
      return {
        ...state,
        userDataUpdateReturnedUnexpectedError: action.payload.value
      };
    }

    default:
      return state;
  }
}
