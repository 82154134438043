import React, { useEffect } from "react";
import { useHistory } from "react-router";

import AnalysisPersistenceLayer from "./utils/AnalysisPersistenceLayer";
import useAnalysisPersistenceLayer from "./utils/useAnalysisPersistenceLayer";

const analysisPersistenceLayer = new AnalysisPersistenceLayer(localStorage);

export default function withAnalysisPersistenceLayerWeb(Component) {
  return function WrappedComponent(props) {
    const analysisPersistenceLayerProps = useAnalysisPersistenceLayer({
      analysisPersistenceLayer
    });
    const history = useHistory();

    useEffect(() => {
      const search = history.location.search;

      if (search && typeof search === "string") {
        analysisPersistenceLayerProps
          .restoreAnalysisFromString(search.slice(1))
          .then(value => {
            if (typeof value === "undefined") {
              return;
            }

            history.replace({
              pathname: history.location.pathname,
              search: analysisPersistenceLayer.purgeString(search)
            });
          });
      }
    }, [history.location.search]);

    return <Component {...props} {...analysisPersistenceLayerProps} />;
  };
}
