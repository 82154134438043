import React from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind";
import config from "../config";

class MediaMonitorAbstract extends React.Component {
  static propTypes = {
    data: PropTypes.shape({
      messages: PropTypes.array,
      is_last_page: PropTypes.bool
    })
  };

  constructor(props) {
    super(props);

    autoBind.react(this);
  }

  onButtonClick(offset: number) {
    const {
      data: { messages },
      ticker,
      market,
      getMoreMediaMonitorEntries
    } = this.props;

    getMoreMediaMonitorEntries({
      start: typeof offset === "number" ? offset : messages.length,
      count: config.recommendationsToFetch,
      ticker,
      market
    });
  }
}

export default MediaMonitorAbstract;
