import { createRoutine } from "redux-saga-routines";
import { handleActions } from "redux-actions";

export const getTransactionHistoryRoutine = createRoutine(
  "app/transactionHistory/GET_TRANSACTION_HISTORY"
);

const initialState = {
  loading: true,
  error: false,
  data: null
};

export default handleActions(
  {
    [getTransactionHistoryRoutine.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [getTransactionHistoryRoutine.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: false,
      data: payload.payments
    }),
    [getTransactionHistoryRoutine.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    })
  },
  initialState
);
