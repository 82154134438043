import React from "react";
import PropTypes from "prop-types";
import NotificationItem from "../../NotificationItem";
import { Link } from "react-router-dom";
import classNames from "classnames";
import withTranslations from "../../../../../hocs/withTranslations";
import "../styles/default.scss";

const NotificationList = ({
  messages,
  fetchNotificationsAction,
  markAsReadAction,
  unreadCount,
  onToggle,
  translate
}) => (
  <div
    className={classNames({
      "notification-list": true,
      "unread-notifications": unreadCount > 0
    })}
  >
    <div className="menu top-menu">
      <Link
        to={"#"}
        onClick={event => {
          event.preventDefault();
          markAsReadAction();
        }}
      >
        <i className="far fa-check-double" />
        <span>{translate("Mark all as read")}</span>
      </Link>
    </div>
    <div className="items">
      {messages &&
        messages.map((item, key) => (
          <NotificationItem
            key={`notification-item-${key}`}
            item={item}
            markAsReadAction={markAsReadAction}
            onToggle={onToggle}
            read={item.read}
          />
        ))}
    </div>
    <div className="menu bottom-menu">
      <Link
        to={"#"}
        onClick={event => {
          event.preventDefault();
          fetchNotificationsAction(true);
        }}
      >
        <i className="far fa-sync" />
        <span>{translate("Load more notifications")}</span>
      </Link>
    </div>
  </div>
);

NotificationList.propTypes = {
  messages: PropTypes.array,
  onToggle: PropTypes.func,
  fetchNotificationsAction: PropTypes.func.isRequired,
  markAsReadAction: PropTypes.func.isRequired,
  unreadCount: PropTypes.number
};

export default withTranslations(NotificationList);
