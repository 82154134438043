import { getIsWeb, getIsIos } from "../../lib/platformHelper";
import { isIphoneX } from "react-native-iphone-x-helper";
import * as Device from "expo-device";

const getTabBarBottomMargin = () => {
  if (getIsWeb()) {
    return 0;
  }

  if (
    isIphoneX() &&
    (Device.modelId === "iPhone13,1" || Device.modelId === "iPhone14,4")
  ) {
    return -10;
  } else if (isIphoneX()) {
    return 10;
  } else {
    return 0;
  }
};

const breakpoints = {
  screen_xs: 480,
  screen_sm: 768,
  screen_md: 992,
  screen_lg: 1200
};

const colors = {
  primary: "#F1C12C",
  success: "#12B886",
  error: "#F03E3E",
  errorBackground: "#FDE4E4",
  errorText: "#BA0E0E",
  warning: "#FDD02E",
  info: "#6FA6CD",
  baseGrey: "#A8B1C1",
  baseGreyDark: "#878CAB",
  baseGreyDark1: "#8797a5",
  baseGreyDark2: "#7e8595",
  baseGreyDark3: "#878DAA",
  baseGreyLight: "#EBF2F8",
  baseGreyLightTransparent: "#EBF2F800",
  baseGreyLight1: "#F2F2F2",
  baseGreyLight8: "#ecf0f3",
  baseGreyDarkBg: "#878DAA",
  baseGrey2: "#1C1F2F",
  baseNavy: "#262A3F",
  warningText: "#D1A700",
  tagDefault: "#73a7ca",
  tagAccent: "#c7dcea",
  portfolioTag: "#8797A4",
  tableRowColor: "#F6F7FD"
};

export default {
  colors,
  breakpoints,
  nativeBaseIndent: 20,
  baseTextStyle: {
    fontSize: 13,
    fontWeight: "500",
    color: colors.baseGreyDark
  },
  snackbarDefaults: {
    color: "#FFFFFF",
    background: "#484848",
    accent: "rgba(255, 255, 255, 0.8)"
  },
  button: {
    default: {
      color: getIsWeb() ? colors.baseGrey2 : "white",
      boxShadow: "none",
      borderRadius: 3
    },
    primary: {
      backgroundColor: colors.warning
    },
    primary_small: {
      backgroundColor: colors.warning,
      fontSize: 14,
      height: 35,
      paddingTop: 0,
      paddingBottom: 0
    },
    secondary: {
      backgroundColor: colors.baseGreyLight,
      color: colors.baseGrey2
    },
    secondary_small: {
      backgroundColor: colors.baseGreyLight,
      color: colors.baseGrey2,
      fontSize: 12,
      height: 28,
      paddingTop: 0,
      paddingBottom: 0
    },
    tertiary: {
      fontSize: 14,
      height: 35,
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: colors.baseGrey,
      fontWeight: "700",
      color: colors.baseNavy
    },
    tertiary_thin: {
      fontSize: 14,
      height: 35,
      paddingTop: 0,
      paddingBottom: 0,
      backgroundColor: colors.baseGrey,
      color: colors.baseNavy
    },
    tertiary_big: {
      fontSize: 14,
      height: 45,
      backgroundColor: colors.baseGrey,
      fontWeight: "700",
      color: colors.baseNavy
    },
    dark: {
      fontSize: 11,
      height: 20,
      backgroundColor: colors.baseGreyDarkBg,
      color: "white",
      borderRadius: 2
    },
    disabled: {
      opacity: 0.5
    }
  },
  navigation: {
    tabBar: {
      background: "#FFFFFF",
      height: 50,
      marginBottom: getTabBarBottomMargin()
    },
    tab: {
      height: 40,
      marginTop: 5,
      padding: 5,
      paddingLeft: 2,
      paddingRight: 2,
      textAlign: "center",
      borderLeftWidth: 1,
      borderLeftStyle: "solid",
      borderLeftColor: colors.baseGreyLight
    },
    icon: {
      size: 20,
      style: {
        color: colors.baseGrey,
        width: 18,
        height: 22,
        marginTop: 10,
        marginBottom: 15
      },
      colors: {
        default: colors.baseGrey,
        active: "#333"
      }
    },
    badge: {
      wrapper: {
        position: "absolute",
        left: 30,
        top: -6,
        backgroundColor: colors.warning,
        width: 16,
        height: 16,
        paddingLeft: getIsIos() ? 1 : 0,
        alignItems: "center",
        justifyContent: "center",
        borderRadius: 50
      },
      text: {
        marginTop: 0,
        letterSpacing: -1,
        fontWeight: "500",
        fontSize: 9,
        color: "#FFFFFF"
      }
    },
    label: {
      fontSize: 8,
      letterSpacing: -0.6,
      fontWeight: "500"
    },
    labelColor: {
      default: colors.baseGreyDark,
      active: "#222"
    }
  }
};
