import moment from "moment-mini";

import config from "../../config";
import getUtcTimestamp from "./getUtcTimestamp";
import SquaberIntervalMap from "./SquaberIntervalMap";
import SquaberIntervalTypes from "./SquaberIntervalTypes";

const SquaberSymbolInfo = stock => ({
  name: stock.ticker,
  ticker: stock.ticker,
  market: stock.market,
  description: stock.short_name
    .toString()
    .replace(/<.*?>/, " ")
    .trim(),
  type: stock.is_index ? "index" : "stock",
  session: "24x7",
  exchange: stock.market,
  timezone: "UTC",
  pricescale: Math.round(1 / parseFloat(stock.pipsize)),
  minmov: stock.multiplier,
  has_intraday: config.tradingViewOptions.hasIntraday,
  has_seconds: false,
  has_daily: true,
  has_weekly_and_monthly: true,
  has_empty_bars: false,
  fractional: false,
  force_session_rebuild: false,
  visible_plots_set: !stock.hasVolume ? "ohlc" : "ohlcv",
  has_fractional_volume: false,
  data_status: "delayed_streaming",
  expired: false,
  currency_code: stock.currency,
  sector: stock.basic_info.sector
});

class TVDataFeed {
  constructor(
    stock,
    quotationsGetter: Promise,
    quotationsNotifier,
    shapeManager,
    translate,
    intervals,
    latestQuoteDatetimeUtc
  ) {
    this.stock = stock;
    this.quotationsGetter = quotationsGetter;
    this.quotationsNotifier = quotationsNotifier;
    this.shapeManager = shapeManager;
    this.translate = translate;
    this.intervals = intervals;
    this.latestQuoteDatetimeUtc = latestQuoteDatetimeUtc;
  }

  _lastQuotations = {};
  marks = [];
  lastFrom = null;
  lastTo = null;
  cachedQuotations = [];
  lastFromWithData = null;
  countBackPossible = true;

  setDataFeed(
    stock,
    quotationsGetter: Promise,
    quotationsNotifier,
    shapeManager,
    translate,
    intervals,
    latestQuoteDatetimeUtc
  ) {
    this.stock = stock;
    this.quotationsGetter = quotationsGetter;
    this.quotationsNotifier = quotationsNotifier;
    this.shapeManager = shapeManager;
    this.translate = translate;
    this.intervals = intervals;
    this.latestQuoteDatetimeUtc = latestQuoteDatetimeUtc;
  }

  _getRealTimeCallback(onRealtimeCallback, resolution) {
    const getNextUTCMonday = () => {
      const now = new Date();
      const nextMonday = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate() + (8 - now.getDay())
      );
      return getUtcTimestamp(nextMonday);
    };

    const getNextUTCMonth = () => {
      const now = new Date();
      const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1);
      return getUtcTimestamp(nextMonth);
    };

    const isInPeriod = quote => {
      if (SquaberIntervalMap[resolution] === SquaberIntervalTypes.daily)
        return (
          quote.time ===
          this._lastQuotations[SquaberIntervalMap[resolution]].time
        );

      if (SquaberIntervalMap[resolution] === SquaberIntervalTypes.weekly)
        return quote.time < getNextUTCMonday();

      if (SquaberIntervalMap[resolution] === SquaberIntervalTypes.monthly)
        return quote.time < getNextUTCMonth();

      throw Error("Unknown interval: #{resolution}.");
    };

    const transformQuote = quote => {
      for (let item of ["open", "high", "low", "close", "volume", "stock_id"])
        quote[item] = Number(quote[item]);

      if (config.quoteConvertersInUse && isInPeriod(quote)) {
        const _lastQuotation = this._lastQuotations[
          SquaberIntervalMap[resolution]
        ];
        _lastQuotation.volume =
          _lastQuotation.volume -
          this._lastQuotations[SquaberIntervalTypes.daily].volume +
          quote.volume;
        _lastQuotation.high = Math.max(_lastQuotation.high, quote.high);
        _lastQuotation.low = Math.min(_lastQuotation.low, quote.low);
        _lastQuotation.close = quote.close;
      } else {
        this._lastQuotations[SquaberIntervalMap[resolution]] = quote;
      }

      if (config.quoteConvertersInUse) {
        this._lastQuotations[SquaberIntervalTypes.daily] = quote;
      }

      return this._lastQuotations[SquaberIntervalMap[resolution]];
    };

    return quote => onRealtimeCallback(transformQuote(quote));
  }

  onReady(callback) {
    const configurationData = {
      supports_search: false,
      supported_resolutions: this.intervals,
      supports_marks: true
    };

    setTimeout(() => callback(configurationData), 0);
  }

  searchSymbolsByName(userInput, exchange, symbolType, onResultReadyCallback) {
    return null;
  }

  resolveSymbol(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
    if (symbolName !== this.stock.ticker) {
      onResolveErrorCallback(
        "SquaberTVDataFeed can operate only on stock on which it was instantiated."
      );
    }

    setTimeout(
      () => onSymbolResolvedCallback(SquaberSymbolInfo(this.stock, config)),
      0
    );
  }

  getBars(
    symbolInfo,
    resolution,
    periodParams,
    onHistoryCallback,
    onErrorCallback
  ) {
    let promise = this.quotationsGetter({
      stockId: this.stock.id,
      interval: SquaberIntervalMap[resolution],
      from: periodParams.from,
      to: periodParams.to,
      stock: this.stock,
      shapeManager: this.shapeManager,
      latestQuoteDatetimeUtc: this.latestQuoteDatetimeUtc,
      firstDataRequest: periodParams.firstDataRequest,
      countBack: this.countBackPossible ? periodParams.countBack : null
    });

    promise.then(
      quotations => {
        if (periodParams.countBack > quotations.length) {
          this.countBackPossible = false;
        }

        for (let quotation of quotations) {
          if (
            !this._lastQuotations[SquaberIntervalMap[resolution]] ||
            this._lastQuotations[SquaberIntervalMap[resolution]].time <
              quotation.time
          ) {
            this._lastQuotations[SquaberIntervalMap[resolution]] = quotation;
          }
        }

        const meta = {};

        if (quotations.length) {
          this.lastFromWithData = periodParams.from;
        } else {
          const daysWithoutQuotations = moment
            .unix(this.lastFromWithData)
            .diff(moment.unix(periodParams.from), "days");

          if (daysWithoutQuotations >= config.maxDaysWithoutQuotations) {
            meta.noData = true;
          }
        }

        let quotationsForCallback = quotations;

        onHistoryCallback(quotationsForCallback, meta);

        this.lastFrom = periodParams.from;
        this.lastTo = periodParams.to;
      },
      () => onErrorCallback("Failed to fetch quotations.")
    );
  }

  subscribeBars(
    symbolInfo,
    resolution,
    onRealtimeCallback,
    subscriberUID,
    onResetCacheNeededCallback
  ) {
    this.quotationsNotifier.subscribe(
      this.stock.market,
      this.stock.ticker,
      SquaberIntervalMap[resolution],
      this._getRealTimeCallback(onRealtimeCallback, resolution)
    );
  }

  unsubscribeBars(subscriberUID) {
    const splitSubscriberUID = subscriberUID.split("_");
    const intervalKey = splitSubscriberUID[splitSubscriberUID.length - 1];

    this.quotationsNotifier.unsubscribe(
      this.stock.market,
      this.stock.ticker,
      SquaberIntervalMap[intervalKey]
    );
  }

  calculateHistoryDepth(resolution, resolutionBack, intervalBack) {
    return null;
  }

  getMarks(symbolInfo, startDate, endDate, onDataCallback, resolution) {
    return onDataCallback(this.marks);
  }

  getTimescaleMarks(
    symbolInfo,
    startDate,
    endDate,
    onDataCallback,
    resolution
  ) {
    return null;
  }

  getServerTime(callback) {
    return null;
  }

  setMarks(marks) {
    this.marks = marks;
  }
}

export default TVDataFeed;
