export function isNumberWithMaximumTwoDecimalDigits(values, value) {
  const transformedValue = value.replace(",", ".");

  if (isNaN(transformedValue)) {
    return true;
  }

  const splitValue = transformedValue.split(".");

  if (splitValue[1]) {
    return transformedValue.split(".")[1].length <= 2;
  }

  return true;
}
