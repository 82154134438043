import React from "react";
import { connect } from "../i18n/connect";
import withFormBuilder from "./withFormBuilder";
import { actions as authActions } from "../redux/modules/auth/login";
import { getUserEditValidationErrors } from "../redux/modules/auth/login/selector";

export default function withEditUserProfileForm(WrappedComponent) {
  const mapStateToProps = state => ({
    validationErrors: getUserEditValidationErrors(state)
  });

  const mapDispatchToProps = {
    editUserData: authActions.editUserData,
    clearValidationError: authActions.clearUserEditValidationError
  };

  const fields = props => [
    {
      name: "username",
      label: "Login",
      type: "text",
      value: props.userData.username,
      fullWidth: true
    },
    {
      name: "first_name",
      label: "First name",
      type: "text",
      value: props.userData.first_name,
      fullWidth: true
    },
    {
      name: "last_name",
      label: "Last name",
      type: "text",
      value: props.userData.last_name,
      fullWidth: true
    },
    {
      name: "email",
      label: "E-mail",
      type: "text",
      value: props.userData.email,
      fullWidth: true,
      validations: {
        isEmail: true
      },
      validationErrors: {
        isEmail: "Your email seems to be invalid"
      }
    },
    {
      name: "phone",
      label: "Phone number",
      type: "text",
      value: props.userData.phone,
      fullWidth: true
    },
    {
      name: "password_old",
      label: "Password",
      type: "password",
      value: "",
      fullWidth: true
    }
  ];

  @connect(mapStateToProps, mapDispatchToProps)
  @withFormBuilder(fields)
  class WithEditUserProfileForm extends React.Component {
    state = {};

    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithEditUserProfileForm;
}
