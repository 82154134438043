import React from "react";
import connect from "../i18n/connect";
import { getStockIsFollowed } from "../redux/modules/followed/selector";

export default function withStockIsFollowed(
  stockIdExtractor = props => props.stock_id
) {
  return WrappedComponent => {
    const mapStateToProps = (state, props) => ({
      stockIsFollowed: getStockIsFollowed(state, stockIdExtractor(props))
    });

    @connect(mapStateToProps)
    class WithStockIsFollowed extends React.Component {
      render() {
        return <WrappedComponent {...this.props} />;
      }
    }

    return WithStockIsFollowed;
  };
}
