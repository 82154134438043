import { createAction } from "redux-actions";
import config from "../../../config";
import { getIsWeb } from "../../../lib/platformHelper";

function getLocaleFromUrl() {
  if (getIsWeb()) {
    let locationArray = window.location.pathname.split("/");
    let selectedLocale = locationArray[1];
    if (
      selectedLocale &&
      config.supportedLocales.indexOf(selectedLocale) !== -1
    ) {
      return selectedLocale;
    } else {
      return config.defaultLocale;
    }
  } else {
    return config.defaultLocale;
  }
}

const namespace = "app/translations";

const FETCH_TRANSLATIONS = `${namespace}/FETCH_TRANSLATIONS`;
const FETCH_TRANSLATIONS_ERROR = `${namespace}/FETCH_TRANSLATIONS_ERROR`;
const FETCH_TRANSLATIONS_SUCCESS = `${namespace}/FETCH_TRANSLATIONS_SUCCESS`;
const RESET_TRANSLATIONS = `${namespace}/RESET_TRANSLATIONS`;
const SET_LOCALE = `${namespace}/SET_LOCALE`;
const FETCH_MARKET_TRANSLATIONS = `${namespace}/FETCH_MARKET_TRANSLATIONS`;
const FETCH_MARKET_TRANSLATIONS_ERROR = `${namespace}/FETCH_MARKET_TRANSLATIONS_ERROR`;
const FETCH_MARKET_TRANSLATIONS_SUCCESS = `${namespace}/FETCH_MARKET_TRANSLATIONS_SUCCESS`;

export const constants = {
  FETCH_TRANSLATIONS,
  FETCH_TRANSLATIONS_ERROR,
  FETCH_TRANSLATIONS_SUCCESS,
  RESET_TRANSLATIONS,
  SET_LOCALE,
  FETCH_MARKET_TRANSLATIONS,
  FETCH_MARKET_TRANSLATIONS_ERROR,
  FETCH_MARKET_TRANSLATIONS_SUCCESS
};

const fetchTranslations = createAction(FETCH_TRANSLATIONS, () => ({}));
const fetchTranslationsError = createAction(
  FETCH_TRANSLATIONS_ERROR,
  (error: Object) => ({ error })
);
const fetchTranslationsSuccess = createAction(
  FETCH_TRANSLATIONS_SUCCESS,
  (locale: string, data: Object) => ({ locale, data })
);
const resetTranslations = createAction(RESET_TRANSLATIONS, () => ({}));
const setLocale = createAction(SET_LOCALE, (locale: string) => ({ locale }));

const fetchMarketTranslations = createAction(
  FETCH_MARKET_TRANSLATIONS,
  () => ({})
);

const fetchMarketTranslationsError = createAction(
  FETCH_MARKET_TRANSLATIONS_ERROR,
  (error: Object) => ({ error })
);

const fetchMarketTranslationsSuccess = createAction(
  FETCH_MARKET_TRANSLATIONS_SUCCESS,
  (data: Object) => ({ data })
);

export const actions = {
  fetchTranslations,
  fetchTranslationsError,
  fetchTranslationsSuccess,
  resetTranslations,
  setLocale,
  fetchMarketTranslations,
  fetchMarketTranslationsError,
  fetchMarketTranslationsSuccess
};

const initialState = {
  fetchCnt: 0,
  translations: {},
  locale: getLocaleFromUrl(),
  marketTranslations: {}
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        fetchCnt: state.fetchCnt + 1,
        translations: {
          ...state.translations,
          [action.payload.locale]: action.payload.data
        }
      };
    case FETCH_MARKET_TRANSLATIONS_SUCCESS:
      return {
        ...state,
        marketTranslations: {
          ...state.marketTranslations,
          ...action.payload.data
        }
      };

    case RESET_TRANSLATIONS:
      return {
        ...state,
        translations: {}
      };

    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload.locale
      };

    default:
      return state;
  }
}
