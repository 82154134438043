import { createRoutine } from "redux-saga-routines";

export const actionHandlerRoutine = createRoutine(
  "app/actionHandler/HANDLE_ACTION"
);

const initialState = {};

export default function reducer() {
  return initialState;
}
