import { takeLatest, fork, put } from "redux-saga/effects";
import ApiClient from "../../../api/ApiClient";
import { initTagsServiceRoutine } from "./index";

export function* initTagsService() {
  try {
    const { data } = yield ApiClient.get("app.tags.allAvailableTags");

    yield put(initTagsServiceRoutine.success(data));
  } catch (e) {}
}

function* tagsServiceSaga() {
  yield takeLatest(initTagsServiceRoutine, initTagsService);
}

export default [fork(tagsServiceSaga)];
