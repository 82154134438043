import React from "react";
import connect from "../i18n/connect";
import {
  getCurrentOffer,
  getCurrentOfferLoading,
  getPaymentDetails,
  getPaymentDetailsLoading
} from "../redux/modules/payment/selector";
import {
  fetchCurrentOfferRoutine,
  paymentDetailsRoutine
} from "../redux/modules/payment";
import autoBind from "auto-bind";

export default function withPaymentDetails(WrappedComponent) {
  const mapStateToProps = state => ({
    paymentDetails: getPaymentDetails(state),
    loading: getPaymentDetailsLoading(state),
    currentOffer: getCurrentOffer(state),
    currentOfferLoading: getCurrentOfferLoading(state)
  });

  const mapDispatchToProps = {
    fetchPaymentDetails: paymentDetailsRoutine,
    fetchCurrentOffer: fetchCurrentOfferRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithPaymentDetails extends React.Component {
    constructor(props) {
      super(props);

      autoBind.react(this);
    }

    fetchPaymentDetails() {
      this.props.fetchPaymentDetails();
      this.props.fetchCurrentOffer();
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          fetchPaymentDetails={this.fetchPaymentDetails}
        />
      );
    }
  }

  return WithPaymentDetails;
}
