import "../styles/default.scss";

import DialogActions from "@material-ui/core/DialogActions";
import classNames from "classnames";
import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import SquaberButton from "../../../common/SquaberButton";
import ModalBase from "../../ModalBase";

@withTranslations
class PageSheetBase extends ModalBase {
  fullscreen = true;

  getContent() {
    const {
      hideModal,
      confirmButtonText,
      cancelButtonText,
      onConfirm,
      onCancel,
      message
    } = this.props;

    return (
      <div
        className={classNames({
          "page-sheet-base": true,
          "scrollable-table-wrapper": true,
          "is-mobile-page-sheet": true
        })}
      >
        <div
          className="close-page-sheet"
          onClick={() => {
            onCancel?.();
            hideModal?.();
          }}
        >
          <i className="fal fa-times" />
        </div>
        <div className={"page-sheet-base__content"}>
          {message}
          <DialogActions className={"page-sheet-base__actions"}>
            {cancelButtonText ? (
              <SquaberButton
                onClick={() => {
                  onCancel?.();
                  hideModal?.();
                }}
                color="secondary"
              >
                {cancelButtonText}
              </SquaberButton>
            ) : null}
            {confirmButtonText ? (
              <SquaberButton
                onClick={() => {
                  onConfirm?.();
                  hideModal?.();
                }}
                color="primary"
              >
                {confirmButtonText}
              </SquaberButton>
            ) : null}
          </DialogActions>
        </div>
      </div>
    );
  }
}

export default PageSheetBase;
