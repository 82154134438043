import { withFormsy } from "formsy-react";
import React from "react";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import withStyles from "@material-ui/core/styles/withStyles";
import connect from "../../../i18n/connect";

const styles = theme => ({
  formControl: {
    margin: theme.spacing()
  }
});

/**
 * @deprecated
 */
class ComposedTextField extends React.Component {
  constructor(props) {
    super(props);
    this.changeValue = this.changeValue.bind(this);
    this.onFocus = this.onFocus.bind(this);
    this.onBlur = this.onBlur.bind(this);
  }

  static defaultProps = {
    propagateEvent: false,
    focusValueDiffer: false
  };

  state = {
    name: "ComposedTextField",
    inputFocus: false,
    dirtyInput: false
  };

  changeValue(event) {
    const { propagateEvent } = this.props;

    if (this.props.value !== event.currentTarget.value) {
      this.props.setValue(event.currentTarget.value);
    }

    if (this.props.onChange) {
      this.props.onChange(propagateEvent ? event : event.currentTarget.value);
    }

    if (
      this.props.onValidChange &&
      this.props.isValidValue(event.currentTarget.value)
    ) {
      this.props.onValidChange(
        propagateEvent ? event : event.currentTarget.value
      );
    }
  }

  onFocus(e) {
    this.setState({
      inputFocus: true
    });

    if (this.props.onFocus) {
      this.props.onFocus(e);
    }
  }

  onBlur(e) {
    this.setState({
      inputFocus: false,
      dirtyInput: true
    });

    if (this.props.onBlur) {
      this.props.onBlur(e);
    }
  }

  shouldShowError() {
    const { inputFocus } = this.state;
    const {
      value,
      isValid,
      isRequired,
      isPristine,
      error,
      showErrorOnFocus
    } = this.props;

    // Deconstructed return statement to fix warnings from linter

    const inputIsNotValid = Boolean(!isValid && value && value.length > 0);
    const requiredInputIsEmpty = Boolean(
      isRequired && !isPristine && value && value.length === 0
    );
    const inputIsForcedToShowError = Boolean(error);
    const inputShouldShowError = Boolean(!inputFocus || showErrorOnFocus);

    return (
      (inputIsNotValid || requiredInputIsEmpty || inputIsForcedToShowError) &&
      inputShouldShowError &&
      !this.shouldShowRequired()
    ); // Show only one error at a time
  }

  shouldShowRequired() {
    const { inputFocus, dirtyInput } = this.state;
    const {
      value,
      showRequired,
      isRequired,
      isPristine,
      showErrorOnFocus,
      error
    } = this.props;

    const requiredInputIsEmpty = Boolean(
      isRequired &&
        showRequired &&
        (!isPristine || dirtyInput) &&
        (value === null || value.length === 0)
    );
    const inputIsForcedToShowError = Boolean(error || requiredInputIsEmpty);
    const inputShouldShowError =
      Boolean(!inputFocus || showErrorOnFocus) && isRequired;

    return (
      (requiredInputIsEmpty || inputIsForcedToShowError) && inputShouldShowError
    );
  }

  render() {
    const {
      value: valueFromProps,
      classes,
      name,
      label,
      fullWidth,
      required,
      requiredText,
      focusValueDiffer,
      focusValue,
      blurValue,
      validationErrors,
      translate,
      errorMessage,
      ...rest
    } = this.props;

    const unnecessaryProps = [
      `setValidations`,
      `setValue`,
      `resetValue`,
      `hasValue`,
      `innerRef`,
      `errorMessage`,
      `errorMessages`,
      `getErrorMessage`,
      `getErrorMessages`,
      `isFormDisabled`,
      `isValid`,
      `isPristine`,
      `isFormSubmitted`,
      `isRequired`,
      `showRequired`,
      `showError`,
      `isValidValue`,
      `validations`,
      `validationErrors`,
      `validationError`,
      `translate`,
      `dispatch`,
      "showErrorOnFocus",
      "propagateEvent",
      "onValidChange",
      "attachToForm",
      "detachFromForm",
      "runValidation",
      "validate"
    ];

    unnecessaryProps.forEach(prop => delete rest[prop]);

    let value = valueFromProps || "";

    if (focusValueDiffer) {
      value = this.state.inputFocus ? focusValue : blurValue;
    }

    const shouldErrorBeShown = Boolean(
      this.shouldShowError() || this.shouldShowRequired()
    );

    return (
      <FormControl
        className={classes.formControl}
        error={shouldErrorBeShown}
        aria-describedby="name-error-text"
        fullWidth={fullWidth}
      >
        <InputLabel htmlFor={name} className="jtc-input-label">
          {label}
        </InputLabel>
        <Input
          {...rest}
          error={rest.error || shouldErrorBeShown}
          required={required}
          id={name}
          value={value}
          onChange={this.changeValue}
          onFocus={this.onFocus}
          inputProps={{
            className: "jtc-input-field"
          }}
          onBlur={e => this.onBlur(e)}
          autoComplete="off"
        />
        {this.shouldShowRequired() ? (
          <FormHelperText>
            {(function(requiredText, validationErrors, translate) {
              if (requiredText) {
                return requiredText;
              } else if (validationErrors && validationErrors.required) {
                return validationErrors.required;
              } else {
                return translate("This field is required");
              }
            })(requiredText, validationErrors, translate)}
          </FormHelperText>
        ) : null}
        {this.shouldShowError() ? (
          <FormHelperText>
            {(function(errorMessage, validationError) {
              if (errorMessage) {
                return translate(errorMessage);
              } else if (validationError instanceof Object) {
                return validationError.map((error, key) => (
                  <React.Fragment key={key}>
                    <span>{translate(error)}</span>
                    <br />
                  </React.Fragment>
                ));
              } else {
                return translate(validationError);
              }
            })(errorMessage, this.props.validationError)}
          </FormHelperText>
        ) : null}
      </FormControl>
    );
  }
}

export default connect()(withStyles(styles)(withFormsy(ComposedTextField)));
