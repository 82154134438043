import React from "react";

import connect from "../i18n/connect";
import decisionModal from "../lib/decisionModal";
import { startAccountDeletionProcess } from "../redux/modules/auth/delete";
import { getUserData } from "../redux/modules/auth/login/selector";

export default function withDeleteAccount(WrappedComponent) {
  const mapStateToProps = state => ({
    deletionDatetime: getUserData(state)?.deletion_request_datetime_utc
  });

  const mapDispatchToProps = {
    deleteAccount: startAccountDeletionProcess
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithDeleteAccount extends React.Component {
    render() {
      return (
        <WrappedComponent
          {...this.props}
          deleteAccount={async () => {
            const decision = await decisionModal(
              "ConfirmModal",
              {
                title: "deletion_account_modal_title",
                message: "deletion_account_modal_description"
              },
              ["confirm", "cancel"]
            );

            if (decision === "confirm") {
              this.props.deleteAccount();
            }
          }}
        />
      );
    }
  }

  return WithDeleteAccount;
}
