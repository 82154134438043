module.exports = {
  title: "Expalerts",
  vendor: "expalerts",
  defaultLocale: "en",
  supportedLocales: ["en"],
  disclaimer: "https://app.expalerts.com/static/invauth/disclaimer.pdf",
  termsAndConditionsUrl:
    "https://s3.eu-central-1.amazonaws.com/panel.expasset.com/1553525484_ddaa5f685f03_ExpRegisterTermsandConditions.pdf",
  privacyPolicyUrl:
    "https://s3.eu-central-1.amazonaws.com/panel.expasset.com/1553525484_ddaa5f685f03_ExpRegisterTermsandConditions.pdf",
  gdprInfoUrl: "https://l.squaber.com/rozszerzona-klauzula-informacyjna/",
  defaultPremiumOfferUrl:
    "https://squaber.com/l/oferta-dostepu-squabera-premium",
  chartImageApiUrl: "https://app.expalerts.com/:locale/api/",
  prodBaseUrl: "https://expalerts.com/",
  cookieDomain: ".expalerts.com",
  authTokenCookieName: "expalerts_auth_token",
  registerUrl: "https://testing.expregister.com/register"
};
