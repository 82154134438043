import "../styles/default.scss";

import Snackbar from "@material-ui/core/Snackbar";
import SnackbarContent from "@material-ui/core/SnackbarContent";
import React from "react";
import { compose } from "redux";

import withEmailConfirmation from "../../../../../hocs/withEmailConfirmation";
import withUserData from "../../../../../hocs/withUserData";
import theme from "../../../../../theme";
import SquaberButton from "../../SquaberButton";

const EmailConfirmationSnackbar = ({
  translate,
  resendConfirmationEmail,
  userData
}) => (
  <Snackbar open={true} className="email-confirmation-snackbar">
    <SnackbarContent
      aria-describedby="client-snackbar"
      style={{
        background: theme.colors.error
      }}
      message={
        <span
          id="client-snackbar"
          style={{
            color: theme.snackbarDefaults.color
          }}
        >
          <strong>{translate("Warning!")}</strong>{" "}
          {translate(
            "email-confirmation-notification-part-1 %{email_address}",
            {
              email_address: userData.email
            }
          )}{" "}
          <SquaberButton
            buttonStyle={false}
            onClick={() => {
              resendConfirmationEmail();
            }}
          >
            {translate("click here")}
          </SquaberButton>
          {translate("email-confirmation-notification-part-2")}
        </span>
      }
    />
  </Snackbar>
);

EmailConfirmationSnackbar.propTypes = {};

export default compose(
  withEmailConfirmation,
  withUserData
)(EmailConfirmationSnackbar);
