import React from "react";
import { getHomeLandingPageState } from "../redux/modules/homeLandingPage/selector";
import { getHomeLandingPageRoutine } from "../redux/modules/homeLandingPage";
import { connect } from "../i18n/connect";

export default function withHomeLandingPage(WrappedComponent) {
  const mapStateToProps = state => ({
    homeLandingPageState: getHomeLandingPageState(state)
  });

  const mapDispatchToProps = {
    getHomeLandingPage: getHomeLandingPageRoutine
  };

  @connect(mapStateToProps, mapDispatchToProps)
  class WithHomeLandingPage extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithHomeLandingPage;
}
