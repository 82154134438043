import Slide from "@material-ui/core/Slide/Slide";
import React from "react";
import { getIsMobile } from "../../../../lib/platformHelper";

const TransitionComponent = props => <Slide {...props} />;
const TRANSITION_DELAY = 150;
const TRANSITION_DOWN_DURATION = 200;

const defaultAnchorOrigin = {
  vertical: getIsMobile() ? "bottom" : "top",
  horizontal: "left"
};

/**
 * @param {object} anchorOrigin - how snack is postioned. e.g.
 * { vertical: 'bottom', horizontal: 'left' }
 * @param {number} level - Level on which snakcbar should be displayed
 * (when snackbars are stacked on top of eachother)
 */
const getTransitionStyles = (level, anchorOrigin = defaultAnchorOrigin) =>
  Object.assign(
    {
      [anchorOrigin.vertical]:
        level * 48 + level * 16 + 20 + (getIsMobile() ? 0 : 50)
    },
    {
      WebKitTransition: `all ${TRANSITION_DOWN_DURATION}ms`,
      MozTransition: `all ${TRANSITION_DOWN_DURATION}ms`,
      msTransition: `all ${TRANSITION_DOWN_DURATION}ms`,
      OTransition: `all ${TRANSITION_DOWN_DURATION}ms`,
      transition: `all ${TRANSITION_DOWN_DURATION}ms`,
      transitionDelay: `${TRANSITION_DELAY}ms`
    }
  );

export {
  TransitionComponent,
  TRANSITION_DELAY,
  TRANSITION_DOWN_DURATION,
  defaultAnchorOrigin,
  getTransitionStyles
};
