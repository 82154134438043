import { Drawer, makeStyles } from "@material-ui/core";
import React, { useCallback, useState } from "react";

import MobileNavigationLinks from "./MobileNavigationLinks";

const useStyles = makeStyles({
  rootBackdrop: {
    opacity: "0 !important"
  }
});

function MobileSubmenu({ open, onClose, label, children }) {
  const [nestedSubmenu, setNestedSubmenu] = useState(false);
  const classes = useStyles();

  const handleCloseNestedSubmenu = useCallback(() => {
    setNestedSubmenu(null);
  }, []);

  const handleOpenNestedSubmenu = useCallback(props => {
    setNestedSubmenu(props);
  });

  return (
    <Drawer
      style={{ zIndex: 300000000 }}
      anchor={"left"}
      open={open}
      onClose={onClose}
      className={"mobile-navigation mobile-submenu"}
      BackdropProps={{ classes: { root: classes.rootBackdrop } }}
    >
      <div className={"mobile-submenu__content"}>
        <div className={"mobile-submenu__breadcrumb"}>
          <button className={"mobile-submenu__close"} onClick={onClose}>
            <i className="far fa-chevron-left" />
          </button>
          <strong className={"mobile-navigation__title"}>{label}</strong>
        </div>
        <MobileNavigationLinks
          links={children}
          onOpenSubmenu={handleOpenNestedSubmenu}
          submenu={nestedSubmenu}
        />
      </div>
      <MobileSubmenu
        onClose={handleCloseNestedSubmenu}
        open={!!nestedSubmenu}
        {...nestedSubmenu}
      />
    </Drawer>
  );
}

export default MobileSubmenu;
