import React from "react";
import PropTypes from "prop-types";
import getDividedNumberString from "../../../../../lib/getDividedNumberString";
import "../styles/default.scss";

const DividedNumber = ({ value }) => {
  const dividedValue = getDividedNumberString(value);

  return (
    <div className="divided-number">
      {dividedValue.split("").map((char, index) => {
        if (char === " ") {
          return <span key={index} className="divider" />;
        }

        return char;
      })}
    </div>
  );
};

DividedNumber.propTypes = { value: PropTypes.number.isRequired };

export default DividedNumber;
