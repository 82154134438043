import type { ContactEventData } from "../types/SalesManagoEventData.type";
import { BaseEvent } from "./BaseEvent";

interface StockListEventMetaData {
  id: number;
  action: "CREATION" | "DELETION";
  name: string;
}

class StockListEvent extends BaseEvent {
  constructor(stockList: StockListEventMetaData) {
    super();
    this.stockList = stockList;
  }

  getEventName() {
    return "stock_list";
  }

  getEventTriggerName() {
    return "Creation or deletion user stock lists (tags)";
  }

  getEventDetailsMatrix() {
    return `detail5=stock list id
detail6=creation/deletion
detail7=stock list name`;
  }

  getContactEventData(): ContactEventData {
    return {
      detail5: String(this.stockList.id),
      detail6: this.stockList.action,
      detail7: this.stockList.name
    };
  }
}
export default StockListEvent;
