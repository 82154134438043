import React from "react";
import SweetScroll from "sweet-scroll";
import classNames from "classnames";
import withFaq from "../../../../../hocs/withFaq";
import { UrlProvider } from "../../../../../api/UrlProvider";
import withDeviceType from "../../../../../hocs/withDeviceType";
import Loader from "../../../common/Loader";
import SquaberLink from "../../../common/SquaberLink";
import PageTitle from "../../../common/PageTitle/modules/default";

import "../styles/default.scss";

@withFaq
@withDeviceType
class Faq extends React.Component {
  state = {
    questionsListOpen: false
  };

  componentDidMount(): void {
    this.scroller = SweetScroll.create({
      duration: 500,
      easing: "easeOutExpo"
    });

    setTimeout(() => {
      this.scrollToQuestion();
    }, 1000);
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ): void {
    const previousQuestionId = this.getQuestionIdFromProps(prevProps);
    const currentQuestionId = this.getQuestionIdFromProps(this.props);

    if (currentQuestionId && previousQuestionId !== currentQuestionId) {
      this.scrollToQuestion();
    }
  }

  getQuestionIdFromProps(props) {
    return props.match.params.questionId;
  }

  scrollToQuestion() {
    const { isMobile } = this.props;
    const questionId = this.getQuestionIdFromProps(this.props);

    if (questionId) {
      this.scroller.to(`#question-${questionId}`, {
        offset: isMobile ? -100 : -135
      });
    }
  }

  openQuestionsList() {
    this.setState({
      questionsListOpen: true
    });
  }

  hideQuestionsList() {
    this.setState({
      questionsListOpen: false
    });
  }

  render() {
    const { translate, locale, faqQuestionsState } = this.props;
    const { questionsListOpen } = this.state;

    if (faqQuestionsState.loading) {
      return <Loader />;
    }

    return (
      <div className="faq-page">
        <PageTitle title={translate("Frequently asked questions")} />
        <div
          className="questions-list-expand"
          onClick={() => {
            this.openQuestionsList();
          }}
        >
          <i className="fas fa-question" />
        </div>
        <div
          className={classNames({
            "faq-page-sidebar": true,
            open: questionsListOpen
          })}
        >
          <div className="questions-list-heading-wrapper">
            <h1 className="big-heading questions-list-heading">
              {translate("Frequently asked questions")}
            </h1>
            <div
              className="hide-questions-list"
              onClick={() => {
                this.hideQuestionsList();
              }}
            >
              <i className="fal fa-times" />
            </div>
          </div>
          <ul className="faq-questions">
            {faqQuestionsState.data.map(question => (
              <li key={question.id}>
                <SquaberLink
                  to={UrlProvider.getUrl("fe.faqQuestion", {
                    locale,
                    questionId: question.id
                  })}
                  onClick={() => {
                    this.hideQuestionsList();
                  }}
                >
                  {question.question}
                </SquaberLink>
              </li>
            ))}
          </ul>
        </div>
        <div className="faq-page-content">
          {faqQuestionsState.data.map(question => (
            <div key={question.id} id={`question-${question.id}`}>
              <h2 className="big-heading">{question.question}</h2>
              <div dangerouslySetInnerHTML={{ __html: question.answer }} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Faq;
