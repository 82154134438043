import { showModal } from "./react-redux-modal-provider";

type Decisions = Array<string>;

export default function decisionModal(
  modalComponent,
  modalProps,
  decisions: Decisions,
  decisionLabels = {}
) {
  return new Promise(resolve => {
    let decisionMethods = {};
    decisions.forEach(decision => {
      decisionMethods[decision] = () => resolve(decision);
    });
    showModal(modalComponent, {
      ...modalProps,
      ...decisionMethods,
      decisionLabels
    });
  });
}
