import { handleActions } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

export const fetchAnalysisCategories = createRoutine(
  "app/analysisCategories/FETCH_ANALYSIS_CATEGORIES"
);

const initialState = {
  data: [],
  error: null,
  loading: null
};

export default handleActions(
  {
    [fetchAnalysisCategories.TRIGGER]: state => ({
      ...state,
      loading: true
    }),
    [fetchAnalysisCategories.FAILURE]: (state, { payload }) => ({
      ...state,
      loading: false,
      error: payload
    }),
    [fetchAnalysisCategories.SUCCESS]: (state, { payload }) => ({
      ...state,
      loading: false,
      data: payload
    })
  },
  initialState
);
