import "../styles/default.scss";

import React from "react";

import Shimmer from "../../../Shimmer";

function StockPageHeaderShimmer() {
  return (
    <div className={"stock-page-header-shimmer"}>
      <div className={"stock-page-header-shimmer__summary"}>
        <Shimmer className={"stock-page-header-shimmer__ticker"} />
        <div className={"stock-page-header-shimmer__stock-status"}>
          <Shimmer className={"stock-page-header-shimmer__change"} />
          <Shimmer className={"stock-page-header-shimmer__price"} />
        </div>
      </div>
      <Shimmer className={"stock-page-header-shimmer__update-time"} />
    </div>
  );
}

export default StockPageHeaderShimmer;
