import "../styles/default.scss";

import classNames from "classnames";
import React from "react";

import withTranslations from "../../../../../hocs/withTranslations";
import FormBuilderWeb from "../../../../../lib/FormBuilder/FormBuilderWeb";
import FormsyComposedTextField from "../../../../../lib/FormBuilder/FormsyMaterialUI/FormsyComposedTextField";
import FormsyDatePicker from "../../../../../lib/FormBuilder/FormsyMaterialUI/FormsyDatePicker";
import SquaberAutocompleteWrapper from "../../SquaberAutocompleteWrapper";
import SquaberButton from "../../SquaberButton";

const TICKER_FIELD_NAME = "ticker";
const PRICE_FIELD_NAME = "price";

const PortfolioAddTransaction = ({
  withTickerSelector = false,
  translate,
  formBuilder,
  autocompleteValue,
  setAutocompleteValue,
  calculateCommission,
  clearValidationError,
  validationErrors,
  setAutocompleteBlurred,
  portfolioData,
  loading
}) => {
  return (
    <tr
      className={classNames({
        "portfolio-add-transaction": true,
        "with-ticker-selector": withTickerSelector
      })}
    >
      {withTickerSelector ? (
        <td>
          <div className="form-builder">
            <SquaberAutocompleteWrapper
              htmlFor={TICKER_FIELD_NAME}
              name={TICKER_FIELD_NAME}
              label={"Ticker"}
              source={"app.stocks.searchAutocompletePortfolio"}
              params={{
                currency: portfolioData.currency
              }}
              autocompleteValue={autocompleteValue}
              setAutocompleteValue={value => {
                setAutocompleteValue(value);

                if (validationErrors?.[TICKER_FIELD_NAME]) {
                  clearValidationError(TICKER_FIELD_NAME);
                }

                if (validationErrors?.[PRICE_FIELD_NAME]) {
                  clearValidationError(PRICE_FIELD_NAME);
                }
              }}
              setAutocompleteBlurred={setAutocompleteBlurred}
            />
          </div>
        </td>
      ) : null}
      <td>
        <FormBuilderWeb
          {...formBuilder}
          fields={formBuilder.fields.filter(field => field.name === "type")}
          clearValidationError={clearValidationError}
          validationErrors={validationErrors}
          className="select-wrapper"
        />
      </td>
      <td>
        <div className="form-builder">
          <div>
            <FormsyDatePicker
              {...formBuilder.fields.filter(field => field.name === "date")[0]}
              onChange={value => {
                formBuilder.handleChange(
                  {
                    name: "date"
                  },
                  value
                );
                clearValidationError("date");
                clearValidationError(PRICE_FIELD_NAME);
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="form-builder">
          <div>
            <FormsyComposedTextField
              {...formBuilder.fields.filter(
                field => field.name === PRICE_FIELD_NAME
              )[0]}
              onChange={value => {
                formBuilder.handleChange(
                  {
                    name: PRICE_FIELD_NAME
                  },
                  value
                );
                clearValidationError(PRICE_FIELD_NAME);
              }}
              onBlur={() => {
                calculateCommission();
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <div className="form-builder">
          <div>
            <FormsyComposedTextField
              {...formBuilder.fields.filter(
                field => field.name === "shares"
              )[0]}
              onChange={value => {
                formBuilder.handleChange(
                  {
                    name: "shares"
                  },
                  value
                );
                clearValidationError("shares");
              }}
              onBlur={() => {
                calculateCommission();
              }}
            />
          </div>
        </div>
      </td>
      <td>
        <FormBuilderWeb
          {...formBuilder}
          fields={formBuilder.fields.filter(
            field => field.name === "commission"
          )}
          clearValidationError={clearValidationError}
          validationErrors={validationErrors}
        />
      </td>
      <td>
        <SquaberButton
          type="submit"
          disabled={loading}
          onClick={() => {
            if (setAutocompleteBlurred) {
              setAutocompleteBlurred(true);
            }
          }}
        >
          {translate("Add")}
        </SquaberButton>
      </td>
    </tr>
  );
};

export default withTranslations(PortfolioAddTransaction);
