import React from "react";
import { compose } from "redux";
import classNames from "classnames";
import withQuoteData from "../../../../../hocs/withQuoteData";
import withFollowUnfollow from "../../../../../hocs/withFollowUnfollow";
import withStockIsFollowed from "../../../../../hocs/withStockIsFollowed";
import { UrlProvider } from "../../../../../api/UrlProvider";
import Price from "../../../../../common/Price";
import ChangePercent from "../../ChangePercent";
import SquaberLink from "../../SquaberLink";

import "../styles/default.scss";

const PortfolioStocksTableRow = ({
  stock,
  locale,
  isCurrent,
  setCurrentStock,
  quoteData,
  stockIsFollowed,
  follow,
  unFollow
}) => (
  <tr className="portfolio-stocks-table-row">
    <td>
      <SquaberLink
        to={UrlProvider.getUrl("fe.stockPage", {
          locale,
          marketId: stock.market,
          stockId: stock.ticker
        })}
      >
        {stock.ticker}
      </SquaberLink>
    </td>
    <td>{stock.shares}</td>
    <td>{Number(stock.value_buy).toFixed(2)}</td>
    <td>
      <Price value={stock.result} />
    </td>
    <td>
      <ChangePercent change={stock.rate_of_return} />
    </td>
    <td>
      <Price value={stock.value_buy / stock.shares} />
    </td>
    <td>{quoteData ? <Price value={quoteData.close} /> : null}</td>
    <td>
      <i
        className={classNames({
          fa: true,
          "fa-bell": true,
          "stock-alert-indicator": true,
          active: stockIsFollowed
        })}
        onClick={() => {
          if (stockIsFollowed) {
            unFollow({
              stockId: stock.stock_id,
              isUnfollowing: true
            });
          } else {
            follow({ stockId: stock.stock_id });
          }
        }}
      />
    </td>
    <td>
      <span
        className="expand-stock"
        onClick={() => {
          if (isCurrent) {
            setCurrentStock(null);
          } else {
            setCurrentStock(stock);
          }
        }}
      >
        <i
          className={classNames({
            fal: true,
            "fa-ellipsis-v": !isCurrent,
            "fa-times": isCurrent
          })}
        />
      </span>
    </td>
  </tr>
);

export default compose(
  withQuoteData(),
  withFollowUnfollow,
  withStockIsFollowed(props => props.stock.stock_id)
)(PortfolioStocksTableRow);
