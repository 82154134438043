export default function alignMovingAverageOptions(source) {
  if (!source.state) {
    return source;
  }

  const { inputs } = source.state;

  if (typeof inputs.in_0 !== "undefined") {
    source.state.inputs.length = inputs.in_0;
  }

  if (typeof inputs.in_1 !== "undefined") {
    source.state.inputs.source = inputs.in_1;
  }

  if (typeof inputs.in_2 !== "undefined") {
    source.state.inputs.smoothingLength = inputs.in_2;
  }

  return source;
}
