import { fork, put, takeLatest } from "redux-saga/effects";

import ApiClient from "../../../api/ApiClient";
import MarketingMessageActivityEvent from "../../../lib/SalesManago/events/MarketingMessagesActivityEvent";
import { trackSMEvent } from "../salesManagoEventTracker";
import {
  acceptMarketingMessageRoutine,
  clickCtaMarketingMessageRoutine,
  dismissMarketingMessageTopBarRoutine,
  fetchMarketingMessagesRoutine,
  skipMarketingMessageRoutine
} from "./index";

function* onFetchMarketingMessages() {
  try {
    const { data } = yield ApiClient.get({
      urlPath: "app.marketing.messages",
      skipAccessCheck: true
    });

    yield put(fetchMarketingMessagesRoutine.success(data));
  } catch (error) {
    yield put(fetchMarketingMessagesRoutine.failure(error));
  }
}

function* onDismissMarketingMessageTopBar({
  payload: { messageId, messageType, messageCtaUrl, messageText }
}) {
  yield put(
    acceptMarketingMessageRoutine({
      messageId,
      messageType,
      messageCtaUrl,
      messageText
    })
  );

  const smEvent = new MarketingMessageActivityEvent({
    marketingMessageId: messageId,
    marketingMessageCtaUrl: messageCtaUrl,
    action: "close",
    marketingMessageType: messageType,
    marketingMessageText: messageText
  });

  yield put(trackSMEvent(smEvent));
}

function* onSkipMarketingMessage({
  payload: { messageId, messageType, messageCtaUrl, messageText }
}) {
  try {
    yield ApiClient.get({
      urlPath: "app.marketing.skipMarketingMessage",
      variables: { messageId }
    });

    const smEvent = new MarketingMessageActivityEvent({
      marketingMessageId: messageId,
      marketingMessageCtaUrl: messageCtaUrl,
      action: "skipped",
      marketingMessageType: messageType,
      marketingMessageText: messageText
    });

    yield put(trackSMEvent(smEvent));
  } catch (error) {
    console.log(error);
  }
}

function* onAcceptMarketingMessage({
  payload: { messageId, messageType, messageCtaUrl, messageText }
}) {
  try {
    yield ApiClient.get({
      urlPath: "app.marketing.acceptMarketingMessage",
      variables: { messageId }
    });

    const smEvent = new MarketingMessageActivityEvent({
      marketingMessageId: messageId,
      marketingMessageCtaUrl: messageCtaUrl,
      action: "accept",
      marketingMessageType: messageType,
      marketingMessageText: messageText
    });

    yield put(trackSMEvent(smEvent));
  } catch (error) {
    console.log(error);
  }
}

function* onClickCtaMarketingMessage({
  payload: { messageId, messageType, messageCtaUrl, messageText }
}) {
  try {
    const smEvent = new MarketingMessageActivityEvent({
      marketingMessageId: messageId,
      marketingMessageCtaUrl: messageCtaUrl,
      action: "CTA",
      marketingMessageType: messageType,
      marketingMessageText: messageText
    });

    yield put(trackSMEvent(smEvent));
  } catch (error) {
    console.log(error);
  }
}

function* watchGetMarketingMessages() {
  yield takeLatest(
    fetchMarketingMessagesRoutine.TRIGGER,
    onFetchMarketingMessages
  );
  yield takeLatest(skipMarketingMessageRoutine.TRIGGER, onSkipMarketingMessage);
  yield takeLatest(
    acceptMarketingMessageRoutine.TRIGGER,
    onAcceptMarketingMessage
  );
  yield takeLatest(
    dismissMarketingMessageTopBarRoutine.TRIGGER,
    onDismissMarketingMessageTopBar
  );
  yield takeLatest(
    clickCtaMarketingMessageRoutine.TRIGGER,
    onClickCtaMarketingMessage
  );
}

export default [fork(watchGetMarketingMessages)];
