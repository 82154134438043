import React from "react";
import connect from "../i18n/connect";
import { getAdvancedChartVisible } from "../redux/modules/advancedChartVisible/selector";
import { setAdvancedChartVisible } from "../redux/modules/advancedChartVisible";

export default function withAdvancedChart(WrappedComponent) {
  const mapStateToProps = state => ({
    advancedChartVisible: getAdvancedChartVisible(state)
  });

  const mapDispatchToProps = {
    setAdvancedChartVisible
  };
  @connect(mapStateToProps, mapDispatchToProps)
  class WithAdvancedChart extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithAdvancedChart;
}
