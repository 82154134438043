import React from "react";

import connect from "../i18n/connect";
import UniversalTableContext from "../lib/UniversalTable/UniversalTableContext";
import UniversalTableCore from "../lib/UniversalTable/UniversalTableCore";
import {
  saveUniversalTableSettings,
  updateUniversalTableSettings
} from "../redux/modules/universalTable";
import { getSavedUniversalTableSettings } from "../redux/modules/universalTable/selector";
import withUserData from "./withUserData";

export default function withUniversalTable(WrappedComponent) {
  const mapStateToProps = state => ({
    savedUniversalTableSettings: getSavedUniversalTableSettings(state)
  });
  const mapDispatchToProps = {
    saveUniversalTableSettings,
    updateUniversalTableSettings
  };

  @connect(mapStateToProps, mapDispatchToProps)
  @withUserData
  class WithUniversalTable extends UniversalTableCore {
    getSavedSettings(cacheKey): {} {
      const savedSettings = {};

      if (cacheKey !== this.props.savedUniversalTableSettings.cacheKey) {
        return savedSettings;
      }

      for (const [key, value] of Object.entries(
        this.props.savedUniversalTableSettings.settings
      )) {
        if (value === null) {
          continue;
        }

        savedSettings[key] = value;
      }

      return savedSettings;
    }

    saveSettings(cacheKey, settings) {
      this.props.saveUniversalTableSettings({ cacheKey, settings });
    }

    updateSavedSettings(cacheKey, settings) {
      this.props.updateUniversalTableSettings({ cacheKey, settings });
    }

    render() {
      const { resourceId, translate } = this.props;

      if (typeof resourceId === "undefined" || resourceId === null) {
        return translate("No resource for table provided");
      }

      return (
        <UniversalTableContext.Provider value={this.state.UniversalTable}>
          <WrappedComponent
            {...this.props}
            getColumns={this.getColumns}
            getColumnPairs={this.getColumnPairs}
            fetchNextPage={this.fetchNextPage}
            setTab={this.setTab}
          />
        </UniversalTableContext.Provider>
      );
    }
  }

  return WithUniversalTable;
}
