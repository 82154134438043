let env = process.env;

function isNative() {
  if (!env.PLATFORM_ENV || env.PLATFORM_ENV !== "web") {
    return true;
  }

  return false;
}

module.exports = isNative;
