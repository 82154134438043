import urlPatterns from "./UrlPatterns";
import config from "../config";

export class UrlProvider {
  static getRawUrl(path, base = "/") {
    let url = urlPatterns;

    path.split(".").forEach(item => {
      url = url[item];
    });

    return base + url;
  }

  static getUrl(path, args = {}, base = "/") {
    let url = this.getRawUrl(path, base);

    Object.keys(args).forEach(key => {
      url = url.replace(":" + key, args[key]);
    });

    return url;
  }

  static replaceLocaleFromUrl(url, locale) {
    const supportedLocales = config?.supportedLocales || [];

    if (!supportedLocales.includes(locale)) return url;

    const localesRegExp = new RegExp(`/(${supportedLocales.join("|")})/`, "g");
    return url.replace(localesRegExp, `/${locale}/`);
  }

  static getUrlFromPath(path, args = {}) {
    let url = path;

    Object.keys(args).forEach(key => {
      url = url.replace(":" + key, args[key]);
    });

    return url;
  }
}
