import React from "react";
import withGlobalConfigFields from "../../../../../hocs/withGlobalConfigFields";
import { globalConfigFields } from "../../../../../redux/modules/globalConfig";
import ContentSection from "../../../common/ContentSection";

function ChangesHistory({ globalConfig: { changesHistory: content } }) {
  return (
    <ContentSection dangerouslySetInnerHTML={{ __html: content }}/>
  );
}

export default withGlobalConfigFields([globalConfigFields.CHANGES_HISTORY])(
  ChangesHistory
);
