import { isEqual } from "lodash";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import ApiClientAsync from "../api/ApiClientAsync";
import { getAuthToken } from "../redux/modules/auth/login/selector";

const useGetRequest = ({
  urlPath,
  variables,
  locale,
  skipAccessCheck = false
}) => {
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState();
  const [error, setError] = useState();
  const [_variables, _setVariables] = useState(variables);
  const authToken = useSelector(getAuthToken);

  useEffect(() => {
    if (isEqual(variables, _variables)) {
      return;
    }

    _setVariables(variables);
  }, [variables]);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);

      try {
        const apiClientAsync = new ApiClientAsync(locale, authToken);
        const data = await apiClientAsync.get({
          urlPath,
          variables: _variables,
          skipAccessCheck
        });

        setResponse(data);
      } catch (e) {
        setError(e);
        console.error(e);
      }

      setLoading(false);
    };

    fetch().then();
  }, [urlPath, _variables]);

  return { response, error, loading };
};

export default useGetRequest;
