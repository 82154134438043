import React from "react";
import classNames from "classnames";
import withTranslations from "../../../../../hocs/withTranslations";

import "../styles/DesktopSorting.scss";

@withTranslations
class DesktopSorting extends React.Component {
  state = {
    open: false
  };

  onChange(value) {
    this.props.onChange(value);
    this.setOpen(false);
  }

  setOpen(value) {
    this.setState({ open: value });
  }

  render() {
    const { options, value, translate } = this.props;
    const { open } = this.state;

    const label = options.find(option => option.value === value).label;

    return (
      <div
        className={classNames({
          "desktop-sorting-select": true,
          open
        })}
      >
        <div
          className="desktop-sorting-select__label"
          onClick={() => {
            this.setOpen(true);
          }}
        >
          <span>{translate(label)}</span>
          <i className="fal fa-angle-down" />
        </div>
        {open ? (
          <React.Fragment>
            <div
              className="desktop-sorting-select__underlay"
              onClick={() => {
                this.setOpen(false);
              }}
            />
            <div className="desktop-sorting-select__options">
              {options.map(option => (
                <div
                  key={option.value}
                  className={classNames({
                    "desktop-sorting-select__option": true,
                    "is-active": option.value === value
                  })}
                  onClick={() => {
                    this.onChange(option.value);
                  }}
                >
                  {translate(option.label)}
                </div>
              ))}
            </div>
          </React.Fragment>
        ) : null}
      </div>
    );
  }
}

export default DesktopSorting;
