import "../styles/default.scss";

import classNames from "classnames";
import React from "react";
import { compose } from "redux";

import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withNavbarHeight from "../../../../../hocs/withNavbarHeight";
import withNotifications from "../../../../../hocs/withNotifications";
import useNavigationDynamicPaddingTop from "../../../../hooks/useNavigationDynamicPaddingTop";
import NotificationList from "../../NotificationList";

const MobileNotificationList = props => {
  const { navbarHeight, setValue, canDisplayVerifyEmailBar } = props;
  const bottomMenuHeight = 50;

  const navbarAndFooterHeight = canDisplayVerifyEmailBar
    ? navbarHeight + bottomMenuHeight + 26
    : navbarHeight + bottomMenuHeight;

  const paddingTop = useNavigationDynamicPaddingTop();

  return (
    <div
      className={classNames("mobile-notification-list")}
      style={{
        top: navbarHeight,
        height: `calc(100% - ${navbarAndFooterHeight}px)`,
        paddingTop: paddingTop
      }}
    >
      <NotificationList {...props} onToggle={setValue} />
    </div>
  );
};

export default compose(
  withNotifications,
  withBottomNavigation(),
  withNavbarHeight
)(MobileNotificationList);
