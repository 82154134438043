import { takeLatest, fork, put } from "redux-saga/effects";
import { fetchManualStrategies } from "./index";
import apiClient from "../../../api/ApiClient";
function* onFetchManualStrategies() {
  let results = [];
  const strategyDescriptors = [
    {
      urlPattern: "app.strategies.manual",
      isTransactionStrategy: false
    },
    {
      urlPattern: "app.strategies.transaction",
      isTransactionStrategy: true
    }
  ];

  try {
    for (const strategyDescriptor of strategyDescriptors) {
      const { data } = yield apiClient.get({
        urlPath: strategyDescriptor.urlPattern,
        skipAccessCheck: true
      });

      results.push(
        ...data.map(strategy => ({
          ...strategy,
          isTransactionStrategy: strategyDescriptor.isTransactionStrategy
        }))
      );
    }

    yield put(
      fetchManualStrategies.success(
        results.map(strategy => ({
          name: strategy.name,
          key: strategy.notifications_unique_id,
          notifications_unique_id: strategy.notifications_unique_id,
          notificationsEnabledByDefault:
            strategy?.notifications_enabled_by_default ?? true,
          isTransactionStrategy: strategy.isTransactionStrategy
        }))
      )
    );
  } catch (e) {
    yield put(fetchManualStrategies.failure(e));
  }
}

function* watchGetManualStrategies() {
  yield takeLatest(fetchManualStrategies.TRIGGER, onFetchManualStrategies);
}

export default [fork(watchGetManualStrategies)];
