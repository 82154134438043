import moment from "moment-mini";
import leadingZero from "../leadingZero";

export function getTimeFromSeconds(seconds) {
  let minutes = Math.floor(seconds / 60);
  let ss = leadingZero(seconds % 60);

  let hours = Math.floor(minutes / 60);
  let mm = leadingZero(minutes % 60);

  let days = Math.floor(hours / 24);
  let hh = leadingZero(hours % 24);

  return { days, hours, minutes, hh, mm, ss };
}

export const checkAccessTime = (paymentDetails = {}, userData) => {
  let {
    end_date,
    end_date_in_seconds,
    payment_menthod_in_verification
  } = paymentDetails;

  if (end_date) end_date += "Z";

  if (typeof end_date === "undefined" || !userData) {
    return false;
  }

  const processedTimeLeft = getTimeFromSeconds(end_date_in_seconds);

  let date, days;

  if (end_date && end_date_in_seconds > 0) {
    date = moment(new Date(end_date));
    days = processedTimeLeft.days;
  } else {
    date = days = 0;
  }

  const { hh, mm, ss } = processedTimeLeft;

  return {
    expired: !date && userData.last_payment_amount !== null,
    days: days,
    noPremiumAccess: !date && userData.last_payment_amount === null,
    time: date ? `${hh}:${mm}:${ss}` : 0,
    paymentMethodInVerification: payment_menthod_in_verification
  };
};
