import { takeLatest, fork, put } from "redux-saga/effects";
import { registerRoutine } from "./index";
import ApiClient from "../../../../api/ApiClient";
import { actions as alertsActions } from "../../alerts";
import { afterLogin } from "../login/saga";
import { getIsWeb } from "../../../../lib/platformHelper";
import config from "../../../../config";

function* onRegister({ payload: { fields, hideModal } }) {
  let requestData = {};
  fields.forEach(field => {
    if (field.type !== "checkbox") {
      requestData[field.name] = field.value;
    } else {
      requestData[field.name] = Boolean(field.selected.length);
    }
  });

  try {
    const { data } = yield ApiClient.post({
      urlPath: "app.auth.register",
      data: requestData
    });

    const { token } = data;

    yield put(registerRoutine.success(token));

    if (hideModal) {
      hideModal();
    }

    if (getIsWeb()) {
      localStorage.setItem(config.introductionModalStorageKey, "true");
    }

    yield afterLogin(token, true, false, "Regular");
  } catch (error) {
    if (error.request) {
      const response = JSON.parse(error.request.response);
      yield put(registerRoutine.failure(response.validation));

      if (response) {
        yield put(
          alertsActions.addErrorAlert({
            title: response.message
          })
        );
      }
    }
  }
}

function* watchRegister() {
  yield takeLatest(registerRoutine.TRIGGER, onRegister);
}

export default [fork(watchRegister)];
