import React from "react";
import withTranslations from "../../../../../hocs/withTranslations";

import "../styles/DesktopTagNotFound.scss";

const DesktopTagNotFound = ({ translate }) => (
  <div className="tag-not-found">
    <h4>{translate("Not found")}</h4>
    <p>{translate("We're very sorry. Desktop you requested was not found.")}</p>
  </div>
);

export default withTranslations(DesktopTagNotFound);
