let env = process.env;

if (!env.PLATFORM_ENV || env.PLATFORM_ENV !== "web") {
  let ExpoConstants = require("expo-constants").default;

  env = {
    ...env,
    ...ExpoConstants.expoConfig.extra
  };
}

export default env;
