import React from "react";
import UniversalTableContext from "../lib/UniversalTable/UniversalTableContext";

export default class PaginationAbstract extends React.Component {
  static contextType = UniversalTableContext;

  getTotalItems() {
    return this.context.data ? this.context.data.count : 0;
  }

  getPaginationState() {
    return this.context.pagination;
  }

  setPaginationState(newState) {
    this.context.setPaginationState(newState);

    if (this.props.onPaginationPageChange) {
      this.props.onPaginationPageChange();
    }
  }
}
