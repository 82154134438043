import React from "react";
import connect from "../i18n/connect";
import { getStockDataLoading } from "../redux/modules/stockData/selector";

export default function withStockDataLoading(WrappedComponent) {
  const mapStateToProps = state => ({
    stockDataLoading: getStockDataLoading(state)
  });

  @connect(mapStateToProps)
  class WithStockDataLoading extends React.Component {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return WithStockDataLoading;
}
