import { takeLatest, fork, put, delay } from "redux-saga/effects";
import { LOCATION_CHANGE } from "react-router-redux";
import { fetchMarketingBarsRoutine } from "../marketingBars";

function* onLocationChange() {
  // debounce by 200ms
  yield delay(200);

  yield put(fetchMarketingBarsRoutine());
}

function* watchLocationChange() {
  yield takeLatest(LOCATION_CHANGE, onLocationChange);
}

export default [fork(watchLocationChange)];
