import { createAction, handleActions } from "redux-actions";
import { createRoutine } from "redux-saga-routines";

export const initTagsServiceRoutine = createRoutine(
  "app/tagsService/INIT_TAGS_SERVICE"
);

export const ADD_TAG = "app/tagsService/ADD_TAG";
export const addTag = createAction(ADD_TAG, (tagData: Object) => ({
  tagData
}));

export const UPDATE_TAG = "app/tagsService/UPDATE_TAG";
export const updateTag = createAction(UPDATE_TAG, (tagData: Object) => ({
  tagData
}));

export const DELETE_TAG = "app/tagsService/DELETE_TAG";
export const deleteTag = createAction(DELETE_TAG, (id: Number) => ({ id }));

const initialState = {
  tags: []
};

export default handleActions(
  {
    [initTagsServiceRoutine.TRIGGER]: () => ({
      tags: []
    }),
    [initTagsServiceRoutine.SUCCESS]: (state, { payload }) => ({
      tags: payload
    }),
    [ADD_TAG]: (state, { payload }) => {
      const tagToInsert = payload.tagData;
      let tags = [...state.tags];

      let indexToInsertNewTagOn = 0;

      for (let i = 0; i < tags.length; i++) {
        if(tags[i + 1].tag_type === "02_admin") {
          indexToInsertNewTagOn = i + 1;
          break;
        }

        if (tags[i].tag_type !== "01_user") {
          continue;
        }

        if (tags[i].name > tagToInsert.name) {
          indexToInsertNewTagOn = i;
          break;
        }
      }

      tags.splice(indexToInsertNewTagOn, 0, tagToInsert);

      return {
        ...state,
        tags
      };
    },
    [UPDATE_TAG]: (state, { payload }) => {
      if (!payload.tagData || !payload.tagData.id) {
        return state;
      }

      const currentTags = [...state.tags];
      const tagToUpdateIndex = currentTags.findIndex(
        tag => tag.id === payload.tagData.id
      );

      currentTags[tagToUpdateIndex] = {
        ...currentTags[tagToUpdateIndex],
        ...payload.tagData
      };

      return {
        ...state,
        tags: currentTags
      };
    },
    [DELETE_TAG]: (state, { payload }) => {
      if (!payload.id) {
        return state;
      }

      const currentTags = [...state.tags];

      return {
        ...state,
        tags: currentTags.filter(tag => Number(tag.id) !== Number(payload.id))
      };
    }
  },
  initialState
);
