import autoBind from "auto-bind";

export default class HelperWithFormattingBase {
  renderMethods = {};

  constructor() {
    autoBind(this);
  }

  renderDefault(value) {
    return value;
  }

  render(value, format = "default", translate = value => value) {
    const renderMethod = this.getRenderMethod(format);

    return renderMethod(value, translate);
  }

  getRenderMethod(format) {
    if (this.renderMethods && this.renderMethods[format]) {
      return this.renderMethods[format].bind(this);
    }

    return this.renderDefault.bind(this);
  }
}
