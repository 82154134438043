import React from "react";
import autoBind from "auto-bind";
import { withRouter } from "react-router-dom";
import withAllAvailableTagsList from "../../../../../hocs/withAllAvailableTagsList";
import withBottomNavigation from "../../../../../hocs/withBottomNavigation";
import withUserDataGetter from "../../../../../hocs/withUserDataGetter";
import { getIsMobile } from "../../../../../lib/platformHelper";
import { UrlProvider } from "../../../../../api/UrlProvider";
import TagsHelper from "../../../../../lib/TagsHelper/TagsHelper";
import DesktopTagsList from "../../../common/DesktopTagsList";
import DesktopDetails from "../../../common/DesktopDetails";
import Loader from "../../../common/Loader";
import PageTitle from "../../../common/PageTitle/";
import tagsHelper from "../../../../../lib/TagsHelper/TagsHelper";

@withAllAvailableTagsList
@withBottomNavigation("bottomNavigationValue")
@withRouter
@withUserDataGetter
class Desktop extends React.Component {
  constructor(props) {
    super(props);

    autoBind.react(this);

    const sortOptions = [
      {
        value: "ticker",
        label: "Ticker ascending"
      },
      {
        value: "-ticker",
        label: "Ticker descending"
      },
      {
        value: "company_name",
        label: "Stock name ascending"
      },
      {
        value: "-company_name",
        label: "Stock name descending"
      },
      {
        value: "daily_change_from_backend",
        label: "Daily change ascending"
      },
      {
        value: "-daily_change_from_backend",
        label: "Daily change descending"
      },
      {
        value: "current_price_from_backend",
        label: "Current price ascending"
      },
      {
        value: "-current_price_from_backend",
        label: "Current price descending"
      }
    ];

    const viewTypes = ["grid", "table"];

    this.sortOptions = sortOptions;
    this.viewTypes = viewTypes;

    this.state = {
      settings: {
        sortBy: sortOptions[0].value,
        viewType: viewTypes[0],
        advancedView: false
      }
    };
  }

  componentDidUpdate(
    prevProps: Readonly<P>,
    prevState: Readonly<S>,
    snapshot: SS
  ) {
    const {
      allAvailableTags,
      match: {
        params: { tagId }
      },
      setValue,
      history,
      locale,
      updateStocksListSource
    } = this.props;

    if (getIsMobile()) {
      if (!allAvailableTags.loading && allAvailableTags.data.length) {
        const selectedTag = allAvailableTags.data.find(
          tag => Number(tag.id) === Number(tagId)
        );

        updateStocksListSource(selectedTag);
        setValue("watched");
        history.push(
          UrlProvider.getUrl("fe.stockPage", {
            locale,
            marketId: "GPW",
            stockId: "KGH"
          })
        );
      }
    }
  }

  updateSettings(key, value) {
    const { settings } = this.state;

    settings[key] = value;

    this.setState({ settings });
  }

  setSortBy(value) {
    this.updateSettings("sortBy", value);
  }

  setViewType(value) {
    this.updateSettings("viewType", value);
  }

  setAdvancedView(value) {
    this.updateSettings("advancedView", value);
  }

  render() {
    const {
      allAvailableTags,
      match: {
        params: { tagId }
      },
      translate,
      userHasAccess
    } = this.props;

    if (getIsMobile()) {
      return null;
    }

    const { settings } = this.state;

    if (!allAvailableTags || !allAvailableTags.data.length) {
      return <Loader />;
    }

    let finalTagId = tagId;

    if (typeof finalTagId === "undefined") {
      const alertedTag = allAvailableTags.data.find(tag =>
        TagsHelper.isAlertedTag(tag)
      );

      const wig20Tag = allAvailableTags.data.find(tag =>
        TagsHelper.isDefaultTag(tag)
      );

      finalTagId =
        alertedTag && alertedTag.stocks.length ? alertedTag.id : wig20Tag.id;
    }

    return (
      <div>
        <PageTitle title={translate("Desktop")} />
        <DesktopTagsList allAvailableTags={allAvailableTags} />
        <DesktopDetails
          id={finalTagId}
          settings={settings}
          options={{
            sortOptions: this.sortOptions,
            viewTypes: this.viewTypes
          }}
          setSortBy={this.setSortBy}
          setViewType={this.setViewType}
          setAdvancedView={this.setAdvancedView}
        />
      </div>
    );
  }
}

Desktop.propTypes = {};

export default Desktop;
