import qs from "qs";
import ApiHelper from "./CoreHelpers/ApiHelper";

export class UniversalTableQuery {
  queryObject = {};

  addOrdering(sortBy): UniversalTableQuery {
    if (!sortBy.field) {
      delete this.queryObject.ordering;
    } else {
      this.queryObject.ordering =
        sortBy.direction === "asc" ? sortBy.field : `-${sortBy.field}`;
    }

    return this;
  }

  addLimit(pagination): UniversalTableQuery {
    this.queryObject.limit = pagination.rowsPerPage;

    return this;
  }

  addOffset(pagination): UniversalTableQuery {
    if (pagination.current > 1) {
      this.queryObject.offset =
        pagination.rowsPerPage * (pagination.current - 1);
    }

    return this;
  }

  addSearch(search): UniversalTableQuery {
    Object.keys(this.queryObject).forEach(key => {
      const keyReg = /__icontains$/gm;

      if (keyReg.test(key)) {
        delete this.queryObject[key];
      }
    });

    for (let field in search) {
      if (search.hasOwnProperty(field)) {
        this.queryObject[`${field}__icontains`] = search[field];
      }
    }

    return this;
  }

  addFilters(filters, columns): UniversalTableQuery {
    for (let field in filters) {
      if (filters.hasOwnProperty(field)) {
        const correspondingColumnData = columns.find(column =>
          ApiHelper.compareColumnAttributeNameForFiltering(column, field)
        );

        if (typeof filters[field].min !== "undefined") {
          this.addRangeFilter(
            field,
            filters[field],
            ApiHelper.getIsDateRange(correspondingColumnData)
          );
        } else if (Array.isArray(filters[field])) {
          let shouldApplyFilter = true;

          if (shouldApplyFilter) {
            this.addValueMultiSelectFilter(field, filters[field]);
          }
        }
      }
    }

    return this;
  }

  addRangeFilter(field, value, isDateRange): void {
    if (
      typeof value.min !== "undefined" &&
      value.min !== null &&
      (!isNaN(value.min) || isDateRange)
    ) {
      this.queryObject[`${field}__gte`] = value.min;
    }

    if (
      typeof value.max !== "undefined" &&
      value.max !== null &&
      (!isNaN(value.max) || isDateRange)
    ) {
      this.queryObject[`${field}__lte`] = value.max;
    }
  }

  addValueMultiSelectFilter(field, value): void {
    this.queryObject[`${field}__in`] = value.join(",");
  }

  toString(): string {
    return qs.stringify(this.queryObject);
  }
}

export default class UniversalTableQueryBuilder {
  tableState = {};

  constructor(tableState) {
    this.setTableState(tableState);
  }

  setTableState(tableState) {
    this.tableState = tableState;
  }

  getQueryParams() {
    const { sortBy, pagination, search, filters } = this.tableState;
    const columns = this.tableState.getAllAvailableColumns();
    let query = new UniversalTableQuery();

    return query
      .addLimit(pagination)
      .addOffset(pagination)
      .addOrdering(sortBy)
      .addSearch(search)
      .addFilters(filters, columns)
      .toString();
  }
}
